import React, { useCallback, useState } from "react";
import {
  Stack,
  Text,
} from "@chakra-ui/react";

import Box from "components/Box";

import { StatusUpdatesCountCardProps, statusUpdatesCardPeriod } from "./types";
import StatusUpdatesCountCardHeader from "./StatusUpdatesCountCardHeader";
import useStatusUpdatesCount from "./hooks/useStatusUpdatesCount";
import GrowthIndicator from "./GrowthIndicator";

const StatusUpdatesCountCard: React.FC<StatusUpdatesCountCardProps> = ({
  showPeriodSelector = false,
  comparisonPeriod,
  badgeColor,
  isManager,
  statuses,
  subtitle,
  period,
  title,
  jobId,
}) => {
  const [
    selectedPeriod,
    setSelectedPeriod,
  ] = useState(statusUpdatesCardPeriod.currentPeriod);

  const handleSetSelectedPeriod = useCallback((value) => () => {
    setSelectedPeriod(value);
  }, []);

  const {
    growthRate,
    loading,
    count,
  } = useStatusUpdatesCount({
    comparisonPeriod,
    selectedPeriod,
    isManager,
    statuses,
    period,
    jobId,
  });

  return (
    <Box
      h="full"
      w="full"
      isLoading={loading}
      wrapperProps={{
        margin: 0,
      }}
      title={(
        <StatusUpdatesCountCardHeader
          setSelectedPeriod={handleSetSelectedPeriod}
          showPeriodSelector={showPeriodSelector}
          selectedPeriod={selectedPeriod}
          badgeColor={badgeColor}
          subtitle={subtitle}
          title={title}
        />
      )}
    >
      <Stack
        justifyContent="center"
        alignItems="center"
        spacing={1}
        h="full"
        p={4}
      >
        <Text
          fontFamily="extrabold"
          fontSize="6xl"
        >
          {count}
        </Text>

        {
          growthRate !== undefined && (
            <GrowthIndicator
              growthRate={Number(growthRate)}
            />
          )
        }
      </Stack>
    </Box>
  );
};

export default StatusUpdatesCountCard;
