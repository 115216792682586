import { Flex } from "@chakra-ui/react";
import React from "react";

import { ColorPickerOverlayProps } from "./types";

const ColorPickerOverlay: React.FC<ColorPickerOverlayProps> = ({
  toggleColorPicker,
  isOpen,
}) => {
  if (!isOpen) {
    return null;
  }

  return (
    <Flex
      onClick={toggleColorPicker}
      position="fixed"
      height="100vh"
      width="100vw"
      zIndex={9}
      bottom={0}
      right={0}
      left={0}
      top={0}
      css={{
        margin: "0 !important",
      }}
    />
  );
};

export default ColorPickerOverlay;
