import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import useConfigureLandingPageStore from "components/Forms/ConfigureLandingPageForm/store";
import useLandingPagePreview from "hooks/useLandingPagePreview";
import GoBackHeader from "components/PageHeaders/GoBackHeader";
import { EDIT_LANDING_PAGE_TEMPLATE_PAGE_PATH } from "routes";
import PageHeader from "components/PageHeaders/PageHeader";
import { LandingPage } from "generated/graphql";
import Button from "components/Button";
import useToast from "hooks/useToast";

const FormHeader: React.FC = () => {
  const [showToast] = useToast();
  const [t] = useTranslation();
  const history = useHistory();

  const {
    landingPage,
    isLoading,
    isValid,
  } = useConfigureLandingPageStore((store) => ({
    landingPage: store.landingPage,
    isLoading: store.isLoading,
    isValid: store.isValid,
  }));

  const handleSaveChanges = useCallback(() => {
    showToast({
      title: t("actions.information_updated"),
      status: "success",
    });

    history.push(EDIT_LANDING_PAGE_TEMPLATE_PAGE_PATH);
  }, [
    showToast,
    history,
    t,
  ]);

  const [showLandingPagePreview] = useLandingPagePreview();

  return (
    <GoBackHeader
      showTitle={false}
      childrenContainerProps={{
        w: "full",
      }}
    >
      <PageHeader title={t("forms.edit_landing_page_template_form.edit_template_information")}>
        <Button
          onClick={showLandingPagePreview(landingPage as LandingPage)}
          isLoading={isLoading}
          variant="outline"
          fontSize="sm"
          size="sm"
        >
          {t("buttons.preview")}
        </Button>

        <Button
          onClick={handleSaveChanges}
          isLoading={isLoading}
          disabled={!isValid}
          fontSize="sm"
          size="sm"
        >
          {t("buttons.finish_editing")}
        </Button>
      </PageHeader>
    </GoBackHeader>
  );
};

export default FormHeader;
