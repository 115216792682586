import React, { useMemo, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import TrackingList from "components/Tracking/TrackingListContainer/TrackingList";
import TrackingListContainer from "components/Tracking/TrackingListContainer";
import ClientListCard from "components/Tracking/Cards/Client/ClientListCard";
import TrackingListButtons from "components/Tracking/TrackingListButtons";
import { Client, useGetClientsQuery } from "generated/graphql";
import { CLIENT_PROFILE_PAGE_LOCATION } from "routes/locations";
import PageHeader from "components/PageHeaders/PageHeader";
import useToast from "hooks/useToast";
import usePaginationParam from "hooks/usePaginationParams";
import ClientsFiltersQueriesContainer from "contexts/trackingFiltersQueries/ClientsFiltersQueriesContainer";
import useClientFiltersParams from "hooks/useClientFiltersParams";
import { PAGINATION_LIMIT_ITEMS_PER_PAGE } from "constants/pagination";
import useFilter, { filters as queryFilters } from "hooks/useFilter";

import makeClientMatchingFilters from "./makeClientMatchingFilters";

const ClientMatching: React.FC = () => {
  const [search] = useFilter(queryFilters.search);
  const history = useHistory();

  const [t] = useTranslation();
  const [showToast] = useToast();
  const [filters, activeFiltersParamsCount] = useClientFiltersParams();

  const [{
    orderBy,
    setPage,
    offset,
    page,
  }, activePaginationParamsCount] = usePaginationParam();

  const listFilters = useMemo(() => (
    makeClientMatchingFilters()
  ), []);

  const {
    refetch,
    loading,
    called,
    data,
  } = useGetClientsQuery({
    skip: !filters.jobId,
    variables: {
      search: search as string,
      filters,
      orderBy,
      offset,
    },
  });

  const handleClick = useCallback((id) => () => {
    history.push(CLIENT_PROFILE_PAGE_LOCATION.toUrl({
      id,
    }));
  }, [history]);

  const renderItem = useCallback((client: Client) => (
    <ClientListCard
      onClick={handleClick(client.id)}
      cursor="pointer"
      key={client?.id}
      client={client}
    />
  ), [
    handleClick,
  ]);

  const refetchClientsList = useCallback(() => {
    if (!refetch) {
      return;
    }

    refetch({
      offset,
      orderBy,
      filters,
    })
      .catch((error) => {
        showToast({
          status: "error",
          title: error?.message,
        });
      });
  }, [
    offset,
    orderBy,
    refetch,
    filters,
    showToast,
  ]);

  useEffect(() => {
    if (called) {
      return;
    }

    refetchClientsList();
  }, [refetchClientsList, called]);

  useEffect(() => {
    const shouldResetPagination = (
      data?.countClients
      && page
      && data?.countClients < PAGINATION_LIMIT_ITEMS_PER_PAGE
      && page > 1
    );

    if (shouldResetPagination) {
      setPage(undefined);
    }
  }, [
    page,
    setPage,
    data,
  ]);

  const clients = (data?.listClients ?? []) as Client[];

  const activeFiltersCount = activePaginationParamsCount + activeFiltersParamsCount;

  return (
    <>
      <PageHeader title={t("client_matching.title")}>
        <TrackingListButtons
          refetchList={refetchClientsList}
          totalCount={data?.countClients}
          loading={!called || loading}
          csvExportType="clients"
        />
      </PageHeader>

      <ClientsFiltersQueriesContainer>
        <TrackingListContainer
          filters={listFilters}
          activeFiltersCount={activeFiltersCount}
        >
          <TrackingList
            countMessage={t("client_matching.clients_found", {
              count: data?.countClients ?? 0,
            })}
            emptyDataMessage={t("empty_data_messages.no_clients_were_found")}
            totalCount={data?.countClients}
            loading={!called || loading}
            renderItem={renderItem}
            items={clients}
          />
        </TrackingListContainer>
      </ClientsFiltersQueriesContainer>
    </>
  );
};

export default ClientMatching;
