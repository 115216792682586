import React from "react";
import { useTranslation } from "react-i18next";

import { ApplicationPreviewComponentProps } from "views/Forms/FormPreview/types";
import DownloadIcon from "settings/theme/icons/DownloadIcon";
import Button from "components/Button";
import useDownloadApplicantResponseResume from "hooks/useDownloadApplicantResponseResume";

import PreviewContent from "../PreviewComponents/PreviewContent";

const Resume: React.FC<ApplicationPreviewComponentProps> = ({
  formResponse,
}) => {
  const [t] = useTranslation();

  const [downloadResume, isLoading] = useDownloadApplicantResponseResume({
    formResponseId: formResponse.id,
  });

  return (
    <PreviewContent
      title={t("forms.steps.resume.if_you_have_a_resume")}
      renderChildrenAsText={false}
    >
      <Button
        isDisabled={!formResponse.resume}
        rightIcon={<DownloadIcon />}
        width={["full", null, "xs"]}
        onClick={downloadResume}
        isLoading={isLoading}
        colorScheme="primary"
        fontSize="xs"
        size="sm"
      >
        {t("forms.steps.resume.view_resume")}
      </Button>
    </PreviewContent>
  );
};

export default Resume;
