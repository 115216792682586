import React from "react";

import { ProfileCardContent } from "components/Tracking/Cards/CardComponents";
import { ApplicantCardProps } from "components/Tracking/Cards/types";

import useApplicantCard from ".";

const ApplicantProfileCard: React.FC<ApplicantCardProps> = ({
  showTooltip = false,
  applicant,
  roleMatch = false,
  ...props
}) => {
  const cardProps = useApplicantCard(applicant, showTooltip, roleMatch);

  return (
    <ProfileCardContent
      {...cardProps}
      {...props}
    />
  );
};

export default ApplicantProfileCard;
