import { useMemo } from "react";

import { FormStep, FormStepId, FormStepType } from "constants/formSteps";
import { ExtraSection, FixedSectionsConfiguration, FormTypeEnum } from "generated/graphql";
import i18n from "translations/i18n";
import { UseStepMode } from "views/Forms/types";

import Introduction from "../FormComponents/Introduction";
import ThankYou from "../FormComponents/ThankYou";
import { UseEditFormStepsOptions } from "../types";

const introductionStep = {
  id: FormStepId.Introduction,
  type: FormStepType.Optional,
  component: Introduction,
  title: i18n.t("forms.steps.introduction.title"),
  editFormPreview: i18n.t("forms.steps.introduction.preview"),
  isOptional: false,
};

const thankYouStep = {
  id: FormStepId.ThankYou,
  type: FormStepType.RequiredEditable,
  component: ThankYou,
  title: i18n.t("forms.steps.thank_you.title"),
  editFormPreview: i18n.t("forms.steps.thank_you.preview"),
  isOptional: false,
};

/**
 * Parses steps for a company onboarding form.
 * @param param0 Options.
 */
const useEditFormSteps = ({
  useSteps,
  formType,
  form,
}: UseEditFormStepsOptions): FormStep[] => {
  const extraSections = form?.extraSections as ExtraSection[];
  const fixedSectionsConfiguration = form?.fixedSectionsConfiguration as FixedSectionsConfiguration;
  const jobForm = form?.job;

  const initialSteps = {
    [FormTypeEnum.ShortApplication]: [],
    [FormTypeEnum.Application]: [introductionStep],
    [FormTypeEnum.Client]: [introductionStep],
  }[formType];

  const baseSteps = useSteps(
    form?.job.role.name,
    fixedSectionsConfiguration,
    extraSections,
    {
      jobForm,
      allowImageUpload: form?.allowImagesUpload ?? false,
      stepMode: UseStepMode.Edition,
      typeForm: FormTypeEnum[formType],
    },
  );

  const finalSteps = {
    [FormTypeEnum.ShortApplication]: [],
    [FormTypeEnum.Application]: [thankYouStep],
    [FormTypeEnum.Client]: [thankYouStep],
  }[formType];

  const steps = useMemo(() => [
    ...initialSteps,
    ...baseSteps,
    ...finalSteps,
  ], [
    initialSteps,
    baseSteps,
    finalSteps,
  ]);

  return steps;
};

export default useEditFormSteps;
