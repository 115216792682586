import React from "react";
import { Stack, Text } from "@chakra-ui/react";
import numeral from "numeral";

import DashboardArrowDownIcon from "settings/theme/icons/DashboardArrowDownIcon";
import DashboardArrowUpIcon from "settings/theme/icons/DashboardArrowUpIcon";

import { GrowthIndicatorProps } from "./types";

const GrowthIndicator: React.FC<GrowthIndicatorProps> = ({
  growthRate,
}) => {
  const growthColor = (
    growthRate === 0
      ? "gray.300"
      : growthRate > 0
        ? "status.success"
        : "status.error"
  );

  const GrowthIndicatorIcon = growthRate !== 0 && (
    growthRate > 0
      ? DashboardArrowUpIcon
      : DashboardArrowDownIcon
  );

  return (
    <Stack
      alignItems="center"
      isInline
    >
      {
        GrowthIndicatorIcon && (
          <GrowthIndicatorIcon
            color={growthColor}
          />
        )
      }

      <Text
        fontFamily="extrabold"
        color={growthColor}
        fontSize="xl"
      >
        {numeral(growthRate).format("0%")}
      </Text>
    </Stack>
  );
};

export default GrowthIndicator;
