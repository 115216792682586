import React from "react";
import {
  Stack,
  Text,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import { FormTypeEnum } from "generated/graphql";
import Image from "components/Images/Image";

import { FormThankYouContentProps } from "./types";

const FormThankYouContent: React.FC<FormThankYouContentProps> = ({
  form,
}) => {
  const [t] = useTranslation();

  const company = form?.job?.publicCompanyInfo;

  const thankYouMessage = ({
    [FormTypeEnum.ShortApplication]: t("forms.steps.thank_you.default"),
    [FormTypeEnum.Application]: form?.applicantThankYouMessage,
    [FormTypeEnum.Client]: form?.clientThankYouMessage,
  })[form?.formType ?? ""];

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      p={[8, 10, 16]}
      spacing={16}
      minH="100vh"
      minW="100vw"
    >
      {
        company?.logoUrl && (
          <Image
            height={["120px", "160px", "200px"]}
            src={company.logoUrl}
            alt={company?.name}
            borderRadius={0}
            width="auto"
          />
        )
      }

      <Stack spacing={8}>
        <Text
          fontSize={["md", "lg", "xl"]}
          whiteSpace="break-spaces"
          fontFamily="heading"
          textAlign="center"
          color="black"
        >
          {thankYouMessage}
        </Text>
      </Stack>
    </Stack>
  );
};

export default FormThankYouContent;
