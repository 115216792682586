import React, { useCallback } from "react";
import { Flex, IconButton, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { CustomHelperProps } from "reactour";

import { REACT_TOUR_APPLICANTS_PROFILE_KEY } from "constants/storage";
import Button from "components/Button";
import CloseIcon from "settings/theme/icons/CloseIcon";

function CustomHelper({
  current,
  content,
  totalSteps,
  gotoStep,
  close,
}: CustomHelperProps): JSX.Element {
  const [t] = useTranslation();

  const handleNext = useCallback(() => {
    gotoStep(Math.min(current + 1, totalSteps - 1));
  }, [
    totalSteps,
    gotoStep,
    current,
  ]);

  const handlePrevious = useCallback(() => {
    gotoStep(Math.max(current - 1, 0));
  }, [
    gotoStep,
    current,
  ]);

  const handleFinish = useCallback(() => {
    localStorage.setItem(
      REACT_TOUR_APPLICANTS_PROFILE_KEY,
      JSON.stringify(true),
    );

    close();
  }, [
    close,
  ]);

  const currentStep = current + 1;
  const isLastStep = currentStep === totalSteps;
  const nextButtonText = isLastStep ? t("buttons.close") : t("buttons.next");

  return (
    <Flex
      position="relative"
      borderRadius="2px"
      flexDir="column"
      bgColor="white"
      maxW="md"
      p={6}
    >
      <IconButton
        aria-label={t("buttons.close")}
        title={t("buttons.close")}
        onClick={handleFinish}
        position="absolute"
        variant="unstyled"
        right={3}
        top={3}
        icon={(
          <CloseIcon
            color="gray.400"
            boxSize={5}
          />
        )}
      />
      {content}

      <Flex
        justifyContent={isLastStep ? "flex-end" : "space-between"}
        alignItems="center"
        w="full"
        mt={4}
      >
        {
          !isLastStep && (
            <Button
              onClick={handleFinish}
              variant="unstyled"
            >
              <Text
                fontSize="small"
                color="gray.400"
              >
                {t("buttons.skip")}
              </Text>
            </Button>
          )
        }

        <Flex
          alignItems="center"
        >
          {
            current !== 0 && (
              <Button
                onClick={handlePrevious}
                variant="unstyled"
              >
                <Text
                  color="primary.200"
                  fontSize="small"
                >
                  {t("buttons.back")}
                </Text>
              </Button>
            )
          }

          <Button
            onClick={isLastStep ? handleFinish : handleNext}
            h="24px"
            ml={4}
            py={1}
            px={2}
          >
            <Text fontSize="small">
              {`${nextButtonText} (${currentStep}/${totalSteps})`}
            </Text>
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default CustomHelper;
