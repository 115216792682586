import React from "react";
import { Flex, Heading, Stack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import Box from "components/Box";

import { ApplicantStatusHistoryCardProps } from "./types";
import StatusUpdateDetails from "./StatusUpdateDetails";

const ApplicantStatusHistoryCard: React.FC<ApplicantStatusHistoryCardProps> = ({
  applicant,
}) => {
  const [t] = useTranslation();

  const statusUpdates = applicant?.statusUpdates ?? [];

  return (
    <Box
      borderRadius={10}
      w="full"
      h="full"
    >
      <Flex
        direction="column"
        h="full"
      >
        <Heading fontSize="md" mb={5}>
          {`${t("applicant_profile.application_history")}:`}
        </Heading>

        <Flex
          position="relative"
          minHeight="sm"
          h="full"
        >
          <Stack
            position="absolute"
            direction="column"
            overflow="auto"
            height="full"
            spacing={4}
            bottom={0}
            right={0}
            left={0}
            top={0}
          >
            {
              statusUpdates.map((statusUpdate) => (
                <StatusUpdateDetails
                  key={statusUpdate.id}
                  statusUpdate={statusUpdate}
                />
              ))
            }
          </Stack>
        </Flex>
      </Flex>
    </Box>
  );
};

export default ApplicantStatusHistoryCard;
