import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  useSimulateSubscriptionOperationLazyQuery,
  SimulateSubscriptionOperationQuery,
  useCreateSubscriptionMutation,
  OperationType,
} from "generated/graphql";
import { createSubscriptionRefetchQueries } from "graphql/mutations/createSubscriptionMutation";
import { onMutationSuccess, onQueryError } from "utils/queryHandlers";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import PaymentModal from "components/Modal/PaymentModal";
import { useModal } from "contexts/modal";
import useToast from "hooks/useToast";

import {
  ModifySubscriptionPaymentData,
  UseModifySubscriptionResult,
  ModifySubscriptionOptions,
} from "./types";
import ModifySubscriptionPaymentInformation from "./Modal/ModifySubscriptionPaymentInformation";
import getModal from "./getModal";

/**
 * Exposes a function that queries for `simulateSubscriptionOperation` and shows a modal asking
 * the user to confirm the operation according to the type.
 *
 * If the user confirms it, it'll open the payment modal to handle payment for the given
 * subscription, and upon confirmation, the `createSubscription` mutation will be executed.
 *
 * The loading state is also exposed.
 *
 * In case the query or mutation fails, a toast will be shown with the error.
 */
const useModifySubscription = (): UseModifySubscriptionResult => {
  const [mutationPayload, setMutationPayload] = useState<ModifySubscriptionOptions>();

  const [showModal, hideModal] = useModal();
  const [showToast] = useToast();
  const [t] = useTranslation();

  const [createSubscription, {
    loading: createSubscriptionLoading,
  }] = useCreateSubscriptionMutation({
    refetchQueries: createSubscriptionRefetchQueries,
    awaitRefetchQueries: true,
  });

  const handleCreateSubscription = useCallback((data: ModifySubscriptionPaymentData) => () => {
    createSubscription({
      variables: data.options,
    })
      .then(() => {
        const message = (
          data.simulateSubscriptionResult.type === OperationType.Downgrade
            ? t("modify_subscription_modal.subscription_downgraded_successfully")
            : t("modify_subscription_modal.subscription_upgraded_successfully")
        );

        onMutationSuccess(message, showToast);

        hideModal();
      })
      .catch((error) => {
        onQueryError(error, showToast);
      })
      .finally(() => {
        setMutationPayload(undefined);
      });
  }, [
    createSubscription,
    hideModal,
    showToast,
    t,
  ]);

  const handleShowPaymentModal = useCallback((data: ModifySubscriptionPaymentData) => () => {
    showModal({
      size: "xl",
      component: PaymentModal,
      componentProps: {
        onConfirm: handleCreateSubscription(data),
        companyId: data.options.companyId,
        paymentInformation: (
          <ModifySubscriptionPaymentInformation
            simulateSubscriptionResult={data.simulateSubscriptionResult}
            options={data.options}
          />
        ),
      },
    });
  }, [
    handleCreateSubscription,
    showModal,
  ]);

  const handleShowConfirmationModal = useCallback((data: SimulateSubscriptionOperationQuery) => {
    const simulateSubscriptionResult = data?.simulateSubscriptionOperation;

    if (!mutationPayload || !simulateSubscriptionResult) {
      return;
    }

    const {
      text: Text,
      ...modal
    } = getModal(simulateSubscriptionResult.type);

    const paymentData: ModifySubscriptionPaymentData = {
      simulateSubscriptionResult,
      options: mutationPayload,
    };

    showModal({
      title: modal.title,
      component: ConfirmationModal,
      componentProps: {
        onConfirm: handleShowPaymentModal(paymentData),
        showDeclineButton: modal.showDeclineButton,
        confirmText: modal.confirmText,
        declineText: modal.declineText,
        hideOnConfirm: false,
        footerContainerProps: {
          w: "full",
        },
        text: (
          <Text
            simulateSubscriptionResult={simulateSubscriptionResult}
            billingType={paymentData.options.billingType}
          />
        ),
      },
    });
  }, [
    handleShowPaymentModal,
    mutationPayload,
    showModal,
  ]);

  const [loadSubscriptionSimulation, {
    loading: subscriptionSimulationLoading,
    refetch: subscriptionSimulationRefetch,
    called: subscriptionSimulationCalled,
  }] = useSimulateSubscriptionOperationLazyQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
    onCompleted: handleShowConfirmationModal,
    onError: (error) => {
      onQueryError(error, showToast);
    },
  });

  const handleModifySubscription = useCallback((options: ModifySubscriptionOptions) => {
    setMutationPayload(options);

    if (!subscriptionSimulationCalled) {
      loadSubscriptionSimulation({
        variables: options,
      });

      return;
    }

    subscriptionSimulationRefetch?.(options);
  }, [
    subscriptionSimulationRefetch,
    subscriptionSimulationCalled,
    loadSubscriptionSimulation,
  ]);

  const isLoading = (
    subscriptionSimulationLoading
    || createSubscriptionLoading
  );

  const payload = useMemo<UseModifySubscriptionResult>(() => [
    handleModifySubscription,
    isLoading,
  ], [
    handleModifySubscription,
    isLoading,
  ]);

  return payload;
};

export default useModifySubscription;
