import React from "react";
import { Box } from "@chakra-ui/react";

import { GetTrackColorsProps, SliderTrackProps } from "components/FormComponents/Slider/types";

const getTrackColors = ({
  colorScheme,
  thumbCount,
  state,
}: GetTrackColorsProps): string => {
  const activeColor = `${colorScheme}.100`;
  const inactiveColor = "gray.100";

  const colors = (
    thumbCount % 2 === 0
      ? [activeColor, inactiveColor]
      : [inactiveColor, activeColor]
  );

  return (
    state.index % 2 === 0
      ? colors[1]
      : colors[0]
  );
};

const SliderTrack: React.FC<SliderTrackProps> = ({
  colorScheme,
  trackProps,
  thumbCount,
  state,
}) => {
  const bgColor = getTrackColors({
    colorScheme,
    thumbCount,
    state,
  });

  return (
    <Box
      {...trackProps}
      bgColor={bgColor}
      borderRadius={10}
      rounded={10}
      h="12px"
    />
  );
};

export default SliderTrack;
