import React, { useEffect } from "react";
import ConditionalWrap from "conditional-wrap";
import { Flex, useColorMode } from "@chakra-ui/react";
import { Helmet } from "react-helmet";
import * as R from "remeda";

import { adminImpersonationSelectHeight } from "components/AdminImpersonation/AdminImpersonationSelect";
import useAdminImpersonationStore from "components/AdminImpersonation/store";
import useResetScrollOnNavigation from "hooks/useResetScrollOnNavigation";
import AdminImpersonation from "components/AdminImpersonation";
import Header, { headerHeight } from "components/Header";
import { appWrapperPrintCss } from "styles/css";
import { useRouteProps } from "contexts/route";
import Footer from "components/Footer";
import i18n from "translations/i18n";

const COLOR_MODE = "light";

/**
 * App wrapper. Can be used to render Header, Footer, etc.
 * @param param0 options
 */
const AppWrapper: React.FC = ({
  children,
}) => {
  const {
    renderAppWrapper,
    showHeader,
    showFooter,
  } = useRouteProps();

  const { setColorMode, colorMode } = useColorMode();

  useEffect(() => {
    if (colorMode === COLOR_MODE) {
      return;
    }

    setColorMode(COLOR_MODE);
  }, [
    colorMode,
    setColorMode,
  ]);

  useResetScrollOnNavigation();

  const htmlAttributes = {
    lang: i18n.language,
  };

  const isAdminImpersonationVisible = useAdminImpersonationStore((store) => store.isVisible);

  const appWrapperMarginTop = R.pipe(
    [
      isAdminImpersonationVisible && adminImpersonationSelectHeight.value,
      showHeader && headerHeight.value,
    ],
    R.reduce((acc, prev) => acc + Number(prev), 0),
  );

  return (
    <>
      <Helmet htmlAttributes={htmlAttributes} />

      {
        showHeader && (
          <>
            <Header />

            <AdminImpersonation />
          </>
        )
      }

      <ConditionalWrap
        condition={renderAppWrapper}
        wrap={(content) => (
          <Flex
            css={appWrapperPrintCss}
            mt={appWrapperMarginTop}
            direction="column"
            p={6}
          >
            {content}
          </Flex>
        )}
      >
        <>
          {children}
        </>
      </ConditionalWrap>

      {
        showFooter && (
          <Footer />
        )
      }
    </>
  );
};

export default AppWrapper;
