import React, { useCallback } from "react";
import { FormProvider, useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Stack } from "@chakra-ui/react";

import FieldTitle from "components/FormComponents/FieldTitle";
import PlusIcon from "settings/theme/icons/PlusIcon";
import Button from "components/Button";

import getFormDefaultValues, { getFormDefaultQuestionsState } from "./getFormDefaultValues";
import { ExtraSectionSchema, ExtraSectionFormProps } from "./types";
import getMutationValues from "./getMutationValues";
import useFormStep from "../hooks/useFormStep";
import QuestionForm from "./QuestionForm";

const ExtraSectionForm: React.FC<ExtraSectionFormProps> = ({
  extraSection,
  formResponse,
}) => {
  const [t] = useTranslation();

  const form = useFormStep<ExtraSectionSchema>({
    formOptions: {
      defaultValues: getFormDefaultValues({
        formResponse,
        extraSection,
      }),
    },
    overrideGetValues: ({
      getValues,
    }) => () => {
      const values = getValues();

      return getMutationValues(extraSection, values);
    },
  });

  const {
    remove,
    append,
    fields,
  } = useFieldArray({
    name: "answers",
    control: form.control,
  });

  const handleAppendForm = useCallback(() => {
    append({
      questions: getFormDefaultQuestionsState({
        extraSection,
      }),
    });
  }, [
    extraSection,
    append,
  ]);

  const handleRemoveForm = useCallback((index: number) => () => {
    remove(index);
  }, [
    remove,
  ]);

  const shouldShowQuestionFormTitle = (
    extraSection.isMultiple
    && fields.length > 1
  );

  return (
    <FormProvider {...form}>
      <Stack spacing={6}>
        {
          extraSection.customIntro && (
            <FieldTitle
              whiteSpace="break-spaces"
              color="gray.400"
              fontSize="sm"
            >
              {extraSection.customIntro}
            </FieldTitle>
          )
        }

        <Stack spacing={10}>
          {
            fields.map((field, index) => {
              const canRemoveForm = !!(
                extraSection.isMultiple
                && fields.length > 1
                && index >= extraSection.minimumNumber
              );

              return (
                <QuestionForm
                  showTitle={shouldShowQuestionFormTitle}
                  questions={extraSection.extraQuestions}
                  extraSectionName={extraSection.name}
                  removeForm={handleRemoveForm}
                  canRemoveForm={canRemoveForm}
                  fieldIndex={index}
                  key={field.id}
                  field={field}
                />
              );
            })
          }
        </Stack>

        {
          extraSection.isMultiple && (
            <Button
              rightIcon={(<PlusIcon />)}
              onClick={handleAppendForm}
              variant="outline"
              fontSize="sm"
              size="sm"
              w="xs"
            >
              {t("forms.steps.extra_section.add_extra_form", {
                name: extraSection.name,
              })}
            </Button>
          )
        }
      </Stack>
    </FormProvider>
  );
};

export default ExtraSectionForm;
