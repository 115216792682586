import yup from "settings/yup";

const requirementsSchema = yup.object().shape({
  fulfilledRequirements: yup
    .array()
    .of(
      yup
        .string()
        .required(),
    ),
});

export interface RequirementsSchema {
  fulfilledRequirements: string[];
}

export default requirementsSchema;
