import { Styles } from "react-select";

import theme from "settings/theme";

const styles: Partial<Styles> = {
  menu: (prev) => ({
    ...prev,
    zIndex: 10,
  }),
  control: (prev) => ({
    ...prev,
    cursor: "pointer",
    borderColor: theme.colors.gray[100],
  }),
  valueContainer: (prev) => ({
    ...prev,
    backgroundColor: theme.colors.gray[100],
  }),
  indicatorsContainer: (prev) => ({
    ...prev,
    backgroundColor: theme.colors.gray[100],
  }),
  dropdownIndicator: (prev) => ({
    ...prev,
    color: theme.colors.black,
  }),
  option: (prev) => ({
    ...prev,
    cursor: "pointer",
  }),
};

export default styles;
