import { useEffect, useMemo, useState } from "react";
import { useMount, useUpdateEffect } from "react-use";
import { useDebouncedCallback } from "use-debounce";
import { yupResolver } from "@hookform/resolvers";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";

import { landingPagesTemplatesData, landingPageTemplates } from "components/LandingPage/Templates/landingPagesTemplatesData";
import { createOrUpdateLandingPageRefetchQueries } from "graphql/mutations/createOrUpdateLandingPageMutation";
import landingPageSchema, { LandingPageSchema } from "settings/yup/schemas/onboarding/landingPageSchema";
import { parseBase64ImageFromFile, parseImageFileFromSource } from "utils/base64";
import { LandingPageTemplate } from "components/LandingPage/Templates/types";
import { useCreateOrUpdateLandingPageMutation } from "generated/graphql";
import { onMutationSuccess, onQueryError } from "utils/queryHandlers";
import useToast from "hooks/useToast";

import { UseConfigureLandingPageHandlerOptions, UseConfigureLandingPageHandlerResult } from "../types";
import useConfigureLandingPageStore from "../store";

/**
 * Handles side-effect complexity for the configure landing page form.
 */
const useConfigureLandingPageHandler = ({
  landingPageLayoutLabel,
  defaultValues,
  jobId,
}: UseConfigureLandingPageHandlerOptions): UseConfigureLandingPageHandlerResult => {
  const [showToast] = useToast();
  const [t] = useTranslation();

  const [hasInitialized, setHasInitialized] = useState(false);

  const [createOrUpdateLandingPage, {
    loading: loadingCreateOrUpdateLandingPage,
  }] = useCreateOrUpdateLandingPageMutation({
    refetchQueries: createOrUpdateLandingPageRefetchQueries,
    awaitRefetchQueries: true,
  });

  const {
    setLandingPage,
    landingPage,
  } = useConfigureLandingPageStore((store) => ({
    setLandingPage: store.setLandingPage,
    landingPage: store.landingPage,
  }));

  const form = useForm<LandingPageSchema>({
    resolver: yupResolver(landingPageSchema),
    mode: "onChange",
  });

  const {
    formState,
    getValues,
    trigger,
    watch,
    reset,
  } = form;

  const formValues = watch();

  const handleUpdateStore = useDebouncedCallback(
    async () => {
      const headerImageUrl = (
        formValues.headerImage
          ? await parseBase64ImageFromFile(formValues.headerImage)
          : landingPage?.headerImageUrl
      );

      const logoUrl = (
        formValues.logo
          ? await parseBase64ImageFromFile(formValues.logo)
          : landingPage?.logoUrl
      );

      setLandingPage({
        ...landingPage,
        jobDescription: formValues.jobDescription,
        buttonText: formValues.buttonText,
        subtitle: formValues.subtitle,
        color: formValues.color,
        title: formValues.title,
        headerImageUrl,
        logoUrl,
      });
    },
    100,
  );

  const handleCreateOrUpdateLandingPage = useDebouncedCallback(
    async () => {
      if (
        !landingPage?.layoutLabel
        || !formState.isValid
        || !formState.isDirty
        || !jobId
      ) {
        return;
      }

      /**
       * Parses one image field for the mutation payload.
       *
       * If we're creating a new landing page and the image hasn't been selected,
       * the fallback will be sent (if defined) since the field is required.
       *
       * Otherwise, the field value will be added only if it's touched, returning
       * `undefined` if it isn't so that we don't upload the image every time.
       */
      const parseImageField = async (
        fieldName: keyof LandingPageSchema,
        fallbackFieldName = "",
      ): Promise<File | undefined> => {
        const hasDefaultValue = !!defaultValues?.[fallbackFieldName];
        const fallbackValue = landingPage?.[fallbackFieldName];

        const isDirty = formState.dirtyFields[fieldName];
        const value = formValues[fieldName] as File;

        if (!value && !hasDefaultValue && fallbackValue) {
          return parseImageFileFromSource(fallbackValue);
        }

        if (isDirty && value) {
          return value;
        }

        return undefined;
      };

      const headerImage = await parseImageField("headerImage", "headerImageUrl");
      const logo = await parseImageField("logo");

      const payload = {
        jobDescription: formValues.jobDescription,
        layoutLabel: landingPage.layoutLabel,
        buttonText: formValues.buttonText,
        subtitle: formValues.subtitle,
        color: formValues.color,
        title: formValues.title,
        headerImage,
        jobId,
        logo,
      };

      createOrUpdateLandingPage({
        variables: {
          params: payload,
        },
      })
        .then(() => {
          onMutationSuccess(t("actions.information_updated"), showToast);

          reset(getValues(), {
            touched: false,
            isValid: true,
          });
        })
        .catch((error) => {
          onQueryError(error, showToast);
        });
    },
    1000,
  );

  /**
   * When initializing the page, we need to read landingPageLayoutLabel the URL
   * and use it to initialize our store and form state.
   */
  useMount(async () => {
    if (hasInitialized) {
      return;
    }

    const template = (
      landingPagesTemplatesData[landingPageLayoutLabel as string]
      ?? landingPageTemplates[0]
    ) as LandingPageTemplate;

    const getFieldValue = (name: keyof LandingPageTemplate): string => (
      defaultValues?.[name] ?? template[name]
    );

    setLandingPage({
      ...template,
      jobDescription: getFieldValue("jobDescription"),
      headerImageUrl: getFieldValue("headerImageUrl"),
      buttonText: getFieldValue("buttonText"),
      subtitle: getFieldValue("subtitle"),
      logoUrl: getFieldValue("logoUrl"),
      title: getFieldValue("title"),
      color: getFieldValue("color"),
    });

    reset({
      jobDescription: getFieldValue("jobDescription"),
      buttonText: getFieldValue("buttonText"),
      subtitle: getFieldValue("subtitle"),
      title: getFieldValue("title"),
      color: getFieldValue("color"),
      headerImage: (
        defaultValues?.headerImageUrl
          ? await parseImageFileFromSource(defaultValues.headerImageUrl)
          : undefined
      ),
      logo: (
        defaultValues?.logoUrl
          ? await parseImageFileFromSource(defaultValues.logoUrl)
          : undefined
      ),
    });

    setHasInitialized(true);
  });

  /**
   * When resetting the form values to initialize it, react-hook-form @`6.9.2` does not
   * compute the `formState.isValid` property. Thus we have to manually check for it
   * after initializing the form.
   *
   * The validation should only be done in case the whole form is filled, to avoid having
   * fields that aren't touched showing errors when the form mounts.
   */
  useUpdateEffect(() => {
    const values = getValues();

    const shouldValidate = (
      Object.values(values).every((value) => !!value)
      && hasInitialized
    );

    if (shouldValidate) {
      trigger();
    }
  }, [
    hasInitialized,
    getValues,
    trigger,
  ]);

  /**
   * Every time the form validation changes or mutation loading state changes,
   * we need to update the store with the new `isValid` & `loading`.
   */
  useEffect(() => {
    useConfigureLandingPageStore.setState({
      isLoading: loadingCreateOrUpdateLandingPage,
      isValid: formState.isValid,
    });
  }, [
    loadingCreateOrUpdateLandingPage,
    formState.isValid,
  ]);

  /**
   * Every time the form state changes, we need to update the landing page data in the store.
   */
  useEffect(() => {
    handleUpdateStore.callback();
  }, [
    handleUpdateStore,
    formValues.jobDescription,
    formValues.headerImage,
    formValues.buttonText,
    formValues.subtitle,
    formValues.color,
    formValues.title,
    formValues.logo,
  ]);

  /**
   * Every time the form state changes, we need to execute the `createOrUpdateLandingPage`
   * mutation in order to sync them form state.
   */
  useEffect(() => {
    handleCreateOrUpdateLandingPage.callback();
  }, [
    handleCreateOrUpdateLandingPage,
    formValues.jobDescription,
    formValues.headerImage,
    formValues.buttonText,
    formValues.subtitle,
    formState.isDirty,
    formValues.color,
    formValues.title,
    formValues.logo,
  ]);

  const payload = useMemo<UseConfigureLandingPageHandlerResult>(() => [
    form,
    !hasInitialized,
  ], [
    form,
    hasInitialized,
  ]);

  return payload;
};

export default useConfigureLandingPageHandler;
