import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ModalBody,
  Stack,
} from "@chakra-ui/react";
import {
  CardElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";

import { createCreditCardRefetchQueries } from "graphql/mutations/createCreditCardMutation";
import { onMutationSuccess, onQueryError } from "utils/queryHandlers";
import { useCreateCreditCardMutation } from "generated/graphql";
import Button from "components/Button";
import useToast from "hooks/useToast";

import { AddCreditCardFormProps } from "./types";

const AddCreditCardForm: React.FC<AddCreditCardFormProps> = ({
  paymentInformation,
  onSuccess,
  hideModal,
  companyId,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const [showToast] = useToast();
  const [t] = useTranslation();

  const elements = useElements();
  const stripe = useStripe();

  const [createCreditCard] = useCreateCreditCardMutation({
    refetchQueries: createCreditCardRefetchQueries,
    awaitRefetchQueries: true,
  });

  const handleSubmit = useCallback(async (): Promise<void> => {
    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    if (!cardElement) {
      showToast({
        title: t("errors.please_fill_all_fields"),
        status: "error",
      });

      return;
    }

    setIsLoading(true);

    const payload = await stripe.createToken(cardElement);

    if (payload?.error || !payload?.token?.id) {
      showToast({
        title: payload?.error?.message || t("errors.an_error_has_occurred"),
        status: "error",
      });

      setIsLoading(false);
      return;
    }

    const cardSource = payload.token.id;

    createCreditCard({
      variables: {
        cardSource,
        companyId,
      },
    })
      .then(() => {
        onMutationSuccess(t("payment_modal.credit_card_added_successfully"), showToast);

        onSuccess?.();
      })
      .catch((error) => {
        onQueryError(error, showToast);
      })
      .finally(() => {
        hideModal();
      });
  }, [
    createCreditCard,
    companyId,
    hideModal,
    onSuccess,
    showToast,
    elements,
    stripe,
    t,
  ]);

  return (
    <ModalBody>
      <Stack
        spacing={6}
        py={4}
      >
        {
          paymentInformation && (
            paymentInformation
          )
        }

        <Stack
          bgColor="gray.100"
          rounded="lg"
          spacing={8}
          p={4}
        >
          <CardElement
            options={{
              hidePostalCode: true,
            }}
          />

          <Button
            isDisabled={!stripe || !elements}
            onClick={handleSubmit}
            isLoading={isLoading}
            fontSize="sm"
            w="full"
          >
            {t("buttons.confirm")}
          </Button>
        </Stack>
      </Stack>
    </ModalBody>
  );
};

export default AddCreditCardForm;
