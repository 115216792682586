/**
 * Defines the threshold to show alert color when showing remaining tokens.
 */
const TOKEN_ALERT_THRESHOLD = 5;

interface TokenIndicatorColors {
  textColor: string;
  bgColor: string;
}

/**
 * Parses the indicator color for the remaining tokens of a job.
 * @param numberOfAvailableTokens The number of available tokens for a job.
 */
const getTokenIndicatorColors = (
  numberOfAvailableTokens: number | null,
): TokenIndicatorColors => {
  if (
    numberOfAvailableTokens === null
    || numberOfAvailableTokens >= TOKEN_ALERT_THRESHOLD
  ) {
    return {
      bgColor: "primary.500",
      textColor: "black",
    };
  }

  return {
    textColor: "status.error",
    bgColor: "status.error",
  };
};

export default getTokenIndicatorColors;
