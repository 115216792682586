import create from "zustand";

import { FormStoreState } from "./types";

const useFormStore = create<FormStoreState>(() => ({
  isValid: true,
  isDirty: false,
  isFormLoading: true,
  form: undefined,
  getValues: undefined,
  errors: undefined,
  watch: undefined,
  trigger: undefined,
  shortCode: undefined,
}));

export default useFormStore;
