import React from "react";
import {
  Stack,
  Text,
} from "@chakra-ui/react";

import parseExtraSectionGroupTitle from "utils/forms/parseExtraSectionGroupTitle";
import { avoidPageBreakAfterCss } from "styles/css";
import ListItem from "components/ListItem";

import { AnswerGroup, ExtraAnswerDataProps } from "./types";
import { makeAnswerItemStackStyles } from "./styles";

const ExtraAnswerData: React.FC<ExtraAnswerDataProps> = ({
  answerGroupsCount,
  extraAnswer,
  position,
}) => {
  const answerGroups: AnswerGroup[] = (
    (
      Array.isArray(extraAnswer?.answerGroups)
      && extraAnswer.answerGroups.length > 0
    )
      ? extraAnswer?.answerGroups
      : [{
        answers: extraAnswer?.answers ?? [],
      }]
  );

  const shouldShowAnswerGroupTitle = answerGroups.length > 1;

  const stackStyles = makeAnswerItemStackStyles({
    answerGroupsCount,
    position,
  });

  return (
    <Stack {...stackStyles}>
      <Text
        fontFamily="bold"
        fontSize="md"
        mb={3}
        css={avoidPageBreakAfterCss}
      >
        {`${extraAnswer.sectionName}:`}
      </Text>

      {
        answerGroups.map((answerGroup, index) => (
          <Stack
            key={String(index)}
            mb={3}
          >
            {
              shouldShowAnswerGroupTitle && (
                <Text
                  fontFamily="bold"
                  fontSize="sm"
                  css={avoidPageBreakAfterCss}
                >
                  {parseExtraSectionGroupTitle(extraAnswer.sectionName, index)}
                </Text>
              )
            }

            {
              answerGroup.answers.map((answerData, answerIndex) => (
                <ListItem
                  title={answerData.questionText}
                  value={answerData.answer}
                  key={String(answerIndex)}
                  containerProps={{
                    flexWrap: "wrap",
                  }}
                />
              ))
            }
          </Stack>
        ))
      }
    </Stack>
  );
};

export default ExtraAnswerData;
