import appHostnames from "./appHostnames";

const SANDBOX_KEY = "pk_test_51EhIMdGr2Ftu5u9lcImfD4Ryxuf5YhKvHGOpIb74gaOnm8rf7Xi7EUzYgAuMc8nIui0HaCQTVNx8n8u1uoefCaoo00OHdmGNOS";

const PRODUCTION_KEY = "pk_live_51EhIMdGr2Ftu5u9l0JJsGVbmEfvVuKTZ8USUY7r9DaPs4mf5y1wwn8co8bEyrX1e7v4xFSVpqOVcn0EhqlcXXfsX00E48pG2GE";

const stripeKeys = [
  { // development
    hostname: "localhost",
    key: SANDBOX_KEY,
  },
  { // homologation
    hostname: appHostnames.homologationHostname,
    key: SANDBOX_KEY,
  },
  { // beta
    hostname: "beta.milimatch.com",
    key: SANDBOX_KEY,
  },
  { // production
    hostname: "app.milimatch.com",
    key: PRODUCTION_KEY,
  },
  { // production
    hostname: "aware.milimatch.com",
    key: PRODUCTION_KEY,
  },
];

/**
 * Defines the public key used for adding credit cards.
 */
export const stripeKey = stripeKeys.find((value) => (
  value.hostname === appHostnames.hostname
))?.key ?? "";
