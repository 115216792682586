import { FormTypeEnum } from "generated/graphql";
import i18n from "translations/i18n";

/**
 * Returns a modal title according to a given form type.
 * @param formType The form type.
 */
const getModalTitle = (formType: FormTypeEnum): string => ({
  [FormTypeEnum.ShortApplication]: i18n.t("send_assessment.send_short_application"),
  [FormTypeEnum.Application]: i18n.t("send_assessment.send_application"),
  [FormTypeEnum.Client]: i18n.t("send_assessment.send_assessment_to_client"),
})[formType];

export default getModalTitle;
