import flagAmericanExpress from "assets/images/creditCardFlags/american_express.png";
import flagDinnersClub from "assets/images/creditCardFlags/dinners_club.png";
import flagMasterCard from "assets/images/creditCardFlags/master_card.png";
import flagDiscover from "assets/images/creditCardFlags/discover.png";
import flagUnknown from "assets/images/creditCardFlags/unknown.png";
import flagVisa from "assets/images/creditCardFlags/visa.png";
import flagJcb from "assets/images/creditCardFlags/jcb.png";

/**
 * Defines possible credit card brands.
 */
const creditCardBrands = {
  AMERICAN_EXPRESS: "American Express",
  DINNERS_CLUB: "Diners Club",
  DISCOVER: "Discover",
  JCB: "JCB",
  MASTER_CARD: "MasterCard",
  VISA: "Visa",
  UNKNOWN: "Unknown",
};

/**
 * Returns a credit card flag image for a given brand.
 * @param brand The brand.
 */
export const getCardFlagByBrand = (brand: string): string => ({
  [creditCardBrands.AMERICAN_EXPRESS]: flagAmericanExpress,
  [creditCardBrands.DINNERS_CLUB]: flagDinnersClub,
  [creditCardBrands.MASTER_CARD]: flagMasterCard,
  [creditCardBrands.DISCOVER]: flagDiscover,
  [creditCardBrands.UNKNOWN]: flagUnknown,
  [creditCardBrands.VISA]: flagVisa,
  [creditCardBrands.JCB]: flagJcb,
})[brand] || flagUnknown;
