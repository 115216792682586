/**
 * Defines size values for CardFit container size variants.
 */
export const fitContainerSizes = {
  md: {
    height: 150,
    width: 150,
  },
  lg: {
    width: 160,
    height: 160,
  },
};

/**
 * Defines the css for CardFit.
 */
export const cardFitCss = {
  clipPath: "circle(60% at 65% 40%)",
};
