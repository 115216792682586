import create from "zustand";

import { ConfigureLandingPageStoreState } from "./types";

const useConfigureLandingPageStore = create<ConfigureLandingPageStoreState>((set) => ({
  isValid: false,
  isLoading: false,
  landingPage: undefined,
  setLandingPage: (value) => set({
    landingPage: value,
  }),
}));

export default useConfigureLandingPageStore;
