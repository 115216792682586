import yup from "settings/yup";

const extraSectionSchema = yup.object().shape({
  minimumNumber: yup
    .number()
    .required(),
  customIntro: yup
    .string(),
});

export interface ExtraSectionSchema {
  minimumNumber: number;
  customIntro: string;
}

export default extraSectionSchema;
