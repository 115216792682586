import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useGetOrCreateFormLinkMutation } from "generated/graphql";
import { onQueryError } from "utils/queryHandlers";
import useToast from "hooks/useToast";

import {
  GetOrCreateFormLinkOptions,
  UseFormLinkResult,
  FormLink,
} from "./types";

/**
 * Exposes a function that mutates for `getOrCreateFormLink` and returns the result.
 * Any error is handled internally, so the promise will never reject.
 * The mutation loading state is also exposed.
 */
const useFormLink = (): UseFormLinkResult => {
  const [showToast] = useToast();
  const [t] = useTranslation();

  const [getOrCreateFormLink, {
    loading,
  }] = useGetOrCreateFormLinkMutation();

  const getFormLink = useCallback(
    (options: GetOrCreateFormLinkOptions) => new Promise<FormLink>((resolve) => {
      getOrCreateFormLink({
        variables: {
          formType: options.formType,
          formId: options.formId,
        },
      })
        .then((response) => {
          const formLink = response?.data?.getOrCreateFormLink;

          if (!formLink) {
            showToast({
              title: t("errors.an_error_has_occurred"),
              status: "error",
            });

            return;
          }

          resolve(formLink);
        })
        .catch((error) => {
          onQueryError(error, showToast);
          resolve(undefined);
        });
    }),
    [
      getOrCreateFormLink,
      showToast,
      t,
    ],
  );

  const payload = useMemo<UseFormLinkResult>(() => [
    getFormLink,
    loading,
  ], [
    getFormLink,
    loading,
  ]);

  return payload;
};

export default useFormLink;
