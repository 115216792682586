import React, { useEffect, useState } from "react";
import * as R from "remeda";

import { parseImageFilesFromSources } from "utils/base64";
import Loading from "components/Loading";

import useApplicantFormResponse from "../hooks/useFormResponse/useApplicantFormResponse";
import PersonalStoryForm from "./PersonalStoryForm";
import { BasePersonalStoryProps } from "./types";

const BasePersonalStory: React.FC<BasePersonalStoryProps> = ({
  isRequired,
}) => {
  const [pictureFiles, setPictureFiles] = useState<File[]>([]);
  const [isLoadingFiles, setIsLoadingFiles] = useState(true);

  const [formResponse, loading] = useApplicantFormResponse();

  /**
   * Initializes images in form values.
   */
  useEffect(() => {
    if (loading || !isLoadingFiles) {
      return;
    }

    const pictures = formResponse?.pictures ?? [];

    if (pictures.length <= 0) {
      setIsLoadingFiles(false);
      return;
    }

    parseImageFilesFromSources(pictures)
      .then((files) => {
        setPictureFiles(files);
      })
      .catch(R.noop)
      .finally(() => {
        setIsLoadingFiles(false);
      });
  }, [
    formResponse,
    isLoadingFiles,
    loading,
  ]);

  if (isLoadingFiles || loading) {
    return (
      <Loading />
    );
  }

  return (
    <PersonalStoryForm
      pictureFiles={pictureFiles}
      formResponse={formResponse}
      isRequired={isRequired}
    />
  );
};

export default BasePersonalStory;
