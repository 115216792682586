import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Stack } from "@chakra-ui/react";

import Box from "components/Box";

import QuestionCardField from "./QuestionCardField";
import { QuestionCardProps } from "./types";

const QuestionCard: React.FC<QuestionCardProps> = ({
  isDisabled,
  children,
  fields,
  title,
}) => {
  const [t] = useTranslation();

  const headerTextProps = useMemo(() => ({
    fontFamily: "bold",
    fontSize: "sm",
    color: (
      isDisabled
        ? "gray.300"
        : ""
    ),
  }), [
    isDisabled,
  ]);

  return (
    <Box
      w="full"
      h="auto"
    >
      <QuestionCardField
        label={{
          text: title,
          textProps: headerTextProps,
        }}
        value={{
          text: t("buttons.display"),
          textProps: headerTextProps,
        }}
      />

      {
        fields.map((field, index) => (
          <QuestionCardField
            {...field}
            isLast={index === (fields.length - 1)}
            isDisabled={isDisabled}
            key={String(index)}
            fieldIndex={index}
          />
        ))
      }

      {
        children && (
          <Stack mt={6}>
            {children}
          </Stack>
        )
      }
    </Box>
  );
};

export default QuestionCard;
