import React, { useCallback } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Stack } from "@chakra-ui/react";

import ColorPickerInput from "components/FormComponents/ColorPickerInput";
import Input from "components/FormComponents/Input";
import { TEXT_INPUT_LENGTH } from "constants/forms";
import UploadBox from "components/UploadBox";
import Box from "components/Box";

import useConfigureLandingPageStore from "../store";

const LandingPageStyleCard: React.FC = () => {
  const [t] = useTranslation();

  const landingPage = useConfigureLandingPageStore((store) => store.landingPage);

  const {
    setValue,
    register,
    control,
    errors,
  } = useFormContext();

  const onCompanyLogoUpload = useCallback((files) => {
    setValue("logo", files?.[0], {
      shouldValidate: true,
      shouldDirty: true,
    });
  }, [setValue]);

  const onHeaderImageUpload = useCallback((files) => {
    setValue("headerImage", files?.[0], {
      shouldValidate: true,
      shouldDirty: true,
    });
  }, [setValue]);

  register({
    name: "headerImage",
  });

  register({
    name: "logo",
  });

  return (
    <Box
      w="full"
      h="full"
      title={t("company_onboarding.steps.configure_landing_page.style")}
      titleProps={{
        textTransform: "uppercase",
        fontFamily: "bold",
        fontSize: "xs",
      }}
    >
      <Stack
        spacing={6}
        h="full"
      >
        <UploadBox
          subtitle={t("landing_pages_templates.company_logo_recommended_resolution")}
          title={`${t("landing_pages_templates.company_logo")}*`}
          defaultImageSrc={landingPage?.logoUrl}
          onUpload={onCompanyLogoUpload}
          ensureContrast
          boxSize="xxs"
          w="md"
        />

        <UploadBox
          subtitle={t("landing_pages_templates.background_image_recommended_resolution")}
          title={t("landing_pages_templates.background_image")}
          defaultImageSrc={landingPage?.headerImageUrl}
          onUpload={onHeaderImageUpload}
          boxSize="xxs"
          w="md"
        />

        <Stack
          spacing={3}
          isInline
        >
          <Controller
            as={Input}
            name="buttonText"
            control={control}
            errors={errors}
            maxLength={TEXT_INPUT_LENGTH}
            title={`${t("landing_pages_templates.button_text")}*`}
          />

          <Controller
            as={ColorPickerInput}
            name="color"
            control={control}
            errors={errors}
            title={t("landing_pages_templates.color")}
          />
        </Stack>
      </Stack>
    </Box>
  );
};

export default LandingPageStyleCard;
