import React from "react";
import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";

import useCompanyOnboardingFormEffects from "components/Forms/CompanyOnboardingForm/hooks/useCompanyOnboardingFormEffects";
import useCompanyOnboardingForm from "components/Forms/CompanyOnboardingForm/hooks/useCompanyOnboardingForm";
import requiredTextSchema, { RequiredTextSchema } from "settings/yup/schemas/onboarding/requiredTextSchema";
import useCompanyOnboardingFormStore from "components/Forms/CompanyOnboardingForm/store";
import Textarea from "components/FormComponents/Textarea";
import { FormTypeEnum } from "generated/graphql";
import Box from "components/Box";

const ThankYou: React.FC = () => {
  const [t] = useTranslation();

  const {
    applicantThankYouMessage,
    clientThankYouMessage,
    formType,
  } = useCompanyOnboardingFormStore((store) => ({
    applicantThankYouMessage: store.form?.applicantThankYouMessage,
    clientThankYouMessage: store.form?.clientThankYouMessage,
    formType: store.formType,
  }));

  const form = useCompanyOnboardingForm<RequiredTextSchema>({
    schema: requiredTextSchema,
    formOptions: {
      defaultValues: {
        text: (
          formType === FormTypeEnum.Application
            ? applicantThankYouMessage
            : clientThankYouMessage
        ) ?? "",
      },
    },
  });

  useCompanyOnboardingFormEffects({
    fields: ["text"],
    form,
    overrideGetValues: ({
      getValues,
    }) => {
      const values = getValues();

      if (!values.text) {
        return {};
      }

      if (formType === FormTypeEnum.Application) {
        return {
          applicantThankYouMessage: values.text,
        };
      }

      return {
        clientThankYouMessage: values.text,
      };
    },
  });

  return (
    <Box
      w="full"
      h="auto"
      title={t("forms.steps.thank_you.question_title")}
      wrapperProps={{
        mb: 0,
      }}
      titleProps={{
        fontFamily: "bold",
      }}
    >
      <Controller
        as={Textarea}
        name="text"
        control={form.control}
        errors={form.errors}
        rows={5}
      />
    </Box>
  );
};

export default ThankYou;
