import * as R from "remeda";

import { SelectOption } from "components/FormComponents/Select/types";

import { Companies } from "./types";

/**
 * Parses companies into select options.
 * @param userCompanyId The user's company id.
 * @param companies The companies fetched from the query.
 */
const getSelectOptions = (
  userCompanyId: number | undefined,
  companies: Companies | undefined,
): SelectOption[] => R.pipe(
  companies ?? [],
  R.filter((company) => company.id !== userCompanyId),
  R.sortBy((company) => company.id),
  R.map((company): SelectOption => ({
    label: `${company.id} - ${company.name} (${company.primaryManager?.user?.email})`,
    value: company.id,
  })),
);

export default getSelectOptions;
