import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  ModalFooter,
  ModalBody,
  Stack,
  Text,
} from "@chakra-ui/react";

import { ModalComponentProps } from "contexts/modal/types";
import useWizardStore from "components/Wizard/store";
import { ROOT_PAGE_PATH } from "routes";
import Button from "components/Button";

const CustomizeCompanyModal: React.FC<ModalComponentProps> = ({
  hideModal,
}) => {
  const [t] = useTranslation();
  const history = useHistory();

  const navigateToNextStep = useWizardStore((store) => store.navigateToNextStep);

  const handleSkipCustomization = useCallback(() => {
    history.push(ROOT_PAGE_PATH);
    hideModal();
  }, [
    hideModal,
    history,
  ]);

  const handleCustomizeCompany = useCallback(() => {
    navigateToNextStep();
    hideModal();
  }, [
    navigateToNextStep,
    hideModal,
  ]);

  return (
    <>
      <ModalBody>
        <Stack
          spacing={5}
          my={3}
        >
          <Text fontSize="sm">
            {t("company_onboarding.steps.select_role.customize_company_confirmation")}
          </Text>

          <Text
            fontFamily="bold"
            fontSize="xs"
          >
            {t("company_onboarding.steps.select_role.customize_company_confirmation_footer")}
          </Text>
        </Stack>
      </ModalBody>

      <ModalFooter>
        <Stack
          spacing={3}
          isInline
        >
          <Button
            onClick={handleSkipCustomization}
            variant="outline"
            fontSize="sm"
            w="full"
          >
            {t("buttons.set_default")}
          </Button>

          <Button
            onClick={handleCustomizeCompany}
            fontSize="sm"
            w="full"
          >
            {t("buttons.customize_it")}
          </Button>
        </Stack>
      </ModalFooter>
    </>
  );
};

export default CustomizeCompanyModal;
