import React, { useMemo } from "react";
import { SimpleGrid, Wrap } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import WrapItem from "components/WrapItem";
import { StatusUpdateEnum } from "generated/graphql";

import { DashboardBadgeColorEnum, DashboardComponentProps } from "./types";
import ApplicationsOverviewCard from "./Cards/ApplicationsOverviewCard";
import SubscriptionsSummaryCard from "./Cards/SubscriptionsSummaryCard";
import StatusUpdatesCountCard from "./Cards/StatusUpdatesCountCard";
import RemainingTokensCard from "./Cards/RemainingTokensCard";
import { getCurrentWeekPeriod } from "./periods";

const DashboardManager: React.FC<DashboardComponentProps> = ({
  comparisonPeriod,
  selectedJobId,
  companyId,
  period,
}) => {
  const [t] = useTranslation();

  const currentWeekPeriod = useMemo(() => getCurrentWeekPeriod(), []);

  return (
    <>
      <Wrap spacing={5}>
        <WrapItem>
          <StatusUpdatesCountCard
            isManager
            badgeColor={DashboardBadgeColorEnum.Stage1}
            title={t("dashboard.dashboard.applicants_applied")}
            subtitle={t("dashboard.dashboard.this_month")}
            comparisonPeriod={comparisonPeriod}
            jobId={selectedJobId}
            period={period}
            statuses={[
              StatusUpdateEnum.AwaitingReview,
            ]}
          />
        </WrapItem>

        <WrapItem>
          <StatusUpdatesCountCard
            isManager
            badgeColor={DashboardBadgeColorEnum.Stage2}
            title={t("dashboard.dashboard.applicants_interviewed")}
            subtitle={t("dashboard.dashboard.this_month")}
            comparisonPeriod={comparisonPeriod}
            jobId={selectedJobId}
            period={period}
            statuses={[
              StatusUpdateEnum.OfferMade,
              StatusUpdateEnum.Rejected,
            ]}
          />
        </WrapItem>

        <WrapItem>
          <StatusUpdatesCountCard
            isManager
            badgeColor={DashboardBadgeColorEnum.Stage4}
            title={t("dashboard.dashboard.applicants_hired")}
            subtitle={t("dashboard.dashboard.this_month")}
            comparisonPeriod={comparisonPeriod}
            jobId={selectedJobId}
            period={period}
            statuses={[
              StatusUpdateEnum.Hired,
            ]}
          />
        </WrapItem>

        <WrapItem>
          <RemainingTokensCard
            showAddTokensButton
          />
        </WrapItem>
      </Wrap>

      <Wrap spacing={5}>
        <WrapItem>
          <SubscriptionsSummaryCard
            companyId={companyId}
          />
        </WrapItem>

        <WrapItem>
          <SimpleGrid
            gridColumn={[null, null, null, "span 3"]}
            columns={[1, null, 2]}
            h="full"
            w="full"
            gap={5}
          >
            <ApplicationsOverviewCard
              title={t("dashboard.dashboard.applications_overview_by_week")}
              subtitle={t("dashboard.dashboard.period_filter.last_7_days")}
              showPeriodSelector={false}
              period={currentWeekPeriod}
              jobId={selectedJobId}
            />

            <ApplicationsOverviewCard
              title={t("dashboard.dashboard.applications_overview_by_month")}
              jobId={selectedJobId}
            />
          </SimpleGrid>
        </WrapItem>
      </Wrap>
    </>
  );
};

export default DashboardManager;
