import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  AvatarBadge,
  MenuButton,
  MenuList,
  MenuItem,
  Avatar,
  Stack,
  Menu,
  Box,
  Text,
} from "@chakra-ui/react";

import NotificationIcon from "settings/theme/icons/NotificationIcon";
import SettingsIcon from "settings/theme/icons/SettingsIcon";
import { DASHBOARD_PAGE_LOCATION } from "routes/locations";
import useSendAssessment from "hooks/useSendAssessment";
import useConfigureForm from "hooks/useConfigureForm";
import LogoIcon from "settings/theme/icons/LogoIcon";
import useNavigate from "hooks/useNavigate";
import useSession from "hooks/useSession";
import { noPrintCss } from "styles/css";
import {
  EDIT_LANDING_PAGE_TEMPLATE_PAGE_PATH,
  APPLICANT_TRACKING_PAGE_PATH,
  CLIENT_MATCHING_PAGE_PATH,
  COMPANY_ACCOUNT_PAGE_PATH,
  MY_ACCOUNT_PAGE_PATH,
  ROOT_PAGE_PATH,
} from "routes";
import {
  MemberOccupationEnum,
  FormTypeEnum,
} from "generated/graphql";
import useHeaderUser from "hooks/useHeaderUser";

import HeaderLink from "./HeaderLink";

export const headerHeight = {
  css: "75px",
  value: 75,
};

const Header: React.FC = () => {
  const [, endSession] = useSession();
  const navigate = useNavigate();
  const [t] = useTranslation();

  const [configureApplicationForm] = useConfigureForm(FormTypeEnum.Application);
  const [configureShortApplicationForm] = useConfigureForm(
    FormTypeEnum.ShortApplication,
  );
  const [configureClientForm] = useConfigureForm(FormTypeEnum.Client);

  const [sendShortApplicantAssessment] = useSendAssessment(
    FormTypeEnum.ShortApplication,
  );
  const [sendApplicantAssessment] = useSendAssessment(FormTypeEnum.Application);
  const [sendClientAssessment] = useSendAssessment(FormTypeEnum.Client);

  const { userAsMember } = useHeaderUser();

  const isManager = userAsMember?.occupation === MemberOccupationEnum.Manager;

  return (
    <Box
      h={headerHeight.css}
      css={noPrintCss}
      position="fixed"
      bg="primary.500"
      zIndex={15}
      shadow="md"
      w="full"
      p={3}
    >
      <Stack isInline spacing={4} alignItems="center">
        <Link to={ROOT_PAGE_PATH}>
          <LogoIcon alignSelf="flex-start" cursor="pointer" fill="white" />
        </Link>

        {
          userAsMember && (
            <Stack flex="1" isInline justify="flex-end" alignItems="center">
              <HeaderLink
                title={t("dashboard.dashboard.title")}
                to={DASHBOARD_PAGE_LOCATION.toUrl()}
              />

              <HeaderLink
                title={t("applicant_tracking.title")}
                to={APPLICANT_TRACKING_PAGE_PATH}
              />

              <HeaderLink
                title={t("client_matching.title")}
                to={CLIENT_MATCHING_PAGE_PATH}
              />

              <Menu>
                <MenuButton
                  as={Text}
                  fontWeight="bold"
                  whiteSpace="pre"
                  cursor="pointer"
                  fontSize="xs"
                  color="white"
                  m="0 12px 0 8px !important"
                >
                  {t("send_assessment.title")}
                </MenuButton>

                <MenuList zIndex={16}>
                  <MenuItem onClick={sendApplicantAssessment}>
                    {t("send_assessment.full_application")}
                  </MenuItem>

                  <MenuItem onClick={sendShortApplicantAssessment}>
                    {t("send_assessment.short_application")}
                  </MenuItem>

                  <MenuItem onClick={sendClientAssessment}>
                    {t("send_assessment.client_questionnaire")}
                  </MenuItem>
                </MenuList>
              </Menu>

              <Avatar cursor="pointer" bg="transparent" size="sm" ml={2}>
                <NotificationIcon
                  alignSelf="center"
                  color="white"
                  w="20px"
                  h="20px"
                  mx={2}
                />

                <AvatarBadge
                  borderColor="crimson"
                  bg="crimson"
                  w="0.7em"
                  h="0.7em"
                  right={1}
                  top={1}
                />
              </Avatar>

              {
                isManager && (
                  <Menu>
                    <MenuButton
                      as={SettingsIcon}
                      alignSelf="center"
                      cursor="pointer"
                      color="white"
                      w="20px"
                      h="20px"
                      mx="8px !important"
                    />

                    <MenuList zIndex={16}>
                      <MenuItem
                        onClick={navigate(EDIT_LANDING_PAGE_TEMPLATE_PAGE_PATH)}
                      >
                        {t("actions.edit_landing_page")}
                      </MenuItem>

                      <MenuItem onClick={configureApplicationForm}>
                        {t("actions.edit_full_application")}
                      </MenuItem>

                      <MenuItem onClick={configureShortApplicationForm}>
                        {t("actions.edit_short_application")}
                      </MenuItem>

                      <MenuItem onClick={configureClientForm}>
                        {t("actions.edit_client_form")}
                      </MenuItem>

                      <MenuItem onClick={navigate(COMPANY_ACCOUNT_PAGE_PATH)}>
                        {t("buttons.company_account")}
                      </MenuItem>
                    </MenuList>
                  </Menu>
                )
              }

              <Menu>
                <MenuButton
                  as={Avatar}
                  src={userAsMember?.photo}
                  cursor="pointer"
                  size="sm"
                />

                <MenuList zIndex={16}>
                  <MenuItem onClick={navigate(MY_ACCOUNT_PAGE_PATH)}>
                    {t("buttons.my_account")}
                  </MenuItem>

                  <MenuItem onClick={endSession}>{t("buttons.logout")}</MenuItem>
                </MenuList>
              </Menu>

              <HeaderLink to={MY_ACCOUNT_PAGE_PATH} />
            </Stack>
          )
        }
      </Stack>
    </Box>
  );
};

export default Header;
