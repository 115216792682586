import i18n from "translations/i18n";

export interface BuildPlanBenefitsOptions {
  monthlyTokens: number;
  isFreePlan: boolean;
  benefits: string[];
}

/**
 * Builds an array of plan benefits based on a given subscription data.
 * @param param0 Options.
 */
const buildPlanBenefits = ({
  monthlyTokens,
  isFreePlan,
  benefits,
}: BuildPlanBenefitsOptions): string[] => {
  if (isFreePlan) {
    return [
      i18n.t("fallbacks.free_subscription_plan.benefits.1"),
      i18n.t("fallbacks.free_subscription_plan.benefits.2"),
    ];
  }

  return [
    i18n.t("dashboard.subscription.applicant_profiles_per_month", {
      monthlyTokens: monthlyTokens ?? i18n.t("fallbacks.unlimited"),
    }),
    ...benefits,
  ];
};

export default buildPlanBenefits;
