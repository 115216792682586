import {
  startOfMonth,
  endOfMonth,
  subMonths,
  endOfDay,
  subDays,
} from "date-fns";

import { formatDate } from "utils/dateFormats";
import { Period } from "generated/graphql";

import { statusUpdatesCardPeriod } from "../types";

/**
 * Returns the period to be used in dashboard status card.
 * @param selectedPeriod The selected period.
 */
export const getStatusUpdatesCardPeriod = (
  selectedPeriod: string,
  currentPeriod: Period | undefined,
): Period => {
  const currentDate = new Date();

  if (!currentPeriod) {
    throw new Error("You must pass the period prop when using a manager-type StatusUpdatesCountCard.");
  }

  if (selectedPeriod === statusUpdatesCardPeriod.currentPeriod) {
    return currentPeriod;
  }

  return ({
    [statusUpdatesCardPeriod.last7Days]: {
      startDate: formatDate(subDays(currentDate, 7)),
      endDate: formatDate(endOfDay(currentDate)),
    },
    [statusUpdatesCardPeriod.lastMonth]: {
      startDate: formatDate(startOfMonth(subMonths(currentDate, 1))),
      endDate: formatDate(endOfMonth(subMonths(currentDate, 1))),
    },
  })[selectedPeriod];
};

export default getStatusUpdatesCardPeriod;
