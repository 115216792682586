import React, { useEffect, useRef } from "react";
import { useWindowSize } from "use-window-size-hook";
import { HorizontalBar } from "react-chartjs-2";

import useChartOptions from "../hooks/useChartOptions";
import { HorizontalBarChartProps } from "./types";
import parseChartConfig from "./parseChartConfig";

const HorizontalBarChart: React.FC<HorizontalBarChartProps> = ({
  comparisonColorScheme = "secondary",
  showAverageValues = false,
  colorScheme = "primary",
  comparisonScores = [],
  comparisonDatasetLabel,
  mainDatasetLabel,
  scores,
}) => {
  const { width } = useWindowSize();

  const ref = useRef<HorizontalBar>(null);

  const chartConfig = parseChartConfig({
    comparisonDatasetLabel,
    showAverageValues,
    mainDatasetLabel,
    inputs: [
      {
        colorScheme,
        scores,
      },
      {
        colorScheme: comparisonColorScheme,
        scores: comparisonScores,
      },
    ],
  });

  const chartOptions = useChartOptions({
    options: chartConfig.options,
  });

  useEffect(() => {
    ref?.current?.chartInstance?.update?.();
  }, [width]);

  return (
    <HorizontalBar
      options={chartOptions}
      data={chartConfig.data}
      ref={ref}
      redraw
    />
  );
};

export default HorizontalBarChart;
