import VerifyUser from "views/VerifyUser";
import CompanyAccount from "views/CompanyAccount";
import CompanySignUp from "views/CompanySignUp";
import CompanyOnboarding from "views/CompanyOnboarding";
import ForgotPassword from "views/ForgotPassword";
import ResetPassword from "views/ResetPassword";
import { RouteProps } from "contexts/route/types";
import MyAccount from "views/MyAccount";
import Dashboard from "views/Dashboard";
import ApplicantTracking from "views/ApplicantTracking";
import ApplicantProfile from "views/ApplicantProfile";
import ClientProfile from "views/ClientProfile";
import ClientMatching from "views/ClientMatching";
import MemberSignUp from "views/MemberSignUp";
import Login from "views/Login";
import Home from "views/Home";
import ApplicantForm from "views/Forms/ApplicantForm";
import ShortApplicantForm from "views/Forms/ShortApplicantForm";
import ClientForm from "views/Forms/ClientForm";
import FormLandingPage from "views/Forms/FormLandingPage";
import FormPreview from "views/Forms/FormPreview";
import FormRedirect from "views/Forms/FormRedirect";
import FormThankYou from "views/Forms/FormThankYou";
import EditApplicationForm from "views/Forms/EditForm/EditApplicationForm";
import EditShortApplicationForm from "views/Forms/EditForm/EditShortApplicationForm";
import EditQuestionnaireForm from "views/Forms/EditForm/EditQuestionnaireForm";
import EditLandingPageTemplateForm from "views/Forms/EditLandingPageTemplateForm";
import EditLandingPageConfigurationForm from "views/Forms/EditLandingPageConfigurationForm";
import NotMatched from "views/NotMatched";
import {
  ROOT_PAGE_PATH,
  LOGIN_PAGE_PATH,
  FORGOT_PASSWORD_PAGE_PATH,
  MY_ACCOUNT_PAGE_PATH,
  COMPANY_SIGN_UP_PAGE_PATH,
  COMPANY_ONBOARDING_PAGE_PATH,
  COMPANY_ACCOUNT_PAGE_PATH,
  DASHBOARD_PAGE_PATH,
  DASHBOARD_MODIFY_SUBSCRIPTION_PAGE_PATH,
  CLIENT_PROFILE_PAGE_PATH,
  CLIENT_MATCHING_PAGE_PATH,
  APPLICANT_PROFILE_PAGE_PATH,
  APPLICANT_TRACKING_PAGE_PATH,
  MEMBER_SIGN_UP_PAGE_PATH,
  RESET_PASSWORD_PAGE_PATH,
  APPLICANT_FORM_PAGE_PATH,
  SHORT_APPLICANT_FORM_PAGE_PATH,
  CLIENT_FORM_PAGE_PATH,
  FORM_LANDING_PAGE_PATH,
  FORM_PREVIEW_PAGE_PATH,
  FORM_THANK_YOU_PAGE_PATH,
  FORM_REDIRECT_PAGE_PATH,
  VERIFY_USER_PAGE_PATH,
  EDIT_APPLICATION_FORM_PAGE_PATH,
  EDIT_SHORT_APPLICATION_FORM_PAGE_PATH,
  EDIT_QUESTIONNAIRE_FORM_PAGE_PATH,
  EDIT_LANDING_PAGE_TEMPLATE_PAGE_PATH,
  EDIT_LANDING_PAGE_CONFIGURATION_PAGE_PATH,
  NOT_MATCHED,
  APPLICANT_IFRAME_CHART,
} from "routes";
import ApplicantIframeChart from "views/ApplicantIframeChart";

const defaultRouteProps = {
  exact: true,
  strict: false,
  requireAuthentication: true,
  renderAppWrapper: true,
  showHeader: true,
  showFooter: true,
  componentProps: {},
};

const appRoutes: RouteProps[] = [
  {
    ...defaultRouteProps,
    path: ROOT_PAGE_PATH,
    component: Home,
  },
  {
    ...defaultRouteProps,
    path: APPLICANT_IFRAME_CHART,
    component: ApplicantIframeChart,
    showHeader: false,
    showFooter: false,
    renderAppWrapper: false,
    requireAuthentication: false,
  },
  {
    ...defaultRouteProps,
    requireAuthentication: false,
    path: LOGIN_PAGE_PATH,
    component: Login,
    showHeader: false,
    showFooter: false,
    renderAppWrapper: false,
  },
  {
    ...defaultRouteProps,
    requireAuthentication: false,
    path: MEMBER_SIGN_UP_PAGE_PATH,
    component: MemberSignUp,
    showHeader: false,
    showFooter: false,
    renderAppWrapper: false,
  },
  {
    ...defaultRouteProps,
    requireAuthentication: false,
    path: FORGOT_PASSWORD_PAGE_PATH,
    component: ForgotPassword,
    showHeader: false,
    showFooter: false,
    renderAppWrapper: false,
  },
  {
    ...defaultRouteProps,
    requireAuthentication: false,
    path: RESET_PASSWORD_PAGE_PATH,
    component: ResetPassword,
    showHeader: false,
    showFooter: false,
    renderAppWrapper: false,
  },
  {
    ...defaultRouteProps,
    requireAuthentication: false,
    path: COMPANY_SIGN_UP_PAGE_PATH,
    component: CompanySignUp,
    showHeader: false,
    showFooter: false,
    renderAppWrapper: false,
  },
  {
    ...defaultRouteProps,
    requireAuthentication: true,
    path: COMPANY_ONBOARDING_PAGE_PATH,
    component: CompanyOnboarding,
    showHeader: false,
    showFooter: false,
  },
  {
    ...defaultRouteProps,
    path: COMPANY_ACCOUNT_PAGE_PATH,
    component: CompanyAccount,
  },
  {
    ...defaultRouteProps,
    path: MY_ACCOUNT_PAGE_PATH,
    component: MyAccount,
  },
  {
    ...defaultRouteProps,
    path: DASHBOARD_PAGE_PATH,
    component: Dashboard,
    renderAppWrapper: false,
  },
  {
    ...defaultRouteProps,
    path: DASHBOARD_MODIFY_SUBSCRIPTION_PAGE_PATH,
    component: Dashboard,
    renderAppWrapper: false,
  },
  {
    ...defaultRouteProps,
    path: APPLICANT_TRACKING_PAGE_PATH,
    component: ApplicantTracking,
  },
  {
    ...defaultRouteProps,
    path: CLIENT_MATCHING_PAGE_PATH,
    component: ClientMatching,
  },
  {
    ...defaultRouteProps,
    path: APPLICANT_PROFILE_PAGE_PATH,
    component: ApplicantProfile,
  },
  {
    ...defaultRouteProps,
    path: CLIENT_PROFILE_PAGE_PATH,
    component: ClientProfile,
  },
  {
    ...defaultRouteProps,
    requireAuthentication: false,
    path: VERIFY_USER_PAGE_PATH,
    component: VerifyUser,
    showHeader: false,
    showFooter: false,
  },
  {
    ...defaultRouteProps,
    requireAuthentication: false,
    path: FORM_THANK_YOU_PAGE_PATH,
    component: FormThankYou,
    showHeader: false,
    showFooter: false,
    renderAppWrapper: false,
  },
  {
    ...defaultRouteProps,
    requireAuthentication: false,
    path: APPLICANT_FORM_PAGE_PATH,
    component: ApplicantForm,
    showHeader: false,
    showFooter: false,
  },
  {
    ...defaultRouteProps,
    requireAuthentication: false,
    path: SHORT_APPLICANT_FORM_PAGE_PATH,
    component: ShortApplicantForm,
    showHeader: false,
    showFooter: false,
  },
  {
    ...defaultRouteProps,
    requireAuthentication: false,
    path: CLIENT_FORM_PAGE_PATH,
    component: ClientForm,
    showHeader: false,
    showFooter: false,
  },
  {
    ...defaultRouteProps,
    requireAuthentication: false,
    path: FORM_LANDING_PAGE_PATH,
    component: FormLandingPage,
    showHeader: false,
    showFooter: false,
    renderAppWrapper: false,
  },
  {
    ...defaultRouteProps,
    requireAuthentication: false,
    path: FORM_PREVIEW_PAGE_PATH,
    component: FormPreview,
    showHeader: false,
    showFooter: false,
    renderAppWrapper: false,
  },
  {
    ...defaultRouteProps,
    requireAuthentication: false,
    path: FORM_REDIRECT_PAGE_PATH,
    component: FormRedirect,
    showHeader: false,
    showFooter: false,
  },
  {
    ...defaultRouteProps,
    path: EDIT_APPLICATION_FORM_PAGE_PATH,
    component: EditApplicationForm,
  },
  {
    ...defaultRouteProps,
    path: EDIT_SHORT_APPLICATION_FORM_PAGE_PATH,
    component: EditShortApplicationForm,
  },
  {
    ...defaultRouteProps,
    path: EDIT_QUESTIONNAIRE_FORM_PAGE_PATH,
    component: EditQuestionnaireForm,
  },
  {
    ...defaultRouteProps,
    path: EDIT_LANDING_PAGE_TEMPLATE_PAGE_PATH,
    component: EditLandingPageTemplateForm,
  },
  {
    ...defaultRouteProps,
    path: EDIT_LANDING_PAGE_CONFIGURATION_PAGE_PATH,
    component: EditLandingPageConfigurationForm,
  },
  {
    ...defaultRouteProps,
    path: NOT_MATCHED,
    component: NotMatched,
  },
];

export default appRoutes;
