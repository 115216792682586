/**
 * CSS to be injected in root Dashboard `Wrap` components.
 *
 * TODO: When updating Chakra UI, use `WrapItem` instead of injecting css.
 * Refer to: https://chakra-ui.com/docs/layout/wrap
 */
export const dashboardWrapCss = {
  li: {
    flex: 1,
  },
};
