import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import numeral from "numeral";

import {
  CardContentProps,
  CardInfoItem,
} from "components/Tracking/Cards/types";
import DescriptionIcon from "settings/theme/icons/DescriptionIcon";
import { Applicant, StatusUpdate } from "generated/graphql";
import { formatDate, monthDayYear } from "utils/dateFormats";
import ScheduleIcon from "settings/theme/icons/ScheduleIcon";
import CalendarIcon from "settings/theme/icons/CalendarIcon";
import colors from "settings/theme/colors";
import buildName from "utils/buildName";
import isPresent from "utils/isPresent";
import { getRole } from "utils/getRole";

import getApplicantCardStatus from "./getApplicantCardStatus";

/**
 * Exposes the logic of Applicant Card, and creates the props to render the component.
 * @param props Card props.
 */
const useApplicantCard = (
  applicant?: Applicant,
  showTooltip?: boolean,
  roleMatch?: boolean,
): CardContentProps => {
  const [t] = useTranslation();

  const {
    qualified = true,
    picturesUrl,
    isFullTime,
    firstName,
    appliedAt,
    lastName,
    source,
    status,
  } = applicant ?? {};

  const infoItems = useMemo<CardInfoItem[]>(() => {
    const items = [];

    if (isPresent(source)) {
      items.push({
        text: (t("tracking_cards.applied_via", { source })
        ),
        icon: DescriptionIcon,
      });
    }

    if (isPresent(isFullTime)) {
      items.push({
        text: (
          isFullTime
            ? t("tracking_cards.full_time")
            : t("tracking_cards.part_time")
        ),
        icon: ScheduleIcon,
      });
    }

    if (isPresent(appliedAt)) {
      items.push({
        text: t("tracking_cards.date_submitted", {
          formattedDate: formatDate(appliedAt, monthDayYear),
        }),
        icon: CalendarIcon,
      });
    }

    return items;
  }, [
    isFullTime,
    appliedAt,
    source,
    t,
  ]);

  const imageProps = useMemo(() => ({
    showFlag: !qualified,
    flagColor: colors.status.error,
    flagText: t("tracking_cards.unqualified"),
  }), [
    qualified,
    t,
  ]);

  const roleFitScore = applicant?.assessment?.roleFitScore;

  const isProfessional = applicant?.job?.role?.isProfessional || roleMatch;

  const role = getRole(applicant?.job?.role?.name);

  const payload = useMemo<CardContentProps>(() => ({
    status: getApplicantCardStatus(status as StatusUpdate),
    fullName: buildName(firstName, lastName),
    fitLabel: isProfessional ? t("tracking_cards.match") : t("tracking_cards.role_fit"),
    fitContainerSize: "md",
    formattedRoleFitScore: isProfessional
      ? String(Math.round(roleFitScore * 100))
      : numeral(roleFitScore).format("0%"),
    roleFitScore,
    picturesUrl,
    imageProps,
    infoItems,
    hasTour: true,
    tooltipText: showTooltip
      ? isProfessional
        ? t("tooltips.match", { role })
        : t("tooltips.role_fit", { role })
      : undefined,
  }), [
    isProfessional,
    roleFitScore,
    picturesUrl,
    showTooltip,
    imageProps,
    infoItems,
    firstName,
    lastName,
    status,
    role,
    t,
  ]);

  return payload;
};

export default useApplicantCard;
