/**
 * A pattern for error URLs which should not be sent to Sentry.
 */
const denyUrls = [
  // Facebook flakiness
  /graph\.facebook\.com/i,

  // Facebook blocked
  /connect\.facebook\.net\/en_US\/all\.js/i,

  // Chrome extensions
  /extensions\//i,
  /^chrome:\/\//i,

  // Other plugins
  /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
  /webappstoolbarba\.texthelp\.com\//i,
  /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
];

export default denyUrls;
