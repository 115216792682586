import React, { useCallback } from "react";
import { ModalBody as ChakraModalBody } from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";

import { ShareProfileSchema } from "settings/yup/schemas/profile/shareProfileSchema";
import Tabs from "components/Tabs";

import tabs from "./tabs";

const ModalBody: React.FC = () => {
  const { reset } = useFormContext<ShareProfileSchema>();

  const onTabChange = useCallback((tabIndex: number) => {
    const tab = tabs[tabIndex];

    reset({
      type: tab.type,
      targets: [{
        value: "",
      }],
    });
  }, [
    reset,
  ]);

  return (
    <ChakraModalBody>
      <Tabs
        onChange={onTabChange}
        items={tabs}
        isLazy
      />
    </ChakraModalBody>
  );
};

export default ModalBody;
