import * as React from "react";
import { useTranslation } from "react-i18next";
import {
  IconButton,
  Stack,
  Text,
} from "@chakra-ui/react";

import DocumentIcon from "settings/theme/icons/DocumentIcon";
import DeleteIcon from "settings/theme/icons/DeleteIcon";

import { DocumentPreviewProps } from "../types";
import FileDropBox from "../FileDropBox";

const DocumentPreview: React.FC<DocumentPreviewProps> = ({
  removeFile,
  boxSize,
  color,
  file,
}) => {
  const [t] = useTranslation();

  if (!file) {
    return (
      <FileDropBox
        boxSize={boxSize}
        color={color}
      />
    );
  }

  return (
    <Stack
      alignItems="center"
      spacing={6}
      isInline
    >
      <DocumentIcon
        boxSize={10}
        color="primary.500"
      />

      <Text
        color="gray.400"
        fontSize="sm"
      >
        {file.name}
      </Text>

      <IconButton
        aria-label={t("buttons.remove")}
        icon={<DeleteIcon />}
        onClick={removeFile}
        variant="ghost"
      />
    </Stack>
  );
};

export default DocumentPreview;
