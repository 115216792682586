import rgba from "rgba-convert";
import get from "lodash.get";
import * as R from "remeda";

import i18n from "translations/i18n";
import toFixed from "utils/toFixed";
import theme from "settings/theme";

import {
  ParseChartDataOptions,
  SpiderChartDataset,
  SpiderChartData,
} from "./types";
import formatDescriptions from "../formatDescription";

/**
 * Defines the opacity (in %) of the chart background fill color.
 */
const backgroundFillColorOpacity = "50";

/**
 * Parses data for a spider chart, returning valid values.
 * @param scores The spider chart scores.
 * @param mainDatasetLabel The main dataset label.
 * @param comparisonDatasetLabel The comparison dataset label.
 */
const parseChartData = ({
  comparisonDatasetLabel,
  mainDatasetLabel,
  inputs,
}: ParseChartDataOptions): SpiderChartData => {
  const defaultScores = inputs[0]?.scores ?? [];

  const labels = R.pipe(
    defaultScores,
    R.sortBy((score) => score.info?.position),
    R.map((score) => score.name),
  );

  const descriptions = formatDescriptions(defaultScores);

  const datasets: SpiderChartDataset[] = R.pipe(
    inputs,
    R.map.indexed((input, index) => {
      const bgColor = get(theme.colors, `${input.colorScheme}.100`);
      const pathColor = get(theme.colors, `${input.colorScheme}.200`);

      const alphaBgColor = rgba.css(`${bgColor}${backgroundFillColorOpacity}`);

      return {
        data: R.pipe(
          input?.scores ?? [],
          R.sortBy((score) => score.info?.position),
          R.map((score) => Number(score.finalValue) + 0.2),
          R.map((value) => toFixed(value, 2)),
        ),
        pointBackgroundColor: pathColor,
        backgroundColor: alphaBgColor,
        pointBorderColor: "#fff",
        borderColor: pathColor,
        fill: true,
        label: (
          index === 0
            ? mainDatasetLabel || i18n.t("charts.score")
            : comparisonDatasetLabel || i18n.t("charts.comparison_score")
        ),
        descriptions,
      };
    }),
    R.filter((dataset) => dataset.data.length > 0),
  );

  const result: SpiderChartData = {
    datasets,
    labels,
  };

  return result;
};

export default parseChartData;
