/**
 * Returns the offset of a pagination
 *
 * @example getPaginationOffset(3, 10) -> 20
 *
 * @param pageNumber The page number
 * @param itemsPerPage The number of items per page
 */
const getPaginationOffset = (pageNumber: number, itemsPerPage: number): number => (
  itemsPerPage * (pageNumber - 1)
);

export default getPaginationOffset;
