import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ModalFooter,
  ModalBody,
  Stack,
} from "@chakra-ui/react";

import { FormTypeEnum } from "generated/graphql";
import { SelectOption } from "components/FormComponents/Select/types";
import Select from "components/FormComponents/Select";
import Button from "components/Button";

import parseRoleOptions from "./parseRoleOptions";
import { SelectJobModalProps } from "../types";

const SelectJobModal: React.FC<SelectJobModalProps> = ({
  componentProps: {
    closeModalOnConfirm,
    onConfirm,
    roles,
    jobs,
    formType,
  },
  hideModal,
}) => {
  const [t] = useTranslation();

  const [selectedJob, setSelectedJob] = useState<SelectOption>();

  let companyRoles = roles;

  if (formType === FormTypeEnum.Client) {
    companyRoles = companyRoles.filter(role => role.hasClient);
  }

  const options = useMemo(() => (
    parseRoleOptions(companyRoles)
  ), [companyRoles]);

  const handleOnChange = useCallback((newValue) => {
    setSelectedJob(newValue);
  }, []);

  const handleFinish = useCallback(() => {
    const job = jobs.find((jobItem) => jobItem.role.id === selectedJob?.value);

    if (onConfirm && job) {
      onConfirm(job);
    }

    if (closeModalOnConfirm) {
      hideModal();
    }
  }, [
    closeModalOnConfirm,
    selectedJob,
    hideModal,
    onConfirm,
    jobs,
  ]);

  return (
    <>
      <ModalBody>
        <Stack
          spacing={4}
          py={6}
        >
          <Select
            onChange={handleOnChange}
            value={selectedJob}
            options={options}
            title={`${t("actions.select_role")}*`}
          />
        </Stack>
      </ModalBody>

      <ModalFooter>
        <Button
          isDisabled={!selectedJob}
          onClick={handleFinish}
          fontSize="sm"
          w="full"
        >
          {t("buttons.confirm")}
        </Button>
      </ModalFooter>
    </>
  );
};

export default SelectJobModal;
