import { QuestionCardField } from "components/Forms/CompanyOnboardingForm/Cards/QuestionCard/types";
import { FormTypeEnum } from "generated/graphql";
import i18n from "translations/i18n";

const fields = [
  {
    label: i18n.t("company_onboarding.steps.personal_information.question_1.field_1"),
    isRequired: {
      [FormTypeEnum.Application]: true,
      [FormTypeEnum.Client]: true,
      [FormTypeEnum.ShortApplication]: true,
    },
  },
  {
    label: i18n.t("company_onboarding.steps.personal_information.question_1.field_2"),
    isRequired: {
      [FormTypeEnum.Application]: true,
      [FormTypeEnum.Client]: true,
      [FormTypeEnum.ShortApplication]: true,
    },
  },
  {
    label: i18n.t("company_onboarding.steps.personal_information.question_1.field_3"),
    isRequired: {
      [FormTypeEnum.Application]: true,
      [FormTypeEnum.Client]: true,
      [FormTypeEnum.ShortApplication]: true,
    },
  },
  {
    label: i18n.t("company_onboarding.steps.personal_information.question_1.field_4"),
    isRequired: {
      [FormTypeEnum.Application]: true,
      [FormTypeEnum.Client]: true,
      [FormTypeEnum.ShortApplication]: true,
    },
  },
  {
    label: i18n.t("company_onboarding.steps.personal_information.question_1.field_5"),
    isRequired: {
      [FormTypeEnum.Application]: false,
      [FormTypeEnum.Client]: true,
      [FormTypeEnum.ShortApplication]: false,
    },
  },
  {
    label: i18n.t("company_onboarding.steps.personal_information.question_1.field_6"),
    isRequired: {
      [FormTypeEnum.Application]: false,
      [FormTypeEnum.Client]: false,
      [FormTypeEnum.ShortApplication]: false,
    },
  },
  {
    label: i18n.t("company_onboarding.steps.personal_information.question_1.field_7"),
    isRequired: {
      [FormTypeEnum.Application]: false,
      [FormTypeEnum.Client]: true,
      [FormTypeEnum.ShortApplication]: false,
    },
  },
  {
    label: i18n.t("company_onboarding.steps.personal_information.question_1.field_8"),
    isRequired: {
      [FormTypeEnum.Application]: false,
      [FormTypeEnum.Client]: true,
      [FormTypeEnum.ShortApplication]: false,
    },
  },
  {
    label: i18n.t("company_onboarding.steps.personal_information.question_1.field_9"),
    isRequired: {
      [FormTypeEnum.Application]: false,
      [FormTypeEnum.Client]: true,
      [FormTypeEnum.ShortApplication]: false,
    },
  },
];

const getFields = (formType: FormTypeEnum): QuestionCardField[] => (
  fields.map((field) => ({
    label: {
      text: field.label,
    },
    value: {
      text: (
        field.isRequired[formType]
          ? i18n.t("question_display_types.required_by_default")
          : i18n.t("question_display_types.optional_by_default")
      ),
    },
  }))
);

export default getFields;
