import React from "react";
import { useTranslation } from "react-i18next";
import { Stack } from "@chakra-ui/react";

import { BillingType } from "generated/graphql";
import ListItem from "components/ListItem";

import { ModifySubscriptionPaymentData } from "../types";

const ModifySubscriptionPaymentInformation: React.FC<ModifySubscriptionPaymentData> = ({
  simulateSubscriptionResult,
  options,
}) => {
  const [t] = useTranslation();

  const shouldShowCancelationBonus = !!parseFloat(
    simulateSubscriptionResult.cancelationBonus?.amount,
  );

  const shouldShowDiscount = !!parseFloat(
    simulateSubscriptionResult.discount?.amount,
  );

  const finalPriceTitle = ({
    [BillingType.Monthly]: t("modify_subscription_modal.total_on_first_month"),
    [BillingType.Annual]: t("modify_subscription_modal.total_on_first_year"),
  })[options.billingType];

  return (
    <Stack spacing={2}>
      <ListItem
        title={t("modify_subscription_modal.selected_billing_type")}
        value={t(`billing_types.${String(options.billingType).toLowerCase()}`)}
      />

      <ListItem
        title={t("modify_subscription_modal.plan_original_price")}
        value={simulateSubscriptionResult.planOriginalPrice.formatted}
      />

      {
        shouldShowDiscount && (
          <ListItem
            title={t("modify_subscription_modal.discount")}
            value={`-${simulateSubscriptionResult.discount.formatted}`}
          />
        )
      }

      {
        shouldShowCancelationBonus && (
          <ListItem
            title={t("modify_subscription_modal.credits_from_current_plan_cancelation", {
              planName: simulateSubscriptionResult.currentSubscription.plan.name,
            })}
            value={`-${simulateSubscriptionResult.cancelationBonus.formatted}`}
          />
        )
      }

      <ListItem
        title={finalPriceTitle}
        value={simulateSubscriptionResult.finalPrice.formatted}
      />
    </Stack>
  );
};

export default ModifySubscriptionPaymentInformation;
