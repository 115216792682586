import { makePhoneNumberTest } from "settings/yup/tests/phoneNumberTest";
import emailTest from "settings/yup/tests/emailTest";
import yup from "settings/yup";

import optionalAddressSchema, { OptionalAddressSchema } from "../optionalAddressSchema";
import addressSchema, { AddressSchema } from "../addressSchema";

const basePersonalInformationSchema = {
  firstName: yup
    .string()
    .required(),
  lastName: yup
    .string()
    .required(),
  phoneNumber: yup
    .string()
    .test(makePhoneNumberTest())
    .required(),
  email: yup
    .string()
    .test(emailTest)
    .required(),
};

export const clientPersonalInformationSchema = yup.object().shape({
  ...basePersonalInformationSchema,
  address: addressSchema,
});

export const applicantPersonalInformationSchema = yup.object().shape({
  ...basePersonalInformationSchema,
  address: optionalAddressSchema,
});

export interface PersonalInformationSchema {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  address: AddressSchema | OptionalAddressSchema;
}
