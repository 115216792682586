import yup from "settings/yup";

const requirementsSchema = yup.object().shape({
  requirements: yup
    .array()
    .of(
      yup
        .string()
        .required(),
    ),
});

export interface RequirementsSchema {
  requirements: string[];
}

export default requirementsSchema;
