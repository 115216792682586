import React, { useCallback, useState } from "react";
import { Stack } from "@chakra-ui/react";

import { BillingType } from "generated/graphql";
import Box from "components/Box";
import useModifySubscription from "hooks/useModifySubscription";
import useCancelSubscription from "hooks/useCancelSubscription";

import PlanCardFooter from "./PlanCardFooter";
import PlanCardTitle from "./PlanCardTitle";
import { PlanCardProps } from "../types";
import PlanCardBody from "./PlanCardBody";

const PlanCard: React.FC<PlanCardProps> = ({
  subscription,
  companyId,
  plan,
  role,
}) => {
  const [selectedBillingType, setSelectedBillingType] = useState<BillingType>((
    subscription?.plan?.id === plan.id
      ? subscription?.billingType
      : BillingType.Monthly
  ));

  const [cancelSubscription, isCancelSubscriptionLoading] = useCancelSubscription();
  const [modifySubscription, isModifySubscriptionLoading] = useModifySubscription();

  const handleSelectPlan = useCallback(() => {
    if (plan.isFreePlan) {
      if (subscription?.id) {
        cancelSubscription({
          subscriptionId: subscription.id,
          roleName: role.name,
        });
      }

      return;
    }

    modifySubscription({
      billingType: selectedBillingType,
      planId: plan.id,
      companyId,
    });
  }, [
    selectedBillingType,
    modifySubscription,
    cancelSubscription,
    subscription,
    role,
    companyId,
    plan,
  ]);

  const isSelected = (
    (plan.isFreePlan && !subscription?.id)
    || (
      selectedBillingType === subscription?.billingType
      && subscription?.plan?.id === plan.id
    )
  );

  return (
    <Box
      title={(
        <PlanCardTitle plan={plan} />
      )}
      wrapperProps={{
        m: 0,
      }}
      minW="280px"
      w="full"
      h="full"
      p={0}
    >
      <Stack
        justifyContent="space-between"
        h="full"
      >
        <PlanCardBody
          setSelectedBillingType={setSelectedBillingType}
          selectedBillingType={selectedBillingType}
          plan={plan}
        />

        <PlanCardFooter
          isLoading={isModifySubscriptionLoading || isCancelSubscriptionLoading}
          selectPlan={handleSelectPlan}
          isSelected={isSelected}
        />
      </Stack>
    </Box>
  );
};

export default PlanCard;
