import { clearInputPlaceholder } from "components/FormComponents/Input/clearInputPlaceholder";
import { ZIP_CODE_LENGTH } from "constants/masks";

export type ZipCode = string

/**
 * Validates one zip code field.
 * @param {*} value The value.
 */
export const isValidZipCode = (value: string): boolean => {
  const parsedZipCode = clearInputPlaceholder(value ?? "");

  return (
    !!parsedZipCode
    && parsedZipCode.length === ZIP_CODE_LENGTH
  );
};
