import {
  useCallback,
  useEffect,
  useState,
  useMemo,
} from "react";
import saveFile from "save-file";

import {
  useExportApplicantsToCsvLazyQuery,
  useExportClientsToCsvLazyQuery,
} from "generated/graphql";
import useApplicantFiltersParams from "hooks/useApplicantFiltersParams";
import useClientFiltersParams from "hooks/useClientFiltersParams";
import useFilter, { filters } from "hooks/useFilter";
import { onQueryError } from "utils/queryHandlers";
import useToast from "hooks/useToast";

import {
  UseExportTrackingListToCsvOptions,
  UseExportTrackingListToCsvResult,
  ExportToCsvQueryCall,
} from "./types";

/**
 * Exposes the logic to download CSV files from tracking list pages.
 * Automatically handles filtering content.
 */
const useExportTrackingListToCsv = (): UseExportTrackingListToCsvResult => {
  const [shouldExport, setShouldExport] = useState<boolean>(false);

  const [showToast] = useToast();

  const [applicantFilters] = useApplicantFiltersParams();
  const [clientFilters] = useClientFiltersParams();

  const [search] = useFilter(filters.search);

  const [callExportClientsToCsv, {
    refetch: refetchExportClientsToCsv,
    called: calledExportClientsToCsv,
    data: dataExportClientsToCsv,
  }] = useExportClientsToCsvLazyQuery({
    notifyOnNetworkStatusChange: true,
    onError: (error) => {
      onQueryError(error, showToast);
    },
    variables: {
      search: search as string,
      filters: clientFilters,
    },
  });

  const [callExportApplicantsToCsv, {
    refetch: refetchExportApplicantsToCsv,
    called: calledExportApplicantsToCsv,
    data: dataExportApplicantsToCsv,
  }] = useExportApplicantsToCsvLazyQuery({
    notifyOnNetworkStatusChange: true,
    onError: (error) => {
      onQueryError(error, showToast);
    },
    variables: {
      search: search as string,
      filters: applicantFilters,
    },
  });

  const called = (
    calledExportApplicantsToCsv
    || calledExportClientsToCsv
  );

  const exportTrackingListToCsv = useCallback((options: UseExportTrackingListToCsvOptions) => {
    setShouldExport(true);

    const call = ({
      applicants: callExportApplicantsToCsv,
      clients: callExportClientsToCsv,
    })[options.csvExportType] as ExportToCsvQueryCall;

    if (!called) {
      call();
      return;
    }

    const refetch = ({
      applicants: refetchExportApplicantsToCsv,
      clients: refetchExportClientsToCsv,
    })[options.csvExportType] as ExportToCsvQueryCall;

    if (refetch) {
      refetch();
    }
  }, [
    refetchExportApplicantsToCsv,
    refetchExportClientsToCsv,
    callExportApplicantsToCsv,
    callExportClientsToCsv,
    called,
  ]);

  useEffect(() => {
    if (!shouldExport || !called) {
      return;
    }

    const file = (
      dataExportApplicantsToCsv?.exportToCsv
      ?? dataExportClientsToCsv?.exportToCsv
    );

    if (!file) {
      return;
    }

    saveFile(file.base64, file.name);

    setTimeout(() => {
      setShouldExport(false);
    }, 300);
  }, [
    dataExportApplicantsToCsv,
    dataExportClientsToCsv,
    shouldExport,
    called,
  ]);

  const payload = useMemo<UseExportTrackingListToCsvResult>(() => [
    exportTrackingListToCsv,
    shouldExport,
  ], [
    exportTrackingListToCsv,
    shouldExport,
  ]);

  return payload;
};

export default useExportTrackingListToCsv;
