import React, { useMemo } from "react";
import { ModalBody as ChakraModalBody } from "@chakra-ui/react";

import { FormTypeEnum } from "generated/graphql";
import parseRoleOptions from "hooks/useSelectJob/SelectJobModal/parseRoleOptions";
import Tabs from "components/Tabs";

import { ModalBodyProps } from "./types";
import tabs from "./tabs";

const ModalBody: React.FC<ModalBodyProps> = ({
  formType,
  roles,
}) => {
  let companyRoles = roles;

  if (formType === FormTypeEnum.Client) {
    companyRoles = companyRoles.filter(role => role.hasClient);
  }

  const injectProps = useMemo(() => ({
    options: parseRoleOptions(companyRoles),
    formType,
  }), [
    formType,
    companyRoles,
  ]);

  return (
    <ChakraModalBody>
      <Tabs
        injectProps={injectProps}
        items={tabs}
        isLazy
      />
    </ChakraModalBody>
  );
};

export default ModalBody;
