import i18n from "translations/i18n";

export const getRole = (role?: string): string | undefined => {
  if (!role) {
    return undefined;
  }

  const parsedRole = role.replace(/ /g, "").toLowerCase();

  return i18n.t(`roles.${parsedRole}`, role);
};
