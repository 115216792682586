/**
 * Defines if one phone number is valid.
 * @param value The value.
 */
const isValidPhoneNumber = (value: string): boolean => {
  const parsedValue = String(value).match(/\d/g);

  const length = parsedValue?.length ?? 0;

  return length <= 14 && length >= 10;
};

export default isValidPhoneNumber;
