import { useCallback, useMemo } from "react";

import { useGetApplicantResumeUrlLazyQuery } from "generated/graphql";
import { onQueryError } from "utils/queryHandlers";
import useToast from "hooks/useToast";

import {
  UseDownloadApplicantResumeOptions,
  UseDownloadApplicantResumeResult,
} from "./types";

/**
 * Exposes a function to download an applicant resume and the query loading state.
 *
 * When executing the function, the hook will query for `getApplicantResumeUrl` in
 * order to ensure that the download url is not expired, and then download the file.
 *
 * In case the query fails, a toast will be shown with the error.
 * @param param0 Options.
 */
const useDownloadApplicantResume = ({
  applicantId,
}: UseDownloadApplicantResumeOptions): UseDownloadApplicantResumeResult => {
  const [showToast] = useToast();

  const [loadQuery, {
    refetch,
    loading,
    called,
  }] = useGetApplicantResumeUrlLazyQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      const url = data?.getApplicantResumeUrl;

      if (url) {
        window.open(url, "_blank");
      }
    },
    onError: (error) => {
      onQueryError(error, showToast);
    },
  });

  const downloadApplicantResume = useCallback(() => {
    const payload = {
      id: applicantId,
    };

    if (!called) {
      loadQuery({
        variables: payload,
      });
      return;
    }

    refetch?.(payload);
  }, [
    applicantId,
    loadQuery,
    refetch,
    called,
  ]);

  const payload = useMemo<UseDownloadApplicantResumeResult>(() => [
    downloadApplicantResume,
    loading,
  ], [
    downloadApplicantResume,
    loading,
  ]);

  return payload;
};

export default useDownloadApplicantResume;
