import { AssertingTestOptions } from "yup";

import { isValidStateAbbreviation } from "utils/isValidStateAbbreviation";
import { STATE_LENGTH } from "constants/masks";
import i18n from "translations/i18n";

/**
 * Applies state abbreviation validation in one schema field.
 */
export const buildStateAbbreviationTest = (isRequired = true): AssertingTestOptions<string> => ({
  name: "isValidStateAbbreviation",
  exclusive: false,
  test (value: string): value is string {
    if (!value && !isRequired) {
      return true;
    }

    return isValidStateAbbreviation(value);
  },
  message: i18n.t("yup.invalid_field_length", {
    min: STATE_LENGTH,
  }),
});
