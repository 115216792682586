import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Skeleton } from "@chakra-ui/react";

import { useTrackingFiltersQueries } from "contexts/trackingFiltersQueries";
import createTrackingFilter from "components/Tracking/Filters";
import Select from "components/FormComponents/Select";
import useFilter, { filters } from "hooks/useFilter";

import getRoleFilterOptions from "./getRoleFilterOptions";

const RoleFilter = createTrackingFilter(({
  setTitle,
}) => {
  const [t] = useTranslation();

  const [jobId, setJobId] = useFilter<number>(filters.jobId);

  const { jobs, loading } = useTrackingFiltersQueries();

  const options = useMemo(() => (
    getRoleFilterOptions(jobs)
  ), [jobs]);

  useEffect(() => {
    setTitle(`${t("filters.role")}:`);
  }, [
    setTitle,
    t,
  ]);

  const onChange = useCallback((option) => {
    setJobId(option?.value);
  }, [
    setJobId,
  ]);

  const validJobId = options?.some((option) => option.value === jobId)
    ? jobId
    : options?.[0]?.value as number;

  useEffect(() => {
    setJobId(validJobId);
  }, [
    validJobId,
    setJobId,
    options,
  ]);

  return (
    <Skeleton isLoaded={!loading}>
      <Select
        onChange={onChange}
        options={options}
        value={jobId}
        name="role"
      />
    </Skeleton>
  );
});

export default RoleFilter;
