import truthyTargetsTest from "settings/yup/tests/truthyTargetsTest";
import { makePhoneNumberTest } from "settings/yup/tests/phoneNumberTest";
import yup from "settings/yup";

export enum ShareProfileTypeEnum {
  Email = "EMAIL",
  SMS = "SMS",
}

const ShareProfileSchema = yup.object().shape({
  type: yup
    .string()
    .required(),
  targets: yup
    .array()
    .required()
    .test(truthyTargetsTest)
    .when("type", {
      is: ShareProfileTypeEnum.Email,
      then: yup.array().of(
        yup.object().shape({
          value: yup
            .string()
            .email(),
        }),
      ),
      otherwise: yup.array().of(
        yup.object().shape({
          value: yup
            .string()
            .test(makePhoneNumberTest(false)),
        }),
      ),
    }),
});

export interface TargetSchema {
  value: string;
}

export interface ShareProfileSchema {
  type: ShareProfileTypeEnum;
  targets: TargetSchema[];
}

export default ShareProfileSchema;
