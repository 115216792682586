import { useMemo } from "react";

import { useGetCurrentCompanyJobsQuery } from "generated/graphql";

import { UseCompanyOnboardingJobResult } from "./types";

/**
 * Exposes the `job` object created on the first step of the company onboarding process.
 * The `job` returned will be the first item of currentUser.member.company.jobs.
 */
const useCompanyOnboardingJob = (): UseCompanyOnboardingJobResult => {
  const {
    loading,
    data,
  } = useGetCurrentCompanyJobsQuery({
    fetchPolicy: "network-only",
  });

  const job = data?.currentUser?.member?.company?.jobs?.[0];

  const payload = useMemo<UseCompanyOnboardingJobResult>(() => [
    job,
    loading,
  ], [
    job,
    loading,
  ]);

  return payload;
};

export default useCompanyOnboardingJob;
