import React from "react";
import numeral from "numeral";
import {
  Stack,
  Flex,
  Text,
} from "@chakra-ui/react";

import { PlanCardTitleProps } from "../types";

const PlanCardTitle: React.FC<PlanCardTitleProps> = ({
  plan,
}) => (
  <Stack
    justifyContent="center"
    alignItems="center"
    position="relative"
    overflow="hidden"
    isInline
    px={8}
    py={5}
  >
    <Text
      textTransform="uppercase"
      fontSize="sm"
    >
      {plan.name}
    </Text>

    {
      plan.activeDiscount && (
        <Flex
          transform="rotate(45deg)"
          bgColor="status.success"
          justifyContent="center"
          position="absolute"
          alignItems="center"
          boxShadow="sm"
          right={-6}
          w="100px"
          top={4}
          px={4}
          h={6}
        >
          <Text
            fontFamily="bold"
            fontSize="xxs"
            color="white"
          >
            {`-${numeral(plan.activeDiscount.discount?.rate).format("0%")}`}
          </Text>
        </Flex>
      )
    }
  </Stack>
);

export default PlanCardTitle;
