import * as React from "react";
import { IconProps } from "@chakra-ui/icons";
import { createIcon } from "@chakra-ui/react";

const UploadIcon: React.FC<IconProps> = createIcon({
  viewBox: "0 0 80 60",
  path: (
    <>
      <defs>
        <linearGradient id="linear-gradient" x1="0.5" y1="1" x2="0.5" gradientUnits="objectBoundingBox">
          <stop offset="0" stopColor="#1b7291" />
          <stop offset="0.304" stopColor="#488cab" />
          <stop offset="1" stopColor="#b0c7e5" />
        </linearGradient>
      </defs>
      <g id="Group_14407" data-name="Group 14407" transform="translate(0 -45.5)">
        <g id="Group_14027" data-name="Group 14027" transform="translate(0 45.5)">
          <path fill="url(#linear-gradient)" id="Path_5746" data-name="Path 5746" d="M63.437,97.83H44.687a2.354,2.354,0,0,1-2.344-2.364V85.851a2.358,2.358,0,0,1,2.043-2.345l-6.731-8.148-6.731,8.148a2.357,2.357,0,0,1,2.043,2.345v9.615a2.354,2.354,0,0,1-2.344,2.364H16.562a16.694,16.694,0,0,1-7.173-31.7A21.143,21.143,0,0,1,48.183,55.011c.411-.036.8-.053,1.192-.053a16.552,16.552,0,0,1,14.87,9.477,16.712,16.712,0,0,1-.807,33.4Z" transform="translate(0 -45.5)" />
        </g>
        <path fill="#d2e6ed" id="Path_5747" data-name="Path 5747" d="M168.531,231.87H156.843A1.957,1.957,0,0,1,154.9,229.9V212.22h-1.948a1.947,1.947,0,0,1-1.764-1.132,1.979,1.979,0,0,1,.268-2.091l9.74-11.79a1.937,1.937,0,0,1,2.993,0l9.74,11.79a1.979,1.979,0,0,1,.268,2.091,1.947,1.947,0,0,1-1.764,1.132h-1.948V229.9A1.957,1.957,0,0,1,168.531,231.87Z" transform="translate(-125.031 -126.37)" />
      </g>
    </>
  ),
});

export default UploadIcon;
