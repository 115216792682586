import yup from "settings/yup";

const updateApplicantSchema = yup.object().shape({
  status: yup
    .string(),
  responsibleRecruiterId: yup
    .mixed(),
  interviewAt: yup
    .date(),
  recruiterRating: yup
    .number(),
  recruiterNotes: yup
    .string(),
});

export interface UpdateApplicantSchema {
  status: string;
  responsibleRecruiterId: number;
  interviewAt: Date;
  recruiterRating: number;
  recruiterNotes: string;
}

export default updateApplicantSchema;
