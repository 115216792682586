import { Divider, Stack, Text } from "@chakra-ui/react";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { TrackingFiltersContainerProps } from "components/Tracking/types";
import TextLink from "components/TextLink";
import Box from "components/Box";
import { useTrackingFiltersQueries } from "contexts/trackingFiltersQueries";

const TrackingFiltersContainer: React.FC<TrackingFiltersContainerProps> = ({
  filters,
  activeFiltersCount,
}) => {
  const [t] = useTranslation();
  const { loading } = useTrackingFiltersQueries();

  const handleClearFilters = useCallback(() => {
    window.location.search = "";
  }, []);

  return (
    <Stack
      as={Box}
      h="auto"
      w="full"
      p={4}
    >
      <Stack
        justifyContent="space-between"
        isInline
      >
        <Text
          fontFamily="bold"
          fontSize="xs"
        >
          {t("buttons.filters")}
          {
            !loading && (
              ` (${activeFiltersCount})`
            )
          }
        </Text>

        <TextLink
          onClick={handleClearFilters}
          fontFamily="bold"
          fontSize="xs"
          color="black"
        >
          {t("buttons.reset_filters")}
        </TextLink>
      </Stack>

      <Divider bgColor="gray.200" />

      {
        filters.map(({
          component: Component,
          props,
        }, index) => (
          <Component key={String(index)} {...(props || {})} />
        ))
      }
    </Stack>
  );
};

export default TrackingFiltersContainer;
