import React from "react";
import { useTranslation } from "react-i18next";
import { Stack, Text } from "@chakra-ui/react";

import ListItem from "components/ListItem";
import { avoidPageBreakAfterCss } from "styles/css";

import parseAdditionalInformationItems from "./parseAdditionalInformationItems";
import { AdditionalInformationDataProps } from "./types";
import { makeAnswerItemStackStyles } from "./styles";

const AdditionalInformationData: React.FC<AdditionalInformationDataProps> = ({
  answerGroupsCount,
  profileType,
  data,
}) => {
  const [t] = useTranslation();

  const additionalInformationItems = parseAdditionalInformationItems({
    profileType,
    data,
  });

  const stackStyles = makeAnswerItemStackStyles({
    answerGroupsCount,
    position: 0,
  });

  return (
    <Stack {...stackStyles}>
      <Text
        fontFamily="bold"
        fontSize="md"
        mb={3}
        css={avoidPageBreakAfterCss}
      >
        {`${t("forms.steps.personal_information.title")}:`}
      </Text>

      <Stack spacing={4}>
        {
          additionalInformationItems.map((item) => (
            <ListItem
              title={item.title}
              value={item.value}
              key={item.title}
            />
          ))
        }
      </Stack>
    </Stack>
  );
};

export default AdditionalInformationData;
