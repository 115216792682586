import { useCallback, useMemo } from "react";
import {
  DelimitedNumericArrayParam,
  UrlUpdateType,
  useQueryParam,
  StringParam,
  NumberParam,
  withDefault,
} from "use-query-params";

import { Filter, UseFilterResult } from "./types";

/**
 * Exposes possible filters.
 */
export const filters = {
  search: {
    name: "search",
    type: withDefault(StringParam, ""),
  },
  jobId: {
    name: "jobId",
    type: StringParam,
  },
  orderBy: {
    name: "orderBy",
    type: StringParam,
  },
  locations: {
    name: "locations",
    type: StringParam,
  },
  isFullTime: {
    name: "isFullTime",
    type: StringParam,
  },
  status: {
    name: "status",
    type: StringParam,
  },
  roleFitScore: {
    name: "roleFitScore",
    type: withDefault(DelimitedNumericArrayParam, [0, 100]),
  },
  applicantId: {
    name: "applicantId",
    type: NumberParam,
  },
  page: {
    name: "page",
    type: withDefault(NumberParam, 1),
  },
  stepIndex: {
    name: "step",
    type: withDefault(NumberParam, 1),
  },
  landingPageLayoutLabel: {
    name: "layoutLabel",
    type: StringParam,
  },
  selectedStep: {
    name: "selectedStep",
    type: withDefault(NumberParam, 1),
  },
};

/**
 * Exposes the useQueryParam hook as used by filters.
 * @param filter The filter.
 */
const useFilter = <T>(
  filterObject: Filter,
  updateType: UrlUpdateType = "replaceIn",
): UseFilterResult<T> => {
  const [filter, setFilter] = useQueryParam(filterObject.name, filterObject.type);

  const handleSetFilter = useCallback((value: unknown) => {
    setFilter(value, updateType);
  }, [setFilter, updateType]);

  const payload = useMemo<UseFilterResult<T>>(() => [
    filter,
    handleSetFilter,
  ], [
    filter,
    handleSetFilter,
  ]);

  return payload;
};

export default useFilter;
