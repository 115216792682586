import React from "react";
import { Box } from "@chakra-ui/react";

import { adminImpersonationSelectHeight } from "components/AdminImpersonation/AdminImpersonationSelect";
import useAdminImpersonationStore from "components/AdminImpersonation/store";
import { headerHeight } from "components/Header";

import DashboardTokensAlert from "./DashboardTokensAlert";
import mapDashboardPage from "./mapDashboardPage";
import { navbarWidth } from "../DashboardNavbar";
import { DashboardContentProps } from "../types";

const dashboardExtraMarginTop = 10;

const DashboardContent: React.FC<DashboardContentProps> = ({
  isModifyingSubscription,
  isManagerOrAdmin,
  activePage,
  buttons,
}) => {
  const Page = mapDashboardPage({
    isModifyingSubscription,
    page: activePage,
    buttons,
  });

  const isAdminImpersonationVisible = useAdminImpersonationStore((store) => store.isVisible);

  const marginTop = dashboardExtraMarginTop + headerHeight.value + Number(
    isAdminImpersonationVisible
    && adminImpersonationSelectHeight.value,
  );

  return (
    <Box
      w={`calc(100% - ${navbarWidth.css})`}
      ml={navbarWidth.css}
      position="relative"
      mt={marginTop}
      h="full"
      p={6}
    >
      <DashboardTokensAlert
        isManagerOrAdmin={isManagerOrAdmin}
      />

      {
        Page && (
          <Page />
        )
      }
    </Box>
  );
};

export default DashboardContent;
