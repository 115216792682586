import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import numeral from "numeral";

import {
  CardContentProps,
  CardInfoItem,
} from "components/Tracking/Cards/types";
import { Client, ClientRespondedEnum, ClientStatusEnum } from "generated/graphql";
import DescriptionIcon from "settings/theme/icons/DescriptionIcon";
import { mapRespondedByEnumTexts } from "constants/respondedBy";
import { formatDate, monthDayYear } from "utils/dateFormats";
import CalendarIcon from "settings/theme/icons/CalendarIcon";
import { clientStatuses } from "constants/statuses";
import buildName from "utils/buildName";
import { FIT_LABEL_FONT_SIZE } from "constants/fitLabelFontSize";

/**
 * Exposes the logic of Client Card, and creates the props to render the component.
 * @param props Card props.
 */
const useClientCard = (client?: Client): CardContentProps => {
  const [t] = useTranslation();

  const {
    picturesUrl,
    submittedAt,
    respondedBy,
    firstName,
    lastName,
    status,
  } = client ?? {};

  const infoItems = useMemo<CardInfoItem[]>(() => ([
    {
      text: t("tracking_cards.filled_by", {
        respondedBy: mapRespondedByEnumTexts[respondedBy as ClientRespondedEnum],
      }),
      icon: DescriptionIcon,
    },
    {
      text: t("tracking_cards.date_submitted", {
        formattedDate: formatDate(submittedAt, monthDayYear),
      }),
      icon: CalendarIcon,
    },
  ]), [
    t,
    submittedAt,
    respondedBy,
  ]);

  const roleFitScore = client?.assessment?.roleFitScore;

  const payload = useMemo<CardContentProps>(() => ({
    status: clientStatuses[status as ClientStatusEnum],
    fullName: buildName(firstName, lastName),
    fitLabel: t("tracking_cards.care_match"),
    fitContainerSize: "lg",
    roleFitScore,
    picturesUrl,
    infoItems,
    formattedRoleFitScore: numeral(roleFitScore).format("0%"),
    fitLabelFontSize: FIT_LABEL_FONT_SIZE,
  }), [
    roleFitScore,
    picturesUrl,
    infoItems,
    firstName,
    lastName,
    status,
    t,
  ]);

  return payload;
};

export default useClientCard;
