import React from "react";
import { Heading, Stack, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import { FormTypeEnum } from "generated/graphql";
import Image from "components/Images/Image";

import { FormPreviewIntroductionProps } from "../types";

const FormPreviewIntroduction: React.FC<FormPreviewIntroductionProps> = ({
  form,
}) => {
  const [t] = useTranslation();

  const company = form?.job?.publicCompanyInfo;

  const introductoryMessage = ({
    [FormTypeEnum.ShortApplication]: null,
    [FormTypeEnum.Application]: form?.applicantIntroductoryMessage,
    [FormTypeEnum.Client]: form?.clientIntroductoryMessage,
  })[form?.formType ?? ""];

  const formTypeTitle = ({
    [FormTypeEnum.ShortApplication]: t("forms.short_applicant_form.subtitle", { roleName: form?.job?.role?.name }),
    [FormTypeEnum.Application]: t("forms.applicant_form.subtitle", { roleName: form?.job?.role?.name }),
    [FormTypeEnum.Client]: t("forms.client_form.subtitle", { roleName: form?.job?.role?.name }),
  })[form?.formType ?? ""];

  return (
    <Stack spacing={8}>
      {
        company?.logoUrl
          ? (
            <Image
              src={company.logoUrl}
              alt={company?.name}
              borderRadius={0}
              height="120px"
              width="auto"
            />
          )
          : (
            <Text
              fontSize={["md", "lg", "xl"]}
              fontFamily="heading"
              textAlign="center"
              color="black"
            >
              {company?.name}
            </Text>
          )
      }

      <Heading
        textAlign="center"
        fontFamily="bold"
        fontSize="xl"
        color="black"
      >
        {formTypeTitle}
      </Heading>

      {
        introductoryMessage && (
          <Text
            textAlign="left"
            color="gray.400"
            fontSize="md"
          >
            {introductoryMessage}
          </Text>
        )
      }
    </Stack>
  );
};

export default FormPreviewIntroduction;
