import React, { useCallback } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Stack } from "@chakra-ui/react";

import CompanyOnboardingForm from "components/Forms/CompanyOnboardingForm";
import PageHeader from "components/PageHeaders/PageHeader";
import useViewForm from "hooks/useViewForm";
import { ROOT_PAGE_PATH } from "routes";
import Button from "components/Button";
import useToast from "hooks/useToast";

import { EditFormProps } from "./types";

const EditForm: React.FC<EditFormProps> = ({
  useSteps,
  formType,
  formId,
  title,
}) => {
  const [showToast] = useToast();
  const [t] = useTranslation();
  const history = useHistory();

  const [viewForm, isViewFormLoading] = useViewForm();

  const handleSaveChanges = useCallback(() => {
    showToast({
      title: t("actions.information_updated"),
      status: "success",
    });

    history.push(ROOT_PAGE_PATH);
  }, [
    showToast,
    history,
    t,
  ]);

  const handleViewForm = useCallback(() => {
    if (!formId) {
      return;
    }

    viewForm({
      formType,
      formId,
    });
  }, [
    viewForm,
    formType,
    formId,
  ]);

  if (!formId) {
    return (
      <Redirect to={ROOT_PAGE_PATH} />
    );
  }

  return (
    <Stack spacing={6}>
      <PageHeader title={title}>
        <Button
          isLoading={isViewFormLoading}
          onClick={handleViewForm}
          isDisabled={!formId}
          variant="outline"
          fontSize="sm"
          size="sm"
        >
          {t("buttons.view")}
        </Button>

        <Button
          onClick={handleSaveChanges}
          fontSize="sm"
          size="sm"
        >
          {t("buttons.finish_editing")}
        </Button>
      </PageHeader>

      <CompanyOnboardingForm
        useSteps={useSteps}
        formType={formType}
        formId={formId}
      />
    </Stack>
  );
};

export default EditForm;
