/**
 * The last ChakraUI release introduces a new bug within the Collapse component.
 * In order to fix this for now we should create a custom style to set the
 * `overflow: initial` property to this component.
 *
 * This bug will be fixed soon, so keep an eye on these two PRs, to know
 * when to update the ChakraUI version again.
 *
 * Workaround discussion: https://github.com/chakra-ui/chakra-ui/issues/2966
 * Fix PR by Segun Adebayo: https://github.com/chakra-ui/chakra-ui/pull/3946
 *
 * Related files: [
 * src/settings/theme/index.ts
 * src/settings/theme/styles.ts
 * src/components/Tracking/Filters/index.tsx
 * ]
 */
import { ChakraTheme, theme as chakraUiTheme } from "@chakra-ui/react";

const styles: ChakraTheme["styles"] = {
  ...chakraUiTheme.styles,
  global: {
    ":not(.chakra-dont-set-collapse) > .chakra-collapse": {
      overflow: "initial !important",
    },
  },
};

export default styles;
