import { useCallback, useMemo } from "react";

import { useModal } from "contexts/modal";

import ShareProfileModal from "./ShareProfileModal";
import getModalTitle from "./getModalTitle";
import {
  OpenShareProfileModal,
  UseShareProfileResult,
} from "./types";

/**
 * Exposes a function to allow the user to share an applicant or client profile.
 *
 * When called, the function will open the modal and share the profile upon confirmation.
 *
 * The mutation loading state is also exposed.
 *
 * In case the mutation fails, a toast will be shown with the error.
 */
const useShareProfile = (): UseShareProfileResult => {
  const [showModal] = useModal();

  const openShareProfileModal = useCallback<OpenShareProfileModal>((options) => {
    const modalTitle = getModalTitle(options.type);

    showModal({
      title: modalTitle,
      component: ShareProfileModal,
      componentProps: {
        options,
      },
    });
  }, [
    showModal,
  ]);

  const payload = useMemo<UseShareProfileResult>(() => [
    openShareProfileModal,
  ], [
    openShareProfileModal,
  ]);

  return payload;
};

export default useShareProfile;
