import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import * as R from "remeda";

import useGenerateProfilePdf from "hooks/useGenerateProfilePdf";
import PrintIcon from "settings/theme/icons/PrintIcon";
import ShareIcon from "settings/theme/icons/ShareIcon";
import useShareProfile from "hooks/useShareProfile";
import Button from "components/Button";

import { TrackingProfileButtonsProps } from "./types";

const TrackingProfileButtons: React.FC<TrackingProfileButtonsProps> = ({
  profileType,
  name,
  id,
}) => {
  const [t] = useTranslation();

  const [generateProfilePdf, isGenerateProfilePdfLoading] = useGenerateProfilePdf();
  const [shareProfile] = useShareProfile();

  const handleShareProfile = useCallback(() => {
    shareProfile({
      type: profileType,
      name,
      id,
    });
  }, [
    shareProfile,
    profileType,
    name,
    id,
  ]);

  const handlePrint = useCallback(() => {
    generateProfilePdf({
      profileType,
      id,
    })
      .then((profilePdfUrl) => {
        window.open(profilePdfUrl, "_blank");
      })
      .catch(R.noop);
  }, [
    generateProfilePdf,
    profileType,
    id,
  ]);

  return (
    <>
      <Button
        onClick={handleShareProfile}
        rightIcon={(<ShareIcon />)}
        variant="outline"
        color="black"
        size="sm"
      >
        {t("buttons.share")}
      </Button>

      <Button
        isLoading={isGenerateProfilePdfLoading}
        rightIcon={(<PrintIcon />)}
        onClick={handlePrint}
        variant="outline"
        color="black"
        size="sm"
      >
        {t("buttons.print")}
      </Button>
    </>
  );
};

export default TrackingProfileButtons;
