import * as React from "react";
import { IconProps } from "@chakra-ui/icons";
import { createIcon } from "@chakra-ui/react";

const LogoIcon: React.FC<IconProps> = createIcon({
  viewBox: "0 0 479.922 186.267",
  defaultProps: {
    width: "135px",
    height: "50px",
  },
  path: (
    <g id="Group_13749" data-name="Group 13749" transform="translate(-101.289 -199.25)">
      <g id="hexagon" transform="translate(101.289 199.25)">
        <path id="Path_1" data-name="Path 1" d="M262.6,245.817V338.95l-80.656,46.567L101.289,338.95V245.817l80.656-46.567Zm-80.656-32.6L113.387,252.8v79.164l68.558,39.582L250.5,331.965V252.8Z" transform="translate(-101.289 -199.25)" />
      </g>
      <g id="M" transform="translate(125.727 231.233)">
        <g id="Group_2" data-name="Group 2" transform="translate(0 28.765)">
          <g id="Group_1" data-name="Group 1">
            <path id="Path_2" data-name="Path 2" d="M601.672,438.164v64.843l-12.927,7.463s0,0-.005,0V445.629Z" transform="translate(-489.236 -438.164)" />
            <path id="Path_3" data-name="Path 3" d="M334.833,504.349v.147l-43.286,24.992L248.26,504.5v-.147l12.929-7.465,30.357,17.527L321.9,496.884Z" transform="translate(-235.328 -481.954)" />
            <path id="Path_4" data-name="Path 4" d="M550.819,467.524v14.931l-12.929-7.465Z" transform="translate(-451.315 -460.059)" />
            <path id="Path_5" data-name="Path 5" d="M261.189,474.989l-12.929,7.465V467.524Z" transform="translate(-235.328 -460.059)" />
            <path id="Path_6" data-name="Path 6" d="M248.278,722.564c-.005,0-.013,0-.018.005v-.015Z" transform="translate(-235.328 -650.243)" />
            <path id="Path_7" data-name="Path 7" d="M210.332,460.707v49.767L197.4,503.007V438.164l12.932,7.465v15.078Z" transform="translate(-197.4 -438.164)" />
          </g>
        </g>
        <text id="iliMatch" transform="translate(144.83 85)" fontSize="85" fontFamily="Avenir Book, Avenir, sans-serif"><tspan x="0" y="0">iliMatch</tspan></text>
        <text id="_" data-name="™" transform="translate(447.484 26.197)" fontSize="8" fontFamily="Avenir Book, Avenir, sans-serif"><tspan x="0" y="0">™</tspan></text>
      </g>
    </g>
  ),
});

export default LogoIcon;
