import React from "react";
import { useTranslation } from "react-i18next";
import {
  Grid, Text, Flex,
} from "@chakra-ui/react";
import ConditionalWrap from "conditional-wrap";

import Checkbox from "components/FormComponents/Checkbox";
import PrintableCheckMarkIcon from "settings/theme/icons/PrintableCheckMarkIcon";

import parseAvailabilityOptions from "./parseAvailabilityOptions";
import { AvailabilityRowProps } from "./types";
import { checkBoxCss } from "./styles";

const AvailabilityRow: React.FC<AvailabilityRowProps> = ({
  firstRowWithTitles = false,
  hideLabels = false,
  lite = false,
  handleOnChange,
  availability,
  isDisabled,
}) => {
  const [t] = useTranslation();

  const availabilityOptions = parseAvailabilityOptions(availability);

  return (
    <Grid
      _last={{ borderBottomWidth: 0 }}
      templateColumns={`${lite ? "30px" : "150px"} 1fr`}
      borderBottomWidth="1px"
      borderColor="gray.200"
      alignItems="center"
      minW="max-content"
      w="full"
      gap={5}
      css={{
        marginTop: "0 !important",
      }}
    >
      <Text
        fontSize={lite ? "14px" : "xs"}
        whiteSpace="nowrap"
        fontWeight="bold"
      >
        {
          !lite
            ? t(`days.${availability.day.toLowerCase()}`)
            : t(`days.${availability.day.toLowerCase()}`)?.substring(0, 3)
        }
      </Text>

      <Grid
        templateColumns="repeat(4, 1fr)"
        justifyContent="space-between"
        borderColor="gray.200"
        borderLeftWidth="1px"
        alignItems="center"
        w="full"
        gap={3}
        p={3}
      >
        {
          availabilityOptions.map((option) => (
            <ConditionalWrap
              key={option.field}
              condition={firstRowWithTitles}
              wrap={(children) => (
                <Flex
                  alignItems="center"
                  position="relative"
                  flexDir="column"
                >

                  {children}
                </Flex>
              )}
            >
              <>
                {
                  firstRowWithTitles && (
                    <Text
                      position="absolute"
                      fontWeight="bold"
                      fontSize="14px"
                      top={-10}
                    >
                      {option?.field.charAt(0).toUpperCase() + option?.field.slice(1)}
                    </Text>
                  )
                }

                <Checkbox
                  onChange={handleOnChange(availability, option.field)}
                  icon={<PrintableCheckMarkIcon />}
                  isChecked={option.checked}
                  isReadOnly={isDisabled}
                  name={option.label}
                  __css={checkBoxCss}
                  key={option.label}
                  style={
                    lite
                      ? {
                        display: "flex",
                        justifyContent: "center",
                      }
                      : {}
                  }
                >
                  { !hideLabels && (option.label)}
                </Checkbox>
              </>
            </ConditionalWrap>
          ))
        }
      </Grid>
    </Grid>
  );
};

export default AvailabilityRow;
