import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import Table from "components/Table";

import { makeTableColumns } from "./tableColumns";
import { CreditCardsTableProps } from "../types";

const CreditCardsTable: React.FC<CreditCardsTableProps> = ({
  setCardAsDefault,
  creditCards,
  removeCard,
}) => {
  const [t] = useTranslation();

  const columns = useMemo(() => (
    makeTableColumns({
      setCardAsDefault,
      removeCard,
    })
  ), [
    setCardAsDefault,
    removeCard,
  ]);

  return (
    <Table
      title={t("dashboard.wallet.my_credit_cards")}
      data={creditCards}
      columns={columns}
      titleProps={{
        textTransform: "uppercase",
      }}
      containerProps={{
        rounded: "lg",
        shadow: "md",
      }}
    />
  );
};

export default CreditCardsTable;
