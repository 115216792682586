import { useMemo } from "react";
import { useQueryParams } from "use-query-params";
import * as R from "remeda";

import { filters } from "hooks/useFilter";
import { LocationFilterOption } from "components/Tracking/types";
import { availabilityFullTimeOption } from "components/Tracking/Filters/AvailabilityFilter/availabilityOptions";

import { UseApplicantFiltersParamsPayload } from "./types";

/**
 * Returns the applicant filters param parsed from query params
 */
const useApplicantFiltersParams = (): UseApplicantFiltersParamsPayload => {
  const [applicantFiltersParams] = useQueryParams({
    [filters.jobId.name]: filters.jobId.type,
    [filters.isFullTime.name]: filters.isFullTime.type,
    [filters.locations.name]: filters.locations.type,
    [filters.status.name]: filters.status.type,
  });

  const {
    jobId,
    status,
    locations,
    isFullTime,
  } = applicantFiltersParams ?? {};

  const parsedApplicantFiltersParam = useMemo(() => ({
    jobId: parseInt(jobId as string),
    status: (
      status
        ? JSON.parse(status)
        : undefined
    ),
    locations: (
      locations
        ? (JSON.parse(locations) ?? [])
          .map((location: LocationFilterOption) => location.value)
        : undefined
    ),
    isFullTime: (
      isFullTime
        ? isFullTime === availabilityFullTimeOption.value
        : undefined
    ),
  }), [
    jobId,
    status,
    locations,
    isFullTime,
  ]);

  const hasAvailabilityAsAll = !applicantFiltersParams.isFullTime;

  const activeFiltersCount = R.pipe(
    Object.keys(parsedApplicantFiltersParam),
    R.filter((key) => !!parsedApplicantFiltersParam[key]),
  ).length + Number(hasAvailabilityAsAll);

  const payload = useMemo<UseApplicantFiltersParamsPayload>(() => [
    parsedApplicantFiltersParam,
    activeFiltersCount,
  ], [
    parsedApplicantFiltersParam,
    activeFiltersCount,
  ]);

  return payload;
};

export default useApplicantFiltersParams;
