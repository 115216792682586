import React from "react";
import { Redirect, useParams } from "react-router-dom";

import { useGetFormLandingPageByShortcodeQuery } from "generated/graphql";
import LandingPage from "components/LandingPage";
import Loading from "components/Loading";
import { ROOT_PAGE_PATH } from "routes";

import { FormPageParams } from "../types";

const FormLandingPage: React.FC = () => {
  const { shortCode } = useParams<FormPageParams>();

  const {
    loading,
    data,
  } = useGetFormLandingPageByShortcodeQuery({
    skip: !shortCode,
    variables: {
      shortcode: shortCode,
    },
  });

  const landingPage = data?.getFormByShortcode?.job?.landingPage;
  const formType = data?.getFormByShortcode?.formType;

  if (loading) {
    return (
      <Loading />
    );
  }

  if (!formType || !shortCode || !landingPage) {
    return (
      <Redirect to={ROOT_PAGE_PATH} />
    );
  }

  return (
    <LandingPage
      landingPage={landingPage}
      shortCode={shortCode}
      formType={formType}
    />
  );
};

export default FormLandingPage;
