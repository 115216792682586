import { ParsedAvailability } from "components/AvailabilityMatrix/types";
import yup from "settings/yup";

const availabilityMatrixSchema = yup.array().of(
  yup.object().shape({
    day: yup
      .string()
      .required(),
    afternoon: yup
      .boolean()
      .required(),
    evening: yup
      .boolean()
      .required(),
    morning: yup
      .boolean()
      .required(),
    overnight: yup
      .boolean()
      .required(),
  }),
);

export type AvailabilityMatrixSchema = ParsedAvailability[];

export default availabilityMatrixSchema;
