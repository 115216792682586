/**
 * Checks if a value is defined and non-null.
 *
 * Will return true if and only if value is not `undefined` or `null`.
 *
 * Empty strings, false values and 0 are considered present.
 *
 * @param value
 */
const isPresent = (value: unknown): boolean => (value !== undefined && value !== null);

export default isPresent;
