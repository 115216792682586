import truthyTargetsTest from "settings/yup/tests/truthyTargetsTest";
import { makePhoneNumberTest } from "settings/yup/tests/phoneNumberTest";
import { SelectOption } from "components/FormComponents/Select/types";
import yup from "settings/yup";

export enum SendAssessmentTypeEnum {
  Email = "EMAIL",
  SMS = "SMS",
}

const sendAssessmentSchema = yup.object().shape({
  role: yup
    .string()
    .required(),
  type: yup
    .string()
    .required(),
  targets: yup
    .array()
    .required()
    .test(truthyTargetsTest)
    .when("type", {
      is: SendAssessmentTypeEnum.Email,
      then: yup.array().of(
        yup.object().shape({
          value: yup
            .string()
            .email(),
        }),
      ),
      otherwise: yup.array().of(
        yup.object().shape({
          value: yup
            .string()
            .test(makePhoneNumberTest(false)),
        }),
      ),
    }),
});

export interface TargetSchema {
  value: string;
}

export interface SendAssessmentSchema {
  role?: SelectOption;
  type: SendAssessmentTypeEnum;
  targets: TargetSchema[];
}

export default sendAssessmentSchema;
