import React, { useEffect, useMemo } from "react";
import { Divider, Stack, Wrap } from "@chakra-ui/react";

import Progress from "components/Progress";

import useWizardStore, { INITIAL_STORE_STEP_INDEX } from "./store";
import WizardHeader from "./WizardHeader";
import { WizardProps } from "./types";

const Wizard: React.FC<WizardProps> = ({
  colorScheme = "primary",
  initialStepIndex = 0,
  showDivider = false,
  steps,
}) => {
  const stepIndex = useWizardStore((store) => store.stepIndex);

  const step = useMemo(() => steps[stepIndex], [
    stepIndex,
    steps,
  ]);

  useEffect(() => {
    if (stepIndex !== INITIAL_STORE_STEP_INDEX) {
      return;
    }

    useWizardStore.setState({
      stepIndex: initialStepIndex,
      steps,
    });
  });

  if (!step?.id) {
    return null;
  }

  const progressValue = ((stepIndex + 1) * 100) / steps.length;

  const HeaderComponent = step.headerComponent;
  const Component = step.component;

  return (
    <Stack spacing={8}>
      <Progress value={progressValue} />

      <Wrap
        justify="space-between"
        align="center"
        spacing={4}
      >
        <WizardHeader
          colorScheme={colorScheme}
          step={step}
        />

        {
          HeaderComponent && (
            <HeaderComponent />
          )
        }
      </Wrap>

      {
        showDivider && (
          <Divider bgColor="gray.100" />
        )
      }

      <Component />
    </Stack>
  );
};

export default Wizard;
