import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  FormControl,
  Input,
  Stack,
  Text,
} from "@chakra-ui/react";

import FieldErrorMessage from "components/FormComponents/FieldErrorMessage";
import FieldTitle from "components/FormComponents/FieldTitle";
import makeProgressiveArray from "utils/makeProgressiveArray";

import { StarRatingsProps } from "./types";
import Star from "./Star";

const StarRatings = React.forwardRef<HTMLInputElement, StarRatingsProps>((
  {
    colorScheme = "primary",
    showErrorMessage = true,
    showIndicators = true,
    clickable = true,
    count = 10,
    value = 0,
    titleProps,
    className,
    onChange,
    errors,
    title,
    name,
  },
  ref,
) => {
  const [rating, setRating] = useState(value);

  const [t] = useTranslation();

  const handleOnClick = useCallback((newRating: number) => {
    setRating(newRating);

    if (onChange) {
      onChange(newRating);
    }
  }, [
    onChange,
  ]);

  const stars = useMemo(() => (
    makeProgressiveArray(count)
  ), [count]);

  const error = name && errors?.[name]?.message;

  const stackJustifyContent = showIndicators ? "space-between" : "flex-start";

  return (
    <FormControl
      className={className}
    >
      {
        title && (
          <FieldTitle
            htmlFor={name}
            {...(titleProps ?? {})}
          >
            {title}
          </FieldTitle>
        )
      }

      <Input
        display="none"
        value={rating}
        isReadOnly
        name={name}
        ref={ref}
      />

      <Stack
        className={className}
        spacing={2}
      >
        <Stack
          justifyContent={stackJustifyContent}
          alignItems="center"
          spacing={2}
          isInline
        >
          {
            stars.map((position) => (
              <Star
                colorScheme={colorScheme}
                clickable={clickable}
                onClick={handleOnClick}
                position={position}
                rating={rating}
                key={position}
              />
            ))
          }
        </Stack>

        {
          showIndicators && (
            <Stack
              justifyContent={stackJustifyContent}
              isInline
            >
              <Text fontSize="xxs">
                {t("buttons.low")}
              </Text>

              <Text fontSize="xxs">
                {t("buttons.high")}
              </Text>
            </Stack>
          )
        }
      </Stack>

      {
        showErrorMessage && (
          <FieldErrorMessage
            textAlign="end"
            error={error}
          />
        )
      }
    </FormControl>
  );
});

export default StarRatings;
