import {
  useCallback,
  useEffect,
  useState,
  useMemo,
} from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import * as R from "remeda";

import { createOrUpdateLandingPageRefetchQueries } from "graphql/mutations/createOrUpdateLandingPageMutation";
import { LandingPageTemplateLayoutLabel } from "components/LandingPage/Templates/landingPagesTemplatesData";
import { EDIT_LANDING_PAGE_CONFIGURATION_PAGE_LOCATION } from "routes/locations";
import { LandingPageTemplate } from "components/LandingPage/Templates/types";
import { onMutationSuccess, onQueryError } from "utils/queryHandlers";
import { SelectOption } from "components/FormComponents/Select/types";
import useGetCompany from "hooks/useGetCompany";
import { filters } from "hooks/useFilter";
import useToast from "hooks/useToast";
import {
  useGetEditLandingPageTemplateImpersonatedCompanyQuery,
  useGetEditLandingPageTemplateCurrentCompanyQuery,
  useCreateOrUpdateLandingPageMutation,
  useDeleteLandingPageMutation,
  GetEditLandingPageTemplateImpersonatedCompanyDocument,
  GetEditLandingPageTemplateCurrentCompanyDocument,
} from "generated/graphql";
import buildRefetchQueriesWithVariables from "utils/buildRefetchQueriesWithVariables";

import { UseEditLandingPageTemplateFormResult } from "./types";

/**
 * Hook that exposes data to be rendered in edit landing page template form.
 */
const useEditLandingPageTemplateForm = (): UseEditLandingPageTemplateFormResult => {
  const [layoutLabel, setLayoutLabel] = useState<LandingPageTemplateLayoutLabel>();
  const [selectedJob, setSelectedJob] = useState<SelectOption>();

  const [showToast] = useToast();
  const [t] = useTranslation();
  const history = useHistory();

  const [company, { loading: companyLoading }] = useGetCompany(
    useGetEditLandingPageTemplateCurrentCompanyQuery,
    useGetEditLandingPageTemplateImpersonatedCompanyQuery,
    {
      fetchPolicy: "network-only",
    },
  );

  const [createOrUpdateLandingPage] = useCreateOrUpdateLandingPageMutation({
    refetchQueries: createOrUpdateLandingPageRefetchQueries,
    awaitRefetchQueries: true,
  });

  const refetchDeleteLandingPageQueries = buildRefetchQueriesWithVariables({
    currentCompanyQueries: [{
      query: GetEditLandingPageTemplateCurrentCompanyDocument,
    }],
    impersonatedCompanyQueries: [{
      query: GetEditLandingPageTemplateImpersonatedCompanyDocument,
      variables: {
        companyId: company?.id,
      },
    }],
  });

  const [deleteLandingPage] = useDeleteLandingPageMutation({
    refetchQueries: refetchDeleteLandingPageQueries,
    awaitRefetchQueries: true,
  });

  const jobs = useMemo(() => (
    company?.jobs ?? []
  ), [
    company,
  ]);

  const selectOptions = useMemo(() => R.pipe(
    jobs,
    R.map((job) => ({
      label: job.role.name,
      value: job.id,
    })),
  ), [
    jobs,
  ]);

  const handleEditTemplate = useCallback((landingPageLayoutLabel) => {
    const job = jobs.find((item) => item.id === selectedJob?.value);

    if (!job) {
      return;
    }

    const path = EDIT_LANDING_PAGE_CONFIGURATION_PAGE_LOCATION.toUrl({
      id: job.landingPage?.id,
    });

    const params = {
      [filters.landingPageLayoutLabel.name]: landingPageLayoutLabel,
      [filters.jobId.name]: selectedJob?.value,
    };

    history.push(`${path}?${queryString.stringify(params)}`);
  }, [
    selectedJob,
    history,
    jobs,
  ]);

  const onEditTemplate = useCallback(() => () => {
    handleEditTemplate(layoutLabel);
  }, [
    handleEditTemplate,
    layoutLabel,
  ]);

  const onTemplateChange = useCallback((template: LandingPageTemplate) => {
    const job = jobs.find((item) => item.id === selectedJob?.value);

    if (!template.layoutLabel || !job) {
      return;
    }

    if (!job?.landingPage?.id) {
      handleEditTemplate(template.layoutLabel);

      return;
    }

    setLayoutLabel(template.layoutLabel as LandingPageTemplateLayoutLabel);

    createOrUpdateLandingPage({
      variables: {
        params: {
          layoutLabel: template.layoutLabel,
          jobId: job.id,
        },
      },
    })
      .then(() => {
        onMutationSuccess(t("actions.information_updated"), showToast);
      })
      .catch((error) => {
        onQueryError(error, showToast);
      });
  }, [
    createOrUpdateLandingPage,
    handleEditTemplate,
    selectedJob,
    showToast,
    jobs,
    t,
  ]);

  const onTemplateDelete = useCallback((template: LandingPageTemplate) => {
    const job = jobs.find((item) => item.id === selectedJob?.value);

    if (!template.layoutLabel || !job) {
      return;
    }

    deleteLandingPage({
      variables: {
        id: job.landingPage.id,
      },
    })
      .then(() => {
        onMutationSuccess(t("actions.information_updated"), showToast);
      })
      .catch(error => {
        onQueryError(error, showToast);
      });
  }, [
    deleteLandingPage,
    selectedJob,
    showToast,
    jobs,
    t,
  ]);

  /**
   * When loading the page, we need to initialize the select option with the first
   * available option that comes from the query.
   */
  useEffect(() => {
    // Do not update to first option if user has selected a new option
    if (selectOptions.some((item) => item.value === selectedJob?.value)) {
      return;
    }

    const firstOption = selectOptions?.[0];

    setSelectedJob(firstOption);
  }, [
    selectOptions,
    selectedJob,
  ]);

  /**
   * When selecting another job, we need to update the layout label to ensure that the user sees
   * the correct selected template and is going to be redirected correctly to edit the template.
   */
  useEffect(() => {
    const newLayoutLabel = jobs.find((item) => (
      item.id === selectedJob?.value
    ))?.landingPage?.layoutLabel as LandingPageTemplateLayoutLabel | undefined;

    setLayoutLabel(newLayoutLabel);
  }, [
    selectedJob,
    jobs,
  ]);

  const payload = useMemo<UseEditLandingPageTemplateFormResult>(() => ({
    isLoading: companyLoading,
    onTemplateChange,
    onTemplateDelete,
    setSelectedJob,
    onEditTemplate,
    selectOptions,
    selectedJob,
    layoutLabel,
  }), [
    onTemplateChange,
    onTemplateDelete,
    onEditTemplate,
    companyLoading,
    selectOptions,
    selectedJob,
    layoutLabel,
  ]);

  return payload;
};

export default useEditLandingPageTemplateForm;
