import i18n from "translations/i18n";

/**
 * Returns translation text according to availability value
 *
 * @param isFullTime The availability value
 */
const getAvailabilityText = (isFullTime: boolean): string => (
  isFullTime
    ? i18n.t("availability.full_time")
    : i18n.t("availability.part_time")
);

export default getAvailabilityText;
