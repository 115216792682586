import React, { useEffect } from "react";
import queryString from "query-string";
import { useHistory } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import Loading from "components/Loading";
import useSession from "hooks/useSession";
import { ROOT_PAGE_PATH } from "routes";
import useNavigate from "hooks/useNavigate";

const VerifyUser: React.FC = () => {
  const [t] = useTranslation();
  const [createSession] = useSession();

  const history = useHistory();
  const showToast = useToast();
  const navigate = useNavigate();

  const token = queryString.parse(history.location.search)?.token as string;

  useEffect(() => {
    if (!token) {
      navigate(ROOT_PAGE_PATH)();

      return;
    }

    createSession(token, ROOT_PAGE_PATH);

    showToast({
      title: t("actions.your_email_was_successfully_verified"),
      status: "success",
    });
  }, [
    t,
    token,
    navigate,
    showToast,
    createSession,
  ]);

  return (
    <Loading />
  );
};

export default VerifyUser;
