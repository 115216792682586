import { useForm, UseFormMethods } from "react-hook-form";
import { useMount, useUpdateEffect } from "react-use";
import { yupResolver } from "@hookform/resolvers";

import { UseCompanyOnboardingFormOptions } from "../types";
import useCompanyOnboardingFormStore from "../store";

/**
 * Handles the Form logic form a Company Onboarding form step.
 * This hook will initialize the `useForm` and expose the results as the returned value.
 * The company onboarding form store will also be updated automatically as the form state changes.
 */
function useCompanyOnboardingForm<Schema>({
  formOptions,
  schema,
}: UseCompanyOnboardingFormOptions<Schema>): UseFormMethods<Schema> {
  const [
    formType,
    formId,
  ] = useCompanyOnboardingFormStore((store) => [
    store.formType,
    store.form?.id,
  ]);

  const form = useForm<Schema>({
    resolver: yupResolver(schema),
    mode: "onChange",
    ...(formOptions ?? {}),
  });

  const {
    setValue,
    watch,
    reset,
  } = form;

  useMount(() => {
    useCompanyOnboardingFormStore.setState({
      setValue,
      watch,
    });
  });

  /**
   * If the user changes the form type, for example going from editing one applicant form
   * to one questionnaire, we need to reset the form values
   * according to the new default values.
   *
   * The same effect needs to happen when changing the form id, meaning that the user goes
   * from editing the applicant form 1 to applicant form 2.
   */
  useUpdateEffect(() => {
    if (!formType || !formId) {
      return;
    }

    reset(formOptions?.defaultValues);
  }, [
    formType,
    formId,
  ]);

  return form;
}

export default useCompanyOnboardingForm;
