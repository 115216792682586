import React from "react";
import * as R from "remeda";

import RemoveItemButton from "components/Forms/CompanyOnboardingForm/Cards/QuestionCard/RemoveItemButton";
import { QuestionCardField } from "components/Forms/CompanyOnboardingForm/Cards/QuestionCard/types";
import i18n from "translations/i18n";

import { MakeFieldsOptions } from "./types";

const baseFields: QuestionCardField[] = [
  {
    label: {
      text: i18n.t("company_onboarding.steps.requirements.question_1.field_1"),
    },
    value: {
      text: i18n.t("question_display_types.required_by_default"),
    },
  },
];

/**
 * Creates the fields to be rendered in requirements form.
 * @param param0 Options.
 */
const makeFields = ({
  removeItem,
  requirements = [],
}: MakeFieldsOptions): QuestionCardField[] => [
  ...baseFields,
  ...R.pipe(
    requirements,
    R.map((requirement): QuestionCardField => ({
      label: {
        text: requirement,
      },
      value: {
        render: ({
          fieldIndex,
        }) => (
          <RemoveItemButton
            removeItem={removeItem}
            item={requirement}
            fieldIndex={fieldIndex}
          />
        ),
      },
    })),
  ),
];

export default makeFields;
