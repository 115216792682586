import React from "react";
import { useTranslation } from "react-i18next";

import { ClientPreviewComponentProps } from "views/Forms/FormPreview/types";

import PreviewContent from "../PreviewComponents/PreviewContent";

const RespondedBy: React.FC<ClientPreviewComponentProps> = ({
  formResponse,
}) => {
  const [t] = useTranslation();

  return (
    <PreviewContent title={`${t("forms.steps.responded_by.who_is_filling_this_out")}*`}>
      {t([`responded_by.${String(formResponse.respondedBy).toLowerCase()}`, ""])}
    </PreviewContent>
  );
};

export default RespondedBy;
