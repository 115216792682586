import { FormStep } from "constants/formSteps";

/**
 * Defines if a step is enabled.
 *
 * @param step
 */
const isStepEnabled = (
  { isOptional, isVisible }: FormStep,
): boolean => !!(!isOptional || isVisible);

export default isStepEnabled;
