import React from "react";
import {
  MenuButton,
  MenuList,
  MenuItem,
  Stack,
  Menu,
  Text,
  Box,
} from "@chakra-ui/react";

import DropdownIcon from "settings/theme/icons/DropdownIcon";

import { statusUpdatesCardPeriod, StatusUpdatesCountCardHeaderProps } from "./types";

const badgeBoxSize = "12px";

const StatusUpdatesCountCardHeader: React.FC<StatusUpdatesCountCardHeaderProps> = ({
  showPeriodSelector,
  setSelectedPeriod,
  selectedPeriod,
  badgeColor,
  subtitle,
  title,
}) => (
  <Stack
    spacing={2}
    mb={3}
  >
    <Stack
      alignItems="center"
      isInline
    >
      <Box
        minHeight={badgeBoxSize}
        minWidth={badgeBoxSize}
        boxSize={badgeBoxSize}
        bgColor={badgeColor}
        borderRadius={100}
      />

      <Text
        textTransform="uppercase"
        fontFamily="heading"
        fontSize="xs"
      >
        {title}
      </Text>
    </Stack>

    {
      showPeriodSelector
        ? (
          <Menu>
            <MenuButton>
              <Stack
                alignItems="center"
                cursor="pointer"
                isInline
              >
                <Text
                  color="gray.400"
                  fontSize="xxs"
                >
                  {selectedPeriod}
                </Text>

                <DropdownIcon
                  color="gray.400"
                  boxSize={3}
                />
              </Stack>
            </MenuButton>

            <MenuList zIndex={16}>
              {
                Object.values(statusUpdatesCardPeriod).map((period) => (
                  <MenuItem
                    onClick={setSelectedPeriod(period)}
                    key={period}
                  >
                    {period}
                  </MenuItem>
                ))
              }
            </MenuList>
          </Menu>
        )
        : (
          <Text
            color="gray.400"
            fontSize="xxs"
          >
            {subtitle}
          </Text>
        )
    }
  </Stack>
);

export default StatusUpdatesCountCardHeader;
