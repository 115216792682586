import useAdminImpersonationStore from "components/AdminImpersonation/store";

import { BuildRefetchQueriesOptions, RefetchQueries } from "./types";

/**
 * Build the `refetchQueries` object to be used in an `useGetCompany` call, refetching
 * the correct queries according to the admin impersonation store state.
 *
 * @param param0 Options.
 */
const buildRefetchQueries = ({
  refetchImpersonatedCompanyQueries,
  refetchCurrentCompanyQueries,
}: BuildRefetchQueriesOptions): RefetchQueries | undefined => {
  const impersonatedCompanyId = useAdminImpersonationStore.getState()?.companyId;

  if (impersonatedCompanyId) {
    return refetchImpersonatedCompanyQueries;
  }

  return refetchCurrentCompanyQueries;
};

export default buildRefetchQueries;
