import { Integrations } from "@sentry/tracing";
import * as Sentry from "@sentry/react";

import { name, version } from "../../../package.json";
import denyUrls from "./denyUrls";

/**
 * Applies configuration for Sentry.
 */
const configureSentry = (): void => {
  if (process.env.NODE_ENV === "production") {
    Sentry.init({
      dsn: "https://06b1177e465449688fdb3cfe424c8d4c@o987713.ingest.sentry.io/5974636",
      environment: process.env.NODE_ENV,
      release: `${name}@${version}`,
      denyUrls,
      integrations: [
        new Integrations.BrowserTracing(),
      ],
      tracesSampleRate: 1.0,
    });

    Sentry.configureScope(scope => {
      scope.setTag("app", "frontend");
    });
  }
};

export default configureSentry;
