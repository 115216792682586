import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import useValidateCurrentCompanyAction from "hooks/useValidateCurrentCompanyAction";
import { useGetDefaultCreditCardQuery } from "generated/graphql";
import { onQueryError } from "utils/queryHandlers";
import { useModal } from "contexts/modal";
import Loading from "components/Loading";
import useToast from "hooks/useToast";

import PaymentPreviewModal from "./PaymentPreviewModal";
import AddCreditCardModal from "./AddCreditCardModal";
import { PaymentModalProps } from "./types";

/**
 * Modal component that queries for the company's default credit card and shows the payment
 * process according to it.
 *
 * If the company doesn't have a credit card, the modal to add a new credit card will be shown.
 *
 * If the company has a credit card, the payment preview modal will be shown with the card info.
 *
 * Upon confirming the payment in the rendered modal, the `onConfirm` callback is called.
 *
 * @param param0 Options.
 */
const PaymentModal: React.FC<PaymentModalProps> = ({
  hideModal,
  size,
  componentProps: {
    paymentInformation,
    onConfirm,
    companyId,
  },
}) => {
  const [showModal] = useModal();
  const [showToast] = useToast();
  const [t] = useTranslation();

  const [validateCurrentCompanyAction] = useValidateCurrentCompanyAction();

  const {
    loading,
    data,
  } = useGetDefaultCreditCardQuery({
    fetchPolicy: "network-only",
    onError: (error) => {
      onQueryError(error, showToast);
      hideModal();
    },
  });

  const defaultCreditCard = data?.currentUser?.member?.company?.defaultCreditCard;

  useEffect(() => {
    if (loading) {
      return;
    }

    validateCurrentCompanyAction(() => {
      if (defaultCreditCard) {
        showModal({
          size,
          title: t("buttons.confirm_payment"),
          component: PaymentPreviewModal,
          componentProps: {
            defaultCreditCard,
            paymentInformation,
            onConfirm,
          },
        });

        return;
      }

      showModal({
        size,
        title: t("buttons.add_new_card"),
        component: AddCreditCardModal,
        componentProps: {
          onSuccess: onConfirm,
          paymentInformation,
          companyId,
        },
      });
    });
  }, [
    validateCurrentCompanyAction,
    paymentInformation,
    defaultCreditCard,
    companyId,
    onConfirm,
    showModal,
    loading,
    size,
    t,
  ]);

  return (
    <Loading />
  );
};

export default PaymentModal;
