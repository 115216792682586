import { gql } from "@apollo/client";

export const deleteCreditCardRefetchQueries = [
  "ListCurrentCompanyCreditCards",
];

export default gql`
  mutation DeleteCreditCard ($id: Int!) {
    deleteCreditCard (id: $id) {
      id
    }
  }
`;
