import { ClientResponse } from "generated/graphql";

import { UseFormResponseResult } from "./types";
import useFormResponse from ".";

/**
 * Exposes the previous values already updated in a given client form response.
 * If no response has been created yet, `undefined` will be returned.
 */
function useClientFormResponse(): UseFormResponseResult<ClientResponse> {
  return useFormResponse<ClientResponse>();
}

export default useClientFormResponse;
