/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * Makes the component where this style is applied to be hidden when printing the page.
 */
export const noPrintCss = {
  "@media print": {
    display: "none",
  },
};

/**
 * Adjusts the margins on app wrapper when printing the page.
 */
export const appWrapperPrintCss = {
  "@media print": {
    margin: "0 !important",
  },
};

/**
 * Avoids page breaks inside the element when printing.
 */
export const avoidPageBreakInsideCss: any = {
  "@media print": {
    pageBreakInside: "avoid",
    display: "block",
    position: "relative",
    "> p": {
      position: "relative",
      pageBreakInside: "avoid",
      marginLeft: "0px !important",
    },
  },
};

/**
 * Avoids page breaks after the element when printing.
 */
export const avoidPageBreakAfterCss: any = {
  "@media print": {
    pageBreakAfter: "avoid",
    position: "relative",
  },
};

/**
 * Adjust the profile grid elements to be print-friendly.
 */
export const profilePrintGridCss: any = {
  "@media print": {
    display: "block !important",

    // Profile stacks
    "> *": {
      display: "block !important",
      pageBreakInside: "avoid",
      marginBottom: "3rem",

      // Stack cards
      "> *": {
        display: "block !important",
      },
    },
  },
};

/**
 * Adjust the profile chart elements to be print-friendly.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const profilePrintChartCss: any = {
  "@media print": {
    display: "table !important",
    overflow: "hidden !important",
    canvas: {
      maxWidth: "1500px !important",
    },
  },
};
