import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * The `Date` scalar type represents a date. The Date appears in a JSON
   * response as an ISO8601 formatted string.
   */
  Date: any;
  /**
   * The `DateTime` scalar type represents a date and time in the UTC
   * timezone. The DateTime appears in a JSON response as an ISO8601 formatted
   * string, including UTC timezone ("Z"). The parsed date and time string will
   * be converted to UTC and any UTC offset other than 0 will be rejected.
   */
  DateTime: any;
  /**
   * The `Decimal` scalar type represents signed double-precision fractional
   * values parsed by the `Decimal` library.  The Decimal appears in a JSON
   * response as a string to preserve precision.
   */
  Decimal: any;
  /**
   * The `JSON` scalar type represents arbitrary json string data, represented as UTF-8
   * character sequences. The Json type is most often used to represent a free-form
   * human-readable json string.
   */
  JSON: any;
  Money: any;
  /**
   * Represents an uploaded file.
   * 
   */
  Upload: any;
};

/** An applicant */
export type Applicant = {
  __typename?: 'Applicant';
  appliedAt: Maybe<Scalars['DateTime']>;
  assessment: Maybe<Assessment>;
  availabilities: Maybe<Array<Maybe<Availability>>>;
  availableAt: Maybe<Scalars['Date']>;
  dayAvailability: Maybe<Scalars['String']>;
  email: Maybe<Scalars['String']>;
  externalId: Maybe<Scalars['Int']>;
  extraAnswers: Maybe<Scalars['JSON']>;
  firstName: Maybe<Scalars['String']>;
  fulfilledRequirements: Maybe<Array<Maybe<Scalars['String']>>>;
  id: Maybe<Scalars['Int']>;
  interviewAt: Maybe<Scalars['DateTime']>;
  isFullTime: Maybe<Scalars['Boolean']>;
  isShortApplication: Maybe<Scalars['Boolean']>;
  job: Maybe<Job>;
  lastName: Maybe<Scalars['String']>;
  location: Maybe<Location>;
  openAnswers: Maybe<Array<Maybe<Scalars['JSON']>>>;
  phoneNumber: Maybe<Scalars['String']>;
  picturesUrl: Maybe<Array<Maybe<Scalars['String']>>>;
  qualified: Maybe<Scalars['Boolean']>;
  recruiterNotes: Maybe<Scalars['String']>;
  recruiterRating: Maybe<Scalars['Int']>;
  responsibleRecruiter: Maybe<Member>;
  resumeUrl: Maybe<Scalars['String']>;
  source: Maybe<Scalars['String']>;
  status: Maybe<StatusUpdate>;
  statusUpdates: Maybe<Array<Maybe<StatusUpdate>>>;
  unfulfilledRequirements: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** Available filters to list applicants */
export type ApplicantFilters = {
  isFullTime?: Maybe<Scalars['Boolean']>;
  jobId: Scalars['Int'];
  locations?: Maybe<Array<Maybe<CityStateFilter>>>;
  roleFitScoreMax?: Maybe<Scalars['Decimal']>;
  roleFitScoreMin?: Maybe<Scalars['Decimal']>;
  status?: Maybe<Array<Maybe<StatusUpdateEnum>>>;
};

/** A applicant response */
export type ApplicantResponse = {
  __typename?: 'ApplicantResponse';
  availability: Maybe<Scalars['JSON']>;
  availableAt: Maybe<Scalars['Date']>;
  city: Maybe<Scalars['String']>;
  complement: Maybe<Scalars['String']>;
  dayAvailability: Maybe<Scalars['String']>;
  email: Maybe<Scalars['String']>;
  extraAnswers: Maybe<Scalars['JSON']>;
  firstName: Maybe<Scalars['String']>;
  formLinkShortcode: Maybe<Scalars['String']>;
  fulfilledRequirements: Maybe<Array<Maybe<Scalars['String']>>>;
  id: Maybe<Scalars['String']>;
  isFullTime: Maybe<Scalars['Boolean']>;
  isShortApplication: Maybe<Scalars['Boolean']>;
  lastName: Maybe<Scalars['String']>;
  latitude: Maybe<Scalars['String']>;
  longitude: Maybe<Scalars['String']>;
  openAnswers: Maybe<Array<Maybe<Scalars['JSON']>>>;
  phoneNumber: Maybe<Scalars['String']>;
  pictures: Maybe<Array<Maybe<Scalars['String']>>>;
  resume: Maybe<Scalars['String']>;
  source: Maybe<Scalars['String']>;
  state: Maybe<Scalars['String']>;
  status: Maybe<ResponseStatusEnum>;
  streetAddress: Maybe<Scalars['String']>;
  zipCode: Maybe<Scalars['String']>;
};

/** Allowed parameters to create a applicant response */
export type ApplicantResponseCreateParams = {
  city?: Maybe<Scalars['String']>;
  complement?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  formLinkShortcode: Scalars['String'];
  lastName: Scalars['String'];
  latitude?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
  state?: Maybe<Scalars['String']>;
  streetAddress?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

/** Allowed parameters to update a applicant response */
export type ApplicantResponseUpdateParams = {
  availability?: Maybe<Scalars['JSON']>;
  availableAt?: Maybe<Scalars['Date']>;
  city?: Maybe<Scalars['String']>;
  complement?: Maybe<Scalars['String']>;
  dayAvailability?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  extraAnswers?: Maybe<Scalars['JSON']>;
  firstName?: Maybe<Scalars['String']>;
  fulfilledRequirements?: Maybe<Array<Maybe<Scalars['String']>>>;
  isFullTime?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
  openAnswers?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  phoneNumber?: Maybe<Scalars['String']>;
  pictures?: Maybe<Array<Maybe<Scalars['Upload']>>>;
  resume?: Maybe<Scalars['Upload']>;
  source?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  streetAddress?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type ApplicationsOverview = {
  __typename?: 'ApplicationsOverview';
  applicants: Maybe<Scalars['Int']>;
  hired: Maybe<Scalars['Int']>;
  interviews: Maybe<Scalars['Int']>;
};

/** An assessment */
export type Assessment = {
  __typename?: 'Assessment';
  applicant: Maybe<Applicant>;
  attributes: Maybe<Array<Maybe<Attribute>>>;
  client: Maybe<Client>;
  id: Maybe<Scalars['Int']>;
  processingValues: Maybe<Scalars['JSON']>;
  roleFitScore: Maybe<Scalars['Decimal']>;
  spiderChartScores: Maybe<Array<Maybe<SpiderChartScore>>>;
};


/** An assessment */
export type AssessmentSpiderChartScoresArgs = {
  active?: Maybe<Scalars['Boolean']>;
};

/** An attribute */
export type Attribute = {
  __typename?: 'Attribute';
  attribute: Maybe<AttributeEnum>;
  finalValue: Maybe<Scalars['Decimal']>;
  rawValue: Maybe<Scalars['Decimal']>;
};

export enum AttributeEnum {
  Ack = 'ACK',
  App = 'APP',
  Att = 'ATT',
  Avr = 'AVR',
  Bel = 'BEL',
  Bnd = 'BND',
  Con = 'CON',
  Crt = 'CRT',
  Det = 'DET',
  Drv = 'DRV',
  Idn = 'IDN',
  Ign = 'IGN',
  Iso = 'ISO',
  Rej = 'REJ',
  Sep = 'SEP',
  Val = 'VAL'
}

/** An applicant availability */
export type Availability = {
  __typename?: 'Availability';
  afternoon: Maybe<Scalars['Boolean']>;
  day: Maybe<DayOfWeekEnum>;
  evening: Maybe<Scalars['Boolean']>;
  id: Maybe<Scalars['Int']>;
  morning: Maybe<Scalars['Boolean']>;
  overnight: Maybe<Scalars['Boolean']>;
};

/** The billing type enum */
export enum BillingType {
  Annual = 'ANNUAL',
  Monthly = 'MONTHLY'
}

/** Location fields available to filter */
export type CityStateFilter = {
  city: Scalars['String'];
  state: Scalars['String'];
};

/** A client */
export type Client = {
  __typename?: 'Client';
  assessment: Maybe<Assessment>;
  email: Maybe<Scalars['String']>;
  extraAnswers: Maybe<Scalars['JSON']>;
  firstName: Maybe<Scalars['String']>;
  id: Maybe<Scalars['Int']>;
  job: Maybe<Job>;
  lastName: Maybe<Scalars['String']>;
  location: Maybe<Location>;
  openAnswers: Maybe<Array<Maybe<Scalars['JSON']>>>;
  phoneNumber: Maybe<Scalars['String']>;
  picturesUrl: Maybe<Array<Maybe<Scalars['String']>>>;
  respondedBy: Maybe<ClientRespondedEnum>;
  status: Maybe<ClientStatusEnum>;
  submittedAt: Maybe<Scalars['DateTime']>;
};

/** Available filters to list clients */
export type ClientFilters = {
  jobId: Scalars['Int'];
  locations?: Maybe<Array<Maybe<CityStateFilter>>>;
  status?: Maybe<Array<Maybe<ClientStatusEnum>>>;
};

/** Client responded */
export enum ClientRespondedEnum {
  /** Caregiver */
  Caregiver = 'CAREGIVER',
  /** Client */
  Client = 'CLIENT',
  /** Family Member */
  FamilyMember = 'FAMILY_MEMBER'
}

/** A client response */
export type ClientResponse = {
  __typename?: 'ClientResponse';
  city: Maybe<Scalars['String']>;
  complement: Maybe<Scalars['String']>;
  email: Maybe<Scalars['String']>;
  extraAnswers: Maybe<Scalars['JSON']>;
  firstName: Maybe<Scalars['String']>;
  formLinkShortcode: Maybe<Scalars['String']>;
  id: Maybe<Scalars['String']>;
  lastName: Maybe<Scalars['String']>;
  latitude: Maybe<Scalars['String']>;
  longitude: Maybe<Scalars['String']>;
  openAnswers: Maybe<Array<Maybe<Scalars['JSON']>>>;
  phoneNumber: Maybe<Scalars['String']>;
  pictures: Maybe<Array<Maybe<Scalars['String']>>>;
  respondedBy: Maybe<ClientRespondedEnum>;
  state: Maybe<Scalars['String']>;
  status: Maybe<ResponseStatusEnum>;
  streetAddress: Maybe<Scalars['String']>;
  zipCode: Maybe<Scalars['String']>;
};

/** Allowed parameters to create a client response */
export type ClientResponseCreateParams = {
  city: Scalars['String'];
  complement?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  formLinkShortcode: Scalars['String'];
  lastName: Scalars['String'];
  latitude: Scalars['String'];
  longitude: Scalars['String'];
  phoneNumber: Scalars['String'];
  state: Scalars['String'];
  streetAddress: Scalars['String'];
  zipCode: Scalars['String'];
};

/** Allowed parameters to update a client response */
export type ClientResponseUpdateParams = {
  city?: Maybe<Scalars['String']>;
  complement?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  extraAnswers?: Maybe<Scalars['JSON']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
  openAnswers?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  phoneNumber?: Maybe<Scalars['String']>;
  pictures?: Maybe<Array<Maybe<Scalars['Upload']>>>;
  respondedBy?: Maybe<ClientRespondedEnum>;
  state?: Maybe<Scalars['String']>;
  streetAddress?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

/** Client status */
export enum ClientStatusEnum {
  /** Active */
  Active = 'ACTIVE',
  /** Inactive */
  Inactive = 'INACTIVE'
}

/** A company */
export type Company = {
  __typename?: 'Company';
  activeMembers: Maybe<Array<Maybe<Member>>>;
  availableRoles: Maybe<Array<Maybe<Role>>>;
  creditCards: Maybe<Array<Maybe<CreditCard>>>;
  defaultCreditCard: Maybe<CreditCard>;
  /** List of company's discounts */
  discounts: Maybe<Array<Maybe<Discount>>>;
  id: Maybe<Scalars['Int']>;
  jobs: Maybe<Array<Maybe<Job>>>;
  location: Maybe<Location>;
  logoUrl: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  /** Number of company payments */
  numberOfPayments: Maybe<Scalars['Int']>;
  numberOfUnprocessedApplicants: Maybe<Scalars['Int']>;
  /** List company payments */
  payments: Maybe<Array<Maybe<Payment>>>;
  primaryManager: Maybe<Member>;
  roles: Maybe<Array<Maybe<Role>>>;
  subscriptions: Maybe<Array<Maybe<Subscription>>>;
};


/** A company */
export type CompanyDiscountsArgs = {
  active?: Maybe<Scalars['Boolean']>;
};


/** A company */
export type CompanyNumberOfPaymentsArgs = {
  period?: Maybe<Period>;
};


/** A company */
export type CompanyPaymentsArgs = {
  pagination?: Maybe<PaginationParams>;
  period?: Maybe<Period>;
};


/** A company */
export type CompanyRolesArgs = {
  hasApplicant?: Maybe<Scalars['Boolean']>;
  hasClient?: Maybe<Scalars['Boolean']>;
};


/** A company */
export type CompanySubscriptionsArgs = {
  onlyActive?: Maybe<Scalars['Boolean']>;
  onlyIndebtedThisMonth?: Maybe<Scalars['Boolean']>;
};

/** Allowed parameters to create a company */
export type CompanyCreateParams = {
  location: LocationCreateParams;
  name: Scalars['String'];
};

/** Allowed parameters to update a company */
export type CompanyUpdateParams = {
  code?: Maybe<Scalars['String']>;
  location?: Maybe<LocationUpdateParams>;
  logo?: Maybe<Scalars['Upload']>;
  name?: Maybe<Scalars['String']>;
};

/** A credit card */
export type CreditCard = {
  __typename?: 'CreditCard';
  brand: Maybe<Scalars['String']>;
  country: Maybe<Scalars['String']>;
  currency: Maybe<Scalars['String']>;
  expMonth: Maybe<Scalars['Int']>;
  expYear: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  isDefault: Maybe<Scalars['Boolean']>;
  last4: Maybe<Scalars['String']>;
};



/** Day of the week */
export enum DayOfWeekEnum {
  /** Friday */
  Friday = 'FRIDAY',
  /** Monday */
  Monday = 'MONDAY',
  /** Saturday */
  Saturday = 'SATURDAY',
  /** Sunday */
  Sunday = 'SUNDAY',
  /** Thursday */
  Thursday = 'THURSDAY',
  /** Tuesday */
  Tuesday = 'TUESDAY',
  /** Wednesday */
  Wednesday = 'WEDNESDAY'
}


/** A device */
export type Device = {
  __typename?: 'Device';
  id: Maybe<Scalars['Int']>;
  playerId: Maybe<Scalars['String']>;
  user: Maybe<User>;
};

/** Allowed parameters to create an device */
export type DeviceCreateParams = {
  playerId: Scalars['String'];
};

/** A discount */
export type Discount = {
  __typename?: 'Discount';
  endAt: Maybe<Scalars['Date']>;
  extraTokensPack: Maybe<ExtraTokensPack>;
  id: Maybe<Scalars['Int']>;
  plan: Maybe<Plan>;
  rate: Maybe<Scalars['Decimal']>;
  startAt: Maybe<Scalars['Date']>;
};

/** Extra questions */
export type ExtraQuestion = {
  __typename?: 'ExtraQuestion';
  displayType: Maybe<ExtraQuestionDisplayTypeEnum>;
  extraSection: Maybe<ExtraSection>;
  id: Maybe<Scalars['Int']>;
  label: Maybe<Scalars['String']>;
  options: Maybe<Array<Maybe<Scalars['String']>>>;
  position: Maybe<Scalars['Int']>;
  question: Maybe<Scalars['String']>;
  type: Maybe<ExtraQuestionTypeEnum>;
};

/** Extra question display types */
export enum ExtraQuestionDisplayTypeEnum {
  /** Hidden */
  Hidden = 'HIDDEN',
  /** Optional */
  Optional = 'OPTIONAL',
  /** Required */
  Required = 'REQUIRED'
}

/** Allowed parameters to update or create an extra question */
export type ExtraQuestionParams = {
  displayType?: Maybe<ExtraQuestionDisplayTypeEnum>;
};

/** Extra question types */
export enum ExtraQuestionTypeEnum {
  /** Date */
  Date = 'DATE',
  /** Options */
  Options = 'OPTIONS',
  /** Will take 100% of a line */
  StringBig = 'STRING_BIG',
  /** Will take 50% of a line */
  StringMedium = 'STRING_MEDIUM',
  /** Will take 25% of a line */
  StringSmall = 'STRING_SMALL',
  /** Text */
  Text = 'TEXT'
}

/** Extra sections */
export type ExtraSection = {
  __typename?: 'ExtraSection';
  customIntro: Maybe<Scalars['String']>;
  extraQuestions: Maybe<Array<Maybe<ExtraQuestion>>>;
  formType: Maybe<FormTypeEnum>;
  id: Maybe<Scalars['Int']>;
  isMultiple: Maybe<Scalars['Boolean']>;
  minimumNumber: Maybe<Scalars['Int']>;
  name: Maybe<Scalars['String']>;
  position: Maybe<Scalars['Int']>;
  role: Maybe<Role>;
  show: Maybe<Scalars['Boolean']>;
};

/** Allowed parameters to update or create an extra section */
export type ExtraSectionParams = {
  customIntro?: Maybe<Scalars['String']>;
  minimumNumber?: Maybe<Scalars['Int']>;
  show?: Maybe<Scalars['Boolean']>;
};

/** An extra tokens pack */
export type ExtraTokensPack = {
  __typename?: 'ExtraTokensPack';
  activeDiscount: Maybe<ExtraTokensPackDiscount>;
  endAt: Maybe<Scalars['DateTime']>;
  id: Maybe<Scalars['Int']>;
  price: Maybe<Scalars['Money']>;
  quantity: Maybe<Scalars['Int']>;
  role: Maybe<Role>;
};

/** An extra tokens pack discount */
export type ExtraTokensPackDiscount = {
  __typename?: 'ExtraTokensPackDiscount';
  discount: Maybe<Discount>;
  discountedPrice: Maybe<Scalars['Money']>;
};

export type File = {
  __typename?: 'File';
  base64: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
};

/** Fixed sections configuration */
export type FixedSectionsConfiguration = {
  __typename?: 'FixedSectionsConfiguration';
  form: Maybe<Form>;
  formType: Maybe<FormTypeEnum>;
  id: Maybe<Scalars['Int']>;
  showAvailability: Maybe<Scalars['Boolean']>;
  showRequirements: Maybe<Scalars['Boolean']>;
  showResume: Maybe<Scalars['Boolean']>;
  showSource: Maybe<Scalars['Boolean']>;
};

/** A form */
export type Form = {
  __typename?: 'Form';
  allowImagesUpload: Maybe<Scalars['Boolean']>;
  applicantIntroductoryMessage: Maybe<Scalars['String']>;
  applicantOpenQuestionsIntro: Maybe<Scalars['String']>;
  applicantThankYouMessage: Maybe<Scalars['String']>;
  clientIntroductoryMessage: Maybe<Scalars['String']>;
  clientOpenQuestionsIntro: Maybe<Scalars['String']>;
  clientThankYouMessage: Maybe<Scalars['String']>;
  extraSections: Maybe<Array<Maybe<ExtraSection>>>;
  fixedSectionsConfiguration: Maybe<FixedSectionsConfiguration>;
  formType: Maybe<FormTypeEnum>;
  id: Maybe<Scalars['Int']>;
  job: Maybe<Job>;
  referrerOptions: Maybe<Array<Maybe<Scalars['String']>>>;
  requirements: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** A form */
export type FormExtraSectionsArgs = {
  formType?: Maybe<FormTypeEnum>;
  questionsDisplayType?: Maybe<Array<Maybe<ExtraQuestionDisplayTypeEnum>>>;
  shortcode?: Maybe<Scalars['String']>;
  show?: Maybe<Scalars['Boolean']>;
};


/** A form */
export type FormFixedSectionsConfigurationArgs = {
  formType?: Maybe<FormTypeEnum>;
  shortcode?: Maybe<Scalars['String']>;
};

/** A form link */
export type FormLink = {
  __typename?: 'FormLink';
  form: Maybe<Form>;
  formType: Maybe<FormTypeEnum>;
  shortcode: Maybe<Scalars['String']>;
  url: Maybe<Scalars['String']>;
};

/** Form types */
export enum FormTypeEnum {
  /** Application */
  Application = 'APPLICATION',
  /** Client */
  Client = 'CLIENT',
  /** Short Application */
  ShortApplication = 'SHORT_APPLICATION'
}


/** A job */
export type Job = {
  __typename?: 'Job';
  company: Maybe<Company>;
  form: Maybe<Form>;
  id: Maybe<Scalars['Int']>;
  landingPage: Maybe<LandingPage>;
  numberOfAvailableTokens: Maybe<Scalars['Int']>;
  publicCompanyInfo: Maybe<PublicCompanyInfo>;
  role: Maybe<Role>;
  subscription: Maybe<Subscription>;
  tokensByExpirationDate: Maybe<Array<Maybe<TokensByExpirationDate>>>;
};

/** Allowed parameters to create a job */
export type JobCreateParams = {
  companyId: Scalars['Int'];
  roleId: Scalars['Int'];
};

/** A landing page */
export type LandingPage = {
  __typename?: 'LandingPage';
  buttonText: Maybe<Scalars['String']>;
  color: Maybe<Scalars['String']>;
  headerImageUrl: Maybe<Scalars['String']>;
  id: Maybe<Scalars['Int']>;
  jobDescription: Maybe<Scalars['String']>;
  layoutLabel: Maybe<Scalars['String']>;
  logoUrl: Maybe<Scalars['String']>;
  subtitle: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
};

/** Allowed parameters to create or update a landing page */
export type LandingPageCreateOrUpdateParams = {
  buttonText?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  headerImage?: Maybe<Scalars['Upload']>;
  jobDescription?: Maybe<Scalars['String']>;
  jobId: Scalars['Int'];
  layoutLabel?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['Upload']>;
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** A location */
export type Location = {
  __typename?: 'Location';
  city: Maybe<Scalars['String']>;
  company: Maybe<Company>;
  complement: Maybe<Scalars['String']>;
  id: Maybe<Scalars['Int']>;
  latitude: Maybe<Scalars['String']>;
  longitude: Maybe<Scalars['String']>;
  state: Maybe<Scalars['String']>;
  streetAddress: Maybe<Scalars['String']>;
  zipCode: Maybe<Scalars['String']>;
};

/** Allowed parameters to create a location */
export type LocationCreateParams = {
  city?: Maybe<Scalars['String']>;
  complement?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  streetAddress?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

/** Allowed parameters to update a location */
export type LocationUpdateParams = {
  city?: Maybe<Scalars['String']>;
  complement?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  streetAddress?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

/** A location with the number of subjects (applicant/client) */
export type LocationWithSubjects = {
  __typename?: 'LocationWithSubjects';
  city: Maybe<Scalars['String']>;
  state: Maybe<Scalars['String']>;
  subjectCount: Maybe<Scalars['Int']>;
};

/** A member */
export type Member = {
  __typename?: 'Member';
  company: Maybe<Company>;
  id: Maybe<Scalars['Int']>;
  invitedEmail: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  occupation: Maybe<MemberOccupationEnum>;
  phoneNumber: Maybe<Scalars['String']>;
  photo: Maybe<Scalars['String']>;
  user: Maybe<User>;
};

/** Allowed parameters to create a member */
export type MemberCreateParams = {
  companyId: Scalars['Int'];
  invitedEmail: Scalars['String'];
  name: Scalars['String'];
  occupation: MemberOccupationEnum;
  phoneNumber: Scalars['String'];
};

/** Member occupations */
export enum MemberOccupationEnum {
  /** Manager */
  Manager = 'MANAGER',
  /** Recruiter */
  Recruiter = 'RECRUITER',
  /** Recruiter/Scheduler */
  RecruiterScheduler = 'RECRUITER_SCHEDULER',
  /** Scheduler */
  Scheduler = 'SCHEDULER'
}

/** Allowed parameters to update a member */
export type MemberUpdateParams = {
  invitedEmail?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  occupation?: Maybe<MemberOccupationEnum>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['Upload']>;
};


/** A next payment */
export type NextPayment = {
  __typename?: 'NextPayment';
  amount: Maybe<Scalars['Money']>;
  expectedDate: Maybe<Scalars['Date']>;
};

/** A notification */
export type Notification = {
  __typename?: 'Notification';
  description: Maybe<Scalars['String']>;
  firedAt: Maybe<Scalars['DateTime']>;
  id: Maybe<Scalars['Int']>;
  isRead: Maybe<Scalars['Boolean']>;
  sendEmail: Maybe<Scalars['Boolean']>;
  sendPush: Maybe<Scalars['Boolean']>;
  title: Maybe<Scalars['String']>;
  url: Maybe<Scalars['String']>;
  user: Maybe<User>;
};

/** Allowed parameters to create an notification */
export type NotificationCreateParams = {
  description: Scalars['String'];
  sendEmail: Scalars['Boolean'];
  sendPush: Scalars['Boolean'];
  title: Scalars['String'];
  url: Scalars['String'];
  userId: Scalars['Int'];
};

/** Allowed parameters to filter a notification */
export type NotificationFilters = {
  isRead?: Maybe<Scalars['Boolean']>;
};

/** The operation type enum */
export enum OperationType {
  /** Downgrade */
  Downgrade = 'DOWNGRADE',
  /** New */
  New = 'NEW',
  /** Upgrade */
  Upgrade = 'UPGRADE'
}

export type PaginationParams = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  sortingOrder?: Maybe<SortingOrder>;
};

/** A payment */
export type Payment = {
  __typename?: 'Payment';
  amount: Maybe<Scalars['Money']>;
  company: Maybe<Company>;
  extraTokensPack: Maybe<ExtraTokensPack>;
  id: Maybe<Scalars['Int']>;
  paidAt: Maybe<Scalars['DateTime']>;
  subscription: Maybe<Subscription>;
  tokensBought: Maybe<Scalars['Int']>;
};

export type Period = {
  endDate: Scalars['Date'];
  startDate: Scalars['Date'];
};

/** A plan */
export type Plan = {
  __typename?: 'Plan';
  activeDiscount: Maybe<PlanDiscount>;
  annualDiscountPercentage: Maybe<Scalars['Int']>;
  annualPrice: Maybe<Scalars['Money']>;
  benefits: Maybe<Array<Maybe<Scalars['String']>>>;
  iconUrl: Maybe<Scalars['String']>;
  id: Maybe<Scalars['Int']>;
  monthlyPrice: Maybe<Scalars['Money']>;
  monthlyTokens: Maybe<Scalars['Int']>;
  name: Maybe<Scalars['String']>;
  role: Maybe<Role>;
};


/** A plan */
export type PlanActiveDiscountArgs = {
  companyId?: Maybe<Scalars['Int']>;
};

/** An plan discount */
export type PlanDiscount = {
  __typename?: 'PlanDiscount';
  discount: Maybe<Discount>;
  discountedAnnualPrice: Maybe<Scalars['Money']>;
  discountedMonthlyPrice: Maybe<Scalars['Money']>;
};

export enum PlanOrderByFields {
  AnnualPrice = 'ANNUAL_PRICE',
  Id = 'ID',
  MonthlyPrice = 'MONTHLY_PRICE',
  MonthlyTokens = 'MONTHLY_TOKENS',
  Name = 'NAME'
}

/** Public company info */
export type PublicCompanyInfo = {
  __typename?: 'PublicCompanyInfo';
  id: Maybe<Scalars['Int']>;
  logoUrl: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
};

export type RegisterWithCompanyParams = {
  company: CompanyCreateParams;
  email: Scalars['String'];
  name: Scalars['String'];
  password: Scalars['String'];
  subscribedToMailingList?: Maybe<Scalars['Boolean']>;
};

/** Response status types */
export enum ResponseStatusEnum {
  /** Ongoing */
  Ongoing = 'ONGOING',
  /** Processed */
  Processed = 'PROCESSED',
  /** Submitted */
  Submitted = 'SUBMITTED'
}

/** A role */
export type Role = {
  __typename?: 'Role';
  applicantOpenQuestions: Maybe<Array<Maybe<Scalars['String']>>>;
  availableToAllCompanies: Maybe<Scalars['Boolean']>;
  clientOpenQuestions: Maybe<Array<Maybe<Scalars['String']>>>;
  companies: Maybe<Array<Maybe<Company>>>;
  extraSections: Maybe<Array<Maybe<ExtraSection>>>;
  extraTokensPacks: Maybe<Array<Maybe<ExtraTokensPack>>>;
  hasApplicant: Maybe<Scalars['Boolean']>;
  hasClient: Maybe<Scalars['Boolean']>;
  id: Maybe<Scalars['Int']>;
  imageUrl: Maybe<Scalars['String']>;
  isProfessional: Maybe<Scalars['Boolean']>;
  name: Maybe<Scalars['String']>;
  /** List of role's plans */
  plans: Maybe<Array<Maybe<Plan>>>;
  showAdditionalQualities: Maybe<Scalars['Boolean']>;
  suggestedRequirements: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** A role */
export type RolePlansArgs = {
  orderBy?: Maybe<PlanOrderByFields>;
};

/** Allowed parameters to create a role */
export type RoleCreateParams = {
  applicantOpenQuestions: Array<Scalars['String']>;
  clientOpenQuestions?: Maybe<Array<Scalars['String']>>;
  hasApplicant?: Maybe<Scalars['Boolean']>;
  hasClient?: Maybe<Scalars['Boolean']>;
  image: Scalars['Upload'];
  name: Scalars['String'];
  spiderLabels: Array<Scalars['String']>;
};

/** Allowed parameters to update a role */
export type RoleUpdateParams = {
  applicantOpenQuestions?: Maybe<Array<Scalars['String']>>;
  clientOpenQuestions?: Maybe<Array<Scalars['String']>>;
  hasApplicant?: Maybe<Scalars['Boolean']>;
  hasClient?: Maybe<Scalars['Boolean']>;
  image?: Maybe<Scalars['Upload']>;
  name?: Maybe<Scalars['String']>;
  spiderLabels?: Maybe<Array<Scalars['String']>>;
};

export type RootMutationType = {
  __typename?: 'RootMutationType';
  /** Creates an extra question */
  createOrUpdateExtraQuestion: Maybe<ExtraQuestion>;
  /** Creates a new subscription */
  createSubscription: Maybe<Subscription>;
  /** Updates a member */
  updateMember: Maybe<Member>;
  /** Creates an notification */
  createNotification: Maybe<Notification>;
  /** Updates a fixed section configuration */
  updateFixedSectionsConfiguration: Maybe<FixedSectionsConfiguration>;
  /** Delete a landing page */
  deleteLandingPage: Maybe<LandingPage>;
  /** Logins an user */
  loginUser: Maybe<Scalars['String']>;
  /** Submit an applicant response */
  submitApplicantResponse: Maybe<ApplicantResponse>;
  /** Creates a job */
  createJob: Maybe<Job>;
  /** Updates an applicant */
  updateApplicant: Maybe<Applicant>;
  /** Cancel a subscription */
  cancelSubscription: Maybe<Subscription>;
  /** Resend member invitation email */
  resendMemberInvitationEmail: Maybe<Member>;
  /** Marks notifications as read */
  markNotificationsAsRead: Maybe<User>;
  /** Sends an email for user email verification */
  sendVerificationEmail: Maybe<User>;
  /** Create or update a landing page */
  createOrUpdateLandingPage: Maybe<LandingPage>;
  deleteCreditCard: Maybe<CreditCard>;
  /** Updates a client response */
  updateClientResponse: Maybe<ClientResponse>;
  /** Updates a role */
  updateRole: Maybe<Role>;
  setDefaultCreditCard: Maybe<CreditCard>;
  /** Share applicant profile */
  shareApplicantProfile: Maybe<Scalars['Boolean']>;
  /** Import clients from a CSV */
  importClientsFromCsv: Maybe<Scalars['Boolean']>;
  /** Creates a client response */
  createClientResponse: Maybe<ClientResponse>;
  /** Creates an unverified user with unconfirmed email */
  registerWithCompany: Maybe<Scalars['String']>;
  /** Update the user's password */
  updatePassword: Maybe<User>;
  /** Delete a member */
  deleteMember: Maybe<Member>;
  /** Resets the user's password */
  resetPassword: Maybe<Scalars['String']>;
  /** Submit an client response */
  submitClientResponse: Maybe<ClientResponse>;
  createCreditCard: Maybe<CreditCard>;
  /** Import applicants from a CSV */
  importApplicantsFromCsv: Maybe<Scalars['Boolean']>;
  /** Share a form link */
  shareFormLink: Maybe<Scalars['Boolean']>;
  /** Generates the applicant profile pdf */
  generateApplicantProfilePdf: Maybe<Scalars['String']>;
  /** Generates the client profile pdf */
  generateClientProfilePdf: Maybe<Scalars['String']>;
  /** Updates a applicant response */
  updateApplicantResponse: Maybe<ApplicantResponse>;
  /** Accept an invitation to an already existent user */
  acceptInvitationAndLogin: Maybe<Scalars['String']>;
  /** Creates a member */
  createMember: Maybe<Member>;
  /** Updates a form */
  updateForm: Maybe<Form>;
  /** Creates a applicant response */
  createApplicantResponse: Maybe<ApplicantResponse>;
  /** Creates an extra section */
  createOrUpdateExtraSection: Maybe<ExtraSection>;
  /** Creates a device */
  createDevice: Maybe<Device>;
  /** Creates a role */
  createRole: Maybe<Role>;
  /** Updates a client */
  updateClient: Maybe<Client>;
  /** Sends an email to reset password */
  requestResetPassword: Maybe<Scalars['String']>;
  /** Creates an user to an invited member */
  registerMember: Maybe<Scalars['String']>;
  /** Updates status of an applicant */
  updateApplicantStatus: Maybe<StatusUpdate>;
  /** Buy tokens */
  buyTokens: Maybe<Array<Maybe<Token>>>;
  /** Gets or creates a form link */
  getOrCreateFormLink: Maybe<FormLink>;
  /** Updates a company and location */
  updateCompany: Maybe<Company>;
  /** Share client profile */
  shareClientProfile: Maybe<Scalars['Boolean']>;
};


export type RootMutationTypeCreateOrUpdateExtraQuestionArgs = {
  extraQuestionId: Scalars['Int'];
  formId: Scalars['Int'];
  params?: Maybe<ExtraQuestionParams>;
};


export type RootMutationTypeCreateSubscriptionArgs = {
  billingType: BillingType;
  companyId: Scalars['Int'];
  planId: Scalars['Int'];
};


export type RootMutationTypeUpdateMemberArgs = {
  id: Scalars['Int'];
  params: MemberUpdateParams;
};


export type RootMutationTypeCreateNotificationArgs = {
  params: NotificationCreateParams;
};


export type RootMutationTypeUpdateFixedSectionsConfigurationArgs = {
  id: Scalars['Int'];
  params: UpdateFixedSectionsConfigurationParams;
};


export type RootMutationTypeDeleteLandingPageArgs = {
  id: Scalars['Int'];
};


export type RootMutationTypeLoginUserArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type RootMutationTypeSubmitApplicantResponseArgs = {
  id: Scalars['String'];
};


export type RootMutationTypeCreateJobArgs = {
  params: JobCreateParams;
};


export type RootMutationTypeUpdateApplicantArgs = {
  id: Scalars['Int'];
  params: UpdateApplicantParams;
};


export type RootMutationTypeCancelSubscriptionArgs = {
  id: Scalars['Int'];
};


export type RootMutationTypeResendMemberInvitationEmailArgs = {
  id: Scalars['Int'];
};


export type RootMutationTypeCreateOrUpdateLandingPageArgs = {
  params: LandingPageCreateOrUpdateParams;
};


export type RootMutationTypeDeleteCreditCardArgs = {
  id: Scalars['Int'];
};


export type RootMutationTypeUpdateClientResponseArgs = {
  id: Scalars['String'];
  params: ClientResponseUpdateParams;
};


export type RootMutationTypeUpdateRoleArgs = {
  id: Scalars['Int'];
  params: RoleUpdateParams;
};


export type RootMutationTypeSetDefaultCreditCardArgs = {
  id: Scalars['Int'];
};


export type RootMutationTypeShareApplicantProfileArgs = {
  emails?: Maybe<Array<Maybe<Scalars['String']>>>;
  id: Scalars['Int'];
  phones?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type RootMutationTypeImportClientsFromCsvArgs = {
  filename: Scalars['String'];
};


export type RootMutationTypeCreateClientResponseArgs = {
  params: ClientResponseCreateParams;
};


export type RootMutationTypeRegisterWithCompanyArgs = {
  params: RegisterWithCompanyParams;
};


export type RootMutationTypeUpdatePasswordArgs = {
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
};


export type RootMutationTypeDeleteMemberArgs = {
  id: Scalars['Int'];
};


export type RootMutationTypeResetPasswordArgs = {
  password: Scalars['String'];
  token: Scalars['String'];
};


export type RootMutationTypeSubmitClientResponseArgs = {
  id: Scalars['String'];
};


export type RootMutationTypeCreateCreditCardArgs = {
  cardSource: Scalars['String'];
  companyId: Scalars['Int'];
};


export type RootMutationTypeImportApplicantsFromCsvArgs = {
  filename: Scalars['String'];
};


export type RootMutationTypeShareFormLinkArgs = {
  emails?: Maybe<Array<Maybe<Scalars['String']>>>;
  phones?: Maybe<Array<Maybe<Scalars['String']>>>;
  shortcode: Scalars['String'];
};


export type RootMutationTypeGenerateApplicantProfilePdfArgs = {
  id: Scalars['Int'];
};


export type RootMutationTypeGenerateClientProfilePdfArgs = {
  id: Scalars['Int'];
};


export type RootMutationTypeUpdateApplicantResponseArgs = {
  id: Scalars['String'];
  params: ApplicantResponseUpdateParams;
};


export type RootMutationTypeAcceptInvitationAndLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
  token: Scalars['String'];
};


export type RootMutationTypeCreateMemberArgs = {
  params: MemberCreateParams;
};


export type RootMutationTypeUpdateFormArgs = {
  id: Scalars['Int'];
  params: UpdateFormParams;
};


export type RootMutationTypeCreateApplicantResponseArgs = {
  params: ApplicantResponseCreateParams;
};


export type RootMutationTypeCreateOrUpdateExtraSectionArgs = {
  extraSectionId: Scalars['Int'];
  formId: Scalars['Int'];
  params?: Maybe<ExtraSectionParams>;
};


export type RootMutationTypeCreateDeviceArgs = {
  params: DeviceCreateParams;
};


export type RootMutationTypeCreateRoleArgs = {
  params: RoleCreateParams;
};


export type RootMutationTypeUpdateClientArgs = {
  id: Scalars['Int'];
  params: UpdateClientParams;
};


export type RootMutationTypeRequestResetPasswordArgs = {
  email: Scalars['String'];
};


export type RootMutationTypeRegisterMemberArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
  subscribedToMailingList?: Maybe<Scalars['Boolean']>;
  token: Scalars['String'];
};


export type RootMutationTypeUpdateApplicantStatusArgs = {
  applicantId: Scalars['Int'];
  params: UpdateApplicantStatusParams;
};


export type RootMutationTypeBuyTokensArgs = {
  companyId: Scalars['Int'];
  extraTokensPackId: Scalars['Int'];
};


export type RootMutationTypeGetOrCreateFormLinkArgs = {
  formId: Scalars['Int'];
  formType: FormTypeEnum;
};


export type RootMutationTypeUpdateCompanyArgs = {
  id: Scalars['Int'];
  params: CompanyUpdateParams;
};


export type RootMutationTypeShareClientProfileArgs = {
  emails?: Maybe<Array<Maybe<Scalars['String']>>>;
  id: Scalars['Int'];
  phones?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RootQueryType = {
  __typename?: 'RootQueryType';
  /** Gets a list of all locations */
  listLocations: Maybe<Array<Maybe<Location>>>;
  /** Gets a client by ID */
  getClient: Maybe<Client>;
  /** Gets a list of all users */
  listUsers: Maybe<Array<Maybe<User>>>;
  countStatusUpdates: Maybe<StatusUpdateCount>;
  /** Counts clients */
  countClients: Maybe<Scalars['Int']>;
  /** Export applicants to a CSV */
  exportApplicantsToCsv: Maybe<File>;
  /** Gets a list of all companies */
  listCompanies: Maybe<Array<Maybe<Company>>>;
  /** Gets a applicant response by ID */
  getApplicantResponse: Maybe<ApplicantResponse>;
  /** Gets a list of all clients */
  listClients: Maybe<Array<Maybe<Client>>>;
  /** Gets an applicant by external ID */
  getApplicantByExternalId: Maybe<Applicant>;
  /** Get the new subscription info */
  simulateSubscriptionOperation: Maybe<SubscriptionOperation>;
  /** Gets a form by form link */
  getFormByShortcode: Maybe<Form>;
  /** Export clients to a CSV */
  exportClientsToCsv: Maybe<File>;
  /** Gets the user associated with the given token */
  currentUser: Maybe<User>;
  /** Gets a role by id */
  getRole: Maybe<Role>;
  /** Gets a company by id */
  getCompany: Maybe<Company>;
  /** Gets a list of all applicants */
  listApplicants: Maybe<Array<Maybe<Applicant>>>;
  /** Gets the applicant response resume url */
  getApplicantResponseResumeUrl: Maybe<Scalars['String']>;
  /** Gets a user by id */
  getUser: Maybe<User>;
  /** Counts applicants */
  countApplicants: Maybe<Scalars['Int']>;
  /** Gets an applicant by ID */
  getApplicant: Maybe<Applicant>;
  /** Gets a list of all locations with clients count */
  listClientLocations: Maybe<Array<Maybe<LocationWithSubjects>>>;
  getPlan: Maybe<Plan>;
  /** Gets a list of all locations with applicants count */
  listApplicantLocations: Maybe<Array<Maybe<LocationWithSubjects>>>;
  /** Gets the Plan Subscription */
  getSubscription: Maybe<Subscription>;
  /** Gets a form by ID */
  getForm: Maybe<Form>;
  getApplicationsOverview: Maybe<ApplicationsOverview>;
  /** Gets the application version */
  version: Maybe<Scalars['String']>;
  listPlans: Maybe<Array<Maybe<Plan>>>;
  /** Gets the applicant resume url */
  getApplicantResumeUrl: Maybe<Scalars['String']>;
  /** Gets a job by id */
  getJob: Maybe<Job>;
  /** Gets a list of all roles */
  listRoles: Maybe<Array<Maybe<Role>>>;
  /** Gets a member by id */
  getMember: Maybe<Member>;
  /** Gets a client response by ID */
  getClientResponse: Maybe<ClientResponse>;
  /** Gets the payment's receipt url */
  getPaymentReceiptUrl: Maybe<Scalars['String']>;
  /** Gets a landing_page by ID */
  getLandingPage: Maybe<LandingPage>;
};


export type RootQueryTypeGetClientArgs = {
  id: Scalars['Int'];
};


export type RootQueryTypeCountStatusUpdatesArgs = {
  comparisonPeriod?: Maybe<Period>;
  jobId: Scalars['Int'];
  period: Period;
  statuses?: Maybe<Array<StatusUpdateEnum>>;
};


export type RootQueryTypeCountClientsArgs = {
  filters: ClientFilters;
  search?: Maybe<Scalars['String']>;
};


export type RootQueryTypeExportApplicantsToCsvArgs = {
  filters: ApplicantFilters;
  pagination?: Maybe<PaginationParams>;
  search?: Maybe<Scalars['String']>;
};


export type RootQueryTypeGetApplicantResponseArgs = {
  id: Scalars['String'];
};


export type RootQueryTypeListClientsArgs = {
  filters: ClientFilters;
  pagination?: Maybe<PaginationParams>;
  search?: Maybe<Scalars['String']>;
};


export type RootQueryTypeGetApplicantByExternalIdArgs = {
  id: Scalars['Int'];
};


export type RootQueryTypeSimulateSubscriptionOperationArgs = {
  billingType: BillingType;
  companyId: Scalars['Int'];
  planId: Scalars['Int'];
};


export type RootQueryTypeGetFormByShortcodeArgs = {
  shortcode: Scalars['String'];
};


export type RootQueryTypeExportClientsToCsvArgs = {
  filters: ClientFilters;
  pagination?: Maybe<PaginationParams>;
  search?: Maybe<Scalars['String']>;
};


export type RootQueryTypeGetRoleArgs = {
  id: Scalars['Int'];
};


export type RootQueryTypeGetCompanyArgs = {
  id: Scalars['Int'];
};


export type RootQueryTypeListApplicantsArgs = {
  filters: ApplicantFilters;
  pagination?: Maybe<PaginationParams>;
  search?: Maybe<Scalars['String']>;
};


export type RootQueryTypeGetApplicantResponseResumeUrlArgs = {
  id: Scalars['String'];
};


export type RootQueryTypeGetUserArgs = {
  id: Scalars['Int'];
};


export type RootQueryTypeCountApplicantsArgs = {
  filters: ApplicantFilters;
  search?: Maybe<Scalars['String']>;
};


export type RootQueryTypeGetApplicantArgs = {
  id: Scalars['Int'];
};


export type RootQueryTypeListClientLocationsArgs = {
  jobId: Scalars['Int'];
};


export type RootQueryTypeGetPlanArgs = {
  id: Scalars['Int'];
};


export type RootQueryTypeListApplicantLocationsArgs = {
  jobId: Scalars['Int'];
};


export type RootQueryTypeGetSubscriptionArgs = {
  id: Scalars['Int'];
};


export type RootQueryTypeGetFormArgs = {
  id: Scalars['Int'];
};


export type RootQueryTypeGetApplicationsOverviewArgs = {
  jobId: Scalars['Int'];
  period: Period;
};


export type RootQueryTypeListPlansArgs = {
  orderBy?: Maybe<PlanOrderByFields>;
  roleId: Scalars['Int'];
};


export type RootQueryTypeGetApplicantResumeUrlArgs = {
  id: Scalars['Int'];
};


export type RootQueryTypeGetJobArgs = {
  id: Scalars['Int'];
};


export type RootQueryTypeGetMemberArgs = {
  id: Scalars['Int'];
};


export type RootQueryTypeGetClientResponseArgs = {
  id: Scalars['String'];
};


export type RootQueryTypeGetPaymentReceiptUrlArgs = {
  id: Scalars['Int'];
};


export type RootQueryTypeGetLandingPageArgs = {
  id: Scalars['Int'];
};

/** Sorting order */
export enum SortingOrder {
  /** Ascending order */
  Asc = 'ASC',
  /** Descending order */
  Desc = 'DESC'
}

/** A spider chart score */
export type SpiderChartScore = {
  __typename?: 'SpiderChartScore';
  finalValue: Maybe<Scalars['Decimal']>;
  id: Maybe<Scalars['Int']>;
  info: Maybe<SpiderChartScoreInfo>;
  name: Maybe<Scalars['String']>;
};

export type SpiderChartScoreInfo = {
  __typename?: 'SpiderChartScoreInfo';
  averageValue: Maybe<Scalars['Decimal']>;
  description: Maybe<Scalars['String']>;
  position: Maybe<Scalars['Int']>;
};

/** A status update */
export type StatusUpdate = {
  __typename?: 'StatusUpdate';
  applicant: Maybe<Applicant>;
  happenedAt: Maybe<Scalars['DateTime']>;
  id: Maybe<Scalars['Int']>;
  interviewAt: Maybe<Scalars['DateTime']>;
  member: Maybe<Member>;
  responsibleRecruiter: Maybe<Member>;
  status: Maybe<StatusUpdateEnum>;
};

export type StatusUpdateCount = {
  __typename?: 'StatusUpdateCount';
  growthRate: Maybe<Scalars['Decimal']>;
  numberOfStatusUpdates: Maybe<Scalars['Int']>;
};

/** Status Updates */
export enum StatusUpdateEnum {
  /** Application Incomplete */
  ApplicationIncomplete = 'APPLICATION_INCOMPLETE',
  /** Application Withdrawn */
  ApplicationWithdrawn = 'APPLICATION_WITHDRAWN',
  /** Awaiting Review */
  AwaitingReview = 'AWAITING_REVIEW',
  /** Claimed */
  Claimed = 'CLAIMED',
  /** Contacted - Left message */
  ContactedLeftMessage = 'CONTACTED_LEFT_MESSAGE',
  /** Fired */
  Fired = 'FIRED',
  /** Hired */
  Hired = 'HIRED',
  /** Interview Completed */
  InterviewCompleted = 'INTERVIEW_COMPLETED',
  /** Interview Scheduled */
  InterviewScheduled = 'INTERVIEW_SCHEDULED',
  /** No show for Interview */
  NoShowForInterview = 'NO_SHOW_FOR_INTERVIEW',
  /** No show for Onboarding */
  NoShowForOnboarding = 'NO_SHOW_FOR_ONBOARDING',
  /** Nonresponsive */
  Nonresponsive = 'NONRESPONSIVE',
  /** Offer Made */
  OfferMade = 'OFFER_MADE',
  /** Pending Paperwork */
  PendingPaperwork = 'PENDING_PAPERWORK',
  /** Rejected */
  Rejected = 'REJECTED',
  /** Resigned */
  Resigned = 'RESIGNED'
}

/** Information about a simulated subscription operation */
export type SubscriptionOperation = {
  __typename?: 'SubscriptionOperation';
  cancelationBonus: Maybe<Scalars['Money']>;
  currentSubscription: Maybe<Subscription>;
  currentSubscriptionEndAt: Maybe<Scalars['DateTime']>;
  discount: Maybe<Scalars['Money']>;
  finalPrice: Maybe<Scalars['Money']>;
  newPlan: Maybe<Plan>;
  planOriginalPrice: Maybe<Scalars['Money']>;
  type: Maybe<OperationType>;
};

/** A token */
export type Token = {
  __typename?: 'Token';
  boughtAt: Maybe<Scalars['DateTime']>;
  expiresAt: Maybe<Scalars['DateTime']>;
  id: Maybe<Scalars['Int']>;
  job: Maybe<Job>;
};

/** Tokens by expiration date */
export type TokensByExpirationDate = {
  __typename?: 'TokensByExpirationDate';
  expirationDate: Maybe<Scalars['Date']>;
  numberOfAvailableTokens: Maybe<Scalars['Int']>;
};

export type UpdateApplicantParams = {
  recruiterNotes?: Maybe<Scalars['String']>;
  recruiterRating?: Maybe<Scalars['Int']>;
};

export type UpdateApplicantStatusParams = {
  interviewAt?: Maybe<Scalars['DateTime']>;
  responsibleRecruiterId?: Maybe<Scalars['Int']>;
  status: StatusUpdateEnum;
};

export type UpdateClientParams = {
  status?: Maybe<ClientStatusEnum>;
};

/** Available parameters to update a fixed sections configuration */
export type UpdateFixedSectionsConfigurationParams = {
  showAvailability?: Maybe<Scalars['Boolean']>;
  showRequirements?: Maybe<Scalars['Boolean']>;
  showResume?: Maybe<Scalars['Boolean']>;
  showSource?: Maybe<Scalars['Boolean']>;
};

export type UpdateFormParams = {
  allowImagesUpload?: Maybe<Scalars['Boolean']>;
  applicantIntroductoryMessage?: Maybe<Scalars['String']>;
  applicantOpenQuestionsIntro?: Maybe<Scalars['String']>;
  applicantThankYouMessage?: Maybe<Scalars['String']>;
  clientIntroductoryMessage?: Maybe<Scalars['String']>;
  clientOpenQuestionsIntro?: Maybe<Scalars['String']>;
  clientThankYouMessage?: Maybe<Scalars['String']>;
  referrerOptions?: Maybe<Array<Maybe<Scalars['String']>>>;
  requirements?: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** An user */
export type User = {
  __typename?: 'User';
  countUnreadNotifications: Maybe<Scalars['Int']>;
  devices: Maybe<Array<Maybe<Device>>>;
  email: Maybe<Scalars['String']>;
  id: Maybe<Scalars['Int']>;
  insertedAt: Maybe<Scalars['DateTime']>;
  member: Maybe<Member>;
  notifications: Maybe<Array<Maybe<Notification>>>;
  notificationsReadAt: Maybe<Scalars['DateTime']>;
  role: Maybe<UserRoleEnum>;
  subscribedToMailingList: Maybe<Scalars['Boolean']>;
  verified: Maybe<Scalars['Boolean']>;
};


/** An user */
export type UserNotificationsArgs = {
  filters?: Maybe<NotificationFilters>;
  pagination?: Maybe<PaginationParams>;
};

/** User roles */
export enum UserRoleEnum {
  /** Admin */
  Admin = 'ADMIN',
  /** User */
  User = 'USER'
}

/** A subscription */
export type Subscription = {
  __typename?: 'subscription';
  billingType: Maybe<BillingType>;
  company: Maybe<Company>;
  endAt: Maybe<Scalars['DateTime']>;
  id: Maybe<Scalars['Int']>;
  lastPayment: Maybe<Payment>;
  nextPayment: Maybe<NextPayment>;
  nextTokensAt: Maybe<Scalars['Date']>;
  plan: Maybe<Plan>;
  startAt: Maybe<Scalars['DateTime']>;
};

export type GetCurrentUserRoleQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentUserRoleQuery = (
  { __typename?: 'RootQueryType' }
  & { currentUser: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'role'>
  )> }
);

export type ListAdminImpersonatorCompaniesQueryVariables = Exact<{ [key: string]: never; }>;


export type ListAdminImpersonatorCompaniesQuery = (
  { __typename?: 'RootQueryType' }
  & { currentUser: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { member: Maybe<(
      { __typename?: 'Member' }
      & Pick<Member, 'id'>
      & { company: Maybe<(
        { __typename?: 'Company' }
        & Pick<Company, 'id'>
      )> }
    )> }
  )>, listCompanies: Maybe<Array<Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name'>
    & { primaryManager: Maybe<(
      { __typename?: 'Member' }
      & Pick<Member, 'id'>
      & { user: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email'>
      )> }
    )>, jobs: Maybe<Array<Maybe<(
      { __typename?: 'Job' }
      & Pick<Job, 'id'>
      & { role: Maybe<(
        { __typename?: 'Role' }
        & Pick<Role, 'id' | 'name'>
      )> }
    )>>> }
  )>>> }
);

export type GetApplicantResponseQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetApplicantResponseQuery = (
  { __typename?: 'RootQueryType' }
  & { getApplicantResponse: Maybe<(
    { __typename?: 'ApplicantResponse' }
    & Pick<ApplicantResponse, 'id' | 'complement' | 'availability' | 'availableAt' | 'city' | 'dayAvailability' | 'email' | 'extraAnswers' | 'firstName' | 'formLinkShortcode' | 'fulfilledRequirements' | 'isFullTime' | 'isShortApplication' | 'lastName' | 'latitude' | 'longitude' | 'openAnswers' | 'phoneNumber' | 'pictures' | 'resume' | 'source' | 'state' | 'streetAddress' | 'zipCode'>
  )> }
);

export type GetClientResponseQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetClientResponseQuery = (
  { __typename?: 'RootQueryType' }
  & { getClientResponse: Maybe<(
    { __typename?: 'ClientResponse' }
    & Pick<ClientResponse, 'id' | 'complement' | 'city' | 'email' | 'extraAnswers' | 'firstName' | 'formLinkShortcode' | 'lastName' | 'latitude' | 'longitude' | 'openAnswers' | 'phoneNumber' | 'pictures' | 'respondedBy' | 'state' | 'streetAddress' | 'zipCode'>
  )> }
);

export type GetFormQueryVariables = Exact<{
  formType: FormTypeEnum;
  id: Scalars['Int'];
}>;


export type GetFormQuery = (
  { __typename?: 'RootQueryType' }
  & { getForm: Maybe<(
    { __typename?: 'Form' }
    & Pick<Form, 'id' | 'requirements' | 'referrerOptions' | 'clientOpenQuestionsIntro' | 'applicantOpenQuestionsIntro' | 'clientThankYouMessage' | 'applicantThankYouMessage' | 'clientIntroductoryMessage' | 'applicantIntroductoryMessage' | 'allowImagesUpload'>
    & { job: Maybe<(
      { __typename?: 'Job' }
      & Pick<Job, 'id'>
      & { role: Maybe<(
        { __typename?: 'Role' }
        & Pick<Role, 'id' | 'applicantOpenQuestions' | 'clientOpenQuestions'>
      )> }
    )>, fixedSectionsConfiguration: Maybe<(
      { __typename?: 'FixedSectionsConfiguration' }
      & Pick<FixedSectionsConfiguration, 'id' | 'showAvailability' | 'showResume' | 'showSource' | 'showRequirements'>
    )>, extraSections: Maybe<Array<Maybe<(
      { __typename?: 'ExtraSection' }
      & Pick<ExtraSection, 'id' | 'name' | 'show' | 'isMultiple' | 'customIntro' | 'minimumNumber'>
      & { extraQuestions: Maybe<Array<Maybe<(
        { __typename?: 'ExtraQuestion' }
        & Pick<ExtraQuestion, 'id' | 'type' | 'options' | 'question' | 'position' | 'displayType'>
      )>>> }
    )>>> }
  )> }
);

export type DeleteLandingPageMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteLandingPageMutation = (
  { __typename?: 'RootMutationType' }
  & { deleteLandingPage: Maybe<(
    { __typename?: 'LandingPage' }
    & Pick<LandingPage, 'id'>
  )> }
);

export type GetHeaderCurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetHeaderCurrentUserQuery = (
  { __typename?: 'RootQueryType' }
  & { currentUser: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { member: Maybe<(
      { __typename?: 'Member' }
      & Pick<Member, 'id' | 'photo' | 'occupation'>
    )> }
  )> }
);

export type GetDefaultCreditCardQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDefaultCreditCardQuery = (
  { __typename?: 'RootQueryType' }
  & { currentUser: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { member: Maybe<(
      { __typename?: 'Member' }
      & Pick<Member, 'id'>
      & { company: Maybe<(
        { __typename?: 'Company' }
        & Pick<Company, 'id'>
        & { defaultCreditCard: Maybe<(
          { __typename?: 'CreditCard' }
          & Pick<CreditCard, 'id' | 'last4' | 'brand'>
        )> }
      )> }
    )> }
  )> }
);

export type ListMatchingApplicantsQueryVariables = Exact<{
  filters: ApplicantFilters;
  search?: Maybe<Scalars['String']>;
}>;


export type ListMatchingApplicantsQuery = (
  { __typename?: 'RootQueryType' }
  & { listApplicants: Maybe<Array<Maybe<(
    { __typename?: 'Applicant' }
    & Pick<Applicant, 'id' | 'lastName' | 'firstName' | 'isFullTime' | 'picturesUrl' | 'availableAt' | 'recruiterNotes' | 'recruiterRating'>
    & { location: Maybe<(
      { __typename?: 'Location' }
      & Pick<Location, 'city' | 'state' | 'zipCode' | 'streetAddress'>
    )>, assessment: Maybe<(
      { __typename?: 'Assessment' }
      & Pick<Assessment, 'id' | 'roleFitScore'>
      & { spiderChartScores: Maybe<Array<Maybe<(
        { __typename?: 'SpiderChartScore' }
        & Pick<SpiderChartScore, 'id' | 'name' | 'finalValue'>
        & { info: Maybe<(
          { __typename?: 'SpiderChartScoreInfo' }
          & Pick<SpiderChartScoreInfo, 'position'>
        )> }
      )>>> }
    )> }
  )>>> }
);

export type GetCurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentUserQuery = (
  { __typename?: 'RootQueryType' }
  & { currentUser: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
  )> }
);

export type GetCurrentCompanyWithJobsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentCompanyWithJobsQuery = (
  { __typename?: 'RootQueryType' }
  & { currentUser: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { member: Maybe<(
      { __typename?: 'Member' }
      & Pick<Member, 'id'>
      & { company: Maybe<(
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'name'>
        & { jobs: Maybe<Array<Maybe<(
          { __typename?: 'Job' }
          & Pick<Job, 'id'>
          & { role: Maybe<(
            { __typename?: 'Role' }
            & Pick<Role, 'name' | 'id'>
          )> }
        )>>> }
      )> }
    )> }
  )> }
);

export type GetImpersonatedCompanyWithJobsQueryVariables = Exact<{
  companyId: Scalars['Int'];
}>;


export type GetImpersonatedCompanyWithJobsQuery = (
  { __typename?: 'RootQueryType' }
  & { getCompany: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name'>
    & { jobs: Maybe<Array<Maybe<(
      { __typename?: 'Job' }
      & Pick<Job, 'id'>
      & { role: Maybe<(
        { __typename?: 'Role' }
        & Pick<Role, 'name' | 'id'>
      )> }
    )>>> }
  )> }
);

export type ListApplicantLocationsQueryVariables = Exact<{
  jobId: Scalars['Int'];
}>;


export type ListApplicantLocationsQuery = (
  { __typename?: 'RootQueryType' }
  & { listApplicantLocations: Maybe<Array<Maybe<(
    { __typename?: 'LocationWithSubjects' }
    & Pick<LocationWithSubjects, 'city' | 'state' | 'subjectCount'>
  )>>> }
);

export type ListClientLocationsQueryVariables = Exact<{
  jobId: Scalars['Int'];
}>;


export type ListClientLocationsQuery = (
  { __typename?: 'RootQueryType' }
  & { listClientLocations: Maybe<Array<Maybe<(
    { __typename?: 'LocationWithSubjects' }
    & ListLocationsFieldsFragment
  )>>> }
);

export type ListLocationsFieldsFragment = (
  { __typename?: 'LocationWithSubjects' }
  & Pick<LocationWithSubjects, 'city' | 'state' | 'subjectCount'>
);

export type AcceptInvitationAndLoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
  token: Scalars['String'];
}>;


export type AcceptInvitationAndLoginMutation = (
  { __typename?: 'RootMutationType' }
  & Pick<RootMutationType, 'acceptInvitationAndLogin'>
);

export type BuyTokensMutationVariables = Exact<{
  extraTokensPackId: Scalars['Int'];
  companyId: Scalars['Int'];
}>;


export type BuyTokensMutation = (
  { __typename?: 'RootMutationType' }
  & { buyTokens: Maybe<Array<Maybe<(
    { __typename?: 'Token' }
    & Pick<Token, 'id'>
  )>>> }
);

export type CancelSubscriptionMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type CancelSubscriptionMutation = (
  { __typename?: 'RootMutationType' }
  & { cancelSubscription: Maybe<(
    { __typename?: 'subscription' }
    & Pick<Subscription, 'id' | 'endAt'>
  )> }
);

export type CreateApplicantResponseMutationVariables = Exact<{
  params: ApplicantResponseCreateParams;
}>;


export type CreateApplicantResponseMutation = (
  { __typename?: 'RootMutationType' }
  & { createApplicantResponse: Maybe<(
    { __typename?: 'ApplicantResponse' }
    & Pick<ApplicantResponse, 'id'>
  )> }
);

export type CreateClientResponseMutationVariables = Exact<{
  params: ClientResponseCreateParams;
}>;


export type CreateClientResponseMutation = (
  { __typename?: 'RootMutationType' }
  & { createClientResponse: Maybe<(
    { __typename?: 'ClientResponse' }
    & Pick<ClientResponse, 'id'>
  )> }
);

export type CreateCreditCardMutationVariables = Exact<{
  cardSource: Scalars['String'];
  companyId: Scalars['Int'];
}>;


export type CreateCreditCardMutation = (
  { __typename?: 'RootMutationType' }
  & { createCreditCard: Maybe<(
    { __typename?: 'CreditCard' }
    & Pick<CreditCard, 'id'>
  )> }
);

export type CreateJobMutationVariables = Exact<{
  params: JobCreateParams;
}>;


export type CreateJobMutation = (
  { __typename?: 'RootMutationType' }
  & { createJob: Maybe<(
    { __typename?: 'Job' }
    & Pick<Job, 'id'>
  )> }
);

export type CreateMemberMutationVariables = Exact<{
  params: MemberCreateParams;
}>;


export type CreateMemberMutation = (
  { __typename?: 'RootMutationType' }
  & { createMember: Maybe<(
    { __typename?: 'Member' }
    & Pick<Member, 'id'>
  )> }
);

export type CreateOrUpdateExtraQuestionMutationVariables = Exact<{
  extraQuestionId: Scalars['Int'];
  formId: Scalars['Int'];
  params: ExtraQuestionParams;
}>;


export type CreateOrUpdateExtraQuestionMutation = (
  { __typename?: 'RootMutationType' }
  & { createOrUpdateExtraQuestion: Maybe<(
    { __typename?: 'ExtraQuestion' }
    & Pick<ExtraQuestion, 'id'>
  )> }
);

export type CreateOrUpdateExtraSectionMutationVariables = Exact<{
  extraSectionId: Scalars['Int'];
  formId: Scalars['Int'];
  params: ExtraSectionParams;
}>;


export type CreateOrUpdateExtraSectionMutation = (
  { __typename?: 'RootMutationType' }
  & { createOrUpdateExtraSection: Maybe<(
    { __typename?: 'ExtraSection' }
    & Pick<ExtraSection, 'id'>
  )> }
);

export type CreateOrUpdateLandingPageMutationVariables = Exact<{
  params: LandingPageCreateOrUpdateParams;
}>;


export type CreateOrUpdateLandingPageMutation = (
  { __typename?: 'RootMutationType' }
  & { createOrUpdateLandingPage: Maybe<(
    { __typename?: 'LandingPage' }
    & Pick<LandingPage, 'id'>
  )> }
);

export type CreateSubscriptionMutationVariables = Exact<{
  billingType: BillingType;
  companyId: Scalars['Int'];
  planId: Scalars['Int'];
}>;


export type CreateSubscriptionMutation = (
  { __typename?: 'RootMutationType' }
  & { createSubscription: Maybe<(
    { __typename?: 'subscription' }
    & Pick<Subscription, 'id'>
  )> }
);

export type DeleteCreditCardMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteCreditCardMutation = (
  { __typename?: 'RootMutationType' }
  & { deleteCreditCard: Maybe<(
    { __typename?: 'CreditCard' }
    & Pick<CreditCard, 'id'>
  )> }
);

export type DeleteMemberMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteMemberMutation = (
  { __typename?: 'RootMutationType' }
  & { deleteMember: Maybe<(
    { __typename?: 'Member' }
    & Pick<Member, 'id'>
  )> }
);

export type GenerateApplicantProfilePdfMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GenerateApplicantProfilePdfMutation = (
  { __typename?: 'RootMutationType' }
  & Pick<RootMutationType, 'generateApplicantProfilePdf'>
);

export type GenerateClientProfilePdfMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GenerateClientProfilePdfMutation = (
  { __typename?: 'RootMutationType' }
  & Pick<RootMutationType, 'generateClientProfilePdf'>
);

export type GetOrCreateFormLinkMutationVariables = Exact<{
  formId: Scalars['Int'];
  formType: FormTypeEnum;
}>;


export type GetOrCreateFormLinkMutation = (
  { __typename?: 'RootMutationType' }
  & { getOrCreateFormLink: Maybe<(
    { __typename?: 'FormLink' }
    & Pick<FormLink, 'shortcode' | 'url'>
    & { form: Maybe<(
      { __typename?: 'Form' }
      & Pick<Form, 'id'>
    )> }
  )> }
);

export type LoginUserMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginUserMutation = (
  { __typename?: 'RootMutationType' }
  & Pick<RootMutationType, 'loginUser'>
);

export type RegisterMemberMutationVariables = Exact<{
  token: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
  subscribedToMailingList?: Maybe<Scalars['Boolean']>;
}>;


export type RegisterMemberMutation = (
  { __typename?: 'RootMutationType' }
  & Pick<RootMutationType, 'registerMember'>
);

export type RegisterWithCompanyMutationMutationVariables = Exact<{
  params: RegisterWithCompanyParams;
}>;


export type RegisterWithCompanyMutationMutation = (
  { __typename?: 'RootMutationType' }
  & Pick<RootMutationType, 'registerWithCompany'>
);

export type RequestResetPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type RequestResetPasswordMutation = (
  { __typename?: 'RootMutationType' }
  & Pick<RootMutationType, 'requestResetPassword'>
);

export type ResendMemberInvitationEmailMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ResendMemberInvitationEmailMutation = (
  { __typename?: 'RootMutationType' }
  & { resendMemberInvitationEmail: Maybe<(
    { __typename?: 'Member' }
    & Pick<Member, 'id'>
  )> }
);

export type ResetPasswordMutationVariables = Exact<{
  token: Scalars['String'];
  password: Scalars['String'];
}>;


export type ResetPasswordMutation = (
  { __typename?: 'RootMutationType' }
  & Pick<RootMutationType, 'resetPassword'>
);

export type SetDefaultCreditCardMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type SetDefaultCreditCardMutation = (
  { __typename?: 'RootMutationType' }
  & { setDefaultCreditCard: Maybe<(
    { __typename?: 'CreditCard' }
    & Pick<CreditCard, 'id'>
  )> }
);

export type ShareFormLinkMutationVariables = Exact<{
  shortcode: Scalars['String'];
  emails?: Maybe<Array<Maybe<Scalars['String']>>>;
  phones?: Maybe<Array<Maybe<Scalars['String']>>>;
}>;


export type ShareFormLinkMutation = (
  { __typename?: 'RootMutationType' }
  & Pick<RootMutationType, 'shareFormLink'>
);

export type SubmitApplicantResponseMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type SubmitApplicantResponseMutation = (
  { __typename?: 'RootMutationType' }
  & { submitApplicantResponse: Maybe<(
    { __typename?: 'ApplicantResponse' }
    & Pick<ApplicantResponse, 'id'>
  )> }
);

export type SubmitClientResponseMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type SubmitClientResponseMutation = (
  { __typename?: 'RootMutationType' }
  & { submitClientResponse: Maybe<(
    { __typename?: 'ClientResponse' }
    & Pick<ClientResponse, 'id'>
  )> }
);

export type UpdateApplicantMutationVariables = Exact<{
  id: Scalars['Int'];
  params: UpdateApplicantParams;
}>;


export type UpdateApplicantMutation = (
  { __typename?: 'RootMutationType' }
  & { updateApplicant: Maybe<(
    { __typename?: 'Applicant' }
    & Pick<Applicant, 'id'>
  )> }
);

export type UpdateApplicantResponseMutationVariables = Exact<{
  id: Scalars['String'];
  params: ApplicantResponseUpdateParams;
}>;


export type UpdateApplicantResponseMutation = (
  { __typename?: 'RootMutationType' }
  & { updateApplicantResponse: Maybe<(
    { __typename?: 'ApplicantResponse' }
    & Pick<ApplicantResponse, 'id'>
  )> }
);

export type UpdateApplicantStatusMutationVariables = Exact<{
  id: Scalars['Int'];
  params: UpdateApplicantStatusParams;
}>;


export type UpdateApplicantStatusMutation = (
  { __typename?: 'RootMutationType' }
  & { updateApplicantStatus: Maybe<(
    { __typename?: 'StatusUpdate' }
    & Pick<StatusUpdate, 'id'>
  )> }
);

export type UpdateClientMutationVariables = Exact<{
  id: Scalars['Int'];
  params: UpdateClientParams;
}>;


export type UpdateClientMutation = (
  { __typename?: 'RootMutationType' }
  & { updateClient: Maybe<(
    { __typename?: 'Client' }
    & Pick<Client, 'id'>
  )> }
);

export type UpdateClientResponseMutationVariables = Exact<{
  id: Scalars['String'];
  params: ClientResponseUpdateParams;
}>;


export type UpdateClientResponseMutation = (
  { __typename?: 'RootMutationType' }
  & { updateClientResponse: Maybe<(
    { __typename?: 'ClientResponse' }
    & Pick<ClientResponse, 'id'>
  )> }
);

export type UpdateCompanyMutationVariables = Exact<{
  id: Scalars['Int'];
  params: CompanyUpdateParams;
}>;


export type UpdateCompanyMutation = (
  { __typename?: 'RootMutationType' }
  & { updateCompany: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id'>
  )> }
);

export type UpdateFixedSectionsConfigurationMutationVariables = Exact<{
  id: Scalars['Int'];
  params: UpdateFixedSectionsConfigurationParams;
}>;


export type UpdateFixedSectionsConfigurationMutation = (
  { __typename?: 'RootMutationType' }
  & { updateFixedSectionsConfiguration: Maybe<(
    { __typename?: 'FixedSectionsConfiguration' }
    & Pick<FixedSectionsConfiguration, 'id'>
  )> }
);

export type UpdateFormMutationVariables = Exact<{
  id: Scalars['Int'];
  params: UpdateFormParams;
}>;


export type UpdateFormMutation = (
  { __typename?: 'RootMutationType' }
  & { updateForm: Maybe<(
    { __typename?: 'Form' }
    & Pick<Form, 'id'>
  )> }
);

export type UpdateMemberMutationVariables = Exact<{
  id: Scalars['Int'];
  params: MemberUpdateParams;
}>;


export type UpdateMemberMutation = (
  { __typename?: 'RootMutationType' }
  & { updateMember: Maybe<(
    { __typename?: 'Member' }
    & Pick<Member, 'id'>
  )> }
);

export type UpdatePasswordMutationVariables = Exact<{
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
}>;


export type UpdatePasswordMutation = (
  { __typename?: 'RootMutationType' }
  & { updatePassword: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
  )> }
);

export type GetCurrentUserCompanyQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentUserCompanyQuery = (
  { __typename?: 'RootQueryType' }
  & { currentUser: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { member: Maybe<(
      { __typename?: 'Member' }
      & Pick<Member, 'id'>
      & { company: Maybe<(
        { __typename?: 'Company' }
        & Pick<Company, 'id'>
        & { availableRoles: Maybe<Array<Maybe<(
          { __typename?: 'Role' }
          & Pick<Role, 'id' | 'name'>
        )>>> }
      )> }
    )> }
  )> }
);

export type GetCurrentUserMemberQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentUserMemberQuery = (
  { __typename?: 'RootQueryType' }
  & { currentUser: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'role'>
    & { member: Maybe<(
      { __typename?: 'Member' }
      & Pick<Member, 'id' | 'occupation'>
    )> }
  )> }
);

export type GetFormByShortcodeQueryVariables = Exact<{
  shortcode: Scalars['String'];
}>;


export type GetFormByShortcodeQuery = (
  { __typename?: 'RootQueryType' }
  & { getFormByShortcode: Maybe<(
    { __typename?: 'Form' }
    & Pick<Form, 'id' | 'formType' | 'requirements' | 'referrerOptions' | 'applicantOpenQuestionsIntro' | 'clientOpenQuestionsIntro' | 'clientThankYouMessage' | 'applicantThankYouMessage' | 'clientIntroductoryMessage' | 'applicantIntroductoryMessage' | 'allowImagesUpload'>
    & { fixedSectionsConfiguration: Maybe<(
      { __typename?: 'FixedSectionsConfiguration' }
      & Pick<FixedSectionsConfiguration, 'id' | 'showAvailability' | 'showResume' | 'showSource' | 'showRequirements'>
    )>, extraSections: Maybe<Array<Maybe<(
      { __typename?: 'ExtraSection' }
      & Pick<ExtraSection, 'id' | 'name' | 'show' | 'formType' | 'isMultiple' | 'customIntro' | 'minimumNumber'>
      & { extraQuestions: Maybe<Array<Maybe<(
        { __typename?: 'ExtraQuestion' }
        & Pick<ExtraQuestion, 'id' | 'displayType' | 'question' | 'options' | 'label' | 'type'>
      )>>> }
    )>>>, job: Maybe<(
      { __typename?: 'Job' }
      & Pick<Job, 'id'>
      & { role: Maybe<(
        { __typename?: 'Role' }
        & Pick<Role, 'id' | 'applicantOpenQuestions' | 'clientOpenQuestions' | 'name'>
      )>, publicCompanyInfo: Maybe<(
        { __typename?: 'PublicCompanyInfo' }
        & Pick<PublicCompanyInfo, 'id' | 'name' | 'logoUrl'>
      )> }
    )> }
  )> }
);

export type GetFormLandingPageByShortcodeQueryVariables = Exact<{
  shortcode: Scalars['String'];
}>;


export type GetFormLandingPageByShortcodeQuery = (
  { __typename?: 'RootQueryType' }
  & { getFormByShortcode: Maybe<(
    { __typename?: 'Form' }
    & Pick<Form, 'id' | 'formType'>
    & { job: Maybe<(
      { __typename?: 'Job' }
      & Pick<Job, 'id'>
      & { landingPage: Maybe<(
        { __typename?: 'LandingPage' }
        & Pick<LandingPage, 'id' | 'headerImageUrl' | 'jobDescription' | 'layoutLabel' | 'buttonText' | 'subtitle' | 'logoUrl' | 'title' | 'color'>
      )> }
    )> }
  )> }
);

export type GetFormRedirectByShortcodeQueryVariables = Exact<{
  shortcode: Scalars['String'];
}>;


export type GetFormRedirectByShortcodeQuery = (
  { __typename?: 'RootQueryType' }
  & { getFormByShortcode: Maybe<(
    { __typename?: 'Form' }
    & Pick<Form, 'id' | 'formType'>
    & { job: Maybe<(
      { __typename?: 'Job' }
      & Pick<Job, 'id'>
      & { landingPage: Maybe<(
        { __typename?: 'LandingPage' }
        & Pick<LandingPage, 'id'>
      )> }
    )> }
  )> }
);

export type GetJobRoleQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetJobRoleQuery = (
  { __typename?: 'RootQueryType' }
  & { getJob: Maybe<(
    { __typename?: 'Job' }
    & Pick<Job, 'id'>
    & { role: Maybe<(
      { __typename?: 'Role' }
      & Pick<Role, 'id' | 'isProfessional'>
    )> }
  )> }
);

export type ListRoleNamesQueryVariables = Exact<{ [key: string]: never; }>;


export type ListRoleNamesQuery = (
  { __typename?: 'RootQueryType' }
  & { listRoles: Maybe<Array<Maybe<(
    { __typename?: 'Role' }
    & Pick<Role, 'id' | 'name'>
  )>>> }
);

export type GetCurrentCompanyJobsQueryVariables = Exact<{
  hasApplicant?: Maybe<Scalars['Boolean']>;
  hasClient?: Maybe<Scalars['Boolean']>;
}>;


export type GetCurrentCompanyJobsQuery = (
  { __typename?: 'RootQueryType' }
  & { currentUser: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { member: Maybe<(
      { __typename?: 'Member' }
      & Pick<Member, 'id'>
      & { company: Maybe<(
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'name'>
        & { roles: Maybe<Array<Maybe<(
          { __typename?: 'Role' }
          & Pick<Role, 'id' | 'name' | 'hasClient'>
        )>>>, jobs: Maybe<Array<Maybe<(
          { __typename?: 'Job' }
          & Pick<Job, 'id'>
          & { role: Maybe<(
            { __typename?: 'Role' }
            & Pick<Role, 'id' | 'name'>
          )>, form: Maybe<(
            { __typename?: 'Form' }
            & Pick<Form, 'id' | 'formType'>
          )>, landingPage: Maybe<(
            { __typename?: 'LandingPage' }
            & Pick<LandingPage, 'id' | 'layoutLabel' | 'jobDescription' | 'headerImageUrl' | 'buttonText' | 'subtitle' | 'logoUrl' | 'color' | 'title'>
          )> }
        )>>> }
      )> }
    )> }
  )> }
);

export type GetImpersonatedCompanyJobsQueryVariables = Exact<{
  companyId: Scalars['Int'];
  hasApplicant?: Maybe<Scalars['Boolean']>;
  hasClient?: Maybe<Scalars['Boolean']>;
}>;


export type GetImpersonatedCompanyJobsQuery = (
  { __typename?: 'RootQueryType' }
  & { getCompany: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name'>
    & { roles: Maybe<Array<Maybe<(
      { __typename?: 'Role' }
      & Pick<Role, 'id' | 'name' | 'hasClient'>
    )>>>, jobs: Maybe<Array<Maybe<(
      { __typename?: 'Job' }
      & Pick<Job, 'id'>
      & { role: Maybe<(
        { __typename?: 'Role' }
        & Pick<Role, 'id' | 'name'>
      )>, form: Maybe<(
        { __typename?: 'Form' }
        & Pick<Form, 'id' | 'formType'>
      )>, landingPage: Maybe<(
        { __typename?: 'LandingPage' }
        & Pick<LandingPage, 'id' | 'layoutLabel' | 'jobDescription' | 'headerImageUrl' | 'buttonText' | 'subtitle' | 'logoUrl' | 'color' | 'title'>
      )> }
    )>>> }
  )> }
);

export type GetApplicantResponseResumeUrlQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetApplicantResponseResumeUrlQuery = (
  { __typename?: 'RootQueryType' }
  & Pick<RootQueryType, 'getApplicantResponseResumeUrl'>
);

export type GetApplicantResumeUrlQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetApplicantResumeUrlQuery = (
  { __typename?: 'RootQueryType' }
  & Pick<RootQueryType, 'getApplicantResumeUrl'>
);

export type ExportApplicantsToCsvQueryVariables = Exact<{
  filters: ApplicantFilters;
  search?: Maybe<Scalars['String']>;
}>;


export type ExportApplicantsToCsvQuery = (
  { __typename?: 'RootQueryType' }
  & { exportToCsv: Maybe<(
    { __typename?: 'File' }
    & Pick<File, 'base64' | 'name'>
  )> }
);

export type ExportClientsToCsvQueryVariables = Exact<{
  filters: ClientFilters;
  search?: Maybe<Scalars['String']>;
}>;


export type ExportClientsToCsvQuery = (
  { __typename?: 'RootQueryType' }
  & { exportToCsv: Maybe<(
    { __typename?: 'File' }
    & Pick<File, 'base64' | 'name'>
  )> }
);

export type GetBaseCurrentCompanyQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBaseCurrentCompanyQuery = (
  { __typename?: 'RootQueryType' }
  & { currentUser: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { member: Maybe<(
      { __typename?: 'Member' }
      & Pick<Member, 'id'>
      & { company: Maybe<(
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'name'>
      )> }
    )> }
  )> }
);

export type GetBaseImpersonatedCompanyQueryVariables = Exact<{
  companyId: Scalars['Int'];
}>;


export type GetBaseImpersonatedCompanyQuery = (
  { __typename?: 'RootQueryType' }
  & { getCompany: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name'>
  )> }
);

export type SimulateSubscriptionOperationQueryVariables = Exact<{
  billingType: BillingType;
  companyId: Scalars['Int'];
  planId: Scalars['Int'];
}>;


export type SimulateSubscriptionOperationQuery = (
  { __typename?: 'RootQueryType' }
  & { simulateSubscriptionOperation: Maybe<(
    { __typename?: 'SubscriptionOperation' }
    & Pick<SubscriptionOperation, 'currentSubscriptionEndAt' | 'planOriginalPrice' | 'cancelationBonus' | 'finalPrice' | 'discount' | 'type'>
    & { newPlan: Maybe<(
      { __typename?: 'Plan' }
      & Pick<Plan, 'id' | 'name'>
    )>, currentSubscription: Maybe<(
      { __typename?: 'subscription' }
      & Pick<Subscription, 'id'>
      & { plan: Maybe<(
        { __typename?: 'Plan' }
        & Pick<Plan, 'id' | 'name'>
      )> }
    )> }
  )> }
);

export type ShareApplicantProfileMutationVariables = Exact<{
  emails?: Maybe<Array<Maybe<Scalars['String']>>>;
  phones?: Maybe<Array<Maybe<Scalars['String']>>>;
  id: Scalars['Int'];
}>;


export type ShareApplicantProfileMutation = (
  { __typename?: 'RootMutationType' }
  & Pick<RootMutationType, 'shareApplicantProfile'>
);

export type ShareClientProfileMutationVariables = Exact<{
  emails?: Maybe<Array<Maybe<Scalars['String']>>>;
  phones?: Maybe<Array<Maybe<Scalars['String']>>>;
  id: Scalars['Int'];
}>;


export type ShareClientProfileMutation = (
  { __typename?: 'RootMutationType' }
  & Pick<RootMutationType, 'shareClientProfile'>
);

export type GetApplicantByExternalIdQueryVariables = Exact<{
  externalId: Scalars['Int'];
}>;


export type GetApplicantByExternalIdQuery = (
  { __typename?: 'RootQueryType' }
  & { getApplicantByExternalId: Maybe<(
    { __typename?: 'Applicant' }
    & Pick<Applicant, 'id' | 'email' | 'firstName' | 'lastName' | 'qualified' | 'appliedAt' | 'resumeUrl' | 'interviewAt' | 'openAnswers' | 'availableAt'>
    & { job: Maybe<(
      { __typename?: 'Job' }
      & Pick<Job, 'id'>
      & { role: Maybe<(
        { __typename?: 'Role' }
        & Pick<Role, 'id' | 'name' | 'isProfessional'>
      )> }
    )>, assessment: Maybe<(
      { __typename?: 'Assessment' }
      & Pick<Assessment, 'id' | 'roleFitScore'>
      & { spiderChartScores: Maybe<Array<Maybe<(
        { __typename?: 'SpiderChartScore' }
        & Pick<SpiderChartScore, 'id' | 'name' | 'finalValue'>
        & { info: Maybe<(
          { __typename?: 'SpiderChartScoreInfo' }
          & Pick<SpiderChartScoreInfo, 'averageValue' | 'position' | 'description'>
        )> }
      )>>> }
    )> }
  )> }
);

export type GetApplicantQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetApplicantQuery = (
  { __typename?: 'RootQueryType' }
  & { getApplicant: Maybe<(
    { __typename?: 'Applicant' }
    & Pick<Applicant, 'id' | 'email' | 'firstName' | 'lastName' | 'source' | 'isFullTime' | 'qualified' | 'appliedAt' | 'picturesUrl' | 'resumeUrl' | 'interviewAt' | 'recruiterRating' | 'recruiterNotes' | 'openAnswers' | 'extraAnswers' | 'availableAt' | 'fulfilledRequirements' | 'unfulfilledRequirements' | 'isShortApplication' | 'dayAvailability' | 'phoneNumber'>
    & { location: Maybe<(
      { __typename?: 'Location' }
      & Pick<Location, 'id' | 'city' | 'state' | 'streetAddress' | 'complement' | 'zipCode' | 'latitude' | 'longitude'>
    )>, status: Maybe<(
      { __typename?: 'StatusUpdate' }
      & Pick<StatusUpdate, 'id' | 'status'>
    )>, availabilities: Maybe<Array<Maybe<(
      { __typename?: 'Availability' }
      & Pick<Availability, 'id' | 'day' | 'afternoon' | 'evening' | 'morning' | 'overnight'>
    )>>>, responsibleRecruiter: Maybe<(
      { __typename?: 'Member' }
      & Pick<Member, 'id' | 'name'>
    )>, assessment: Maybe<(
      { __typename?: 'Assessment' }
      & Pick<Assessment, 'id' | 'roleFitScore'>
      & { spiderChartScores: Maybe<Array<Maybe<(
        { __typename?: 'SpiderChartScore' }
        & Pick<SpiderChartScore, 'id' | 'name' | 'finalValue'>
        & { info: Maybe<(
          { __typename?: 'SpiderChartScoreInfo' }
          & Pick<SpiderChartScoreInfo, 'averageValue' | 'position' | 'description'>
        )> }
      )>>> }
    )>, job: Maybe<(
      { __typename?: 'Job' }
      & Pick<Job, 'id'>
      & { role: Maybe<(
        { __typename?: 'Role' }
        & Pick<Role, 'id' | 'name' | 'isProfessional' | 'showAdditionalQualities'>
      )>, company: Maybe<(
        { __typename?: 'Company' }
        & Pick<Company, 'id'>
        & { activeMembers: Maybe<Array<Maybe<(
          { __typename?: 'Member' }
          & Pick<Member, 'id' | 'name'>
        )>>> }
      )> }
    )>, statusUpdates: Maybe<Array<Maybe<(
      { __typename?: 'StatusUpdate' }
      & Pick<StatusUpdate, 'id' | 'status' | 'happenedAt' | 'interviewAt'>
      & { responsibleRecruiter: Maybe<(
        { __typename?: 'Member' }
        & Pick<Member, 'id' | 'name'>
      )>, member: Maybe<(
        { __typename?: 'Member' }
        & Pick<Member, 'id' | 'name' | 'photo'>
      )> }
    )>>> }
  )> }
);

export type GetSpiderChartInactiveScoresQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetSpiderChartInactiveScoresQuery = (
  { __typename?: 'RootQueryType' }
  & { getApplicant: Maybe<(
    { __typename?: 'Applicant' }
    & Pick<Applicant, 'id'>
    & { assessment: Maybe<(
      { __typename?: 'Assessment' }
      & Pick<Assessment, 'id'>
      & { spiderChartScores: Maybe<Array<Maybe<(
        { __typename?: 'SpiderChartScore' }
        & Pick<SpiderChartScore, 'id' | 'name' | 'finalValue'>
        & { info: Maybe<(
          { __typename?: 'SpiderChartScoreInfo' }
          & Pick<SpiderChartScoreInfo, 'averageValue' | 'position' | 'description'>
        )> }
      )>>> }
    )> }
  )> }
);

export type GetApplicantsQueryVariables = Exact<{
  filters: ApplicantFilters;
  offset?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
}>;


export type GetApplicantsQuery = (
  { __typename?: 'RootQueryType' }
  & Pick<RootQueryType, 'countApplicants'>
  & { listApplicants: Maybe<Array<Maybe<(
    { __typename?: 'Applicant' }
    & Pick<Applicant, 'id' | 'source' | 'lastName' | 'firstName' | 'appliedAt' | 'qualified' | 'isFullTime' | 'isShortApplication' | 'picturesUrl'>
    & { status: Maybe<(
      { __typename?: 'StatusUpdate' }
      & Pick<StatusUpdate, 'id' | 'status' | 'happenedAt'>
    )>, job: Maybe<(
      { __typename?: 'Job' }
      & Pick<Job, 'id'>
      & { role: Maybe<(
        { __typename?: 'Role' }
        & Pick<Role, 'id' | 'isProfessional'>
      )> }
    )>, assessment: Maybe<(
      { __typename?: 'Assessment' }
      & Pick<Assessment, 'id' | 'roleFitScore'>
    )> }
  )>>> }
);

export type GetClientsQueryVariables = Exact<{
  filters: ClientFilters;
  offset?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
}>;


export type GetClientsQuery = (
  { __typename?: 'RootQueryType' }
  & Pick<RootQueryType, 'countClients'>
  & { listClients: Maybe<Array<Maybe<(
    { __typename?: 'Client' }
    & Pick<Client, 'id' | 'status' | 'lastName' | 'firstName' | 'submittedAt' | 'picturesUrl' | 'respondedBy'>
    & { assessment: Maybe<(
      { __typename?: 'Assessment' }
      & Pick<Assessment, 'id' | 'roleFitScore'>
    )> }
  )>>> }
);

export type GetClientQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetClientQuery = (
  { __typename?: 'RootQueryType' }
  & { getClient: Maybe<(
    { __typename?: 'Client' }
    & Pick<Client, 'id' | 'email' | 'status' | 'firstName' | 'lastName' | 'respondedBy' | 'submittedAt' | 'picturesUrl' | 'openAnswers' | 'extraAnswers' | 'phoneNumber'>
    & { location: Maybe<(
      { __typename?: 'Location' }
      & Pick<Location, 'id' | 'city' | 'state' | 'streetAddress' | 'complement' | 'zipCode' | 'latitude' | 'longitude'>
    )>, job: Maybe<(
      { __typename?: 'Job' }
      & Pick<Job, 'id'>
      & { role: Maybe<(
        { __typename?: 'Role' }
        & Pick<Role, 'id' | 'name'>
      )> }
    )>, assessment: Maybe<(
      { __typename?: 'Assessment' }
      & Pick<Assessment, 'id' | 'roleFitScore'>
      & { spiderChartScores: Maybe<Array<Maybe<(
        { __typename?: 'SpiderChartScore' }
        & Pick<SpiderChartScore, 'id' | 'name' | 'finalValue'>
        & { info: Maybe<(
          { __typename?: 'SpiderChartScoreInfo' }
          & Pick<SpiderChartScoreInfo, 'averageValue' | 'position' | 'description'>
        )> }
      )>>> }
    )> }
  )> }
);

export type GetCurrentCompanyDataQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentCompanyDataQuery = (
  { __typename?: 'RootQueryType' }
  & { currentUser: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { member: Maybe<(
      { __typename?: 'Member' }
      & Pick<Member, 'id'>
      & { company: Maybe<(
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'name' | 'logoUrl'>
        & { location: Maybe<(
          { __typename?: 'Location' }
          & Pick<Location, 'id' | 'streetAddress' | 'zipCode' | 'city' | 'state' | 'complement'>
        )> }
      )> }
    )> }
  )> }
);

export type GetImpersonatedCompanyDataQueryVariables = Exact<{
  companyId: Scalars['Int'];
}>;


export type GetImpersonatedCompanyDataQuery = (
  { __typename?: 'RootQueryType' }
  & { getCompany: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name' | 'logoUrl'>
    & { location: Maybe<(
      { __typename?: 'Location' }
      & Pick<Location, 'id' | 'streetAddress' | 'zipCode' | 'city' | 'state' | 'complement'>
    )> }
  )> }
);

export type ListRolesQueryVariables = Exact<{ [key: string]: never; }>;


export type ListRolesQuery = (
  { __typename?: 'RootQueryType' }
  & { listRoles: Maybe<Array<Maybe<(
    { __typename?: 'Role' }
    & Pick<Role, 'id' | 'name' | 'imageUrl'>
  )>>> }
);

export type GetCurrentCompanyNumberOfUnprocessedApplicantsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentCompanyNumberOfUnprocessedApplicantsQuery = (
  { __typename?: 'RootQueryType' }
  & { currentUser: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { member: Maybe<(
      { __typename?: 'Member' }
      & Pick<Member, 'id'>
      & { company: Maybe<(
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'name' | 'numberOfUnprocessedApplicants'>
      )> }
    )> }
  )> }
);

export type GetImpersonatedCompanyNumberOfUnprocessedApplicantsQueryVariables = Exact<{
  companyId: Scalars['Int'];
}>;


export type GetImpersonatedCompanyNumberOfUnprocessedApplicantsQuery = (
  { __typename?: 'RootQueryType' }
  & { getCompany: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name' | 'numberOfUnprocessedApplicants'>
  )> }
);

export type GetDashboardApplicationsOverviewQueryVariables = Exact<{
  period: Period;
  jobId: Scalars['Int'];
}>;


export type GetDashboardApplicationsOverviewQuery = (
  { __typename?: 'RootQueryType' }
  & { getApplicationsOverview: Maybe<(
    { __typename?: 'ApplicationsOverview' }
    & Pick<ApplicationsOverview, 'applicants' | 'interviews' | 'hired'>
  )> }
);

export type GetCurrentCompanyDashboardRemainingTokensQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentCompanyDashboardRemainingTokensQuery = (
  { __typename?: 'RootQueryType' }
  & { currentUser: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { member: Maybe<(
      { __typename?: 'Member' }
      & Pick<Member, 'id'>
      & { company: Maybe<(
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'name'>
        & { jobs: Maybe<Array<Maybe<(
          { __typename?: 'Job' }
          & Pick<Job, 'id' | 'numberOfAvailableTokens'>
          & { subscription: Maybe<(
            { __typename?: 'subscription' }
            & Pick<Subscription, 'id'>
            & { plan: Maybe<(
              { __typename?: 'Plan' }
              & Pick<Plan, 'id' | 'name'>
            )> }
          )>, tokensByExpirationDate: Maybe<Array<Maybe<(
            { __typename?: 'TokensByExpirationDate' }
            & Pick<TokensByExpirationDate, 'expirationDate' | 'numberOfAvailableTokens'>
          )>>>, role: Maybe<(
            { __typename?: 'Role' }
            & Pick<Role, 'id' | 'name'>
          )> }
        )>>> }
      )> }
    )> }
  )> }
);

export type GetImpersonatedCompanyDashboardRemainingTokensQueryVariables = Exact<{
  companyId: Scalars['Int'];
}>;


export type GetImpersonatedCompanyDashboardRemainingTokensQuery = (
  { __typename?: 'RootQueryType' }
  & { getCompany: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name'>
    & { jobs: Maybe<Array<Maybe<(
      { __typename?: 'Job' }
      & Pick<Job, 'id' | 'numberOfAvailableTokens'>
      & { subscription: Maybe<(
        { __typename?: 'subscription' }
        & Pick<Subscription, 'id'>
        & { plan: Maybe<(
          { __typename?: 'Plan' }
          & Pick<Plan, 'id' | 'name'>
        )> }
      )>, tokensByExpirationDate: Maybe<Array<Maybe<(
        { __typename?: 'TokensByExpirationDate' }
        & Pick<TokensByExpirationDate, 'expirationDate' | 'numberOfAvailableTokens'>
      )>>>, role: Maybe<(
        { __typename?: 'Role' }
        & Pick<Role, 'id' | 'name'>
      )> }
    )>>> }
  )> }
);

export type GetDashboardCountApplicantsQueryVariables = Exact<{
  statuses?: Maybe<Array<StatusUpdateEnum>>;
  jobId: Scalars['Int'];
}>;


export type GetDashboardCountApplicantsQuery = (
  { __typename?: 'RootQueryType' }
  & Pick<RootQueryType, 'countApplicants'>
);

export type GetDashboardCountStatusUpdatesQueryVariables = Exact<{
  statuses?: Maybe<Array<StatusUpdateEnum>>;
  comparisonPeriod?: Maybe<Period>;
  period: Period;
  jobId: Scalars['Int'];
}>;


export type GetDashboardCountStatusUpdatesQuery = (
  { __typename?: 'RootQueryType' }
  & { countStatusUpdates: Maybe<(
    { __typename?: 'StatusUpdateCount' }
    & Pick<StatusUpdateCount, 'numberOfStatusUpdates' | 'growthRate'>
  )> }
);

export type GetCompanyDashboardSubscriptionsSummaryQueryVariables = Exact<{
  companyId: Scalars['Int'];
}>;


export type GetCompanyDashboardSubscriptionsSummaryQuery = (
  { __typename?: 'RootQueryType' }
  & { getCompany: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id'>
    & { subscriptions: Maybe<Array<Maybe<(
      { __typename?: 'subscription' }
      & Pick<Subscription, 'id'>
      & { plan: Maybe<(
        { __typename?: 'Plan' }
        & Pick<Plan, 'id' | 'name'>
        & { role: Maybe<(
          { __typename?: 'Role' }
          & Pick<Role, 'id' | 'name'>
        )> }
      )>, lastPayment: Maybe<(
        { __typename?: 'Payment' }
        & Pick<Payment, 'id' | 'amount' | 'paidAt'>
      )>, nextPayment: Maybe<(
        { __typename?: 'NextPayment' }
        & Pick<NextPayment, 'amount' | 'expectedDate'>
      )> }
    )>>> }
  )> }
);

export type GetCurrentCompanyDashboardRolesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentCompanyDashboardRolesQuery = (
  { __typename?: 'RootQueryType' }
  & { currentUser: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { member: Maybe<(
      { __typename?: 'Member' }
      & Pick<Member, 'id' | 'occupation'>
      & { company: Maybe<(
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'name'>
        & { jobs: Maybe<Array<Maybe<(
          { __typename?: 'Job' }
          & Pick<Job, 'id'>
          & { role: Maybe<(
            { __typename?: 'Role' }
            & Pick<Role, 'name' | 'id'>
          )> }
        )>>> }
      )> }
    )> }
  )> }
);

export type GetImpersonatedCompanyDashboardRolesQueryVariables = Exact<{
  companyId: Scalars['Int'];
}>;


export type GetImpersonatedCompanyDashboardRolesQuery = (
  { __typename?: 'RootQueryType' }
  & { getCompany: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name'>
    & { jobs: Maybe<Array<Maybe<(
      { __typename?: 'Job' }
      & Pick<Job, 'id'>
      & { role: Maybe<(
        { __typename?: 'Role' }
        & Pick<Role, 'name' | 'id'>
      )> }
    )>>> }
  )> }
);

export type GetJobQueryVariables = Exact<{
  companyId?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
}>;


export type GetJobQuery = (
  { __typename?: 'RootQueryType' }
  & { getJob: Maybe<(
    { __typename?: 'Job' }
    & Pick<Job, 'id'>
    & { company: Maybe<(
      { __typename?: 'Company' }
      & Pick<Company, 'id'>
    )>, subscription: Maybe<(
      { __typename?: 'subscription' }
      & Pick<Subscription, 'id' | 'endAt' | 'billingType'>
      & { plan: Maybe<(
        { __typename?: 'Plan' }
        & Pick<Plan, 'id'>
      )> }
    )>, role: Maybe<(
      { __typename?: 'Role' }
      & Pick<Role, 'id' | 'name'>
      & { plans: Maybe<Array<Maybe<(
        { __typename?: 'Plan' }
        & Pick<Plan, 'id' | 'name' | 'iconUrl' | 'benefits' | 'annualPrice' | 'monthlyPrice' | 'monthlyTokens' | 'annualDiscountPercentage'>
        & { activeDiscount: Maybe<(
          { __typename?: 'PlanDiscount' }
          & Pick<PlanDiscount, 'discountedAnnualPrice' | 'discountedMonthlyPrice'>
          & { discount: Maybe<(
            { __typename?: 'Discount' }
            & Pick<Discount, 'id' | 'rate'>
          )> }
        )> }
      )>>> }
    )> }
  )> }
);

export type GetPaymentReceiptUrlQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetPaymentReceiptUrlQuery = (
  { __typename?: 'RootQueryType' }
  & Pick<RootQueryType, 'getPaymentReceiptUrl'>
);

export type ListCurrentCompanyPaymentsQueryVariables = Exact<{
  offset: Scalars['Int'];
}>;


export type ListCurrentCompanyPaymentsQuery = (
  { __typename?: 'RootQueryType' }
  & { currentUser: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { member: Maybe<(
      { __typename?: 'Member' }
      & Pick<Member, 'id'>
      & { company: Maybe<(
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'name' | 'numberOfPayments'>
        & { payments: Maybe<Array<Maybe<(
          { __typename?: 'Payment' }
          & Pick<Payment, 'id' | 'amount' | 'paidAt' | 'tokensBought'>
          & { extraTokensPack: Maybe<(
            { __typename?: 'ExtraTokensPack' }
            & Pick<ExtraTokensPack, 'id'>
            & { role: Maybe<(
              { __typename?: 'Role' }
              & Pick<Role, 'id' | 'name'>
            )> }
          )>, subscription: Maybe<(
            { __typename?: 'subscription' }
            & Pick<Subscription, 'id'>
            & { plan: Maybe<(
              { __typename?: 'Plan' }
              & Pick<Plan, 'id' | 'name'>
              & { role: Maybe<(
                { __typename?: 'Role' }
                & Pick<Role, 'id' | 'name'>
              )> }
            )> }
          )> }
        )>>> }
      )> }
    )> }
  )> }
);

export type ListCurrentCompanySubscriptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type ListCurrentCompanySubscriptionsQuery = (
  { __typename?: 'RootQueryType' }
  & { currentUser: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { member: Maybe<(
      { __typename?: 'Member' }
      & Pick<Member, 'id'>
      & { company: Maybe<(
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'name'>
        & { availableRoles: Maybe<Array<Maybe<(
          { __typename?: 'Role' }
          & Pick<Role, 'id' | 'name'>
        )>>>, jobs: Maybe<Array<Maybe<(
          { __typename?: 'Job' }
          & Pick<Job, 'id' | 'numberOfAvailableTokens'>
          & { role: Maybe<(
            { __typename?: 'Role' }
            & Pick<Role, 'id' | 'name'>
            & { extraTokensPacks: Maybe<Array<Maybe<(
              { __typename?: 'ExtraTokensPack' }
              & Pick<ExtraTokensPack, 'id'>
            )>>> }
          )>, subscription: Maybe<(
            { __typename?: 'subscription' }
            & Pick<Subscription, 'id' | 'billingType' | 'endAt'>
            & { plan: Maybe<(
              { __typename?: 'Plan' }
              & Pick<Plan, 'id' | 'name' | 'benefits' | 'monthlyTokens' | 'annualPrice' | 'monthlyPrice'>
              & { activeDiscount: Maybe<(
                { __typename?: 'PlanDiscount' }
                & Pick<PlanDiscount, 'discountedAnnualPrice' | 'discountedMonthlyPrice'>
                & { discount: Maybe<(
                  { __typename?: 'Discount' }
                  & Pick<Discount, 'id' | 'endAt' | 'rate'>
                )> }
              )> }
            )> }
          )> }
        )>>> }
      )> }
    )> }
  )> }
);

export type ListExtraTokenPacksQueryVariables = Exact<{
  roleId: Scalars['Int'];
}>;


export type ListExtraTokenPacksQuery = (
  { __typename?: 'RootQueryType' }
  & { getRole: Maybe<(
    { __typename?: 'Role' }
    & Pick<Role, 'id' | 'name'>
    & { extraTokensPacks: Maybe<Array<Maybe<(
      { __typename?: 'ExtraTokensPack' }
      & Pick<ExtraTokensPack, 'id' | 'price' | 'quantity'>
      & { activeDiscount: Maybe<(
        { __typename?: 'ExtraTokensPackDiscount' }
        & Pick<ExtraTokensPackDiscount, 'discountedPrice'>
        & { discount: Maybe<(
          { __typename?: 'Discount' }
          & Pick<Discount, 'id' | 'rate'>
        )> }
      )> }
    )>>> }
  )> }
);

export type ListImpersonatedCompanyPaymentsQueryVariables = Exact<{
  companyId: Scalars['Int'];
  offset: Scalars['Int'];
}>;


export type ListImpersonatedCompanyPaymentsQuery = (
  { __typename?: 'RootQueryType' }
  & { getCompany: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name' | 'numberOfPayments'>
    & { payments: Maybe<Array<Maybe<(
      { __typename?: 'Payment' }
      & Pick<Payment, 'id' | 'amount' | 'paidAt' | 'tokensBought'>
      & { extraTokensPack: Maybe<(
        { __typename?: 'ExtraTokensPack' }
        & Pick<ExtraTokensPack, 'id'>
        & { role: Maybe<(
          { __typename?: 'Role' }
          & Pick<Role, 'id' | 'name'>
        )> }
      )>, subscription: Maybe<(
        { __typename?: 'subscription' }
        & Pick<Subscription, 'id'>
        & { plan: Maybe<(
          { __typename?: 'Plan' }
          & Pick<Plan, 'id' | 'name'>
          & { role: Maybe<(
            { __typename?: 'Role' }
            & Pick<Role, 'id' | 'name'>
          )> }
        )> }
      )> }
    )>>> }
  )> }
);

export type ListImpersonatedCompanySubscriptionsQueryVariables = Exact<{
  companyId: Scalars['Int'];
}>;


export type ListImpersonatedCompanySubscriptionsQuery = (
  { __typename?: 'RootQueryType' }
  & { getCompany: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name'>
    & { availableRoles: Maybe<Array<Maybe<(
      { __typename?: 'Role' }
      & Pick<Role, 'id' | 'name'>
    )>>>, jobs: Maybe<Array<Maybe<(
      { __typename?: 'Job' }
      & Pick<Job, 'id' | 'numberOfAvailableTokens'>
      & { role: Maybe<(
        { __typename?: 'Role' }
        & Pick<Role, 'id' | 'name'>
        & { extraTokensPacks: Maybe<Array<Maybe<(
          { __typename?: 'ExtraTokensPack' }
          & Pick<ExtraTokensPack, 'id'>
        )>>> }
      )>, subscription: Maybe<(
        { __typename?: 'subscription' }
        & Pick<Subscription, 'id' | 'billingType' | 'endAt'>
        & { plan: Maybe<(
          { __typename?: 'Plan' }
          & Pick<Plan, 'id' | 'name' | 'benefits' | 'monthlyTokens' | 'annualPrice' | 'monthlyPrice'>
          & { activeDiscount: Maybe<(
            { __typename?: 'PlanDiscount' }
            & Pick<PlanDiscount, 'discountedAnnualPrice' | 'discountedMonthlyPrice'>
            & { discount: Maybe<(
              { __typename?: 'Discount' }
              & Pick<Discount, 'id' | 'endAt' | 'rate'>
            )> }
          )> }
        )> }
      )> }
    )>>> }
  )> }
);

export type ListCurrentCompanyActiveMembersQueryVariables = Exact<{ [key: string]: never; }>;


export type ListCurrentCompanyActiveMembersQuery = (
  { __typename?: 'RootQueryType' }
  & { currentUser: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { member: Maybe<(
      { __typename?: 'Member' }
      & Pick<Member, 'id'>
      & { company: Maybe<(
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'name'>
        & { activeMembers: Maybe<Array<Maybe<(
          { __typename?: 'Member' }
          & Pick<Member, 'id' | 'name' | 'photo' | 'invitedEmail' | 'phoneNumber' | 'occupation'>
          & { user: Maybe<(
            { __typename?: 'User' }
            & Pick<User, 'id' | 'email' | 'insertedAt'>
          )> }
        )>>> }
      )> }
    )> }
  )> }
);

export type ListImpersonatedCompanyActiveMembersQueryVariables = Exact<{
  companyId: Scalars['Int'];
}>;


export type ListImpersonatedCompanyActiveMembersQuery = (
  { __typename?: 'RootQueryType' }
  & { getCompany: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name'>
    & { activeMembers: Maybe<Array<Maybe<(
      { __typename?: 'Member' }
      & Pick<Member, 'id' | 'name' | 'photo' | 'invitedEmail' | 'phoneNumber' | 'occupation'>
      & { user: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'insertedAt'>
      )> }
    )>>> }
  )> }
);

export type ListCurrentCompanyCreditCardsQueryVariables = Exact<{ [key: string]: never; }>;


export type ListCurrentCompanyCreditCardsQuery = (
  { __typename?: 'RootQueryType' }
  & { currentUser: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { member: Maybe<(
      { __typename?: 'Member' }
      & Pick<Member, 'id'>
      & { company: Maybe<(
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'name'>
        & { creditCards: Maybe<Array<Maybe<(
          { __typename?: 'CreditCard' }
          & Pick<CreditCard, 'id' | 'brand' | 'last4' | 'isDefault' | 'expMonth' | 'expYear'>
        )>>> }
      )> }
    )> }
  )> }
);

export type ListImpersonatedCompanyCreditCardsQueryVariables = Exact<{
  companyId: Scalars['Int'];
}>;


export type ListImpersonatedCompanyCreditCardsQuery = (
  { __typename?: 'RootQueryType' }
  & { getCompany: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name'>
    & { creditCards: Maybe<Array<Maybe<(
      { __typename?: 'CreditCard' }
      & Pick<CreditCard, 'id' | 'brand' | 'last4' | 'isDefault' | 'expMonth' | 'expYear'>
    )>>> }
  )> }
);

export type GetLandingPageQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetLandingPageQuery = (
  { __typename?: 'RootQueryType' }
  & { getLandingPage: Maybe<(
    { __typename?: 'LandingPage' }
    & Pick<LandingPage, 'id' | 'headerImageUrl' | 'jobDescription' | 'layoutLabel' | 'buttonText' | 'subtitle' | 'logoUrl' | 'title' | 'color'>
  )> }
);

export type GetEditLandingPageTemplateCurrentCompanyQueryVariables = Exact<{ [key: string]: never; }>;


export type GetEditLandingPageTemplateCurrentCompanyQuery = (
  { __typename?: 'RootQueryType' }
  & { currentUser: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { member: Maybe<(
      { __typename?: 'Member' }
      & Pick<Member, 'id'>
      & { company: Maybe<(
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'name'>
        & { jobs: Maybe<Array<Maybe<(
          { __typename?: 'Job' }
          & Pick<Job, 'id'>
          & { role: Maybe<(
            { __typename?: 'Role' }
            & Pick<Role, 'id' | 'name'>
          )>, landingPage: Maybe<(
            { __typename?: 'LandingPage' }
            & Pick<LandingPage, 'id' | 'layoutLabel'>
          )> }
        )>>> }
      )> }
    )> }
  )> }
);

export type GetEditLandingPageTemplateImpersonatedCompanyQueryVariables = Exact<{
  companyId: Scalars['Int'];
}>;


export type GetEditLandingPageTemplateImpersonatedCompanyQuery = (
  { __typename?: 'RootQueryType' }
  & { getCompany: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name'>
    & { jobs: Maybe<Array<Maybe<(
      { __typename?: 'Job' }
      & Pick<Job, 'id'>
      & { role: Maybe<(
        { __typename?: 'Role' }
        & Pick<Role, 'name' | 'id'>
      )>, landingPage: Maybe<(
        { __typename?: 'LandingPage' }
        & Pick<LandingPage, 'id' | 'layoutLabel'>
      )> }
    )>>> }
  )> }
);

export type GetCurrentUserProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentUserProfileQuery = (
  { __typename?: 'RootQueryType' }
  & { currentUser: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'email'>
    & { member: Maybe<(
      { __typename?: 'Member' }
      & Pick<Member, 'id' | 'name' | 'photo' | 'occupation' | 'phoneNumber'>
    )> }
  )> }
);

export const ListLocationsFieldsFragmentDoc = gql`
    fragment listLocationsFields on LocationWithSubjects {
  city
  state
  subjectCount
}
    `;
export const GetCurrentUserRoleDocument = gql`
    query GetCurrentUserRole {
  currentUser {
    id
    role
  }
}
    `;

/**
 * __useGetCurrentUserRoleQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserRoleQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserRoleQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentUserRoleQuery, GetCurrentUserRoleQueryVariables>) {
        return Apollo.useQuery<GetCurrentUserRoleQuery, GetCurrentUserRoleQueryVariables>(GetCurrentUserRoleDocument, baseOptions);
      }
export function useGetCurrentUserRoleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentUserRoleQuery, GetCurrentUserRoleQueryVariables>) {
          return Apollo.useLazyQuery<GetCurrentUserRoleQuery, GetCurrentUserRoleQueryVariables>(GetCurrentUserRoleDocument, baseOptions);
        }
export type GetCurrentUserRoleQueryHookResult = ReturnType<typeof useGetCurrentUserRoleQuery>;
export type GetCurrentUserRoleLazyQueryHookResult = ReturnType<typeof useGetCurrentUserRoleLazyQuery>;
export type GetCurrentUserRoleQueryResult = Apollo.QueryResult<GetCurrentUserRoleQuery, GetCurrentUserRoleQueryVariables>;
export const ListAdminImpersonatorCompaniesDocument = gql`
    query ListAdminImpersonatorCompanies {
  currentUser {
    id
    member {
      id
      company {
        id
      }
    }
  }
  listCompanies {
    id
    name
    primaryManager {
      id
      user {
        id
        email
      }
    }
    jobs {
      id
      role {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useListAdminImpersonatorCompaniesQuery__
 *
 * To run a query within a React component, call `useListAdminImpersonatorCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAdminImpersonatorCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAdminImpersonatorCompaniesQuery({
 *   variables: {
 *   },
 * });
 */
export function useListAdminImpersonatorCompaniesQuery(baseOptions?: Apollo.QueryHookOptions<ListAdminImpersonatorCompaniesQuery, ListAdminImpersonatorCompaniesQueryVariables>) {
        return Apollo.useQuery<ListAdminImpersonatorCompaniesQuery, ListAdminImpersonatorCompaniesQueryVariables>(ListAdminImpersonatorCompaniesDocument, baseOptions);
      }
export function useListAdminImpersonatorCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListAdminImpersonatorCompaniesQuery, ListAdminImpersonatorCompaniesQueryVariables>) {
          return Apollo.useLazyQuery<ListAdminImpersonatorCompaniesQuery, ListAdminImpersonatorCompaniesQueryVariables>(ListAdminImpersonatorCompaniesDocument, baseOptions);
        }
export type ListAdminImpersonatorCompaniesQueryHookResult = ReturnType<typeof useListAdminImpersonatorCompaniesQuery>;
export type ListAdminImpersonatorCompaniesLazyQueryHookResult = ReturnType<typeof useListAdminImpersonatorCompaniesLazyQuery>;
export type ListAdminImpersonatorCompaniesQueryResult = Apollo.QueryResult<ListAdminImpersonatorCompaniesQuery, ListAdminImpersonatorCompaniesQueryVariables>;
export const GetApplicantResponseDocument = gql`
    query GetApplicantResponse($id: String!) {
  getApplicantResponse(id: $id) {
    id
    complement
    availability
    availableAt
    city
    dayAvailability
    email
    extraAnswers
    firstName
    formLinkShortcode
    fulfilledRequirements
    isFullTime
    isShortApplication
    lastName
    latitude
    longitude
    openAnswers
    phoneNumber
    pictures
    resume
    source
    state
    streetAddress
    zipCode
  }
}
    `;

/**
 * __useGetApplicantResponseQuery__
 *
 * To run a query within a React component, call `useGetApplicantResponseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApplicantResponseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApplicantResponseQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetApplicantResponseQuery(baseOptions?: Apollo.QueryHookOptions<GetApplicantResponseQuery, GetApplicantResponseQueryVariables>) {
        return Apollo.useQuery<GetApplicantResponseQuery, GetApplicantResponseQueryVariables>(GetApplicantResponseDocument, baseOptions);
      }
export function useGetApplicantResponseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetApplicantResponseQuery, GetApplicantResponseQueryVariables>) {
          return Apollo.useLazyQuery<GetApplicantResponseQuery, GetApplicantResponseQueryVariables>(GetApplicantResponseDocument, baseOptions);
        }
export type GetApplicantResponseQueryHookResult = ReturnType<typeof useGetApplicantResponseQuery>;
export type GetApplicantResponseLazyQueryHookResult = ReturnType<typeof useGetApplicantResponseLazyQuery>;
export type GetApplicantResponseQueryResult = Apollo.QueryResult<GetApplicantResponseQuery, GetApplicantResponseQueryVariables>;
export const GetClientResponseDocument = gql`
    query GetClientResponse($id: String!) {
  getClientResponse(id: $id) {
    id
    complement
    city
    email
    extraAnswers
    firstName
    formLinkShortcode
    lastName
    latitude
    longitude
    openAnswers
    phoneNumber
    pictures
    respondedBy
    state
    streetAddress
    zipCode
  }
}
    `;

/**
 * __useGetClientResponseQuery__
 *
 * To run a query within a React component, call `useGetClientResponseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientResponseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientResponseQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetClientResponseQuery(baseOptions?: Apollo.QueryHookOptions<GetClientResponseQuery, GetClientResponseQueryVariables>) {
        return Apollo.useQuery<GetClientResponseQuery, GetClientResponseQueryVariables>(GetClientResponseDocument, baseOptions);
      }
export function useGetClientResponseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClientResponseQuery, GetClientResponseQueryVariables>) {
          return Apollo.useLazyQuery<GetClientResponseQuery, GetClientResponseQueryVariables>(GetClientResponseDocument, baseOptions);
        }
export type GetClientResponseQueryHookResult = ReturnType<typeof useGetClientResponseQuery>;
export type GetClientResponseLazyQueryHookResult = ReturnType<typeof useGetClientResponseLazyQuery>;
export type GetClientResponseQueryResult = Apollo.QueryResult<GetClientResponseQuery, GetClientResponseQueryVariables>;
export const GetFormDocument = gql`
    query GetForm($formType: FormTypeEnum!, $id: Int!) {
  getForm(id: $id) {
    id
    requirements
    referrerOptions
    clientOpenQuestionsIntro
    applicantOpenQuestionsIntro
    clientThankYouMessage
    applicantThankYouMessage
    clientIntroductoryMessage
    applicantIntroductoryMessage
    allowImagesUpload
    job {
      id
      role {
        id
        applicantOpenQuestions
        clientOpenQuestions
      }
    }
    fixedSectionsConfiguration(formType: $formType) {
      id
      showAvailability
      showResume
      showSource
      showRequirements
    }
    extraSections(formType: $formType) {
      id
      name
      show
      isMultiple
      customIntro
      minimumNumber
      extraQuestions {
        id
        type
        options
        question
        position
        displayType
      }
    }
  }
}
    `;

/**
 * __useGetFormQuery__
 *
 * To run a query within a React component, call `useGetFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFormQuery({
 *   variables: {
 *      formType: // value for 'formType'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetFormQuery(baseOptions?: Apollo.QueryHookOptions<GetFormQuery, GetFormQueryVariables>) {
        return Apollo.useQuery<GetFormQuery, GetFormQueryVariables>(GetFormDocument, baseOptions);
      }
export function useGetFormLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFormQuery, GetFormQueryVariables>) {
          return Apollo.useLazyQuery<GetFormQuery, GetFormQueryVariables>(GetFormDocument, baseOptions);
        }
export type GetFormQueryHookResult = ReturnType<typeof useGetFormQuery>;
export type GetFormLazyQueryHookResult = ReturnType<typeof useGetFormLazyQuery>;
export type GetFormQueryResult = Apollo.QueryResult<GetFormQuery, GetFormQueryVariables>;
export const DeleteLandingPageDocument = gql`
    mutation DeleteLandingPage($id: Int!) {
  deleteLandingPage(id: $id) {
    id
  }
}
    `;
export type DeleteLandingPageMutationFn = Apollo.MutationFunction<DeleteLandingPageMutation, DeleteLandingPageMutationVariables>;

/**
 * __useDeleteLandingPageMutation__
 *
 * To run a mutation, you first call `useDeleteLandingPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLandingPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLandingPageMutation, { data, loading, error }] = useDeleteLandingPageMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteLandingPageMutation(baseOptions?: Apollo.MutationHookOptions<DeleteLandingPageMutation, DeleteLandingPageMutationVariables>) {
        return Apollo.useMutation<DeleteLandingPageMutation, DeleteLandingPageMutationVariables>(DeleteLandingPageDocument, baseOptions);
      }
export type DeleteLandingPageMutationHookResult = ReturnType<typeof useDeleteLandingPageMutation>;
export type DeleteLandingPageMutationResult = Apollo.MutationResult<DeleteLandingPageMutation>;
export type DeleteLandingPageMutationOptions = Apollo.BaseMutationOptions<DeleteLandingPageMutation, DeleteLandingPageMutationVariables>;
export const GetHeaderCurrentUserDocument = gql`
    query GetHeaderCurrentUser {
  currentUser {
    id
    member {
      id
      photo
      occupation
    }
  }
}
    `;

/**
 * __useGetHeaderCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetHeaderCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHeaderCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHeaderCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetHeaderCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<GetHeaderCurrentUserQuery, GetHeaderCurrentUserQueryVariables>) {
        return Apollo.useQuery<GetHeaderCurrentUserQuery, GetHeaderCurrentUserQueryVariables>(GetHeaderCurrentUserDocument, baseOptions);
      }
export function useGetHeaderCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHeaderCurrentUserQuery, GetHeaderCurrentUserQueryVariables>) {
          return Apollo.useLazyQuery<GetHeaderCurrentUserQuery, GetHeaderCurrentUserQueryVariables>(GetHeaderCurrentUserDocument, baseOptions);
        }
export type GetHeaderCurrentUserQueryHookResult = ReturnType<typeof useGetHeaderCurrentUserQuery>;
export type GetHeaderCurrentUserLazyQueryHookResult = ReturnType<typeof useGetHeaderCurrentUserLazyQuery>;
export type GetHeaderCurrentUserQueryResult = Apollo.QueryResult<GetHeaderCurrentUserQuery, GetHeaderCurrentUserQueryVariables>;
export const GetDefaultCreditCardDocument = gql`
    query GetDefaultCreditCard {
  currentUser {
    id
    member {
      id
      company {
        id
        defaultCreditCard {
          id
          last4
          brand
        }
      }
    }
  }
}
    `;

/**
 * __useGetDefaultCreditCardQuery__
 *
 * To run a query within a React component, call `useGetDefaultCreditCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDefaultCreditCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDefaultCreditCardQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDefaultCreditCardQuery(baseOptions?: Apollo.QueryHookOptions<GetDefaultCreditCardQuery, GetDefaultCreditCardQueryVariables>) {
        return Apollo.useQuery<GetDefaultCreditCardQuery, GetDefaultCreditCardQueryVariables>(GetDefaultCreditCardDocument, baseOptions);
      }
export function useGetDefaultCreditCardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDefaultCreditCardQuery, GetDefaultCreditCardQueryVariables>) {
          return Apollo.useLazyQuery<GetDefaultCreditCardQuery, GetDefaultCreditCardQueryVariables>(GetDefaultCreditCardDocument, baseOptions);
        }
export type GetDefaultCreditCardQueryHookResult = ReturnType<typeof useGetDefaultCreditCardQuery>;
export type GetDefaultCreditCardLazyQueryHookResult = ReturnType<typeof useGetDefaultCreditCardLazyQuery>;
export type GetDefaultCreditCardQueryResult = Apollo.QueryResult<GetDefaultCreditCardQuery, GetDefaultCreditCardQueryVariables>;
export const ListMatchingApplicantsDocument = gql`
    query ListMatchingApplicants($filters: ApplicantFilters!, $search: String) {
  listApplicants(filters: $filters, search: $search) {
    id
    lastName
    firstName
    isFullTime
    picturesUrl
    availableAt
    recruiterNotes
    recruiterRating
    location {
      city
      state
      zipCode
      streetAddress
    }
    assessment {
      id
      roleFitScore
      spiderChartScores {
        id
        name
        finalValue
        info {
          position
        }
      }
    }
  }
}
    `;

/**
 * __useListMatchingApplicantsQuery__
 *
 * To run a query within a React component, call `useListMatchingApplicantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListMatchingApplicantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListMatchingApplicantsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useListMatchingApplicantsQuery(baseOptions?: Apollo.QueryHookOptions<ListMatchingApplicantsQuery, ListMatchingApplicantsQueryVariables>) {
        return Apollo.useQuery<ListMatchingApplicantsQuery, ListMatchingApplicantsQueryVariables>(ListMatchingApplicantsDocument, baseOptions);
      }
export function useListMatchingApplicantsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListMatchingApplicantsQuery, ListMatchingApplicantsQueryVariables>) {
          return Apollo.useLazyQuery<ListMatchingApplicantsQuery, ListMatchingApplicantsQueryVariables>(ListMatchingApplicantsDocument, baseOptions);
        }
export type ListMatchingApplicantsQueryHookResult = ReturnType<typeof useListMatchingApplicantsQuery>;
export type ListMatchingApplicantsLazyQueryHookResult = ReturnType<typeof useListMatchingApplicantsLazyQuery>;
export type ListMatchingApplicantsQueryResult = Apollo.QueryResult<ListMatchingApplicantsQuery, ListMatchingApplicantsQueryVariables>;
export const GetCurrentUserDocument = gql`
    query GetCurrentUser {
  currentUser {
    id
  }
}
    `;

/**
 * __useGetCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
        return Apollo.useQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, baseOptions);
      }
export function useGetCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
          return Apollo.useLazyQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, baseOptions);
        }
export type GetCurrentUserQueryHookResult = ReturnType<typeof useGetCurrentUserQuery>;
export type GetCurrentUserLazyQueryHookResult = ReturnType<typeof useGetCurrentUserLazyQuery>;
export type GetCurrentUserQueryResult = Apollo.QueryResult<GetCurrentUserQuery, GetCurrentUserQueryVariables>;
export const GetCurrentCompanyWithJobsDocument = gql`
    query GetCurrentCompanyWithJobs {
  currentUser {
    id
    member {
      id
      company {
        id
        name
        jobs {
          id
          role {
            name
            id
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetCurrentCompanyWithJobsQuery__
 *
 * To run a query within a React component, call `useGetCurrentCompanyWithJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentCompanyWithJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentCompanyWithJobsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentCompanyWithJobsQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentCompanyWithJobsQuery, GetCurrentCompanyWithJobsQueryVariables>) {
        return Apollo.useQuery<GetCurrentCompanyWithJobsQuery, GetCurrentCompanyWithJobsQueryVariables>(GetCurrentCompanyWithJobsDocument, baseOptions);
      }
export function useGetCurrentCompanyWithJobsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentCompanyWithJobsQuery, GetCurrentCompanyWithJobsQueryVariables>) {
          return Apollo.useLazyQuery<GetCurrentCompanyWithJobsQuery, GetCurrentCompanyWithJobsQueryVariables>(GetCurrentCompanyWithJobsDocument, baseOptions);
        }
export type GetCurrentCompanyWithJobsQueryHookResult = ReturnType<typeof useGetCurrentCompanyWithJobsQuery>;
export type GetCurrentCompanyWithJobsLazyQueryHookResult = ReturnType<typeof useGetCurrentCompanyWithJobsLazyQuery>;
export type GetCurrentCompanyWithJobsQueryResult = Apollo.QueryResult<GetCurrentCompanyWithJobsQuery, GetCurrentCompanyWithJobsQueryVariables>;
export const GetImpersonatedCompanyWithJobsDocument = gql`
    query GetImpersonatedCompanyWithJobs($companyId: Int!) {
  getCompany(id: $companyId) {
    id
    name
    jobs {
      id
      role {
        name
        id
      }
    }
  }
}
    `;

/**
 * __useGetImpersonatedCompanyWithJobsQuery__
 *
 * To run a query within a React component, call `useGetImpersonatedCompanyWithJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetImpersonatedCompanyWithJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetImpersonatedCompanyWithJobsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetImpersonatedCompanyWithJobsQuery(baseOptions?: Apollo.QueryHookOptions<GetImpersonatedCompanyWithJobsQuery, GetImpersonatedCompanyWithJobsQueryVariables>) {
        return Apollo.useQuery<GetImpersonatedCompanyWithJobsQuery, GetImpersonatedCompanyWithJobsQueryVariables>(GetImpersonatedCompanyWithJobsDocument, baseOptions);
      }
export function useGetImpersonatedCompanyWithJobsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetImpersonatedCompanyWithJobsQuery, GetImpersonatedCompanyWithJobsQueryVariables>) {
          return Apollo.useLazyQuery<GetImpersonatedCompanyWithJobsQuery, GetImpersonatedCompanyWithJobsQueryVariables>(GetImpersonatedCompanyWithJobsDocument, baseOptions);
        }
export type GetImpersonatedCompanyWithJobsQueryHookResult = ReturnType<typeof useGetImpersonatedCompanyWithJobsQuery>;
export type GetImpersonatedCompanyWithJobsLazyQueryHookResult = ReturnType<typeof useGetImpersonatedCompanyWithJobsLazyQuery>;
export type GetImpersonatedCompanyWithJobsQueryResult = Apollo.QueryResult<GetImpersonatedCompanyWithJobsQuery, GetImpersonatedCompanyWithJobsQueryVariables>;
export const ListApplicantLocationsDocument = gql`
    query ListApplicantLocations($jobId: Int!) {
  listApplicantLocations(jobId: $jobId) {
    city
    state
    subjectCount
  }
}
    `;

/**
 * __useListApplicantLocationsQuery__
 *
 * To run a query within a React component, call `useListApplicantLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListApplicantLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListApplicantLocationsQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useListApplicantLocationsQuery(baseOptions?: Apollo.QueryHookOptions<ListApplicantLocationsQuery, ListApplicantLocationsQueryVariables>) {
        return Apollo.useQuery<ListApplicantLocationsQuery, ListApplicantLocationsQueryVariables>(ListApplicantLocationsDocument, baseOptions);
      }
export function useListApplicantLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListApplicantLocationsQuery, ListApplicantLocationsQueryVariables>) {
          return Apollo.useLazyQuery<ListApplicantLocationsQuery, ListApplicantLocationsQueryVariables>(ListApplicantLocationsDocument, baseOptions);
        }
export type ListApplicantLocationsQueryHookResult = ReturnType<typeof useListApplicantLocationsQuery>;
export type ListApplicantLocationsLazyQueryHookResult = ReturnType<typeof useListApplicantLocationsLazyQuery>;
export type ListApplicantLocationsQueryResult = Apollo.QueryResult<ListApplicantLocationsQuery, ListApplicantLocationsQueryVariables>;
export const ListClientLocationsDocument = gql`
    query ListClientLocations($jobId: Int!) {
  listClientLocations(jobId: $jobId) {
    ...listLocationsFields
  }
}
    ${ListLocationsFieldsFragmentDoc}`;

/**
 * __useListClientLocationsQuery__
 *
 * To run a query within a React component, call `useListClientLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListClientLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListClientLocationsQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useListClientLocationsQuery(baseOptions?: Apollo.QueryHookOptions<ListClientLocationsQuery, ListClientLocationsQueryVariables>) {
        return Apollo.useQuery<ListClientLocationsQuery, ListClientLocationsQueryVariables>(ListClientLocationsDocument, baseOptions);
      }
export function useListClientLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListClientLocationsQuery, ListClientLocationsQueryVariables>) {
          return Apollo.useLazyQuery<ListClientLocationsQuery, ListClientLocationsQueryVariables>(ListClientLocationsDocument, baseOptions);
        }
export type ListClientLocationsQueryHookResult = ReturnType<typeof useListClientLocationsQuery>;
export type ListClientLocationsLazyQueryHookResult = ReturnType<typeof useListClientLocationsLazyQuery>;
export type ListClientLocationsQueryResult = Apollo.QueryResult<ListClientLocationsQuery, ListClientLocationsQueryVariables>;
export const AcceptInvitationAndLoginDocument = gql`
    mutation AcceptInvitationAndLogin($email: String!, $password: String!, $token: String!) {
  acceptInvitationAndLogin(email: $email, password: $password, token: $token)
}
    `;
export type AcceptInvitationAndLoginMutationFn = Apollo.MutationFunction<AcceptInvitationAndLoginMutation, AcceptInvitationAndLoginMutationVariables>;

/**
 * __useAcceptInvitationAndLoginMutation__
 *
 * To run a mutation, you first call `useAcceptInvitationAndLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptInvitationAndLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptInvitationAndLoginMutation, { data, loading, error }] = useAcceptInvitationAndLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useAcceptInvitationAndLoginMutation(baseOptions?: Apollo.MutationHookOptions<AcceptInvitationAndLoginMutation, AcceptInvitationAndLoginMutationVariables>) {
        return Apollo.useMutation<AcceptInvitationAndLoginMutation, AcceptInvitationAndLoginMutationVariables>(AcceptInvitationAndLoginDocument, baseOptions);
      }
export type AcceptInvitationAndLoginMutationHookResult = ReturnType<typeof useAcceptInvitationAndLoginMutation>;
export type AcceptInvitationAndLoginMutationResult = Apollo.MutationResult<AcceptInvitationAndLoginMutation>;
export type AcceptInvitationAndLoginMutationOptions = Apollo.BaseMutationOptions<AcceptInvitationAndLoginMutation, AcceptInvitationAndLoginMutationVariables>;
export const BuyTokensDocument = gql`
    mutation BuyTokens($extraTokensPackId: Int!, $companyId: Int!) {
  buyTokens(extraTokensPackId: $extraTokensPackId, companyId: $companyId) {
    id
  }
}
    `;
export type BuyTokensMutationFn = Apollo.MutationFunction<BuyTokensMutation, BuyTokensMutationVariables>;

/**
 * __useBuyTokensMutation__
 *
 * To run a mutation, you first call `useBuyTokensMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBuyTokensMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [buyTokensMutation, { data, loading, error }] = useBuyTokensMutation({
 *   variables: {
 *      extraTokensPackId: // value for 'extraTokensPackId'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useBuyTokensMutation(baseOptions?: Apollo.MutationHookOptions<BuyTokensMutation, BuyTokensMutationVariables>) {
        return Apollo.useMutation<BuyTokensMutation, BuyTokensMutationVariables>(BuyTokensDocument, baseOptions);
      }
export type BuyTokensMutationHookResult = ReturnType<typeof useBuyTokensMutation>;
export type BuyTokensMutationResult = Apollo.MutationResult<BuyTokensMutation>;
export type BuyTokensMutationOptions = Apollo.BaseMutationOptions<BuyTokensMutation, BuyTokensMutationVariables>;
export const CancelSubscriptionDocument = gql`
    mutation CancelSubscription($id: Int!) {
  cancelSubscription(id: $id) {
    id
    endAt
  }
}
    `;
export type CancelSubscriptionMutationFn = Apollo.MutationFunction<CancelSubscriptionMutation, CancelSubscriptionMutationVariables>;

/**
 * __useCancelSubscriptionMutation__
 *
 * To run a mutation, you first call `useCancelSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSubscriptionMutation, { data, loading, error }] = useCancelSubscriptionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCancelSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<CancelSubscriptionMutation, CancelSubscriptionMutationVariables>) {
        return Apollo.useMutation<CancelSubscriptionMutation, CancelSubscriptionMutationVariables>(CancelSubscriptionDocument, baseOptions);
      }
export type CancelSubscriptionMutationHookResult = ReturnType<typeof useCancelSubscriptionMutation>;
export type CancelSubscriptionMutationResult = Apollo.MutationResult<CancelSubscriptionMutation>;
export type CancelSubscriptionMutationOptions = Apollo.BaseMutationOptions<CancelSubscriptionMutation, CancelSubscriptionMutationVariables>;
export const CreateApplicantResponseDocument = gql`
    mutation CreateApplicantResponse($params: ApplicantResponseCreateParams!) {
  createApplicantResponse(params: $params) {
    id
  }
}
    `;
export type CreateApplicantResponseMutationFn = Apollo.MutationFunction<CreateApplicantResponseMutation, CreateApplicantResponseMutationVariables>;

/**
 * __useCreateApplicantResponseMutation__
 *
 * To run a mutation, you first call `useCreateApplicantResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApplicantResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApplicantResponseMutation, { data, loading, error }] = useCreateApplicantResponseMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useCreateApplicantResponseMutation(baseOptions?: Apollo.MutationHookOptions<CreateApplicantResponseMutation, CreateApplicantResponseMutationVariables>) {
        return Apollo.useMutation<CreateApplicantResponseMutation, CreateApplicantResponseMutationVariables>(CreateApplicantResponseDocument, baseOptions);
      }
export type CreateApplicantResponseMutationHookResult = ReturnType<typeof useCreateApplicantResponseMutation>;
export type CreateApplicantResponseMutationResult = Apollo.MutationResult<CreateApplicantResponseMutation>;
export type CreateApplicantResponseMutationOptions = Apollo.BaseMutationOptions<CreateApplicantResponseMutation, CreateApplicantResponseMutationVariables>;
export const CreateClientResponseDocument = gql`
    mutation CreateClientResponse($params: ClientResponseCreateParams!) {
  createClientResponse(params: $params) {
    id
  }
}
    `;
export type CreateClientResponseMutationFn = Apollo.MutationFunction<CreateClientResponseMutation, CreateClientResponseMutationVariables>;

/**
 * __useCreateClientResponseMutation__
 *
 * To run a mutation, you first call `useCreateClientResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClientResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClientResponseMutation, { data, loading, error }] = useCreateClientResponseMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useCreateClientResponseMutation(baseOptions?: Apollo.MutationHookOptions<CreateClientResponseMutation, CreateClientResponseMutationVariables>) {
        return Apollo.useMutation<CreateClientResponseMutation, CreateClientResponseMutationVariables>(CreateClientResponseDocument, baseOptions);
      }
export type CreateClientResponseMutationHookResult = ReturnType<typeof useCreateClientResponseMutation>;
export type CreateClientResponseMutationResult = Apollo.MutationResult<CreateClientResponseMutation>;
export type CreateClientResponseMutationOptions = Apollo.BaseMutationOptions<CreateClientResponseMutation, CreateClientResponseMutationVariables>;
export const CreateCreditCardDocument = gql`
    mutation CreateCreditCard($cardSource: String!, $companyId: Int!) {
  createCreditCard(cardSource: $cardSource, companyId: $companyId) {
    id
  }
}
    `;
export type CreateCreditCardMutationFn = Apollo.MutationFunction<CreateCreditCardMutation, CreateCreditCardMutationVariables>;

/**
 * __useCreateCreditCardMutation__
 *
 * To run a mutation, you first call `useCreateCreditCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCreditCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCreditCardMutation, { data, loading, error }] = useCreateCreditCardMutation({
 *   variables: {
 *      cardSource: // value for 'cardSource'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useCreateCreditCardMutation(baseOptions?: Apollo.MutationHookOptions<CreateCreditCardMutation, CreateCreditCardMutationVariables>) {
        return Apollo.useMutation<CreateCreditCardMutation, CreateCreditCardMutationVariables>(CreateCreditCardDocument, baseOptions);
      }
export type CreateCreditCardMutationHookResult = ReturnType<typeof useCreateCreditCardMutation>;
export type CreateCreditCardMutationResult = Apollo.MutationResult<CreateCreditCardMutation>;
export type CreateCreditCardMutationOptions = Apollo.BaseMutationOptions<CreateCreditCardMutation, CreateCreditCardMutationVariables>;
export const CreateJobDocument = gql`
    mutation CreateJob($params: JobCreateParams!) {
  createJob(params: $params) {
    id
  }
}
    `;
export type CreateJobMutationFn = Apollo.MutationFunction<CreateJobMutation, CreateJobMutationVariables>;

/**
 * __useCreateJobMutation__
 *
 * To run a mutation, you first call `useCreateJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createJobMutation, { data, loading, error }] = useCreateJobMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useCreateJobMutation(baseOptions?: Apollo.MutationHookOptions<CreateJobMutation, CreateJobMutationVariables>) {
        return Apollo.useMutation<CreateJobMutation, CreateJobMutationVariables>(CreateJobDocument, baseOptions);
      }
export type CreateJobMutationHookResult = ReturnType<typeof useCreateJobMutation>;
export type CreateJobMutationResult = Apollo.MutationResult<CreateJobMutation>;
export type CreateJobMutationOptions = Apollo.BaseMutationOptions<CreateJobMutation, CreateJobMutationVariables>;
export const CreateMemberDocument = gql`
    mutation CreateMember($params: MemberCreateParams!) {
  createMember(params: $params) {
    id
  }
}
    `;
export type CreateMemberMutationFn = Apollo.MutationFunction<CreateMemberMutation, CreateMemberMutationVariables>;

/**
 * __useCreateMemberMutation__
 *
 * To run a mutation, you first call `useCreateMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMemberMutation, { data, loading, error }] = useCreateMemberMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useCreateMemberMutation(baseOptions?: Apollo.MutationHookOptions<CreateMemberMutation, CreateMemberMutationVariables>) {
        return Apollo.useMutation<CreateMemberMutation, CreateMemberMutationVariables>(CreateMemberDocument, baseOptions);
      }
export type CreateMemberMutationHookResult = ReturnType<typeof useCreateMemberMutation>;
export type CreateMemberMutationResult = Apollo.MutationResult<CreateMemberMutation>;
export type CreateMemberMutationOptions = Apollo.BaseMutationOptions<CreateMemberMutation, CreateMemberMutationVariables>;
export const CreateOrUpdateExtraQuestionDocument = gql`
    mutation CreateOrUpdateExtraQuestion($extraQuestionId: Int!, $formId: Int!, $params: ExtraQuestionParams!) {
  createOrUpdateExtraQuestion(extraQuestionId: $extraQuestionId, formId: $formId, params: $params) {
    id
  }
}
    `;
export type CreateOrUpdateExtraQuestionMutationFn = Apollo.MutationFunction<CreateOrUpdateExtraQuestionMutation, CreateOrUpdateExtraQuestionMutationVariables>;

/**
 * __useCreateOrUpdateExtraQuestionMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateExtraQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateExtraQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateExtraQuestionMutation, { data, loading, error }] = useCreateOrUpdateExtraQuestionMutation({
 *   variables: {
 *      extraQuestionId: // value for 'extraQuestionId'
 *      formId: // value for 'formId'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useCreateOrUpdateExtraQuestionMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrUpdateExtraQuestionMutation, CreateOrUpdateExtraQuestionMutationVariables>) {
        return Apollo.useMutation<CreateOrUpdateExtraQuestionMutation, CreateOrUpdateExtraQuestionMutationVariables>(CreateOrUpdateExtraQuestionDocument, baseOptions);
      }
export type CreateOrUpdateExtraQuestionMutationHookResult = ReturnType<typeof useCreateOrUpdateExtraQuestionMutation>;
export type CreateOrUpdateExtraQuestionMutationResult = Apollo.MutationResult<CreateOrUpdateExtraQuestionMutation>;
export type CreateOrUpdateExtraQuestionMutationOptions = Apollo.BaseMutationOptions<CreateOrUpdateExtraQuestionMutation, CreateOrUpdateExtraQuestionMutationVariables>;
export const CreateOrUpdateExtraSectionDocument = gql`
    mutation CreateOrUpdateExtraSection($extraSectionId: Int!, $formId: Int!, $params: ExtraSectionParams!) {
  createOrUpdateExtraSection(extraSectionId: $extraSectionId, formId: $formId, params: $params) {
    id
  }
}
    `;
export type CreateOrUpdateExtraSectionMutationFn = Apollo.MutationFunction<CreateOrUpdateExtraSectionMutation, CreateOrUpdateExtraSectionMutationVariables>;

/**
 * __useCreateOrUpdateExtraSectionMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateExtraSectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateExtraSectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateExtraSectionMutation, { data, loading, error }] = useCreateOrUpdateExtraSectionMutation({
 *   variables: {
 *      extraSectionId: // value for 'extraSectionId'
 *      formId: // value for 'formId'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useCreateOrUpdateExtraSectionMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrUpdateExtraSectionMutation, CreateOrUpdateExtraSectionMutationVariables>) {
        return Apollo.useMutation<CreateOrUpdateExtraSectionMutation, CreateOrUpdateExtraSectionMutationVariables>(CreateOrUpdateExtraSectionDocument, baseOptions);
      }
export type CreateOrUpdateExtraSectionMutationHookResult = ReturnType<typeof useCreateOrUpdateExtraSectionMutation>;
export type CreateOrUpdateExtraSectionMutationResult = Apollo.MutationResult<CreateOrUpdateExtraSectionMutation>;
export type CreateOrUpdateExtraSectionMutationOptions = Apollo.BaseMutationOptions<CreateOrUpdateExtraSectionMutation, CreateOrUpdateExtraSectionMutationVariables>;
export const CreateOrUpdateLandingPageDocument = gql`
    mutation CreateOrUpdateLandingPage($params: LandingPageCreateOrUpdateParams!) {
  createOrUpdateLandingPage(params: $params) {
    id
  }
}
    `;
export type CreateOrUpdateLandingPageMutationFn = Apollo.MutationFunction<CreateOrUpdateLandingPageMutation, CreateOrUpdateLandingPageMutationVariables>;

/**
 * __useCreateOrUpdateLandingPageMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateLandingPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateLandingPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateLandingPageMutation, { data, loading, error }] = useCreateOrUpdateLandingPageMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useCreateOrUpdateLandingPageMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrUpdateLandingPageMutation, CreateOrUpdateLandingPageMutationVariables>) {
        return Apollo.useMutation<CreateOrUpdateLandingPageMutation, CreateOrUpdateLandingPageMutationVariables>(CreateOrUpdateLandingPageDocument, baseOptions);
      }
export type CreateOrUpdateLandingPageMutationHookResult = ReturnType<typeof useCreateOrUpdateLandingPageMutation>;
export type CreateOrUpdateLandingPageMutationResult = Apollo.MutationResult<CreateOrUpdateLandingPageMutation>;
export type CreateOrUpdateLandingPageMutationOptions = Apollo.BaseMutationOptions<CreateOrUpdateLandingPageMutation, CreateOrUpdateLandingPageMutationVariables>;
export const CreateSubscriptionDocument = gql`
    mutation CreateSubscription($billingType: BillingType!, $companyId: Int!, $planId: Int!) {
  createSubscription(billingType: $billingType, companyId: $companyId, planId: $planId) {
    id
  }
}
    `;
export type CreateSubscriptionMutationFn = Apollo.MutationFunction<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>;

/**
 * __useCreateSubscriptionMutation__
 *
 * To run a mutation, you first call `useCreateSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubscriptionMutation, { data, loading, error }] = useCreateSubscriptionMutation({
 *   variables: {
 *      billingType: // value for 'billingType'
 *      companyId: // value for 'companyId'
 *      planId: // value for 'planId'
 *   },
 * });
 */
export function useCreateSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>) {
        return Apollo.useMutation<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>(CreateSubscriptionDocument, baseOptions);
      }
export type CreateSubscriptionMutationHookResult = ReturnType<typeof useCreateSubscriptionMutation>;
export type CreateSubscriptionMutationResult = Apollo.MutationResult<CreateSubscriptionMutation>;
export type CreateSubscriptionMutationOptions = Apollo.BaseMutationOptions<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>;
export const DeleteCreditCardDocument = gql`
    mutation DeleteCreditCard($id: Int!) {
  deleteCreditCard(id: $id) {
    id
  }
}
    `;
export type DeleteCreditCardMutationFn = Apollo.MutationFunction<DeleteCreditCardMutation, DeleteCreditCardMutationVariables>;

/**
 * __useDeleteCreditCardMutation__
 *
 * To run a mutation, you first call `useDeleteCreditCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCreditCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCreditCardMutation, { data, loading, error }] = useDeleteCreditCardMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCreditCardMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCreditCardMutation, DeleteCreditCardMutationVariables>) {
        return Apollo.useMutation<DeleteCreditCardMutation, DeleteCreditCardMutationVariables>(DeleteCreditCardDocument, baseOptions);
      }
export type DeleteCreditCardMutationHookResult = ReturnType<typeof useDeleteCreditCardMutation>;
export type DeleteCreditCardMutationResult = Apollo.MutationResult<DeleteCreditCardMutation>;
export type DeleteCreditCardMutationOptions = Apollo.BaseMutationOptions<DeleteCreditCardMutation, DeleteCreditCardMutationVariables>;
export const DeleteMemberDocument = gql`
    mutation DeleteMember($id: Int!) {
  deleteMember(id: $id) {
    id
  }
}
    `;
export type DeleteMemberMutationFn = Apollo.MutationFunction<DeleteMemberMutation, DeleteMemberMutationVariables>;

/**
 * __useDeleteMemberMutation__
 *
 * To run a mutation, you first call `useDeleteMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMemberMutation, { data, loading, error }] = useDeleteMemberMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMemberMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMemberMutation, DeleteMemberMutationVariables>) {
        return Apollo.useMutation<DeleteMemberMutation, DeleteMemberMutationVariables>(DeleteMemberDocument, baseOptions);
      }
export type DeleteMemberMutationHookResult = ReturnType<typeof useDeleteMemberMutation>;
export type DeleteMemberMutationResult = Apollo.MutationResult<DeleteMemberMutation>;
export type DeleteMemberMutationOptions = Apollo.BaseMutationOptions<DeleteMemberMutation, DeleteMemberMutationVariables>;
export const GenerateApplicantProfilePdfDocument = gql`
    mutation GenerateApplicantProfilePdf($id: Int!) {
  generateApplicantProfilePdf(id: $id)
}
    `;
export type GenerateApplicantProfilePdfMutationFn = Apollo.MutationFunction<GenerateApplicantProfilePdfMutation, GenerateApplicantProfilePdfMutationVariables>;

/**
 * __useGenerateApplicantProfilePdfMutation__
 *
 * To run a mutation, you first call `useGenerateApplicantProfilePdfMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateApplicantProfilePdfMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateApplicantProfilePdfMutation, { data, loading, error }] = useGenerateApplicantProfilePdfMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGenerateApplicantProfilePdfMutation(baseOptions?: Apollo.MutationHookOptions<GenerateApplicantProfilePdfMutation, GenerateApplicantProfilePdfMutationVariables>) {
        return Apollo.useMutation<GenerateApplicantProfilePdfMutation, GenerateApplicantProfilePdfMutationVariables>(GenerateApplicantProfilePdfDocument, baseOptions);
      }
export type GenerateApplicantProfilePdfMutationHookResult = ReturnType<typeof useGenerateApplicantProfilePdfMutation>;
export type GenerateApplicantProfilePdfMutationResult = Apollo.MutationResult<GenerateApplicantProfilePdfMutation>;
export type GenerateApplicantProfilePdfMutationOptions = Apollo.BaseMutationOptions<GenerateApplicantProfilePdfMutation, GenerateApplicantProfilePdfMutationVariables>;
export const GenerateClientProfilePdfDocument = gql`
    mutation GenerateClientProfilePdf($id: Int!) {
  generateClientProfilePdf(id: $id)
}
    `;
export type GenerateClientProfilePdfMutationFn = Apollo.MutationFunction<GenerateClientProfilePdfMutation, GenerateClientProfilePdfMutationVariables>;

/**
 * __useGenerateClientProfilePdfMutation__
 *
 * To run a mutation, you first call `useGenerateClientProfilePdfMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateClientProfilePdfMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateClientProfilePdfMutation, { data, loading, error }] = useGenerateClientProfilePdfMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGenerateClientProfilePdfMutation(baseOptions?: Apollo.MutationHookOptions<GenerateClientProfilePdfMutation, GenerateClientProfilePdfMutationVariables>) {
        return Apollo.useMutation<GenerateClientProfilePdfMutation, GenerateClientProfilePdfMutationVariables>(GenerateClientProfilePdfDocument, baseOptions);
      }
export type GenerateClientProfilePdfMutationHookResult = ReturnType<typeof useGenerateClientProfilePdfMutation>;
export type GenerateClientProfilePdfMutationResult = Apollo.MutationResult<GenerateClientProfilePdfMutation>;
export type GenerateClientProfilePdfMutationOptions = Apollo.BaseMutationOptions<GenerateClientProfilePdfMutation, GenerateClientProfilePdfMutationVariables>;
export const GetOrCreateFormLinkDocument = gql`
    mutation GetOrCreateFormLink($formId: Int!, $formType: FormTypeEnum!) {
  getOrCreateFormLink(formId: $formId, formType: $formType) {
    shortcode
    url
    form {
      id
    }
  }
}
    `;
export type GetOrCreateFormLinkMutationFn = Apollo.MutationFunction<GetOrCreateFormLinkMutation, GetOrCreateFormLinkMutationVariables>;

/**
 * __useGetOrCreateFormLinkMutation__
 *
 * To run a mutation, you first call `useGetOrCreateFormLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetOrCreateFormLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getOrCreateFormLinkMutation, { data, loading, error }] = useGetOrCreateFormLinkMutation({
 *   variables: {
 *      formId: // value for 'formId'
 *      formType: // value for 'formType'
 *   },
 * });
 */
export function useGetOrCreateFormLinkMutation(baseOptions?: Apollo.MutationHookOptions<GetOrCreateFormLinkMutation, GetOrCreateFormLinkMutationVariables>) {
        return Apollo.useMutation<GetOrCreateFormLinkMutation, GetOrCreateFormLinkMutationVariables>(GetOrCreateFormLinkDocument, baseOptions);
      }
export type GetOrCreateFormLinkMutationHookResult = ReturnType<typeof useGetOrCreateFormLinkMutation>;
export type GetOrCreateFormLinkMutationResult = Apollo.MutationResult<GetOrCreateFormLinkMutation>;
export type GetOrCreateFormLinkMutationOptions = Apollo.BaseMutationOptions<GetOrCreateFormLinkMutation, GetOrCreateFormLinkMutationVariables>;
export const LoginUserDocument = gql`
    mutation LoginUser($email: String!, $password: String!) {
  loginUser(email: $email, password: $password)
}
    `;
export type LoginUserMutationFn = Apollo.MutationFunction<LoginUserMutation, LoginUserMutationVariables>;

/**
 * __useLoginUserMutation__
 *
 * To run a mutation, you first call `useLoginUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginUserMutation, { data, loading, error }] = useLoginUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginUserMutation(baseOptions?: Apollo.MutationHookOptions<LoginUserMutation, LoginUserMutationVariables>) {
        return Apollo.useMutation<LoginUserMutation, LoginUserMutationVariables>(LoginUserDocument, baseOptions);
      }
export type LoginUserMutationHookResult = ReturnType<typeof useLoginUserMutation>;
export type LoginUserMutationResult = Apollo.MutationResult<LoginUserMutation>;
export type LoginUserMutationOptions = Apollo.BaseMutationOptions<LoginUserMutation, LoginUserMutationVariables>;
export const RegisterMemberDocument = gql`
    mutation RegisterMember($token: String!, $email: String!, $password: String!, $subscribedToMailingList: Boolean) {
  registerMember(token: $token, email: $email, password: $password, subscribedToMailingList: $subscribedToMailingList)
}
    `;
export type RegisterMemberMutationFn = Apollo.MutationFunction<RegisterMemberMutation, RegisterMemberMutationVariables>;

/**
 * __useRegisterMemberMutation__
 *
 * To run a mutation, you first call `useRegisterMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMemberMutation, { data, loading, error }] = useRegisterMemberMutation({
 *   variables: {
 *      token: // value for 'token'
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      subscribedToMailingList: // value for 'subscribedToMailingList'
 *   },
 * });
 */
export function useRegisterMemberMutation(baseOptions?: Apollo.MutationHookOptions<RegisterMemberMutation, RegisterMemberMutationVariables>) {
        return Apollo.useMutation<RegisterMemberMutation, RegisterMemberMutationVariables>(RegisterMemberDocument, baseOptions);
      }
export type RegisterMemberMutationHookResult = ReturnType<typeof useRegisterMemberMutation>;
export type RegisterMemberMutationResult = Apollo.MutationResult<RegisterMemberMutation>;
export type RegisterMemberMutationOptions = Apollo.BaseMutationOptions<RegisterMemberMutation, RegisterMemberMutationVariables>;
export const RegisterWithCompanyMutationDocument = gql`
    mutation RegisterWithCompanyMutation($params: RegisterWithCompanyParams!) {
  registerWithCompany(params: $params)
}
    `;
export type RegisterWithCompanyMutationMutationFn = Apollo.MutationFunction<RegisterWithCompanyMutationMutation, RegisterWithCompanyMutationMutationVariables>;

/**
 * __useRegisterWithCompanyMutationMutation__
 *
 * To run a mutation, you first call `useRegisterWithCompanyMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterWithCompanyMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerWithCompanyMutationMutation, { data, loading, error }] = useRegisterWithCompanyMutationMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useRegisterWithCompanyMutationMutation(baseOptions?: Apollo.MutationHookOptions<RegisterWithCompanyMutationMutation, RegisterWithCompanyMutationMutationVariables>) {
        return Apollo.useMutation<RegisterWithCompanyMutationMutation, RegisterWithCompanyMutationMutationVariables>(RegisterWithCompanyMutationDocument, baseOptions);
      }
export type RegisterWithCompanyMutationMutationHookResult = ReturnType<typeof useRegisterWithCompanyMutationMutation>;
export type RegisterWithCompanyMutationMutationResult = Apollo.MutationResult<RegisterWithCompanyMutationMutation>;
export type RegisterWithCompanyMutationMutationOptions = Apollo.BaseMutationOptions<RegisterWithCompanyMutationMutation, RegisterWithCompanyMutationMutationVariables>;
export const RequestResetPasswordDocument = gql`
    mutation RequestResetPassword($email: String!) {
  requestResetPassword(email: $email)
}
    `;
export type RequestResetPasswordMutationFn = Apollo.MutationFunction<RequestResetPasswordMutation, RequestResetPasswordMutationVariables>;

/**
 * __useRequestResetPasswordMutation__
 *
 * To run a mutation, you first call `useRequestResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestResetPasswordMutation, { data, loading, error }] = useRequestResetPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useRequestResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<RequestResetPasswordMutation, RequestResetPasswordMutationVariables>) {
        return Apollo.useMutation<RequestResetPasswordMutation, RequestResetPasswordMutationVariables>(RequestResetPasswordDocument, baseOptions);
      }
export type RequestResetPasswordMutationHookResult = ReturnType<typeof useRequestResetPasswordMutation>;
export type RequestResetPasswordMutationResult = Apollo.MutationResult<RequestResetPasswordMutation>;
export type RequestResetPasswordMutationOptions = Apollo.BaseMutationOptions<RequestResetPasswordMutation, RequestResetPasswordMutationVariables>;
export const ResendMemberInvitationEmailDocument = gql`
    mutation ResendMemberInvitationEmail($id: Int!) {
  resendMemberInvitationEmail(id: $id) {
    id
  }
}
    `;
export type ResendMemberInvitationEmailMutationFn = Apollo.MutationFunction<ResendMemberInvitationEmailMutation, ResendMemberInvitationEmailMutationVariables>;

/**
 * __useResendMemberInvitationEmailMutation__
 *
 * To run a mutation, you first call `useResendMemberInvitationEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendMemberInvitationEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendMemberInvitationEmailMutation, { data, loading, error }] = useResendMemberInvitationEmailMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useResendMemberInvitationEmailMutation(baseOptions?: Apollo.MutationHookOptions<ResendMemberInvitationEmailMutation, ResendMemberInvitationEmailMutationVariables>) {
        return Apollo.useMutation<ResendMemberInvitationEmailMutation, ResendMemberInvitationEmailMutationVariables>(ResendMemberInvitationEmailDocument, baseOptions);
      }
export type ResendMemberInvitationEmailMutationHookResult = ReturnType<typeof useResendMemberInvitationEmailMutation>;
export type ResendMemberInvitationEmailMutationResult = Apollo.MutationResult<ResendMemberInvitationEmailMutation>;
export type ResendMemberInvitationEmailMutationOptions = Apollo.BaseMutationOptions<ResendMemberInvitationEmailMutation, ResendMemberInvitationEmailMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($token: String!, $password: String!) {
  resetPassword(token: $token, password: $password)
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      token: // value for 'token'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, baseOptions);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const SetDefaultCreditCardDocument = gql`
    mutation SetDefaultCreditCard($id: Int!) {
  setDefaultCreditCard(id: $id) {
    id
  }
}
    `;
export type SetDefaultCreditCardMutationFn = Apollo.MutationFunction<SetDefaultCreditCardMutation, SetDefaultCreditCardMutationVariables>;

/**
 * __useSetDefaultCreditCardMutation__
 *
 * To run a mutation, you first call `useSetDefaultCreditCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDefaultCreditCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDefaultCreditCardMutation, { data, loading, error }] = useSetDefaultCreditCardMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSetDefaultCreditCardMutation(baseOptions?: Apollo.MutationHookOptions<SetDefaultCreditCardMutation, SetDefaultCreditCardMutationVariables>) {
        return Apollo.useMutation<SetDefaultCreditCardMutation, SetDefaultCreditCardMutationVariables>(SetDefaultCreditCardDocument, baseOptions);
      }
export type SetDefaultCreditCardMutationHookResult = ReturnType<typeof useSetDefaultCreditCardMutation>;
export type SetDefaultCreditCardMutationResult = Apollo.MutationResult<SetDefaultCreditCardMutation>;
export type SetDefaultCreditCardMutationOptions = Apollo.BaseMutationOptions<SetDefaultCreditCardMutation, SetDefaultCreditCardMutationVariables>;
export const ShareFormLinkDocument = gql`
    mutation ShareFormLink($shortcode: String!, $emails: [String], $phones: [String]) {
  shareFormLink(shortcode: $shortcode, emails: $emails, phones: $phones)
}
    `;
export type ShareFormLinkMutationFn = Apollo.MutationFunction<ShareFormLinkMutation, ShareFormLinkMutationVariables>;

/**
 * __useShareFormLinkMutation__
 *
 * To run a mutation, you first call `useShareFormLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShareFormLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shareFormLinkMutation, { data, loading, error }] = useShareFormLinkMutation({
 *   variables: {
 *      shortcode: // value for 'shortcode'
 *      emails: // value for 'emails'
 *      phones: // value for 'phones'
 *   },
 * });
 */
export function useShareFormLinkMutation(baseOptions?: Apollo.MutationHookOptions<ShareFormLinkMutation, ShareFormLinkMutationVariables>) {
        return Apollo.useMutation<ShareFormLinkMutation, ShareFormLinkMutationVariables>(ShareFormLinkDocument, baseOptions);
      }
export type ShareFormLinkMutationHookResult = ReturnType<typeof useShareFormLinkMutation>;
export type ShareFormLinkMutationResult = Apollo.MutationResult<ShareFormLinkMutation>;
export type ShareFormLinkMutationOptions = Apollo.BaseMutationOptions<ShareFormLinkMutation, ShareFormLinkMutationVariables>;
export const SubmitApplicantResponseDocument = gql`
    mutation SubmitApplicantResponse($id: String!) {
  submitApplicantResponse(id: $id) {
    id
  }
}
    `;
export type SubmitApplicantResponseMutationFn = Apollo.MutationFunction<SubmitApplicantResponseMutation, SubmitApplicantResponseMutationVariables>;

/**
 * __useSubmitApplicantResponseMutation__
 *
 * To run a mutation, you first call `useSubmitApplicantResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitApplicantResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitApplicantResponseMutation, { data, loading, error }] = useSubmitApplicantResponseMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSubmitApplicantResponseMutation(baseOptions?: Apollo.MutationHookOptions<SubmitApplicantResponseMutation, SubmitApplicantResponseMutationVariables>) {
        return Apollo.useMutation<SubmitApplicantResponseMutation, SubmitApplicantResponseMutationVariables>(SubmitApplicantResponseDocument, baseOptions);
      }
export type SubmitApplicantResponseMutationHookResult = ReturnType<typeof useSubmitApplicantResponseMutation>;
export type SubmitApplicantResponseMutationResult = Apollo.MutationResult<SubmitApplicantResponseMutation>;
export type SubmitApplicantResponseMutationOptions = Apollo.BaseMutationOptions<SubmitApplicantResponseMutation, SubmitApplicantResponseMutationVariables>;
export const SubmitClientResponseDocument = gql`
    mutation SubmitClientResponse($id: String!) {
  submitClientResponse(id: $id) {
    id
  }
}
    `;
export type SubmitClientResponseMutationFn = Apollo.MutationFunction<SubmitClientResponseMutation, SubmitClientResponseMutationVariables>;

/**
 * __useSubmitClientResponseMutation__
 *
 * To run a mutation, you first call `useSubmitClientResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitClientResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitClientResponseMutation, { data, loading, error }] = useSubmitClientResponseMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSubmitClientResponseMutation(baseOptions?: Apollo.MutationHookOptions<SubmitClientResponseMutation, SubmitClientResponseMutationVariables>) {
        return Apollo.useMutation<SubmitClientResponseMutation, SubmitClientResponseMutationVariables>(SubmitClientResponseDocument, baseOptions);
      }
export type SubmitClientResponseMutationHookResult = ReturnType<typeof useSubmitClientResponseMutation>;
export type SubmitClientResponseMutationResult = Apollo.MutationResult<SubmitClientResponseMutation>;
export type SubmitClientResponseMutationOptions = Apollo.BaseMutationOptions<SubmitClientResponseMutation, SubmitClientResponseMutationVariables>;
export const UpdateApplicantDocument = gql`
    mutation UpdateApplicant($id: Int!, $params: UpdateApplicantParams!) {
  updateApplicant(id: $id, params: $params) {
    id
  }
}
    `;
export type UpdateApplicantMutationFn = Apollo.MutationFunction<UpdateApplicantMutation, UpdateApplicantMutationVariables>;

/**
 * __useUpdateApplicantMutation__
 *
 * To run a mutation, you first call `useUpdateApplicantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApplicantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApplicantMutation, { data, loading, error }] = useUpdateApplicantMutation({
 *   variables: {
 *      id: // value for 'id'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useUpdateApplicantMutation(baseOptions?: Apollo.MutationHookOptions<UpdateApplicantMutation, UpdateApplicantMutationVariables>) {
        return Apollo.useMutation<UpdateApplicantMutation, UpdateApplicantMutationVariables>(UpdateApplicantDocument, baseOptions);
      }
export type UpdateApplicantMutationHookResult = ReturnType<typeof useUpdateApplicantMutation>;
export type UpdateApplicantMutationResult = Apollo.MutationResult<UpdateApplicantMutation>;
export type UpdateApplicantMutationOptions = Apollo.BaseMutationOptions<UpdateApplicantMutation, UpdateApplicantMutationVariables>;
export const UpdateApplicantResponseDocument = gql`
    mutation UpdateApplicantResponse($id: String!, $params: ApplicantResponseUpdateParams!) {
  updateApplicantResponse(id: $id, params: $params) {
    id
  }
}
    `;
export type UpdateApplicantResponseMutationFn = Apollo.MutationFunction<UpdateApplicantResponseMutation, UpdateApplicantResponseMutationVariables>;

/**
 * __useUpdateApplicantResponseMutation__
 *
 * To run a mutation, you first call `useUpdateApplicantResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApplicantResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApplicantResponseMutation, { data, loading, error }] = useUpdateApplicantResponseMutation({
 *   variables: {
 *      id: // value for 'id'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useUpdateApplicantResponseMutation(baseOptions?: Apollo.MutationHookOptions<UpdateApplicantResponseMutation, UpdateApplicantResponseMutationVariables>) {
        return Apollo.useMutation<UpdateApplicantResponseMutation, UpdateApplicantResponseMutationVariables>(UpdateApplicantResponseDocument, baseOptions);
      }
export type UpdateApplicantResponseMutationHookResult = ReturnType<typeof useUpdateApplicantResponseMutation>;
export type UpdateApplicantResponseMutationResult = Apollo.MutationResult<UpdateApplicantResponseMutation>;
export type UpdateApplicantResponseMutationOptions = Apollo.BaseMutationOptions<UpdateApplicantResponseMutation, UpdateApplicantResponseMutationVariables>;
export const UpdateApplicantStatusDocument = gql`
    mutation UpdateApplicantStatus($id: Int!, $params: UpdateApplicantStatusParams!) {
  updateApplicantStatus(applicantId: $id, params: $params) {
    id
  }
}
    `;
export type UpdateApplicantStatusMutationFn = Apollo.MutationFunction<UpdateApplicantStatusMutation, UpdateApplicantStatusMutationVariables>;

/**
 * __useUpdateApplicantStatusMutation__
 *
 * To run a mutation, you first call `useUpdateApplicantStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApplicantStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApplicantStatusMutation, { data, loading, error }] = useUpdateApplicantStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useUpdateApplicantStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateApplicantStatusMutation, UpdateApplicantStatusMutationVariables>) {
        return Apollo.useMutation<UpdateApplicantStatusMutation, UpdateApplicantStatusMutationVariables>(UpdateApplicantStatusDocument, baseOptions);
      }
export type UpdateApplicantStatusMutationHookResult = ReturnType<typeof useUpdateApplicantStatusMutation>;
export type UpdateApplicantStatusMutationResult = Apollo.MutationResult<UpdateApplicantStatusMutation>;
export type UpdateApplicantStatusMutationOptions = Apollo.BaseMutationOptions<UpdateApplicantStatusMutation, UpdateApplicantStatusMutationVariables>;
export const UpdateClientDocument = gql`
    mutation UpdateClient($id: Int!, $params: UpdateClientParams!) {
  updateClient(id: $id, params: $params) {
    id
  }
}
    `;
export type UpdateClientMutationFn = Apollo.MutationFunction<UpdateClientMutation, UpdateClientMutationVariables>;

/**
 * __useUpdateClientMutation__
 *
 * To run a mutation, you first call `useUpdateClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientMutation, { data, loading, error }] = useUpdateClientMutation({
 *   variables: {
 *      id: // value for 'id'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useUpdateClientMutation(baseOptions?: Apollo.MutationHookOptions<UpdateClientMutation, UpdateClientMutationVariables>) {
        return Apollo.useMutation<UpdateClientMutation, UpdateClientMutationVariables>(UpdateClientDocument, baseOptions);
      }
export type UpdateClientMutationHookResult = ReturnType<typeof useUpdateClientMutation>;
export type UpdateClientMutationResult = Apollo.MutationResult<UpdateClientMutation>;
export type UpdateClientMutationOptions = Apollo.BaseMutationOptions<UpdateClientMutation, UpdateClientMutationVariables>;
export const UpdateClientResponseDocument = gql`
    mutation UpdateClientResponse($id: String!, $params: ClientResponseUpdateParams!) {
  updateClientResponse(id: $id, params: $params) {
    id
  }
}
    `;
export type UpdateClientResponseMutationFn = Apollo.MutationFunction<UpdateClientResponseMutation, UpdateClientResponseMutationVariables>;

/**
 * __useUpdateClientResponseMutation__
 *
 * To run a mutation, you first call `useUpdateClientResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientResponseMutation, { data, loading, error }] = useUpdateClientResponseMutation({
 *   variables: {
 *      id: // value for 'id'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useUpdateClientResponseMutation(baseOptions?: Apollo.MutationHookOptions<UpdateClientResponseMutation, UpdateClientResponseMutationVariables>) {
        return Apollo.useMutation<UpdateClientResponseMutation, UpdateClientResponseMutationVariables>(UpdateClientResponseDocument, baseOptions);
      }
export type UpdateClientResponseMutationHookResult = ReturnType<typeof useUpdateClientResponseMutation>;
export type UpdateClientResponseMutationResult = Apollo.MutationResult<UpdateClientResponseMutation>;
export type UpdateClientResponseMutationOptions = Apollo.BaseMutationOptions<UpdateClientResponseMutation, UpdateClientResponseMutationVariables>;
export const UpdateCompanyDocument = gql`
    mutation UpdateCompany($id: Int!, $params: CompanyUpdateParams!) {
  updateCompany(id: $id, params: $params) {
    id
  }
}
    `;
export type UpdateCompanyMutationFn = Apollo.MutationFunction<UpdateCompanyMutation, UpdateCompanyMutationVariables>;

/**
 * __useUpdateCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyMutation, { data, loading, error }] = useUpdateCompanyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useUpdateCompanyMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>) {
        return Apollo.useMutation<UpdateCompanyMutation, UpdateCompanyMutationVariables>(UpdateCompanyDocument, baseOptions);
      }
export type UpdateCompanyMutationHookResult = ReturnType<typeof useUpdateCompanyMutation>;
export type UpdateCompanyMutationResult = Apollo.MutationResult<UpdateCompanyMutation>;
export type UpdateCompanyMutationOptions = Apollo.BaseMutationOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>;
export const UpdateFixedSectionsConfigurationDocument = gql`
    mutation UpdateFixedSectionsConfiguration($id: Int!, $params: UpdateFixedSectionsConfigurationParams!) {
  updateFixedSectionsConfiguration(id: $id, params: $params) {
    id
  }
}
    `;
export type UpdateFixedSectionsConfigurationMutationFn = Apollo.MutationFunction<UpdateFixedSectionsConfigurationMutation, UpdateFixedSectionsConfigurationMutationVariables>;

/**
 * __useUpdateFixedSectionsConfigurationMutation__
 *
 * To run a mutation, you first call `useUpdateFixedSectionsConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFixedSectionsConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFixedSectionsConfigurationMutation, { data, loading, error }] = useUpdateFixedSectionsConfigurationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useUpdateFixedSectionsConfigurationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFixedSectionsConfigurationMutation, UpdateFixedSectionsConfigurationMutationVariables>) {
        return Apollo.useMutation<UpdateFixedSectionsConfigurationMutation, UpdateFixedSectionsConfigurationMutationVariables>(UpdateFixedSectionsConfigurationDocument, baseOptions);
      }
export type UpdateFixedSectionsConfigurationMutationHookResult = ReturnType<typeof useUpdateFixedSectionsConfigurationMutation>;
export type UpdateFixedSectionsConfigurationMutationResult = Apollo.MutationResult<UpdateFixedSectionsConfigurationMutation>;
export type UpdateFixedSectionsConfigurationMutationOptions = Apollo.BaseMutationOptions<UpdateFixedSectionsConfigurationMutation, UpdateFixedSectionsConfigurationMutationVariables>;
export const UpdateFormDocument = gql`
    mutation UpdateForm($id: Int!, $params: UpdateFormParams!) {
  updateForm(id: $id, params: $params) {
    id
  }
}
    `;
export type UpdateFormMutationFn = Apollo.MutationFunction<UpdateFormMutation, UpdateFormMutationVariables>;

/**
 * __useUpdateFormMutation__
 *
 * To run a mutation, you first call `useUpdateFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFormMutation, { data, loading, error }] = useUpdateFormMutation({
 *   variables: {
 *      id: // value for 'id'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useUpdateFormMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFormMutation, UpdateFormMutationVariables>) {
        return Apollo.useMutation<UpdateFormMutation, UpdateFormMutationVariables>(UpdateFormDocument, baseOptions);
      }
export type UpdateFormMutationHookResult = ReturnType<typeof useUpdateFormMutation>;
export type UpdateFormMutationResult = Apollo.MutationResult<UpdateFormMutation>;
export type UpdateFormMutationOptions = Apollo.BaseMutationOptions<UpdateFormMutation, UpdateFormMutationVariables>;
export const UpdateMemberDocument = gql`
    mutation UpdateMember($id: Int!, $params: MemberUpdateParams!) {
  updateMember(id: $id, params: $params) {
    id
  }
}
    `;
export type UpdateMemberMutationFn = Apollo.MutationFunction<UpdateMemberMutation, UpdateMemberMutationVariables>;

/**
 * __useUpdateMemberMutation__
 *
 * To run a mutation, you first call `useUpdateMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMemberMutation, { data, loading, error }] = useUpdateMemberMutation({
 *   variables: {
 *      id: // value for 'id'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useUpdateMemberMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMemberMutation, UpdateMemberMutationVariables>) {
        return Apollo.useMutation<UpdateMemberMutation, UpdateMemberMutationVariables>(UpdateMemberDocument, baseOptions);
      }
export type UpdateMemberMutationHookResult = ReturnType<typeof useUpdateMemberMutation>;
export type UpdateMemberMutationResult = Apollo.MutationResult<UpdateMemberMutation>;
export type UpdateMemberMutationOptions = Apollo.BaseMutationOptions<UpdateMemberMutation, UpdateMemberMutationVariables>;
export const UpdatePasswordDocument = gql`
    mutation UpdatePassword($newPassword: String!, $oldPassword: String!) {
  updatePassword(newPassword: $newPassword, oldPassword: $oldPassword) {
    id
  }
}
    `;
export type UpdatePasswordMutationFn = Apollo.MutationFunction<UpdatePasswordMutation, UpdatePasswordMutationVariables>;

/**
 * __useUpdatePasswordMutation__
 *
 * To run a mutation, you first call `useUpdatePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePasswordMutation, { data, loading, error }] = useUpdatePasswordMutation({
 *   variables: {
 *      newPassword: // value for 'newPassword'
 *      oldPassword: // value for 'oldPassword'
 *   },
 * });
 */
export function useUpdatePasswordMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePasswordMutation, UpdatePasswordMutationVariables>) {
        return Apollo.useMutation<UpdatePasswordMutation, UpdatePasswordMutationVariables>(UpdatePasswordDocument, baseOptions);
      }
export type UpdatePasswordMutationHookResult = ReturnType<typeof useUpdatePasswordMutation>;
export type UpdatePasswordMutationResult = Apollo.MutationResult<UpdatePasswordMutation>;
export type UpdatePasswordMutationOptions = Apollo.BaseMutationOptions<UpdatePasswordMutation, UpdatePasswordMutationVariables>;
export const GetCurrentUserCompanyDocument = gql`
    query GetCurrentUserCompany {
  currentUser {
    id
    member {
      id
      company {
        id
        availableRoles {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useGetCurrentUserCompanyQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserCompanyQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserCompanyQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentUserCompanyQuery, GetCurrentUserCompanyQueryVariables>) {
        return Apollo.useQuery<GetCurrentUserCompanyQuery, GetCurrentUserCompanyQueryVariables>(GetCurrentUserCompanyDocument, baseOptions);
      }
export function useGetCurrentUserCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentUserCompanyQuery, GetCurrentUserCompanyQueryVariables>) {
          return Apollo.useLazyQuery<GetCurrentUserCompanyQuery, GetCurrentUserCompanyQueryVariables>(GetCurrentUserCompanyDocument, baseOptions);
        }
export type GetCurrentUserCompanyQueryHookResult = ReturnType<typeof useGetCurrentUserCompanyQuery>;
export type GetCurrentUserCompanyLazyQueryHookResult = ReturnType<typeof useGetCurrentUserCompanyLazyQuery>;
export type GetCurrentUserCompanyQueryResult = Apollo.QueryResult<GetCurrentUserCompanyQuery, GetCurrentUserCompanyQueryVariables>;
export const GetCurrentUserMemberDocument = gql`
    query GetCurrentUserMember {
  currentUser {
    id
    role
    member {
      id
      occupation
    }
  }
}
    `;

/**
 * __useGetCurrentUserMemberQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserMemberQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserMemberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserMemberQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserMemberQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentUserMemberQuery, GetCurrentUserMemberQueryVariables>) {
        return Apollo.useQuery<GetCurrentUserMemberQuery, GetCurrentUserMemberQueryVariables>(GetCurrentUserMemberDocument, baseOptions);
      }
export function useGetCurrentUserMemberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentUserMemberQuery, GetCurrentUserMemberQueryVariables>) {
          return Apollo.useLazyQuery<GetCurrentUserMemberQuery, GetCurrentUserMemberQueryVariables>(GetCurrentUserMemberDocument, baseOptions);
        }
export type GetCurrentUserMemberQueryHookResult = ReturnType<typeof useGetCurrentUserMemberQuery>;
export type GetCurrentUserMemberLazyQueryHookResult = ReturnType<typeof useGetCurrentUserMemberLazyQuery>;
export type GetCurrentUserMemberQueryResult = Apollo.QueryResult<GetCurrentUserMemberQuery, GetCurrentUserMemberQueryVariables>;
export const GetFormByShortcodeDocument = gql`
    query GetFormByShortcode($shortcode: String!) {
  getFormByShortcode(shortcode: $shortcode) {
    id
    formType
    requirements
    referrerOptions
    applicantOpenQuestionsIntro
    clientOpenQuestionsIntro
    clientThankYouMessage
    applicantThankYouMessage
    clientIntroductoryMessage
    applicantIntroductoryMessage
    allowImagesUpload
    fixedSectionsConfiguration(shortcode: $shortcode) {
      id
      showAvailability
      showResume
      showSource
      showRequirements
    }
    extraSections(questionsDisplayType: [OPTIONAL, REQUIRED], shortcode: $shortcode, show: true) {
      id
      name
      show
      formType
      isMultiple
      customIntro
      minimumNumber
      extraQuestions {
        id
        displayType
        question
        options
        label
        type
      }
    }
    job {
      id
      role {
        id
        applicantOpenQuestions
        clientOpenQuestions
        name
      }
      publicCompanyInfo {
        id
        name
        logoUrl
      }
    }
  }
}
    `;

/**
 * __useGetFormByShortcodeQuery__
 *
 * To run a query within a React component, call `useGetFormByShortcodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFormByShortcodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFormByShortcodeQuery({
 *   variables: {
 *      shortcode: // value for 'shortcode'
 *   },
 * });
 */
export function useGetFormByShortcodeQuery(baseOptions?: Apollo.QueryHookOptions<GetFormByShortcodeQuery, GetFormByShortcodeQueryVariables>) {
        return Apollo.useQuery<GetFormByShortcodeQuery, GetFormByShortcodeQueryVariables>(GetFormByShortcodeDocument, baseOptions);
      }
export function useGetFormByShortcodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFormByShortcodeQuery, GetFormByShortcodeQueryVariables>) {
          return Apollo.useLazyQuery<GetFormByShortcodeQuery, GetFormByShortcodeQueryVariables>(GetFormByShortcodeDocument, baseOptions);
        }
export type GetFormByShortcodeQueryHookResult = ReturnType<typeof useGetFormByShortcodeQuery>;
export type GetFormByShortcodeLazyQueryHookResult = ReturnType<typeof useGetFormByShortcodeLazyQuery>;
export type GetFormByShortcodeQueryResult = Apollo.QueryResult<GetFormByShortcodeQuery, GetFormByShortcodeQueryVariables>;
export const GetFormLandingPageByShortcodeDocument = gql`
    query GetFormLandingPageByShortcode($shortcode: String!) {
  getFormByShortcode(shortcode: $shortcode) {
    id
    formType
    job {
      id
      landingPage {
        id
        headerImageUrl
        jobDescription
        layoutLabel
        buttonText
        subtitle
        logoUrl
        title
        color
      }
    }
  }
}
    `;

/**
 * __useGetFormLandingPageByShortcodeQuery__
 *
 * To run a query within a React component, call `useGetFormLandingPageByShortcodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFormLandingPageByShortcodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFormLandingPageByShortcodeQuery({
 *   variables: {
 *      shortcode: // value for 'shortcode'
 *   },
 * });
 */
export function useGetFormLandingPageByShortcodeQuery(baseOptions?: Apollo.QueryHookOptions<GetFormLandingPageByShortcodeQuery, GetFormLandingPageByShortcodeQueryVariables>) {
        return Apollo.useQuery<GetFormLandingPageByShortcodeQuery, GetFormLandingPageByShortcodeQueryVariables>(GetFormLandingPageByShortcodeDocument, baseOptions);
      }
export function useGetFormLandingPageByShortcodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFormLandingPageByShortcodeQuery, GetFormLandingPageByShortcodeQueryVariables>) {
          return Apollo.useLazyQuery<GetFormLandingPageByShortcodeQuery, GetFormLandingPageByShortcodeQueryVariables>(GetFormLandingPageByShortcodeDocument, baseOptions);
        }
export type GetFormLandingPageByShortcodeQueryHookResult = ReturnType<typeof useGetFormLandingPageByShortcodeQuery>;
export type GetFormLandingPageByShortcodeLazyQueryHookResult = ReturnType<typeof useGetFormLandingPageByShortcodeLazyQuery>;
export type GetFormLandingPageByShortcodeQueryResult = Apollo.QueryResult<GetFormLandingPageByShortcodeQuery, GetFormLandingPageByShortcodeQueryVariables>;
export const GetFormRedirectByShortcodeDocument = gql`
    query GetFormRedirectByShortcode($shortcode: String!) {
  getFormByShortcode(shortcode: $shortcode) {
    id
    formType
    job {
      id
      landingPage {
        id
      }
    }
  }
}
    `;

/**
 * __useGetFormRedirectByShortcodeQuery__
 *
 * To run a query within a React component, call `useGetFormRedirectByShortcodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFormRedirectByShortcodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFormRedirectByShortcodeQuery({
 *   variables: {
 *      shortcode: // value for 'shortcode'
 *   },
 * });
 */
export function useGetFormRedirectByShortcodeQuery(baseOptions?: Apollo.QueryHookOptions<GetFormRedirectByShortcodeQuery, GetFormRedirectByShortcodeQueryVariables>) {
        return Apollo.useQuery<GetFormRedirectByShortcodeQuery, GetFormRedirectByShortcodeQueryVariables>(GetFormRedirectByShortcodeDocument, baseOptions);
      }
export function useGetFormRedirectByShortcodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFormRedirectByShortcodeQuery, GetFormRedirectByShortcodeQueryVariables>) {
          return Apollo.useLazyQuery<GetFormRedirectByShortcodeQuery, GetFormRedirectByShortcodeQueryVariables>(GetFormRedirectByShortcodeDocument, baseOptions);
        }
export type GetFormRedirectByShortcodeQueryHookResult = ReturnType<typeof useGetFormRedirectByShortcodeQuery>;
export type GetFormRedirectByShortcodeLazyQueryHookResult = ReturnType<typeof useGetFormRedirectByShortcodeLazyQuery>;
export type GetFormRedirectByShortcodeQueryResult = Apollo.QueryResult<GetFormRedirectByShortcodeQuery, GetFormRedirectByShortcodeQueryVariables>;
export const GetJobRoleDocument = gql`
    query GetJobRole($id: Int!) {
  getJob(id: $id) {
    id
    role {
      id
      isProfessional
    }
  }
}
    `;

/**
 * __useGetJobRoleQuery__
 *
 * To run a query within a React component, call `useGetJobRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobRoleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetJobRoleQuery(baseOptions?: Apollo.QueryHookOptions<GetJobRoleQuery, GetJobRoleQueryVariables>) {
        return Apollo.useQuery<GetJobRoleQuery, GetJobRoleQueryVariables>(GetJobRoleDocument, baseOptions);
      }
export function useGetJobRoleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetJobRoleQuery, GetJobRoleQueryVariables>) {
          return Apollo.useLazyQuery<GetJobRoleQuery, GetJobRoleQueryVariables>(GetJobRoleDocument, baseOptions);
        }
export type GetJobRoleQueryHookResult = ReturnType<typeof useGetJobRoleQuery>;
export type GetJobRoleLazyQueryHookResult = ReturnType<typeof useGetJobRoleLazyQuery>;
export type GetJobRoleQueryResult = Apollo.QueryResult<GetJobRoleQuery, GetJobRoleQueryVariables>;
export const ListRoleNamesDocument = gql`
    query ListRoleNames {
  listRoles {
    id
    name
  }
}
    `;

/**
 * __useListRoleNamesQuery__
 *
 * To run a query within a React component, call `useListRoleNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListRoleNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListRoleNamesQuery({
 *   variables: {
 *   },
 * });
 */
export function useListRoleNamesQuery(baseOptions?: Apollo.QueryHookOptions<ListRoleNamesQuery, ListRoleNamesQueryVariables>) {
        return Apollo.useQuery<ListRoleNamesQuery, ListRoleNamesQueryVariables>(ListRoleNamesDocument, baseOptions);
      }
export function useListRoleNamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListRoleNamesQuery, ListRoleNamesQueryVariables>) {
          return Apollo.useLazyQuery<ListRoleNamesQuery, ListRoleNamesQueryVariables>(ListRoleNamesDocument, baseOptions);
        }
export type ListRoleNamesQueryHookResult = ReturnType<typeof useListRoleNamesQuery>;
export type ListRoleNamesLazyQueryHookResult = ReturnType<typeof useListRoleNamesLazyQuery>;
export type ListRoleNamesQueryResult = Apollo.QueryResult<ListRoleNamesQuery, ListRoleNamesQueryVariables>;
export const GetCurrentCompanyJobsDocument = gql`
    query GetCurrentCompanyJobs($hasApplicant: Boolean, $hasClient: Boolean) {
  currentUser {
    id
    member {
      id
      company {
        id
        name
        roles(hasApplicant: $hasApplicant, hasClient: $hasClient) {
          id
          name
          hasClient
        }
        jobs {
          id
          role {
            id
            name
          }
          form {
            id
            formType
          }
          landingPage {
            id
            layoutLabel
            jobDescription
            headerImageUrl
            buttonText
            subtitle
            logoUrl
            color
            title
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetCurrentCompanyJobsQuery__
 *
 * To run a query within a React component, call `useGetCurrentCompanyJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentCompanyJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentCompanyJobsQuery({
 *   variables: {
 *      hasApplicant: // value for 'hasApplicant'
 *      hasClient: // value for 'hasClient'
 *   },
 * });
 */
export function useGetCurrentCompanyJobsQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentCompanyJobsQuery, GetCurrentCompanyJobsQueryVariables>) {
        return Apollo.useQuery<GetCurrentCompanyJobsQuery, GetCurrentCompanyJobsQueryVariables>(GetCurrentCompanyJobsDocument, baseOptions);
      }
export function useGetCurrentCompanyJobsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentCompanyJobsQuery, GetCurrentCompanyJobsQueryVariables>) {
          return Apollo.useLazyQuery<GetCurrentCompanyJobsQuery, GetCurrentCompanyJobsQueryVariables>(GetCurrentCompanyJobsDocument, baseOptions);
        }
export type GetCurrentCompanyJobsQueryHookResult = ReturnType<typeof useGetCurrentCompanyJobsQuery>;
export type GetCurrentCompanyJobsLazyQueryHookResult = ReturnType<typeof useGetCurrentCompanyJobsLazyQuery>;
export type GetCurrentCompanyJobsQueryResult = Apollo.QueryResult<GetCurrentCompanyJobsQuery, GetCurrentCompanyJobsQueryVariables>;
export const GetImpersonatedCompanyJobsDocument = gql`
    query GetImpersonatedCompanyJobs($companyId: Int!, $hasApplicant: Boolean, $hasClient: Boolean) {
  getCompany(id: $companyId) {
    id
    name
    roles(hasApplicant: $hasApplicant, hasClient: $hasClient) {
      id
      name
      hasClient
    }
    jobs {
      id
      role {
        id
        name
      }
      form {
        id
        formType
      }
      landingPage {
        id
        layoutLabel
        jobDescription
        headerImageUrl
        buttonText
        subtitle
        logoUrl
        color
        title
      }
    }
  }
}
    `;

/**
 * __useGetImpersonatedCompanyJobsQuery__
 *
 * To run a query within a React component, call `useGetImpersonatedCompanyJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetImpersonatedCompanyJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetImpersonatedCompanyJobsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      hasApplicant: // value for 'hasApplicant'
 *      hasClient: // value for 'hasClient'
 *   },
 * });
 */
export function useGetImpersonatedCompanyJobsQuery(baseOptions?: Apollo.QueryHookOptions<GetImpersonatedCompanyJobsQuery, GetImpersonatedCompanyJobsQueryVariables>) {
        return Apollo.useQuery<GetImpersonatedCompanyJobsQuery, GetImpersonatedCompanyJobsQueryVariables>(GetImpersonatedCompanyJobsDocument, baseOptions);
      }
export function useGetImpersonatedCompanyJobsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetImpersonatedCompanyJobsQuery, GetImpersonatedCompanyJobsQueryVariables>) {
          return Apollo.useLazyQuery<GetImpersonatedCompanyJobsQuery, GetImpersonatedCompanyJobsQueryVariables>(GetImpersonatedCompanyJobsDocument, baseOptions);
        }
export type GetImpersonatedCompanyJobsQueryHookResult = ReturnType<typeof useGetImpersonatedCompanyJobsQuery>;
export type GetImpersonatedCompanyJobsLazyQueryHookResult = ReturnType<typeof useGetImpersonatedCompanyJobsLazyQuery>;
export type GetImpersonatedCompanyJobsQueryResult = Apollo.QueryResult<GetImpersonatedCompanyJobsQuery, GetImpersonatedCompanyJobsQueryVariables>;
export const GetApplicantResponseResumeUrlDocument = gql`
    query GetApplicantResponseResumeUrl($id: String!) {
  getApplicantResponseResumeUrl(id: $id)
}
    `;

/**
 * __useGetApplicantResponseResumeUrlQuery__
 *
 * To run a query within a React component, call `useGetApplicantResponseResumeUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApplicantResponseResumeUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApplicantResponseResumeUrlQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetApplicantResponseResumeUrlQuery(baseOptions?: Apollo.QueryHookOptions<GetApplicantResponseResumeUrlQuery, GetApplicantResponseResumeUrlQueryVariables>) {
        return Apollo.useQuery<GetApplicantResponseResumeUrlQuery, GetApplicantResponseResumeUrlQueryVariables>(GetApplicantResponseResumeUrlDocument, baseOptions);
      }
export function useGetApplicantResponseResumeUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetApplicantResponseResumeUrlQuery, GetApplicantResponseResumeUrlQueryVariables>) {
          return Apollo.useLazyQuery<GetApplicantResponseResumeUrlQuery, GetApplicantResponseResumeUrlQueryVariables>(GetApplicantResponseResumeUrlDocument, baseOptions);
        }
export type GetApplicantResponseResumeUrlQueryHookResult = ReturnType<typeof useGetApplicantResponseResumeUrlQuery>;
export type GetApplicantResponseResumeUrlLazyQueryHookResult = ReturnType<typeof useGetApplicantResponseResumeUrlLazyQuery>;
export type GetApplicantResponseResumeUrlQueryResult = Apollo.QueryResult<GetApplicantResponseResumeUrlQuery, GetApplicantResponseResumeUrlQueryVariables>;
export const GetApplicantResumeUrlDocument = gql`
    query GetApplicantResumeUrl($id: Int!) {
  getApplicantResumeUrl(id: $id)
}
    `;

/**
 * __useGetApplicantResumeUrlQuery__
 *
 * To run a query within a React component, call `useGetApplicantResumeUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApplicantResumeUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApplicantResumeUrlQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetApplicantResumeUrlQuery(baseOptions?: Apollo.QueryHookOptions<GetApplicantResumeUrlQuery, GetApplicantResumeUrlQueryVariables>) {
        return Apollo.useQuery<GetApplicantResumeUrlQuery, GetApplicantResumeUrlQueryVariables>(GetApplicantResumeUrlDocument, baseOptions);
      }
export function useGetApplicantResumeUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetApplicantResumeUrlQuery, GetApplicantResumeUrlQueryVariables>) {
          return Apollo.useLazyQuery<GetApplicantResumeUrlQuery, GetApplicantResumeUrlQueryVariables>(GetApplicantResumeUrlDocument, baseOptions);
        }
export type GetApplicantResumeUrlQueryHookResult = ReturnType<typeof useGetApplicantResumeUrlQuery>;
export type GetApplicantResumeUrlLazyQueryHookResult = ReturnType<typeof useGetApplicantResumeUrlLazyQuery>;
export type GetApplicantResumeUrlQueryResult = Apollo.QueryResult<GetApplicantResumeUrlQuery, GetApplicantResumeUrlQueryVariables>;
export const ExportApplicantsToCsvDocument = gql`
    query ExportApplicantsToCsv($filters: ApplicantFilters!, $search: String) {
  exportToCsv: exportApplicantsToCsv(filters: $filters, search: $search) {
    base64
    name
  }
}
    `;

/**
 * __useExportApplicantsToCsvQuery__
 *
 * To run a query within a React component, call `useExportApplicantsToCsvQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportApplicantsToCsvQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportApplicantsToCsvQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useExportApplicantsToCsvQuery(baseOptions?: Apollo.QueryHookOptions<ExportApplicantsToCsvQuery, ExportApplicantsToCsvQueryVariables>) {
        return Apollo.useQuery<ExportApplicantsToCsvQuery, ExportApplicantsToCsvQueryVariables>(ExportApplicantsToCsvDocument, baseOptions);
      }
export function useExportApplicantsToCsvLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportApplicantsToCsvQuery, ExportApplicantsToCsvQueryVariables>) {
          return Apollo.useLazyQuery<ExportApplicantsToCsvQuery, ExportApplicantsToCsvQueryVariables>(ExportApplicantsToCsvDocument, baseOptions);
        }
export type ExportApplicantsToCsvQueryHookResult = ReturnType<typeof useExportApplicantsToCsvQuery>;
export type ExportApplicantsToCsvLazyQueryHookResult = ReturnType<typeof useExportApplicantsToCsvLazyQuery>;
export type ExportApplicantsToCsvQueryResult = Apollo.QueryResult<ExportApplicantsToCsvQuery, ExportApplicantsToCsvQueryVariables>;
export const ExportClientsToCsvDocument = gql`
    query ExportClientsToCsv($filters: ClientFilters!, $search: String) {
  exportToCsv: exportClientsToCsv(filters: $filters, search: $search) {
    base64
    name
  }
}
    `;

/**
 * __useExportClientsToCsvQuery__
 *
 * To run a query within a React component, call `useExportClientsToCsvQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportClientsToCsvQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportClientsToCsvQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useExportClientsToCsvQuery(baseOptions?: Apollo.QueryHookOptions<ExportClientsToCsvQuery, ExportClientsToCsvQueryVariables>) {
        return Apollo.useQuery<ExportClientsToCsvQuery, ExportClientsToCsvQueryVariables>(ExportClientsToCsvDocument, baseOptions);
      }
export function useExportClientsToCsvLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportClientsToCsvQuery, ExportClientsToCsvQueryVariables>) {
          return Apollo.useLazyQuery<ExportClientsToCsvQuery, ExportClientsToCsvQueryVariables>(ExportClientsToCsvDocument, baseOptions);
        }
export type ExportClientsToCsvQueryHookResult = ReturnType<typeof useExportClientsToCsvQuery>;
export type ExportClientsToCsvLazyQueryHookResult = ReturnType<typeof useExportClientsToCsvLazyQuery>;
export type ExportClientsToCsvQueryResult = Apollo.QueryResult<ExportClientsToCsvQuery, ExportClientsToCsvQueryVariables>;
export const GetBaseCurrentCompanyDocument = gql`
    query GetBaseCurrentCompany {
  currentUser {
    id
    member {
      id
      company {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useGetBaseCurrentCompanyQuery__
 *
 * To run a query within a React component, call `useGetBaseCurrentCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBaseCurrentCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBaseCurrentCompanyQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBaseCurrentCompanyQuery(baseOptions?: Apollo.QueryHookOptions<GetBaseCurrentCompanyQuery, GetBaseCurrentCompanyQueryVariables>) {
        return Apollo.useQuery<GetBaseCurrentCompanyQuery, GetBaseCurrentCompanyQueryVariables>(GetBaseCurrentCompanyDocument, baseOptions);
      }
export function useGetBaseCurrentCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBaseCurrentCompanyQuery, GetBaseCurrentCompanyQueryVariables>) {
          return Apollo.useLazyQuery<GetBaseCurrentCompanyQuery, GetBaseCurrentCompanyQueryVariables>(GetBaseCurrentCompanyDocument, baseOptions);
        }
export type GetBaseCurrentCompanyQueryHookResult = ReturnType<typeof useGetBaseCurrentCompanyQuery>;
export type GetBaseCurrentCompanyLazyQueryHookResult = ReturnType<typeof useGetBaseCurrentCompanyLazyQuery>;
export type GetBaseCurrentCompanyQueryResult = Apollo.QueryResult<GetBaseCurrentCompanyQuery, GetBaseCurrentCompanyQueryVariables>;
export const GetBaseImpersonatedCompanyDocument = gql`
    query GetBaseImpersonatedCompany($companyId: Int!) {
  getCompany(id: $companyId) {
    id
    name
  }
}
    `;

/**
 * __useGetBaseImpersonatedCompanyQuery__
 *
 * To run a query within a React component, call `useGetBaseImpersonatedCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBaseImpersonatedCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBaseImpersonatedCompanyQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetBaseImpersonatedCompanyQuery(baseOptions?: Apollo.QueryHookOptions<GetBaseImpersonatedCompanyQuery, GetBaseImpersonatedCompanyQueryVariables>) {
        return Apollo.useQuery<GetBaseImpersonatedCompanyQuery, GetBaseImpersonatedCompanyQueryVariables>(GetBaseImpersonatedCompanyDocument, baseOptions);
      }
export function useGetBaseImpersonatedCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBaseImpersonatedCompanyQuery, GetBaseImpersonatedCompanyQueryVariables>) {
          return Apollo.useLazyQuery<GetBaseImpersonatedCompanyQuery, GetBaseImpersonatedCompanyQueryVariables>(GetBaseImpersonatedCompanyDocument, baseOptions);
        }
export type GetBaseImpersonatedCompanyQueryHookResult = ReturnType<typeof useGetBaseImpersonatedCompanyQuery>;
export type GetBaseImpersonatedCompanyLazyQueryHookResult = ReturnType<typeof useGetBaseImpersonatedCompanyLazyQuery>;
export type GetBaseImpersonatedCompanyQueryResult = Apollo.QueryResult<GetBaseImpersonatedCompanyQuery, GetBaseImpersonatedCompanyQueryVariables>;
export const SimulateSubscriptionOperationDocument = gql`
    query SimulateSubscriptionOperation($billingType: BillingType!, $companyId: Int!, $planId: Int!) {
  simulateSubscriptionOperation(billingType: $billingType, companyId: $companyId, planId: $planId) {
    currentSubscriptionEndAt
    planOriginalPrice
    cancelationBonus
    finalPrice
    discount
    type
    newPlan {
      id
      name
    }
    currentSubscription {
      id
      plan {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useSimulateSubscriptionOperationQuery__
 *
 * To run a query within a React component, call `useSimulateSubscriptionOperationQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimulateSubscriptionOperationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimulateSubscriptionOperationQuery({
 *   variables: {
 *      billingType: // value for 'billingType'
 *      companyId: // value for 'companyId'
 *      planId: // value for 'planId'
 *   },
 * });
 */
export function useSimulateSubscriptionOperationQuery(baseOptions?: Apollo.QueryHookOptions<SimulateSubscriptionOperationQuery, SimulateSubscriptionOperationQueryVariables>) {
        return Apollo.useQuery<SimulateSubscriptionOperationQuery, SimulateSubscriptionOperationQueryVariables>(SimulateSubscriptionOperationDocument, baseOptions);
      }
export function useSimulateSubscriptionOperationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SimulateSubscriptionOperationQuery, SimulateSubscriptionOperationQueryVariables>) {
          return Apollo.useLazyQuery<SimulateSubscriptionOperationQuery, SimulateSubscriptionOperationQueryVariables>(SimulateSubscriptionOperationDocument, baseOptions);
        }
export type SimulateSubscriptionOperationQueryHookResult = ReturnType<typeof useSimulateSubscriptionOperationQuery>;
export type SimulateSubscriptionOperationLazyQueryHookResult = ReturnType<typeof useSimulateSubscriptionOperationLazyQuery>;
export type SimulateSubscriptionOperationQueryResult = Apollo.QueryResult<SimulateSubscriptionOperationQuery, SimulateSubscriptionOperationQueryVariables>;
export const ShareApplicantProfileDocument = gql`
    mutation ShareApplicantProfile($emails: [String], $phones: [String], $id: Int!) {
  shareApplicantProfile(emails: $emails, phones: $phones, id: $id)
}
    `;
export type ShareApplicantProfileMutationFn = Apollo.MutationFunction<ShareApplicantProfileMutation, ShareApplicantProfileMutationVariables>;

/**
 * __useShareApplicantProfileMutation__
 *
 * To run a mutation, you first call `useShareApplicantProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShareApplicantProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shareApplicantProfileMutation, { data, loading, error }] = useShareApplicantProfileMutation({
 *   variables: {
 *      emails: // value for 'emails'
 *      phones: // value for 'phones'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useShareApplicantProfileMutation(baseOptions?: Apollo.MutationHookOptions<ShareApplicantProfileMutation, ShareApplicantProfileMutationVariables>) {
        return Apollo.useMutation<ShareApplicantProfileMutation, ShareApplicantProfileMutationVariables>(ShareApplicantProfileDocument, baseOptions);
      }
export type ShareApplicantProfileMutationHookResult = ReturnType<typeof useShareApplicantProfileMutation>;
export type ShareApplicantProfileMutationResult = Apollo.MutationResult<ShareApplicantProfileMutation>;
export type ShareApplicantProfileMutationOptions = Apollo.BaseMutationOptions<ShareApplicantProfileMutation, ShareApplicantProfileMutationVariables>;
export const ShareClientProfileDocument = gql`
    mutation ShareClientProfile($emails: [String], $phones: [String], $id: Int!) {
  shareClientProfile(emails: $emails, phones: $phones, id: $id)
}
    `;
export type ShareClientProfileMutationFn = Apollo.MutationFunction<ShareClientProfileMutation, ShareClientProfileMutationVariables>;

/**
 * __useShareClientProfileMutation__
 *
 * To run a mutation, you first call `useShareClientProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShareClientProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shareClientProfileMutation, { data, loading, error }] = useShareClientProfileMutation({
 *   variables: {
 *      emails: // value for 'emails'
 *      phones: // value for 'phones'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useShareClientProfileMutation(baseOptions?: Apollo.MutationHookOptions<ShareClientProfileMutation, ShareClientProfileMutationVariables>) {
        return Apollo.useMutation<ShareClientProfileMutation, ShareClientProfileMutationVariables>(ShareClientProfileDocument, baseOptions);
      }
export type ShareClientProfileMutationHookResult = ReturnType<typeof useShareClientProfileMutation>;
export type ShareClientProfileMutationResult = Apollo.MutationResult<ShareClientProfileMutation>;
export type ShareClientProfileMutationOptions = Apollo.BaseMutationOptions<ShareClientProfileMutation, ShareClientProfileMutationVariables>;
export const GetApplicantByExternalIdDocument = gql`
    query getApplicantByExternalId($externalId: Int!) {
  getApplicantByExternalId(id: $externalId) {
    id
    email
    firstName
    lastName
    qualified
    appliedAt
    resumeUrl
    interviewAt
    openAnswers
    availableAt
    job {
      id
      role {
        id
        name
        isProfessional
      }
    }
    assessment {
      id
      roleFitScore
      spiderChartScores {
        id
        name
        finalValue
        info {
          averageValue
          position
          description
        }
      }
    }
  }
}
    `;

/**
 * __useGetApplicantByExternalIdQuery__
 *
 * To run a query within a React component, call `useGetApplicantByExternalIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApplicantByExternalIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApplicantByExternalIdQuery({
 *   variables: {
 *      externalId: // value for 'externalId'
 *   },
 * });
 */
export function useGetApplicantByExternalIdQuery(baseOptions?: Apollo.QueryHookOptions<GetApplicantByExternalIdQuery, GetApplicantByExternalIdQueryVariables>) {
        return Apollo.useQuery<GetApplicantByExternalIdQuery, GetApplicantByExternalIdQueryVariables>(GetApplicantByExternalIdDocument, baseOptions);
      }
export function useGetApplicantByExternalIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetApplicantByExternalIdQuery, GetApplicantByExternalIdQueryVariables>) {
          return Apollo.useLazyQuery<GetApplicantByExternalIdQuery, GetApplicantByExternalIdQueryVariables>(GetApplicantByExternalIdDocument, baseOptions);
        }
export type GetApplicantByExternalIdQueryHookResult = ReturnType<typeof useGetApplicantByExternalIdQuery>;
export type GetApplicantByExternalIdLazyQueryHookResult = ReturnType<typeof useGetApplicantByExternalIdLazyQuery>;
export type GetApplicantByExternalIdQueryResult = Apollo.QueryResult<GetApplicantByExternalIdQuery, GetApplicantByExternalIdQueryVariables>;
export const GetApplicantDocument = gql`
    query GetApplicant($id: Int!) {
  getApplicant(id: $id) {
    id
    email
    firstName
    lastName
    source
    isFullTime
    qualified
    appliedAt
    picturesUrl
    resumeUrl
    interviewAt
    recruiterRating
    recruiterNotes
    openAnswers
    extraAnswers
    availableAt
    fulfilledRequirements
    unfulfilledRequirements
    isShortApplication
    dayAvailability
    phoneNumber
    location {
      id
      city
      state
      streetAddress
      complement
      zipCode
      latitude
      longitude
    }
    status {
      id
      status
    }
    availabilities {
      id
      day
      afternoon
      evening
      morning
      overnight
    }
    responsibleRecruiter {
      id
      name
    }
    assessment {
      id
      roleFitScore
      spiderChartScores {
        id
        name
        finalValue
        info {
          averageValue
          position
          description
        }
      }
    }
    job {
      id
      role {
        id
        name
        isProfessional
        showAdditionalQualities
      }
      company {
        id
        activeMembers {
          id
          name
        }
      }
    }
    statusUpdates {
      id
      status
      happenedAt
      interviewAt
      responsibleRecruiter {
        id
        name
      }
      member {
        id
        name
        photo
      }
    }
  }
}
    `;

/**
 * __useGetApplicantQuery__
 *
 * To run a query within a React component, call `useGetApplicantQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApplicantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApplicantQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetApplicantQuery(baseOptions?: Apollo.QueryHookOptions<GetApplicantQuery, GetApplicantQueryVariables>) {
        return Apollo.useQuery<GetApplicantQuery, GetApplicantQueryVariables>(GetApplicantDocument, baseOptions);
      }
export function useGetApplicantLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetApplicantQuery, GetApplicantQueryVariables>) {
          return Apollo.useLazyQuery<GetApplicantQuery, GetApplicantQueryVariables>(GetApplicantDocument, baseOptions);
        }
export type GetApplicantQueryHookResult = ReturnType<typeof useGetApplicantQuery>;
export type GetApplicantLazyQueryHookResult = ReturnType<typeof useGetApplicantLazyQuery>;
export type GetApplicantQueryResult = Apollo.QueryResult<GetApplicantQuery, GetApplicantQueryVariables>;
export const GetSpiderChartInactiveScoresDocument = gql`
    query GetSpiderChartInactiveScores($id: Int!) {
  getApplicant(id: $id) {
    id
    assessment {
      id
      spiderChartScores(active: false) {
        id
        name
        finalValue
        info {
          averageValue
          position
          description
        }
      }
    }
  }
}
    `;

/**
 * __useGetSpiderChartInactiveScoresQuery__
 *
 * To run a query within a React component, call `useGetSpiderChartInactiveScoresQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSpiderChartInactiveScoresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSpiderChartInactiveScoresQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSpiderChartInactiveScoresQuery(baseOptions?: Apollo.QueryHookOptions<GetSpiderChartInactiveScoresQuery, GetSpiderChartInactiveScoresQueryVariables>) {
        return Apollo.useQuery<GetSpiderChartInactiveScoresQuery, GetSpiderChartInactiveScoresQueryVariables>(GetSpiderChartInactiveScoresDocument, baseOptions);
      }
export function useGetSpiderChartInactiveScoresLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSpiderChartInactiveScoresQuery, GetSpiderChartInactiveScoresQueryVariables>) {
          return Apollo.useLazyQuery<GetSpiderChartInactiveScoresQuery, GetSpiderChartInactiveScoresQueryVariables>(GetSpiderChartInactiveScoresDocument, baseOptions);
        }
export type GetSpiderChartInactiveScoresQueryHookResult = ReturnType<typeof useGetSpiderChartInactiveScoresQuery>;
export type GetSpiderChartInactiveScoresLazyQueryHookResult = ReturnType<typeof useGetSpiderChartInactiveScoresLazyQuery>;
export type GetSpiderChartInactiveScoresQueryResult = Apollo.QueryResult<GetSpiderChartInactiveScoresQuery, GetSpiderChartInactiveScoresQueryVariables>;
export const GetApplicantsDocument = gql`
    query GetApplicants($filters: ApplicantFilters!, $offset: Int, $search: String, $orderBy: String) {
  countApplicants(filters: $filters, search: $search)
  listApplicants(filters: $filters, search: $search, pagination: {offset: $offset, limit: 10, orderBy: $orderBy, sortingOrder: DESC}) {
    id
    status {
      id
      status
      happenedAt
    }
    source
    lastName
    firstName
    appliedAt
    qualified
    isFullTime
    isShortApplication
    picturesUrl
    job {
      id
      role {
        id
        isProfessional
      }
    }
    assessment {
      id
      roleFitScore
    }
  }
}
    `;

/**
 * __useGetApplicantsQuery__
 *
 * To run a query within a React component, call `useGetApplicantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApplicantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApplicantsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      offset: // value for 'offset'
 *      search: // value for 'search'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetApplicantsQuery(baseOptions?: Apollo.QueryHookOptions<GetApplicantsQuery, GetApplicantsQueryVariables>) {
        return Apollo.useQuery<GetApplicantsQuery, GetApplicantsQueryVariables>(GetApplicantsDocument, baseOptions);
      }
export function useGetApplicantsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetApplicantsQuery, GetApplicantsQueryVariables>) {
          return Apollo.useLazyQuery<GetApplicantsQuery, GetApplicantsQueryVariables>(GetApplicantsDocument, baseOptions);
        }
export type GetApplicantsQueryHookResult = ReturnType<typeof useGetApplicantsQuery>;
export type GetApplicantsLazyQueryHookResult = ReturnType<typeof useGetApplicantsLazyQuery>;
export type GetApplicantsQueryResult = Apollo.QueryResult<GetApplicantsQuery, GetApplicantsQueryVariables>;
export const GetClientsDocument = gql`
    query GetClients($filters: ClientFilters!, $offset: Int, $search: String, $orderBy: String) {
  countClients(filters: $filters, search: $search)
  listClients(filters: $filters, search: $search, pagination: {offset: $offset, limit: 10, orderBy: $orderBy, sortingOrder: DESC}) {
    id
    status
    lastName
    firstName
    submittedAt
    picturesUrl
    respondedBy
    assessment {
      id
      roleFitScore
    }
  }
}
    `;

/**
 * __useGetClientsQuery__
 *
 * To run a query within a React component, call `useGetClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      offset: // value for 'offset'
 *      search: // value for 'search'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetClientsQuery(baseOptions?: Apollo.QueryHookOptions<GetClientsQuery, GetClientsQueryVariables>) {
        return Apollo.useQuery<GetClientsQuery, GetClientsQueryVariables>(GetClientsDocument, baseOptions);
      }
export function useGetClientsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClientsQuery, GetClientsQueryVariables>) {
          return Apollo.useLazyQuery<GetClientsQuery, GetClientsQueryVariables>(GetClientsDocument, baseOptions);
        }
export type GetClientsQueryHookResult = ReturnType<typeof useGetClientsQuery>;
export type GetClientsLazyQueryHookResult = ReturnType<typeof useGetClientsLazyQuery>;
export type GetClientsQueryResult = Apollo.QueryResult<GetClientsQuery, GetClientsQueryVariables>;
export const GetClientDocument = gql`
    query GetClient($id: Int!) {
  getClient(id: $id) {
    id
    email
    status
    firstName
    lastName
    respondedBy
    submittedAt
    picturesUrl
    openAnswers
    extraAnswers
    submittedAt
    phoneNumber
    location {
      id
      city
      state
      streetAddress
      complement
      zipCode
      latitude
      longitude
    }
    job {
      id
      role {
        id
        name
      }
    }
    assessment {
      id
      roleFitScore
      spiderChartScores {
        id
        name
        finalValue
        info {
          averageValue
          position
          description
        }
      }
    }
  }
}
    `;

/**
 * __useGetClientQuery__
 *
 * To run a query within a React component, call `useGetClientQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetClientQuery(baseOptions?: Apollo.QueryHookOptions<GetClientQuery, GetClientQueryVariables>) {
        return Apollo.useQuery<GetClientQuery, GetClientQueryVariables>(GetClientDocument, baseOptions);
      }
export function useGetClientLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClientQuery, GetClientQueryVariables>) {
          return Apollo.useLazyQuery<GetClientQuery, GetClientQueryVariables>(GetClientDocument, baseOptions);
        }
export type GetClientQueryHookResult = ReturnType<typeof useGetClientQuery>;
export type GetClientLazyQueryHookResult = ReturnType<typeof useGetClientLazyQuery>;
export type GetClientQueryResult = Apollo.QueryResult<GetClientQuery, GetClientQueryVariables>;
export const GetCurrentCompanyDataDocument = gql`
    query GetCurrentCompanyData {
  currentUser {
    id
    member {
      id
      company {
        id
        name
        logoUrl
        location {
          id
          streetAddress
          zipCode
          city
          state
          complement
        }
      }
    }
  }
}
    `;

/**
 * __useGetCurrentCompanyDataQuery__
 *
 * To run a query within a React component, call `useGetCurrentCompanyDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentCompanyDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentCompanyDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentCompanyDataQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentCompanyDataQuery, GetCurrentCompanyDataQueryVariables>) {
        return Apollo.useQuery<GetCurrentCompanyDataQuery, GetCurrentCompanyDataQueryVariables>(GetCurrentCompanyDataDocument, baseOptions);
      }
export function useGetCurrentCompanyDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentCompanyDataQuery, GetCurrentCompanyDataQueryVariables>) {
          return Apollo.useLazyQuery<GetCurrentCompanyDataQuery, GetCurrentCompanyDataQueryVariables>(GetCurrentCompanyDataDocument, baseOptions);
        }
export type GetCurrentCompanyDataQueryHookResult = ReturnType<typeof useGetCurrentCompanyDataQuery>;
export type GetCurrentCompanyDataLazyQueryHookResult = ReturnType<typeof useGetCurrentCompanyDataLazyQuery>;
export type GetCurrentCompanyDataQueryResult = Apollo.QueryResult<GetCurrentCompanyDataQuery, GetCurrentCompanyDataQueryVariables>;
export const GetImpersonatedCompanyDataDocument = gql`
    query GetImpersonatedCompanyData($companyId: Int!) {
  getCompany(id: $companyId) {
    id
    name
    logoUrl
    location {
      id
      streetAddress
      zipCode
      city
      state
      complement
    }
  }
}
    `;

/**
 * __useGetImpersonatedCompanyDataQuery__
 *
 * To run a query within a React component, call `useGetImpersonatedCompanyDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetImpersonatedCompanyDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetImpersonatedCompanyDataQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetImpersonatedCompanyDataQuery(baseOptions?: Apollo.QueryHookOptions<GetImpersonatedCompanyDataQuery, GetImpersonatedCompanyDataQueryVariables>) {
        return Apollo.useQuery<GetImpersonatedCompanyDataQuery, GetImpersonatedCompanyDataQueryVariables>(GetImpersonatedCompanyDataDocument, baseOptions);
      }
export function useGetImpersonatedCompanyDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetImpersonatedCompanyDataQuery, GetImpersonatedCompanyDataQueryVariables>) {
          return Apollo.useLazyQuery<GetImpersonatedCompanyDataQuery, GetImpersonatedCompanyDataQueryVariables>(GetImpersonatedCompanyDataDocument, baseOptions);
        }
export type GetImpersonatedCompanyDataQueryHookResult = ReturnType<typeof useGetImpersonatedCompanyDataQuery>;
export type GetImpersonatedCompanyDataLazyQueryHookResult = ReturnType<typeof useGetImpersonatedCompanyDataLazyQuery>;
export type GetImpersonatedCompanyDataQueryResult = Apollo.QueryResult<GetImpersonatedCompanyDataQuery, GetImpersonatedCompanyDataQueryVariables>;
export const ListRolesDocument = gql`
    query ListRoles {
  listRoles {
    id
    name
    imageUrl
  }
}
    `;

/**
 * __useListRolesQuery__
 *
 * To run a query within a React component, call `useListRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useListRolesQuery(baseOptions?: Apollo.QueryHookOptions<ListRolesQuery, ListRolesQueryVariables>) {
        return Apollo.useQuery<ListRolesQuery, ListRolesQueryVariables>(ListRolesDocument, baseOptions);
      }
export function useListRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListRolesQuery, ListRolesQueryVariables>) {
          return Apollo.useLazyQuery<ListRolesQuery, ListRolesQueryVariables>(ListRolesDocument, baseOptions);
        }
export type ListRolesQueryHookResult = ReturnType<typeof useListRolesQuery>;
export type ListRolesLazyQueryHookResult = ReturnType<typeof useListRolesLazyQuery>;
export type ListRolesQueryResult = Apollo.QueryResult<ListRolesQuery, ListRolesQueryVariables>;
export const GetCurrentCompanyNumberOfUnprocessedApplicantsDocument = gql`
    query GetCurrentCompanyNumberOfUnprocessedApplicants {
  currentUser {
    id
    member {
      id
      company {
        id
        name
        numberOfUnprocessedApplicants
      }
    }
  }
}
    `;

/**
 * __useGetCurrentCompanyNumberOfUnprocessedApplicantsQuery__
 *
 * To run a query within a React component, call `useGetCurrentCompanyNumberOfUnprocessedApplicantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentCompanyNumberOfUnprocessedApplicantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentCompanyNumberOfUnprocessedApplicantsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentCompanyNumberOfUnprocessedApplicantsQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentCompanyNumberOfUnprocessedApplicantsQuery, GetCurrentCompanyNumberOfUnprocessedApplicantsQueryVariables>) {
        return Apollo.useQuery<GetCurrentCompanyNumberOfUnprocessedApplicantsQuery, GetCurrentCompanyNumberOfUnprocessedApplicantsQueryVariables>(GetCurrentCompanyNumberOfUnprocessedApplicantsDocument, baseOptions);
      }
export function useGetCurrentCompanyNumberOfUnprocessedApplicantsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentCompanyNumberOfUnprocessedApplicantsQuery, GetCurrentCompanyNumberOfUnprocessedApplicantsQueryVariables>) {
          return Apollo.useLazyQuery<GetCurrentCompanyNumberOfUnprocessedApplicantsQuery, GetCurrentCompanyNumberOfUnprocessedApplicantsQueryVariables>(GetCurrentCompanyNumberOfUnprocessedApplicantsDocument, baseOptions);
        }
export type GetCurrentCompanyNumberOfUnprocessedApplicantsQueryHookResult = ReturnType<typeof useGetCurrentCompanyNumberOfUnprocessedApplicantsQuery>;
export type GetCurrentCompanyNumberOfUnprocessedApplicantsLazyQueryHookResult = ReturnType<typeof useGetCurrentCompanyNumberOfUnprocessedApplicantsLazyQuery>;
export type GetCurrentCompanyNumberOfUnprocessedApplicantsQueryResult = Apollo.QueryResult<GetCurrentCompanyNumberOfUnprocessedApplicantsQuery, GetCurrentCompanyNumberOfUnprocessedApplicantsQueryVariables>;
export const GetImpersonatedCompanyNumberOfUnprocessedApplicantsDocument = gql`
    query GetImpersonatedCompanyNumberOfUnprocessedApplicants($companyId: Int!) {
  getCompany(id: $companyId) {
    id
    name
    numberOfUnprocessedApplicants
  }
}
    `;

/**
 * __useGetImpersonatedCompanyNumberOfUnprocessedApplicantsQuery__
 *
 * To run a query within a React component, call `useGetImpersonatedCompanyNumberOfUnprocessedApplicantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetImpersonatedCompanyNumberOfUnprocessedApplicantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetImpersonatedCompanyNumberOfUnprocessedApplicantsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetImpersonatedCompanyNumberOfUnprocessedApplicantsQuery(baseOptions?: Apollo.QueryHookOptions<GetImpersonatedCompanyNumberOfUnprocessedApplicantsQuery, GetImpersonatedCompanyNumberOfUnprocessedApplicantsQueryVariables>) {
        return Apollo.useQuery<GetImpersonatedCompanyNumberOfUnprocessedApplicantsQuery, GetImpersonatedCompanyNumberOfUnprocessedApplicantsQueryVariables>(GetImpersonatedCompanyNumberOfUnprocessedApplicantsDocument, baseOptions);
      }
export function useGetImpersonatedCompanyNumberOfUnprocessedApplicantsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetImpersonatedCompanyNumberOfUnprocessedApplicantsQuery, GetImpersonatedCompanyNumberOfUnprocessedApplicantsQueryVariables>) {
          return Apollo.useLazyQuery<GetImpersonatedCompanyNumberOfUnprocessedApplicantsQuery, GetImpersonatedCompanyNumberOfUnprocessedApplicantsQueryVariables>(GetImpersonatedCompanyNumberOfUnprocessedApplicantsDocument, baseOptions);
        }
export type GetImpersonatedCompanyNumberOfUnprocessedApplicantsQueryHookResult = ReturnType<typeof useGetImpersonatedCompanyNumberOfUnprocessedApplicantsQuery>;
export type GetImpersonatedCompanyNumberOfUnprocessedApplicantsLazyQueryHookResult = ReturnType<typeof useGetImpersonatedCompanyNumberOfUnprocessedApplicantsLazyQuery>;
export type GetImpersonatedCompanyNumberOfUnprocessedApplicantsQueryResult = Apollo.QueryResult<GetImpersonatedCompanyNumberOfUnprocessedApplicantsQuery, GetImpersonatedCompanyNumberOfUnprocessedApplicantsQueryVariables>;
export const GetDashboardApplicationsOverviewDocument = gql`
    query GetDashboardApplicationsOverview($period: Period!, $jobId: Int!) {
  getApplicationsOverview(period: $period, jobId: $jobId) {
    applicants
    interviews
    hired
  }
}
    `;

/**
 * __useGetDashboardApplicationsOverviewQuery__
 *
 * To run a query within a React component, call `useGetDashboardApplicationsOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDashboardApplicationsOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDashboardApplicationsOverviewQuery({
 *   variables: {
 *      period: // value for 'period'
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useGetDashboardApplicationsOverviewQuery(baseOptions?: Apollo.QueryHookOptions<GetDashboardApplicationsOverviewQuery, GetDashboardApplicationsOverviewQueryVariables>) {
        return Apollo.useQuery<GetDashboardApplicationsOverviewQuery, GetDashboardApplicationsOverviewQueryVariables>(GetDashboardApplicationsOverviewDocument, baseOptions);
      }
export function useGetDashboardApplicationsOverviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDashboardApplicationsOverviewQuery, GetDashboardApplicationsOverviewQueryVariables>) {
          return Apollo.useLazyQuery<GetDashboardApplicationsOverviewQuery, GetDashboardApplicationsOverviewQueryVariables>(GetDashboardApplicationsOverviewDocument, baseOptions);
        }
export type GetDashboardApplicationsOverviewQueryHookResult = ReturnType<typeof useGetDashboardApplicationsOverviewQuery>;
export type GetDashboardApplicationsOverviewLazyQueryHookResult = ReturnType<typeof useGetDashboardApplicationsOverviewLazyQuery>;
export type GetDashboardApplicationsOverviewQueryResult = Apollo.QueryResult<GetDashboardApplicationsOverviewQuery, GetDashboardApplicationsOverviewQueryVariables>;
export const GetCurrentCompanyDashboardRemainingTokensDocument = gql`
    query GetCurrentCompanyDashboardRemainingTokens {
  currentUser {
    id
    member {
      id
      company {
        id
        name
        jobs {
          id
          subscription {
            id
            plan {
              id
              name
            }
          }
          numberOfAvailableTokens
          tokensByExpirationDate {
            expirationDate
            numberOfAvailableTokens
          }
          role {
            id
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetCurrentCompanyDashboardRemainingTokensQuery__
 *
 * To run a query within a React component, call `useGetCurrentCompanyDashboardRemainingTokensQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentCompanyDashboardRemainingTokensQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentCompanyDashboardRemainingTokensQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentCompanyDashboardRemainingTokensQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentCompanyDashboardRemainingTokensQuery, GetCurrentCompanyDashboardRemainingTokensQueryVariables>) {
        return Apollo.useQuery<GetCurrentCompanyDashboardRemainingTokensQuery, GetCurrentCompanyDashboardRemainingTokensQueryVariables>(GetCurrentCompanyDashboardRemainingTokensDocument, baseOptions);
      }
export function useGetCurrentCompanyDashboardRemainingTokensLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentCompanyDashboardRemainingTokensQuery, GetCurrentCompanyDashboardRemainingTokensQueryVariables>) {
          return Apollo.useLazyQuery<GetCurrentCompanyDashboardRemainingTokensQuery, GetCurrentCompanyDashboardRemainingTokensQueryVariables>(GetCurrentCompanyDashboardRemainingTokensDocument, baseOptions);
        }
export type GetCurrentCompanyDashboardRemainingTokensQueryHookResult = ReturnType<typeof useGetCurrentCompanyDashboardRemainingTokensQuery>;
export type GetCurrentCompanyDashboardRemainingTokensLazyQueryHookResult = ReturnType<typeof useGetCurrentCompanyDashboardRemainingTokensLazyQuery>;
export type GetCurrentCompanyDashboardRemainingTokensQueryResult = Apollo.QueryResult<GetCurrentCompanyDashboardRemainingTokensQuery, GetCurrentCompanyDashboardRemainingTokensQueryVariables>;
export const GetImpersonatedCompanyDashboardRemainingTokensDocument = gql`
    query GetImpersonatedCompanyDashboardRemainingTokens($companyId: Int!) {
  getCompany(id: $companyId) {
    id
    name
    jobs {
      id
      subscription {
        id
        plan {
          id
          name
        }
      }
      numberOfAvailableTokens
      tokensByExpirationDate {
        expirationDate
        numberOfAvailableTokens
      }
      role {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useGetImpersonatedCompanyDashboardRemainingTokensQuery__
 *
 * To run a query within a React component, call `useGetImpersonatedCompanyDashboardRemainingTokensQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetImpersonatedCompanyDashboardRemainingTokensQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetImpersonatedCompanyDashboardRemainingTokensQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetImpersonatedCompanyDashboardRemainingTokensQuery(baseOptions?: Apollo.QueryHookOptions<GetImpersonatedCompanyDashboardRemainingTokensQuery, GetImpersonatedCompanyDashboardRemainingTokensQueryVariables>) {
        return Apollo.useQuery<GetImpersonatedCompanyDashboardRemainingTokensQuery, GetImpersonatedCompanyDashboardRemainingTokensQueryVariables>(GetImpersonatedCompanyDashboardRemainingTokensDocument, baseOptions);
      }
export function useGetImpersonatedCompanyDashboardRemainingTokensLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetImpersonatedCompanyDashboardRemainingTokensQuery, GetImpersonatedCompanyDashboardRemainingTokensQueryVariables>) {
          return Apollo.useLazyQuery<GetImpersonatedCompanyDashboardRemainingTokensQuery, GetImpersonatedCompanyDashboardRemainingTokensQueryVariables>(GetImpersonatedCompanyDashboardRemainingTokensDocument, baseOptions);
        }
export type GetImpersonatedCompanyDashboardRemainingTokensQueryHookResult = ReturnType<typeof useGetImpersonatedCompanyDashboardRemainingTokensQuery>;
export type GetImpersonatedCompanyDashboardRemainingTokensLazyQueryHookResult = ReturnType<typeof useGetImpersonatedCompanyDashboardRemainingTokensLazyQuery>;
export type GetImpersonatedCompanyDashboardRemainingTokensQueryResult = Apollo.QueryResult<GetImpersonatedCompanyDashboardRemainingTokensQuery, GetImpersonatedCompanyDashboardRemainingTokensQueryVariables>;
export const GetDashboardCountApplicantsDocument = gql`
    query GetDashboardCountApplicants($statuses: [StatusUpdateEnum!], $jobId: Int!) {
  countApplicants(filters: {status: $statuses, jobId: $jobId})
}
    `;

/**
 * __useGetDashboardCountApplicantsQuery__
 *
 * To run a query within a React component, call `useGetDashboardCountApplicantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDashboardCountApplicantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDashboardCountApplicantsQuery({
 *   variables: {
 *      statuses: // value for 'statuses'
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useGetDashboardCountApplicantsQuery(baseOptions?: Apollo.QueryHookOptions<GetDashboardCountApplicantsQuery, GetDashboardCountApplicantsQueryVariables>) {
        return Apollo.useQuery<GetDashboardCountApplicantsQuery, GetDashboardCountApplicantsQueryVariables>(GetDashboardCountApplicantsDocument, baseOptions);
      }
export function useGetDashboardCountApplicantsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDashboardCountApplicantsQuery, GetDashboardCountApplicantsQueryVariables>) {
          return Apollo.useLazyQuery<GetDashboardCountApplicantsQuery, GetDashboardCountApplicantsQueryVariables>(GetDashboardCountApplicantsDocument, baseOptions);
        }
export type GetDashboardCountApplicantsQueryHookResult = ReturnType<typeof useGetDashboardCountApplicantsQuery>;
export type GetDashboardCountApplicantsLazyQueryHookResult = ReturnType<typeof useGetDashboardCountApplicantsLazyQuery>;
export type GetDashboardCountApplicantsQueryResult = Apollo.QueryResult<GetDashboardCountApplicantsQuery, GetDashboardCountApplicantsQueryVariables>;
export const GetDashboardCountStatusUpdatesDocument = gql`
    query GetDashboardCountStatusUpdates($statuses: [StatusUpdateEnum!], $comparisonPeriod: Period, $period: Period!, $jobId: Int!) {
  countStatusUpdates(comparisonPeriod: $comparisonPeriod, statuses: $statuses, period: $period, jobId: $jobId) {
    numberOfStatusUpdates
    growthRate
  }
}
    `;

/**
 * __useGetDashboardCountStatusUpdatesQuery__
 *
 * To run a query within a React component, call `useGetDashboardCountStatusUpdatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDashboardCountStatusUpdatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDashboardCountStatusUpdatesQuery({
 *   variables: {
 *      statuses: // value for 'statuses'
 *      comparisonPeriod: // value for 'comparisonPeriod'
 *      period: // value for 'period'
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useGetDashboardCountStatusUpdatesQuery(baseOptions?: Apollo.QueryHookOptions<GetDashboardCountStatusUpdatesQuery, GetDashboardCountStatusUpdatesQueryVariables>) {
        return Apollo.useQuery<GetDashboardCountStatusUpdatesQuery, GetDashboardCountStatusUpdatesQueryVariables>(GetDashboardCountStatusUpdatesDocument, baseOptions);
      }
export function useGetDashboardCountStatusUpdatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDashboardCountStatusUpdatesQuery, GetDashboardCountStatusUpdatesQueryVariables>) {
          return Apollo.useLazyQuery<GetDashboardCountStatusUpdatesQuery, GetDashboardCountStatusUpdatesQueryVariables>(GetDashboardCountStatusUpdatesDocument, baseOptions);
        }
export type GetDashboardCountStatusUpdatesQueryHookResult = ReturnType<typeof useGetDashboardCountStatusUpdatesQuery>;
export type GetDashboardCountStatusUpdatesLazyQueryHookResult = ReturnType<typeof useGetDashboardCountStatusUpdatesLazyQuery>;
export type GetDashboardCountStatusUpdatesQueryResult = Apollo.QueryResult<GetDashboardCountStatusUpdatesQuery, GetDashboardCountStatusUpdatesQueryVariables>;
export const GetCompanyDashboardSubscriptionsSummaryDocument = gql`
    query GetCompanyDashboardSubscriptionsSummary($companyId: Int!) {
  getCompany(id: $companyId) {
    id
    subscriptions {
      id
      plan {
        id
        name
        role {
          id
          name
        }
      }
      lastPayment {
        id
        amount
        paidAt
      }
      nextPayment {
        amount
        expectedDate
      }
    }
  }
}
    `;

/**
 * __useGetCompanyDashboardSubscriptionsSummaryQuery__
 *
 * To run a query within a React component, call `useGetCompanyDashboardSubscriptionsSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyDashboardSubscriptionsSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyDashboardSubscriptionsSummaryQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetCompanyDashboardSubscriptionsSummaryQuery(baseOptions?: Apollo.QueryHookOptions<GetCompanyDashboardSubscriptionsSummaryQuery, GetCompanyDashboardSubscriptionsSummaryQueryVariables>) {
        return Apollo.useQuery<GetCompanyDashboardSubscriptionsSummaryQuery, GetCompanyDashboardSubscriptionsSummaryQueryVariables>(GetCompanyDashboardSubscriptionsSummaryDocument, baseOptions);
      }
export function useGetCompanyDashboardSubscriptionsSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyDashboardSubscriptionsSummaryQuery, GetCompanyDashboardSubscriptionsSummaryQueryVariables>) {
          return Apollo.useLazyQuery<GetCompanyDashboardSubscriptionsSummaryQuery, GetCompanyDashboardSubscriptionsSummaryQueryVariables>(GetCompanyDashboardSubscriptionsSummaryDocument, baseOptions);
        }
export type GetCompanyDashboardSubscriptionsSummaryQueryHookResult = ReturnType<typeof useGetCompanyDashboardSubscriptionsSummaryQuery>;
export type GetCompanyDashboardSubscriptionsSummaryLazyQueryHookResult = ReturnType<typeof useGetCompanyDashboardSubscriptionsSummaryLazyQuery>;
export type GetCompanyDashboardSubscriptionsSummaryQueryResult = Apollo.QueryResult<GetCompanyDashboardSubscriptionsSummaryQuery, GetCompanyDashboardSubscriptionsSummaryQueryVariables>;
export const GetCurrentCompanyDashboardRolesDocument = gql`
    query GetCurrentCompanyDashboardRoles {
  currentUser {
    id
    member {
      id
      occupation
      company {
        id
        name
        jobs {
          id
          role {
            name
            id
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetCurrentCompanyDashboardRolesQuery__
 *
 * To run a query within a React component, call `useGetCurrentCompanyDashboardRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentCompanyDashboardRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentCompanyDashboardRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentCompanyDashboardRolesQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentCompanyDashboardRolesQuery, GetCurrentCompanyDashboardRolesQueryVariables>) {
        return Apollo.useQuery<GetCurrentCompanyDashboardRolesQuery, GetCurrentCompanyDashboardRolesQueryVariables>(GetCurrentCompanyDashboardRolesDocument, baseOptions);
      }
export function useGetCurrentCompanyDashboardRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentCompanyDashboardRolesQuery, GetCurrentCompanyDashboardRolesQueryVariables>) {
          return Apollo.useLazyQuery<GetCurrentCompanyDashboardRolesQuery, GetCurrentCompanyDashboardRolesQueryVariables>(GetCurrentCompanyDashboardRolesDocument, baseOptions);
        }
export type GetCurrentCompanyDashboardRolesQueryHookResult = ReturnType<typeof useGetCurrentCompanyDashboardRolesQuery>;
export type GetCurrentCompanyDashboardRolesLazyQueryHookResult = ReturnType<typeof useGetCurrentCompanyDashboardRolesLazyQuery>;
export type GetCurrentCompanyDashboardRolesQueryResult = Apollo.QueryResult<GetCurrentCompanyDashboardRolesQuery, GetCurrentCompanyDashboardRolesQueryVariables>;
export const GetImpersonatedCompanyDashboardRolesDocument = gql`
    query GetImpersonatedCompanyDashboardRoles($companyId: Int!) {
  getCompany(id: $companyId) {
    id
    name
    jobs {
      id
      role {
        name
        id
      }
    }
  }
}
    `;

/**
 * __useGetImpersonatedCompanyDashboardRolesQuery__
 *
 * To run a query within a React component, call `useGetImpersonatedCompanyDashboardRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetImpersonatedCompanyDashboardRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetImpersonatedCompanyDashboardRolesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetImpersonatedCompanyDashboardRolesQuery(baseOptions?: Apollo.QueryHookOptions<GetImpersonatedCompanyDashboardRolesQuery, GetImpersonatedCompanyDashboardRolesQueryVariables>) {
        return Apollo.useQuery<GetImpersonatedCompanyDashboardRolesQuery, GetImpersonatedCompanyDashboardRolesQueryVariables>(GetImpersonatedCompanyDashboardRolesDocument, baseOptions);
      }
export function useGetImpersonatedCompanyDashboardRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetImpersonatedCompanyDashboardRolesQuery, GetImpersonatedCompanyDashboardRolesQueryVariables>) {
          return Apollo.useLazyQuery<GetImpersonatedCompanyDashboardRolesQuery, GetImpersonatedCompanyDashboardRolesQueryVariables>(GetImpersonatedCompanyDashboardRolesDocument, baseOptions);
        }
export type GetImpersonatedCompanyDashboardRolesQueryHookResult = ReturnType<typeof useGetImpersonatedCompanyDashboardRolesQuery>;
export type GetImpersonatedCompanyDashboardRolesLazyQueryHookResult = ReturnType<typeof useGetImpersonatedCompanyDashboardRolesLazyQuery>;
export type GetImpersonatedCompanyDashboardRolesQueryResult = Apollo.QueryResult<GetImpersonatedCompanyDashboardRolesQuery, GetImpersonatedCompanyDashboardRolesQueryVariables>;
export const GetJobDocument = gql`
    query GetJob($companyId: Int, $id: Int!) {
  getJob(id: $id) {
    id
    company {
      id
    }
    subscription {
      id
      endAt
      billingType
      plan {
        id
      }
    }
    role {
      id
      name
      plans {
        id
        name
        iconUrl
        benefits
        annualPrice
        monthlyPrice
        monthlyTokens
        annualDiscountPercentage
        activeDiscount(companyId: $companyId) {
          discountedAnnualPrice
          discountedMonthlyPrice
          discount {
            id
            rate
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetJobQuery__
 *
 * To run a query within a React component, call `useGetJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetJobQuery(baseOptions?: Apollo.QueryHookOptions<GetJobQuery, GetJobQueryVariables>) {
        return Apollo.useQuery<GetJobQuery, GetJobQueryVariables>(GetJobDocument, baseOptions);
      }
export function useGetJobLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetJobQuery, GetJobQueryVariables>) {
          return Apollo.useLazyQuery<GetJobQuery, GetJobQueryVariables>(GetJobDocument, baseOptions);
        }
export type GetJobQueryHookResult = ReturnType<typeof useGetJobQuery>;
export type GetJobLazyQueryHookResult = ReturnType<typeof useGetJobLazyQuery>;
export type GetJobQueryResult = Apollo.QueryResult<GetJobQuery, GetJobQueryVariables>;
export const GetPaymentReceiptUrlDocument = gql`
    query GetPaymentReceiptUrl($id: Int!) {
  getPaymentReceiptUrl(id: $id)
}
    `;

/**
 * __useGetPaymentReceiptUrlQuery__
 *
 * To run a query within a React component, call `useGetPaymentReceiptUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentReceiptUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentReceiptUrlQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPaymentReceiptUrlQuery(baseOptions?: Apollo.QueryHookOptions<GetPaymentReceiptUrlQuery, GetPaymentReceiptUrlQueryVariables>) {
        return Apollo.useQuery<GetPaymentReceiptUrlQuery, GetPaymentReceiptUrlQueryVariables>(GetPaymentReceiptUrlDocument, baseOptions);
      }
export function useGetPaymentReceiptUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaymentReceiptUrlQuery, GetPaymentReceiptUrlQueryVariables>) {
          return Apollo.useLazyQuery<GetPaymentReceiptUrlQuery, GetPaymentReceiptUrlQueryVariables>(GetPaymentReceiptUrlDocument, baseOptions);
        }
export type GetPaymentReceiptUrlQueryHookResult = ReturnType<typeof useGetPaymentReceiptUrlQuery>;
export type GetPaymentReceiptUrlLazyQueryHookResult = ReturnType<typeof useGetPaymentReceiptUrlLazyQuery>;
export type GetPaymentReceiptUrlQueryResult = Apollo.QueryResult<GetPaymentReceiptUrlQuery, GetPaymentReceiptUrlQueryVariables>;
export const ListCurrentCompanyPaymentsDocument = gql`
    query ListCurrentCompanyPayments($offset: Int!) {
  currentUser {
    id
    member {
      id
      company {
        id
        name
        numberOfPayments
        payments(pagination: {orderBy: "paid_at", sortingOrder: DESC, offset: $offset, limit: 10}) {
          id
          amount
          paidAt
          tokensBought
          extraTokensPack {
            id
            role {
              id
              name
            }
          }
          subscription {
            id
            plan {
              id
              name
              role {
                id
                name
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useListCurrentCompanyPaymentsQuery__
 *
 * To run a query within a React component, call `useListCurrentCompanyPaymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCurrentCompanyPaymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCurrentCompanyPaymentsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useListCurrentCompanyPaymentsQuery(baseOptions?: Apollo.QueryHookOptions<ListCurrentCompanyPaymentsQuery, ListCurrentCompanyPaymentsQueryVariables>) {
        return Apollo.useQuery<ListCurrentCompanyPaymentsQuery, ListCurrentCompanyPaymentsQueryVariables>(ListCurrentCompanyPaymentsDocument, baseOptions);
      }
export function useListCurrentCompanyPaymentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListCurrentCompanyPaymentsQuery, ListCurrentCompanyPaymentsQueryVariables>) {
          return Apollo.useLazyQuery<ListCurrentCompanyPaymentsQuery, ListCurrentCompanyPaymentsQueryVariables>(ListCurrentCompanyPaymentsDocument, baseOptions);
        }
export type ListCurrentCompanyPaymentsQueryHookResult = ReturnType<typeof useListCurrentCompanyPaymentsQuery>;
export type ListCurrentCompanyPaymentsLazyQueryHookResult = ReturnType<typeof useListCurrentCompanyPaymentsLazyQuery>;
export type ListCurrentCompanyPaymentsQueryResult = Apollo.QueryResult<ListCurrentCompanyPaymentsQuery, ListCurrentCompanyPaymentsQueryVariables>;
export const ListCurrentCompanySubscriptionsDocument = gql`
    query ListCurrentCompanySubscriptions {
  currentUser {
    id
    member {
      id
      company {
        id
        name
        availableRoles {
          id
          name
        }
        jobs {
          id
          numberOfAvailableTokens
          role {
            id
            name
            extraTokensPacks {
              id
            }
          }
          subscription {
            id
            billingType
            endAt
            plan {
              id
              name
              benefits
              monthlyTokens
              annualPrice
              monthlyPrice
              activeDiscount {
                discountedAnnualPrice
                discountedMonthlyPrice
                discount {
                  id
                  endAt
                  rate
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useListCurrentCompanySubscriptionsQuery__
 *
 * To run a query within a React component, call `useListCurrentCompanySubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCurrentCompanySubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCurrentCompanySubscriptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListCurrentCompanySubscriptionsQuery(baseOptions?: Apollo.QueryHookOptions<ListCurrentCompanySubscriptionsQuery, ListCurrentCompanySubscriptionsQueryVariables>) {
        return Apollo.useQuery<ListCurrentCompanySubscriptionsQuery, ListCurrentCompanySubscriptionsQueryVariables>(ListCurrentCompanySubscriptionsDocument, baseOptions);
      }
export function useListCurrentCompanySubscriptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListCurrentCompanySubscriptionsQuery, ListCurrentCompanySubscriptionsQueryVariables>) {
          return Apollo.useLazyQuery<ListCurrentCompanySubscriptionsQuery, ListCurrentCompanySubscriptionsQueryVariables>(ListCurrentCompanySubscriptionsDocument, baseOptions);
        }
export type ListCurrentCompanySubscriptionsQueryHookResult = ReturnType<typeof useListCurrentCompanySubscriptionsQuery>;
export type ListCurrentCompanySubscriptionsLazyQueryHookResult = ReturnType<typeof useListCurrentCompanySubscriptionsLazyQuery>;
export type ListCurrentCompanySubscriptionsQueryResult = Apollo.QueryResult<ListCurrentCompanySubscriptionsQuery, ListCurrentCompanySubscriptionsQueryVariables>;
export const ListExtraTokenPacksDocument = gql`
    query ListExtraTokenPacks($roleId: Int!) {
  getRole(id: $roleId) {
    id
    name
    extraTokensPacks {
      id
      price
      quantity
      activeDiscount {
        discountedPrice
        discount {
          id
          rate
        }
      }
    }
  }
}
    `;

/**
 * __useListExtraTokenPacksQuery__
 *
 * To run a query within a React component, call `useListExtraTokenPacksQuery` and pass it any options that fit your needs.
 * When your component renders, `useListExtraTokenPacksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListExtraTokenPacksQuery({
 *   variables: {
 *      roleId: // value for 'roleId'
 *   },
 * });
 */
export function useListExtraTokenPacksQuery(baseOptions?: Apollo.QueryHookOptions<ListExtraTokenPacksQuery, ListExtraTokenPacksQueryVariables>) {
        return Apollo.useQuery<ListExtraTokenPacksQuery, ListExtraTokenPacksQueryVariables>(ListExtraTokenPacksDocument, baseOptions);
      }
export function useListExtraTokenPacksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListExtraTokenPacksQuery, ListExtraTokenPacksQueryVariables>) {
          return Apollo.useLazyQuery<ListExtraTokenPacksQuery, ListExtraTokenPacksQueryVariables>(ListExtraTokenPacksDocument, baseOptions);
        }
export type ListExtraTokenPacksQueryHookResult = ReturnType<typeof useListExtraTokenPacksQuery>;
export type ListExtraTokenPacksLazyQueryHookResult = ReturnType<typeof useListExtraTokenPacksLazyQuery>;
export type ListExtraTokenPacksQueryResult = Apollo.QueryResult<ListExtraTokenPacksQuery, ListExtraTokenPacksQueryVariables>;
export const ListImpersonatedCompanyPaymentsDocument = gql`
    query ListImpersonatedCompanyPayments($companyId: Int!, $offset: Int!) {
  getCompany(id: $companyId) {
    id
    name
    numberOfPayments
    payments(pagination: {orderBy: "paid_at", sortingOrder: DESC, offset: $offset, limit: 10}) {
      id
      amount
      paidAt
      tokensBought
      extraTokensPack {
        id
        role {
          id
          name
        }
      }
      subscription {
        id
        plan {
          id
          name
          role {
            id
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useListImpersonatedCompanyPaymentsQuery__
 *
 * To run a query within a React component, call `useListImpersonatedCompanyPaymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListImpersonatedCompanyPaymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListImpersonatedCompanyPaymentsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useListImpersonatedCompanyPaymentsQuery(baseOptions?: Apollo.QueryHookOptions<ListImpersonatedCompanyPaymentsQuery, ListImpersonatedCompanyPaymentsQueryVariables>) {
        return Apollo.useQuery<ListImpersonatedCompanyPaymentsQuery, ListImpersonatedCompanyPaymentsQueryVariables>(ListImpersonatedCompanyPaymentsDocument, baseOptions);
      }
export function useListImpersonatedCompanyPaymentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListImpersonatedCompanyPaymentsQuery, ListImpersonatedCompanyPaymentsQueryVariables>) {
          return Apollo.useLazyQuery<ListImpersonatedCompanyPaymentsQuery, ListImpersonatedCompanyPaymentsQueryVariables>(ListImpersonatedCompanyPaymentsDocument, baseOptions);
        }
export type ListImpersonatedCompanyPaymentsQueryHookResult = ReturnType<typeof useListImpersonatedCompanyPaymentsQuery>;
export type ListImpersonatedCompanyPaymentsLazyQueryHookResult = ReturnType<typeof useListImpersonatedCompanyPaymentsLazyQuery>;
export type ListImpersonatedCompanyPaymentsQueryResult = Apollo.QueryResult<ListImpersonatedCompanyPaymentsQuery, ListImpersonatedCompanyPaymentsQueryVariables>;
export const ListImpersonatedCompanySubscriptionsDocument = gql`
    query ListImpersonatedCompanySubscriptions($companyId: Int!) {
  getCompany(id: $companyId) {
    id
    name
    availableRoles {
      id
      name
    }
    jobs {
      id
      numberOfAvailableTokens
      role {
        id
        name
        extraTokensPacks {
          id
        }
      }
      subscription {
        id
        billingType
        endAt
        plan {
          id
          name
          benefits
          monthlyTokens
          annualPrice
          monthlyPrice
          activeDiscount(companyId: $companyId) {
            discountedAnnualPrice
            discountedMonthlyPrice
            discount {
              id
              endAt
              rate
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useListImpersonatedCompanySubscriptionsQuery__
 *
 * To run a query within a React component, call `useListImpersonatedCompanySubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListImpersonatedCompanySubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListImpersonatedCompanySubscriptionsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useListImpersonatedCompanySubscriptionsQuery(baseOptions?: Apollo.QueryHookOptions<ListImpersonatedCompanySubscriptionsQuery, ListImpersonatedCompanySubscriptionsQueryVariables>) {
        return Apollo.useQuery<ListImpersonatedCompanySubscriptionsQuery, ListImpersonatedCompanySubscriptionsQueryVariables>(ListImpersonatedCompanySubscriptionsDocument, baseOptions);
      }
export function useListImpersonatedCompanySubscriptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListImpersonatedCompanySubscriptionsQuery, ListImpersonatedCompanySubscriptionsQueryVariables>) {
          return Apollo.useLazyQuery<ListImpersonatedCompanySubscriptionsQuery, ListImpersonatedCompanySubscriptionsQueryVariables>(ListImpersonatedCompanySubscriptionsDocument, baseOptions);
        }
export type ListImpersonatedCompanySubscriptionsQueryHookResult = ReturnType<typeof useListImpersonatedCompanySubscriptionsQuery>;
export type ListImpersonatedCompanySubscriptionsLazyQueryHookResult = ReturnType<typeof useListImpersonatedCompanySubscriptionsLazyQuery>;
export type ListImpersonatedCompanySubscriptionsQueryResult = Apollo.QueryResult<ListImpersonatedCompanySubscriptionsQuery, ListImpersonatedCompanySubscriptionsQueryVariables>;
export const ListCurrentCompanyActiveMembersDocument = gql`
    query ListCurrentCompanyActiveMembers {
  currentUser {
    id
    member {
      id
      company {
        id
        name
        activeMembers {
          id
          name
          photo
          invitedEmail
          phoneNumber
          occupation
          user {
            id
            email
            insertedAt
          }
        }
      }
    }
  }
}
    `;

/**
 * __useListCurrentCompanyActiveMembersQuery__
 *
 * To run a query within a React component, call `useListCurrentCompanyActiveMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCurrentCompanyActiveMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCurrentCompanyActiveMembersQuery({
 *   variables: {
 *   },
 * });
 */
export function useListCurrentCompanyActiveMembersQuery(baseOptions?: Apollo.QueryHookOptions<ListCurrentCompanyActiveMembersQuery, ListCurrentCompanyActiveMembersQueryVariables>) {
        return Apollo.useQuery<ListCurrentCompanyActiveMembersQuery, ListCurrentCompanyActiveMembersQueryVariables>(ListCurrentCompanyActiveMembersDocument, baseOptions);
      }
export function useListCurrentCompanyActiveMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListCurrentCompanyActiveMembersQuery, ListCurrentCompanyActiveMembersQueryVariables>) {
          return Apollo.useLazyQuery<ListCurrentCompanyActiveMembersQuery, ListCurrentCompanyActiveMembersQueryVariables>(ListCurrentCompanyActiveMembersDocument, baseOptions);
        }
export type ListCurrentCompanyActiveMembersQueryHookResult = ReturnType<typeof useListCurrentCompanyActiveMembersQuery>;
export type ListCurrentCompanyActiveMembersLazyQueryHookResult = ReturnType<typeof useListCurrentCompanyActiveMembersLazyQuery>;
export type ListCurrentCompanyActiveMembersQueryResult = Apollo.QueryResult<ListCurrentCompanyActiveMembersQuery, ListCurrentCompanyActiveMembersQueryVariables>;
export const ListImpersonatedCompanyActiveMembersDocument = gql`
    query ListImpersonatedCompanyActiveMembers($companyId: Int!) {
  getCompany(id: $companyId) {
    id
    name
    activeMembers {
      id
      name
      photo
      invitedEmail
      phoneNumber
      occupation
      user {
        id
        email
        insertedAt
      }
    }
  }
}
    `;

/**
 * __useListImpersonatedCompanyActiveMembersQuery__
 *
 * To run a query within a React component, call `useListImpersonatedCompanyActiveMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListImpersonatedCompanyActiveMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListImpersonatedCompanyActiveMembersQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useListImpersonatedCompanyActiveMembersQuery(baseOptions?: Apollo.QueryHookOptions<ListImpersonatedCompanyActiveMembersQuery, ListImpersonatedCompanyActiveMembersQueryVariables>) {
        return Apollo.useQuery<ListImpersonatedCompanyActiveMembersQuery, ListImpersonatedCompanyActiveMembersQueryVariables>(ListImpersonatedCompanyActiveMembersDocument, baseOptions);
      }
export function useListImpersonatedCompanyActiveMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListImpersonatedCompanyActiveMembersQuery, ListImpersonatedCompanyActiveMembersQueryVariables>) {
          return Apollo.useLazyQuery<ListImpersonatedCompanyActiveMembersQuery, ListImpersonatedCompanyActiveMembersQueryVariables>(ListImpersonatedCompanyActiveMembersDocument, baseOptions);
        }
export type ListImpersonatedCompanyActiveMembersQueryHookResult = ReturnType<typeof useListImpersonatedCompanyActiveMembersQuery>;
export type ListImpersonatedCompanyActiveMembersLazyQueryHookResult = ReturnType<typeof useListImpersonatedCompanyActiveMembersLazyQuery>;
export type ListImpersonatedCompanyActiveMembersQueryResult = Apollo.QueryResult<ListImpersonatedCompanyActiveMembersQuery, ListImpersonatedCompanyActiveMembersQueryVariables>;
export const ListCurrentCompanyCreditCardsDocument = gql`
    query ListCurrentCompanyCreditCards {
  currentUser {
    id
    member {
      id
      company {
        id
        name
        creditCards {
          id
          brand
          last4
          isDefault
          expMonth
          expYear
        }
      }
    }
  }
}
    `;

/**
 * __useListCurrentCompanyCreditCardsQuery__
 *
 * To run a query within a React component, call `useListCurrentCompanyCreditCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCurrentCompanyCreditCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCurrentCompanyCreditCardsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListCurrentCompanyCreditCardsQuery(baseOptions?: Apollo.QueryHookOptions<ListCurrentCompanyCreditCardsQuery, ListCurrentCompanyCreditCardsQueryVariables>) {
        return Apollo.useQuery<ListCurrentCompanyCreditCardsQuery, ListCurrentCompanyCreditCardsQueryVariables>(ListCurrentCompanyCreditCardsDocument, baseOptions);
      }
export function useListCurrentCompanyCreditCardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListCurrentCompanyCreditCardsQuery, ListCurrentCompanyCreditCardsQueryVariables>) {
          return Apollo.useLazyQuery<ListCurrentCompanyCreditCardsQuery, ListCurrentCompanyCreditCardsQueryVariables>(ListCurrentCompanyCreditCardsDocument, baseOptions);
        }
export type ListCurrentCompanyCreditCardsQueryHookResult = ReturnType<typeof useListCurrentCompanyCreditCardsQuery>;
export type ListCurrentCompanyCreditCardsLazyQueryHookResult = ReturnType<typeof useListCurrentCompanyCreditCardsLazyQuery>;
export type ListCurrentCompanyCreditCardsQueryResult = Apollo.QueryResult<ListCurrentCompanyCreditCardsQuery, ListCurrentCompanyCreditCardsQueryVariables>;
export const ListImpersonatedCompanyCreditCardsDocument = gql`
    query ListImpersonatedCompanyCreditCards($companyId: Int!) {
  getCompany(id: $companyId) {
    id
    name
    creditCards {
      id
      brand
      last4
      isDefault
      expMonth
      expYear
    }
  }
}
    `;

/**
 * __useListImpersonatedCompanyCreditCardsQuery__
 *
 * To run a query within a React component, call `useListImpersonatedCompanyCreditCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListImpersonatedCompanyCreditCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListImpersonatedCompanyCreditCardsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useListImpersonatedCompanyCreditCardsQuery(baseOptions?: Apollo.QueryHookOptions<ListImpersonatedCompanyCreditCardsQuery, ListImpersonatedCompanyCreditCardsQueryVariables>) {
        return Apollo.useQuery<ListImpersonatedCompanyCreditCardsQuery, ListImpersonatedCompanyCreditCardsQueryVariables>(ListImpersonatedCompanyCreditCardsDocument, baseOptions);
      }
export function useListImpersonatedCompanyCreditCardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListImpersonatedCompanyCreditCardsQuery, ListImpersonatedCompanyCreditCardsQueryVariables>) {
          return Apollo.useLazyQuery<ListImpersonatedCompanyCreditCardsQuery, ListImpersonatedCompanyCreditCardsQueryVariables>(ListImpersonatedCompanyCreditCardsDocument, baseOptions);
        }
export type ListImpersonatedCompanyCreditCardsQueryHookResult = ReturnType<typeof useListImpersonatedCompanyCreditCardsQuery>;
export type ListImpersonatedCompanyCreditCardsLazyQueryHookResult = ReturnType<typeof useListImpersonatedCompanyCreditCardsLazyQuery>;
export type ListImpersonatedCompanyCreditCardsQueryResult = Apollo.QueryResult<ListImpersonatedCompanyCreditCardsQuery, ListImpersonatedCompanyCreditCardsQueryVariables>;
export const GetLandingPageDocument = gql`
    query GetLandingPage($id: Int!) {
  getLandingPage(id: $id) {
    id
    headerImageUrl
    jobDescription
    layoutLabel
    buttonText
    subtitle
    logoUrl
    title
    color
  }
}
    `;

/**
 * __useGetLandingPageQuery__
 *
 * To run a query within a React component, call `useGetLandingPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLandingPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLandingPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLandingPageQuery(baseOptions?: Apollo.QueryHookOptions<GetLandingPageQuery, GetLandingPageQueryVariables>) {
        return Apollo.useQuery<GetLandingPageQuery, GetLandingPageQueryVariables>(GetLandingPageDocument, baseOptions);
      }
export function useGetLandingPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLandingPageQuery, GetLandingPageQueryVariables>) {
          return Apollo.useLazyQuery<GetLandingPageQuery, GetLandingPageQueryVariables>(GetLandingPageDocument, baseOptions);
        }
export type GetLandingPageQueryHookResult = ReturnType<typeof useGetLandingPageQuery>;
export type GetLandingPageLazyQueryHookResult = ReturnType<typeof useGetLandingPageLazyQuery>;
export type GetLandingPageQueryResult = Apollo.QueryResult<GetLandingPageQuery, GetLandingPageQueryVariables>;
export const GetEditLandingPageTemplateCurrentCompanyDocument = gql`
    query GetEditLandingPageTemplateCurrentCompany {
  currentUser {
    id
    member {
      id
      company {
        id
        name
        jobs {
          id
          role {
            id
            name
          }
          landingPage {
            id
            layoutLabel
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetEditLandingPageTemplateCurrentCompanyQuery__
 *
 * To run a query within a React component, call `useGetEditLandingPageTemplateCurrentCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEditLandingPageTemplateCurrentCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEditLandingPageTemplateCurrentCompanyQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEditLandingPageTemplateCurrentCompanyQuery(baseOptions?: Apollo.QueryHookOptions<GetEditLandingPageTemplateCurrentCompanyQuery, GetEditLandingPageTemplateCurrentCompanyQueryVariables>) {
        return Apollo.useQuery<GetEditLandingPageTemplateCurrentCompanyQuery, GetEditLandingPageTemplateCurrentCompanyQueryVariables>(GetEditLandingPageTemplateCurrentCompanyDocument, baseOptions);
      }
export function useGetEditLandingPageTemplateCurrentCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEditLandingPageTemplateCurrentCompanyQuery, GetEditLandingPageTemplateCurrentCompanyQueryVariables>) {
          return Apollo.useLazyQuery<GetEditLandingPageTemplateCurrentCompanyQuery, GetEditLandingPageTemplateCurrentCompanyQueryVariables>(GetEditLandingPageTemplateCurrentCompanyDocument, baseOptions);
        }
export type GetEditLandingPageTemplateCurrentCompanyQueryHookResult = ReturnType<typeof useGetEditLandingPageTemplateCurrentCompanyQuery>;
export type GetEditLandingPageTemplateCurrentCompanyLazyQueryHookResult = ReturnType<typeof useGetEditLandingPageTemplateCurrentCompanyLazyQuery>;
export type GetEditLandingPageTemplateCurrentCompanyQueryResult = Apollo.QueryResult<GetEditLandingPageTemplateCurrentCompanyQuery, GetEditLandingPageTemplateCurrentCompanyQueryVariables>;
export const GetEditLandingPageTemplateImpersonatedCompanyDocument = gql`
    query GetEditLandingPageTemplateImpersonatedCompany($companyId: Int!) {
  getCompany(id: $companyId) {
    id
    name
    jobs {
      id
      role {
        name
        id
      }
      landingPage {
        id
        layoutLabel
      }
    }
  }
}
    `;

/**
 * __useGetEditLandingPageTemplateImpersonatedCompanyQuery__
 *
 * To run a query within a React component, call `useGetEditLandingPageTemplateImpersonatedCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEditLandingPageTemplateImpersonatedCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEditLandingPageTemplateImpersonatedCompanyQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetEditLandingPageTemplateImpersonatedCompanyQuery(baseOptions?: Apollo.QueryHookOptions<GetEditLandingPageTemplateImpersonatedCompanyQuery, GetEditLandingPageTemplateImpersonatedCompanyQueryVariables>) {
        return Apollo.useQuery<GetEditLandingPageTemplateImpersonatedCompanyQuery, GetEditLandingPageTemplateImpersonatedCompanyQueryVariables>(GetEditLandingPageTemplateImpersonatedCompanyDocument, baseOptions);
      }
export function useGetEditLandingPageTemplateImpersonatedCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEditLandingPageTemplateImpersonatedCompanyQuery, GetEditLandingPageTemplateImpersonatedCompanyQueryVariables>) {
          return Apollo.useLazyQuery<GetEditLandingPageTemplateImpersonatedCompanyQuery, GetEditLandingPageTemplateImpersonatedCompanyQueryVariables>(GetEditLandingPageTemplateImpersonatedCompanyDocument, baseOptions);
        }
export type GetEditLandingPageTemplateImpersonatedCompanyQueryHookResult = ReturnType<typeof useGetEditLandingPageTemplateImpersonatedCompanyQuery>;
export type GetEditLandingPageTemplateImpersonatedCompanyLazyQueryHookResult = ReturnType<typeof useGetEditLandingPageTemplateImpersonatedCompanyLazyQuery>;
export type GetEditLandingPageTemplateImpersonatedCompanyQueryResult = Apollo.QueryResult<GetEditLandingPageTemplateImpersonatedCompanyQuery, GetEditLandingPageTemplateImpersonatedCompanyQueryVariables>;
export const GetCurrentUserProfileDocument = gql`
    query GetCurrentUserProfile {
  currentUser {
    id
    email
    member {
      id
      name
      photo
      occupation
      phoneNumber
    }
  }
}
    `;

/**
 * __useGetCurrentUserProfileQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserProfileQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentUserProfileQuery, GetCurrentUserProfileQueryVariables>) {
        return Apollo.useQuery<GetCurrentUserProfileQuery, GetCurrentUserProfileQueryVariables>(GetCurrentUserProfileDocument, baseOptions);
      }
export function useGetCurrentUserProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentUserProfileQuery, GetCurrentUserProfileQueryVariables>) {
          return Apollo.useLazyQuery<GetCurrentUserProfileQuery, GetCurrentUserProfileQueryVariables>(GetCurrentUserProfileDocument, baseOptions);
        }
export type GetCurrentUserProfileQueryHookResult = ReturnType<typeof useGetCurrentUserProfileQuery>;
export type GetCurrentUserProfileLazyQueryHookResult = ReturnType<typeof useGetCurrentUserProfileLazyQuery>;
export type GetCurrentUserProfileQueryResult = Apollo.QueryResult<GetCurrentUserProfileQuery, GetCurrentUserProfileQueryVariables>;