import React from "react";
import { useTranslation } from "react-i18next";

import Button from "components/Button";

import { RemoveItemButtonProps } from "./types";

const RemoveItemButton: React.FC<RemoveItemButtonProps> = ({
  removeItem,
  item,
}) => {
  const [t] = useTranslation();

  return (
    <Button
      onClick={removeItem(item)}
      color="status.error"
      variant="unstyled"
      fontSize="xs"
    >
      {t("buttons.remove")}
    </Button>
  );
};

export default RemoveItemButton;
