import React, { useCallback } from "react";
import { Trans, useTranslation } from "react-i18next";
import {
  ModalFooter,
  ModalBody,
  Stack,
  Text,
} from "@chakra-ui/react";

import Button from "components/Button";

import { ConfirmationModalProps } from "./types";

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  hideModal,
  componentProps: {
    footerContainerProps = {},
    showDeclineButton = true,
    hideOnConfirm = true,
    confirmText,
    declineText,
    onConfirm,
    textProps,
    text,
  },
}) => {
  const [t] = useTranslation();

  const handleOnConfirm = useCallback(() => {
    if (onConfirm) {
      onConfirm();
    }

    if (hideOnConfirm) {
      hideModal();
    }
  }, [
    hideOnConfirm,
    hideModal,
    onConfirm,
  ]);

  const handleOnClose = useCallback(() => {
    hideModal();
  }, [
    hideModal,
  ]);

  return (
    <>
      <ModalBody>
        <Stack
          spacing={3}
          my={3}
        >
          {
            typeof text === "string"
              ? (
                <Trans>
                  <Text
                    fontSize="sm"
                    {...(textProps ?? {})}
                  >
                    {text}
                  </Text>
                </Trans>
              )
              : text
          }
        </Stack>
      </ModalBody>

      <ModalFooter>
        <Stack
          spacing={3}
          isInline
          {...footerContainerProps}
        >
          {
            showDeclineButton && (
              <Button
                onClick={handleOnClose}
                variant="outline"
                fontSize="sm"
                w="full"
              >
                {declineText ?? t("buttons.no")}
              </Button>
            )
          }

          <Button
            onClick={handleOnConfirm}
            fontSize="sm"
            w="full"
          >
            {confirmText ?? t("buttons.yes")}
          </Button>
        </Stack>
      </ModalFooter>
    </>
  );
};

export default ConfirmationModal;
