import React from "react";
import { Redirect } from "react-router-dom";

import { DASHBOARD_PAGE_LOCATION } from "routes/locations";

const Home: React.FC = () => (
  <Redirect to={DASHBOARD_PAGE_LOCATION.toUrl()} />
);

export default Home;
