import React from "react";
import {
  Heading,
  VStack,
  Stack,
  Image,
  Flex,
  Text,
} from "@chakra-ui/react";
import { LoremIpsum } from "react-lorem-ipsum";

import Button from "components/Button";

import { LandingPageTemplateProps } from "./types";
import JobDescriptionSection from "./JobDescriptionSection";
import TemplateBackground from "./TemplateBackground";

const Template4: React.FC<LandingPageTemplateProps> = ({
  navigateToForm,
  landingPage,
}) => (
  <>
    <TemplateBackground
      height="auto"
      headerImageUrl={landingPage?.headerImageUrl}
      backgroundPosition="bottom center"
    >
      <Stack
        width="100%"
        height="100%"
        spacing={10}
        paddingY={10}
        paddingX={[10, null, 40]}
        alignItems="stretch"
        direction={["column", null, "row"]}
        justifyContent="space-between"
      >
        <Flex
          background="white"
          alignItems="center"
          direction={["column", "row"]}
          padding={5}
          width="15rem"
        >
          <Image
            src={landingPage?.logoUrl}
            height={100}
            width="15rem"
            alignSelf="center"
            objectFit="contain"
          />
        </Flex>

        <VStack
          spacing={4}
          width="100%"
          height="100%"
          marginTop="auto"
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="space-between"
        >
          <Heading
            textTransform="uppercase"
            fontFamily="bold"
            textAlign="left"
            fontSize="xs"
            color="white"
          >
            {landingPage.title}
          </Heading>

          <Text
            color="white"
            fontSize="xs"
            maxWidth="20rem"
            lineHeight="1.5"
            textAlign="left"
            whiteSpace="break-spaces"
          >
            {
              landingPage.subtitle || (
                <LoremIpsum
                  avgWordsPerSentence={4}
                  avgSentencesPerParagraph={4}
                />
              )
            }
          </Text>

          <Button
            minWidth="10rem"
            fontSize="xs"
            textTransform="uppercase"
            onClick={navigateToForm}
            color={landingPage.textColor}
            backgroundColor={landingPage.color}
          >
            {landingPage.buttonText}
          </Button>
        </VStack>
      </Stack>
    </TemplateBackground>

    <JobDescriptionSection
      navigateToForm={navigateToForm}
      landingPage={landingPage}
      buttonProps={{
        textTransform: "capitalize",
        borderRadius: 4,
        alignSelf: "flex-start",
        minWidth: "15rem",
      }}
      headingProps={{
        alignSelf: "flex-start",
      }}
    />
  </>
);

export default Template4;
