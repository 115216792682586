import React from "react";

import RouteSessionValidator from "./RouteSessionValidator";

const withRouterSessionValidator = (WrappedComponent: React.ComponentType) => (
  (props: Record<string, unknown>): JSX.Element => (
    <RouteSessionValidator>
      <WrappedComponent {...props} />
    </RouteSessionValidator>
  )
);

export default withRouterSessionValidator;
