import { useEffect } from "react";

import { Filter } from "hooks/useFilter/types";
import useFilter from "hooks/useFilter";

/**
 * Handles the update of one field state to the query params.
 *
 * @param filter The filter object
 * @param fieldValue The field state value.
 */
const useFieldValueToQueryParams = (filter: Filter, fieldValue: unknown): void => {
  const [, setFilter] = useFilter(filter);

  useEffect(() => {
    setFilter(fieldValue);
  }, [
    fieldValue,
    setFilter,
  ]);
};

export default useFieldValueToQueryParams;
