import React from "react";
import { Redirect, useParams, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Flex, Grid, Stack } from "@chakra-ui/react";
import queryString from "query-string";

import ApplicantProfileCard from "components/Tracking/Cards/Applicant/ApplicantProfileCard";
import ApplicantStatusHistoryCard from "components/Tracking/ApplicantStatusHistoryCard";
import TrackingProfileButtons from "components/Tracking/TrackingProfileButtons";
import AvailabilityMatrixCard from "components/Tracking/AvailabilityMatrixCard";
import TrackingProfileCharts from "components/Tracking/TrackingProfileCharts";
import ExtraInformationCard from "components/Tracking/ExtraInformationCard";
import ApplicantStatusCard from "components/Tracking/ApplicantStatusCard";
import { Applicant, useGetApplicantQuery, useGetSpiderChartInactiveScoresQuery } from "generated/graphql";
import GoBackHeader from "components/PageHeaders/GoBackHeader";
import { APPLICANT_TRACKING_PAGE_PATH } from "routes";
import Profile from "components/Tracking/Profile";
import { ProfileType } from "constants/profiles";
import Story from "components/Tracking/Story";
import buildName from "utils/buildName";
import QualityTooltip from "components/Tooltip/QualityTooltip";
import ApplicantsProfileTour from "components/ReactTour/ApplicantsProfileTour";
import { APPLICANTS_PROFILE_TOUR_STEPS } from "constants/reactTour";
import { getRole } from "utils/getRole";
import TrackingProfileAdditionalQualitiesChart from "components/Tracking/TrackingProfileAdditionalQualitiesChart";

import { ApplicantProfileParams } from "./types";

const ApplicantProfile: React.FC = () => {
  const [t] = useTranslation();
  const history = useHistory();

  const { id } = useParams<ApplicantProfileParams>();

  const isPrintLayout = !!parseInt(
    queryString.parse(history.location.search)?.print as string,
  );

  const applicantId = parseInt(id);

  const {
    loading,
    data,
  } = useGetApplicantQuery({
    variables: {
      id: applicantId,
    },
  });

  const applicant = data?.getApplicant as Applicant;
  const isProfessional = applicant?.job?.role.isProfessional;
  const showAdditionalQualities = applicant?.job?.role.showAdditionalQualities;

  const {
    loading: inactiveSpiderChartLoading,
    data: inactiveSpiderChartData,
  } = useGetSpiderChartInactiveScoresQuery({
    variables: {
      id: applicantId,
    },
    skip: !applicantId || !isProfessional,
  });

  const role = getRole(applicant?.job?.role?.name);

  if (!loading && !applicant && !inactiveSpiderChartLoading) {
    return (
      <Redirect to={APPLICANT_TRACKING_PAGE_PATH} />
    );
  }

  const availabilityCard = (lite: boolean): JSX.Element => (
    <AvailabilityMatrixCard
      value={applicant?.availabilities}
      isDisabled
      lite={lite}
    />
  );

  const showAdditional = (isProfessional && showAdditionalQualities);

  return (
    <Flex
      position="relative"
      flexDir="column"
    >
      <ApplicantsProfileTour
        roleName={applicant?.job.role.name}
        isProfessional={isProfessional}
        isPrintLayout={isPrintLayout}
        isLoading={loading}
      />

      <GoBackHeader>
        <TrackingProfileButtons
          name={buildName(applicant?.firstName, applicant?.lastName)}
          profileType={ProfileType.Applicant}
          id={applicantId}
        />
      </GoBackHeader>

      <Profile loading={loading}>
        <Grid
          gridColumn="1 / 2 span"
          templateColumns={{
            base: "1fr",
            xl: "1fr 30%",
          }}
          gap={4}
        >
          <Stack spacing={4}>
            <ApplicantProfileCard
              applicant={applicant}
              showTooltip
            />

            <TrackingProfileCharts
              mainDatasetLabel={t("applicant_profile.applicant_qualities")}
              title={
                isProfessional
                  ? `${t("applicant_profile.core_qualities")}:`
                  : `${t("applicant_profile.applicant_qualities")}:`
              }
              scores={applicant?.assessment?.spiderChartScores}
              tourClassNames={APPLICANTS_PROFILE_TOUR_STEPS}
              showAverageValues
              QualityTooltip={(
                <QualityTooltip
                  description={t("tooltips.qualities.description", { role })}
                  footerNote={t("tooltips.qualities.footer")}
                />
              )}
            />
          </Stack>

          <ApplicantStatusCard
            tourClassNames={APPLICANTS_PROFILE_TOUR_STEPS}
            isPrintLayout={isPrintLayout}
            applicant={applicant}
          />
        </Grid>

        <Stack spacing={4}>
          {
            showAdditional && (
              <TrackingProfileAdditionalQualitiesChart
                scores={inactiveSpiderChartData?.getApplicant?.assessment?.spiderChartScores ?? []}
                mainDatasetLabel={t("applicant_profile.applicant_qualities")}
                title={t("applicant_profile.additional_qualities")}
                showAverageValues
              />
            )
          }

          {
            isProfessional && !showAdditional && availabilityCard(false)
          }

          {
            !isProfessional && (availabilityCard(false))
          }
        </Stack>

        <Stack spacing={4}>
          <ApplicantStatusHistoryCard applicant={applicant} />

          {
            (isProfessional && showAdditional) && (availabilityCard(true))
          }
        </Stack>

        <Story
          title={t("applicant_profile.applicant_story")}
          openAnswers={applicant?.openAnswers}
        />

        <ExtraInformationCard
          title={t("applicant_profile.application_information")}
          profileType={ProfileType.Applicant}
          data={applicant}
        />
      </Profile>
    </Flex>
  );
};

export default ApplicantProfile;
