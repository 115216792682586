import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Stack } from "@chakra-ui/react";

import useCompanyOnboardingFormStore from "components/Forms/CompanyOnboardingForm/store";
import useFilter, { filters } from "hooks/useFilter";
import useWizardStore from "components/Wizard/store";
import { FormTypeEnum } from "generated/graphql";
import useViewForm from "hooks/useViewForm";
import Button from "components/Button";

const ConfigureApplicationHeader: React.FC = () => {
  const [, setSelectedStepIndex] = useFilter(filters.selectedStep);

  const [t] = useTranslation();

  const formId = useCompanyOnboardingFormStore((store) => store.form?.id);

  const navigateToNextStep = useWizardStore((store) => store.navigateToNextStep);

  const [viewForm, isViewFormLoading] = useViewForm();

  const handleNavigateToNextStep = useCallback(() => {
    setSelectedStepIndex(1);

    navigateToNextStep();
  }, [
    setSelectedStepIndex,
    navigateToNextStep,
  ]);

  const handleViewForm = useCallback(() => {
    if (!formId) {
      return;
    }

    viewForm({
      formType: FormTypeEnum.Application,
      formId,
    });
  }, [
    viewForm,
    formId,
  ]);

  return (
    <Stack isInline>
      <Button
        isLoading={isViewFormLoading}
        onClick={handleViewForm}
        isDisabled={!formId}
        variant="outline"
        fontSize="sm"
        size="sm"
      >
        {t("buttons.view")}
      </Button>

      <Button
        onClick={handleNavigateToNextStep}
        fontSize="sm"
        size="sm"
      >
        {t("buttons.next")}
      </Button>
    </Stack>
  );
};

export default ConfigureApplicationHeader;
