import { theme as chakraUiTheme, extendTheme } from "@chakra-ui/react";

const PRIMARY_COLOR = "#1B7291";
const PRIMARY_COLOR_LIGHT = "#66B4D0";
const PRIMARY_COLOR_DISABLED = "#CEE5ED";

const SECONDARY_COLOR = "#F7921C";
const SECONDARY_COLOR_LIGHT = "#F2AE5A";
const SECONDARY_COLOR_DISABLED = "#FFE5C2";

// Remember to update the colors type in `src/settings/theme/types.ts
// when adding a new colors scheme.
const colors = extendTheme({
  ...chakraUiTheme.colors,
  black: "#000000",
  white: "#ffffff",
  background: "#FAFAFA",
  primary: {
    50: PRIMARY_COLOR_DISABLED,
    100: PRIMARY_COLOR_LIGHT,
    200: PRIMARY_COLOR,
    300: PRIMARY_COLOR,
    400: PRIMARY_COLOR,
    500: PRIMARY_COLOR,
    600: PRIMARY_COLOR,
    700: PRIMARY_COLOR,
    800: PRIMARY_COLOR,
    900: PRIMARY_COLOR,
  },
  secondary: {
    50: SECONDARY_COLOR_DISABLED,
    100: SECONDARY_COLOR_LIGHT,
    200: SECONDARY_COLOR,
    300: SECONDARY_COLOR,
    400: SECONDARY_COLOR,
    500: SECONDARY_COLOR,
    600: SECONDARY_COLOR,
    700: SECONDARY_COLOR,
    800: SECONDARY_COLOR,
    900: SECONDARY_COLOR,
  },
  gray: {
    ...chakraUiTheme.colors.gray,
    100: "#EAEAEA",
    200: "#D1D1D1",
    300: "#ADADAD",
    400: "#707070",
  },
  additionalQualities: {
    100: "#D1D1D1",
    200: "#9B9B9B",
    300: "#707070",
    400: "#474747",
  },
  status: {
    success: "#489B4B",
    warning: "#CE7915",
    error: "#C11E1E",
    info: "#248EB4",
  },
});

export default colors;
