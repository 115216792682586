import React from "react";

import { GetStepsProps, GetStepsResultProps } from "./types";
import ReactTourStep from "./ReactTourStep";

const getSteps = ({
  mapStepList,
  stepList,
}: GetStepsProps): GetStepsResultProps[] => Object.values(stepList).map((step) => ({
  selector: `.${step}`,
  content: React.createElement(
    ReactTourStep,
    {
      mapStepList,
      step,
    },
  ),
}));

export default getSteps;
