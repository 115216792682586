import * as React from "react";
import { Box as ChakraBox, BoxProps } from "@chakra-ui/react";

const Box = React.forwardRef<HTMLDivElement, BoxProps>((
  {
    borderColor = "gray.400",
    ...props
  },
  ref,
) => (
  <ChakraBox
    {...props}
    _focus={{ outline: "none" }}
    borderColor={borderColor}
    justifyContent="center"
    borderStyle="dashed"
    alignItems="center"
    borderWidth="2px"
    display="flex"
    rounded="lg"
    ref={ref}
  />
));

export default Box;
