import React, { useCallback } from "react";
import { Text, Flex, Button } from "@chakra-ui/react";
import * as R from "remeda";

import {
  GetPaginationComponentsParameters,
  CreatePaginationComponentParameters,
  PaginationButtonProps,
  PaginationArrowProps,
  PageVariant,
  PageItem,
} from "components/Pagination/types";
import ArrowLeftIcon from "settings/theme/icons/ArrowLeftIcon";
import ArrowRightIcon from "settings/theme/icons/ArrowRightIcon";

export const PaginationArrow: React.FC<PaginationArrowProps> = ({
  page,
  setPage,
  onClick,
  disabled,
  pageVariant,
  isLastPageSelected,
  ...rest
}) => {
  const handleClick = useCallback((event) => {
    if (onClick) {
      onClick(event);
    }

    let pageNumber = page;

    if (pageVariant === PageVariant.previous) {
      pageNumber = page - 1;
    } else if (pageVariant === PageVariant.next) {
      pageNumber = page + 1;
    }

    setPage(Number(pageNumber));
  }, [
    page,
    onClick,
    setPage,
    pageVariant,
  ]);

  const isDisabled = (
    (
      isLastPageSelected
      && PageVariant.next === pageVariant
    )
    || disabled
  );

  return (
    <Button
      px={1}
      width={18}
      height="100%"
      display="flex"
      disabled={isDisabled}
      onClick={handleClick}
      minWidth="100%"
      maxWidth="100%"
      placeItems="center"
      variant="unstyled"
      backgroundColor="gray.100"
      {...rest}
    >
      {
        pageVariant === PageVariant.previous ? (
          <ArrowLeftIcon />
        ) : (
          <ArrowRightIcon />
        )
      }
    </Button>
  );
};

export const PaginationButton: React.FC<PaginationButtonProps> = ({
  page,
  onClick,
  setPage,
  pageItem,
  colorScheme = "primary",
  ...rest
}) => {
  const isCurrentPage = page === pageItem.page;

  const handleClick = useCallback((event) => {
    setPage(Number(pageItem?.page));

    if (onClick) {
      onClick(event);
    }
  }, [
    setPage,
    onClick,
    pageItem,
  ]);

  return (
    <Button
      px={1}
      width={18}
      color="white"
      height="100%"
      minWidth="100%"
      maxWidth="100%"
      variant="unstyled"
      onClick={handleClick}
      fontSize="xxs"
      backgroundColor={isCurrentPage ? `${colorScheme}.300` : `${colorScheme}.100`}
      {...rest}
    >
      {pageItem.page}
    </Button>
  );
};

/**
 * Creates a pagination component
 */
export const createPaginationComponent = ({
  page,
  setPage,
  pageItem,
  colorScheme,
  isLastPageSelected,
}: CreatePaginationComponentParameters): JSX.Element => {
  const shouldShowPaginationArrow = (
    pageItem?.page === PageVariant.next
    || pageItem?.page === PageVariant.previous
  );

  const shouldShowPaginationPage = Number(pageItem?.page) >= 0;

  return (
    <Flex
      key={pageItem?.page}
      as="li"
      height="100%"
      maxHeight="100%"
    >
      {
        shouldShowPaginationArrow && (
          <PaginationArrow
            page={page}
            setPage={setPage}
            pageVariant={pageItem?.page}
            isLastPageSelected={isLastPageSelected}
            {...pageItem.props}
          />
        )
      }

      {
        pageItem?.page === PageVariant.gap && (
          <Text color="dark">...</Text>
        )
      }

      {
        shouldShowPaginationPage && (
          <PaginationButton
            page={page}
            setPage={setPage}
            pageItem={pageItem}
            colorScheme={colorScheme}
            {...pageItem.props}
          />
        )
      }
    </Flex>
  );
};

/**
 * The numbers of arrows returned by the pagination hook
 */
const arrowsCount = 2;

/**
 * Returns the pagination components based on the current pagination data
 * @param paginationData The pagination data
 */
export const getPaginationComponents = ({
  page,
  setPage,
  colorScheme,
  paginationData,
}: GetPaginationComponentsParameters): JSX.Element[] => {
  const isLastPageSelected = page === (paginationData.size - arrowsCount);

  return R.pipe(
    (Array.from({ length: paginationData.size })),
    (paginationSize) => paginationSize.map((_value, index): JSX.Element => {
      const pageItem = paginationData.getPageItem(index) as PageItem;

      return createPaginationComponent({
        page,
        setPage,
        pageItem,
        colorScheme,
        isLastPageSelected,
      });
    }),
  );
};
