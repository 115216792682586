import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import {
  FormTypeEnum,
  useCreateApplicantResponseMutation,
  useUpdateApplicantResponseMutation,
} from "generated/graphql";
import FormWizard from "components/FormComponents/FormWizard";
import useFormHandler from "hooks/useFormHandler";
import Loading from "components/Loading";
import isStepEnabled from "utils/isStepEnabled";

import { FormPageParams, UseStepMode } from "../types";
import useSteps from "./hooks/useSteps";
import useFormStore from "../store";

const ShortApplicantForm: React.FC = () => {
  const { shortCode } = useParams<FormPageParams>();

  const {
    onContinue,
    onSubmit,
    loading,
  } = useFormHandler({
    useCreateResponseMutation: useCreateApplicantResponseMutation,
    useUpdateResponseMutation: useUpdateApplicantResponseMutation,
    shortCode,
  });

  useEffect(() => {
    useFormStore.setState({ shortCode });
  }, [shortCode]);

  const {
    isFormLoading,
    roleName,
    fixedSectionsConfiguration,
    jobForm,
    allowImageUpload,
  } = useFormStore((store) => ({
    roleName: store.form?.job.role.name,
    fixedSectionsConfiguration: store.form?.fixedSectionsConfiguration,
    isFormLoading: store.isFormLoading,
    jobForm: store.form?.job,
    allowImageUpload: store.form?.allowImagesUpload,
  }));

  const steps = useSteps(
    roleName,
    fixedSectionsConfiguration,
    [],
    {
      jobForm,
      allowImageUpload: allowImageUpload ?? false,
      stepMode: UseStepMode.Submission,
      typeForm: FormTypeEnum.Application,
    },
  )
    .filter(isStepEnabled);

  if (isFormLoading) {
    return (
      <Loading />
    );
  }

  return (
    <FormWizard
      onContinue={onContinue}
      onSubmit={onSubmit}
      isLoading={loading}
      steps={steps}
    />
  );
};

export default ShortApplicantForm;
