import React, { useCallback } from "react";
import { Redirect } from "react-router-dom";
import { Stack } from "@chakra-ui/react";

import useFormResponse from "components/FormSteps/hooks/useFormResponse";
import { getFormPath } from "constants/formTypes";
import useFormSteps from "hooks/useFormSteps";
import { filters } from "hooks/useFilter";
import Loading from "components/Loading";
import { ROOT_PAGE_PATH } from "routes";
import isStepEnabled from "utils/isStepEnabled";
import { FormTypeEnum } from "generated/graphql";

import FormPreviewIntroduction from "./FormPreviewIntroduction";
import useSubmitResponse from "./hooks/useSubmitResponse";
import FormPreviewFooter from "./FormPreviewFooter";
import { FormPreviewContentProps } from "./types";
import FormResponses from "./FormResponses";
import { UseStepMode } from "../types";

const FormPreviewContent: React.FC<FormPreviewContentProps> = ({
  shortCode,
  form,
}) => {
  const [formResponse, loading] = useFormResponse(form.formType);

  const steps = useFormSteps({
    formName: form.job.role.name,
    fixedSectionsConfiguration: form.fixedSectionsConfiguration,
    extraSections: form.extraSections,
    formType: form.formType,
    otherFormParams: {
      jobForm: form.job,
      stepMode: UseStepMode.Submission,
      allowImageUpload: form.allowImagesUpload ?? false,
      typeForm: FormTypeEnum[form.formType],
    },
  }).filter(isStepEnabled);

  const [submitResponse, isSubmitResponseLoading] = useSubmitResponse({
    formResponseId: formResponse?.id as string,
    formType: form.formType,
    shortCode,
  });

  const handleGoToStep = useCallback((index: number) => () => {
    const formPath = getFormPath({
      formType: form.formType,
      shortCode,
    });

    const path = `${formPath}?${filters.stepIndex.name}=${index}`;

    window.location.href = path;
  }, [
    form.formType,
    shortCode,
  ]);

  if (loading) {
    return (
      <Loading />
    );
  }

  if (!formResponse) {
    return (
      <Redirect to={ROOT_PAGE_PATH} />
    );
  }

  return (
    <Stack
      p={[8, 10, 16]}
      spacing={8}
    >
      <FormPreviewIntroduction form={form} />

      <FormResponses
        formResponse={formResponse}
        goToStep={handleGoToStep}
        steps={steps}
        form={form}
      />

      <FormPreviewFooter
        isSubmitResponseLoading={isSubmitResponseLoading}
        goBack={handleGoToStep(steps.length)}
        submitResponse={submitResponse}
      />
    </Stack>
  );
};

export default FormPreviewContent;
