import { formatDate, monthDayYear } from "utils/dateFormats";
import { TokensByExpirationDate } from "generated/graphql";
import i18n from "translations/i18n";

/**
 * Formats a token by expiration date.
 * @param tokenByExpirationDate The token by expiration date as returned by the backend.
 */
const formatTokenByExpirationDate = (
  tokenByExpirationDate: TokensByExpirationDate,
): string => {
  const isUnlimitedTokens = tokenByExpirationDate.numberOfAvailableTokens === null;
  const isUnlimitedExpiration = tokenByExpirationDate.expirationDate === null;

  const expiringDate = (
    isUnlimitedExpiration
      ? ""
      : formatDate(tokenByExpirationDate.expirationDate, monthDayYear)
  );

  /**
   * For unlimited tokens, should show "Subscription: Unlimited tokens" (with no expiration date)
   * or "Subscription: Unlimited tokens until [date]" (with expiration date).
   */
  if (isUnlimitedTokens) {
    if (isUnlimitedExpiration) {
      return i18n.t("tokens_count.subscription_unlimited_tokens");
    }

    return i18n.t("tokens_count.subscription_unlimited_tokens_date", {
      date: expiringDate,
    });
  }

  /**
   * For tokens that don't expire, should show "Purchased (never expire): [count] tokens".
   */
  if (isUnlimitedExpiration) {
    return i18n.t("tokens_count.tokens_purchased_never_expire", {
      count: tokenByExpirationDate.numberOfAvailableTokens,
    });
  }

  /**
   * For tokens that expire, should show "Subscription: [count] tokens expiring [date]".
   */
  return i18n.t("tokens_count.tokens_subscription_expires", {
    date: formatDate(tokenByExpirationDate.expirationDate, monthDayYear),
    count: tokenByExpirationDate.numberOfAvailableTokens,
  });
};

export default formatTokenByExpirationDate;
