import React from "react";
import { Flex, Heading, Stack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import AvailabilityMatrix from "components/AvailabilityMatrix";
import Box from "components/Box";

import { AvailabilityMatrixCardProps } from "./types";

const AvailabilityMatrixCard: React.FC<AvailabilityMatrixCardProps> = (props) => {
  const [t] = useTranslation();

  return (
    <Box
      w="full"
      h="full"
      borderRadius={10}
    >
      <Flex
        direction="column"
        h="full"
      >
        <Heading fontSize="md" mb={5}>
          {`${t("filters.availability_not_valid_on_all_profiles")}:`}
        </Heading>

        <Stack h="full">
          <AvailabilityMatrix {...props} />
        </Stack>
      </Flex>
    </Box>
  );
};

export default AvailabilityMatrixCard;
