import { formatDate, monthDayYear } from "utils/dateFormats";
import i18n from "translations/i18n";

/**
 * Parses the payment text to be rendered in the payments summary.
 */
export const getPaymentText = (
  suffix: string,
  formattedAmount: string | undefined,
  date: string | undefined,
): string => {
  let result = `${suffix} ${i18n.t("dashboard.dashboard.payment_date")}: `;

  if (!formattedAmount || !date) {
    result += i18n.t("dashboard.dashboard.subscription_canceled");
  } else {
    result += `${formattedAmount} ${i18n.t("dashboard.dashboard.on")} ${formatDate(date, monthDayYear)}`;
  }

  return result;
};
