import React, { useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import invert from "invert-color";

import { getFormPath } from "constants/formTypes";

import {
  LandingPageTemplateLayoutLabel,
  commonLandingPageTemplateData,
  landingPagesTemplatesData,
} from "./Templates/landingPagesTemplatesData";
import createLandingPageTemplate from "./Templates";
import { LandingPageProps } from "./types";

const LandingPage: React.FC<LandingPageProps> = ({
  landingPage,
  shortCode,
  formType,
}) => {
  const history = useHistory();

  const layoutLabel = landingPage?.layoutLabel as LandingPageTemplateLayoutLabel;

  const landingPageData = useMemo(() => ({
    ...(landingPagesTemplatesData[layoutLabel] ?? {}),
    ...(landingPage ?? {}),
    textColor: (
      landingPage.color
        ? invert(landingPage.color as string, true)
        : commonLandingPageTemplateData.textColor
    ),
  }), [
    landingPage,
    layoutLabel,
  ]);

  const Template = createLandingPageTemplate(layoutLabel);

  const navigateToForm = useCallback(() => {
    if (!formType || !shortCode) {
      return;
    }

    const path = getFormPath({
      shortCode,
      formType,
    });

    history.push(path);
  }, [
    shortCode,
    formType,
    history,
  ]);

  if (!Template) {
    return null;
  }

  return (
    <Template
      navigateToForm={navigateToForm}
      landingPage={landingPageData}
    />
  );
};

export default LandingPage;
