import Chart, { ChartOptions } from "chart.js";

import theme from "settings/theme";

import { SpiderChartData } from "./SpiderChart/types";

/**
 * Defines the chart animation duration time, in ms.
 */
export const CHART_ANIMATION_DURATION = 500;

/**
 * Defines default options for charts.
 */
const defaultChartOptions: ChartOptions = {
  legend: {
    display: true,
    align: "start",
    position: "bottom",
    fullWidth: true,
    onClick: (event: MouseEvent): void => {
      event.stopPropagation();
    },
    labels: {
      filter: (item: Chart.ChartLegendLabelItem): boolean => (
        !!item.text
      ),
      fontFamily: theme.fonts.heading,
      fontColor: theme.colors.black,
      usePointStyle: true,
      fontSize: 12,
      padding: 10,
    },
  },
  tooltips: {
    enabled: true,
    mode: "nearest",
    intersect: false,
    backgroundColor: theme.colors.secondary[50],
    titleFontColor: theme.colors.black,
    titleFontSize: 12,
    titleFontFamily: theme.fonts.body,
    titleFontStyle: "bold",
    bodyFontColor: theme.colors.black,
    bodyFontFamily: theme.fonts.body,
    bodyFontStyle: "italic",
    bodyFontSize: 12,
    bodySpacing: 6,
    footerFontColor: theme.colors.black,
    footerFontStyle: "normal",
    footerFontFamily: theme.fonts.body,
    displayColors: false,
    callbacks: {
      title(tooltipItem: Chart.ChartTooltipItem[], data: SpiderChartData): string {
        if (!data.datasets) {
          return "";
        }

        return data
          .datasets[0]
          .descriptions[tooltipItem[0].index as number]
          .label;
      },
      label(tooltipItem: Chart.ChartTooltipItem, data: SpiderChartData): string[] {
        if (!data.datasets) {
          return [];
        }

        return data
          .datasets[0]
          .descriptions[tooltipItem.index as number]
          .descriptionKeywords;
      },
      footer(tooltipItem: Chart.ChartTooltipItem[], data: SpiderChartData): string[] {
        if (!data.datasets) {
          return [];
        }

        return data
          .datasets[0]
          .descriptions[tooltipItem[0].index as number]
          .descriptionText;
      },
    },
  },
};

export default defaultChartOptions;
