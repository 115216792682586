import { gql } from "@apollo/client";

export const createCreditCardRefetchQueries = [
  "ListCurrentCompanyCreditCards",
];

export default gql`
  mutation CreateCreditCard (
    $cardSource: String!,
    $companyId: Int!,
  ) {
    createCreditCard (
      cardSource: $cardSource,
      companyId: $companyId,
    ) {
      id
    }
  }
`;
