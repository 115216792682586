import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Stack } from "@chakra-ui/react";

import CancelSubscriptionIcon from "settings/theme/icons/CancelSubscriptionIcon";
import useAdminImpersonationStore from "components/AdminImpersonation/store";
import useCancelSubscription from "hooks/useCancelSubscription";
import GoBackHeader from "components/PageHeaders/GoBackHeader";
import PageHeader from "components/PageHeaders/PageHeader";
import { useGetJobQuery } from "generated/graphql";
import Loading from "components/Loading";
import Button from "components/Button";

import { ModifySubscriptionParams } from "./types";
import PlanCards from "./PlanCards";

const ModifySubscription: React.FC = () => {
  const [t] = useTranslation();

  const { jobId } = useParams<ModifySubscriptionParams>();

  const impersonatedCompanyId = useAdminImpersonationStore((store) => store.companyId);

  const {
    loading,
    data,
  } = useGetJobQuery({
    variables: {
      companyId: impersonatedCompanyId,
      id: parseInt(jobId),
    },
  });

  const [cancelSubscription, isCancelSubscriptionLoading] = useCancelSubscription();

  const role = data?.getJob?.role;

  const companyId = data?.getJob?.company?.id;

  const plans = role?.plans ?? [];

  const subscription = data?.getJob?.subscription;

  const handleCancelSubscription = useCallback(() => {
    if (!subscription?.id || !role?.name) {
      return;
    }

    cancelSubscription({
      subscriptionId: subscription.id,
      roleName: role.name,
    });
  }, [
    cancelSubscription,
    subscription,
    role,
  ]);

  if (loading) {
    return (
      <Loading />
    );
  }

  const canCancelSubscription = !!(
    subscription?.id
    && !subscription?.endAt
  );

  return (
    <>
      <GoBackHeader
        showTitle={false}
        childrenContainerProps={{
          w: "full",
        }}
      >
        <PageHeader
          title={t("dashboard.modify_subscription.title", {
            role: role?.name,
          })}
        >
          <Button
            rightIcon={(<CancelSubscriptionIcon />)}
            isLoading={isCancelSubscriptionLoading}
            isDisabled={!canCancelSubscription}
            onClick={handleCancelSubscription}
            variant="outline"
            fontSize="xs"
            color="black"
            size="sm"
          >
            {t("buttons.cancel_subscription")}
          </Button>
        </PageHeader>
      </GoBackHeader>

      <Stack
        spacing={4}
        w="full"
        mt={6}
      >
        {
          (loading || !role)
            ? (
              <Loading />
            )
            : (
              <PlanCards
                companyId={companyId as number}
                subscription={subscription}
                plans={plans}
                role={role}
              />
            )
        }
      </Stack>
    </>
  );
};

export default ModifySubscription;
