import { ApplicantResponse } from "generated/graphql";

import { UseFormResponseResult } from "./types";
import useFormResponse from ".";

/**
 * Exposes the previous values already updated in a given applicant form response.
 * If no response has been created yet, `undefined` will be returned.
 */
function useApplicantFormResponse(): UseFormResponseResult<ApplicantResponse> {
  return useFormResponse<ApplicantResponse>();
}

export default useApplicantFormResponse;
