import * as React from "react";
import { IconProps } from "@chakra-ui/icons";
import { createIcon } from "@chakra-ui/react";

const WalletIcon: React.FC<IconProps> = createIcon({
  path: (
    <g
      id="Group_14369"
      data-name="Group 14369"
      transform="translate(-146 -208)"
    >
      <path
        fill="none"
        id="Path_7244"
        data-name="Path 7244"
        d="M0,0H24V24H0Z"
        transform="translate(146 208)"
      />
      <path
        fill="currentColor"
        id="Path_7243"
        data-name="Path 7243"
        d="M20,4H4A1.974,1.974,0,0,0,2.01,5.979L2,17.854a1.983,1.983,0,0,0,2,1.979H20a1.983,1.983,0,0,0,2-1.979V5.979A1.983,1.983,0,0,0,20,4Zm0,13.854H4V11.917H20Zm0-9.9H4V5.979H20Z"
        transform="translate(146 208)"
      />
    </g>
  ),
});

export default WalletIcon;
