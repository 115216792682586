import { theme as chakraUiTheme } from "@chakra-ui/react";

import { ThemeFontSizes } from "./types";

const fontSizes: ThemeFontSizes = {
  ...chakraUiTheme.fontSizes,
  "2xxs": "9pt",
  xxs: "10pt",
  xs: "12pt",
  sm: "14pt",
  md: "16pt",
  lg: "18pt",
  xl: "20pt",
  "2xl": "24pt",
  "3xl": "26pt",
  "4xl": "30pt",
  "5xl": "34pt",
  "6xl": "38pt",
};

export default fontSizes;
