import React from "react";
import { useTranslation } from "react-i18next";

import QuestionCard from "components/Forms/CompanyOnboardingForm/Cards/QuestionCard";

import getFields from "./fields";
import { PersonalInformationProps } from "./types";

const PersonalInformation: React.FC<PersonalInformationProps> = ({
  formType,
}) => {
  const [t] = useTranslation();
  const fields = getFields(formType);

  return (
    <QuestionCard
      title={t("forms.field")}
      fields={fields}
    />
  );
};

export default PersonalInformation;
