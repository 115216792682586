import { useCallback, useEffect, useMemo } from "react";

import getFormResponseId from "utils/forms/getFormResponseId";
import useFormStore from "views/Forms/store";
import {
  useGetApplicantResponseLazyQuery,
  useGetClientResponseLazyQuery,
  ApplicantResponse,
  ClientResponse,
  FormTypeEnum,
} from "generated/graphql";

import { UseFormResponseResult } from "./types";

/**
 * Exposes the previous values already updated in a given form response for the current form type.
 * If no response has been created yet, `undefined` will be returned.
 *
 * If `overrideFormType` is not provided as an argument, it will fall back to the current form type
 * located in `useFormStore`, which requires the component to call the `useFormHandler` hook.
 */
function useFormResponse<QueryResult = ApplicantResponse | ClientResponse>(
  overrideFormType?: FormTypeEnum,
): UseFormResponseResult<QueryResult> {
  const storeFormType = useFormStore((store) => store.form?.formType);

  const shortCode = useFormStore((store) => store?.shortCode);

  const formType = overrideFormType ?? storeFormType;

  const responseId = getFormResponseId(shortCode);

  const [loadApplicantResponse, {
    loading: loadingApplicantResponse,
    called: calledApplicantResponse,
    data: dataApplicantResponse,
  }] = useGetApplicantResponseLazyQuery({
    fetchPolicy: "network-only",
  });

  const [loadClientResponse, {
    loading: loadingClientResponse,
    called: calledClientResponse,
    data: dataClientResponse,
  }] = useGetClientResponseLazyQuery({
    fetchPolicy: "network-only",
  });

  const onLoadResponses = useCallback(() => {
    if (responseId) {
      if (formType === FormTypeEnum.Client) {
        loadClientResponse({
          variables: {
            id: responseId,
          },
        });
        return;
      }

      loadApplicantResponse({
        variables: {
          id: responseId,
        },
      });
    }
  }, [
    formType,
    loadApplicantResponse,
    loadClientResponse,
    responseId,
  ]);

  useEffect(() => {
    if (
      calledApplicantResponse
      || calledClientResponse
      || !responseId
    ) {
      return;
    }

    onLoadResponses();
  }, [
    calledApplicantResponse,
    calledClientResponse,
    loadApplicantResponse,
    loadClientResponse,
    responseId,
    formType,
    onLoadResponses,
  ]);

  const payload = useMemo(() => {
    const getIsLoading = (loading: boolean): boolean => {
      if (!responseId) {
        return false;
      }

      return loading;
    };

    if (formType === FormTypeEnum.Client) {
      return [
        dataClientResponse?.getClientResponse,
        getIsLoading(loadingClientResponse || !calledClientResponse),
      ];
    }

    return [
      dataApplicantResponse?.getApplicantResponse,
      getIsLoading(loadingApplicantResponse || !calledApplicantResponse),
    ];
  }, [
    calledApplicantResponse,
    calledClientResponse,
    dataApplicantResponse,
    dataClientResponse,
    loadingApplicantResponse,
    loadingClientResponse,
    responseId,
    formType,
  ]);

  return payload as UseFormResponseResult<QueryResult>;
}

export default useFormResponse;
