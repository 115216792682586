import React from "react";
import {
  HStack,
  VStack,
  Flex,
  List,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import usePhotoModal from "hooks/usePhotoModal";
import Image from "components/Images/Image";
import ApplicantDetails from "components/Tracking/ClientMatchCard/ClientMatchList/ApplicantDetails";
import { ApplicantResumeProps } from "components/Tracking/ClientMatchCard/types";
import Button from "components/Button";
import useNavigate from "hooks/useNavigate";
import { APPLICANT_PROFILE_PAGE_LOCATION } from "routes/locations";
import getAvailabilityText from "utils/getAvailabilityText";
import { formatDate, monthDayYear } from "utils/dateFormats";
import StarRatings from "components/FormComponents/StarRatings";
import { noPrintCss } from "styles/css";

import { ApplicantResumeItem } from "./ApplicantResumeComponents";
import formatApplicantAddress from "./formatApplicantAddress";

const ApplicantResume: React.FC<ApplicantResumeProps> = ({
  applicant,
}) => {
  const [t] = useTranslation();

  const navigate = useNavigate();

  const showPhotoModal = usePhotoModal();

  return (
    <Flex direction="column" height="full">
      <VStack
        height="full"
        align="start"
        spacing={4}
      >
        <ApplicantDetails
          applicant={applicant}
          clickable={false}
          textProps={{
            fontFamily: "bold",
            fontSize: "lg",
          }}
        />

        <HStack w="full" mb={4}>
          {
            (applicant?.picturesUrl ?? []).map(pictureUrl => (
              <Button
                display="flex"
                height="full"
                padding={0}
                variant="unstyled"
                borderRadius="lg"
                onClick={showPhotoModal(pictureUrl)}
              >
                <Image
                  flex={1}
                  height={120}
                  src={pictureUrl}
                  width="auto !important"
                  objectFit="contain"
                />
              </Button>
            ))
          }
        </HStack>

        <List width="full">
          <VStack spacing={2} align="start">
            <ApplicantResumeItem
              label={`${t("filters.availability")}: `}
              value={getAvailabilityText(applicant?.isFullTime)}
            />

            <ApplicantResumeItem
              label={`${t("client_profile.available_start_date")}: `}
              value={formatDate(applicant?.availableAt, monthDayYear)}
            />

            <ApplicantResumeItem
              label={`${t("filters.location")}: `}
              value={formatApplicantAddress(applicant)}
            />

            <ApplicantResumeItem
              label={`${t("applicant_profile.recruiter_rating")}: `}
              flexDirection="column"
            >
              <Flex mb={2}>
                <StarRatings
                  count={applicant?.recruiterRating}
                  value={applicant?.recruiterRating}
                  clickable={false}
                  showIndicators={false}
                />
              </Flex>
            </ApplicantResumeItem>

            <ApplicantResumeItem
              label={`${t("applicant_profile.notes")}: `}
              value={applicant?.recruiterNotes}
            />
          </VStack>
        </List>
      </VStack>

      <Button
        onClick={navigate(APPLICANT_PROFILE_PAGE_LOCATION.toUrl({
          id: applicant?.id,
        }))}
        css={noPrintCss}
        marginTop="auto"
        fontSize="xs"
        width="100%"
      >
        {t("client_profile.view_applicant_profile")}
      </Button>
    </Flex>
  );
};

export default ApplicantResume;
