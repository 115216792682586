
import {
  Job,
  Role,
  Company,
} from "../generated/graphql";

interface JobsAndRoles {
  jobs: Job[] | [];
  roles: Role[] | [];
}

const getJobsAndRoles = (company: Company): JobsAndRoles => {
  const jobs = (
    company?.jobs
      ? company.jobs
      : []
  );

  const roles = (
    company?.roles
      ? company?.roles
      : []
  );

  return { jobs, roles };
};

export default getJobsAndRoles;
