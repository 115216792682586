import React from "react";

import { clientPersonalInformationSchema } from "settings/yup/schemas/form/personalInformationSchema";
import Loading from "components/Loading";

import useApplicantFormResponse from "../hooks/useFormResponse/useApplicantFormResponse";
import PersonalInformationForm from "./PersonalInformationForm";

const ClientPersonalInformation: React.FC = () => {
  const [formResponse, loading] = useApplicantFormResponse();

  if (loading) {
    return (
      <Loading />
    );
  }

  return (
    <PersonalInformationForm
      schema={clientPersonalInformationSchema}
      formResponse={formResponse}
      isAddressRequired
    />
  );
};

export default ClientPersonalInformation;
