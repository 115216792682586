import * as React from "react";
import { Flex, Text } from "@chakra-ui/react";
import { Trans } from "react-i18next";

import UploadIcon from "settings/theme/icons/UploadIcon";

import { FileDropBoxProps } from "../types";

const FileDropBox: React.FC<FileDropBoxProps> = ({
  boxSize,
  color,
}) => (
  <Flex
    direction="column"
    alignItems="center"
  >
    <UploadIcon boxSize={20} />

    <Text
      as="span"
      fontSize={boxSize}
      textAlign="center"
      fontFamily="bold"
      color="gray.400"
    >
      <Trans
        i18nKey="actions.drag_and_drop_or_browse"
        components={{
          HighlightText: (
            <Text color={color} />
          ),
        }}
      />
    </Text>
  </Flex>
);

export default FileDropBox;
