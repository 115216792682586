import { clearInputPlaceholder } from "components/FormComponents/Input/clearInputPlaceholder";
import { STATE_LENGTH } from "constants/masks";

/**
 * Validates one state abbreviation field.
 * @param {*} value The value.
 */
export const isValidStateAbbreviation = (value: string): boolean => {
  const parsedStateAbbreviation = clearInputPlaceholder(value ?? "");

  return (
    !!parsedStateAbbreviation
    && parsedStateAbbreviation.length === STATE_LENGTH
  );
};
