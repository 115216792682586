import { useToast as chakraUseToast } from "@chakra-ui/react";
import { useCallback, useMemo, useRef } from "react";

import {
  UseToastDefaultOptions,
  UseToastOptions,
  BaseToastResult,
  UseToastResult,
} from "./types";

export const toastDuration = {
  SHORT: 4000,
  LONG: 10000,
  INDEFINITE: null,
};

const useToastDefaultOptions: UseToastDefaultOptions = {
  position: "bottom-left",
  duration: "SHORT",
  isClosable: true,
};

/**
 * Exposes the Chakra UI Toast API while keeping default settings.
 */
const useToast = (): UseToastResult => {
  const baseToast = chakraUseToast();

  const baseToastRef = useRef<BaseToastResult>();

  baseToastRef.current = baseToast;

  const showToast = useCallback((options: UseToastOptions) => {
    const durationKey = options.duration ?? useToastDefaultOptions.duration;

    const toastOptions = {
      ...options,
      isClosable: options?.isClosable ?? useToastDefaultOptions.isClosable,
      position: options?.position ?? useToastDefaultOptions.position,
      duration: toastDuration[durationKey],
    };

    return baseToastRef.current?.(toastOptions);
  }, []);

  const payload = useMemo<UseToastResult>(
    () => [
      showToast,
      {
        close: baseToastRef.current?.close as BaseToastResult["close"],
        closeAll: baseToastRef.current?.closeAll as BaseToastResult["closeAll"],
        update: baseToastRef.current?.update as BaseToastResult["update"],
        isActive: baseToastRef.current?.isActive as BaseToastResult["isActive"],
      },
    ],
    [
      showToast,
    ],
  );

  return payload;
};

export default useToast;
