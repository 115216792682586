import { OperationType } from "generated/graphql";
import i18n from "translations/i18n";

import AutoRenewalConfirmationModalText from "./Modal/AutoRenewalConfirmationModalText";
import DowngradeConfirmationModalText from "./Modal/DowngradeConfirmationModalText";
import { ModifySubscriptionModalProps } from "./types";

interface ModalComponent {
  text: React.FC<ModifySubscriptionModalProps>;
  showDeclineButton: boolean;
  declineText?: string;
  confirmText: string;
  title: string;
}

const autoRenewalModal: ModalComponent = {
  text: AutoRenewalConfirmationModalText,
  title: i18n.t("modify_subscription_modal.subscription_auto_renewal"),
  confirmText: i18n.t("buttons.agree_and_pay"),
  declineText: i18n.t("buttons.cancel"),
  showDeclineButton: true,
};

const mapModalComponent: Record<OperationType, ModalComponent> = {
  [OperationType.Downgrade]: {
    text: DowngradeConfirmationModalText,
    title: i18n.t("modify_subscription_modal.subscription_downgrade"),
    confirmText: i18n.t("buttons.ok"),
    showDeclineButton: false,
  },
  [OperationType.Upgrade]: autoRenewalModal,
  [OperationType.New]: autoRenewalModal,
};

/**
 * Returns the modal data to be rendered that handles starting the payment flow,
 * according to a given operation type returned by the modify subscription simulation.
 *
 * @param type The operation type.
 */
const getModal = (type: OperationType): ModalComponent => mapModalComponent[type];

export default getModal;
