import React from "react";

import Loading from "components/Loading";

import useClientFormResponse from "../hooks/useFormResponse/useClientFormResponse";
import RespondedByForm from "./RespondedByForm";

const RespondedBy: React.FC = () => {
  const [formResponse, loading] = useClientFormResponse();

  if (loading) {
    return (
      <Loading />
    );
  }

  return (
    <RespondedByForm formResponse={formResponse} />
  );
};

export default RespondedBy;
