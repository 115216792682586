import React from "react";
import { Stack, Text } from "@chakra-ui/react";

import useCompanyOnboardingFormStore from "components/Forms/CompanyOnboardingForm/store";
import { FormStepId } from "constants/formSteps";

import getFormComponent from "./getFormComponent";
import EditExtraSectionMinimumNumber from "./EditExtraSectionMinimumNumber";
import { ConfigureStepCardProps } from "./types";

const ConfigureStepCard: React.FC<ConfigureStepCardProps> = ({
  formType,
}) => {
  const step = useCompanyOnboardingFormStore((store) => store.getStep());

  const FormComponent = getFormComponent(step?.id as FormStepId, formType);

  return (
    <Stack spacing={3}>
      <Stack
        alignItems="center"
        spacing={6}
        isInline
      >
        {
          step && (
            <Text
              fontFamily="heading"
              fontSize="xs"
            >
              {step.title}
            </Text>
          )
        }

        <EditExtraSectionMinimumNumber
          extraSection={step?.extraSection}
        />
      </Stack>

      {
        FormComponent && (
          <FormComponent />
        )
      }
    </Stack>
  );
};

export default ConfigureStepCard;
