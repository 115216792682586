import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import update from "immutability-helper";

import useCompanyOnboardingFormEffects from "components/Forms/CompanyOnboardingForm/hooks/useCompanyOnboardingFormEffects";
import useCompanyOnboardingForm from "components/Forms/CompanyOnboardingForm/hooks/useCompanyOnboardingForm";
import sourceSchema, { SourceSchema } from "settings/yup/schemas/onboarding/sourceSchema";
import useCompanyOnboardingFormStore from "components/Forms/CompanyOnboardingForm/store";
import QuestionCard from "components/Forms/CompanyOnboardingForm/Cards/QuestionCard";
import AddMoreOptionsModal from "components/Modal/AddMoreOptionsModal";
import PlusIcon from "settings/theme/icons/PlusIcon";
import { useModal } from "contexts/modal";
import Button from "components/Button";

import { makeOptionFields, textFields } from "./fields";

const Source: React.FC = () => {
  const [showModal] = useModal();
  const [t] = useTranslation();

  const defaultReferrerOptions = useCompanyOnboardingFormStore((store) => (
    store.form?.referrerOptions
  ));

  const form = useCompanyOnboardingForm<SourceSchema>({
    schema: sourceSchema,
    formOptions: {
      defaultValues: {
        referrerOptions: defaultReferrerOptions ?? [],
      },
    },
  });

  const {
    register,
    setValue,
    watch,
  } = form;

  useCompanyOnboardingFormEffects({
    fields: ["referrerOptions"],
    form,
  });

  const referrerOptions = watch("referrerOptions");

  const removeReferrerOption = useCallback((referrerOption: string) => () => {
    const index = referrerOptions.findIndex((item) => item === referrerOption);

    const newValue = update(referrerOptions, {
      $splice: [[index, 1]],
    });

    setValue("referrerOptions", newValue, {
      shouldValidate: true,
      shouldDirty: true,
    });
  }, [
    referrerOptions,
    setValue,
  ]);

  const addReferrerOptions = useCallback(() => {
    showModal({
      title: t("buttons.add_more_options"),
      component: AddMoreOptionsModal,
      componentProps: {
        onFinish: (newReferrerOptions: string[]) => {
          const newValue = update(referrerOptions, {
            $push: newReferrerOptions,
          });

          setValue("referrerOptions", newValue, {
            shouldValidate: true,
            shouldDirty: true,
          });
        },
      },
    });
  }, [
    referrerOptions,
    showModal,
    setValue,
    t,
  ]);

  const optionFields = useMemo(() => (
    makeOptionFields({
      removeItem: removeReferrerOption,
      referrerOptions,
    })
  ), [
    removeReferrerOption,
    referrerOptions,
  ]);

  register({
    name: "referrerOptions",
  });

  return (
    <>
      <QuestionCard
        title={t("question_types.single_choice_index", { index: 1 })}
        fields={optionFields}
      >
        <Button
          onClick={addReferrerOptions}
          rightIcon={<PlusIcon />}
          variant="outline"
          fontSize="xs"
          width="xs"
          size="sm"
        >
          {t("buttons.add_more_options")}
        </Button>
      </QuestionCard>

      <QuestionCard
        title={t("question_types.free_text_index", { index: 2 })}
        fields={textFields}
      />
    </>
  );
};

export default Source;
