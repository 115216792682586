import { useCallback, useMemo } from "react";

import useFormLink from "hooks/useFormLink";

import { UseViewFormOptions, UseViewFormResult } from "./types";

/**
 * Exposes a function that mutates for `getOrCreateFormLink` and opens the form in a new tab.
 * The mutation loading state is also exposed.
 * In case the mutation fails, a toast will be shown with the error.
 */
const useViewForm = (): UseViewFormResult => {
  const [getOrCreateFormLink, loading] = useFormLink();

  const viewForm = useCallback(async (options: UseViewFormOptions) => {
    const formLink = await getOrCreateFormLink({
      formType: options.formType,
      formId: options.formId,
    });

    if (!formLink?.url) {
      return;
    }

    window.open(formLink.url, "_blank");
  }, [
    getOrCreateFormLink,
  ]);

  const payload = useMemo<UseViewFormResult>(() => [
    viewForm,
    loading,
  ], [
    viewForm,
    loading,
  ]);

  return payload;
};

export default useViewForm;
