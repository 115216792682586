import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

import useSelectLandingPageStore from "components/Forms/SelectLandingPageTemplateForm/store";
import useFilter, { filters } from "hooks/useFilter";
import useWizardStore from "components/Wizard/store";
import Button from "components/Button";

const SelectLandingPageHeader: React.FC = () => {
  const [, setLandingPageLayoutLabel] = useFilter(filters.landingPageLayoutLabel);
  const [t] = useTranslation();

  const navigateToNextStep = useWizardStore((store) => store.navigateToNextStep);

  const layoutLabel = useSelectLandingPageStore((store) => store.layoutLabel);

  const handleOnClick = useCallback(() => {
    setLandingPageLayoutLabel(layoutLabel);

    navigateToNextStep();
  }, [
    setLandingPageLayoutLabel,
    navigateToNextStep,
    layoutLabel,
  ]);

  return (
    <Button
      onClick={handleOnClick}
      fontSize="sm"
      size="sm"
    >
      {
        layoutLabel
          ? t("buttons.save_and_next")
          : t("buttons.skip_and_next")
      }
    </Button>
  );
};

export default SelectLandingPageHeader;
