import { AssertingTestOptions } from "yup";

import { TargetSchema } from "settings/yup/schemas/assessment/sendAssessmentSchema";

/**
 * Validates the targets field for send assessments and share profile modal.
 * There should be at least one item with a truthy value for the array to be valid.
 */
const truthyTargetsTest: AssertingTestOptions<string> = ({
  name: "truthyTargetsTest",
  exclusive: false,
  test (value): value is string {
    const targets = value as TargetSchema[];

    /**
     * The form is only invalid when there is only one item and this item is empty.
     * If the first item is not empty or there are more items, the form should be valid.
     *
     * HACK: There seems to be a bug in `react-hook-form@6.10.1` that invalidates the
     * first validation run by yup right after appending one item to the array,
     * since the values that come to the validation function are not correct.
     *
     * To work around this issue the logic here only checks for the first item.
     *
     * Details:
     * https://gitlab.com/jungsoft/milimatch/-/merge_requests/280#note_443396441
     *
     * TODO: Check if a newer version of `react-hook-form` fixes this issue.
     */
    const isInvalid = !!(
      Array.isArray(targets)
      && targets.length === 1
      && !targets[0]?.value
    );

    return !isInvalid;
  },
});

export default truthyTargetsTest;
