import React, { useEffect, useCallback } from "react";
import {
  matchPath,
  useHistory,
  useParams,
  Redirect,
} from "react-router-dom";

import {
  MemberOccupationEnum,
  UserRoleEnum,
} from "generated/graphql";
import {
  DASHBOARD_MODIFY_SUBSCRIPTION_PAGE_PATH,
  NOT_MATCHED,
} from "routes";
import { DASHBOARD_PAGE_LOCATION } from "routes/locations";
import Loading from "components/Loading";
import appRoutes from "routes/appRoutes";
import useHeaderUser from "hooks/useHeaderUser";
import { useCurrentUser } from "contexts/currentUser";

import makeDashboardNavbarButtons from "./dashboardNavbarButtons";
import { DashboardPage, DashboardParams } from "./types";
import DashboardContent from "./DashboardContent";
import DashboardNavbar from "./DashboardNavbar";

const Dashboard: React.FC = () => {
  const { page } = useParams<DashboardParams>();

  const history = useHistory();

  const setActivePage = useCallback((newPage: DashboardPage | string) => {
    history.push(DASHBOARD_PAGE_LOCATION.toUrl({
      page: newPage,
    }));
  }, [
    history,
  ]);

  const setExternalPage = useCallback((externalPage: string) => {
    window.open(externalPage, "_blank");
  }, []);

  const {
    userAsMember,
    loading: loadingUser,
  } = useHeaderUser();

  const [currentUser] = useCurrentUser();

  const isManagerOrAdmin = (
    userAsMember?.occupation === MemberOccupationEnum.Manager
    || (currentUser?.role === UserRoleEnum.Admin)
  );

  const buttons = makeDashboardNavbarButtons(isManagerOrAdmin);

  const appRoute = appRoutes.find((route) => matchPath(window.location.pathname, route));

  const isModifyingSubscription = appRoute?.path === DASHBOARD_MODIFY_SUBSCRIPTION_PAGE_PATH;

  useEffect(() => {
    if (isModifyingSubscription || page || buttons.length <= 0) {
      return;
    }

    const defaultPage = buttons.find((item) => !item.isDisabled);

    if (defaultPage) {
      setActivePage(defaultPage.page);
    }
  }, [
    isModifyingSubscription,
    setActivePage,
    buttons,
    page,
  ]);

  if (loadingUser) {
    return (
      <Loading />
    );
  }

  if (!userAsMember) {
    return (
      <Redirect
        to={{
          pathname: NOT_MATCHED,
          state: { from: page },
        }}
      />
    );
  }

  return (
    <>
      <DashboardNavbar
        isModifyingSubscription={isModifyingSubscription}
        setActivePage={setActivePage}
        setExternalPage={setExternalPage}
        activePage={page}
        buttons={buttons}
      />

      <DashboardContent
        isModifyingSubscription={isModifyingSubscription}
        isManagerOrAdmin={isManagerOrAdmin}
        activePage={page}
        buttons={buttons}
      />
    </>
  );
};

export default Dashboard;
