import { CSSObject } from "@emotion/react";
import get from "lodash.get";

import theme from "settings/theme";
import { ThemeColors } from "settings/theme/types";

export const makeStarContainerCss = (
  colorScheme?: keyof ThemeColors,
): CSSObject => ({
  ":hover": {
    svg: {
      color: get(theme.colors, `${colorScheme}.50`),
    },
    p: {
      color: "white",
    },
  },
});
