import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Stack, Text } from "@chakra-ui/react";

import ConfirmationModal from "components/Modal/ConfirmationModal";
import useWizardStore from "components/Wizard/store";
import useFormStore from "views/Forms/store";
import { useModal } from "contexts/modal";
import Button from "components/Button";
import useToast from "hooks/useToast";

import { FormWizardFooterProps } from "./types";

const FormWizardFooter: React.FC<FormWizardFooterProps> = ({
  continueButtonDisabled,
  goBackButtonDisabled,
  submitButtonDisabled,
  showContinueButton,
  showGoBackButton,
  showSubmitButton,
  colorScheme,
  isLoading,
  onContinue,
  onSubmit,
}) => {
  const [showModal] = useModal();
  const [showToast] = useToast();
  const [t] = useTranslation();

  const {
    navigateToPreviousStep,
    navigateToNextStep,
    getIsFirstStep,
    getIsLastStep,
    getIsLoaded,
  } = useWizardStore((store) => ({
    navigateToPreviousStep: store.navigateToPreviousStep,
    navigateToNextStep: store.navigateToNextStep,
    getIsFirstStep: store.getIsFirstStep,
    getIsLastStep: store.getIsLastStep,
    getIsLoaded: store.getIsLoaded,
    stepIndex: store.stepIndex,
  }));

  const {
    isDirty,
    trigger,
  } = useFormStore((store) => ({
    isDirty: store.isDirty,
    trigger: store.trigger,
  }));

  const handleGoToNextStep = useCallback(() => {
    onContinue?.();

    navigateToNextStep();
  }, [
    navigateToNextStep,
    onContinue,
  ]);

  const handleValidate = useCallback((callback?: () => void) => {
    if (!trigger) {
      callback?.();
      return;
    }

    trigger()
      .then((isValid) => {
        if (!isValid) {
          showToast({
            status: "error",
            title: t("errors.please_fill_all_fields"),
          });

          return;
        }

        callback?.();
      })
      .catch(() => {
        showToast({
          status: "error",
          title: t("errors.please_fill_all_fields"),
        });
      });
  }, [
    showToast,
    trigger,
    t,
  ]);

  const handleOnSubmit = useCallback(() => {
    handleValidate(onSubmit);
  }, [
    handleValidate,
    onSubmit,
  ]);

  const handleOnContinue = useCallback(() => {
    handleValidate(handleGoToNextStep);
  }, [
    handleGoToNextStep,
    handleValidate,
  ]);

  const handleGoBack = useCallback(() => {
    if (!isDirty) {
      navigateToPreviousStep();
      return;
    }

    showModal({
      size: "xl",
      title: t("buttons.go_back"),
      component: ConfirmationModal,
      componentProps: {
        onConfirm: navigateToPreviousStep,
        text: (
          <>
            <Text fontSize="xs">
              {t("forms.go_back_warning")}
            </Text>

            <Text fontSize="xs">
              {t("actions.do_you_want_to_go_back_and_discard_the_current_step")}
            </Text>
          </>
        ),
      },
    });
  }, [
    navigateToPreviousStep,
    showModal,
    isDirty,
    t,
  ]);

  const isFirstStep = getIsFirstStep();
  const isLastStep = getIsLastStep();
  const isLoaded = getIsLoaded();

  const shouldShowContinueButton = (
    showContinueButton
    && isLoaded
    && !isLastStep
  );

  const shouldShowGoBackButton = (
    showGoBackButton
    && isLoaded
    && !isFirstStep
  );

  const shouldShowSubmitButton = (
    showSubmitButton
    && isLoaded
    && isLastStep
  );

  return (
    <Stack
      justifyContent="flex-end"
      alignItems="center"
      isInline
      w="full"
    >
      {
        shouldShowGoBackButton && (
          <Button
            isDisabled={isLoading || goBackButtonDisabled}
            bgColor={`${colorScheme}.100`}
            onClick={handleGoBack}
            variant="outline"
            fontSize="sm"
            size="sm"
          >
            {t("buttons.go_back")}
          </Button>
        )
      }

      {
        shouldShowContinueButton && (
          <Button
            isDisabled={continueButtonDisabled}
            bgColor={`${colorScheme}.100`}
            onClick={handleOnContinue}
            isLoading={isLoading}
            fontSize="sm"
            size="sm"
          >
            {t("buttons.continue")}
          </Button>
        )
      }

      {
        shouldShowSubmitButton && (
          <Button
            isDisabled={submitButtonDisabled}
            bgColor={`${colorScheme}.100`}
            onClick={handleOnSubmit}
            isLoading={isLoading}
            fontSize="sm"
            size="sm"
          >
            {t("buttons.save_and_preview")}
          </Button>
        )
      }
    </Stack>
  );
};

export default FormWizardFooter;
