import React from "react";
import { useTranslation } from "react-i18next";
import numeral from "numeral";
import {
  Stack,
  Text,
} from "@chakra-ui/react";

import { formatDate, monthDayYear } from "utils/dateFormats";
import { BillingType } from "generated/graphql";

import { SubscriptionPriceProps } from "../types";

const SubscriptionPrice: React.FC<SubscriptionPriceProps> = ({
  job,
}) => {
  const [t] = useTranslation();

  const activeDiscount = job?.subscription?.plan?.activeDiscount;

  const billingType = job?.subscription?.billingType;

  const price = ({
    [BillingType.Monthly]: job?.subscription?.plan?.monthlyPrice?.formatted,
    [BillingType.Annual]: job?.subscription?.plan?.annualPrice?.formatted,
  })[job?.subscription?.billingType] || t("fallbacks.free_subscription_plan.price");

  if (activeDiscount) {
    const discountedPrice = ({
      [BillingType.Monthly]: activeDiscount.discountedMonthlyPrice,
      [BillingType.Annual]: activeDiscount.discountedAnnualPrice,
    })[billingType];

    return (
      <Stack
        alignItems="center"
        isInline
      >
        <Text
          textDecoration="line-through"
          color="gray.400"
          fontSize="xxs"
          mx={1}
        >
          {price}
        </Text>

        <Text
          color="status.success"
          fontFamily="extrabold"
          whiteSpace="nowrap"
          fontSize="xs"
        >
          {`${discountedPrice.formatted} (-${numeral(activeDiscount.discount.rate).format("0%")})`}
        </Text>

        {
          activeDiscount.discount.endAt && (
            <Text fontSize="xxs">
              {" - "}

              {t("dashboard.subscription.discount_valid_until", {
                date: formatDate(activeDiscount.discount.endAt, monthDayYear),
              })}
            </Text>
          )
        }
      </Stack>
    );
  }

  return (
    <Text fontSize="xs">
      {price}
    </Text>
  );
};

export default SubscriptionPrice;
