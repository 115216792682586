import queryString, { ParsedQuery } from "query-string";

interface InvitationQuery {
  token: string;
  email: string;
}

type ParsedInvitation = InvitationQuery | ParsedQuery;

const parseSignUpInvitationQuery = (query: string): ParsedInvitation => (
  queryString.parse(query)
);

export default parseSignUpInvitationQuery;
