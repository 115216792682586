import React from "react";
import { FormHelperText } from "@chakra-ui/react";

import { FieldErrorMessageProps } from "./types";

const FieldErrorMessage: React.FC<FieldErrorMessageProps> = ({
  textAlign = "start",
  validationMessage,
  error,
  ...rest
}) => {
  if (!error && !validationMessage) {
    return null;
  }

  return (
    <FormHelperText
      color={error ? "status.error" : "gray.400"}
      textAlign={textAlign}
      fontSize="xxs"
      {...rest}
    >
      {error ?? validationMessage}
    </FormHelperText>
  );
};

export default FieldErrorMessage;
