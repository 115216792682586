import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  useFormContext,
  useFieldArray,
  Controller,
} from "react-hook-form";
import {
  IconButton,
  Stack,
} from "@chakra-ui/react";

import { SendAssessmentTypeEnum, SendAssessmentSchema } from "settings/yup/schemas/assessment/sendAssessmentSchema";
import FieldTitle from "components/FormComponents/FieldTitle";
import { handleOnKeyPress } from "utils/handleOnKeyPress";
import DeleteIcon from "settings/theme/icons/DeleteIcon";
import Select from "components/FormComponents/Select";
import PlusIcon from "settings/theme/icons/PlusIcon";
import Input from "components/FormComponents/Input";
import { FormTypeEnum } from "generated/graphql";
import Button from "components/Button";

import { ModalBodyFormProps } from "./types";

const ModalBodyForm: React.FC<ModalBodyFormProps> = ({
  formType,
  options,
}) => {
  const [t] = useTranslation();

  const {
    formState,
    control,
    errors,
    watch,
  } = useFormContext<SendAssessmentSchema>();

  const {
    fields,
    append,
    remove,
  } = useFieldArray({
    name: "targets",
    control,
  });

  const appendItem = useCallback(() => {
    if (!formState.isValid) {
      return;
    }

    append({
      value: "",
    });
  }, [
    formState.isValid,
    append,
  ]);

  const removeItem = useCallback((index: number) => () => {
    remove(index);
  }, [remove]);

  /**
   * When the field array length changes, we automatically get
   * the next input & focus on it to improve the experience.
   */
  useEffect(() => {
    const lastFieldIndex = fields.length - 1;

    const inputElement = document.getElementById(`target-${lastFieldIndex}`);

    inputElement?.focus?.();
  }, [
    fields.length,
  ]);

  const type = watch("type");

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const InputComponent: React.FC<any> = ({
    [SendAssessmentTypeEnum.Email]: Input,
  })[type];

  const placeholder = ({
    [SendAssessmentTypeEnum.Email]: t("fallbacks.enter_email_address"),
  })[type];

  const addButtonText = ({
    [SendAssessmentTypeEnum.Email]: t("buttons.add_email_address"),
  })[type];

  const roleTitle = ({
    [FormTypeEnum.Client]: t("send_assessment.offered_service"),
    [FormTypeEnum.ShortApplication]: t("send_assessment.role"),
    [FormTypeEnum.Application]: t("send_assessment.role"),
  })[formType];

  return (
    <Stack
      spacing={4}
      py={6}
    >
      <Controller
        as={Select}
        name="role"
        control={control}
        errors={errors}
        title={`${roleTitle}*`}
        options={options}
        defaultValue={options?.[0]}
      />

      <FieldTitle mb={0}>
        {`${t("buttons.send_to")}*`}
      </FieldTitle>

      <Stack
        overflowY="scroll"
        overflowX="hidden"
        maxH={160}
        height={160}
      >
        {
          fields.map((field, index) => {
            const canRemove = index > 0;

            return (
              <Stack
                alignItems="center"
                key={field.id}
                isInline
              >
                <Controller
                  as={InputComponent}
                  name={`targets[${index}].value`}
                  id={`target-${index}`}
                  control={control}
                  errors={errors}
                  defaultValue=""
                  placeholder={placeholder}
                  showErrorMessage={false}
                  onKeyDown={handleOnKeyPress(appendItem)}
                />

                {
                  canRemove && (
                    <IconButton
                      aria-label={t("buttons.remove")}
                      onClick={removeItem(index)}
                      icon={<DeleteIcon />}
                    />
                  )
                }
              </Stack>
            );
          })
        }
      </Stack>

      <Stack
        alignItems="flex-end"
        mt={2}
      >
        <Button
          isDisabled={!formState.isValid}
          rightIcon={<PlusIcon />}
          onClick={appendItem}
          variant="outline"
          width="xxs"
          size="sm"
        >
          {addButtonText}
        </Button>
      </Stack>
    </Stack>
  );
};

export default ModalBodyForm;
