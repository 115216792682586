import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ModalFooter,
  ModalBody,
  Stack,
} from "@chakra-ui/react";

import {
  useCreateJobMutation,
} from "generated/graphql";
import { createJobRefetchQueries } from "graphql/mutations/createJobMutation";
import { SelectOption } from "components/FormComponents/Select/types";
import { onMutationSuccess, onQueryError } from "utils/queryHandlers";
import Select from "components/FormComponents/Select";
import Button from "components/Button";
import useToast from "hooks/useToast";

import parseRoleOptions from "./parseRoleOptions";
import { CreateJobModalProps } from "../types";

const CreateJobModal: React.FC<CreateJobModalProps> = ({
  componentProps: {
    existingJobs,
    companyId,
    availableRoles,
  },
  hideModal,
}) => {
  const [showToast] = useToast();
  const [t] = useTranslation();

  const [createJob, {
    loading: createJobLoading,
  }] = useCreateJobMutation({
    refetchQueries: createJobRefetchQueries,
    awaitRefetchQueries: true,
  });

  const [selectedRole, setSelectedRole] = useState<SelectOption>();

  const options = useMemo(() => (
    parseRoleOptions(availableRoles, existingJobs)
  ), [
    existingJobs,
    availableRoles,
  ]);

  const handleFinish = useCallback(() => {
    createJob({
      variables: {
        params: {
          roleId: selectedRole?.value as number,
          companyId,
        },
      },
    })
      .then(() => {
        onMutationSuccess(t("dashboard.subscription.role_hired_successfully"), showToast);
        hideModal();
      })
      .catch((error) => {
        onQueryError(error, showToast);
      });
  }, [
    selectedRole,
    companyId,
    createJob,
    hideModal,
    showToast,
    t,
  ]);

  return (
    <>
      <ModalBody>
        <Stack
          spacing={4}
          py={6}
        >
          {
            options && (
              <Select
                title={`${t("filters.role")}*`}
                onChange={setSelectedRole}
                value={selectedRole}
                options={options}
              />
            )
          }
        </Stack>
      </ModalBody>

      <ModalFooter>
        <Button
          isLoading={createJobLoading}
          isDisabled={!selectedRole}
          onClick={handleFinish}
          fontSize="sm"
          w="full"
        >
          {t("buttons.add")}
        </Button>
      </ModalFooter>
    </>
  );
};

export default CreateJobModal;
