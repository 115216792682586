import { ProfileType } from "constants/profiles";
import i18n from "translations/i18n";

/**
 * Returns a modal title according to a given profile type.
 * @param profileType The profile type.
 */
const getModalTitle = (profileType: ProfileType): string => ({
  [ProfileType.Applicant]: i18n.t("share_profile.share_applicant_profile"),
  [ProfileType.Client]: i18n.t("share_profile.share_client_profile"),
})[profileType];

export default getModalTitle;
