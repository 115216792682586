import React from "react";
import { Wrap } from "@chakra-ui/react";

import { dashboardWrapCss } from "views/Dashboard/DashboardContent/Pages/Dashboard/styles";
import WrapItem from "components/WrapItem";

import { SubscriptionCardsProps } from "../types";
import SubscriptionCard from "./SubscriptionCard";

const SubscriptionCards: React.FC<SubscriptionCardsProps> = ({
  addTokens,
  jobs,
}) => (
  <Wrap
    css={dashboardWrapCss}
    spacing={5}
  >
    {
      jobs.map((job, index) => (
        <WrapItem>
          <SubscriptionCard
            addTokens={addTokens}
            currentIndex={index}
            key={job.id}
            job={job}
          />
        </WrapItem>
      ))
    }
  </Wrap>
);

export default SubscriptionCards;
