import { ShareProfileTypeEnum } from "settings/yup/schemas/profile/shareProfileSchema";
import i18n from "translations/i18n";

import { ShareProfileTabItem } from "../types";
import ModalBodyForm from "./ModalBodyForm";

/**
 * Defines tabs for the share profile modal.
 */
const tabs: ShareProfileTabItem[] = [
  {
    title: i18n.t("share_profile.email"),
    component: ModalBodyForm,
    type: ShareProfileTypeEnum.Email,
  },
  {
    title: i18n.t("share_profile.sms"),
    component: ModalBodyForm,
    type: ShareProfileTypeEnum.SMS,
  },
];

export default tabs;
