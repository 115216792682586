import React from "react";
import { Box as ChakraBox, BoxProps as ChakraBoxProps } from "@chakra-ui/react";

const BackgroundBox: React.FC<ChakraBoxProps> = (props) => (
  <ChakraBox
    flexDirection="column"
    bgColor="background"
    minHeight="100vh"
    display="flex"
    {...props}
  />
);

export default BackgroundBox;
