import { ChartOptions } from "chart.js";
import { useEffect, useRef, useMemo } from "react";

import { CHART_ANIMATION_DURATION } from "../defaultChartOptions";
import { UseChartOptionsParams } from "./types";

/**
 * Hook that returns the chart options while handling the animation duration and legend display.
 * @param chartOptions parsed chart options
 */
const useChartOptions = ({
  options,
}: UseChartOptionsParams): ChartOptions => {
  const shouldAnimateRef = useRef(true);

  useEffect(() => {
    setTimeout(() => {
      shouldAnimateRef.current = false;
    }, CHART_ANIMATION_DURATION);
  }, []);

  const animationDuration = (
    shouldAnimateRef.current
      ? CHART_ANIMATION_DURATION
      : 0
  );

  const newChartOptions = useMemo<ChartOptions>(() => (
    {
      plugins: {
        datalabels: {
          display: false,
        },
      },
      ...options,
      animation: {
        ...(options?.animation ?? {}),
        duration: animationDuration,
      },
    }
  ), [
    animationDuration,
    options,
  ]);

  return newChartOptions;
};

export default useChartOptions;
