import React from "react";
import { Wrap } from "@chakra-ui/react";

import {
  Role,
  useGetCurrentUserCompanyQuery,
} from "generated/graphql";
import Loading from "components/Loading";

import RoleCard from "./RoleCard";

const SelectRole: React.FC = () => {
  const { loading, data } = useGetCurrentUserCompanyQuery();

  const roles = (data?.currentUser.member.company.availableRoles ?? []) as Role[];

  if (loading) {
    return (
      <Loading />
    );
  }

  return (
    <Wrap spacing={5}>
      {
        roles.map((role) => (
          <RoleCard
            key={role.id}
            role={role}
          />
        ))
      }
    </Wrap>
  );
};

export default SelectRole;
