import { MemberOccupationEnum } from "generated/graphql";
import i18n from "translations/i18n";

export interface MemberOccupationInfo {
  value: MemberOccupationEnum;
  label: string;
}

/**
 * Defines information about member occupations.
 */
const memberOccupations: Record<MemberOccupationEnum, MemberOccupationInfo> = {
  [MemberOccupationEnum.Manager]: {
    label: i18n.t("occupations.manager"),
    value: MemberOccupationEnum.Manager,
  },
  [MemberOccupationEnum.Recruiter]: {
    label: i18n.t("occupations.recruiter"),
    value: MemberOccupationEnum.Recruiter,
  },
  [MemberOccupationEnum.Scheduler]: {
    label: i18n.t("occupations.scheduler"),
    value: MemberOccupationEnum.Scheduler,
  },
  [MemberOccupationEnum.RecruiterScheduler]: {
    label: i18n.t("occupations.recruiter_scheduler"),
    value: MemberOccupationEnum.RecruiterScheduler,
  },
};

/**
 * Defines member occupations select options.
 */
export const memberOccupationOptions = Object.keys(memberOccupations).map((key) => ({
  label: memberOccupations[key].label,
  value: memberOccupations[key].value,
}));

export default memberOccupations;
