import React from "react";
import { CheckboxGroup, Stack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";

import requirementsSchema, { RequirementsSchema } from "settings/yup/schemas/form/requirementsSchema";
import FieldTitle from "components/FormComponents/FieldTitle";
import Checkbox from "components/FormComponents/Checkbox";
import useFormStore from "views/Forms/store";

import { RequirementsFormProps } from "./types";
import useFormStep from "../hooks/useFormStep";

const RequirementsForm: React.FC<RequirementsFormProps> = ({
  formResponse,
}) => {
  const [t] = useTranslation();

  const requirements = useFormStore((store) => store.form?.requirements) ?? [];

  const { control } = useFormStep<RequirementsSchema>({
    schema: requirementsSchema,
    formOptions: {
      defaultValues: {
        fulfilledRequirements: formResponse?.fulfilledRequirements ?? [],
      },
    },
  });

  return (
    <Stack spacing={6}>
      <FieldTitle>
        {`${t("forms.steps.requirements.please_attest_to_the_following_job_requirements")}:*`}
      </FieldTitle>

      <Controller
        control={control}
        name="fulfilledRequirements"
        render={(props) => (
          <CheckboxGroup {...props}>
            <Stack spacing={4}>
              {
                requirements.map((requirement) => (
                  <Checkbox
                    value={requirement}
                    name={requirement}
                    key={requirement}
                  >
                    {requirement}
                  </Checkbox>
                ))
              }
            </Stack>
          </CheckboxGroup>
        )}
      />
    </Stack>
  );
};

export default RequirementsForm;
