import React, { useEffect } from "react";

import {
  useGetCurrentUserRoleQuery,
  UserRoleEnum,
} from "generated/graphql";

import AdminImpersonationSelect from "./AdminImpersonationSelect";
import useAdminImpersonationStore from "./store";

const AdminImpersonation: React.FC = () => {
  const { data } = useGetCurrentUserRoleQuery();

  const isVisible = !!(
    data?.currentUser?.role === UserRoleEnum.Admin
  );

  useEffect(() => {
    useAdminImpersonationStore.setState({
      isVisible,
    });
  }, [
    isVisible,
  ]);

  if (!isVisible) {
    return null;
  }

  return (
    <AdminImpersonationSelect />
  );
};

export default AdminImpersonation;
