import React from "react";
import { Stack, Text } from "@chakra-ui/react";

import { mapFormStepTypes } from "constants/formSteps";
import NumberCircle from "components/NumberCircle";

import { StepRowProps } from "./types";

const StepRow: React.FC<StepRowProps> = ({
  isDisabled,
  stepIndex,
  step,
}) => (
  <Stack
    spacing={4}
    isInline
    w="full"
  >
    <NumberCircle
      bgColor={isDisabled ? "gray.300" : "primary.100"}
      value={step.editFormPreview ?? stepIndex}
    />

    <Stack spacing={1}>
      <Text
        color={isDisabled ? "gray.400" : "primary.500"}
        fontFamily="bold"
        fontSize="xs"
      >
        {step.title}
      </Text>

      <Text
        fontFamily="bold"
        color="gray.300"
        fontSize="xxs"
      >
        {mapFormStepTypes[step.type]?.label}
      </Text>
    </Stack>
  </Stack>
);

export default StepRow;
