import { PureQueryOptions } from "@apollo/client";

import useAdminImpersonationStore from "components/AdminImpersonation/store";

import { BuildRefetchQueriesWithVariablesOptions } from "./types";

/**
 * Build the `refetchQueries` list that must have variables to refetch data.
 */
const buildRefetchQueriesWithVariables = ({
  impersonatedCompanyQueries,
  currentCompanyQueries,
}: BuildRefetchQueriesWithVariablesOptions): PureQueryOptions[] => {
  const impersonatedCompanyId = useAdminImpersonationStore.getState()?.companyId;

  if (impersonatedCompanyId) {
    return impersonatedCompanyQueries.map(impersonatedCompanyQuery => ({
      query: impersonatedCompanyQuery.query,
      variables: impersonatedCompanyQuery.variables || {},
      context: impersonatedCompanyQuery.context || {},
    }));
  }

  return currentCompanyQueries.map(currentCompanyQuery => ({
    query: currentCompanyQuery.query,
    variables: currentCompanyQuery.variables || {},
    context: currentCompanyQuery.context || {},
  }));
};

export default buildRefetchQueriesWithVariables;
