import React from "react";

import Input from "components/FormComponents/Input";

import { PhoneInputProps } from "./types";

// This input allows users to enter only numeric characters.
const PhoneInput: React.FC<PhoneInputProps> = ({
  ...rest
}) => (
  (
    <Input
      onKeyPress={e => !/[0-9]/.test(e.key) && e.preventDefault()}
      onKeyDown={e => e.key === "Dead" && e.preventDefault()}
      pattern="[0-9]*"
      type="number"
      {...rest}
    />
  )
);

export default PhoneInput;
