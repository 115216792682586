import React from "react";

import { useGetJobRoleLazyQuery } from "generated/graphql";
import useApplicantFiltersParams from "hooks/useApplicantFiltersParams";

const useIsProfessional = (): boolean => {
  const [isProfessional, setIsProfessional] = React.useState<boolean>(false);

  const [filters] = useApplicantFiltersParams();

  const [getJob, { data: jobData }] = useGetJobRoleLazyQuery();

  React.useEffect(() => {
    if (!filters?.jobId) {
      return;
    }

    getJob({ variables: { id: filters?.jobId } });
  }, [
    filters?.jobId,
    getJob,
  ]);

  React.useEffect(() => {
    if (!jobData) {
      return;
    }

    setIsProfessional(!!jobData?.getJob?.role?.isProfessional);
  }, [
    jobData,
  ]);

  return isProfessional;
};

export default useIsProfessional;
