import * as React from "react";
import { Link as ReactRouterLink } from "react-router-dom";
import { Text, Box, Link } from "@chakra-ui/react";

import { HeaderLinkProps } from "./types";

const HeaderLink: React.FC<HeaderLinkProps> = ({
  children,
  title,
  to,
  ...rest
}) => (
  <Link
    _hover={{ textDecoration: "none" }}
    _focus={{ boxShadow: "none" }}
    as={ReactRouterLink}
    alignItems="center"
    to={to}
  >
    {
      title && (
        <Box
          as={Text}
          _hover={{ fontWeight: "bold" }}
          whiteSpace="pre"
          fontSize="xs"
          color="white"
          mx={3}
          {...rest}
        >
          {title}
        </Box>
      )
    }

    {children}
  </Link>
);

export default HeaderLink;
