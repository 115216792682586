import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import numeral from "numeral";
import {
  TextProps,
  Switch,
  Stack,
  Text,
} from "@chakra-ui/react";

import { BillingType } from "generated/graphql";

import { PlanPriceSelectorProps } from "../../types";

const PlanPriceSelector: React.FC<PlanPriceSelectorProps> = ({
  setSelectedBillingType,
  selectedBillingType,
  plan,
}) => {
  const [t] = useTranslation();

  const getTextProps = useCallback((value: BillingType): TextProps => {
    const isSelected = value === selectedBillingType;

    return {
      fontFamily: isSelected ? "bold" : "heading",
      color: isSelected ? "black" : "gray.400",
      fontSize: "sm",
    };
  }, [
    selectedBillingType,
  ]);

  const handleOnSwitchChange = useCallback((event) => {
    const newBillingType = (
      event?.target?.checked
        ? BillingType.Annual
        : BillingType.Monthly
    );

    setSelectedBillingType(newBillingType);
  }, [
    setSelectedBillingType,
  ]);

  if (plan.isFreePlan) {
    return (
      <Text
        fontFamily="heading"
        color="gray.400"
        fontSize="sm"
      >
        {t("fallbacks.free_subscription_plan.price")}
      </Text>
    );
  }

  return (
    <Stack
      justifyContent={{
        base: "space-around",
        xl: "center",
      }}
      alignItems="center"
      spacing={{
        base: 2,
        lg: 3,
        xl: 6,
      }}
      isInline
      w="full"
      px={3}
    >
      <Text {...getTextProps(BillingType.Monthly)}>
        {t("billing_types.monthly")}
      </Text>

      <Switch
        isChecked={selectedBillingType === BillingType.Annual}
        onChange={handleOnSwitchChange}
        colorScheme="primary"
        size="lg"
      />

      <Stack
        alignItems="center"
        spacing={1}
      >
        <Text {...getTextProps(BillingType.Annual)}>
          {t("billing_types.annual")}
        </Text>

        <Text
          color="gray.400"
          fontSize="2xs"
        >
          {t("dashboard.modify_subscription.save_percent", {
            value: numeral(plan.annualDiscountPercentage / 100).format("0%"),
          })}
        </Text>
      </Stack>
    </Stack>
  );
};

export default PlanPriceSelector;
