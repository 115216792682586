import React from "react";

import { ProfileCardContent } from "components/Tracking/Cards/CardComponents";
import { ClientCardProps } from "components/Tracking/Cards/types";

import useClientCard from ".";

const ClientProfileCard: React.FC<ClientCardProps> = ({
  client,
  ...props
}) => {
  const cardProps = useClientCard(client);

  return (
    <ProfileCardContent
      {...cardProps}
      {...props}
    />
  );
};

export default ClientProfileCard;
