import * as R from "remeda";

import { SelectOption } from "components/FormComponents/Select/types";
import { Member } from "generated/graphql";

/**
 * Parses the select recruiter options.
 * @param data Query result.
 */
const getRecruiterOptions = (activeMembers: Member[] = []): SelectOption[] => R.pipe(
  activeMembers,
  R.sortBy((member) => member.name),
  R.map((member) => ({
    value: member.id,
    label: member.name,
  })),
);

export default getRecruiterOptions;
