import React from "react";
import { useTranslation } from "react-i18next";
import {
  IconButton,
  Tooltip,
  Text,
  Stack,
} from "@chakra-ui/react";

import { PreviewTitleProps } from "views/Forms/FormPreview/types";
import EditIcon from "settings/theme/icons/EditIcon";

/**
 * Renders the title of a question, displaying the icon to edit the form.
 * @param props Component props.
 */
const PreviewTitle: React.FC<PreviewTitleProps> = ({
  editStep,
  title,
}) => {
  const [t] = useTranslation();

  const tooltipTitle = t("buttons.edit_item", {
    item: title,
  });

  return (
    <Stack
      alignItems="center"
      spacing={2}
      isInline
    >
      <Text
        color="primary.500"
        fontFamily="bold"
        fontSize="lg"
      >
        {`${title}:`}
      </Text>

      <Tooltip
        aria-label={tooltipTitle}
        label={tooltipTitle}
        fontSize="xxs"
        hasArrow
      >
        <IconButton
          aria-label={tooltipTitle}
          variant="unstyled"
          onClick={editStep}
          icon={(
            <EditIcon color="primary.200" />
          )}
        />
      </Tooltip>
    </Stack>
  );
};

export default PreviewTitle;
