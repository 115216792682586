import { Availability, DayOfWeekEnum } from "generated/graphql";

import { ParsedAvailability } from "./types";

/**
 * Parses availability for one day.
 * @param value The availabilities array.
 */
const parseDayAvailability = (
  day: DayOfWeekEnum,
  availabilities: Availability[],
): ParsedAvailability => {
  const availability = availabilities?.find?.((item) => item.day === day);

  return {
    afternoon: availability?.afternoon ?? false,
    overnight: availability?.overnight ?? false,
    evening: availability?.evening ?? false,
    morning: availability?.morning ?? false,
    day,
  };
};

/**
 * Parses an array of availabilities, ensuring to always return an empty default array.
 * Values that are passed in `initialValue` will override default values.
 * @param availabilities The initial value.
 */
const parseAvailabilities = (
  availabilities: Availability[] = [],
): ParsedAvailability[] => [
  parseDayAvailability(DayOfWeekEnum.Sunday, availabilities),
  parseDayAvailability(DayOfWeekEnum.Monday, availabilities),
  parseDayAvailability(DayOfWeekEnum.Tuesday, availabilities),
  parseDayAvailability(DayOfWeekEnum.Wednesday, availabilities),
  parseDayAvailability(DayOfWeekEnum.Thursday, availabilities),
  parseDayAvailability(DayOfWeekEnum.Friday, availabilities),
  parseDayAvailability(DayOfWeekEnum.Saturday, availabilities),
];

export default parseAvailabilities;
