import { extraStepIdPrefix, FormStepId } from "constants/formSteps";

import { PreviewComponent } from "../types";
import PersonalInformation from "./Steps/PersonalInformation";
import PersonalStory from "./Steps/PersonalStory";
import Requirements from "./Steps/Requirements";
import Availability from "./Steps/Availability";
import ExtraSection from "./Steps/ExtraSection";
import RespondedBy from "./Steps/RespondedBy";
import Resume from "./Steps/Resume";
import Source from "./Steps/Source";

/**
 * Maps form step ids to preview components.
 */
const mapStepComponent: Partial<Record<FormStepId, PreviewComponent>> = {
  [FormStepId.PersonalInformation]: PersonalInformation as PreviewComponent,
  [FormStepId.PersonalStory]: PersonalStory as PreviewComponent,
  [FormStepId.Requirements]: Requirements as PreviewComponent,
  [FormStepId.Availability]: Availability as PreviewComponent,
  [FormStepId.RespondedBy]: RespondedBy as PreviewComponent,
  [FormStepId.Resume]: Resume as PreviewComponent,
  [FormStepId.Source]: Source as PreviewComponent,
};

/**
 * Maps the form preview step component to be rendered according to a step id.
 * @param formType The form type.
 */
const getStepComponent = (
  stepId: FormStepId | string,
): PreviewComponent => {
  if (stepId.includes(extraStepIdPrefix)) {
    return ExtraSection;
  }

  return mapStepComponent[stepId];
};

export default getStepComponent;
