import ClientStatusFilter from "components/Tracking/Filters/StatusFilters/ClientStatusFilter";
import LocationFilter from "components/Tracking/Filters/LocationFilter";
import RoleFilter from "components/Tracking/Filters/RoleFilter";
import SortByFilter from "components/Tracking/Filters/SortByFilter";
import { clientsSortByFilterOptions, clientsSortByFilterDefaultValue } from "components/Tracking/Filters/SortByFilter/sortByFilterOptions";
import { TrackingFilter } from "components/Tracking/types";

/**
 * Builds the Client Matching filter list according to the query & filter state.
 */
const makeClientMatchingFilters = (): TrackingFilter[] => [
  {
    component: RoleFilter,
  },
  {
    component: SortByFilter,
    props: {
      options: clientsSortByFilterOptions,
      defaultValue: clientsSortByFilterDefaultValue,
    },
  },
  {
    component: LocationFilter,
  },
  {
    component: ClientStatusFilter,
  },
];

export default makeClientMatchingFilters;
