import React from "react";
import * as R from "remeda";

import { QuestionCardProps } from "components/Forms/CompanyOnboardingForm/Cards/QuestionCard/types";
import { FormTypeEnum } from "generated/graphql";
import i18n from "translations/i18n";
import { QuestionDisplayTypeEnum } from "constants/formEditableQuestions";

import SelectDisplayType from "../SelectDisplayType";
import { BuildQuestionsOptions, PictureUploadFieldsOptions } from "./types";

const pictureUploadQuestionFields = ({
  allowImagesUpload,
  updatePermissionForPictureUpload,
}: PictureUploadFieldsOptions): QuestionCardProps["fields"] => [
  {
    label: {
      text: i18n.t("company_onboarding.steps.personal_story.picture_upload_question.field_1"),
    },
    value: {
      render: () => {
        const getSelectTypeValue = (
          uploadPermission: boolean | undefined,
        ): string => ((uploadPermission
          ? QuestionDisplayTypeEnum.SHOW
          : QuestionDisplayTypeEnum.HIDDEN
        ));

        return (
          <SelectDisplayType
            value={getSelectTypeValue(allowImagesUpload)}
            handleCallback={updatePermissionForPictureUpload}
          />
        );
      },
    },
  },
  {
    label: {
      text: i18n.t("company_onboarding.steps.personal_story.picture_upload_question.field_2"),
    },
    value: {
      text: i18n.t("question_display_types.show_option_by_default"),
    },
  },
  {
    label: {
      text: i18n.t("company_onboarding.steps.personal_story.picture_upload_question.field_2"),
    },
    value: {
      text: i18n.t("question_display_types.show_option_by_default"),
    },
  },
];

/**
 * Map questions to be rendered.
 * @param questions The questions.
 */
const mapQuestions = (
  questions: string[] | undefined,
): QuestionCardProps[] => R.pipe(
  questions ?? [],
  R.map.indexed((question, index) => ({
    title: i18n.t("question_types.free_text_index", { index: index + 1 }),
    fields: [
      {
        label: {
          text: question,
        },
        value: {
          text: i18n.t("question_display_types.required_by_default"),
        },
      },
    ],
  })),
);

/**
 * Builds the questions for personal story form.
 * @param param0 Options.
 */
const buildQuestions = ({
  applicantOpenQuestions,
  clientOpenQuestions,
  formType,
  allowImagesUpload,
  updatePermissionForPictureUpload,
}: BuildQuestionsOptions): QuestionCardProps[] => {
  const questions = mapQuestions(
    formType === FormTypeEnum.Client
      ? clientOpenQuestions
      : applicantOpenQuestions,
  );

  return [
    ...questions,
    {
      title: i18n.t("question_types.upload_index", { index: questions.length + 1 }),
      fields: pictureUploadQuestionFields({
        allowImagesUpload,
        updatePermissionForPictureUpload,
      }),
    },
  ];
};

export default buildQuestions;
