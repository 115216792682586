import React from "react";

import ConfigureLandingPageForm from "components/Forms/ConfigureLandingPageForm";
import useCompanyOnboardingJob from "hooks/useCompanyOnboardingJob";
import useFilter, { filters } from "hooks/useFilter";
import Loading from "components/Loading";

const ConfigureLandingPage: React.FC = () => {
  const [landingPageLayoutLabel] = useFilter(filters.landingPageLayoutLabel);

  const [job, loadingJob] = useCompanyOnboardingJob();

  if (loadingJob || !job?.id) {
    return (
      <Loading />
    );
  }

  return (
    <ConfigureLandingPageForm
      landingPageLayoutLabel={landingPageLayoutLabel as string}
      initialValues={job?.landingPage}
      jobId={job.id}
    />
  );
};

export default ConfigureLandingPage;
