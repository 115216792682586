import React from "react";
import { Carousel } from "react-responsive-carousel";

import ArrowRightIcon from "settings/theme/icons/ArrowRightIcon";
import ArrowLeftIcon from "settings/theme/icons/ArrowLeftIcon";
import theme from "settings/theme";

import { renderCarouselArrow } from "./CarouselArrow";
import { ImagesProps } from "./types";
import Image from "./Image";

const Images: React.FC<ImagesProps> = ({
  showIndicators = true,
  emulateTouch = true,
  showStatus = false,
  showThumbs = false,
  showArrows = true,
  autoPlay = false,
  height = "md",
  width = "md",
  src,
  ...rest
}) => {
  const carouselWidth = theme.sizes[width as string] ?? width;

  const imagesLength = src?.length ?? 1;

  const shouldShowArrows = (
    showArrows
    && imagesLength > 1
    && height !== "xxs"
  );

  const shouldShowIndicators = (
    showIndicators
    && imagesLength > 1
    && height !== "xxs"
  );

  if (Array.isArray(src) && src.length > 0) {
    return (
      <Carousel
        renderArrowNext={renderCarouselArrow(ArrowRightIcon, shouldShowArrows, { right: 0 })}
        renderArrowPrev={renderCarouselArrow(ArrowLeftIcon, shouldShowArrows, { left: 0 })}
        showIndicators={shouldShowIndicators}
        showArrows={shouldShowArrows}
        emulateTouch={emulateTouch}
        showThumbs={showThumbs}
        showStatus={showStatus}
        width={carouselWidth}
        autoPlay={autoPlay}
        dynamicHeight
      >
        {
          src.map((imageSrc) => (
            <Image
              height={height}
              key={imageSrc}
              src={imageSrc}
              width={width}
              {...rest}
            />
          ))
        }
      </Carousel>
    );
  }

  return (
    <Image
      src={String(src ?? "")}
      height={height}
      width={width}
      {...rest}
    />
  );
};

export default Images;
