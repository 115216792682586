import * as React from "react";
import { IconProps } from "@chakra-ui/icons";
import { createIcon } from "@chakra-ui/react";

const CalendarIcon: React.FC<IconProps> = createIcon({
  path: (
    <>
      <path
        d="M0 0h24v24H0V0z"
        fill="none"
      />
      <path
        fill="currentColor"
        d="M18.286 5.714h-1.715V4.571a.572.572 0 1 0-1.143 0v1.143H8.571V4.571a.572.572 0 1 0-1.143 0v1.143H5.714A1.714 1.714 0 0 0 4 7.429v10.857A1.714 1.714 0 0 0 5.714 20h12.572A1.714 1.714 0 0 0 20 18.286V7.429a1.714 1.714 0 0 0-1.714-1.715zm.571 12.571a.571.571 0 0 1-.571.571H5.714a.571.571 0 0 1-.571-.571v-7.428h13.714zm0-8.571H5.143V7.429a.571.571 0 0 1 .571-.571h1.715V8a.571.571 0 1 0 1.142 0V6.857h6.857V8a.572.572 0 1 0 1.143 0V6.857h1.714a.571.571 0 0 1 .571.571v2.286z"
      />
      <path
        fill="currentColor"
        d="M8.571 12H7.424a.572.572 0 1 0 0 1.143h1.143a.572.572 0 1 0 0-1.143zM12.571 12h-1.143a.572.572 0 0 0 0 1.143h1.143a.572.572 0 1 0 0-1.143zM16.571 12h-1.143a.572.572 0 1 0 0 1.143h1.143a.572.572 0 0 0 0-1.143zM8.571 14.286H7.424a.572.572 0 1 0 0 1.143h1.143a.572.572 0 1 0 0-1.143zM12.571 14.286h-1.143a.572.572 0 0 0 0 1.143h1.143a.572.572 0 1 0 0-1.143zM16.571 14.286h-1.143a.572.572 0 1 0 0 1.143h1.143a.572.572 0 0 0 0-1.143zM8.571 16.571H7.424a.572.572 0 1 0 0 1.143h1.143a.572.572 0 1 0 0-1.143zM12.571 16.571h-1.143a.572.572 0 0 0 0 1.143h1.143a.572.572 0 1 0 0-1.143zM16.571 16.571h-1.143a.572.572 0 1 0 0 1.143h1.143a.572.572 0 0 0 0-1.143z"
      />
    </>
  ),
});

export default CalendarIcon;
