import { gql } from "@apollo/client";

export const setDefaultCreditCardRefetchQueries = [
  "ListCurrentCompanyCreditCards",
];

export default gql`
  mutation SetDefaultCreditCard ($id: Int!) {
    setDefaultCreditCard (id: $id) {
      id
    }
  }
`;
