/**
 * This function receives a string and returns an array of string
 * on that every element represents a text line.
 * The parameter that determines if the string should be split is the
 * maxLineLength, so the string is split into a new line if the length
 * is higher than maxLineLength and the next char is
 * whitespace.
 * @param text string that should be splitted into multiple lines
 * @param maxLineLength max line characters length
 * @returns undefined or an array of strings
 */
const getMultilineText = (
  text?: string,
  maxLineLength = 40,
): string[] | [] => {
  if (!text) {
    return [];
  }

  return text
    .split(new RegExp(`(.{${maxLineLength},}?)(?:\\s|$)`, "g"))
    .filter(Boolean);
};

export default getMultilineText;
