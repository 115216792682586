/**
 * Returns a field label, indicating if it's required or not.
 *
 * @example getFieldLabel("Name", false) => "Name"
 * @example getFieldLabel("Name", true) => "Name*"
 *
 * @param label The field label.
 * @param isRequired Defines if the field is required.
 */
const getFieldLabel = (label: string, isRequired: boolean): string => (
  `${label}${isRequired ? "*" : ""}`
);

export default getFieldLabel;
