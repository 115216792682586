import React from "react";
import { useTranslation } from "react-i18next";
import { Stack, Text } from "@chakra-ui/react";

import { useGetCompanyDashboardSubscriptionsSummaryQuery } from "generated/graphql";
import Box from "components/Box";

import { SubscriptionsSummaryCardProps } from "./types";
import PaymentList from "./PaymentList";

const SubscriptionsSummaryCard: React.FC<SubscriptionsSummaryCardProps> = ({
  companyId,
}) => {
  const [t] = useTranslation();

  const {
    loading,
    data,
  } = useGetCompanyDashboardSubscriptionsSummaryQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      companyId,
    },
  });

  const subscriptions = data?.getCompany?.subscriptions ?? [];

  return (
    <Box
      p={5}
      h="full"
      w="full"
      isLoading={loading}
      title={t("dashboard.dashboard.subscriptions_financial_summary")}
      titleProps={{
        textTransform: "uppercase",
        fontFamily: "heading",
        fontSize: "xs",
      }}
      wrapperProps={{
        mb: 0,
      }}
    >
      {
        subscriptions.length > 0
          ? (
            <Stack
              maxHeight="200px"
              overflowY="auto"
              spacing={6}
            >
              {
                subscriptions.map((subscription) => (
                  <PaymentList
                    key={subscription.id}
                    subscription={subscription}
                  />
                ))
              }
            </Stack>
          )
          : (
            <Text
              color="gray.400"
              fontSize="xs"
              pl={3}
            >
              {t("dashboard.dashboard.no_payments_found")}
            </Text>
          )
      }
    </Box>
  );
};

export default SubscriptionsSummaryCard;
