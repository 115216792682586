import React from "react";
import { Checkbox as ChakraCheckbox, FormControl, Text } from "@chakra-ui/react";
import ConditionalWrap from "conditional-wrap";

import getErrorMessage from "utils/getErrorMessage";
import FieldErrorMessage from "components/FormComponents/FieldErrorMessage";

import { CheckboxProps } from "./types";

const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>((
  {
    renderChildrenAsText = true,
    showErrorMessage = true,
    colorScheme = "primary",
    color = "black",
    fontSize = "xs",
    isReadOnly,
    children,
    errors,
    name,
    ...rest
  },
  ref,
) => {
  const error = getErrorMessage(name, errors);

  return (
    <FormControl>
      <ChakraCheckbox
        {...rest}
        cursor={isReadOnly ? "default" : "pointer"}
        colorScheme={colorScheme}
        isReadOnly={isReadOnly}
        isInvalid={!!error}
        name={name}
        ref={ref}
        css={{
          "*": {
            boxShadow: (
              isReadOnly
                ? "none !important"
                : ""
            ),
          },
        }}
      >
        <ConditionalWrap
          condition={renderChildrenAsText}
          wrap={(content) => (
            <Text fontSize={fontSize} color={color}>
              {content}
            </Text>
          )}
        >
          <>
            {children}
          </>
        </ConditionalWrap>
      </ChakraCheckbox>

      {
        showErrorMessage && (
          <FieldErrorMessage error={error} />
        )
      }
    </FormControl>
  );
});

export default Checkbox;
