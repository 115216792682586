import AvailabilityFilter from "components/Tracking/Filters/AvailabilityFilter";
import LocationFilter from "components/Tracking/Filters/LocationFilter";
import SortByFilter from "components/Tracking/Filters/SortByFilter";
import ApplicantStatusFilter from "components/Tracking/Filters/StatusFilters/ApplicantStatusFilter";
import RoleFilter from "components/Tracking/Filters/RoleFilter";
import { TrackingFilter } from "components/Tracking/types";
import { applicantsSortByFilterOptions, applicantsSortByFilterDefaultValue } from "components/Tracking/Filters/SortByFilter/sortByFilterOptions";

/**
 * Builds the Applicant Tracking filter list according to the query & filter state.
 */
const makeApplicantTrackingFilters = (
  isProfessional?: boolean,
): TrackingFilter[] => [
  {
    component: RoleFilter,
  },
  {
    component: SortByFilter,
    props: {
      options: applicantsSortByFilterOptions(isProfessional),
      defaultValue: applicantsSortByFilterDefaultValue,
    },
  },
  {
    component: LocationFilter,
  },
  {
    component: AvailabilityFilter,
  },
  {
    component: ApplicantStatusFilter,
  },
];

export default makeApplicantTrackingFilters;
