import * as React from "react";
import { IconProps } from "@chakra-ui/icons";
import { createIcon } from "@chakra-ui/react";

const BarChartIcon: React.FC<IconProps> = createIcon({
  viewBox: "0 0 24.2 24",
  path: (
    <g
      id="diagram"
      transform="translate(176.106 -381.997) rotate(90)"
    >
      <rect
        id="Rectangle_4916"
        data-name="Rectangle 4916"
        width="24"
        height="24"
        transform="translate(381.997 176.006) rotate(-90)"
        fill="none"
      />
      <g
        id="Group_14005"
        data-name="Group 14005"
        transform="translate(384.003 152.006)"
      >
        <g
          id="Group_14004"
          data-name="Group 14004"
          transform="translate(0 0)"
        >
          <path
            fill="currentColor"
            stroke="#fff"
            strokeWidth="0.2"
            id="Path_5728"
            data-name="Path 5728"
            d="M389.192,152.006h-4.541a.649.649,0,0,0-.649.649v22.7a.649.649,0,0,0,.649.649h4.541a.649.649,0,0,0,.649-.649v-22.7A.649.649,0,0,0,389.192,152.006Zm-.649,22.7H385.3V153.3h3.243Z"
            transform="translate(-384.003 -152.006)"
          />
        </g>
      </g>
      <g
        id="Group_14006"
        data-name="Group 14006"
        transform="translate(390.814 152.006)"
      >
        <g
          id="Group_14004-2"
          data-name="Group 14004"
          transform="translate(0 0)"
        >
          <path
            fill="currentColor"
            stroke="#fff"
            strokeWidth="0.2"
            id="Path_5728-2"
            data-name="Path 5728"
            d="M389.192,152.006h-4.541a.649.649,0,0,0-.649.649v22.7a.649.649,0,0,0,.649.649h4.541a.649.649,0,0,0,.649-.649v-22.7A.649.649,0,0,0,389.192,152.006Zm-.649,22.7H385.3V153.3h3.243Z"
            transform="translate(-384.003 -152.006)"
          />
        </g>
      </g>
      <g
        id="Group_14007"
        data-name="Group 14007"
        transform="translate(397.625 152.006)"
      >
        <g
          id="Group_14004-3"
          data-name="Group 14004"
          transform="translate(0 0)"
        >
          <path
            fill="currentColor"
            stroke="#fff"
            strokeWidth="0.2"
            id="Path_5728-3"
            data-name="Path 5728"
            d="M389.192,152.006h-4.541a.649.649,0,0,0-.649.649v22.7a.649.649,0,0,0,.649.649h4.541a.649.649,0,0,0,.649-.649v-22.7A.649.649,0,0,0,389.192,152.006Zm-.649,22.7H385.3V153.3h3.243Z"
            transform="translate(-384.003 -152.006)"
          />
        </g>
      </g>
    </g>
  ),
});

export default BarChartIcon;
