import React from "react";
import { Stack } from "@chakra-ui/react";

import { FormTypeEnum } from "generated/graphql";

import PreviewTitle from "./PreviewComponents/PreviewTitle";
import getStepComponent from "./getStepComponent";
import { FormResponsesProps } from "../types";

const FormResponses: React.FC<FormResponsesProps> = ({
  formResponse,
  goToStep,
  steps,
  form,
}) => {
  const isRequired = form.formType === FormTypeEnum.Client;

  return (
    <>
      {
        steps.map((step, index) => {
          const StepComponent = getStepComponent(step.id);

          if (!StepComponent) {
            return null;
          }

          return (
            <Stack
              key={String(index)}
              spacing={5}
            >
              <PreviewTitle
                editStep={goToStep(index + 1)}
                title={step.title}
              />

              <Stack spacing={4}>
                <StepComponent
                  formResponse={formResponse}
                  isRequired={isRequired}
                  step={step}
                  form={form}
                />
              </Stack>
            </Stack>
          );
        })
      }
    </>
  );
};

export default FormResponses;
