import * as React from "react";

import { TrackingFiltersQueriesContextPayload } from "./types";

const TrackingFiltersQueriesContext = (
  React.createContext({} as TrackingFiltersQueriesContextPayload)
);

export const TrackingFiltersQueriesProvider = TrackingFiltersQueriesContext.Provider;

/**
 * Exposes the tracking filters data that should be queried from the backend.
 */
export const useTrackingFiltersQueries = (): TrackingFiltersQueriesContextPayload => {
  const context = React.useContext(TrackingFiltersQueriesContext);

  if (!context) {
    throw new Error("useTrackingFiltersQueries should be within TrackingFiltersQueriesProvider");
  }

  return context;
};
