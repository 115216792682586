import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Stack } from "@chakra-ui/react";
import * as R from "remeda";

import {
  useGetImpersonatedCompanyDashboardRolesQuery,
  useGetCurrentCompanyDashboardRolesQuery,
} from "generated/graphql";
import { SelectOption } from "components/FormComponents/Select/types";
import FieldTitle from "components/FormComponents/FieldTitle";
import PageHeader from "components/PageHeaders/PageHeader";
import Select from "components/FormComponents/Select";
import useGetCompany from "hooks/useGetCompany";
import Loading from "components/Loading";

import getDashboardComponent from "./getDashboardComponent";
import { getDashboardPeriods } from "./periods";

const Dashboard: React.FC = () => {
  const [t] = useTranslation();

  const [selectedJob, setSelectedJob] = useState<SelectOption>();

  const [company, {
    impersonatedCompanyData,
    currentCompanyData,
    loading,
  }] = useGetCompany(
    useGetCurrentCompanyDashboardRolesQuery,
    useGetImpersonatedCompanyDashboardRolesQuery,
  );

  const selectOptions = useMemo(() => R.pipe(
    company?.jobs ?? [],
    R.map((job) => ({
      label: job.role.name,
      value: job.id,
    })),
  ), [
    company,
  ]);

  const periods = useMemo(() => (
    getDashboardPeriods()
  ), []);

  useEffect(() => {
    setSelectedJob(selectOptions?.[0]);
  }, [
    selectOptions,
  ]);

  const companyId = company?.id;

  if (loading || !selectedJob || !companyId) {
    return (
      <Loading />
    );
  }

  const DashboardComponent = getDashboardComponent(
    currentCompanyData?.currentUser?.member?.occupation,
    !!impersonatedCompanyData,
  );

  return (
    <Stack spacing={6}>
      <PageHeader
        title={t("dashboard.dashboard.title")}
        childrenContainerProps={{
          alignItems: "center",
          width: "xs",
        }}
      >
        <FieldTitle
          whiteSpace="nowrap"
          fontSize="xs"
          mb={0}
        >
          {`${t("filters.filter_by")}:`}
        </FieldTitle>

        <Select
          defaultValue={selectOptions?.[0]}
          onChange={setSelectedJob}
          options={selectOptions}
          value={selectedJob}
        />
      </PageHeader>

      <DashboardComponent
        selectedJobId={selectedJob?.value as number}
        comparisonPeriod={periods.comparisonPeriod}
        period={periods.currentPeriod}
        companyId={companyId}
      />
    </Stack>
  );
};

export default Dashboard;
