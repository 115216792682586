import React from "react";
import { useTranslation } from "react-i18next";
import {
  Stack,
  Text,
} from "@chakra-ui/react";

import {
  useGetImpersonatedCompanyDashboardRemainingTokensQuery,
  useGetCurrentCompanyDashboardRemainingTokensQuery,
} from "generated/graphql";
import formatTokenByExpirationDate from "utils/dashboard/formatTokenByExpirationDate";
import getTokenIndicatorColors from "utils/dashboard/getTokenIndicatorColors";
import { DASHBOARD_PAGE_LOCATION } from "routes/locations";
import useGetCompany from "hooks/useGetCompany";
import useNavigate from "hooks/useNavigate";
import Button from "components/Button";
import Box from "components/Box";

import { RemainingTokensCardProps } from "./types";

const RemainingTokensCard: React.FC<RemainingTokensCardProps> = ({
  showAddTokensButton = false,
}) => {
  const navigate = useNavigate();
  const [t] = useTranslation();

  const [company, {
    loading,
  }] = useGetCompany(
    useGetCurrentCompanyDashboardRemainingTokensQuery,
    useGetImpersonatedCompanyDashboardRemainingTokensQuery,
    {
      fetchPolicy: "cache-and-network",
    },
  );

  const jobs = company?.jobs ?? [];

  return (
    <Box
      p={5}
      h="full"
      w="full"
      isLoading={loading}
      title={t("dashboard.dashboard.remaining_tokens")}
      titleProps={{
        textTransform: "uppercase",
        fontFamily: "heading",
        fontSize: "xs",
      }}
      wrapperProps={{
        mb: 0,
      }}
    >
      <Stack
        maxHeight="250px"
        overflowY="auto"
        height="full"
        spacing={6}
      >
        {
          jobs.map((job) => {
            const { bgColor } = getTokenIndicatorColors(job.numberOfAvailableTokens);

            return (
              <Stack key={String(job.id)}>
                <Text
                  fontFamily="bold"
                  color={bgColor}
                  fontSize="xs"
                  pl={3}
                >
                  {
                    job?.subscription?.plan
                      ? (`${job?.role?.name} - ${job.subscription?.plan?.name} ${t("dashboard.subscription.plan")}`)
                      : (`${job?.role?.name} - ${t("fallbacks.free_subscription_plan.plan")} ${t("dashboard.subscription.plan")}`)
                  }
                </Text>

                {
                  job.tokensByExpirationDate?.length > 0
                    ? job.tokensByExpirationDate.map((tokenByExpirationDate, index) => (
                      <Text
                        as="span"
                        key={String(index)}
                        fontSize="xs"
                        pl={3}
                      >
                        {formatTokenByExpirationDate(tokenByExpirationDate)}
                      </Text>
                    ))
                    : (
                      <Text
                        as="span"
                        fontSize="xs"
                        pl={3}
                      >
                        {t("tokens_count.count_tokens", {
                          count: 0,
                        })}
                      </Text>
                    )
                }
              </Stack>
            );
          })
        }
      </Stack>

      {
        showAddTokensButton && (
          <Button
            onClick={navigate(DASHBOARD_PAGE_LOCATION.toUrl({
              page: "subscription",
            }))}
            _hover={{
              bgColor: "primary.200",
            }}
            bgColor="primary.100"
            borderRadius={0}
            marginX={-5}
            fontSize="xxs"
            color="white"
            size="md"
            mt={4}
          >
            {t("buttons.go_to_current_plans")}
          </Button>
        )
      }
    </Box>
  );
};

export default RemainingTokensCard;
