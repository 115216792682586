import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import update from "immutability-helper";

import useCompanyOnboardingFormEffects from "components/Forms/CompanyOnboardingForm/hooks/useCompanyOnboardingFormEffects";
import useCompanyOnboardingForm from "components/Forms/CompanyOnboardingForm/hooks/useCompanyOnboardingForm";
import requirementsSchema, { RequirementsSchema } from "settings/yup/schemas/onboarding/requirementsSchema";
import useCompanyOnboardingFormStore from "components/Forms/CompanyOnboardingForm/store";
import QuestionCard from "components/Forms/CompanyOnboardingForm/Cards/QuestionCard";
import AddMoreOptionsModal from "components/Modal/AddMoreOptionsModal";
import PlusIcon from "settings/theme/icons/PlusIcon";
import { useModal } from "contexts/modal";
import Button from "components/Button";

import makeFields from "./fields";

const Requirements: React.FC = () => {
  const [showModal] = useModal();
  const [t] = useTranslation();

  const defaultRequirements = useCompanyOnboardingFormStore((store) => store.form?.requirements);

  const form = useCompanyOnboardingForm<RequirementsSchema>({
    schema: requirementsSchema,
    formOptions: {
      defaultValues: {
        requirements: defaultRequirements ?? [],
      },
    },
  });

  useCompanyOnboardingFormEffects({
    fields: ["requirements"],
    form,
  });

  const {
    register,
    setValue,
    watch,
  } = form;

  const requirements = watch("requirements");

  const removeRequirement = useCallback((requirement: string) => () => {
    const index = requirements.findIndex((item) => item === requirement);

    const newValue = update(requirements, {
      $splice: [[index, 1]],
    });

    setValue("requirements", newValue, {
      shouldValidate: true,
      shouldDirty: true,
    });
  }, [
    requirements,
    setValue,
  ]);

  const addRequirements = useCallback(() => {
    showModal({
      title: t("buttons.add_more_options"),
      component: AddMoreOptionsModal,
      componentProps: {
        onFinish: (newRequirements: string[]) => {
          const newValue = update(requirements, {
            $push: newRequirements,
          });

          setValue("requirements", newValue, {
            shouldValidate: true,
            shouldDirty: true,
          });
        },
      },
    });
  }, [
    requirements,
    setValue,
    showModal,
    t,
  ]);

  const fields = useMemo(() => (
    makeFields({
      removeItem: removeRequirement,
      requirements,
    })
  ), [
    removeRequirement,
    requirements,
  ]);

  register({
    name: "requirements",
  });

  return (
    <QuestionCard
      title={t("question_types.multiple_choice_index", { index: 1 })}
      fields={fields}
    >
      <Button
        onClick={addRequirements}
        rightIcon={<PlusIcon />}
        variant="outline"
        fontSize="xs"
        width="xs"
        size="sm"
      >
        {t("buttons.add_more_options")}
      </Button>
    </QuestionCard>
  );
};

export default Requirements;
