const photoModalDefaultStyle = {
  size: "xl",
  overflow: "auto",
  showDivider: false,
  modalHeaderProps: {
    padding: 0,
    opacity: 0,
  },
  modalContentProps: {
    overflow: "hidden",
    marginTop: 0,
    marginBottom: 0,
    backgroundColor: "transparent",
  },
  modalCloseButtonProps: {
    zIndex: 100,
    margin: 5,
    fontSize: "sm",
    color: "white",
  },
};

export default photoModalDefaultStyle;
