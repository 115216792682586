import * as R from "remeda";

import { ParsedStatusUpdate } from "components/Tracking/ApplicantStatusHistoryCard/types";
import { formatDate, monthDayYearWithTime } from "utils/dateFormats";
import { StatusUpdate, StatusUpdateEnum } from "generated/graphql";
import { statusUpdates } from "constants/statuses";
import i18n from "translations/i18n";

/**
 * Returns one status label according to a status update.
 * @param statusUpdate The status update.
 */
const getStatusLabel = (statusUpdate: StatusUpdate): string => {
  const formattedStatusUpdate = statusUpdates[statusUpdate?.status as StatusUpdateEnum];

  let suffix = "";

  if (
    statusUpdate?.status === StatusUpdateEnum.InterviewScheduled
    && statusUpdate?.interviewAt
  ) {
    suffix = i18n.t("tracking_cards.date_for", {
      date: formatDate(statusUpdate?.interviewAt, monthDayYearWithTime),
    });
  }

  if (
    statusUpdate?.status === StatusUpdateEnum.Claimed
    && statusUpdate?.responsibleRecruiter?.name
  ) {
    suffix = i18n.t("tracking_cards.updated_by", {
      memberName: statusUpdate?.responsibleRecruiter?.name,
    });
  }

  return R.pipe(
    [formattedStatusUpdate.label, suffix],
    (arr) => arr.join(" "),
  );
};

/**
 * Returns one update label according to a status update.
 * @param statusUpdate The status update.
 */
const getUpdateLabel = (statusUpdate: StatusUpdate): string => {
  const prefix = i18n.t("tracking_cards.updated");

  const happenedAt = i18n.t("tracking_cards.date_on", {
    date: formatDate(statusUpdate?.happenedAt, monthDayYearWithTime),
  });

  let updatedBy = "";

  if (statusUpdate?.member?.name) {
    updatedBy = i18n.t("tracking_cards.updated_by", {
      memberName: statusUpdate?.member?.name,
    });
  }

  return R.pipe(
    [prefix, updatedBy, happenedAt],
    (arr) => arr.join(" "),
  );
};

/**
 * Parses one applicant status update to return information to be displayed.
 * @param statusUpdate The status update.
 */
const parseApplicantStatusUpdate = (statusUpdate: StatusUpdate): ParsedStatusUpdate => {
  const statusLabel = getStatusLabel(statusUpdate);
  const updateLabel = getUpdateLabel(statusUpdate);

  return {
    statusLabel,
    updateLabel,
  };
};

export default parseApplicantStatusUpdate;
