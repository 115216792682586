import React, { useMemo } from "react";

import useApplicantFiltersParams from "hooks/useApplicantFiltersParams";
import useGetCompany from "hooks/useGetCompany";
import {
  useGetImpersonatedCompanyWithJobsQuery,
  useGetCurrentCompanyWithJobsQuery,
  useListApplicantLocationsQuery,
} from "generated/graphql";

import { TrackingFiltersQueriesContextPayload } from "./types";
import { TrackingFiltersQueriesProvider } from ".";

const ApplicantFiltersQueriesContainer: React.FC = ({ children }) => {
  const [{ jobId }] = useApplicantFiltersParams();

  const [company, { loading: companyLoading }] = useGetCompany(
    useGetCurrentCompanyWithJobsQuery,
    useGetImpersonatedCompanyWithJobsQuery,
  );

  const {
    loading: listApplicantLocationsLoading,
    data: listApplicantLocationsData,
  } = useListApplicantLocationsQuery({
    skip: !jobId,
    variables: {
      jobId,
    },
  });

  const loading = !!(
    listApplicantLocationsLoading
    || companyLoading
  );

  const jobs = useMemo(() => (
    company?.jobs ?? []
  ), [
    company,
  ]);

  const payload = useMemo<TrackingFiltersQueriesContextPayload>(() => ({
    locations: listApplicantLocationsData?.listApplicantLocations ?? [],
    loading,
    jobs,
  }), [
    listApplicantLocationsData,
    loading,
    jobs,
  ]);

  return (
    <TrackingFiltersQueriesProvider value={payload}>
      {children}
    </TrackingFiltersQueriesProvider>
  );
};

export default ApplicantFiltersQueriesContainer;
