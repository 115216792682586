import React, { useCallback } from "react";
import { Flex, IconButton, Tooltip } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import DownloadIcon from "settings/theme/icons/DownloadIcon";

import { ImageDownloadIconProps } from "../types";

const ImageDownloadIcon: React.FC<ImageDownloadIconProps> = ({
  src,
}) => {
  const [t] = useTranslation();

  const handleDownload = useCallback(() => {
    window.open(src, "_blank");
  }, [
    src,
  ]);

  return (
    <Flex
      position="absolute"
      right={2}
      top={2}
    >
      <Tooltip
        aria-label={t("buttons.download")}
        label={t("buttons.download")}
        fontSize="xxs"
        hasArrow
      >
        <IconButton
          aria-label={t("buttons.download")}
          onClick={handleDownload}
          variant="unstyled"
          icon={(
            <DownloadIcon color="white" />
          )}
        />
      </Tooltip>
    </Flex>
  );
};

export default ImageDownloadIcon;
