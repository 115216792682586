import { gql } from "@apollo/client";

export const buyTokensRefetchQueries = [
  "GetCurrentCompanyNumberOfUnprocessedApplicants",
  "ListCurrentCompanySubscriptions",
  "ListCurrentCompanyCreditCards",
  "ListCurrentCompanyPayments",
];

export default gql`
  mutation BuyTokens (
    $extraTokensPackId: Int!,
    $companyId: Int!,
  ) {
    buyTokens (
      extraTokensPackId: $extraTokensPackId,
      companyId: $companyId,
    ) {
      id
    }
  }
`;
