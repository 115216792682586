import { gql } from "@apollo/client";

export const updateCompanyRefetchQueries = [
  "ListAdminImpersonatorCompanies",
];

export default gql`
  mutation UpdateCompany (
    $id: Int!,
    $params: CompanyUpdateParams!,
  ) {
    updateCompany(
      id: $id,
      params: $params,
    ) {
      id
    }
  }
`;
