import * as React from "react";
import { IconProps } from "@chakra-ui/icons";
import { createIcon } from "@chakra-ui/react";

const HidePasswordIcon: React.FC<IconProps> = createIcon({
  path: (
    <path
      fill="currentColor"
      id="Path_5866"
      data-name="Path 5866"
      d="M11.5,5.55a9.44,9.44,0,0,1,8.419,4.8,8.568,8.568,0,0,1-2.3,2.725l1.346,1.231A10.361,10.361,0,0,0,22,10.354,11.315,11.315,0,0,0,11.5,3.8a12.278,12.278,0,0,0-3.475.5L9.6,5.743A10.51,10.51,0,0,1,11.5,5.55Zm-1.021,1,1.976,1.808a2.294,2.294,0,0,1,1.222,1.118l1.976,1.808a3.76,3.76,0,0,0,.134-.935A4.11,4.11,0,0,0,11.5,6.424,4.406,4.406,0,0,0,10.479,6.546ZM1.964,3.69,4.522,6.031A10.382,10.382,0,0,0,1,10.354,11.315,11.315,0,0,0,11.5,16.9a12.116,12.116,0,0,0,4.124-.716l3.265,2.987,1.346-1.231L3.31,2.45Zm7.159,6.55,2.491,2.279a.52.52,0,0,1-.115.017,2.293,2.293,0,0,1-2.386-2.183C9.114,10.31,9.123,10.284,9.123,10.24ZM5.878,7.271,7.548,8.8A3.725,3.725,0,0,0,7.2,10.354a4.131,4.131,0,0,0,4.3,3.93,4.656,4.656,0,0,0,1.69-.314l.935.856a10.826,10.826,0,0,1-2.625.332,9.44,9.44,0,0,1-8.419-4.8,8.877,8.877,0,0,1,2.8-3.083Z"
    />
  ),
});

export default HidePasswordIcon;
