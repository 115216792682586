import * as React from "react";
import { IconProps } from "@chakra-ui/icons";
import { createIcon } from "@chakra-ui/react";

const CancelSubscriptionIcon: React.FC<IconProps> = createIcon({
  path: (
    <path
      fill="currentColor"
      id="Path_5857"
      data-name="Path 5857"
      d="M12,2A10,10,0,1,0,22,12,9.991,9.991,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8.011,8.011,0,0,1,12,20ZM15.59,7,12,10.59,8.41,7,7,8.41,10.59,12,7,15.59,8.41,17,12,13.41,15.59,17,17,15.59,13.41,12,17,8.41Z"
    />
  ),
});

export default CancelSubscriptionIcon;
