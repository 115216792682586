import React, { useCallback } from "react";
import { yupResolver } from "@hookform/resolvers";
import { useTranslation } from "react-i18next";
import { Text } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { addSeconds, getTime } from "date-fns";
import { useTimer } from "react-timer-hook";

import forgotPasswordSchema, { ForgotPasswordSchema } from "settings/yup/schemas/forgotPasswordSchema";
import { useRequestResetPasswordMutation } from "generated/graphql";
import Input from "components/FormComponents/Input";
import AuthBox from "components/Box/AuthBox";
import TextLink from "components/TextLink";
import Button from "components/Button";
import useToast from "hooks/useToast";
import { handleOnKeyPress } from "utils/handleOnKeyPress";

const TIME_TO_RESEND_EMAIL = 60;

const ForgotPassword: React.FC = () => {
  const [showToast] = useToast();
  const [t] = useTranslation();
  const history = useHistory();

  const {
    errors,
    register,
    handleSubmit,
  } = useForm<ForgotPasswordSchema>({
    resolver: yupResolver(forgotPasswordSchema),
    mode: "onChange",
    defaultValues: {
      email: "",
    },
  });

  const {
    restart: restartTimer,
    seconds,
  } = useTimer({
    expiryTimestamp: 0,
  });

  const [requestResetPassword, { loading }] = useRequestResetPasswordMutation();

  const onSubmit = useCallback((values) => {
    requestResetPassword({
      variables: {
        email: values.email,
      },
    })
      .then(() => {
        restartTimer(getTime(addSeconds(new Date(), TIME_TO_RESEND_EMAIL)));

        showToast({
          title: t("forgot_password.reset_password_link_has_been_sent"),
          status: "success",
        });
      })
      .catch((error) => {
        showToast({
          title: error?.message,
          status: "error",
        });
      });
  }, [
    requestResetPassword,
    showToast,
    restartTimer,
    t,
  ]);

  const isTimerActive = seconds > 0;

  return (
    <AuthBox
      title={t("forgot_password.title")}
      subtitle={t("forgot_password.subtitle")}
    >
      {
        isTimerActive && (
          <Text
            fontSize="xxs"
            color="status.error"
            textAlign="center"
          >
            {t("forgot_password.youll_be_able", { seconds })}
          </Text>
        )
      }

      <Input
        name="email"
        ref={register}
        errors={errors}
        isDisabled={isTimerActive}
        title={`${t("forgot_password.email_address")}*`}
        onKeyPress={handleOnKeyPress(handleSubmit(onSubmit))}
      />

      <Button
        onClick={handleSubmit(onSubmit)}
        disabled={isTimerActive}
        isLoading={loading}
        fontSize="xs"
        textTransform="uppercase"
      >
        {t("buttons.request_link")}
      </Button>

      <TextLink
        color="primary.500"
        fontSize="xxs"
        fontWeight="bold"
        d="flex"
        alignItems="center"
        alignSelf="center"
        onClick={history.goBack}
      >
        {t("buttons.go_back")}
      </TextLink>
    </AuthBox>
  );
};

export default ForgotPassword;
