import React from "react";
import { useTranslation } from "react-i18next";

import ListItem from "components/ListItem";

import { AddTokensPaymentInformationProps } from "./types";
import ExtraTokenPackPrice from "./ExtraTokenPackPrice";

const AddTokensPaymentInformation: React.FC<AddTokensPaymentInformationProps> = ({
  extraTokensPack,
}) => {
  const [t] = useTranslation();

  return (
    <ListItem
      title={t("dashboard.subscription.add_tokens_modal.selected_item")}
      value={(
        <ExtraTokenPackPrice
          extraTokensPack={extraTokensPack}
          key={extraTokensPack.id}
          renderAsListItem={false}
        />
      )}
    />
  );
};

export default AddTokensPaymentInformation;
