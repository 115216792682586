import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import useSteps from "views/Forms/ClientForm/hooks/useSteps";
import { FormTypeEnum } from "generated/graphql";

import { EditFormParams } from "./types";
import EditForm from ".";

const EditQuestionnaireForm: React.FC = () => {
  const [t] = useTranslation();

  const { id } = useParams<EditFormParams>();

  return (
    <EditForm
      title={t("forms.edit_questionnaire_form.title")}
      formType={FormTypeEnum.Client}
      formId={parseInt(id)}
      useSteps={useSteps}
    />
  );
};

export default EditQuestionnaireForm;
