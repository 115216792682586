import React from "react";
import { useTranslation } from "react-i18next";
import { Text, Flex } from "@chakra-ui/react";

import Box from "components/Box";
import Button from "components/Button";
import useSession from "hooks/useSession";

const NotMatched: React.FC = () => {
  const [t] = useTranslation();
  const [, endSession] = useSession();

  return (
    <Flex justifyContent="center" width="full" mt={10}>
      <Box
        p={5}
        h="full"
        w="50%"
        title={t("not_matched.title")}
        titleProps={{
          textTransform: "uppercase",
          fontFamily: "heading",
          fontSize: "xs",
        }}
      >
        <Text color="gray.400" fontSize="xs">
          {t("not_matched.description")}
        </Text>
        <Flex justifyContent="center" pt={8}>
          <Button
            onClick={endSession}
            variant="outline"
            fontSize="xs"
            color="black"
            size="sm"
            isFullWidth
          >
            {t("buttons.logout")}
          </Button>
        </Flex>
      </Box>
    </Flex>
  );
};

export default NotMatched;
