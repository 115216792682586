import React from "react";
import { Avatar, Stack, Text } from "@chakra-ui/react";

import { StatusUpdateDetailsProps } from "components/Tracking/ApplicantStatusHistoryCard/types";

import parseApplicantStatusUpdate from "./parseApplicantStatusUpdate";

const StatusUpdateDetails: React.FC<StatusUpdateDetailsProps> = ({
  statusUpdate,
}) => {
  const parsedStatusUpdate = parseApplicantStatusUpdate(statusUpdate);

  return (
    <Stack
      alignItems="center"
      isInline
    >
      <Avatar
        name={statusUpdate?.member?.name}
        src={statusUpdate?.member?.photo}
        size="sm"
      />

      <Stack
        spacing={0}
        w="full"
      >
        <Text
          fontFamily="bold"
          fontSize="xxs"
        >
          {parsedStatusUpdate.statusLabel}
        </Text>

        <Text
          fontSize="xxs"
        >
          {parsedStatusUpdate.updateLabel}
        </Text>
      </Stack>
    </Stack>
  );
};

export default StatusUpdateDetails;
