import React, { useEffect, useRef } from "react";
import { useWindowSize } from "use-window-size-hook";
import { Bar } from "react-chartjs-2";

import useChartOptions from "components/Charts/hooks/useChartOptions";

import { ApplicationsOverviewChartProps } from "./types";
import parseChartData from "./parseChartData";
import options from "./options";

const ApplicationsOverviewChart: React.FC<ApplicationsOverviewChartProps> = ({
  data,
}) => {
  const { width } = useWindowSize();

  const ref = useRef<Bar>(null);

  const chartData = parseChartData({
    applicantsCount: data?.applicants ?? 0,
    interviewsCount: data?.interviews ?? 0,
    hiredCount: data?.hired ?? 0,
  });

  const chartOptions = useChartOptions({
    options,
  });

  useEffect(() => {
    ref?.current?.chartInstance?.update?.();
  }, [width]);

  return (
    <Bar
      options={chartOptions}
      data={chartData}
      ref={ref}
      redraw
    />
  );
};

export default ApplicationsOverviewChart;
