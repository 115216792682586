import React, { useCallback } from "react";

import SelectableCard from "components/Cards/SelectableCard";

import useSelectRoleStore from "./store";
import { RoleCardProps } from "./types";

const RoleCard: React.FC<RoleCardProps> = ({
  role,
}) => {
  const {
    setRoleId,
    roleId,
  } = useSelectRoleStore((store) => ({
    setRoleId: store.setRoleId,
    roleId: store.roleId,
  }));

  const handleToggleRole = useCallback(() => {
    setRoleId(role.id);
  }, [
    setRoleId,
    role.id,
  ]);

  const isSelected = roleId === role.id;

  return (
    <SelectableCard
      onSelect={handleToggleRole}
      imageSrc={role.imageUrl}
      isSelected={isSelected}
      allowDeselect={false}
      title={role.name}
    />
  );
};

export default RoleCard;
