import React from "react";

import { ListCardContent } from "components/Tracking/Cards/CardComponents";
import { ClientCardProps } from "components/Tracking/Cards/types";
import { FIT_LABEL_FONT_SIZE } from "constants/fitLabelFontSize";

import useClientCard from ".";

const ClientListCard: React.FC<ClientCardProps> = ({
  client,
  ...props
}) => {
  const cardProps = {
    ...useClientCard(client),
    fitLabelFontSize: FIT_LABEL_FONT_SIZE,
  };

  return (
    <ListCardContent
      {...cardProps}
      {...props}
    />
  );
};

export default ClientListCard;
