import React from "react";
import { useTranslation } from "react-i18next";
import { Stack } from "@chakra-ui/react";

import { DASHBOARD_MODIFY_SUBSCRIPTION_PAGE_LOCATION } from "routes/locations";
import buildPlanBenefits from "utils/subscriptions/buildPlanBenefits";
import { formatDate, monthDayYear } from "utils/dateFormats";
import useNavigate from "hooks/useNavigate";
import ListItem from "components/ListItem";
import Button from "components/Button";
import Box from "components/Box";

import SubscriptionCardTitle from "./SubscriptionCardTitle";
import SubscriptionPrice from "./SubscriptionPrice";
import { SubscriptionCardProps } from "../types";

const SubscriptionCard: React.FC<SubscriptionCardProps> = ({
  currentIndex,
  addTokens,
  job,
}) => {
  const navigate = useNavigate();
  const [t] = useTranslation();

  const planBenefits = buildPlanBenefits({
    monthlyTokens: job?.subscription?.plan?.monthlyTokens,
    benefits: job?.subscription?.plan?.benefits ?? [],
    isFreePlan: !job?.subscription,
  });

  const billingType = t([
    `billing_types.${String(job?.subscription?.billingType ?? "").toLowerCase()}`,
    "fallbacks.free_subscription_plan.contract",
  ]);

  const canAddTokens = job?.role?.extraTokensPacks?.length > 0;

  return (
    <Box
      title={(
        <SubscriptionCardTitle
          currentIndex={currentIndex}
          job={job}
        />
      )}
      wrapperProps={{
        m: 0,
      }}
      w="full"
      h="full"
      p={0}
    >
      <Stack
        justifyContent="space-between"
        direction="column"
        spacing={8}
        h="full"
        p={5}
      >
        <Stack spacing={1}>
          <ListItem
            value={job?.subscription?.plan?.name ?? t("fallbacks.free_subscription_plan.plan")}
            title={t("dashboard.subscription.plan")}
          />

          <ListItem
            title={t("dashboard.subscription.contract")}
            value={billingType}
          />

          <ListItem
            title={t("dashboard.subscription.price")}
            value={(
              <SubscriptionPrice job={job} />
            )}
          />

          {
            job?.subscription?.endAt && (
              <ListItem
                title={t("dashboard.subscription.end_at")}
                value={formatDate(job.subscription.endAt, monthDayYear)}
              />
            )
          }

          <ListItem
            title={t("dashboard.subscription.benefits")}
            value={planBenefits}
          />
        </Stack>

        <Stack
          justifyContent="flex-end"
          alignItems="center"
          spacing={3}
          isInline
        >
          <Button
            isDisabled={!canAddTokens}
            onClick={addTokens(job)}
            fontSize="xs"
            size="sm"
          >
            {t("buttons.add_tokens")}
          </Button>

          <Button
            fontSize="xs"
            size="sm"
            onClick={navigate(DASHBOARD_MODIFY_SUBSCRIPTION_PAGE_LOCATION.toUrl({
              jobId: job.id,
            }))}
          >
            {t("buttons.modify_subscription")}
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};

export default SubscriptionCard;
