import React from "react";
import { Text, Stack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import InboxIcon from "settings/theme/icons/InboxIcon";

import { EmptyDataMessageProps } from "./types";

export const EmptyDataMessage: React.FC<EmptyDataMessageProps> = ({
  containerProps,
  isDataLoading,
  message,
}) => {
  const [t] = useTranslation();

  if (isDataLoading) {
    return null;
  }

  return (
    <Stack
      {...(containerProps || {})}
      alignItems="center"
      direction="column"
      spacing={2}
      m={6}
    >
      <InboxIcon
        color="gray.300"
        boxSize={20}
      />

      <Text
        fontSize="md"
        color="gray.400"
        textAlign="center"
      >
        {message ?? t("empty_data_messages.no_information_to_be_displayed")}
      </Text>
    </Stack>
  );
};
