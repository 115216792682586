import React from "react";
import { useTranslation } from "react-i18next";
import {
  IconButton,
  Tooltip,
  Stack,
} from "@chakra-ui/react";

import useViewPaymentReceipt from "hooks/useViewPaymentReceipt";
import ReceiptIcon from "settings/theme/icons/ReceiptIcon";

import { ReceiptButtonRowProps } from "../types";

const ReceiptButtonRow: React.FC<ReceiptButtonRowProps> = ({
  payment,
}) => {
  const [t] = useTranslation();

  const [viewPaymentReceipt, isLoading] = useViewPaymentReceipt();

  return (
    <Stack
      justifyContent="flex-end"
      alignItems="center"
      spacing={1}
      isInline
    >
      <Tooltip
        aria-label={t("dashboard.subscription.payments_table.view_receipt")}
        label={t("dashboard.subscription.payments_table.view_receipt")}
        fontSize="xxs"
        hasArrow
      >
        <IconButton
          aria-label={t("dashboard.subscription.payments_table.view_receipt")}
          onClick={viewPaymentReceipt(payment.id)}
          isLoading={isLoading}
          variant="ghost"
          icon={(
            <ReceiptIcon color="primary.200" />
          )}
        />
      </Tooltip>
    </Stack>
  );
};

export default ReceiptButtonRow;
