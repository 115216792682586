import { ButtonProps } from "@chakra-ui/react";

import { ThemeColors } from "settings/theme/types";

export enum PageVariant {
  previous = "previous",
  next = "next",
  gap = "gap"
}

export interface PageItem {
  current: boolean;
  props: Omit<ButtonProps, "colorScheme">;
  page: PageVariant | number;
}

export interface PaginationData {
  getPageItem: (index: number) => PageItem;
  itemsPerPage: number;
  size: number;
}

export interface PaginationProps {
  totalCount?: number;
  itemsPerPage?: PaginationData["itemsPerPage"];
  colorScheme?: keyof ThemeColors;
}

export interface GetPaginationComponentsParameters {
  page: number;
  setPage: (page: number) => void;
  colorScheme?: PaginationProps["colorScheme"];
  paginationData: PaginationData;
}

export interface CreatePaginationComponentParameters extends ButtonProps {
  page: number;
  setPage: (page: number) => void;
  pageItem: PageItem;
  colorScheme?: PaginationProps["colorScheme"];
  isLastPageSelected?: boolean;
}

export interface PaginationArrowProps extends ButtonProps {
  page: CreatePaginationComponentParameters["page"];
  setPage: (page: number) => void;
  pageVariant: PageItem["page"];
  isLastPageSelected?: boolean;
}

export interface PaginationButtonProps extends ButtonProps {
  page: CreatePaginationComponentParameters["page"];
  pageItem: PageItem;
  colorScheme?: PaginationProps["colorScheme"];
  setPage: (page: number) => void;
}
