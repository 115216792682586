import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Flex } from "@chakra-ui/react";

import { ExtraQuestionDisplayTypeEnum } from "generated/graphql";
import getFieldLabel from "utils/getFieldLabel";

import getQuestionComponent from "./getQuestionComponent";
import { QuestionInputProps } from "./types";

const QuestionInput: React.FC<QuestionInputProps> = ({
  questionFormIndex,
  fieldIndex,
  question,
  field,
}) => {
  const {
    register,
    control,
    errors,
  } = useFormContext();

  const isRequired = question.displayType === ExtraQuestionDisplayTypeEnum.Required;

  const pathPrefix = `answers[${questionFormIndex}].questions[${fieldIndex}]`;

  const title = getFieldLabel(question.question, isRequired);

  const name = `${pathPrefix}.value`;

  register({
    name: `${pathPrefix}.questionId`,
    value: question.id,
  });

  const {
    component: Component,
    componentProps,
    defaultValue,
    gridColumn,
  } = getQuestionComponent(question);

  return (
    <Flex
      gridColumn={gridColumn}
      w="full"
    >
      <Controller
        as={Component}
        name={name}
        control={control}
        errors={errors}
        title={title}
        defaultValue={field?.value || defaultValue}
        rules={{
          required: question.displayType === ExtraQuestionDisplayTypeEnum.Required,
        }}
        {...(componentProps ?? {})}
      />
    </Flex>
  );
};

export default QuestionInput;
