import i18n from "translations/i18n";

/**
 * Converts one file to a base64 image, if possible.
 * @param file The file.
 */
export const parseBase64ImageFromFile = (
  file: File,
): Promise<string> => new Promise((resolve, reject) => {
  const reader = new FileReader();

  reader.readAsDataURL(file);

  reader.onload = () => resolve(reader.result as string);
  reader.onerror = error => reject(error);
});

/**
 * Converts an array of files to an array of base64 images, if possible.
 * @param files The files.
 */
export const parseBase64ImageFromFiles = (
  files: File[],
): Promise<string[]> => new Promise((resolve, reject) => {
  const promises = files.map((file) => parseBase64ImageFromFile(file));

  Promise.all(promises)
    .then((result) => resolve(result))
    .catch((error) => reject(error));
});

/**
 * Converts one source string to one file of a given mime type.
 * @param source The source, as an url or base64 string.
 */
export const parseFileFromSource = (
  source: string | undefined,
  fileName: string,
  mimeType?: string,
): Promise<File> => new Promise((resolve) => {
  if (!source) {
    resolve();
    return;
  }

  fetch(source)
    .then((response) => response.blob())
    .then((blob) => {
      const file = new File([blob], fileName, { type: mimeType });

      resolve(file);
    })
    .catch(() => resolve());
});

/**
 * Converts one source string to one image file.
 * @param source The source, as an url or base64 string.
 */
export const parseImageFileFromSource = (
  source: string | undefined,
  fileName = `${i18n.t("fallbacks.image")}.jpg`,
): Promise<File> => parseFileFromSource(
  source,
  fileName,
  "image/png",
);

/**
 * Converts one source string to one document file.
 * @param source The source, as an url or base64 string.
 */
export const parseDocumentFileFromSource = (
  source: string | undefined,
  fileName = i18n.t("fallbacks.document"),
): Promise<File> => parseFileFromSource(
  source,
  fileName,
);

/**
 * Converts an array of image sources to an array of files.
 * @param sources The sources, each as an url or base64 string.
 */
export const parseImageFilesFromSources = (
  sources: string[],
): Promise<File[]> => new Promise((resolve, reject) => {
  const promises = sources.map((source) => parseImageFileFromSource(source));

  Promise.all(promises)
    .then((result) => resolve(result))
    .catch((error) => reject(error));
});
