/* eslint-disable react/no-children-prop */

import React from "react";
import MaskedInput from "react-text-mask";
import mergeRefs from "react-merge-refs";
import {
  Input as ChakraInput,
  InputRightElement,
  InputLeftElement,
  FormControl,
  InputGroup,
} from "@chakra-ui/react";

import FieldErrorMessage from "components/FormComponents/FieldErrorMessage";
import FieldTitle from "components/FormComponents/FieldTitle";
import getErrorMessage from "utils/getErrorMessage";

import maskPlaceholderChar from "./maskPlaceholderChar";
import { InputProps } from "./types";

const Input = React.forwardRef<HTMLElement, InputProps>((
  {
    errorBorderColor = "status.error",
    showErrorMessage = true,
    rightElementProps = {},
    leftElementProps = {},
    variant = "filled",
    validationMessage,
    fontSize = "xs",
    showMask = false,
    mask = false,
    rightElement,
    leftElement,
    titleProps,
    onChange,
    onBlur,
    errors,
    title,
    name,
    ...rest
  },
  ref,
) => {
  const error = getErrorMessage(name, errors);

  return (
    <FormControl>
      {
        title && (
          <FieldTitle
            htmlFor={name}
            {...(titleProps ?? {})}
          >
            {title}
          </FieldTitle>
        )
      }

      <InputGroup>
        {
          leftElement && (
            <InputLeftElement
              children={leftElement}
              {...leftElementProps}
            />
          )
        }

        <MaskedInput
          placeholderChar={maskPlaceholderChar}
          showMask={showMask}
          onChange={onChange}
          onBlur={onBlur}
          mask={mask}
          render={(maskRef, props) => (
            <ChakraInput
              {...props}
              {...rest}
              errorBorderColor={errorBorderColor}
              ref={mergeRefs([maskRef, ref])}
              fontSize={fontSize}
              isInvalid={!!error}
              variant={variant}
              name={name}
            />
          )}
        />

        {
          rightElement && (
            <InputRightElement
              children={rightElement}
              {...rightElementProps}
            />
          )
        }
      </InputGroup>

      {
        showErrorMessage && (
          <FieldErrorMessage
            validationMessage={validationMessage}
            textAlign="end"
            error={error}
          />
        )
      }
    </FormControl>
  );
});

export default Input;
