import { UseFormMethods } from "react-hook-form";

import { Form, FormTypeEnum, Job } from "generated/graphql";

export interface FormPageParams {
  shortCode: string;
}

export interface UseFormHandlerOptions {
  shortCode: string;
}

export type FormStoreState = {
  isValid: boolean;
  isDirty: boolean;
  form: Form | undefined;
  getValues: UseFormMethods["getValues"] | undefined;
  errors: UseFormMethods["errors"] | undefined;
  watch: UseFormMethods["watch"] | undefined;
  trigger: UseFormMethods["trigger"] | undefined;

  /**
   * Defines if the getForm query is loading.
   */
  isFormLoading: boolean;
  shortCode?: string;
}

export enum UseStepMode {
  Edition = "EDITION",
  Submission = "SUBMISSION",
  View = "VIEW",
}

export type OtherFormParams = {
  jobForm: Job | undefined;
  allowImageUpload: boolean;
  typeForm: FormTypeEnum;
  stepMode: UseStepMode;
}
