import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Stack } from "@chakra-ui/react";

import {
  useListImpersonatedCompanySubscriptionsQuery,
  useListCurrentCompanySubscriptionsQuery,
  useListImpersonatedCompanyPaymentsQuery,
  useListCurrentCompanyPaymentsQuery,
} from "generated/graphql";
import AddTokensModal from "components/Modal/AddTokensModal";
import usePaginationParams from "hooks/usePaginationParams";
import PageHeader from "components/PageHeaders/PageHeader";
import PlusIcon from "settings/theme/icons/PlusIcon";
import { onQueryError } from "utils/queryHandlers";
import useGetCompany from "hooks/useGetCompany";
import { useModal } from "contexts/modal";
import Loading from "components/Loading";
import Button from "components/Button";
import useToast from "hooks/useToast";

import SubscriptionCards from "./SubscriptionCards";
import CreateJobModal from "./CreateJobModal";
import PaymentsTable from "./PaymentsTable";
import { Job } from "./types";

const Subscription: React.FC = () => {
  const [showToast] = useToast();
  const [showModal] = useModal();
  const [t] = useTranslation();

  const [{
    offset,
  }] = usePaginationParams();

  const [company, {
    loading: listSubscriptionsLoading,
  }] = useGetCompany(
    useListCurrentCompanySubscriptionsQuery,
    useListImpersonatedCompanySubscriptionsQuery,
    {
      onError: (error) => {
        onQueryError(error, showToast);
      },
    },
  );

  const [companyWithPayments, {
    loading: listPaymentsLoading,
  }] = useGetCompany(
    useListCurrentCompanyPaymentsQuery,
    useListImpersonatedCompanyPaymentsQuery,
    {
      fetchPolicy: "cache-and-network",
      variables: {
        offset,
      },
      onError: (error) => {
        onQueryError(error, showToast);
      },
    },
  );

  const jobs = useMemo(() => (
    company?.jobs ?? []
  ), [
    company,
  ]);

  const payments = useMemo(() => (
    companyWithPayments?.payments ?? []
  ), [
    companyWithPayments,
  ]);

  const numberOfPayments = companyWithPayments?.numberOfPayments ?? 0;

  const companyId = company?.id;

  const availableRoles = useMemo(() => (
    company?.availableRoles ?? []
  ), [
    company,
  ]);

  const handleHireNewRole = useCallback(() => {
    showModal({
      title: t("dashboard.subscription.add_new_role"),
      component: CreateJobModal,
      componentProps: {
        existingJobs: jobs,
        companyId,
        availableRoles,
      },
    });
  }, [
    companyId,
    showModal,
    jobs,
    t,
    availableRoles,
  ]);

  const handleAddTokens = useCallback((job: Job) => () => {
    if (job.numberOfAvailableTokens === null) {
      showToast({
        title: t("errors.add_tokens_with_unlimited_tokens_plan"),
        status: "error",
      });

      return;
    }

    showModal({
      title: t("dashboard.subscription.add_tokens_modal.title"),
      component: AddTokensModal,
      componentProps: {
        roleId: job.role.id,
        companyId,
      },
    });
  }, [
    showModal,
    showToast,
    companyId,
    t,
  ]);

  return (
    <>
      <PageHeader title={t("dashboard.subscription.title")}>
        <Button
          isLoading={listSubscriptionsLoading}
          onClick={handleHireNewRole}
          rightIcon={(<PlusIcon />)}
          variant="outline"
          fontSize="xs"
          color="black"
          size="sm"
        >
          {t("dashboard.subscription.add_new_role")}
        </Button>
      </PageHeader>

      <Stack
        spacing={10}
        mt={6}
      >
        <Stack
          spacing={4}
          w="full"
        >
          {
            listSubscriptionsLoading
              ? (
                <Loading />
              )
              : (
                <SubscriptionCards
                  addTokens={handleAddTokens}
                  jobs={jobs}
                />
              )
          }
        </Stack>

        <Stack
          spacing={4}
          w="full"
        >
          {
            listPaymentsLoading
              ? (
                <Loading />
              )
              : (
                <PaymentsTable
                  numberOfPayments={numberOfPayments}
                  payments={payments}
                />
              )
          }
        </Stack>
      </Stack>
    </>
  );
};

export default Subscription;
