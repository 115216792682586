import React, { useCallback } from "react";
import { CircularProgress, Flex, Stack } from "@chakra-ui/react";
import ConditionalWrap from "conditional-wrap";
import { useDropzone } from "react-dropzone";

import { FILE_TYPE_DOCUMENTS, UPLOAD_REQUEST_MAXIMUM_SIZE_MB } from "constants/fileUpload";
import checkIsValidUploadRequestSize from "utils/checkIsValidUploadRequestSize";
import FieldTitle from "components/FormComponents/FieldTitle";
import useToast from "hooks/useToast";
import i18n from "translations/i18n";

import { DocumentUploadBoxProps } from "./types";
import DocumentPreview from "./DocumentPreview";
import Box from "./Box";

const DocumentUploadBox: React.FC<DocumentUploadBoxProps> = ({
  accept = FILE_TYPE_DOCUMENTS,
  color = "primary.300",
  boxSize = "md",
  subtitleProps,
  initialFile,
  isUploading,
  titleProps,
  subtitle,
  onUpload,
  title,
  ...props
}) => {
  const [file, setFile] = React.useState<File | undefined>(initialFile);
  const [showToast] = useToast();

  const removeFile = useCallback(() => {
    setFile(undefined);
  }, []);

  const onDrop = React.useCallback((droppedFiles: File[]) => {
    const isValidRequestSize = checkIsValidUploadRequestSize(droppedFiles);

    if (!isValidRequestSize) {
      showToast({
        status: "error",
        title: i18n.t("actions.request_size_limit", {
          limit: UPLOAD_REQUEST_MAXIMUM_SIZE_MB,
        }),
      });

      return;
    }

    const newFile = droppedFiles[0];

    setFile(newFile);

    onUpload(newFile);
  }, [
    showToast,
    onUpload,
  ]);

  const {
    getInputProps,
    getRootProps,
  } = useDropzone({
    disabled: isUploading,
    multiple: false,
    accept,
    onDrop,
  });

  return (
    <Stack spacing={4}>
      <Stack spacing={1}>
        {
          title && (
            <FieldTitle {...(titleProps ?? {})}>
              {title}
            </FieldTitle>
          )
        }

        {
          subtitle && (
            <FieldTitle
              fontSize="xs"
              color="gray.400"
              fontFamily="body"
              {...(subtitleProps ?? {})}
            >
              {subtitle}
            </FieldTitle>
          )
        }
      </Stack>

      <ConditionalWrap
        condition={!file}
        wrap={(children) => (
          <Box
            cursor={isUploading ? "progress" : "pointer"}
            boxSize={boxSize}
            {...props}
            {...getRootProps()}
          >
            {children}
          </Box>
        )}
      >
        <Flex>
          <input {...getInputProps()} />

          {
            isUploading
              ? (
                <CircularProgress color={color} />
              )
              : (
                <DocumentPreview
                  removeFile={removeFile}
                  boxSize={boxSize}
                  color={color}
                  file={file}
                />
              )
          }
        </Flex>
      </ConditionalWrap>
    </Stack>
  );
};

export default DocumentUploadBox;
