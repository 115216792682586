import * as R from "remeda";

import { LocationWithSubjects } from "generated/graphql";
import { LocationFilterOption } from "components/Tracking/types";

/**
 * Parses the select locations filter options.
 * @param data Query result.
 */
const getLocationFilterOptions = (
  data?: LocationWithSubjects[],
): LocationFilterOption[] => R.pipe(
  (data ?? []),
  R.sort((a, b) => (
    String(a.state).localeCompare(b.state)
    || String(a.city).localeCompare(b.city)
  )),
  R.map((location) => ({
    value: {
      city: location.city,
      state: location.state,
    },
    label: `${location.city}, ${location.state}`,
  })),
);

export default getLocationFilterOptions;
