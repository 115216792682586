import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  ModalFooter as ChakraModalFooter,
  Stack,
  Text,
} from "@chakra-ui/react";

import { ShareProfileSchema } from "settings/yup/schemas/profile/shareProfileSchema";
import TextLink from "components/TextLink";
import Button from "components/Button";

import { ModalFooterProps } from "../types";

const ModalFooter: React.FC<ModalFooterProps> = ({
  isLoading,
  onConfirm,
  copyLink,
}) => {
  const [t] = useTranslation();

  const { formState } = useFormContext<ShareProfileSchema>();

  return (
    <ChakraModalFooter>
      <Stack
        justifyContent="center"
        alignItems="center"
        w="full"
      >
        <Button
          isDisabled={!formState.isValid}
          isLoading={isLoading}
          onClick={onConfirm}
          fontSize="sm"
          w="full"
        >
          {t("buttons.send")}
        </Button>

        <Stack isInline>
          <Text
            color="gray.400"
            fontSize="xs"
          >
            {t("buttons.or")}
          </Text>

          <TextLink
            onClick={copyLink}
            color="primary.200"
            fontFamily="bold"
            fontSize="xs"
          >
            {t("buttons.copy_link")}
          </TextLink>
        </Stack>
      </Stack>
    </ChakraModalFooter>
  );
};

export default ModalFooter;
