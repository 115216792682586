import { ClientMatchStatus } from "constants/statuses";
import yup from "settings/yup";

const clientMatchSchema = yup.object().shape({
  search: yup
    .string(),
  status: yup
    .string(),
  roleFitScore: yup
    .array()
    .of(yup.number()),
});

export interface ClientMatchSchema {
  search: string;
  status: ClientMatchStatus;
  roleFitScore: number[];
}

export default clientMatchSchema;
