import yup from "settings/yup";

export const optionSchema = yup.object().shape({
  value: yup
    .string()
    .required(),
});

const addOptionsSchema = yup.object().shape({
  newOptions: yup
    .array()
    .of(optionSchema),
});

export interface OptionSchema {
  value: string;
}

export interface AddOptionsSchema {
  newOptions: OptionSchema[];
}

export default addOptionsSchema;
