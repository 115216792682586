import { FilterOption } from "components/Tracking/types";
import i18n from "translations/i18n";

export const availabilityAllOption = {
  label: i18n.t("filters.all"),
  value: i18n.t("filters.all"),
};

export const availabilityFullTimeOption = {
  label: i18n.t("filters.only_full_time"),
  value: "1",
};

export const availabilityPartTimeOption = {
  label: i18n.t("filters.only_part_time"),
  value: "0",
};

/**
 * The options of the availability filter
 */
const availabilityOptions: FilterOption[] = [
  availabilityFullTimeOption,
  availabilityPartTimeOption,
];

export default availabilityOptions;
