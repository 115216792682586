import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Flex, Stack } from "@chakra-ui/react";

import useDashboardTokensAlertStore from "views/Dashboard/DashboardContent/DashboardTokensAlert/store";
import { dashboardTokensAlertHeight } from "views/Dashboard/DashboardContent/DashboardTokensAlert";
import { useListAdminImpersonatorCompaniesQuery } from "generated/graphql";
import { SelectOption } from "components/FormComponents/Select/types";
import FieldTitle from "components/FormComponents/FieldTitle";
import Select from "components/FormComponents/Select";
import { headerHeight } from "components/Header";

import getSelectOptions from "./getSelectOptions";
import useAdminImpersonationStore from "./store";

export const adminImpersonationSelectHeight = {
  css: "60px",
  value: 60,
};

/**
 * Returns the top position of the admin impersonation select, according to the visibility
 * of other components that may result in the need to dislocate it.
 */
const getTopPosition = (
  hasOptions: boolean,
  isDashboardTokensAlertVisible: boolean,
): string => {
  if (!hasOptions) {
    return "0px";
  }

  let result = headerHeight.value;

  if (isDashboardTokensAlertVisible) {
    result += dashboardTokensAlertHeight.value;
  }

  return `${result}px`;
};

/**
 * Renders the selector for the admin to select a company to impersonate.
 * Updates the store with the select result.
 */
const AdminImpersonationSelect: React.FC = () => {
  const [t] = useTranslation();

  const { data } = useListAdminImpersonatorCompaniesQuery();

  const [options, setOptions] = useState<SelectOption[]>(
    getSelectOptions(
      data?.currentUser?.member?.company?.id,
      data?.listCompanies,
    ),
  );

  const companyId = useAdminImpersonationStore((store) => store.companyId);

  const isDashboardTokensAlertVisible = useDashboardTokensAlertStore((store) => store.isVisible);

  const onChange = useCallback((option) => {
    const company = (data?.listCompanies ?? []).find((item) => (
      item.id === option?.value
    ));

    useAdminImpersonationStore.setState({
      companyId: option?.value,
      company,
    });
  }, [
    data,
  ]);

  useEffect(() => {
    setOptions(getSelectOptions(
      data?.currentUser?.member?.company?.id,
      data?.listCompanies,
    ));
  }, [
    data,
  ]);

  const value = options.find((option) => option.value === companyId);

  const top = getTopPosition(!!options.length, isDashboardTokensAlertVisible);

  return (
    <Flex
      transform="translate(50%)"
      borderBottomRadius="md"
      borderColor="gray.200"
      transition="all 0.5s"
      alignItems="center"
      position="absolute"
      bgColor="white"
      boxShadow="md"
      minWidth="md"
      right="50%"
      height={adminImpersonationSelectHeight.css}
      borderBottomWidth={1}
      borderRightWidth={1}
      borderLeftWidth={1}
      zIndex={14}
      top={top}
    >
      <Stack
        alignItems="center"
        width="full"
        padding={3}
        isInline
      >
        <FieldTitle
          whiteSpace="nowrap"
          fontSize="xs"
          mb={0}
        >
          {`${t("admin_impersonation.view_as_company")}:`}
        </FieldTitle>

        <Select
          onChange={onChange}
          options={options}
          value={value}
          isClearable
        />
      </Stack>
    </Flex>
  );
};

export default AdminImpersonationSelect;
