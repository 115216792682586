import React, { useCallback, useMemo, useState } from "react";
import {
  Fade,
  Modal,
  Heading,
  Divider,
  ModalHeader,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  ModalCloseButton,
} from "@chakra-ui/react";

import { ThemeSizeKey } from "settings/theme/types";

import {
  ModalContextPayload,
  ModalContainerProps,
  OnChakraModalClose,
  ShowModalOptions,
  ModalState,
} from "./types";
import { ModalProvider } from ".";

function ModalContainer<T = Record<string, unknown>>({
  children,
}: ModalContainerProps<T>): React.ReactElement {
  const defaultState = useMemo<ModalState<T>>(
    () => ({
      title: "",
      size: "lg",
      showDivider: true,
      onClose: undefined,
      component: undefined,
      isCloseable: true,
      colorScheme: "primary",
      componentProps: {},
      modalHeaderProps: {},
      modalContentProps: {},
      modalCloseButtonProps: {},
    }),
    [],
  );
  const [newComponentProps, setNewComponentProps] = useState<T | Record<string, unknown>>({});

  const [modalState, setModalState] = useState<ModalState<T>>(defaultState);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const showModal = useCallback((options: ShowModalOptions<T> | unknown) => {
    const modalOptions = options as ShowModalOptions<T>;
    const newState = {
      ...defaultState,
      ...modalOptions,
      isCloseable: modalOptions?.isCloseable ?? defaultState.isCloseable,
    };

    setModalState(newState);
    onOpen();
  }, [
    defaultState,
    onOpen,
  ]);

  const hideModal = useCallback((payload) => {
    modalState.onClose?.(payload ?? {});

    setModalState(defaultState);
    onClose();
  }, [
    defaultState,
    modalState,
    onClose,
  ]);

  const updateComponentProps = useCallback((props: Record<string, unknown>) => {
    const componentProps = props ?? {};
    setNewComponentProps(componentProps);
  },
  []);

  const payload = useMemo<ModalContextPayload<T>>(() => [
    showModal,
    hideModal,
    {
      isOpen,
    },
    updateComponentProps,
  ], [
    showModal,
    hideModal,
    isOpen,
    updateComponentProps,
  ]);

  const Component = modalState?.component;
  const componentProps = (modalState?.componentProps || {}) as T;

  return (
    <ModalProvider value={payload}>
      {children}

      <Fade in={isOpen}>
        <Modal
          closeOnOverlayClick={modalState?.isCloseable}
          onClose={hideModal as OnChakraModalClose}
          closeOnEsc={modalState?.isCloseable}
          size={modalState?.size as string}
          isOpen={isOpen}
          isCentered
        >
          <ModalOverlay zIndex={1000}>
            <ModalContent {...(modalState?.modalContentProps ?? {})}>
              {
                modalState?.title && (
                  <>
                    <ModalHeader {...(modalState?.modalHeaderProps ?? {})}>
                      <Heading
                        color={`${defaultState?.colorScheme}.200`}
                        textAlign="center"
                        fontSize="md"
                      >
                        {modalState?.title}
                      </Heading>
                    </ModalHeader>

                    {
                      modalState?.showDivider && (
                        <Divider borderColor="gray.100" />
                      )
                    }
                  </>
                )
              }

              {
                modalState?.isCloseable && (
                  <ModalCloseButton
                    {...(modalState?.modalCloseButtonProps ?? {})}
                  />
                )
              }

              {
                Component && (
                  <Component
                    size={modalState?.size as ThemeSizeKey}
                    componentProps={{ ...componentProps, ...newComponentProps }}
                    hideModal={hideModal}
                  />
                )
              }
            </ModalContent>
          </ModalOverlay>
        </Modal>
      </Fade>
    </ModalProvider>
  );
}

export default ModalContainer;
