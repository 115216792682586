import React, { useMemo, useCallback, useEffect } from "react";
import { CheckboxGroup, Divider, VStack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import {
  applicantsInProgressFilterValues,
  closedApplicantsFilterValues,
  allApplicantsFilterValues,
  allStatusOption,
} from "components/Tracking/Filters/StatusFilters/options";
import {
  applicantsInProgressStatusSelectOptions,
  closedApplicantsStatusSelectOptions,
} from "constants/statuses";
import getIsStringArraysEqual from "utils/getIsStringArrayEqual";
import createTrackingFilter from "components/Tracking/Filters";
import Checkbox from "components/FormComponents/Checkbox";
import useFilter, { filters } from "hooks/useFilter";

const ApplicantStatusFilter = createTrackingFilter(({
  setTitle,
}) => {
  const [t] = useTranslation();
  const [status, setStatus] = useFilter(filters.status);

  const value = useMemo<string[]>(() => (
    status ? JSON.parse(status as string) : []
  ), [status]);

  useEffect(() => {
    setTitle(`${t("filters.status")}:`);
  }, [
    setTitle,
    t,
  ]);

  useEffect(() => {
    const hasInitialValue = (
      !!status
      && Array.isArray(value)
      && value.length > 0
    );

    if (hasInitialValue) {
      return;
    }

    setStatus(JSON.stringify(applicantsInProgressFilterValues));
  }, [
    value,
    status,
    setStatus,
  ]);

  const onChange = useCallback((option) => {
    const shouldSetFilter = Array.isArray(option) && option?.length > 0;

    if (shouldSetFilter) {
      setStatus(JSON.stringify(option));
    } else {
      setStatus(undefined);
    }
  }, [setStatus]);

  const handleGroupFilter = useCallback(
    (filterValues) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const shouldSelectFilter = event.target.checked;

      if (shouldSelectFilter) {
        setStatus(JSON.stringify(filterValues));
      } else {
        setStatus(undefined);
      }
    },
    [setStatus],
  );

  return (
    <VStack spacing={2} align="flex-start">
      <Checkbox
        isChecked={getIsStringArraysEqual(allApplicantsFilterValues, value)}
        onChange={handleGroupFilter(allApplicantsFilterValues)}
        value={allStatusOption.value}
        name={allStatusOption.label}
      >
        {allStatusOption.label}
      </Checkbox>

      <VStack spacing={2}>
        <Divider />

        <Checkbox
          isChecked={getIsStringArraysEqual(applicantsInProgressFilterValues, value)}
          onChange={handleGroupFilter(applicantsInProgressFilterValues)}
          name={t("filters.applicants_in_progress")}
        >
          {t("filters.applicants_in_progress")}
        </Checkbox>

        <CheckboxGroup
          onChange={onChange}
          value={value}
        >
          {
            applicantsInProgressStatusSelectOptions.map(option => (
              <Checkbox
                key={option.value}
                name={option.label}
                value={option.value}
              >
                {option.label}
              </Checkbox>
            ))
          }
        </CheckboxGroup>
      </VStack>

      <VStack spacing={2}>
        <Divider />

        <Checkbox
          isChecked={getIsStringArraysEqual(closedApplicantsFilterValues, value)}
          onChange={handleGroupFilter(closedApplicantsFilterValues)}
          name={t("filters.closed_applicants")}
        >
          {t("filters.closed_applicants")}
        </Checkbox>

        <CheckboxGroup
          onChange={onChange}
          value={value}
        >
          {
            closedApplicantsStatusSelectOptions.map(option => (
              <Checkbox
                key={option.value}
                name={option.label}
                value={option.value}
              >
                {option.label}
              </Checkbox>
            ))
          }
        </CheckboxGroup>
      </VStack>
    </VStack>
  );
});

export default ApplicantStatusFilter;
