import React, { useCallback, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import { useTranslation } from "react-i18next";
import {
  HStack,
  VStack,
  Stack,
} from "@chakra-ui/react";

import updateCompanySchema, { UpdateCompanySchema } from "settings/yup/schemas/updateCompanySchema";
import { updateCompanyRefetchQueries } from "graphql/mutations/updateCompanyMutation";
import AvatarPreviewInput from "components/FormComponents/AvatarPreviewInput";
import FormContainer from "components/FormComponents/FormContainer";
import FormHeader from "components/FormComponents/FormHeader";
import { useUpdateCompanyMutation } from "generated/graphql";
import { ZIP_CODE_MASK, STATE_MASK } from "constants/masks";
import { handleOnKeyPress } from "utils/handleOnKeyPress";
import Input from "components/FormComponents/Input";
import useToast from "hooks/useToast";

import { CompanyAccountFormProps } from "./types";

const CompanyAccountForm: React.FC<CompanyAccountFormProps> = ({
  company,
}) => {
  const [showToast] = useToast();
  const [t] = useTranslation();

  const [logoFile, setLogoFile] = useState<File>();

  const [
    updateCompany,
    { loading: loadingCompanyMutation },
  ] = useUpdateCompanyMutation({
    refetchQueries: updateCompanyRefetchQueries,
  });

  const {
    handleSubmit,
    formState,
    control,
    errors,
  } = useForm<UpdateCompanySchema>({
    resolver: yupResolver(updateCompanySchema),
    mode: "onChange",
    defaultValues: {
      name: company?.name ?? "",
      location: {
        streetAddress: company?.location?.streetAddress ?? "",
        complement: company?.location?.complement ?? "",
        zipCode: company?.location?.zipCode ?? "",
        state: company?.location?.state ?? "",
        city: company?.location?.city ?? "",
      },
    },
  });

  const onSubmit = useCallback((values) => {
    const companyId = company?.id;

    if (!companyId) {
      return;
    }

    updateCompany({
      variables: {
        id: companyId,
        params: {
          ...values,
          logo: logoFile,
        },
      },
    })
      .then(() => {
        showToast({
          status: "success",
          title: t("company_account.account_updated"),
        });
      })
      .catch((error) => {
        showToast({
          title: error?.message,
          status: "error",
        });
      });
  }, [
    t,
    logoFile,
    showToast,
    updateCompany,
    company,
  ]);

  const isButtonDisabled = loadingCompanyMutation || (
    formState.isSubmitted
    && !formState.isValid
  );

  return (
    <>
      <FormHeader
        title={t("company_account.title")}
        isButtonDisabled={isButtonDisabled}
        onSubmit={handleSubmit(onSubmit)}
      />

      <FormContainer>
        <VStack spacing={6} alignItems="center">
          <AvatarPreviewInput
            defaultPreview={company?.logoUrl}
            onChange={setLogoFile}
            isLogo
          />
        </VStack>

        <Stack width="full" spacing={6} paddingTop={10}>
          <HStack
            alignItems="flex-start"
            display="flex"
            spacing={6}
          >
            <Controller
              as={Input}
              title={t("company_sign_up.company_name")}
              name="name"
              control={control}
              errors={errors}
              onKeyPress={handleOnKeyPress(handleSubmit(onSubmit))}
            />
          </HStack>

          <HStack
            display="flex"
            spacing={6}
            alignItems="flex-start"
          >
            <Controller
              as={Input}
              title={t("forms.address.company_address")}
              name="location.streetAddress"
              errors={errors}
              control={control}
            />

            <Controller
              as={Input}
              title={t("forms.address.zip_code")}
              name="location.zipCode"
              mask={ZIP_CODE_MASK}
              control={control}
              errors={errors}
              onKeyPress={handleOnKeyPress(handleSubmit(onSubmit))}
            />
          </HStack>

          <HStack
            display="flex"
            spacing={6}
            alignItems="flex-start"
          >
            <Controller
              as={Input}
              title={t("forms.address.city")}
              name="location.city"
              control={control}
              errors={errors}
              onKeyPress={handleOnKeyPress(handleSubmit(onSubmit))}
            />

            <Controller
              as={Input}
              title={t("forms.address.state")}
              name="location.state"
              control={control}
              errors={errors}
              onKeyPress={handleOnKeyPress(handleSubmit(onSubmit))}
              textTransform="uppercase"
              mask={STATE_MASK}
            />
          </HStack>

          <HStack
            display="flex"
            spacing={6}
            alignItems="flex-start"
          >
            <Controller
              as={Input}
              title={t("forms.address.complement_suite")}
              name="location.complement"
              control={control}
              errors={errors}
              onKeyPress={handleOnKeyPress(handleSubmit(onSubmit))}
            />
          </HStack>
        </Stack>
      </FormContainer>
    </>
  );
};

export default CompanyAccountForm;
