import { useEffect, useState } from "react";
import { useMount } from "react-use";

import useFilter, { filters } from "hooks/useFilter";

import useCompanyOnboardingFormStore from "../store";

/**
 * Automatically persists the selected step in the URL.
 *
 * The step will be persisted in the URL as a n + 1 count, so that index 0 for example becomes 1.
 * The returned result will be the correct index, that is, index 0 will be 0.
 */
const useCompanyOnboardingFormQueryStringPersistor = (): void => {
  const [querySelectedStepIndex, setQuerySelectedStepIndex] = useFilter(filters.selectedStep);

  const selectedStepIndex = useCompanyOnboardingFormStore((store) => store.selectedStepIndex);

  const [hasInitialized, setHasInitialized] = useState(false);

  /**
   * On mount, we persist the currently selected step that comes from the url.
   */
  useMount(() => {
    if (querySelectedStepIndex) {
      useCompanyOnboardingFormStore.setState({
        selectedStepIndex: (querySelectedStepIndex as number) - 1,
      });

      setHasInitialized(true);
    }
  });

  /**
   * If the hook has already persisted the url, we only
   * need to update the filter when step changes.
   */
  useEffect(() => {
    if (!hasInitialized) {
      return;
    }

    setQuerySelectedStepIndex(selectedStepIndex + 1);
  }, [
    setQuerySelectedStepIndex,
    selectedStepIndex,
    hasInitialized,
  ]);
};

export default useCompanyOnboardingFormQueryStringPersistor;
