import React, { useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";

import useCompanyOnboardingExtraSectionEffects from "components/Forms/CompanyOnboardingForm/hooks/useCompanyOnboardingExtraSectionEffects";
import { createOrUpdateExtraQuestionRefetchQueries } from "graphql/mutations/createOrUpdateExtraQuestionMutation";
import useCompanyOnboardingForm from "components/Forms/CompanyOnboardingForm/hooks/useCompanyOnboardingForm";
import extraSectionSchema, { ExtraSectionSchema } from "settings/yup/schemas/onboarding/extraSectionSchema";
import useCompanyOnboardingFormStore from "components/Forms/CompanyOnboardingForm/store";
import { useCreateOrUpdateExtraQuestionMutation } from "generated/graphql";
import { onMutationSuccess, onQueryError } from "utils/queryHandlers";
import Textarea from "components/FormComponents/Textarea";
import useToast from "hooks/useToast";
import Box from "components/Box";

import { ExtraQuestionDisplayTypePayload, ExtraSectionFormProps } from "./types";
import getFormDefaultValues from "./getFormDefaultValues";
import ExtraQuestionCard from "./ExtraQuestionCard";

const ExtraSectionForm: React.FC<ExtraSectionFormProps> = ({
  extraSection,
}) => {
  const [showToast] = useToast();
  const [t] = useTranslation();

  const formId = useCompanyOnboardingFormStore((store) => store.form?.id as number);

  const extraSectionRef = useRef(extraSection);

  extraSectionRef.current = extraSection;

  const [createOrUpdateExtraQuestion] = useCreateOrUpdateExtraQuestionMutation({
    refetchQueries: createOrUpdateExtraQuestionRefetchQueries,
  });

  const form = useCompanyOnboardingForm<ExtraSectionSchema>({
    schema: extraSectionSchema,
    formOptions: {
      defaultValues: getFormDefaultValues(extraSection),
    },
  });

  useCompanyOnboardingExtraSectionEffects({
    fields: [
      "minimumNumber",
      "customIntro",
    ],
    form,
  });

  const {
    register,
    control,
    errors,
    reset,
  } = form;

  const updateExtraQuestionDisplayType = useCallback(
    (payload: ExtraQuestionDisplayTypePayload) => {
      createOrUpdateExtraQuestion({
        variables: {
          extraQuestionId: payload.id,
          formId,
          params: {
            displayType: payload.displayType,
          },
        },
      })
        .then(() => {
          onMutationSuccess(t("actions.information_updated"), showToast);
        })
        .catch((error) => {
          onQueryError(error, showToast);
        });
    },
    [
      createOrUpdateExtraQuestion,
      showToast,
      formId,
      t,
    ],
  );

  useEffect(() => {
    reset(getFormDefaultValues(extraSectionRef.current));
  }, [
    extraSection,
    reset,
  ]);

  register({
    name: "minimumNumber",
  });

  const extraQuestions = extraSection.extraQuestions ?? [];

  const isDisabled = !extraSection.show;

  return (
    <>
      <Box
        w="full"
        h="auto"
        isDisabled={isDisabled}
        title={t("question_types.introductory_description_optional")}
        wrapperProps={{
          mb: 0,
        }}
        titleProps={{
          fontFamily: "bold",
        }}
      >
        <Controller
          as={Textarea}
          isDisabled={isDisabled}
          name="customIntro"
          control={control}
          errors={errors}
          rows={5}
        />
      </Box>

      {
        extraQuestions.map((extraQuestion) => (
          <ExtraQuestionCard
            updateExtraQuestionDisplayType={updateExtraQuestionDisplayType}
            extraQuestion={extraQuestion}
            isDisabled={isDisabled}
            key={extraQuestion.id}
          />
        ))
      }
    </>
  );
};

export default ExtraSectionForm;
