import { QuestionCardProps } from "components/Forms/CompanyOnboardingForm/Cards/QuestionCard/types";
import i18n from "translations/i18n";

const questions: QuestionCardProps[] = [
  {
    title: i18n.t("question_types.single_choice_index", { index: 1 }),
    fields: [
      {
        label: {
          text: i18n.t("company_onboarding.steps.availability.question_1.field_1"),
        },
        value: {
          text: i18n.t("question_display_types.required_by_default"),
        },
      },
      {
        label: {
          text: i18n.t("company_onboarding.steps.availability.question_1.field_2"),
        },
        value: {
          text: i18n.t("question_display_types.show_option_by_default"),
        },
      },
      {
        label: {
          text: i18n.t("company_onboarding.steps.availability.question_1.field_3"),
        },
        value: {
          text: i18n.t("question_display_types.show_option_by_default"),
        },
      },
    ],
  },
  {
    title: i18n.t("question_types.date_picker_index", { index: 2 }),
    fields: [
      {
        label: {
          text: i18n.t("company_onboarding.steps.availability.question_2.field_1"),
        },
        value: {
          text: i18n.t("question_display_types.required_by_default"),
        },
      },
    ],
  },
  {
    title: i18n.t("question_types.multiple_choice_index", { index: 3 }),
    fields: [
      {
        label: {
          text: i18n.t("company_onboarding.steps.availability.question_3.field_1"),
        },
        value: {
          text: i18n.t("question_display_types.required_by_default"),
        },
      },
      {
        label: {
          text: i18n.t("company_onboarding.steps.availability.question_3.field_2"),
        },
        value: {
          text: i18n.t("question_display_types.show_option_by_default"),
        },
      },
    ],
  },
  {
    title: i18n.t("question_types.free_text_index", { index: 4 }),
    fields: [
      {
        label: {
          text: i18n.t("company_onboarding.steps.availability.question_4.field_1"),
        },
        value: {
          text: i18n.t("question_display_types.optional_by_default"),
        },
      },
    ],
  },
];

export default questions;
