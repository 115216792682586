import * as R from "remeda";

import freePlanImage from "assets/images/plans/free-plan.svg";
import i18n from "translations/i18n";

import { Plan, Plans } from "../types";

/**
 * Builds the array of available plans, adding the free plan details.
 * @param plans The plans as returned by the backend.
 */
const buildAvailablePlans = (plans: Plans): Plan[] => {
  const freePlan = {
    name: i18n.t("fallbacks.free_subscription_plan.plan"),
    iconUrl: freePlanImage,
    isFreePlan: true,
    benefits: [
      i18n.t("fallbacks.free_subscription_plan.benefits.1"),
      i18n.t("fallbacks.free_subscription_plan.benefits.2"),
    ],
    annualPrice: {
      formatted: i18n.t("fallbacks.free_subscription_plan.contract"),
    },
    monthlyPrice: {
      formatted: i18n.t("fallbacks.free_subscription_plan.contract"),
    },
  } as Plan;

  return [
    freePlan,
    ...R.pipe(
      plans,
      R.map((plan): Plan => ({
        ...plan,
        isFreePlan: false,
      })),
    ),
  ];
};

export default buildAvailablePlans;
