import yup from "settings/yup";

const textSchema = yup.object().shape({
  text: yup
    .string(),
});

export interface TextSchema {
  text?: string;
}

export default textSchema;
