import * as React from "react";
import { IconProps } from "@chakra-ui/icons";
import { createIcon } from "@chakra-ui/react";

const DropdownIcon: React.FC<IconProps> = createIcon({
  viewBox: "0 0 12 12",
  path: (
    <path
      fill="currentColor"
      d="M7,10l5,5,5-5Z"
      transform="translate(-6 -6)"
    />
  ),
});

export default DropdownIcon;
