import React, { useCallback, useEffect } from "react";
import { RadioGroup, VStack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import createTrackingFilter from "components/Tracking/Filters";
import Radio from "components/FormComponents/Radio";
import useFilter, { filters } from "hooks/useFilter";

import options, { availabilityAllOption } from "./availabilityOptions";

const AvailabilityFilter = createTrackingFilter(({
  setTitle,
}) => {
  const [t] = useTranslation();

  const [isFullTime, setIsFullTime] = useFilter(filters.isFullTime);

  useEffect(() => {
    setTitle(`${t("filters.availability")}:`);
  }, [
    setTitle,
    t,
  ]);

  useEffect(() => {
    const hasInitialValue = !!isFullTime;

    if (hasInitialValue) {
      return;
    }

    setIsFullTime(undefined);
  }, [
    isFullTime,
    setIsFullTime,
  ]);

  const handleAllAvailabilityFilter = useCallback((value) => {
    const shouldClearFilter = value === availabilityAllOption.value;

    if (shouldClearFilter) {
      setIsFullTime(undefined);

      return;
    }

    setIsFullTime(value);
  }, [setIsFullTime]);

  return (
    <RadioGroup
      defaultValue={availabilityAllOption.value}
      onChange={handleAllAvailabilityFilter}
      name="availability"
    >
      <VStack spacing={2}>
        <Radio
          name={availabilityAllOption.label}
          value={availabilityAllOption.value}
        >
          {availabilityAllOption.label}
        </Radio>

        {
          options?.map(option => (
            <Radio
              key={option.value}
              name={option.label}
              value={option.value}
            >
              {option.label}
            </Radio>
          ))
        }
      </VStack>
    </RadioGroup>
  );
});

export default AvailabilityFilter;
