import { useMemo } from "react";

import { useGetHeaderCurrentUserQuery } from "generated/graphql";

import { UseValidUserMemberPayload } from "./types";

/**
 * Hook to get the current user member and validate if the user is a member
 */
const useUserMember = (): UseValidUserMemberPayload => {
  const { data, loading } = useGetHeaderCurrentUserQuery();

  const headerUser = data?.currentUser;
  const userAsMember = headerUser?.member;

  const payload = useMemo(() => ({
    headerUser,
    userAsMember,
    loading,
  }), [
    headerUser,
    userAsMember,
    loading,
  ]);

  return payload;
};

export default useUserMember;
