import React, { useCallback } from "react";
import {
  HStack,
  Stack,
  Flex,
  Text,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import PasswordInput from "components/FormComponents/Input/PasswordInput";
import Checkbox from "components/FormComponents/Checkbox";
import AddressForm from "components/Forms/AddressForm";
import Input from "components/FormComponents/Input";
import { TERMS_LINK } from "constants/links";
import useNavigate from "hooks/useNavigate";
import TextLink from "components/TextLink";
import { LOGIN_PAGE_PATH } from "routes";
import Button from "components/Button";

import { CompanySignUpFormProps } from "./types";

const CompanySignUpFields: React.FC<CompanySignUpFormProps> = ({
  handleSubmit,
  formState,
  register,
  onSubmit,
  control,
  loading,
  errors,
}) => {
  const navigate = useNavigate();
  const [t] = useTranslation();

  const handleViewTermsAndConditions = useCallback((event) => {
    event?.preventDefault?.();
    event?.stopPropagation?.();

    window.open(TERMS_LINK, "_blank");
  }, []);

  return (
    <Stack spacing={6}>
      <Input
        title={`${t("company_sign_up.company_name")}*`}
        name="companyName"
        ref={register}
        errors={errors}
      />

      <AddressForm
        complementFieldTitle={t("forms.address.complement_suite")}
        schemaAddressPath="address"
        control={control}
        errors={errors}
        isRequired
      />

      <Input
        title={`${t("company_sign_up.manager_name")}*`}
        name="managerName"
        ref={register}
        errors={errors}
      />

      <Input
        title={`${t("company_sign_up.email_address")}*`}
        name="email"
        ref={register}
        errors={errors}
      />

      <HStack
        spacing={4}
        d="flex"
        alignItems="flex-start"
      >
        <PasswordInput
          title={`${t("company_sign_up.password")}*`}
          name="password"
          ref={register}
          errors={errors}
        />

        <PasswordInput
          title={`${t("company_sign_up.confirm_password")}*`}
          name="confirmPassword"
          ref={register}
          errors={errors}
        />
      </HStack>

      <Stack
        spacing={8}
        d="flex"
        flexDir="column"
        w="full"
      >
        <Stack spacing={4}>
          <Checkbox
            name="terms"
            ref={register}
            errors={errors}
            alignItems="baseline"
            borderColor="primary.100"
            renderChildrenAsText={false}
            mt={6}
          >
            <Text
              fontSize="xxs"
              as="span"
              d="flex"
            >
              {t("actions.i_have_reviewed_the_terms")}

              <TextLink
                onClick={handleViewTermsAndConditions}
                color="primary.500"
                fontSize="xxs"
                ml={1}
              >
                {TERMS_LINK}
              </TextLink>
            </Text>
          </Checkbox>

          <Checkbox
            name="subscribedToMailingList"
            ref={register}
            errors={errors}
            alignItems="baseline"
            borderColor="primary.100"
            fontSize="xxs"
            defaultIsChecked
          >
            {t("actions.i_agree_to_receive_marketing_emails")}
          </Checkbox>
        </Stack>

        <Button
          onClick={handleSubmit(onSubmit)}
          disabled={!formState?.isValid}
          isLoading={loading}
          fontSize="xs"
          textTransform="uppercase"
        >
          {t("buttons.sign_up")}
        </Button>
      </Stack>

      <Flex
        justifyContent="center"
        alignItems="center"
        fontSize="xxs"
      >
        {t("company_sign_up.already_have_an_account")}
        <TextLink
          ml={1}
          color="primary.500"
          fontSize="xxs"
          fontWeight="bold"
          onClick={navigate(LOGIN_PAGE_PATH)}
        >
          {t("buttons.login")}
        </TextLink>
      </Flex>
    </Stack>
  );
};

export default CompanySignUpFields;
