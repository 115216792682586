import React, { useEffect, useRef, useState } from "react";
import { Textarea as ChakraTextarea, FormControl } from "@chakra-ui/react";
import mergeRefs from "react-merge-refs";

import getErrorMessage from "utils/getErrorMessage";
import FieldErrorMessage from "components/FormComponents/FieldErrorMessage";
import FieldTitle from "components/FormComponents/FieldTitle";

import { TextareaProps } from "./types";

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>((
  {
    showErrorMessage = true,
    autoHeight = false,
    variant = "filled",
    validationMessage,
    fontSize = "xs",
    titleProps,
    children,
    errors,
    title,
    name,
    ...rest
  },
  ref,
) => {
  const error = getErrorMessage(name, errors);

  const textareaRef = useRef<HTMLTextAreaElement>();

  const [height, setHeight] = useState<string>();

  useEffect(() => {
    if (!autoHeight || !textareaRef.current) {
      return;
    }

    const newHeight = `${textareaRef.current.scrollHeight}px`;

    setHeight(newHeight);
  }, [
    textareaRef,
    autoHeight,
  ]);

  return (
    <FormControl>
      {
        title && (
          <FieldTitle
            htmlFor={name}
            {...(titleProps ?? {})}
          >
            {title}
          </FieldTitle>
        )
      }

      <ChakraTextarea
        {...rest}
        ref={mergeRefs([ref, textareaRef])}
        errorBorderColor="status.error"
        isInvalid={!!error}
        fontSize={fontSize}
        variant={variant}
        height={height}
        name={name}
      >
        {children}
      </ChakraTextarea>

      {
        showErrorMessage && (
          <FieldErrorMessage
            validationMessage={validationMessage}
            textAlign="end"
            error={error}
          />
        )
      }
    </FormControl>
  );
});

export default Textarea;
