import * as React from "react";
import { IconProps } from "@chakra-ui/icons";

import DashboardArrowDownIcon from "./DashboardArrowDownIcon";

const DashboardArrowUpIcon = React.forwardRef<SVGSVGElement, IconProps>((props, ref) => (
  <DashboardArrowDownIcon
    {...props}
    ref={ref}
    transform="rotate(180deg)"
  />
));

export default DashboardArrowUpIcon;
