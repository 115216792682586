/**
 * Parses a date value.
 * @param {*} value The value
 */
const parseDate = (
  value: unknown,
): Date | null => {
  if (!value) {
    return null;
  }

  const date = new Date(String(value));

  if (Number.isNaN(date.getTime())) {
    return null;
  }

  return date;
};

export default parseDate;
