const hostname = String(window?.location?.hostname || "");

const homologationRegex = /[a-z0-9 -]*.milimatch.jungsoft.dev/;

const homologationHostname = hostname.match(homologationRegex)?.[0];

/**
 * Exports the app's current hostname & homologation hostname.
 */
const appHostnames = {
  hostname,
  homologationHostname,
};

export default appHostnames;
