import {
  APPLICANTS_IN_PROGRESS_STATUSES,
  CLIENTS_LIST_DEFAULT_STATUSES,
  CLOSED_APPLICANTS_STATUSES,
  clientStatusSelectOptions,
  APPLICANT_STATUSES,
} from "constants/statuses";
import i18n from "translations/i18n";

export const allStatusOption = {
  label: i18n.t("filters.all"),
  value: i18n.t("filters.all"),
};

export const allApplicantsFilterValues = (
  APPLICANT_STATUSES.map((option) => option.value)
);

/**
 * The default values of the applicant status filter
 */
export const applicantsInProgressFilterValues = (
  APPLICANTS_IN_PROGRESS_STATUSES.map((option) => option.value)
);

/**
 * The values for closed applicants in status filter
 */
export const closedApplicantsFilterValues = (
  CLOSED_APPLICANTS_STATUSES.map((option) => option.value)
);

/**
 * The options values of the clients status filter
 */
export const clientStatusFilterOptionsValues: string[] = clientStatusSelectOptions.map(
  (statusOption) => String(statusOption.value),
);

/**
 * The default values of the clients status filter
 */
export const clientStatusFilterDefaultValues = (
  CLIENTS_LIST_DEFAULT_STATUSES.map(defaultOption => defaultOption.value)
);
