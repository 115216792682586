import { SourceSchema } from "settings/yup/schemas/form/sourceSchema";
import { ApplicantResponse } from "generated/graphql";

/**
 * Defines which referral options should show the input to allow the user to type more details.
 */
export const referrerOptionsWithInput = [
  "Other",
  "Referral",
];

/**
 * Parses form default values.
 */
export const getDefaultValues = (
  referrerOptions: string[],
  formResponse?: ApplicantResponse,
): SourceSchema => {
  if (!formResponse?.source) {
    return {
      source: "",
      sourceInput: "",
    };
  }

  const isSelectableOption = (
    formResponse?.source
    && referrerOptions.includes(formResponse?.source)
  );

  return {
    source: (
      isSelectableOption
        ? formResponse?.source ?? ""
        : referrerOptionsWithInput[0]
    ),
    sourceInput: (
      isSelectableOption
        ? ""
        : formResponse?.source ?? ""
    ),
  };
};
