import React from "react";
import { useTranslation } from "react-i18next";
import { Stack } from "@chakra-ui/react";

import ListItem from "components/ListItem";

import ExtraTokenPackPrice from "./ExtraTokenPackPrice";
import { ExtraTokensPacksPricesProps } from "./types";

const ExtraTokensPacksPrices: React.FC<ExtraTokensPacksPricesProps> = ({
  extraTokensPacks,
}) => {
  const [t] = useTranslation();

  return (
    <ListItem
      title={t("dashboard.subscription.add_tokens_modal.price")}
      value={(
        <Stack>
          {
            extraTokensPacks.map((extraTokensPack) => (
              <ExtraTokenPackPrice
                extraTokensPack={extraTokensPack}
                key={extraTokensPack.id}
              />
            ))
          }
        </Stack>
      )}
      containerProps={{
        alignItems: "flex-start",
        isInline: false,
      }}
    />
  );
};

export default ExtraTokensPacksPrices;
