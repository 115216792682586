import React, { useMemo } from "react";
import { FormProvider } from "react-hook-form";
import { SimpleGrid } from "@chakra-ui/react";

import Loading from "components/Loading";

import { ConfigureLandingPageDefaultValues, ConfigureLandingPageFormProps } from "./types";
import useConfigureLandingPageHandler from "./hooks/useConfigureLandingPageHandler";
import LandingPageContentCard from "./Cards/LandingPageContentCard";
import LandingPageStyleCard from "./Cards/LandingPageStyleCard";

const ConfigureLandingPageForm: React.FC<ConfigureLandingPageFormProps> = ({
  landingPageLayoutLabel,
  initialValues,
  jobId,
}) => {
  const defaultValues = useMemo<ConfigureLandingPageDefaultValues>(() => ({
    headerImageUrl: initialValues?.headerImageUrl,
    jobDescription: initialValues?.jobDescription,
    logoUrl: initialValues?.logoUrl ?? "",
    buttonText: initialValues?.buttonText,
    subtitle: initialValues?.subtitle,
    color: initialValues?.color,
    title: initialValues?.title,
  }), [
    initialValues,
  ]);

  const [form, isLoading] = useConfigureLandingPageHandler({
    landingPageLayoutLabel,
    defaultValues,
    jobId,
  });

  if (isLoading) {
    return (
      <Loading />
    );
  }

  return (
    <FormProvider {...form}>
      <SimpleGrid
        columns={[1, null, 2]}
        gap={5}
      >
        <LandingPageContentCard />

        <LandingPageStyleCard />
      </SimpleGrid>
    </FormProvider>
  );
};

export default ConfigureLandingPageForm;
