import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { LandingPageTemplateLayoutLabel } from "components/LandingPage/Templates/landingPagesTemplatesData";
import useLandingPagePreview from "hooks/useLandingPagePreview";
import SelectableCard from "components/Cards/SelectableCard";
import EditIcon from "settings/theme/icons/EditIcon";
import { useModal } from "contexts/modal";
import ConfirmationModal from "components/Modal/ConfirmationModal";

import useSelectLandingPageStore from "./store";
import { TemplateCardProps } from "./types";

const TemplateCard: React.FC<TemplateCardProps> = ({
  onTemplateDelete,
  onEditTemplate,
  showEditButton,
  allowDeselect,
  template,
  onChange,
}) => {
  const [showLandingPagePreview] = useLandingPagePreview();
  const [showModal] = useModal();
  const [t] = useTranslation();

  const {
    setLayoutLabel,
    layoutLabel,
  } = useSelectLandingPageStore((store) => ({
    layoutLabel: store.layoutLabel as LandingPageTemplateLayoutLabel,
    setLayoutLabel: store.setLayoutLabel,
  }));

  const handleToggleTemplate = useCallback(() => {
    if (layoutLabel === template.layoutLabel) {
      if (allowDeselect) {
        if (!template.id) {
          return;
        }

        showModal({
          title: t("delete_template_modal.are_you_sure"),
          component: ConfirmationModal,
          componentProps: {
            text: t("delete_template_modal.all_changes_will_be_lost"),
            onConfirm: () => {
              onTemplateDelete?.(template);

              setLayoutLabel(undefined);
            },
          },
        });
      }

      return;
    }

    setLayoutLabel(template.layoutLabel);

    onChange?.(template);
  }, [
    onTemplateDelete,
    setLayoutLabel,
    allowDeselect,
    layoutLabel,
    showModal,
    template,
    onChange,
    t,
  ]);

  const isSelected = layoutLabel === template.layoutLabel;

  return (
    <SelectableCard
      onTitleIconClick={onEditTemplate?.(layoutLabel)}
      onCardClick={showLandingPagePreview(template)}
      imageTooltip={t("buttons.preview")}
      onSelect={handleToggleTemplate}
      imageSrc={template.screenshot}
      allowDeselect={allowDeselect}
      isSelected={isSelected}
      title={template.name}
      titleIcon={(
        (showEditButton && isSelected)
          ? <EditIcon color="primary.200" />
          : undefined
      )}
      imageProps={{
        objectFit: "scale-down",
        shadow: "md",
      }}
    />
  );
};

export default TemplateCard;
