import { theme as chakraUiTheme } from "@chakra-ui/react";

import { ThemeFonts } from "./types";

const fonts: ThemeFonts = {
  ...chakraUiTheme.fonts,
  heading: "Avenir Medium, sans-serif",
  bold: "Avenir Heavy, sans-serif",
  extrabold: "Avenir Black, sans-serif",
  body: "Avenir Book, sans-serif",
  mono: "Avenir Book, sans-serif",
};

export default fonts;
