import create from "zustand";

import { AdminImpersonationStoreState } from "./types";

const useAdminImpersonationStore = create<AdminImpersonationStoreState>((_set, get) => ({
  companyId: undefined,
  company: undefined,
  isVisible: false,
  getCompanyId: (fallback) => {
    const { companyId } = get();

    return companyId ?? fallback;
  },
}));

export default useAdminImpersonationStore;
