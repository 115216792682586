import React from "react";
import { Flex, Heading, Stack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import useDownloadApplicantResume from "hooks/useDownloadApplicantResume";
import DownloadIcon from "settings/theme/icons/DownloadIcon";
import { noPrintCss } from "styles/css";
import Button from "components/Button";
import Box from "components/Box";

import { ApplicantStatusCardProps } from "./types";
import StatusCardForm from "./StatusCardForm";

const ApplicantStatusCard: React.FC<ApplicantStatusCardProps> = ({
  tourClassNames,
  isPrintLayout,
  applicant,
}) => {
  const [t] = useTranslation();

  const [downloadResume, isLoading] = useDownloadApplicantResume({
    applicantId: applicant?.id as number,
  });

  return (
    <Box
      borderRadius={10}
      w="full"
      h="full"
    >
      <Flex
        direction="column"
        h="full"
      >
        <Heading fontSize="md" mb={5}>
          {`${t("applicant_profile.update_applicant_status")}:`}
        </Heading>

        <Stack
          justifyContent="space-between"
          h="full"
        >
          <StatusCardForm
            tourClassNames={tourClassNames}
            isPrintLayout={isPrintLayout}
            applicant={applicant}
          />

          <Button
            isDisabled={!applicant?.resumeUrl}
            rightIcon={<DownloadIcon />}
            onClick={downloadResume}
            isLoading={isLoading}
            colorScheme="primary"
            css={noPrintCss}
            fontSize="xs"
          >
            {t("applicant_profile.view_applicant_resume")}
          </Button>
        </Stack>
      </Flex>
    </Box>
  );
};

export default ApplicantStatusCard;
