import React from "react";

import Image from "components/Images/Image";

import { PhotoModalProps } from "./types";

const PhotoModal: React.FC<PhotoModalProps> = ({
  componentProps,
}) => (
  <Image
    src={componentProps.pictureUrl}
    maxH="95vh"
    minFallbackHeight="md"
    objectFit="contain"
  />
);

export default PhotoModal;
