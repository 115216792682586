import React from "react";
import {
  Tabs as ChakraTabs,
  TabPanels,
  TabPanel,
  TabList,
  Tab,
} from "@chakra-ui/react";

import { TabsProps } from "./types";

const Tabs: React.FC<TabsProps> = ({
  selectedColorScheme = "primary.100",
  colorScheme = "primary.50",
  isFitted = true,
  injectProps,
  items,
  ...rest
}) => (
  <ChakraTabs
    isFitted={isFitted}
    {...rest}
  >
    <TabList>
      {
        items.map(({
          disabled,
          title,
        }) => (
          <Tab
            _selected={{
              borderColor: selectedColorScheme,
              color: selectedColorScheme,
            }}
            _focus={{ boxShadow: "none" }}
            _active={{ background: "" }}
            borderBottom="3px solid"
            disabled={disabled}
            color={colorScheme}
            fontFamily="bold"
            key={title}
          >
            {title}
          </Tab>
        ))
      }
    </TabList>

    <TabPanels>
      {
        items.map(({
          component: Component,
          title,
        }) => (
          <TabPanel key={title} py={2}>
            <Component
              {...(injectProps ?? {})}
            />
          </TabPanel>
        ))
      }
    </TabPanels>
  </ChakraTabs>
);

export default Tabs;
