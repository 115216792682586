import React from "react";
import { Button as ChakraButton } from "@chakra-ui/react";

import { ButtonProps } from "./types";

const Button: React.FC<ButtonProps> = ({
  colorScheme = "primary",
  children,
  ...rest
}) => (
  <ChakraButton
    colorScheme={colorScheme}
    {...rest}
  >
    {children}
  </ChakraButton>
);

export default Button;
