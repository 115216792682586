import React from "react";
import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";
import { Stack } from "@chakra-ui/react";

import { PersonalInformationSchema } from "settings/yup/schemas/form/personalInformationSchema";
import PhoneInput from "components/FormComponents/PhoneInput";
import AddressForm from "components/Forms/AddressForm";
import Input from "components/FormComponents/Input";

import { PersonalInformationFormProps } from "./types";
import useFormStep from "../hooks/useFormStep";

const PersonalInformationForm: React.FC<PersonalInformationFormProps> = ({
  isAddressRequired,
  formResponse,
  schema,
}) => {
  const [t] = useTranslation();

  const {
    control,
    errors,
  } = useFormStep<PersonalInformationSchema>({
    schema,
    formOptions: {
      defaultValues: {
        phoneNumber: formResponse?.phoneNumber ?? "",
        firstName: formResponse?.firstName ?? "",
        lastName: formResponse?.lastName ?? "",
        email: formResponse?.email ?? "",
        address: {
          streetAddress: formResponse?.streetAddress ?? "",
          complement: formResponse?.complement ?? "",
          longitude: formResponse?.longitude ?? "",
          latitude: formResponse?.latitude ?? "",
          zipCode: formResponse?.zipCode ?? "",
          state: formResponse?.state ?? "",
          city: formResponse?.city ?? "",
        },
      },
    },
  });

  return (
    <Stack spacing={6}>
      <Stack
        spacing={6}
        isInline
      >
        <Controller
          as={Input}
          name="firstName"
          control={control}
          errors={errors}
          title={`${t("forms.steps.personal_information.first_name")}*`}
        />

        <Controller
          as={Input}
          name="lastName"
          control={control}
          errors={errors}
          title={`${t("forms.steps.personal_information.last_name")}*`}
        />
      </Stack>

      <Stack
        spacing={6}
        isInline
      >
        <Controller
          as={PhoneInput}
          name="phoneNumber"
          control={control}
          errors={errors}
          title={`${t("forms.steps.personal_information.phone_number")}*`}
        />

        <Controller
          as={Input}
          name="email"
          control={control}
          errors={errors}
          title={`${t("forms.steps.personal_information.email_address")}*`}
        />
      </Stack>

      <AddressForm
        complementFieldTitle={t("forms.address.complement_apt")}
        isRequired={isAddressRequired}
        schemaAddressPath="address"
        control={control}
        errors={errors}
      />
    </Stack>
  );
};

export default PersonalInformationForm;
