import React from "react";
import {
  Avatar,
  Stack,
  Text,
  Flex,
} from "@chakra-ui/react";

import buildPlanBenefits from "utils/subscriptions/buildPlanBenefits";

import { PlanCardBodyProps } from "../../types";
import PlanPriceSelector from "./PlanPriceSelector";
import PlanPrice from "./PlanPrice";

const PlanCardBody: React.FC<PlanCardBodyProps> = ({
  setSelectedBillingType,
  selectedBillingType,
  plan,
}) => {
  const planBenefits = buildPlanBenefits({
    monthlyTokens: plan.monthlyTokens,
    benefits: plan.benefits ?? [],
    isFreePlan: plan.isFreePlan,
  });

  return (
    <Stack
      spacing={10}
      p={5}
    >
      <Stack
        alignItems="center"
        spacing={4}
      >
        <Avatar
          borderColor="primary.100"
          src={plan.iconUrl}
          bgColor="white"
          showBorder
          size="2xl"
          css={{
            img: {
              objectFit: "scale-down",
            },
          }}
        />

        <PlanPrice
          selectedBillingType={selectedBillingType}
          plan={plan}
        />

        <PlanPriceSelector
          setSelectedBillingType={setSelectedBillingType}
          selectedBillingType={selectedBillingType}
          plan={plan}
        />
      </Stack>

      <Stack
        spacing={4}
        pl={3}
      >
        {
          planBenefits.map((benefit) => (
            <Stack
              alignItems="center"
              key={benefit}
              isInline
            >
              <Flex
                bgColor="primary.200"
                borderRadius="full"
                boxSize={4}
                minW={4}
                minH={4}
              />

              <Text fontSize="xs">
                {benefit}
              </Text>
            </Stack>
          ))
        }
      </Stack>
    </Stack>
  );
};

export default PlanCardBody;
