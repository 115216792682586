import React from "react";
import ConditionalWrap from "conditional-wrap";
import { useTranslation } from "react-i18next";
import {
  IconButton,
  Tooltip,
  Divider,
  Stack,
  Text,
  Flex,
  Box,
} from "@chakra-ui/react";

import Image from "components/Images/Image";
import TextLink from "components/TextLink";

import { SelectableCardProps } from "./types";

const SelectableCard: React.FC<SelectableCardProps> = ({
  allowDeselect = true,
  onTitleIconClick,
  imageTooltip,
  onCardClick,
  isSelected,
  imageProps,
  titleIcon,
  onSelect,
  imageSrc,
  title,
}) => {
  const [t] = useTranslation();

  const selectedLabel = (
    allowDeselect
      ? t("buttons.deselect")
      : t("buttons.selected")
  );

  return (
    <Flex
      as={Box}
      borderColor={isSelected ? "primary.100" : "transparent"}
      direction="column"
      borderWidth="2px"
      borderRadius="md"
      bgColor="white"
      boxSize="xs"
      shadow="md"
    >
      <Stack
        justifyContent="space-between"
        alignItems="center"
        isInline
        h={12}
        p={4}
      >
        <Stack
          alignItems="center"
          isInline
        >
          <Text
            textTransform="uppercase"
            fontSize="xs"
          >
            {title}
          </Text>

          {
            titleIcon && (
              <IconButton
                onClick={onTitleIconClick}
                variant="unstyled"
                aria-label={title}
                icon={titleIcon}
              />
            )
          }
        </Stack>

        <TextLink
          onClick={onSelect}
          color="primary.200"
          fontFamily="bold"
        >
          {
            isSelected
              ? selectedLabel
              : t("buttons.select")
          }
        </TextLink>
      </Stack>

      <Divider borderColor="gray.100" />

      <Flex
        cursor={onCardClick && "pointer"}
        onClick={onCardClick}
        flexGrow={1}
        p={4}
      >
        <ConditionalWrap
          condition={!!imageTooltip}
          wrap={(children) => (
            <Tooltip
              aria-label={imageTooltip}
              label={imageTooltip}
              fontSize="xxs"
              hasArrow
            >
              {children}
            </Tooltip>
          )}
        >
          <Image
            src={imageSrc}
            maxHeight="100%"
            height="3xs"
            {...(imageProps ?? {})}
          />
        </ConditionalWrap>
      </Flex>
    </Flex>
  );
};

export default SelectableCard;
