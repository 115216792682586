import { useMemo } from "react";
import { useQueryParams } from "use-query-params";
import * as R from "remeda";

import useFilter, { filters } from "hooks/useFilter";
import { PAGINATION_LIMIT_ITEMS_PER_PAGE } from "constants/pagination";
import getPaginationOffset from "components/Pagination/getPaginationOffset";

import { UsePaginationParamsPayload } from "./types";

/**
 * Returns the pagination param parsed from query params
 */
const usePaginationParams = (): UsePaginationParamsPayload => {
  const [page, setPage] = useFilter<number>(filters.page, "pushIn");

  const [paginationParams] = useQueryParams({
    [filters.orderBy.name]: filters.orderBy.type,
  });

  const { orderBy } = paginationParams ?? {};

  const offset = (
    page
      ? getPaginationOffset(
        page,
        PAGINATION_LIMIT_ITEMS_PER_PAGE,
      )
      : 0
  );

  const parsedPaginationParams = useMemo(() => ({
    page,
    setPage,
    offset,
    orderBy: orderBy as string,
  }),
  [
    setPage,
    orderBy,
    offset,
    page,
  ]);

  const activePaginationParamsCount = R.pipe(
    Object.keys(paginationParams),
    R.filter((key) => !!paginationParams[key]),
  ).length;

  const payload = useMemo<UsePaginationParamsPayload>(() => [
    parsedPaginationParams,
    activePaginationParamsCount,
  ], [
    parsedPaginationParams,
    activePaginationParamsCount,
  ]);

  return payload;
};

export default usePaginationParams;
