import React, { useEffect, useState } from "react";
import * as R from "remeda";

import { parseDocumentFileFromSource } from "utils/base64";
import Loading from "components/Loading";

import useApplicantFormResponse from "../hooks/useFormResponse/useApplicantFormResponse";
import ResumeForm from "./ResumeForm";

const Resume: React.FC = () => {
  const [isLoadingFile, setIsLoadingFile] = useState(true);
  const [resumeFile, setResumeFile] = useState<File>();

  const [formResponse, loading] = useApplicantFormResponse();

  /**
   * Initializes the document file in form values.
   */
  useEffect(() => {
    if (loading || !isLoadingFile) {
      return;
    }

    if (!formResponse?.resume) {
      setIsLoadingFile(false);
      return;
    }

    parseDocumentFileFromSource(formResponse.resume)
      .then(setResumeFile)
      .catch(R.noop)
      .finally(() => {
        setIsLoadingFile(false);
      });
  }, [
    formResponse,
    isLoadingFile,
    loading,
  ]);

  if (isLoadingFile || loading) {
    return (
      <Loading />
    );
  }

  return (
    <ResumeForm
      resumeFile={resumeFile}
    />
  );
};

export default Resume;
