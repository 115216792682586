import create from "zustand";

import { CompanyOnboardingFormState } from "./types";

const useCompanyOnboardingFormStore = create<CompanyOnboardingFormState>((set, get) => ({
  isFormLoading: true,
  formType: undefined,
  form: undefined,
  steps: [],
  selectedStepIndex: 0,
  getStep: () => {
    const state = get();

    return state.steps?.[state.selectedStepIndex];
  },
  setSelectedStepIndex: (index) => set({
    selectedStepIndex: index,
  }),
  setValue: undefined,
  watch: undefined,
}));

export default useCompanyOnboardingFormStore;
