import { ObjectSchema } from "yup";

import yup from "settings/yup";
import minWordsTest from "settings/yup/tests/minWordsTest";

const buildQuestionSchema = (isRequired: boolean): ObjectSchema => yup.object().shape({
  label: yup
    .string(),
  value: (
    isRequired
      ? yup
        .string()
        .test(minWordsTest())
        .required()
      : yup.string()
  ),
});

export const buildPersonalStorySchema = (isRequired: boolean): ObjectSchema => yup.object().shape({
  questions: yup
    .array()
    .of(buildQuestionSchema(isRequired)),
  firstImage: yup
    .mixed(),
  secondImage: yup
    .mixed(),
});

export interface QuestionSchema {
  label: string;
  value: string;
}

export interface PersonalStorySchema {
  questions: QuestionSchema[];
  firstImage?: File;
  secondImage?: File;
}
