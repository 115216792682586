import { useMemo } from "react";
import { useQueryParams } from "use-query-params";
import * as R from "remeda";

import { filters } from "hooks/useFilter";
import { LocationFilterOption } from "components/Tracking/types";

import { UseClientFiltersParamsPayload } from "./types";

/**
 * Returns the client filters param parsed from query params
 */
const useClientFiltersParams = (): UseClientFiltersParamsPayload => {
  const [clientFiltersParams] = useQueryParams({
    [filters.jobId.name]: filters.jobId.type,
    [filters.locations.name]: filters.locations.type,
    [filters.status.name]: filters.status.type,
  });

  const {
    jobId,
    status,
    locations,
  } = clientFiltersParams ?? {};

  const parsedClientFiltersParam = useMemo(() => ({
    jobId: parseInt(jobId as string),
    status: (
      status
        ? JSON.parse(status)
        : undefined
    ),
    locations: (
      locations
        ? (JSON.parse(locations) ?? [])
          .map((location: LocationFilterOption) => location.value)
        : undefined
    ),
  }), [
    jobId,
    status,
    locations,
  ]);

  const activeFiltersCount = R.pipe(
    Object.keys(parsedClientFiltersParam),
    R.filter((key) => !!parsedClientFiltersParam[key]),
  ).length;

  const payload = useMemo<UseClientFiltersParamsPayload>(() => [
    parsedClientFiltersParam,
    activeFiltersCount,
  ], [
    parsedClientFiltersParam,
    activeFiltersCount,
  ]);

  return payload;
};

export default useClientFiltersParams;
