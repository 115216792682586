import React from "react";

import useCompanyOnboardingFormStore from "components/Forms/CompanyOnboardingForm/store";
import Loading from "components/Loading";

import ExtraSectionForm from "./ExtraSectionForm";

const ExtraSection: React.FC = () => {
  const extraSection = useCompanyOnboardingFormStore((store) => store.getStep()?.extraSection);

  if (!extraSection) {
    return (
      <Loading />
    );
  }

  return (
    <ExtraSectionForm
      extraSection={extraSection}
    />
  );
};

export default ExtraSection;
