import React from "react";
import { Flex, Spinner } from "@chakra-ui/react";

import Box from "components/Box";

import { FormContainerProps } from "./types";

const FormContainer: React.FC<FormContainerProps> = ({
  isLoading = false,
  children,
}) => (
  isLoading ? (
    <Flex
      width="full"
      height="full"
      alignItems="center"
      justifyContent="center"
      marginTop={20}
    >
      <Spinner size="xl" />
    </Flex>
  ) : (
    <Box
      width="full"
      height="auto"
      marginTop={12}
    >
      {children}
    </Box>
  )
);

export default FormContainer;
