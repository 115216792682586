import React from "react";
import { useTranslation } from "react-i18next";
import { Text, Wrap } from "@chakra-ui/react";

import { parseOpenAnswers } from "components/FormSteps/PersonalStory/formValues";
import { PreviewComponentProps } from "views/Forms/FormPreview/types";
import getFieldLabel from "utils/getFieldLabel";
import Image from "components/Images/Image";

import PreviewContent from "../PreviewComponents/PreviewContent";

const PersonalStory: React.FC<PreviewComponentProps> = ({
  formResponse,
  isRequired,
  form,
  step,
}) => {
  const [t] = useTranslation();

  const questionNumber = step?.questionNumber || 0;

  const isImageUploadStep = step?.isImageUploadStep;

  const openAnswers = parseOpenAnswers(formResponse?.openAnswers);

  const currentOpenAnswer = !isImageUploadStep && openAnswers[questionNumber];

  const firstPictureSrc = formResponse.pictures?.[0];

  const secondPictureSrc = formResponse.pictures?.[1];

  const showUploadImageSection = form.allowImagesUpload;

  return (
    <>
      {
        currentOpenAnswer && (
          <PreviewContent
            title={getFieldLabel(currentOpenAnswer.question, isRequired)}
            key={currentOpenAnswer.question}
          >
            {currentOpenAnswer.answer}
          </PreviewContent>
        )
      }

      {
        isImageUploadStep && showUploadImageSection && (
          <PreviewContent
            title={t("forms.steps.personal_story.a_picture_is_worth_a_thousand_words")}
            renderChildrenAsText={false}
          >
            {
              (firstPictureSrc || secondPictureSrc)
                ? (
                  <Wrap spacing={10}>
                    {
                      firstPictureSrc && (
                        <Image
                          src={firstPictureSrc}
                          showDownloadIcon
                          boxSize="xxs"
                        />
                      )
                    }

                    {
                      secondPictureSrc && (
                        <Image
                          src={secondPictureSrc}
                          showDownloadIcon
                          boxSize="xxs"
                        />
                      )
                    }
                  </Wrap>
                )
                : (
                  <Text
                    color="gray.400"
                    fontSize="xs"
                  >
                    {t("fallbacks.no_pictures_added")}
                  </Text>
                )
            }
          </PreviewContent>
        )
      }
    </>
  );
};

export default PersonalStory;
