import React from "react";

import { formatDate, monthDayYear, monthName } from "utils/dateFormats";
import { Column } from "components/Table/types";
import i18n from "translations/i18n";

import ReceiptButtonRow from "./ReceiptButtonRow";
import { Payment } from "../types";

/**
 * Builds the table columns.
 */
export const makeTableColumns = (): Column<Payment>[] => [
  {
    title: i18n.t("dashboard.subscription.payments_table.type"),
    fieldResolver: (payment) => {
      if (payment.tokensBought) {
        return i18n.t("dashboard.subscription.purchase_tokens_payment", {
          role: payment.extraTokensPack?.role?.name,
          amount: payment.tokensBought,
        });
      }

      return i18n.t("dashboard.subscription.subscription_payment", {
        plan: payment.subscription?.plan?.name,
        role: payment.subscription?.plan?.role?.name,
      });
    },
  },
  {
    title: i18n.t("dashboard.subscription.payments_table.month"),
    fieldResolver: (payment) => (
      formatDate(payment.paidAt, monthName)
    ),
  },
  {
    title: i18n.t("dashboard.subscription.payments_table.payment_date"),
    fieldResolver: (payment) => (
      formatDate(payment.paidAt, monthDayYear)
    ),
  },
  {
    title: i18n.t("dashboard.subscription.payments_table.price"),
    fieldResolver: (payment) => payment.amount?.formatted,
    cellProps: {
      borderRightWidth: 0,
    },
  },
  {
    render: ({
      row: payment,
    }) => (
      <ReceiptButtonRow payment={payment} />
    ),
  },
];
