import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Text } from "@chakra-ui/react";

import { formatDate, monthDayYear } from "utils/dateFormats";

import { ModifySubscriptionModalProps } from "../types";

const DowngradeConfirmationModalText: React.FC<ModifySubscriptionModalProps> = ({
  simulateSubscriptionResult,
  billingType,
}) => {
  const [t] = useTranslation();

  const billingPeriod = (
    t(`billing_types.${String(billingType).toLowerCase()}`)
  ).toLowerCase();

  return (
    <Trans>
      <Text fontSize="sm">
        {t("modify_subscription_modal.please_note_your_subscription_ends_at", {
          endAt: formatDate(simulateSubscriptionResult.currentSubscriptionEndAt, monthDayYear),
          finalPrice: simulateSubscriptionResult.finalPrice.formatted,
          newPlanName: simulateSubscriptionResult.newPlan.name,
          billingPeriod,
        })}
      </Text>
    </Trans>
  );
};

export default DowngradeConfirmationModalText;
