import yup from "settings/yup";

const personalStorySchema = yup.object().shape({
  introductionText: yup.string(),
  allowImagesUpload: yup.bool(),
});

export interface PersonalStorySchema {
  introductionText?: string;
  allowImagesUpload?: boolean;
}

export default personalStorySchema;
