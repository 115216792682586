import React from "react";

import { applicantPersonalInformationSchema } from "settings/yup/schemas/form/personalInformationSchema";
import Loading from "components/Loading";

import useApplicantFormResponse from "../hooks/useFormResponse/useApplicantFormResponse";
import PersonalInformationForm from "./PersonalInformationForm";

const ApplicantPersonalInformation: React.FC = () => {
  const [formResponse, loading] = useApplicantFormResponse();

  if (loading) {
    return (
      <Loading />
    );
  }

  return (
    <PersonalInformationForm
      schema={applicantPersonalInformationSchema}
      formResponse={formResponse}
      isAddressRequired={false}
    />
  );
};

export default ApplicantPersonalInformation;
