import rgba from "rgba-convert";

import theme from "settings/theme";

const backgroundImageFilterOpacity = "80";

const backgroundImageFilter = rgba.css(`${theme.colors.gray["400"]}${backgroundImageFilterOpacity}`);

/**
 * Returns the background image rule value for landing pages templates.
 */
export const getBackgroundImage = (headerImageUrl: string): string => (
  `linear-gradient(to bottom, ${backgroundImageFilter}, ${backgroundImageFilter}), url(${headerImageUrl})`
);
