import React, { useCallback } from "react";
import ReactSlider, { ReactSliderProps } from "react-slider";
import { FormControl } from "@chakra-ui/react";

import getErrorMessage from "utils/getErrorMessage";
import FieldErrorMessage from "components/FormComponents/FieldErrorMessage";
import FieldTitle from "components/FormComponents/FieldTitle";

import getThumbCount from "./getThumbCount";
import SliderTrack from "./SliderTrack";
import SliderThumb from "./SliderThumb";
import { SliderProps } from "./types";

const Slider = React.forwardRef<React.Component<ReactSliderProps>, SliderProps>((
  {
    showErrorMessage = true,
    colorScheme = "primary",
    labelPrefix = "",
    labelSuffix = "",
    defaultValue,
    titleProps,
    errors,
    title,
    value,
    name,
    ...rest
  },
  ref,
) => {
  const error = getErrorMessage(name, errors);

  const thumbCount = getThumbCount(value ?? defaultValue);

  const renderThumb = useCallback((props, state) => (
    <SliderThumb
      colorScheme={colorScheme}
      labelPrefix={labelPrefix}
      labelSuffix={labelSuffix}
      thumbProps={props}
      key={state.index}
      state={state}
    />
  ), [
    colorScheme,
    labelPrefix,
    labelSuffix,
  ]);

  const renderTrack = useCallback((props, state) => (
    <SliderTrack
      colorScheme={colorScheme}
      thumbCount={thumbCount}
      trackProps={props}
      key={state.index}
      state={state}
    />
  ), [
    colorScheme,
    thumbCount,
  ]);

  return (
    <FormControl minHeight="80px">
      {
        title && (
          <FieldTitle
            htmlFor={name}
            {...(titleProps ?? {})}
          >
            {title}
          </FieldTitle>
        )
      }

      <ReactSlider
        {...rest}
        defaultValue={defaultValue}
        renderThumb={renderThumb}
        renderTrack={renderTrack}
        value={value}
        ref={ref}
      />

      {
        showErrorMessage && (
          <FieldErrorMessage
            textAlign="end"
            error={error}
          />
        )
      }
    </FormControl>
  );
});

export default Slider;
