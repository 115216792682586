import React, { useMemo } from "react";

import { QuestionSchema } from "settings/yup/schemas/form/personalStorySchema";

import { UseCurrentPersonalStory } from "./type";

const useCurrentPersonalStoryValues = (): UseCurrentPersonalStory => {
  const [currentStoryValues, setCurrentStoryValues] = React.useState<
  QuestionSchema[] | null
  >(null);

  const payload = useMemo<UseCurrentPersonalStory>(() => [
    currentStoryValues,
    setCurrentStoryValues,
  ], [currentStoryValues]);

  return payload;
};

export default useCurrentPersonalStoryValues;
