import React, { useMemo } from "react";

import useClientFiltersParams from "hooks/useClientFiltersParams";
import useGetCompany from "hooks/useGetCompany";
import {
  useGetImpersonatedCompanyWithJobsQuery,
  useGetCurrentCompanyWithJobsQuery,
  useListClientLocationsQuery,
} from "generated/graphql";

import { TrackingFiltersQueriesContextPayload } from "./types";
import { TrackingFiltersQueriesProvider } from ".";

const ClientsFiltersQueriesContainer: React.FC = ({ children }) => {
  const [{ jobId }] = useClientFiltersParams();

  const [company, { loading: companyLoading }] = useGetCompany(
    useGetCurrentCompanyWithJobsQuery,
    useGetImpersonatedCompanyWithJobsQuery,
  );

  const {
    loading: listClientLocationsLoading,
    data: listClientLocationsData,
  } = useListClientLocationsQuery({
    skip: !jobId,
    variables: {
      jobId,
    },
  });

  const loading = !!(
    listClientLocationsLoading
    || companyLoading
  );

  const jobs = useMemo(() => (
    company?.jobs ?? []
  ), [
    company,
  ]);

  const payload = useMemo<TrackingFiltersQueriesContextPayload>(() => ({
    locations: listClientLocationsData?.listClientLocations ?? [],
    loading,
    jobs,
  }), [
    listClientLocationsData,
    loading,
    jobs,
  ]);

  return (
    <TrackingFiltersQueriesProvider value={payload}>
      {children}
    </TrackingFiltersQueriesProvider>
  );
};

export default ClientsFiltersQueriesContainer;
