import React from "react";
import {
  Heading,
  Stack,
  Flex,
} from "@chakra-ui/react";

import { profilePrintChartCss } from "styles/css";
import Box from "components/Box";
import HorizontalBarChart from "components/Charts/HorizontalBarChart";

import { TrackingProfileAdditionalQualitiesChartProps } from "./types";

/**
 * Renders a chart within a styled card.
 * @param param0 Props.
 */
const TrackingProfileAdditionalQualitiesChart: React.FC<
TrackingProfileAdditionalQualitiesChartProps
> = ({
  colorScheme = "additionalQualities",
  comparisonDatasetLabel,
  comparisonColorScheme,
  showAverageValues,
  mainDatasetLabel,
  comparisonScores,
  containerProps,
  scores,
  title,
}) => (
  <Box
    borderRadius={10}
    w="full"
    h="full"
    css={profilePrintChartCss}
    {...(containerProps ?? {})}
  >
    <Flex
      direction="column"
      h="full"
    >
      <Stack
        isInline
        alignItems="center"
        justifyContent="space-between"
        h={10}
      >
        <Heading fontSize="md">
          {title}
        </Heading>
      </Stack>

      <Flex
        className="additional-qualities-chart"
        justifyContent="center"
        alignItems="center"
        position="relative"
        minHeight="sm"
        h="1000px"
        p={3}
      >
        <HorizontalBarChart
          comparisonDatasetLabel={comparisonDatasetLabel}
          comparisonColorScheme={comparisonColorScheme}
          showAverageValues={showAverageValues}
          mainDatasetLabel={mainDatasetLabel}
          comparisonScores={comparisonScores}
          colorScheme={colorScheme}
          scores={scores}
        />
      </Flex>
    </Flex>
  </Box>
);

export default TrackingProfileAdditionalQualitiesChart;
