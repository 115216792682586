import { ChartOptions } from "chart.js";
import deepMerge from "deepmerge";

import defaultChartOptions from "components/Charts/defaultChartOptions";
import theme from "settings/theme";

/**
 * Default options for spider charts.
 */
const options: ChartOptions = deepMerge(defaultChartOptions, {
  maintainAspectRatio: false,
  scale: {
    ticks: {
      display: false,
      min: 0,
      max: 1.2,
      stepSize: 0.25,
    },
    pointLabels: {
      fontFamily: theme.fonts.heading,
      fontColor: theme.colors.black,
      lineHeight: 2.5,
      fontSize: 14,
    },
  },
});

export default options;
