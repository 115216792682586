import React from "react";
import {
  Divider,
  Heading,
  Stack,
  Text,
} from "@chakra-ui/react";

import LogoIcon from "settings/theme/icons/LogoIcon";
import { AuthBoxProps } from "components/Box/types";
import Box from "components/Box";

/**
 * Authentication page box component.
 * Renders the form as children.
 * @param param0 Options.
 */
const AuthBox: React.FC<AuthBoxProps> = ({
  children,
  title,
  subtitle,
  ...rest
}) => (
  <Stack
    as={Box}
    alignItems="center"
    direction="column"
    spacing={10}
    d="flex"
    m="auto"
    h="auto"
    p={10}
    {...rest}
  >
    <LogoIcon />

    <Stack
      spacing={4}
      w="full"
    >
      <Stack
        alignItems="center"
        spacing={3}
        isInline
        w="full"
      >
        <Divider borderColor="gray.300" />

        <Heading
          textTransform="uppercase"
          color="primary.500"
          fontSize="md"
          flex="0 0 auto"
        >
          {title}
        </Heading>

        <Divider borderColor="gray.300" />
      </Stack>

      {
        subtitle && (
          <Text
            alignItems="center"
            alignSelf="center"
            fontSize="xs"
            textAlign="center"
            d="flex"
          >
            {subtitle}
          </Text>
        )
      }
    </Stack>

    <Stack
      spacing={6}
      w="full"
    >
      {children}
    </Stack>
  </Stack>
);

export default AuthBox;
