export const backendUrl = (
  process.env.NODE_ENV === "production"
    ? `${window.location.protocol}//${window.location.host}`
    : `${window.location.protocol}//localhost:4000`
);

export const ROOT_PAGE_PATH = "/";

export const LOGIN_PAGE_PATH = "/login";

export const MEMBER_SIGN_UP_PAGE_PATH = "/member-sign-up";

export const FORGOT_PASSWORD_PAGE_PATH = "/forgot-password";

export const RESET_PASSWORD_PAGE_PATH = "/reset-password";

export const COMPANY_SIGN_UP_PAGE_PATH = "/sign-up";

export const COMPANY_ONBOARDING_PAGE_PATH = "/sign-up/onboarding";

export const COMPANY_ACCOUNT_PAGE_PATH = "/company-account";

export const MY_ACCOUNT_PAGE_PATH = "/my-account";

export const DASHBOARD_PAGE_PATH = "/dashboard/:page?";

export const DASHBOARD_MODIFY_SUBSCRIPTION_PAGE_PATH = "/dashboard/subscription/modify/:jobId";

export const APPLICANT_TRACKING_PAGE_PATH = "/applicants";

export const APPLICANT_PROFILE_PAGE_PATH = "/applicants/:id";

export const CLIENT_MATCHING_PAGE_PATH = "/clients";

export const CLIENT_PROFILE_PAGE_PATH = "/clients/:id";

export const VERIFY_USER_PAGE_PATH = "/verify-user";

export const APPLICANT_FORM_PAGE_PATH = "/form/applicant/:shortCode";

export const SHORT_APPLICANT_FORM_PAGE_PATH = "/form/short-applicant/:shortCode";

export const CLIENT_FORM_PAGE_PATH = "/form/client/:shortCode";

export const FORM_LANDING_PAGE_PATH = "/form/introduction/:shortCode";

export const FORM_PREVIEW_PAGE_PATH = "/form/preview/:shortCode";

export const FORM_REDIRECT_PAGE_PATH = "/form/:shortCode";

export const FORM_THANK_YOU_PAGE_PATH = "/form/:formLocationType/thank-you/:shortCode";

export const EDIT_APPLICATION_FORM_PAGE_PATH = "/form/edit/application/:id";

export const EDIT_SHORT_APPLICATION_FORM_PAGE_PATH = "/form/edit/short_application/:id";

export const EDIT_QUESTIONNAIRE_FORM_PAGE_PATH = "/form/edit/questionnaire/:id";

export const EDIT_LANDING_PAGE_TEMPLATE_PAGE_PATH = "/form/edit/template";

export const EDIT_LANDING_PAGE_CONFIGURATION_PAGE_PATH = "/form/edit/template/configure/:id?";

export const NOT_MATCHED = "/not-matched";

export const APPLICANT_IFRAME_CHART = "/applicants/chart/:externalId/:token";
