import React from "react";
import {
  BoxProps,
  Divider,
  Text,
  Box,
} from "@chakra-ui/react";
import get from "lodash.get";

import {
  TableCellContainerProps,
  TableCellBoxProps,
  TableHeaderProps,
  TableTitleProps,
  TableCellProps,
} from "components/Table/types";

/**
 * Renders a `Box` component with table border definition.
 * @param props Component props.
 */
export const TableCellBox = React.forwardRef<HTMLDivElement, TableCellBoxProps>((
  {
    ...props
  },
  ref,
) => (
  <Box
    bgColor={props?.bgColor ?? "white"}
    _last={{ borderRightWidth: 0 }}
    borderColor="gray.100"
    borderRightWidth="1px"
    borderTopWidth="1px"
    py={props?.py ?? 4}
    px={props?.px ?? 6}
    ref={ref}
    {...props}
  />
));

/**
 * Renders the table container.
 * @param props Component props.
 */
export const TableContainer: React.FC<BoxProps> = (props) => (
  <Box
    {...props}
    bgColor={props.bgColor ?? "white"}
    p={props?.p ?? 3}
    overflow="scroll"
  />
);

/**
 * Renders the table title, with a divider.
 * @param props Component props.
 */
export const TableTitle: React.FC<TableTitleProps> = ({
  title,
  ...rest
}) => (
  <>
    <Text
      fontFamily="heading"
      fontSize="sm"
      px={4}
      pt={2}
      {...rest}
    >
      {title}
    </Text>

    <Divider
      borderColor="gray.200"
      my={4}
    />
  </>
);

/**
 * Represents tabular data - that is, information presented in a two-dimensional table
 * comprised of rows and columns of cells containing data.
 * It renders a `<table>` HTML element.
 */
export const TableRoot = React.forwardRef<HTMLTableElement, BoxProps>((
  props,
  ref,
) => (
  <Box
    {...props}
    as="table"
    ref={ref}
    width="full"
  />
));

/**
 * Defines a set of rows defining the head of the columns of the table.
 * It renders a `<thead>` HTML element, along with all the children inside a `<tr>`.
 */
export const TableHead = React.forwardRef<HTMLTableSectionElement, BoxProps>((
  {
    children,
    ...props
  },
  ref,
) => (
  <Box
    {...props}
    as="thead"
    ref={ref}
  >
    <tr>
      {children}
    </tr>
  </Box>
));

/**
 * Defines a row of cells in a table. The row's cells can then be established
 * using a mix of `TableCell` and `TableHeader` elements.
 * It renders a `<tr>` HTML element.
 */
export const TableRow = React.forwardRef<HTMLTableRowElement, BoxProps>((
  props,
  ref,
) => (
  <Box
    {...props}
    as="tr"
    ref={ref}
  />
));

/**
 * Encapsulates a set of table rows, indicating that they comprise the body of the table.
 * It renders a `<tbody>` HTML element.
 */
export const TableBody = React.forwardRef<HTMLTableSectionElement, BoxProps>((
  props,
  ref,
) => (
  <Box
    {...props}
    as="tbody"
    ref={ref}
  />
));

/**
 * Defines a cell as header of a group of table cells.
 * It renders a `<th>` HTML element.
 */
export const TableHeaderContainer = React.forwardRef<HTMLDivElement, TableCellContainerProps>((
  {
    textAlign = "left",
    cellProps,
    ...rest
  },
  ref,
) => (
  <TableCellBox
    {...rest}
    as="th"
    ref={ref}
    textAlign={textAlign}
    borderBottomWidth="1px"
    letterSpacing="wider"
    fontFamily="heading"
    borderTopWidth={0}
    lineHeight="1rem"
    {...cellProps ?? {}}
  />
));

/**
 * Defines a cell of a table that contains data.
 * It renders a `<td>` HTML element.
 */
export const TableCellContainer = React.forwardRef<HTMLDivElement, TableCellContainerProps>((
  {
    cellProps,
    children,
    ...props
  },
  ref,
) => (
  <TableCellBox
    {...props}
    as="td"
    ref={ref}
    whiteSpace="nowrap"
    {...cellProps ?? {}}
  >
    {children}
  </TableCellBox>
));

/**
 * Renders a table cell with resolved content.
 */
export function TableHeader<T = Record<string, unknown>>({
  column,
  ...rest
}: TableHeaderProps<T>): React.ReactElement {
  const columnProps = column.props || {};

  return (
    <TableHeaderContainer
      cellProps={column.cellProps}
      {...columnProps}
      {...rest}
    >
      {column.title ?? ""}
    </TableHeaderContainer>
  );
}

/**
 * Renders a table cell with resolved content.
 */
export function TableCell<T = Record<string, unknown>>({
  currentIndex,
  column,
  row,
  ...rest
}: TableCellProps<T>): React.ReactElement {
  const columnProps = column.props || {};

  const cellValue = (
    column.fieldPath
      ? get(row, column.fieldPath)
      : column.fieldResolver?.(row)
  ) ?? "" as string;

  return (
    <TableCellContainer
      cellProps={column.cellProps}
      {...columnProps}
      {...rest}
    >
      {
        column.render
          ? column.render({
            currentIndex,
            props: rest,
            row,
          })
          : (
            <Text fontSize={rest?.fontSize}>
              {cellValue}
            </Text>
          )
      }
    </TableCellContainer>
  );
}
