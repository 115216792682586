import React from "react";
import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";
import { Stack } from "@chakra-ui/react";

import availabilitySchema, { AvailabilitySchema } from "settings/yup/schemas/form/availabilitySchema";
import DatePickerInput from "components/FormComponents/DatePickerInput";
import AvailabilityMatrix from "components/AvailabilityMatrix";
import RadioGroup from "components/FormComponents/RadioGroup";
import FieldTitle from "components/FormComponents/FieldTitle";
import Input from "components/FormComponents/Input";
import { formatDate } from "utils/dateFormats";

import availabilityOptions from "./availabilityOptions";
import { getDefaultValues } from "./formValues";
import { AvailabilityFormProps } from "./types";
import useFormStep from "../hooks/useFormStep";

const AvailabilityForm: React.FC<AvailabilityFormProps> = ({
  formResponse,
}) => {
  const [t] = useTranslation();

  const {
    control,
    errors,
  } = useFormStep<AvailabilitySchema>({
    schema: availabilitySchema,
    formOptions: {
      defaultValues: getDefaultValues(formResponse),
    },
    overrideGetValues: ({
      getValues,
    }) => () => {
      const values = getValues();

      const parsedValues = {
        ...values,
        isFullTime: (
          values.isFullTime
            ? !!parseInt(values.isFullTime ?? "")
            : undefined
        ),
        availableAt: (
          values.availableAt
            ? formatDate(values.availableAt)
            : undefined
        ),
        availability: JSON.stringify({
          days: values.availability,
        }),
      };

      return parsedValues;
    },
  });

  return (
    <Stack spacing={6}>
      <Controller
        control={control}
        name="isFullTime"
        render={(props) => (
          <RadioGroup
            {...props}
            title={`${t("forms.steps.availability.are_you_looking_for_a_full_time_or_part_time_position")}*`}
            options={availabilityOptions}
            errors={errors}
          />
        )}
      />

      <Controller
        as={DatePickerInput}
        name="availableAt"
        control={control}
        errors={errors}
        title={`${t("forms.steps.availability.when_are_you_available_to_begin_work")}*`}
      />

      <Controller
        name="availability"
        control={control}
        render={({
          onChange,
          value,
        }) => (
          <Stack overflow="scroll">
            <FieldTitle>
              {t("forms.steps.availability.which_days_and_times_are_you_available_to_work")}
            </FieldTitle>

            <AvailabilityMatrix
              onChange={onChange}
              value={value}
            />
          </Stack>
        )}
      />

      <Controller
        as={Input}
        name="dayAvailability"
        control={control}
        errors={errors}
        title={t("forms.steps.availability.specifically_what_time_of_day_are_you_available_to_start_and_end_work_each_day")}
      />
    </Stack>
  );
};

export default AvailabilityForm;
