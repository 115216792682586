import React from "react";
import { Stack } from "@chakra-ui/react";

import PreviewContent from "views/Forms/FormPreview/FormResponses/PreviewComponents/PreviewContent";
import { ExtraAnswer } from "components/Tracking/ExtraInformationCard/types";
import { PreviewComponentProps } from "views/Forms/FormPreview/types";

import ExtraSectionAnswer from "./ExtraSectionAnswer";

const ExtraSection: React.FC<PreviewComponentProps> = ({
  formResponse,
  step,
}) => {
  if (!step.extraSection) {
    return null;
  }

  const extraAnswer = formResponse?.extraAnswers?.[step.extraSection.id] as ExtraAnswer;

  const answerGroups = extraAnswer?.answerGroups ?? [];

  const answers = extraAnswer?.answers ?? [];

  if (answerGroups.length > 0) {
    const title = (
      answerGroups.length > 1
      && extraAnswer.sectionName
    );

    return (
      <>
        {
          answerGroups.map((answerGroup, index) => (
            <Stack
              key={String(index)}
              _last={{ mb: 0 }}
              mb={4}
            >
              {
                title && (
                  <PreviewContent title={`${title} ${index + 1}`} />
                )
              }

              {
                answerGroup.answers.map((questionAnswer) => (
                  <ExtraSectionAnswer
                    questionAnswer={questionAnswer}
                    key={questionAnswer.id}
                  />
                ))
              }
            </Stack>
          ))
        }
      </>
    );
  }

  return (
    <>
      {
        answers.map((questionAnswer) => (
          <ExtraSectionAnswer
            questionAnswer={questionAnswer}
            key={questionAnswer.id}
          />
        ))
      }
    </>
  );
};

export default ExtraSection;
