import React, { useCallback } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import { useTranslation } from "react-i18next";
import { MutationTuple } from "@apollo/client";
import * as R from "remeda";

import shareProfileSchema, { ShareProfileTypeEnum, ShareProfileSchema } from "settings/yup/schemas/profile/shareProfileSchema";
import { useShareApplicantProfileMutation, useShareClientProfileMutation } from "generated/graphql";
import { onMutationSuccess, onQueryError } from "utils/queryHandlers";
import { ProfileType } from "constants/profiles";
import useShare from "hooks/useShare";
import useToast from "hooks/useToast";

import { ShareProfile, ShareProfileModalProps } from "../types";
import ModalFooter from "./ModalFooter";
import ModalBody from "./ModalBody";

const ShareProfileModal: React.FC<ShareProfileModalProps> = ({
  hideModal,
  componentProps: {
    options,
  },
}) => {
  const [showToast] = useToast();
  const [t] = useTranslation();
  const share = useShare();

  const [shareApplicantProfile, {
    loading: shareApplicantProfileLoading,
  }] = useShareApplicantProfileMutation();

  const [shareClientProfile, {
    loading: shareClientProfileLoading,
  }] = useShareClientProfileMutation();

  const shareProfile = useCallback<ShareProfile>((mutationPayload) => {
    const mutation = ({
      [ProfileType.Applicant]: shareApplicantProfile,
      [ProfileType.Client]: shareClientProfile,
    })[options.type] as MutationTuple<unknown, unknown>[0];

    mutation({
      variables: {
        emails: mutationPayload.emails,
        phones: mutationPayload.phones,
        id: options.id,
      },
    })
      .then(() => {
        onMutationSuccess(t("share_profile.profile_shared_successfully"), showToast);

        hideModal();
      })
      .catch((error) => {
        onQueryError(error, showToast);
      });
  }, [
    shareApplicantProfile,
    shareClientProfile,
    options.type,
    options.id,
    showToast,
    hideModal,
    t,
  ]);

  const form = useForm<ShareProfileSchema>({
    resolver: yupResolver(shareProfileSchema),
    mode: "onChange",
    defaultValues: {
      type: ShareProfileTypeEnum.Email,
      targets: [{
        value: "",
      }],
    },
  });

  const {
    getValues,
    register,
  } = form;

  register({
    name: "type",
    value: ShareProfileTypeEnum.Email,
  });

  const copyLink = useCallback(async () => {
    share({
      url: window.location.href,
      title: t("share_profile.share_link_text", {
        name: options.name,
      }),
    });
  }, [
    options.name,
    share,
    t,
  ]);

  const handleOnConfirm = useCallback(() => {
    const values = getValues();

    const targets = R.pipe(
      values.targets,
      R.map((target) => target.value),
      R.filter((value) => !!value),
    );

    const payload = ({
      [ShareProfileTypeEnum.Email]: {
        emails: targets,
      },
      [ShareProfileTypeEnum.SMS]: {
        phones: targets,
      },
    })[values.type];

    shareProfile(payload);
  }, [
    shareProfile,
    getValues,
  ]);

  const isLoading = (
    shareApplicantProfileLoading
    || shareClientProfileLoading
  );

  return (
    <FormProvider {...form}>
      <ModalBody />

      <ModalFooter
        onConfirm={handleOnConfirm}
        isLoading={isLoading}
        copyLink={copyLink}
      />
    </FormProvider>
  );
};

export default ShareProfileModal;
