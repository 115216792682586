import React from "react";
import {
  IconButton,
  Tooltip,
  Stack,
  Image,
  Text,
} from "@chakra-ui/react";

import { getCardFlagByBrand } from "constants/creditCards";
import DeleteIcon from "settings/theme/icons/DeleteIcon";
import Radio from "components/FormComponents/Radio";
import { Column } from "components/Table/types";
import i18n from "translations/i18n";

import { CreditCardCrudActions, CreditCard } from "../types";

/**
 * Builds the table columns.
 */
export const makeTableColumns = ({
  setCardAsDefault,
  removeCard,
}: CreditCardCrudActions): Column<CreditCard>[] => [
  {
    cellProps: {
      borderRightWidth: 0,
    },
    render: ({
      currentIndex,
      props,
    }) => (
      <Stack
        alignItems="center"
        spacing={4}
        isInline
      >
        <Text fontSize={props.fontSize}>
          {currentIndex + 1}
        </Text>
      </Stack>
    ),
  },
  {
    title: i18n.t("dashboard.wallet.table.default"),
    render: ({
      row: creditCard,
    }) => (
      <Stack
        justifyContent="center"
        alignItems="center"
        spacing={4}
      >
        <Radio
          onChange={setCardAsDefault(creditCard)}
          isChecked={creditCard.isDefault}
        />
      </Stack>
    ),
  },
  {
    title: i18n.t("dashboard.wallet.table.credit_card"),
    render: ({
      row: creditCard,
      props,
    }) => (
      <Stack
        alignItems="center"
        spacing={3}
        isInline
      >
        <Image
          src={getCardFlagByBrand(creditCard.brand)}
          alt={creditCard.brand}
          w="auto"
          h={8}
        />

        <Text fontSize={props.fontSize}>
          {i18n.t("dashboard.wallet.card_ending_in", {
            last4: creditCard.last4,
            brand: creditCard.brand,
          })}
        </Text>
      </Stack>
    ),
  },
  {
    title: i18n.t("dashboard.wallet.table.expires_in"),
    fieldResolver: (creditCard) => (
      `${creditCard.expMonth}/${creditCard.expYear}`
    ),
    cellProps: {
      borderRightWidth: 0,
    },
  },
  {
    render: ({
      row: creditCard,
    }) => (
      <Stack
        justifyContent="flex-end"
        alignItems="center"
        spacing={1}
        isInline
      >
        <Tooltip
          aria-label={i18n.t("buttons.remove")}
          label={i18n.t("buttons.remove")}
          fontSize="xxs"
          hasArrow
        >
          <IconButton
            aria-label={i18n.t("buttons.remove")}
            onClick={removeCard(creditCard)}
            variant="ghost"
            icon={(
              <DeleteIcon color="primary.200" />
            )}
          />
        </Tooltip>
      </Stack>
    ),
  },
];
