import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Stack } from "@chakra-ui/react";

import useCompanyOnboardingFormStore from "components/Forms/CompanyOnboardingForm/store";
import { FormTypeEnum } from "generated/graphql";
import useNavigate from "hooks/useNavigate";
import useViewForm from "hooks/useViewForm";
import { ROOT_PAGE_PATH } from "routes";
import Button from "components/Button";

const ConfigureQuestionnaireHeader: React.FC = () => {
  const navigate = useNavigate();
  const [t] = useTranslation();

  const formId = useCompanyOnboardingFormStore((store) => store.form?.id);

  const [viewForm, isViewFormLoading] = useViewForm();

  const handleViewForm = useCallback(() => {
    if (!formId) {
      return;
    }

    viewForm({
      formType: FormTypeEnum.Client,
      formId,
    });
  }, [
    viewForm,
    formId,
  ]);

  return (
    <Stack isInline>
      <Button
        isLoading={isViewFormLoading}
        onClick={handleViewForm}
        isDisabled={!formId}
        variant="outline"
        fontSize="sm"
        size="sm"
      >
        {t("buttons.view")}
      </Button>

      <Button
        onClick={navigate(ROOT_PAGE_PATH)}
        fontSize="sm"
        size="sm"
      >
        {t("buttons.finish")}
      </Button>
    </Stack>
  );
};

export default ConfigureQuestionnaireHeader;
