import i18n from "translations/i18n";

const EMAIL_VALIDATION_REGEX = "^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]*(?<!\\.)@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$";

/**
 * Applies the email test validation in one schema field.
 *
 * The reason for using this test instead of `yup().string().email()` is that the test must be
 * exactly the same as the backend one, in order to avoid having false positives when comparing
 * with the backend tests.
 */
const emailTest = {
  name: "isValidEmail",
  exclusive: false,
  message: i18n.t("yup.string_email"),
  test (value: string): value is string {
    return !!(
      value
      && !value?.includes("..")
      && value?.match(EMAIL_VALIDATION_REGEX)
    );
  },
};

export default emailTest;
