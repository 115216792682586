import React from "react";
import { Grid } from "@chakra-ui/react";

import QuestionCardItem from "./QuestionCardItem";
import { QuestionCardFieldProps } from "./types";

const QuestionCardField: React.FC<QuestionCardFieldProps> = ({
  fieldIndex,
  isDisabled,
  isLast,
  label,
  value,
  ...rest
}) => (
  <Grid
    borderBottomWidth={isLast ? 0 : "1px"}
    templateColumns="1fr 210px"
    borderColor="gray.100"
    {...rest}
  >
    <QuestionCardItem
      {...label}
      isDisabled={isDisabled}
      fieldIndex={fieldIndex}
    />

    <QuestionCardItem
      {...value}
      isDisabled={isDisabled}
      fieldIndex={fieldIndex}
      borderColor="gray.100"
      borderLeftWidth="1px"
    />
  </Grid>
);

export default QuestionCardField;
