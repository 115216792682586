import React, { useCallback, useEffect, useState } from "react";
import {
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInputStepper,
  NumberInputField,
  NumberInput,
} from "@chakra-ui/react";

import useCompanyOnboardingFormStore from "components/Forms/CompanyOnboardingForm/store";

import { EditExtraSectionMinimumNumberProps } from "./types";

const EditExtraSectionMinimumNumber: React.FC<EditExtraSectionMinimumNumberProps> = ({
  extraSection,
}) => {
  const [value, setValue] = useState(1);

  const [setFormValue, watch] = useCompanyOnboardingFormStore((store) => [
    store.setValue,
    store.watch,
  ]);

  const minimumNumber = watch?.("minimumNumber") ?? value;

  useEffect(() => {
    setValue(minimumNumber);
  }, [minimumNumber]);

  const onChange = useCallback((newValue) => {
    setValue(newValue);

    setFormValue?.("minimumNumber", parseInt(newValue), {
      shouldValidate: true,
      shouldDirty: true,
    });
  }, [setFormValue]);

  if (!setFormValue || !extraSection?.isMultiple) {
    return null;
  }

  return (
    <NumberInput
      isDisabled={!extraSection.show}
      onChange={onChange}
      value={value}
      width={20}
      size="sm"
      max={99}
      min={1}
    >
      <NumberInputField />
      <NumberInputStepper>
        <NumberIncrementStepper />
        <NumberDecrementStepper />
      </NumberInputStepper>
    </NumberInput>
  );
};

export default EditExtraSectionMinimumNumber;
