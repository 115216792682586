import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
  IconButton,
  Tooltip,
  Stack,
  Box,
} from "@chakra-ui/react";

import { headerHeight } from "components/Header";

import { DashboardNavbarProps } from "../types";

export const navbarWidth = {
  css: "90px",
  value: 90,
};

const DashboardNavbar: React.FC<DashboardNavbarProps> = ({
  isModifyingSubscription,
  setActivePage,
  setExternalPage,
  activePage,
  buttons,
}) => {
  const [t] = useTranslation();

  const onIconClick = useCallback((page, isExternalPage) => () => {
    if (isExternalPage) {
      setExternalPage(page);
    } else {
      setActivePage(page);
    }
  }, [
    setActivePage,
    setExternalPage,
  ]);

  return (
    <Box
      marginTop={headerHeight.css}
      w={navbarWidth.css}
      borderRadius={0}
      position="fixed"
      bg="gray.100"
      zIndex={14}
      shadow="lg"
      bottom={0}
      right={0}
      left={0}
      top={0}
      p={4}
    >
      <Stack
        alignItems="center"
        spacing={4}
      >
        {
          buttons.map(({
            icon: Icon,
            ...button
          }) => {
            const isActive = (
              (button.page === "subscription" && isModifyingSubscription)
              || button.page === activePage
            );

            return (
              <Tooltip
                aria-label={button.name}
                label={button.name}
                key={button.name}
                placement="right"
                fontSize="xxs"
                zIndex={15}
                hasArrow
              >
                <IconButton
                  _hover={{ bgColor: "primary.200" }}
                  bgColor={isActive ? "primary.200" : "transparent"}
                  onClick={onIconClick(button.page, button.isExternalPage)}
                  aria-label={t("buttons.toggle")}
                  isDisabled={button.isDisabled}
                  borderRadius={100}
                  variant="ghost"
                  css={{
                    ":hover": {
                      "*": {
                        color: "white",
                      },
                    },
                  }}
                  icon={(
                    <Icon
                      color={isActive ? "white" : "primary.200"}
                      boxSize={button.iconBoxSize}
                    />
                  )}
                />
              </Tooltip>
            );
          })
        }
      </Stack>
    </Box>
  );
};

export default DashboardNavbar;
