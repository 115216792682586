import { SendAssessmentTypeEnum } from "settings/yup/schemas/assessment/sendAssessmentSchema";
import i18n from "translations/i18n";

import { SendAssessmentTabItem } from "./types";
import ModalBodyForm from "./ModalBodyForm";

/**
 * Defines tabs for the send assessment modal.
 */
const tabs: SendAssessmentTabItem[] = [
  {
    title: i18n.t("send_assessment.email"),
    component: ModalBodyForm,
    type: SendAssessmentTypeEnum.Email,
  },
];

export default tabs;
