import * as React from "react";
import { IconProps } from "@chakra-ui/icons";
import { createIcon } from "@chakra-ui/react";

const ReinviteIcon: React.FC<IconProps> = createIcon({
  path: (
    <g
      id="reply-24px_1_"
      data-name="reply-24px (1)"
      transform="translate(-3 -4)"
    >
      <path
        fill="none"
        id="Path_7264"
        data-name="Path 7264"
        d="M18,0H0V18H18Z"
        transform="translate(3 4)"
      />
      <path
        fill="currentColor"
        id="Path_7265"
        data-name="Path 7265"
        d="M14,9V5l7,7-7,7V14.9c-5,0-8.5,1.6-11,5.1C4,15,7,10,14,9Z"
        transform="translate(0 1)"
      />
    </g>
  ),
});

export default ReinviteIcon;
