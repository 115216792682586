import * as React from "react";
import { IconProps } from "@chakra-ui/icons";

import ArrowLeftIcon from "./ArrowLeftIcon";

const ArrowRightIcon = React.forwardRef<SVGSVGElement, IconProps>((props, ref) => (
  <ArrowLeftIcon
    {...props}
    ref={ref}
    transform="rotate(180deg)"
  />
));

export default ArrowRightIcon;
