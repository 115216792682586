import * as R from "remeda";

import { ExtraQuestionDisplayTypeEnum } from "generated/graphql";
import i18n from "translations/i18n";

export interface DisplayTypeInfo {
  value: ExtraQuestionDisplayTypeEnum;
  label: string;
}

/**
 * Maps possible form step types with their translations.
 */
export const mapExtraQuestionDisplayTypes: Record<ExtraQuestionDisplayTypeEnum, DisplayTypeInfo> = {
  [ExtraQuestionDisplayTypeEnum.Hidden]: {
    value: ExtraQuestionDisplayTypeEnum.Hidden,
    label: i18n.t("question_display_types.hidden"),
  },
  [ExtraQuestionDisplayTypeEnum.Optional]: {
    value: ExtraQuestionDisplayTypeEnum.Optional,
    label: i18n.t("question_display_types.show_as_optional"),
  },
  [ExtraQuestionDisplayTypeEnum.Required]: {
    value: ExtraQuestionDisplayTypeEnum.Required,
    label: i18n.t("question_display_types.show_as_required"),
  },
};

/**
 * Display type options to be used in select components.
 */
export const extraQuestionDisplayTypeOptions = R.pipe(
  Object.values(mapExtraQuestionDisplayTypes),
  R.map((displayType) => ({
    label: displayType.label,
    value: displayType.value,
  })),
);
