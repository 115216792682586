import React, { useCallback } from "react";
import { yupResolver } from "@hookform/resolvers";
import { Trans, useTranslation } from "react-i18next";
import { Flex, Stack } from "@chakra-ui/react";
import { useForm, Controller } from "react-hook-form";
import { useHistory } from "react-router-dom";

import loginSchema, { LoginSchema } from "settings/yup/schemas/loginSchema";
import PasswordInput from "components/FormComponents/Input/PasswordInput";
import {
  useLoginUserMutation,
  useAcceptInvitationAndLoginMutation,
} from "generated/graphql";
import {
  FORGOT_PASSWORD_PAGE_PATH,
  COMPANY_SIGN_UP_PAGE_PATH,
  ROOT_PAGE_PATH,
  MEMBER_SIGN_UP_PAGE_PATH,
} from "routes";
import Input from "components/FormComponents/Input";
import AuthBox from "components/Box/AuthBox";
import TextLink from "components/TextLink";
import useSession from "hooks/useSession";
import Button from "components/Button";
import useToast from "hooks/useToast";
import parseSignUpInvitationQuery from "utils/parseSignUpInvitationQuery";

const Login: React.FC = () => {
  const [createSession] = useSession();
  const [showToast] = useToast();
  const [t] = useTranslation();
  const history = useHistory();

  const searchQuery = history.location.search;
  const { email, token } = parseSignUpInvitationQuery(searchQuery);
  const isAnInvite = [email, token].every((item) => typeof item === "string");

  const {
    getValues,
    control,
    errors,
    trigger,
  } = useForm<LoginSchema>({
    resolver: yupResolver(loginSchema),
    mode: "onChange",
    defaultValues: {
      email: typeof email === "string" ? email : "",
      password: "",
    },
  });

  const [
    loginUser,
    { loading: loginLoading },
  ] = useLoginUserMutation();

  const [
    acceptInvitationAndLoginMutation,
    { loading: invitationLoading },
  ] = useAcceptInvitationAndLoginMutation();

  const onError = useCallback((errorMsg) => {
    showToast({
      title: errorMsg || t("errors.please_fill_all_fields"),
      status: "error",
    });
  }, [showToast, t]);

  const onCreateSession = useCallback((authToken): void => {
    if (authToken) {
      createSession(authToken, ROOT_PAGE_PATH);
    }
  }, [createSession]);

  const onLogin = useCallback((values) => {
    const variables = {
      password: values.password,
      email: values.email,
    };

    loginUser({ variables })
      .then((response) => {
        const authToken = response?.data?.loginUser;
        onCreateSession(authToken);
      })
      .catch((error) => {
        onError(error.message);
      });
  }, [
    loginUser,
    onCreateSession,
    onError,
  ]);

  const onInviteAndLogin = useCallback((values) => {
    const variables = {
      password: values.password,
      email: values.email,
      token: values.token,
    };

    acceptInvitationAndLoginMutation({ variables })
      .then((response) => {
        const authToken = response?.data?.acceptInvitationAndLogin;
        onCreateSession(authToken);
      })
      .catch((error) => {
        onError(error.message);
      });
  }, [
    acceptInvitationAndLoginMutation,
    onCreateSession,
    onError,
  ]);

  const onSubmit = useCallback(async (e) => {
    e.preventDefault();
    const isValid = await trigger();

    if (!isValid) return;

    const formValues = getValues();

    if (typeof token === "string") {
      onInviteAndLogin({ ...formValues, token });
    } else {
      onLogin(formValues);
    }
  }, [
    token,
    onLogin,
    onInviteAndLogin,
    getValues,
    trigger,
  ]);

  const handleRequestResetPassword = useCallback(() => {
    history.push(FORGOT_PASSWORD_PAGE_PATH);
  }, [history]);

  const handleSignUp = useCallback(() => {
    const signUpPath = searchQuery
      ? `${MEMBER_SIGN_UP_PAGE_PATH}${searchQuery}`
      : COMPANY_SIGN_UP_PAGE_PATH;

    history.push(signUpPath);
  }, [history, searchQuery]);

  return (
    <AuthBox title={t("login.title")}>
      <form onSubmit={onSubmit}>
        <Stack spacing={4}>
          <Controller
            type="email"
            name="email"
            isDisabled={isAnInvite}
            as={Input}
            control={control}
            errors={errors}
            title={`${t("login.email")}*`}
          />
          <Stack>
            <Controller
              type="password"
              name="password"
              as={PasswordInput}
              defaultValue=""
              control={control}
              errors={errors}
              title={`${t("login.password")}*`}
            />

            <TextLink
              onClick={handleRequestResetPassword}
              w="fit-content"
              alignSelf="flex-end"
            >
              {t("buttons.forgot_password")}
            </TextLink>
          </Stack>

          <Stack spacing={3}>
            <Button
              type="submit"
              isLoading={loginLoading || invitationLoading}
            >
              {
                t(
                  isAnInvite
                    ? "login.accept_invitation_and_login"
                    : "buttons.login",
                )
              }
            </Button>

            <Flex
              flexDir="row"
              alignItems="center"
              alignSelf="center"
              fontSize="xxs"
            >
              <Trans
                i18nKey="buttons.dont_have_an_account_yet_sign_up"
                components={{
                  TextLink: (
                    <TextLink
                      onClick={handleSignUp}
                      color="primary.500"
                      fontSize="xxs"
                      ml={1}
                    />
                  ),
                }}
              />
            </Flex>
          </Stack>
        </Stack>
      </form>
    </AuthBox>
  );
};

export default Login;
