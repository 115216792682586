import * as Sentry from "@sentry/react";

import { logError } from "./logger";

/**
 * Captures a sentry error while adding extra content to it.
 *
 * @param error The error
 * @param details The error details
 */
const captureSentryException = (
  error: Error,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  details: Record<string, any> = {},
): void => {
  logError("Captured Sentry exception", error, {
    details,
  });

  Sentry.withScope((scope) => {
    if (details) {
      scope.setExtra("details", details);
    }

    Sentry.captureException(error);
  });
};

export default captureSentryException;
