/**
 * Converts a number to a fixed amount of characters.
 * @param value The value.
 * @param digits Amount of characters to set.
 */
const toFixed = (
  value: number,
  digits: number,
  base = 10,
): number => {
  const pow = base ** digits;

  return Math.round(value * pow) / pow;
};

export default toFixed;
