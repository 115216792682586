import React from "react";
import {
  Text,
  Box,
} from "@chakra-ui/react";

import { SliderThumbProps } from "components/FormComponents/Slider/types";

const THUMB_SIZE = "21px";

const SliderThumb: React.FC<SliderThumbProps> = ({
  labelPrefix,
  labelSuffix,
  colorScheme,
  thumbProps,
  state,
}) => (
  <>
    <Box
      {...thumbProps}
      key={`thumb-knob-${state.index}`}
      bgColor={`${colorScheme}.500`}
      _focus={{ outline: "none" }}
      borderRadius={100}
      cursor="pointer"
      w={THUMB_SIZE}
      h={THUMB_SIZE}
      top="-4px"
    />

    <Text
      {...thumbProps}
      key={`thumb-label-${state.index}`}
      _focus={{ outline: "none" }}
      textAlign="center"
      whiteSpace="pre"
      cursor="pointer"
      fontSize="xxs"
      w={THUMB_SIZE}
      h={THUMB_SIZE}
      top="20px"
    >
      {`${labelPrefix}${state.valueNow}${labelSuffix}`}
    </Text>
  </>
);

export default SliderThumb;
