import { QuestionCardField } from "components/Forms/CompanyOnboardingForm/Cards/QuestionCard/types";
import i18n from "translations/i18n";

const fields: QuestionCardField[] = [
  {
    label: {
      text: i18n.t("company_onboarding.steps.resume.question_1.field_1"),
    },
    value: {
      text: i18n.t("question_display_types.show_as_optional"),
    },
  },
];

export default fields;
