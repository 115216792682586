import React, { useCallback } from "react";
import {
  ModalFooter,
  IconButton,
  ModalBody,
  Stack,
} from "@chakra-ui/react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import { useTranslation } from "react-i18next";

import addOptionsSchema, { AddOptionsSchema } from "settings/yup/schemas/addOptionsSchema";
import DeleteIcon from "settings/theme/icons/DeleteIcon";
import PlusIcon from "settings/theme/icons/PlusIcon";
import Input from "components/FormComponents/Input";
import Button from "components/Button";

import { AddMoreOptionsModalProps } from "./types";

const AddMoreOptionsModal: React.FC<AddMoreOptionsModalProps> = ({
  componentProps: {
    maxItems = 5,
    onFinish,
  },
  hideModal,
}) => {
  const [t] = useTranslation();

  const {
    getValues,
    formState,
    control,
    errors,
  } = useForm<AddOptionsSchema>({
    resolver: yupResolver(addOptionsSchema),
    mode: "onChange",
    defaultValues: {
      newOptions: [{
        value: "",
      }],
    },
  });

  const {
    fields,
    append,
    remove,
  } = useFieldArray({
    name: "newOptions",
    control,
  });

  const handleAppend = useCallback(() => {
    append({
      value: "",
    });
  }, [append]);

  const handleRemove = useCallback((index: number) => () => {
    remove(index);
  }, [remove]);

  const handleFinish = useCallback(() => {
    const values = getValues();

    const newOptions = values.newOptions.map((option) => option.value);

    onFinish(newOptions);

    hideModal();
  }, [
    getValues,
    hideModal,
    onFinish,
  ]);

  return (
    <>
      <ModalBody>
        <Stack
          spacing={4}
          py={6}
        >
          {
            fields.map((field, index) => {
              const rightElement = (
                index > 0
                  ? (
                    <IconButton
                      onClick={handleRemove(index)}
                      aria-label={t("buttons.remove")}
                      icon={<DeleteIcon />}
                    />
                  )
                  : undefined
              );

              return (
                <Controller
                  as={Input}
                  name={`newOptions[${index}].value`}
                  control={control}
                  errors={errors}
                  key={field.id}
                  defaultValue=""
                  title={t("forms.new_option_index", { index: index + 1 })}
                  rightElement={rightElement}
                />
              );
            })
          }
        </Stack>

        <Stack
          alignItems="flex-end"
          mt={2}
        >
          <Button
            isDisabled={fields.length >= maxItems}
            rightIcon={<PlusIcon />}
            onClick={handleAppend}
            variant="outline"
            width="xxs"
            size="sm"
          >
            {t("buttons.add_field")}
          </Button>
        </Stack>
      </ModalBody>

      <ModalFooter>
        <Button
          isDisabled={!formState.isValid}
          onClick={handleFinish}
          fontSize="sm"
          w="full"
        >
          {t("buttons.send")}
        </Button>
      </ModalFooter>
    </>
  );
};

export default AddMoreOptionsModal;
