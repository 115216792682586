import * as R from "remeda";

/**
 * Parses an opacity according to a roleFitScore value.
 * @param roleFitScore The value of roleFitScore.
 */
const getRoleFitScoreOpacity = (roleFitScore?: number): number => (
  R.clamp(Number(roleFitScore), {
    min: 0.4,
  })
);

export default getRoleFitScoreOpacity;
