import { StackProps } from "@chakra-ui/react";

import { avoidPageBreakInsideCss } from "styles/css";

import { MakeAnswerItemStackStylesOptions } from "./types";

/**
 * Builds the styles for answer items.
 * @param param0 Options.
 */
export const makeAnswerItemStackStyles = ({
  answerGroupsCount,
  position,
}: MakeAnswerItemStackStylesOptions): StackProps => ({
  borderColor: "gray.100",
  h: "full",
  p: 5,
  _odd: {
    borderRightWidth: (
      answerGroupsCount > 1
        ? "1px"
        : 0
    ),
  },
  css: {
    ...avoidPageBreakInsideCss,
    borderTopWidth: (
      (position === 0 || position === 1)
        ? 0
        : "1px"
    ),
  },
});
