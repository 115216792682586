import yup from "settings/yup";

import { makePhoneNumberTest } from "../tests/phoneNumberTest";

const updateMemberSchema = yup.object().shape({
  name: yup
    .string()
    .required(),
  phoneNumber: yup
    .string()
    .test(makePhoneNumberTest())
    .required(),
});

export interface UpdateMemberSchema {
  name: string;
  phoneNumber: string;
}

export default updateMemberSchema;
