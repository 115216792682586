import * as React from "react";
import { IconProps } from "@chakra-ui/icons";
import { createIcon } from "@chakra-ui/react";

const DescriptionIcon: React.FC<IconProps> = createIcon({
  path: (
    <>
      <path
        d="M0 0h24v24H0z"
        fill="none"
      />
      <path
        d="M9.2 15.2h6.4v1.6H9.2zm0-3.2h6.4v1.6H9.2zM14 4H7.6A1.6 1.6 0 0 0 6 5.6v12.8A1.6 1.6 0 0 0 7.592 20H17.2a1.6 1.6 0 0 0 1.6-1.6V8.8zm3.2 14.4H7.6V5.6h5.6v4h4z"
        stroke="#fff"
        fill="currentColor"
        strokeWidth=".5"
      />
    </>
  ),
});

export default DescriptionIcon;
