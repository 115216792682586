import { gql } from "@apollo/client";

export const updateMemberRefetchQueries = [
  "GetHeaderCurrentUser",
];

export default gql`
  mutation UpdateMember (
    $id: Int!,
    $params: MemberUpdateParams!,
  ) {
    updateMember(
      id: $id,
      params: $params,
    ) {
      id
    }
  }
`;
