import React, { useCallback, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Text } from "@chakra-ui/react";

import { cancelSubscriptionRefetchQueries } from "graphql/mutations/cancelSubscriptionMutation";
import { useCancelSubscriptionMutation } from "generated/graphql";
import { useModal } from "contexts/modal";
import useToast from "hooks/useToast";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import { onMutationSuccess, onQueryError } from "utils/queryHandlers";
import { formatDate, monthDayYear } from "utils/dateFormats";

import { CancelSubscriptionOptions, UseCancelSubscriptionResult } from "./types";

/**
 * Exposes a function that allows the user to cancel the subscription by executing the
 * `cancelSubscription` mutation.
 *
 * The loading state is also exposed.
 *
 * In case the mutation fails, a toast will be shown with the error.
 */
const useCancelSubscription = (): UseCancelSubscriptionResult => {
  const [showModal] = useModal();
  const [showToast] = useToast();
  const [t] = useTranslation();

  const [cancelSubscription, {
    loading: cancelSubscriptionLoading,
  }] = useCancelSubscriptionMutation({
    refetchQueries: cancelSubscriptionRefetchQueries,
    awaitRefetchQueries: true,
  });

  const handleCancelSubscription = useCallback((options: CancelSubscriptionOptions) => {
    showModal({
      size: "xl",
      title: t("buttons.cancel_subscription"),
      component: ConfirmationModal,
      componentProps: {
        onConfirm: () => {
          cancelSubscription({
            variables: {
              id: options.subscriptionId,
            },
          })
            .then((response) => {
              const endAt = response?.data?.cancelSubscription.endAt;

              onMutationSuccess(t("cancel_subscription_modal.your_subscription_has_been_canceled", {
                date: formatDate(endAt, monthDayYear),
              }), showToast);
            })
            .catch((error) => {
              onQueryError(error, showToast);
            });
        },
        declineText: t("buttons.go_back"),
        text: (
          <>
            <Trans>
              <Text fontSize="xs">
                {t("cancel_subscription_modal.are_you_sure_you_want_to_cancel_your_subscription", {
                  role: options.roleName,
                })}
              </Text>
            </Trans>

            <Text
              color="gray.400"
              fontSize="xs"
            >
              {t("cancel_subscription_modal.cancel_subscription_footer")}
            </Text>
          </>
        ),
      },
    });
  }, [
    cancelSubscription,
    showModal,
    showToast,
    t,
  ]);

  const payload = useMemo<UseCancelSubscriptionResult>(() => [
    handleCancelSubscription,
    cancelSubscriptionLoading,
  ], [
    handleCancelSubscription,
    cancelSubscriptionLoading,
  ]);

  return payload;
};

export default useCancelSubscription;
