import { DashboardPage, MapDashboardPageOptions } from "views/Dashboard/types";

import ModifySubscription from "./Pages/ModifySubscription";
import Subscription from "./Pages/Subscription";
import Dashboard from "./Pages/Dashboard";
import Wallet from "./Pages/Wallet";
import Team from "./Pages/Team";
import Help from "./Pages/Help";

const mapPages: Record<DashboardPage, React.FC> = {
  summary: Dashboard,
  team: Team,
  subscription: Subscription,
  wallet: Wallet,
  help: Help,
};

/**
 * Maps dashboard pages with components.
 * @param page The page identifier.
 */
const mapDashboardPage = ({
  isModifyingSubscription,
  buttons,
  page,
}: MapDashboardPageOptions): React.FC | undefined => {
  if (isModifyingSubscription) {
    return ModifySubscription;
  }

  const button = buttons.find((item) => item.page === page);

  if (!button || button?.isDisabled) {
    return undefined;
  }

  return mapPages[page];
};

export default mapDashboardPage;
