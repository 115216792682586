import * as React from "react";
import { IconProps } from "@chakra-ui/icons";
import { createIcon } from "@chakra-ui/react";

const PrintIcon: React.FC<IconProps> = createIcon({
  path: (
    <path
      fill="currentColor"
      d="M19,8H18V3H6V8H5a3,3,0,0,0-3,3v6H6v4H18V17h4V11A3,3,0,0,0,19,8ZM8,5h8V8H8Zm8,12v2H8V15h8Zm2-2V13H6v2H4V11a1,1,0,0,1,1-1H19a1,1,0,0,1,1,1v4Z"
    />
  ),
});

export default PrintIcon;
