import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import { stripeKey } from "constants/stripe";

import AddCreditCardForm from "./AddCreditCardForm";
import { AddCreditCardModalProps } from "./types";

const stripePromise = loadStripe(stripeKey);

const AddCreditCardModal: React.FC<AddCreditCardModalProps> = ({
  hideModal,
  componentProps: {
    paymentInformation,
    onSuccess,
    companyId,
  },
}) => (
  <Elements stripe={stripePromise}>
    <AddCreditCardForm
      paymentInformation={paymentInformation}
      onSuccess={onSuccess}
      hideModal={hideModal}
      companyId={companyId}
    />
  </Elements>
);

export default AddCreditCardModal;
