import { QuestionCardField } from "components/Forms/CompanyOnboardingForm/Cards/QuestionCard/types";
import i18n from "translations/i18n";

const fields: QuestionCardField[] = [
  {
    label: {
      text: i18n.t("company_onboarding.steps.responded_by.question_1.field_1"),
    },
    value: {
      text: i18n.t("question_display_types.required_by_default"),
    },
  },
  {
    label: {
      text: i18n.t("company_onboarding.steps.responded_by.question_1.field_2"),
    },
    value: {
      text: i18n.t("question_display_types.show_option_by_default"),
    },
  },
  {
    label: {
      text: i18n.t("company_onboarding.steps.responded_by.question_1.field_3"),
    },
    value: {
      text: i18n.t("question_display_types.show_option_by_default"),
    },
  },
  {
    label: {
      text: i18n.t("company_onboarding.steps.responded_by.question_1.field_4"),
    },
    value: {
      text: i18n.t("question_display_types.show_option_by_default"),
    },
  },
];

export default fields;
