import React from "react";
import { Stack, Divider, Flex } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import Button from "components/Button";

import { FormPreviewFooterProps } from "../types";

const FormPreviewFooter: React.FC<FormPreviewFooterProps> = ({
  isSubmitResponseLoading,
  submitResponse,
  goBack,
}) => {
  const [t] = useTranslation();

  return (
    <Stack spacing={6}>
      <Divider borderColor="gray.100" />

      <Flex
        justifyContent={["center", null, "flex-end"]}
        flexDirection={["column", null, "row"]}
        alignItems="center"
      >
        <Button
          width={["full", null, "xxs"]}
          onClick={goBack}
          variant="outline"
          size="sm"
        >
          {t("buttons.go_back")}
        </Button>

        <Button
          isLoading={isSubmitResponseLoading}
          width={["full", null, "xxs"]}
          onClick={submitResponse}
          ml={[0, null, 4]}
          mt={[4, null, 0]}
          size="sm"
        >
          {t("buttons.submit")}
        </Button>
      </Flex>
    </Stack>
  );
};

export default FormPreviewFooter;
