import React from "react";

import { WizardStep } from "components/Wizard/types";
import { ExtraSection, FixedSectionsConfiguration } from "generated/graphql";
import i18n from "translations/i18n";
import { OtherFormParams, UseStepMode } from "views/Forms/types";

/**
 * Defines possible ids for form steps.
 */
export enum FormStepId {
  PersonalInformation = "personal-information",
  PersonalStory = "personal-story",
  Requirements = "requirements",
  Availability = "availability",
  RespondedBy = "responded-by",
  Source = "source",
  Resume = "resume",
  Introduction = "introduction",
  ThankYou = "thank-you",
}

/**
 * Prefix used for extra step ids.
 */
export const extraStepIdPrefix = "extra-";

/**
 * Defines one id for one extra step based on its id.
 * @param id The extra step id.
 */
export const getExtraStepId = (id: number): string => `${extraStepIdPrefix}${id}`;

/**
 * Defines possible values for a form step.
 */
export enum FormStepType {
  RequiredReadOnly = "required-read-only",
  RequiredEditable = "required-editable",
  Optional = "optional",
}

/**
 * Defines possible values for a section type.
 */
export enum SectionType {
  Fixed = "fixed-section",
  Extra = "extra-section",
}

export enum SectionVisibilityField {
  Availability = "showAvailability",
  Requirements = "showRequirements",
  Resume = "showResume",
  Source = "showSource",
}

export interface FormStepTypeInfo {
  value: FormStepType;
  label: string;
}

/**
 * Maps possible form step types with their translations.
 */
export const mapFormStepTypes: Record<FormStepType, FormStepTypeInfo> = {
  [FormStepType.RequiredReadOnly]: {
    value: FormStepType.RequiredReadOnly,
    label: i18n.t("forms.step_types.required_read_only"),
  },
  [FormStepType.RequiredEditable]: {
    value: FormStepType.RequiredEditable,
    label: i18n.t("forms.step_types.required_editable"),
  },
  [FormStepType.Optional]: {
    value: FormStepType.Optional,
    label: i18n.t("forms.step_types.optional"),
  },
};

/**
 * Defines a form step.
 */
export interface FormStep extends WizardStep {
  /**
   * The form step id.
   */
  id: FormStepId | string;

  /**
   * The form step type.
   */
  type: FormStepType;

  /**
   * The extra section information, if the form step is an extra section.
   */
  extraSection?: ExtraSection;

  /**
   * Overrides the index in edit form number circles.
   */
  editFormPreview?: string;

  /**
   * If a step is optional, a button to toggle its visibility will be shown.
   */
  isOptional: boolean;

  /**
   * Defines if the current visibility of the section.
   */
  isVisible?: boolean;

  /**
   * Defines which field defines its visibility.
   */
  sectionVisibilityField?: SectionVisibilityField;

  questionNumber?: number;

  isImageUploadStep?: boolean;
}

export type FixedSectionsConfigurationParam = FixedSectionsConfiguration | undefined | null;

export type PersonalStoryParams = {
  component: React.FC;
  questionNumber: number;
  roleName: string | undefined;
  typeForm: string;
  isImageUploadStep: boolean;
  totalQuestions: number;
  stepMode: UseStepMode;
}

export type PersonalStoriesParams = OtherFormParams & Omit<PersonalStoryParams, "questionNumber" | "isImageUploadStep" | "totalQuestions">
