import React from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import { useTranslation } from "react-i18next";
import { Stack } from "@chakra-ui/react";

import useClientUpdateHandler from "components/Tracking/ClientStatusCard/hooks/useClientUpdateHandler";
import updateClientSchema, { UpdateClientSchema } from "settings/yup/schemas/updateClientSchema";
import { ClientStatusCardProps } from "components/Tracking/ClientStatusCard/types";
import { clientStatusUpdateSelectOptions } from "constants/statuses";
import Select from "components/FormComponents/Select";

const StatusCardForm: React.FC<ClientStatusCardProps> = ({
  client,
}) => {
  const [t] = useTranslation();

  const {
    formState,
    control,
    errors,
    reset,
    watch,
  } = useForm<UpdateClientSchema>({
    resolver: yupResolver(updateClientSchema),
    mode: "onChange",
  });

  useClientUpdateHandler({
    formState,
    client,
    reset,
    watch,
  });

  return (
    <Stack spacing={4}>
      <Controller
        name="status"
        control={control}
        defaultValue={client?.status}
        render={({
          onChange,
          value,
        }) => (
          <Select
            value={value}
            errors={errors}
            onChange={onChange}
            title={`${t("filters.status")}:`}
            options={clientStatusUpdateSelectOptions}
          />
        )}
      />
    </Stack>
  );
};

export default StatusCardForm;
