import React from "react";
import { Box } from "@chakra-ui/react";

import { getBackgroundImage } from "./styles";
import { TemplateBackgroundProps } from "./types";

const TemplateBackground: React.FC<TemplateBackgroundProps> = ({
  headerImageUrl,
  children,
  ...rest
}) => {
  if (!headerImageUrl) {
    return null;
  }

  return (
    <Box
      width="100vw"
      backgroundSize="cover"
      backgroundImage={getBackgroundImage(headerImageUrl)}
      backgroundRepeat="no-repeat"
      backgroundPosition="center center"
      backgroundAttachment="fixed"
      {...rest}
    >
      {children}
    </Box>
  );
};

export default TemplateBackground;
