import React from "react";
import { useTranslation } from "react-i18next";
import { Stack } from "@chakra-ui/react";

import useConfigureLandingPageStore from "components/Forms/ConfigureLandingPageForm/store";
import useLandingPagePreview from "hooks/useLandingPagePreview";
import useWizardStore from "components/Wizard/store";
import { LandingPage } from "generated/graphql";
import Button from "components/Button";

const ConfigureLandingPageHeader: React.FC = () => {
  const [t] = useTranslation();

  const navigateToNextStep = useWizardStore((store) => store.navigateToNextStep);

  const {
    landingPage,
    isLoading,
    isValid,
  } = useConfigureLandingPageStore((store) => ({
    landingPage: store.landingPage,
    isLoading: store.isLoading,
    isValid: store.isValid,
  }));

  const [showLandingPagePreview] = useLandingPagePreview();

  return (
    <Stack isInline>
      <Button
        onClick={showLandingPagePreview(landingPage as LandingPage)}
        isLoading={isLoading}
        variant="outline"
        fontSize="sm"
        size="sm"
      >
        {t("buttons.preview")}
      </Button>

      <Button
        onClick={navigateToNextStep}
        isLoading={isLoading}
        disabled={!isValid}
        fontSize="sm"
        size="sm"
      >
        {t("buttons.save_and_next")}
      </Button>
    </Stack>
  );
};

export default ConfigureLandingPageHeader;
