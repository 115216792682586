import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useUpdatePasswordMutation } from "generated/graphql";
import { onMutationSuccess, onQueryError } from "utils/queryHandlers";
import { useModal } from "contexts/modal";
import useToast from "hooks/useToast";

import { IChangePasswordParams, UseChangeUserPassword } from "./types";
import ChangePasswordModalForm from "../../views/MyAccount/ChangePasswordModalForm";

const useChangeUserPassword = (): UseChangeUserPassword => {
  const [showToast] = useToast();
  const [t] = useTranslation();
  const [
    showModal,
    hideModal,,
    updateModalComponentProps,
  ] = useModal();

  const [updatePassword, { loading }] = useUpdatePasswordMutation();

  useEffect(() => {
    updateModalComponentProps({ isLoading: loading });
  }, [
    updateModalComponentProps,
    loading,
  ]);

  const handleChangePassword = useCallback(() => {
    showModal({
      size: "md",
      title: t("my_account.change_password.title"),
      component: ChangePasswordModalForm,
      componentProps: {
        isLoading: loading,
        onFinish: (values: IChangePasswordParams) => {
          updatePassword({
            variables: {
              newPassword: values.newPassword,
              oldPassword: values.oldPassword,
            },
          })
            .then(() => {
              onMutationSuccess(
                t("my_account.change_password.changed"),
                showToast,
              );
              hideModal();
            })
            .catch((error) => {
              onQueryError(error, showToast);
            });
        },
      },
    });
  }, [
    showModal,
    hideModal,
    t,
    updatePassword,
    loading,
    showToast,
  ]);

  return handleChangePassword;
};

export default useChangeUserPassword;
