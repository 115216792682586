import * as React from "react";
import { IconProps } from "@chakra-ui/icons";
import { createIcon } from "@chakra-ui/react";

const ReceiptIcon: React.FC<IconProps> = createIcon({
  path: (
    <>
      <path
        fill="currentColor"
        id="Path_5737"
        data-name="Path 5737"
        d="M19.5,3.5,18,2,16.5,3.5,15,2,13.5,3.5,12,2,10.5,3.5,9,2,7.5,3.5,6,2V16H3v3a3,3,0,0,0,3,3H18a3,3,0,0,0,3-3V2ZM15,20H6a1,1,0,0,1-1-1V18H15Zm4-1a1,1,0,1,1-2,0V16H8V5H19Z"
        transform="translate(-3 -2)"
      />
      <rect
        fill="currentColor"
        id="Rectangle_4940"
        data-name="Rectangle 4940"
        width="6"
        height="2"
        transform="translate(6 5)"
      />
      <rect
        fill="currentColor"
        id="Rectangle_4941"
        data-name="Rectangle 4941"
        width="2"
        height="2"
        transform="translate(13 5)"
      />
      <rect
        fill="currentColor"
        id="Rectangle_4942"
        data-name="Rectangle 4942"
        width="6"
        height="2"
        transform="translate(6 8)"
      />
      <rect
        fill="currentColor"
        id="Rectangle_4943"
        data-name="Rectangle 4943"
        width="2"
        height="2"
        transform="translate(13 8)"
      />
    </>
  ),
});

export default ReceiptIcon;
