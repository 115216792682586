import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
  IconButton,
  Stack,
  Text,
} from "@chakra-ui/react";

import { onMutationSuccess, onQueryError } from "utils/queryHandlers";
import HidePasswordIcon from "settings/theme/icons/HidePasswordIcon";
import ShowPasswordIcon from "settings/theme/icons/ShowPasswordIcon";
import useToast from "hooks/useToast";
import { SectionType } from "constants/formSteps";

import { ToggleSectionVisibilityProps } from "./types";
import useUpdateVisibility from "./hooks/useUpdateVisibility";

/**
 * Handles toggling section visibility.
 */
const ToggleSectionVisibility: React.FC<ToggleSectionVisibilityProps> = ({
  formId,
  fixedSectionConfigurationId,
  step,
}) => {
  const [showToast] = useToast();
  const [t] = useTranslation();

  const sectionType = step.extraSection
    ? SectionType.Extra
    : SectionType.Fixed;

  const {
    updateVisibility,
    loading,
  } = useUpdateVisibility({
    formId,
    sectionType,
    extraSectionId: step.extraSection?.id,
    fixedSectionConfigurationId,
    sectionVisibilityField: step.sectionVisibilityField,
  });

  const handleUpdateVisibility = useCallback(
    (show: boolean) => (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.stopPropagation();

      updateVisibility(show)
        .then(() => {
          onMutationSuccess(t("actions.information_updated"), showToast);
        })
        .catch((error) => {
          onQueryError(error, showToast);
        });
    },
    [
      updateVisibility,
      showToast,
      t,
    ],
  );

  if (!step.isOptional) {
    return null;
  }

  const { isVisible } = step;

  const buttonText = (
    isVisible
      ? t("buttons.hide")
      : t("buttons.show")
  );

  const Icon = (
    isVisible
      ? HidePasswordIcon
      : ShowPasswordIcon
  );

  return (
    <Stack
      onClick={handleUpdateVisibility(!isVisible)}
      justifyContent="center"
      alignItems="center"
      spacing={0}
    >
      <IconButton
        aria-label={buttonText}
        isLoading={loading}
        variant="unstyled"
        size="sm"
        icon={(
          <Icon color="primary.100" />
        )}
      />

      <Text
        color="primary.100"
        fontFamily="body"
        fontSize="2xxs"
      >
        {buttonText}
      </Text>
    </Stack>
  );
};

export default ToggleSectionVisibility;
