import { ExtraQuestion, ExtraQuestionTypeEnum } from "generated/graphql";
import DatePickerInput from "components/FormComponents/DatePickerInput";
import RadioGroup from "components/FormComponents/RadioGroup";
import Textarea from "components/FormComponents/Textarea";
import Input from "components/FormComponents/Input";

import { QuestionComponent } from "./types";

/**
 * Maps one question to its component that should be rendered.
 * @param question The question.
 */
const getQuestionComponent = (question: ExtraQuestion): QuestionComponent => ({
  [ExtraQuestionTypeEnum.Date]: {
    component: DatePickerInput,
    gridColumn: "span 1",
    defaultValue: "",
  },
  [ExtraQuestionTypeEnum.Options]: {
    component: RadioGroup,
    gridColumn: ["span 1", "span 2", "span 4"],
    defaultValue: "",
    componentProps: {
      options: (question.options ?? []).map((option) => ({
        label: option,
        value: option,
      })),
    },
  },
  [ExtraQuestionTypeEnum.StringBig]: {
    component: Input,
    gridColumn: ["span 1", "span 2", "span 4"],
    defaultValue: "",
  },
  [ExtraQuestionTypeEnum.StringMedium]: {
    component: Input,
    gridColumn: ["span 1", "span 2"],
    defaultValue: "",
    componentProps: {
    },
  },
  [ExtraQuestionTypeEnum.StringSmall]: {
    component: Input,
    gridColumn: "span 1",
    defaultValue: "",
  },
  [ExtraQuestionTypeEnum.Text]: {
    component: Textarea,
    gridColumn: ["span 1", "span 2", "span 4"],
    defaultValue: "",
    componentProps: {
      rows: 4,
    },
  },
})[question.type];

export default getQuestionComponent;
