import * as React from "react";
import { IconProps } from "@chakra-ui/icons";
import { createIcon } from "@chakra-ui/react";

const ScheduleIcon: React.FC<IconProps> = createIcon({
  path: (
    <>
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M11.992 4A8 8 0 1 0 20 12a8 8 0 0 0-8.008-8zM12 18.4a6.4 6.4 0 1 1 6.4-6.4 6.4 6.4 0 0 1-6.4 6.4zM12.4 8h-1.2v4.8l4.2 2.52.6-.984-3.6-2.136z" stroke="#fff" strokeWidth=".5" />
    </>
  ),
});

export default ScheduleIcon;
