import * as React from "react";
import { IconProps } from "@chakra-ui/icons";
import { createIcon } from "@chakra-ui/react";

const MinusIcon: React.FC<IconProps> = createIcon({
  path: (
    <path fill="currentColor" id="Path_5743" data-name="Path 5743" d="M19,13H5V11H19Z" />
  ),
});

export default MinusIcon;
