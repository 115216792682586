import React from "react";
import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";

import useCompanyOnboardingFormEffects from "components/Forms/CompanyOnboardingForm/hooks/useCompanyOnboardingFormEffects";
import personalStorySchema, { PersonalStorySchema } from "settings/yup/schemas/onboarding/personalStorySchema";
import useCompanyOnboardingForm from "components/Forms/CompanyOnboardingForm/hooks/useCompanyOnboardingForm";
import useCompanyOnboardingFormStore from "components/Forms/CompanyOnboardingForm/store";
import QuestionCard from "components/Forms/CompanyOnboardingForm/Cards/QuestionCard";
import Textarea from "components/FormComponents/Textarea";
import { FormTypeEnum } from "generated/graphql";
import { QuestionDisplayTypeEnum } from "constants/formEditableQuestions";
import Box from "components/Box";

import buildQuestions from "./questions";

const PersonalStory: React.FC = () => {
  const [t] = useTranslation();

  const {
    applicantOpenQuestionsIntro,
    clientOpenQuestionsIntro,
    applicantOpenQuestions,
    clientOpenQuestions,
    formType,
    isAllowedUploadImage,
  } = useCompanyOnboardingFormStore((store) => ({
    applicantOpenQuestionsIntro: store.form?.applicantOpenQuestionsIntro,
    applicantOpenQuestions: store.form?.job?.role?.applicantOpenQuestions,
    clientOpenQuestions: store.form?.job?.role?.clientOpenQuestions,
    clientOpenQuestionsIntro: store.form?.clientOpenQuestionsIntro,
    formType: store.formType,
    isAllowedUploadImage: store.form?.allowImagesUpload,
  }));

  const companyOnboardingFormEffects = useCompanyOnboardingFormEffects;

  const form = useCompanyOnboardingForm<PersonalStorySchema>({
    schema: personalStorySchema,
    formOptions: {
      defaultValues: {
        allowImagesUpload: isAllowedUploadImage,
        introductionText: (
          formType === FormTypeEnum.Application
            ? applicantOpenQuestionsIntro
            : clientOpenQuestionsIntro
        ),
      },
    },
  });

  const {
    setValue,
    watch,
    register,
    formState,
  } = form;

  register({ name: "allowImagesUpload" });

  companyOnboardingFormEffects({
    fields: ["allowImagesUpload", "introductionText"],
    form,
    overrideGetValues: ({
      getValues,
    }) => {
      const values = getValues();
      const { allowImagesUpload } = values;

      if (formType === FormTypeEnum.Application) {
        return {
          applicantOpenQuestionsIntro: values.introductionText,
          allowImagesUpload,
        };
      }

      return {
        clientOpenQuestionsIntro: values.introductionText,
        allowImagesUpload,
      };
    },
  });

  const allowImagesUpload = watch("allowImagesUpload");

  const updatePermissionForPictureUpload = (value: string): void => {
    const allowedPictureValue = value === QuestionDisplayTypeEnum.SHOW;
    formState.isDirty = true;
    setValue("allowImagesUpload", allowedPictureValue);
  };

  const questions = buildQuestions({
    applicantOpenQuestions,
    clientOpenQuestions,
    formType,
    allowImagesUpload,
    updatePermissionForPictureUpload,
  });

  return (
    <>
      <Box
        w="full"
        h="auto"
        title={t("question_types.introductory_description_optional")}
        wrapperProps={{
          mb: 0,
        }}
        titleProps={{
          fontFamily: "bold",
        }}
      >
        <Controller
          as={Textarea}
          name="introductionText"
          control={form.control}
          errors={form.errors}
          rows={5}
        />
      </Box>

      {
        questions.map((question) => (
          <QuestionCard
            fields={question.fields}
            title={question.title}
            key={question.title}
          />
        ))
      }
    </>
  );
};

export default PersonalStory;
