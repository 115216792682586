import { getErrorMessage } from "apollo-sentry-helper";
import { ApolloError } from "@apollo/client";

import { ShowToast } from "hooks/useToast/types";
import history from "settings/history";
import i18n from "translations/i18n";

/**
 * Shows an error snackbar displaying the contents of the query error.
 * @param error Query error
 * @param showToast Toast function
 */
export const onQueryError = (
  error: ApolloError,
  showToast: ShowToast,
): void => {
  showToast({
    title: getErrorMessage(error),
    status: "error",
  });
};

/**
 * Shows a snackbar according to network status.
 * @param message Success message
 * @param showToast Toast function
 */
export const onMutationSuccess = (
  message: string,
  showToast: ShowToast,
): void => {
  if (!navigator.onLine) {
    showToast({
      title: i18n.t("actions.you_are_offline"),
      status: "info",
    });

    return;
  }

  if (message) {
    showToast({
      title: message,
      status: "success",
    });
  }
};

/**
 * Shows a snackbar according to network status and redirects the
 * user to a specified path.
 * @param message Success message
 * @param redirectPath Path to redirect
 * @param showToast Toast function
 */
export const onMutationSuccessRedirect = (
  message: string,
  redirectPath: string,
  showToast: ShowToast,
): void => {
  onMutationSuccess(message, showToast);
  history.push(redirectPath);
};

/**
 * Shows a snackbar according to network status and goes back
 * to the previous visited path.
 * @param message Success message
 * @param showToast Toast function
 */
export const onMutationSuccessGoBack = (
  message: string,
  showToast: ShowToast,
): void => {
  onMutationSuccess(message, showToast);
  history.goBack();
};
