import React, { useCallback } from "react";
import { IconButtonProps, IconButton } from "@chakra-ui/react";
import { IconProps } from "@chakra-ui/icons";

import { CarouselArrowProps } from "components/Images/types";

const CarouselArrow: React.FC<CarouselArrowProps> = ({
  icon: IconComponent,
  iconButtonProps,
  clickHandler,
  hasPrev,
  label,
}) => {
  const handleClick = useCallback((event) => {
    event.stopPropagation();

    if (clickHandler) {
      clickHandler();
    }
  }, [clickHandler]);

  return (
    <IconButton
      {...iconButtonProps}
      transform="translate(0, -50%)"
      onClick={handleClick}
      isDisabled={!hasPrev}
      position="absolute"
      aria-label={label}
      variant="link"
      zIndex={10}
      top="50%"
      icon={(
        <IconComponent
          cursor="pointer"
          color="gray.200"
          boxSize={10}
        />
      )}
    />
  );
};

/**
 * Wrapper to render custom carousel icons.
 * @param Icon The icon component.
 * @param shouldRenderArrow Defines if the arrow should be shown.
 * @param props The icon component props.
 */
export const renderCarouselArrow = (
  icon: React.FC<IconProps>,
  shouldRenderArrow: boolean,
  props: Partial<IconButtonProps>,
) => (
  clickHandler: () => void,
  hasPrev: boolean,
  label: string,
): React.ReactNode => {
  if (shouldRenderArrow) {
    return (
      <CarouselArrow
        clickHandler={clickHandler}
        iconButtonProps={props}
        hasPrev={hasPrev}
        label={label}
        icon={icon}
      />
    );
  }

  return React.Fragment;
};

export default CarouselArrow;
