import { MIN_PASSWORD_LENGTH } from "constants/forms";
import yup from "settings/yup";

const resetPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .min(MIN_PASSWORD_LENGTH)
    .required(),
});

export interface ResetPasswordSchema {
  password: string;
}

export default resetPasswordSchema;
