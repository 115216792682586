import React from "react";
import { Stack } from "@chakra-ui/react";

import useWizardQueryStringPersistor from "components/Wizard/hooks/useWizardQueryStringPersistor";
import Wizard from "components/Wizard";

import FormWizardFooter from "./FormWizardFooter";
import { FormWizardProps } from "./types";
import FormWizardHeader from "./FormWizardHeader";

const FormWizard: React.FC<FormWizardProps> = ({
  showContinueButton = true,
  showGoBackButton = true,
  showSubmitButton = true,
  continueButtonDisabled,
  goBackButtonDisabled,
  submitButtonDisabled,
  colorScheme,
  isLoading,
  onContinue,
  onSubmit,
  ...rest
}) => {
  const initialStepIndex = useWizardQueryStringPersistor();

  return (
    <Stack spacing={4}>
      <FormWizardHeader />

      <Wizard
        initialStepIndex={initialStepIndex}
        colorScheme={colorScheme}
        showDivider
        {...rest}
      />

      <FormWizardFooter
        continueButtonDisabled={continueButtonDisabled}
        goBackButtonDisabled={goBackButtonDisabled}
        submitButtonDisabled={submitButtonDisabled}
        showContinueButton={showContinueButton}
        showGoBackButton={showGoBackButton}
        showSubmitButton={showSubmitButton}
        colorScheme={colorScheme}
        isLoading={isLoading}
        onContinue={onContinue}
        onSubmit={onSubmit}
      />
    </Stack>
  );
};

export default FormWizard;
