import React, { useCallback } from "react";
import { useDebouncedCallback } from "use-debounce";
import {
  SimpleGrid,
  Stack,
  Flex,
  Text,
} from "@chakra-ui/react";

import { PAGINATION_LIMIT_ITEMS_PER_PAGE } from "constants/pagination";
import SearchInput from "components/FormComponents/Input/SearchInput";
import { EmptyDataMessage } from "components/EmptyDataMessage";
import { TrackingListProps } from "components/Tracking/types";
import useFilter, { filters } from "hooks/useFilter";
import useLoadingDelay from "hooks/useLoadingDelay";
import Pagination from "components/Pagination";
import Loading from "components/Loading";

function TrackingList<T>({
  itemsPerPage = PAGINATION_LIMIT_ITEMS_PER_PAGE,
  loading: queryLoading,
  emptyDataMessage,
  countMessage,
  renderItem,
  totalCount,
  items,
}: TrackingListProps<T>): React.ReactElement {
  const [search, setSearch] = useFilter(filters.search);

  const debouncedSetSearch = useDebouncedCallback(setSearch, 500);

  const loading = useLoadingDelay(queryLoading);

  const handleSetSearch = useCallback((event) => {
    debouncedSetSearch.callback(event?.target?.value);
  }, [debouncedSetSearch]);

  const currentPageCount = items.length;

  return (
    <Stack
      alignItems="center"
      spacing={4}
      w="full"
    >
      <SearchInput
        defaultValue={String(search || "")}
        onChange={handleSetSearch}
        name="search"
      />

      {
        loading
          ? (
            <Loading />
          )
          : (
            <>
              <Text
                fontSize="xs"
                fontFamily="body"
                mr="auto !important"
              >
                {countMessage}
              </Text>

              <Flex width="100%">
                {
                  currentPageCount > 0
                    ? (
                      <SimpleGrid
                        columns={{ base: 1, xl: 2 }}
                        alignItems="flex-start"
                        gridAutoRows={200}
                        width="100%"
                        spacing={5}
                      >
                        {items.map(renderItem)}
                      </SimpleGrid>
                    )
                    : (
                      <Flex
                        align="center"
                        justify="center"
                        width="100%"
                        height="100%"
                      >
                        <EmptyDataMessage
                          message={emptyDataMessage}
                          isDataLoading={loading}
                        />
                      </Flex>
                    )
                }
              </Flex>

              <Pagination
                itemsPerPage={itemsPerPage}
                totalCount={totalCount}
              />
            </>
          )
      }
    </Stack>
  );
}

export default TrackingList;
