import { Applicant } from "generated/graphql";

/**
 * Formats the applicant address to show on the resume
 *
 * @param applicant The applicant
 */
const formatApplicantAddress = (applicant: Applicant): string => {
  const location = applicant?.location;

  if (location?.city && location?.state && location?.zipCode) {
    return `${location?.city}, ${location?.state} ${location?.zipCode}`;
  }

  return location?.streetAddress ?? "";
};

export default formatApplicantAddress;
