import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import Table from "components/Table";

import { makeTableColumns } from "./tableColumns";
import { PaymentsTableProps } from "../types";

const PaymentsTable: React.FC<PaymentsTableProps> = ({
  numberOfPayments,
  payments,
}) => {
  const [t] = useTranslation();

  const columns = useMemo(() => makeTableColumns(), []);

  return (
    <Table
      title={t("dashboard.subscription.payment_history")}
      columns={columns}
      data={payments}
      titleProps={{
        textTransform: "uppercase",
      }}
      containerProps={{
        rounded: "lg",
        shadow: "md",
      }}
      pagination={{
        totalCount: numberOfPayments,
      }}
    />
  );
};

export default PaymentsTable;
