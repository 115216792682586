import parseCompleteLocation from "utils/parseCompleteLocation";
import { Applicant, Client } from "generated/graphql";
import { ProfileType } from "constants/profiles";
import i18n from "translations/i18n";
import { formatDate, monthDayYear } from "utils/dateFormats";

import {
  ParseAdditionalInformationItemsOptions,
  AdditionalInformationItem,
} from "./types";

/**
 * Parses additional information items to be rendered according to the profile type.
 * @param param0 Options.
 */
const parseAdditionalInformationItems = ({
  profileType,
  data,
}: ParseAdditionalInformationItemsOptions): AdditionalInformationItem[] => {
  const additionalInformationItems: AdditionalInformationItem[] = [
    {
      title: i18n.t("profile_extra_information_card.email"),
      value: data?.email,
    },
    {
      title: i18n.t("profile_extra_information_card.location"),
      value: parseCompleteLocation(data?.location),
    },
    {
      title: i18n.t("profile_extra_information_card.phone_number"),
      value: data?.phoneNumber,
    },
  ];

  if (profileType === ProfileType.Applicant) {
    const applicant = data as Applicant;

    additionalInformationItems.push(...[
      {
        title: i18n.t("profile_extra_information_card.submitted_at"),
        value: formatDate(applicant?.appliedAt ?? "", monthDayYear),
      },
      {
        title: applicant?.qualified
          ? i18n.t("profile_extra_information_card.fulfilled_requirements")
          : i18n.t("profile_extra_information_card.unfulfilled_requirements"),
        value: applicant?.qualified
          ? (applicant?.fulfilledRequirements ?? [])
          : (applicant?.unfulfilledRequirements ?? []),
      },
      {
        title: i18n.t("profile_extra_information_card.available_start_date"),
        value: formatDate(applicant?.availableAt ?? "", monthDayYear),
      },
      {
        title: i18n.t("profile_extra_information_card.regarding_availability"),
        value: applicant?.dayAvailability,
      },
      {
        title: i18n.t("profile_extra_information_card.submitted_as_a_short_application"),
        value: (
          applicant?.isShortApplication
            ? i18n.t("buttons.yes")
            : i18n.t("buttons.no")
        ),
      },
    ]);
  }

  if (profileType === ProfileType.Client) {
    const client = data as Client;

    additionalInformationItems.push(...[
      {
        title: i18n.t("profile_extra_information_card.submitted_at"),
        value: formatDate(client?.submittedAt ?? "", monthDayYear),
      },
    ]);
  }

  const result = additionalInformationItems.filter((item) => !!item.value);

  return result;
};

export default parseAdditionalInformationItems;
