import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";

import configureSentry from "settings/sentry";

import App from "./App";

import "assets/fonts/index.css";
import "assets/scss/app.scss";

import "translations/i18n";

configureSentry();

ReactDOM.render(<App />, document.getElementById("root"));
