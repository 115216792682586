import React from "react";
import { Redirect } from "react-router-dom";

import CompanyOnboardingForm from "components/Forms/CompanyOnboardingForm";
import useCompanyOnboardingJob from "hooks/useCompanyOnboardingJob";
import useSteps from "views/Forms/ClientForm/hooks/useSteps";
import { FormTypeEnum } from "generated/graphql";
import Loading from "components/Loading";
import { ROOT_PAGE_PATH } from "routes";

const ConfigureQuestionnaire: React.FC = () => {
  const [job, loadingJob] = useCompanyOnboardingJob();

  if (loadingJob) {
    return (
      <Loading />
    );
  }

  if (!job?.form?.id) {
    return (
      <Redirect to={ROOT_PAGE_PATH} />
    );
  }

  return (
    <CompanyOnboardingForm
      formType={FormTypeEnum.Client}
      formId={job.form.id}
      useSteps={useSteps}
    />
  );
};

export default ConfigureQuestionnaire;
