import React, { useMemo, useCallback, useEffect } from "react";
import { CheckboxGroup, VStack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import createTrackingFilter from "components/Tracking/Filters";
import Checkbox from "components/FormComponents/Checkbox";
import useFilter, { filters } from "hooks/useFilter";
import { clientStatusSelectOptions } from "constants/statuses";
import { clientStatusFilterDefaultValues } from "components/Tracking/Filters/StatusFilters/options";

const ClientStatusFilter = createTrackingFilter(({
  setTitle,
}) => {
  const [t] = useTranslation();
  const [status, setStatus] = useFilter(filters.status);

  const value = useMemo<string[]>(() => (
    status ? JSON.parse(status as string) : []
  ), [status]);

  useEffect(() => {
    setTitle(`${t("filters.status")}:`);
  }, [
    setTitle,
    t,
  ]);

  useEffect(() => {
    const hasInitialValue = (
      !!status
      && Array.isArray(value)
      && value.length > 0
    );

    if (hasInitialValue) {
      return;
    }

    setStatus(JSON.stringify(clientStatusFilterDefaultValues));
  }, [
    value,
    status,
    setStatus,
  ]);

  const onChange = useCallback((option) => {
    const shouldSetFilter = Array.isArray(option) && option?.length > 0;

    if (shouldSetFilter) {
      setStatus(JSON.stringify(option));
    } else {
      setStatus(undefined);
    }
  }, [setStatus]);

  return (
    <VStack spacing={2} align="flex-start">
      <CheckboxGroup
        onChange={onChange}
        value={value}
      >
        <VStack spacing={2}>
          {
            clientStatusSelectOptions.map(option => (
              <Checkbox
                key={option.value}
                name={option.label}
                value={option.value}
              >
                {option.label}
              </Checkbox>
            ))
          }
        </VStack>
      </CheckboxGroup>
    </VStack>
  );
});

export default ClientStatusFilter;
