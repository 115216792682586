import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ModalFooter,
  ModalBody,
  Stack,
} from "@chakra-ui/react";

import { buyTokensRefetchQueries } from "graphql/mutations/buyTokensMutation";
import { SelectOption } from "components/FormComponents/Select/types";
import { onMutationSuccess, onQueryError } from "utils/queryHandlers";
import PaymentModal from "components/Modal/PaymentModal";
import { useModal } from "contexts/modal";
import Loading from "components/Loading";
import Button from "components/Button";
import useToast from "hooks/useToast";
import {
  useListExtraTokenPacksQuery,
  useBuyTokensMutation,
} from "generated/graphql";

import AddTokensPaymentInformation from "./AddTokensPaymentInformation";
import { AddTokensModalProps } from "./types";
import AddTokensForm from "./AddTokensForm";

const AddTokensModal: React.FC<AddTokensModalProps> = ({
  componentProps: {
    companyId,
    roleId,
  },
  hideModal,
}) => {
  const [showModal] = useModal();
  const [showToast] = useToast();
  const [t] = useTranslation();

  const {
    loading,
    data,
  } = useListExtraTokenPacksQuery({
    fetchPolicy: "network-only",
    variables: {
      roleId,
    },
  });

  const [buyTokens, {
    loading: buyTokensLoading,
  }] = useBuyTokensMutation({
    refetchQueries: buyTokensRefetchQueries,
    awaitRefetchQueries: true,
  });

  const [selectedTokensPack, setSelectedTokensPack] = useState<SelectOption>();

  const role = data?.getRole;

  const handleBuyTokens = useCallback(() => {
    buyTokens({
      variables: {
        extraTokensPackId: selectedTokensPack?.value as number,
        companyId,
      },
    })
      .then(() => {
        onMutationSuccess(t("dashboard.subscription.add_tokens_modal.tokens_added_successfully"), showToast);
      })
      .catch((error) => {
        onQueryError(error, showToast);
      })
      .finally(() => {
        hideModal();
      });
  }, [
    selectedTokensPack,
    companyId,
    buyTokens,
    hideModal,
    showToast,
    t,
  ]);

  const handleFinish = useCallback(() => {
    const extraTokensPack = role?.extraTokensPacks?.find((item) => (
      item.id === selectedTokensPack?.value
    ));

    if (!extraTokensPack) {
      return;
    }

    showModal({
      component: PaymentModal,
      componentProps: {
        onConfirm: handleBuyTokens,
        companyId,
        paymentInformation: (
          <AddTokensPaymentInformation extraTokensPack={extraTokensPack} />
        ),
      },
    });
  }, [
    selectedTokensPack,
    handleBuyTokens,
    showModal,
    companyId,
    role,
  ]);

  return (
    <>
      <ModalBody>
        <Stack
          spacing={4}
          py={6}
        >
          {
            (loading || !role)
              ? (
                <Loading
                  containerHeight={8}
                  size="md"
                />
              )
              : (
                <AddTokensForm
                  setSelectedTokensPack={setSelectedTokensPack}
                  selectedTokensPack={selectedTokensPack}
                  role={role}
                />
              )
          }
        </Stack>
      </ModalBody>

      <ModalFooter>
        <Button
          isDisabled={!selectedTokensPack}
          isLoading={buyTokensLoading}
          onClick={handleFinish}
          fontSize="sm"
          w="full"
        >
          {t("dashboard.subscription.add_tokens_modal.buy_tokens_now")}
        </Button>
      </ModalFooter>
    </>
  );
};

export default AddTokensModal;
