import { getI18n } from "react-i18next";

import yup from "settings/yup";
import { MIN_PASSWORD_LENGTH } from "constants/forms";

export const yupChangePasswordSchema = yup.object().shape({
  currentPassword: yup
    .string()
    .required(),
  newPassword: yup
    .string()
    .min(MIN_PASSWORD_LENGTH)
    .required(),
  confirmPassword: yup
    .string()
    .oneOf(
      [yup.ref("newPassword"), ""],
      getI18n().t("my_account.change_password.password_must_match"),
    )
    .min(MIN_PASSWORD_LENGTH)
    .required(),
});
