import { extendTheme } from "@chakra-ui/react";

import fontSizes from "./fontSizes";
import { Theme } from "./types";
import colors from "./colors";
import fonts from "./fonts";
import sizes from "./sizes";
import styles from "./styles";
import components from "./components";

const theme: Theme = extendTheme({
  fontSizes,
  colors,
  fonts,
  sizes,
  styles,
  components,
});

export default theme;
