import React from "react";
import { RadioGroup as ChakraRadioGroup, FormControl, Stack } from "@chakra-ui/react";

import FieldErrorMessage from "components/FormComponents/FieldErrorMessage";
import FieldTitle from "components/FormComponents/FieldTitle";
import getErrorMessage from "utils/getErrorMessage";
import Radio from "components/FormComponents/Radio";

import { RadioGroupProps } from "./types";

const RadioGroup = React.forwardRef<HTMLInputElement, RadioGroupProps>((
  {
    showErrorMessage = true,
    titleProps,
    options,
    errors,
    title,
    name,
    ...rest
  },
  ref,
) => {
  const error = getErrorMessage(name, errors);

  return (
    <FormControl>
      <ChakraRadioGroup
        ref={ref}
        {...rest}
      >
        {
          title && (
            <FieldTitle
              htmlFor={name}
              {...(titleProps ?? {})}
            >
              {title}
            </FieldTitle>
          )
        }

        <Stack spacing={2}>
          {
            options.map((option) => (
              <Radio
                value={option.value}
                isInvalid={!!error}
                key={option.value}
              >
                {option.label}
              </Radio>
            ))
          }
        </Stack>
      </ChakraRadioGroup>

      {
        showErrorMessage && (
          <FieldErrorMessage error={error} />
        )
      }
    </FormControl>
  );
});

export default RadioGroup;
