import React from "react";

import { LandingPageTemplateLayoutLabel } from "./landingPagesTemplatesData";
import Template1 from "./Template1";
import Template2 from "./Template2";
import Template3 from "./Template3";
import Template4 from "./Template4";
import Template5 from "./Template5";
import Template6 from "./Template6";
import { LandingPageTemplateProps } from "./types";

/**
 * Map landing pages templates according to the layout labels
 */
const mapLandingPagesTemplates = {
  [LandingPageTemplateLayoutLabel.TEMPLATE_1]: Template1,
  [LandingPageTemplateLayoutLabel.TEMPLATE_2]: Template2,
  [LandingPageTemplateLayoutLabel.TEMPLATE_3]: Template3,
  [LandingPageTemplateLayoutLabel.TEMPLATE_4]: Template4,
  [LandingPageTemplateLayoutLabel.TEMPLATE_5]: Template5,
  [LandingPageTemplateLayoutLabel.TEMPLATE_6]: Template6,
};

/**
 * Creates the landing page template according to a given
 * layout label
 */
const createLandingPageTemplate = (
  layoutLabel: LandingPageTemplateLayoutLabel,
): React.FC<LandingPageTemplateProps> => {
  const template = mapLandingPagesTemplates[layoutLabel];

  return template;
};

export default createLandingPageTemplate;
