import React from "react";
import { CircularProgress, Grid } from "@chakra-ui/react";

import { profilePrintGridCss } from "styles/css";

import { ProfileProps } from "./types";

const Profile: React.FC<ProfileProps> = ({
  loading,
  children,
}) => {
  if (loading) {
    return (
      <CircularProgress
        color="primary.500"
        alignSelf="center"
        mt={10}
      />
    );
  }

  return (
    <Grid
      css={profilePrintGridCss}
      templateColumns={{
        base: "1fr",
        xl: "1fr 30%",
      }}
      gap={4}
      mt={6}
    >
      {children}
    </Grid>
  );
};

export default Profile;
