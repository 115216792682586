import { useHistory } from "react-router-dom";
import { useEffect } from "react";

import { Form, useGetFormQuery } from "generated/graphql";
import { onQueryError } from "utils/queryHandlers";
import { ROOT_PAGE_PATH } from "routes";
import useToast from "hooks/useToast";

import useCompanyOnboardingFormQueryStringPersistor from "./useCompanyOnboardingFormQueryStringPersistor";
import { UseCompanyOnboardingFormStoreHandlerOptions } from "../types";
import useCompanyOnboardingFormStore from "../store";
import useEditFormSteps from "./useEditFormSteps";

/**
 * Hook that handles synchronizing data from the company onboarding form to the store.
 * It will handle fetching the latest form data from the backend and updating the store.
 * It will handle synchronizing the current selected step with the store.
 */
function useCompanyOnboardingFormStoreHandler({
  useSteps,
  formType,
  formId,
}: UseCompanyOnboardingFormStoreHandlerOptions): void {
  const [showToast] = useToast();
  const history = useHistory();

  useCompanyOnboardingFormQueryStringPersistor();

  const { data } = useGetFormQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
    skip: !formId,
    variables: {
      id: formId,
      formType,
    },
    onCompleted: (newData) => {
      const form = newData?.getForm as Form;

      if (!form) {
        history.push(ROOT_PAGE_PATH);
        return;
      }

      useCompanyOnboardingFormStore.setState({
        isFormLoading: false,
        formType,
        form,
      });
    },
    onError: (error) => {
      onQueryError(error, showToast);
      history.push(ROOT_PAGE_PATH);
    },
  });

  const form = data?.getForm as Form;

  const steps = useEditFormSteps({
    useSteps,
    formType,
    form,
  });

  useEffect(() => {
    useCompanyOnboardingFormStore.setState({
      steps,
    });
  }, [
    steps,
  ]);
}

export default useCompanyOnboardingFormStoreHandler;
