import { Text, ListItem } from "@chakra-ui/react";
import React from "react";

import { ApplicantResumeItemProps } from "components/Tracking/ClientMatchCard/types";

export const ApplicantResumeItem: React.FC<ApplicantResumeItemProps> = ({
  label,
  value,
  children,
  ...rest
}) => (
  <ListItem
    display="inline-block"
    w="full"
    {...rest}
  >
    <Text
      display="inline-block"
      maxHeight={120}
      maxWidth="full"
      overflow="auto"
    >
      <Text
        as="span"
        fontWeight="bold"
      >
        {label}
      </Text>

      {
        value && (
          <Text as="span" fontWeight="lighter">
            {value}
          </Text>
        )
      }
    </Text>

    {children}
  </ListItem>
);
