import { gql } from "@apollo/client";

export const createOrUpdateExtraSectionRefetchQueries = [
  "GetForm",
];

export default gql`
  mutation CreateOrUpdateExtraSection (
    $extraSectionId: Int!,
    $formId: Int!,
    $params: ExtraSectionParams!,
  ){
    createOrUpdateExtraSection (
      extraSectionId: $extraSectionId,
      formId: $formId,
      params: $params,
    ) {
      id
    }
  }
`;
