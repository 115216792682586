import { format, isValid, parseISO } from "date-fns";

import getDateFnsLocale from "translations/dateFns";

import captureSentryException from "./captureSentryException";

/**
 * Time mask.
 * E.g. 12:00:00
 */
export const timeMask = "HH:mm:ss";

/**
 * Hours and minutes.
 * E.g. 12:00
 */
export const hoursAndMinutes = "HH:mm";

/**
 * Date mask.
 * E.g. 01 April 2020
 */
export const dateMask = "d MMMM yyyy";

/**
 * Full date mask.
 * E.g. 1 April 2020 - 12:00:00
 */
export const fullDateMask = `${dateMask} - ${timeMask}`;

/**
 * Mask with year, month, day, for backend usage.
 * E.g. 2020-12-25
 */
export const yearMonthDay = "yyyy-MM-dd";

/**
 * Mask for date pickers.
 * E.g. 25/12/2020
 */
export const datePickerDateMask = "dd/MM/yyyy";

/**
 * Mask for short month name, day and hour.
 * E.g. May 3th - 08:00 PM
 */
export const monthNameDayHour = "MMM do - p";

/**
 * Mask for detailed time.
 * E.g. 08:00 PM
 */
export const detailedTime = "p";

/**
 * Mask for month name only.
 * E.g. January.
 */
export const monthName = "LLLL";

/**
 * Mask with month, day and year
 * E.g. 05/21/20
 */
export const monthDayYear = "MM/dd/yyyy";

/**
 * Mask with month, day and year and time
 * E.g. 05/21/20, 03:39 PM
 */
export const monthDayYearWithTime = "MM/dd/yyyy, p";

/**
 * Format to display date in DatePicker input with time.
 * E.g. 09/08/2020 - 03:39 PM
 */
export const dateTimePickerFormat = "MM/dd/yyyy - p";

/**
 * Format to display date in DatePicker input without time.
 * E.g. 09/08/2020M
 */
export const datePickerFormat = "MM/dd/yyyy";

/**
 * Tries to parse a date with `parseISO` when possible. Will fallback to the default `Date`
 * constructor in case the value is not valid for parsing or the parsed date is invalid.
 * @param date the date.
 */
const parseDate = (
  date: Date | string,
): Date => {
  if (date && typeof date === "string") {
    const parsedDate = parseISO(date);

    if (isValid(parsedDate)) {
      return parsedDate;
    }
  }

  return new Date(date);
};

/**
 * Safely formats a date with a mask.
 * @param date the date.
 * @param mask the mask.
 * @param fallback fallback return value if date is invalid.
 */
export const formatDate = (
  date: Date | string,
  mask = yearMonthDay,
  fallback = "",
): string => {
  if (!date) {
    return fallback;
  }

  let result: string;

  const parsedDate = parseDate(date);

  try {
    result = format(parsedDate, mask, {
      locale: getDateFnsLocale(),
    });
  } catch (error) {
    captureSentryException(new Error("Failed to format date."), {
      error,
      input: {
        parsedDate,
        fallback,
        date,
        mask,
      },
    });

    result = fallback;
  }

  return result;
};

/**
 * Safely formats a date to fullDate mask.
 * @param date the date.
 */
export const formatFullDate = (
  date: Date | string,
): string => formatDate(date, fullDateMask);

/**
 * Safely formats a date to a time mask.
 * @param date the date.
 */
export const formatTime = (
  date: Date | string,
): string => formatDate(date, timeMask);
