import i18n from "translations/i18n";
import { ClientRespondedEnum } from "generated/graphql";

/**
 * Maps the translation texts of ClientRespondedEnum
 */
export const mapRespondedByEnumTexts: Record<ClientRespondedEnum, string> = {
  [ClientRespondedEnum.Client]: i18n.t("responded_by.client"),
  [ClientRespondedEnum.Caregiver]: i18n.t("responded_by.caregiver"),
  [ClientRespondedEnum.FamilyMember]: i18n.t("responded_by.family_member"),
};

/**
 * Maps the translation texts of responded by sections in forms.
 */
export const mapRespondedByFormTexts: Record<ClientRespondedEnum, string> = {
  [ClientRespondedEnum.Client]: i18n.t("responded_by.client"),
  [ClientRespondedEnum.Caregiver]: i18n.t("responded_by.caregiver_form"),
  [ClientRespondedEnum.FamilyMember]: i18n.t("responded_by.family_member_form"),
};

/**
 * Defines possible options for responded by selects.
 */
export const respondedByFormOptions = Object.keys(mapRespondedByFormTexts).map((key) => ({
  value: key,
  label: mapRespondedByFormTexts[key],
}));
