import * as React from "react";
import { IconProps } from "@chakra-ui/icons";
import { createIcon } from "@chakra-ui/react";

const UpdateListIcon: React.FC<IconProps> = createIcon({
  path: (
    <path
      fill="currentColor"
      id="Path_5692"
      data-name="Path 5692"
      d="M12,4V1L8,5l4,4V6a6,6,0,0,1,6,6,5.87,5.87,0,0,1-.7,2.8l1.46,1.46A7.986,7.986,0,0,0,12,4Zm0,14a6,6,0,0,1-6-6,5.87,5.87,0,0,1,.7-2.8L5.24,7.74A7.986,7.986,0,0,0,12,20v3l4-4-4-4Z"
    />
  ),
});

export default UpdateListIcon;
