import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Stack } from "@chakra-ui/react";

import {
  useListImpersonatedCompanyCreditCardsQuery,
  useListCurrentCompanyCreditCardsQuery,
  useSetDefaultCreditCardMutation,
  useDeleteCreditCardMutation,
} from "generated/graphql";
import { setDefaultCreditCardRefetchQueries } from "graphql/mutations/setDefaultCreditCardMutation";
import { deleteCreditCardRefetchQueries } from "graphql/mutations/deleteCreditCardMutation";
import useValidateCurrentCompanyAction from "hooks/useValidateCurrentCompanyAction";
import AddCreditCardModal from "components/Modal/PaymentModal/AddCreditCardModal";
import { onMutationSuccess, onQueryError } from "utils/queryHandlers";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import PageHeader from "components/PageHeaders/PageHeader";
import PlusIcon from "settings/theme/icons/PlusIcon";
import useGetCompany from "hooks/useGetCompany";
import { useModal } from "contexts/modal";
import Loading from "components/Loading";
import Button from "components/Button";
import useToast from "hooks/useToast";

import CreditCardsTable from "./CreditCardsTable";
import { CreditCard } from "./types";

const Wallet: React.FC = () => {
  const [showModal] = useModal();
  const [showToast] = useToast();
  const [t] = useTranslation();

  const [validateCurrentCompanyAction] = useValidateCurrentCompanyAction();

  const [company, {
    loading,
  }] = useGetCompany(
    useListCurrentCompanyCreditCardsQuery,
    useListImpersonatedCompanyCreditCardsQuery,
    {
      fetchPolicy: "cache-and-network",
      onError: (error) => {
        onQueryError(error, showToast);
      },
    },
  );

  const [setDefaultCreditCard] = useSetDefaultCreditCardMutation({
    refetchQueries: setDefaultCreditCardRefetchQueries,
    awaitRefetchQueries: true,
  });

  const [deleteCreditCard] = useDeleteCreditCardMutation({
    refetchQueries: deleteCreditCardRefetchQueries,
    awaitRefetchQueries: true,
  });

  const companyId = company?.id;

  const creditCards = company?.creditCards ?? [];

  const handleAddNewCard = useCallback(() => {
    if (!companyId) {
      return;
    }

    validateCurrentCompanyAction(() => {
      showModal({
        title: t("buttons.add_new_card"),
        component: AddCreditCardModal,
        componentProps: {
          companyId,
        },
      });
    });
  }, [
    validateCurrentCompanyAction,
    companyId,
    showModal,
    t,
  ]);

  const handleRemoveCard = useCallback((creditCard: CreditCard) => () => {
    validateCurrentCompanyAction(() => {
      showModal({
        size: "xl",
        title: t("dashboard.wallet.remove_credit_card"),
        component: ConfirmationModal,
        componentProps: {
          text: t("dashboard.wallet.are_you_sure_you_want_to_remove", {
            last4: creditCard.last4,
          }),
          onConfirm: () => {
            deleteCreditCard({
              variables: {
                id: creditCard.id,
              },
            })
              .then(() => {
                onMutationSuccess(t("dashboard.wallet.credit_card_removed_successfully"), showToast);
              })
              .catch((error) => {
                onQueryError(error, showToast);
              });
          },
        },
      });
    });
  }, [
    validateCurrentCompanyAction,
    deleteCreditCard,
    showModal,
    showToast,
    t,
  ]);

  const handleSetCardAsDefault = useCallback((creditCard: CreditCard) => () => {
    validateCurrentCompanyAction(() => {
      setDefaultCreditCard({
        variables: {
          id: creditCard.id,
        },
      })
        .then(() => {
          onMutationSuccess(t("actions.information_updated"), showToast);
        })
        .catch((error) => {
          onQueryError(error, showToast);
        });
    });
  }, [
    validateCurrentCompanyAction,
    setDefaultCreditCard,
    showToast,
    t,
  ]);

  return (
    <>
      <PageHeader title={t("dashboard.wallet.title")}>
        <Button
          rightIcon={(<PlusIcon />)}
          onClick={handleAddNewCard}
          variant="outline"
          fontSize="xs"
          color="black"
          size="sm"
        >
          {t("buttons.add_new_card")}
        </Button>
      </PageHeader>

      <Stack
        spacing={4}
        w="full"
        mt={6}
      >
        {
          loading
            ? (
              <Loading />
            )
            : (
              <CreditCardsTable
                setCardAsDefault={handleSetCardAsDefault}
                removeCard={handleRemoveCard}
                creditCards={creditCards}
              />
            )
        }
      </Stack>
    </>
  );
};

export default Wallet;
