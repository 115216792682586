import { gql } from "@apollo/client";

export const createSubscriptionRefetchQueries = [
  "ListCurrentCompanySubscriptions",
  "ListCurrentCompanyPayments",
  "GetJob",
];

export default gql`
  mutation CreateSubscription (
    $billingType: BillingType!,
    $companyId: Int!,
    $planId: Int!,
  ) {
    createSubscription (
      billingType: $billingType,
      companyId: $companyId,
      planId: $planId,
    ) {
      id
    }
  }
`;
