import React from "react";
import {
  Avatar,
  Stack,
  Text,
} from "@chakra-ui/react";

import { formatDate, monthDayYear } from "utils/dateFormats";
import { Column } from "components/Table/types";
import i18n from "translations/i18n";

import { ActiveMember, MemberCrudActions } from "../types";
import ActionButtonsRow from "./ActionButtonsRow";

/**
 * Builds the table columns.
 */
export const makeTableColumns = ({
  handleReinviteMember,
  handleRemoveMember,
  currentUserMember,
  handleEditMember,
}: MemberCrudActions): Column<ActiveMember>[] => [
  {
    cellProps: {
      borderRightWidth: 0,
    },
    render: ({
      currentIndex,
      props,
    }) => (
      <Stack
        alignItems="center"
        spacing={4}
        isInline
      >
        <Text fontSize={props.fontSize}>
          {currentIndex + 1}
        </Text>
      </Stack>
    ),
  },
  {
    title: i18n.t("dashboard.team.table.name"),
    fieldPath: "name",
    render: ({
      row: member,
      props,
    }) => (
      <Stack
        alignItems="center"
        spacing={4}
        isInline
      >
        <Avatar
          name={member?.name}
          src={member?.photo}
          size="sm"
        />

        <Text
          fontSize={props.fontSize}
          whiteSpace="break-spaces"
        >
          {member?.name}
        </Text>
      </Stack>
    ),
  },
  {
    title: i18n.t("dashboard.team.table.start_date"),
    fieldResolver: (member) => {
      const insertedAt = member?.user?.insertedAt;

      return (
        insertedAt
          ? formatDate(insertedAt, monthDayYear)
          : i18n.t("dashboard.team.table.invited")
      );
    },
  },
  {
    title: i18n.t("dashboard.team.table.email_address"),
    fieldResolver: (member) => {
      const email = member?.invitedEmail ?? member?.user?.email;

      if (!member?.user?.id) {
        return i18n.t("dashboard.team.table.invite_sent_to", {
          to: email,
        });
      }

      return email;
    },
  },
  {
    title: i18n.t("dashboard.team.table.phone_number"),
    fieldPath: "phoneNumber",
  },
  {
    title: i18n.t("dashboard.team.table.occupation"),
    fieldResolver: (member) => i18n.t(`occupations.${String(member.occupation).toLowerCase()}`),
    cellProps: {
      borderRightWidth: 0,
    },
  },
  {
    render: ({
      row: member,
    }) => (
      <ActionButtonsRow
        handleReinviteMember={handleReinviteMember}
        handleRemoveMember={handleRemoveMember}
        currentUserMember={currentUserMember}
        handleEditMember={handleEditMember}
        member={member}
      />
    ),
  },
];
