import { Flex, Text } from "@chakra-ui/react";
import React from "react";

import Box from "components/Box";

import { NumberCircleProps } from "./types";

const NumberCircle: React.FC<NumberCircleProps> = ({
  bgColor = "primary.500",
  boxSize = "40px",
  value,
}) => (
  <Flex
    as={Box}
    justifyContent="center"
    alignItems="center"
    borderRadius={100}
    minHeight={boxSize}
    minWidth={boxSize}
    boxSize={boxSize}
    bgColor={bgColor}
  >
    <Text
      fontSize="sm"
      color="white"
    >
      {value}
    </Text>
  </Flex>
);

export default NumberCircle;
