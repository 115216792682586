import { MemberOccupationEnum } from "generated/graphql";

import { DashboardComponentProps } from "./types";
import DashboardManager from "./DashboardManager";
import DashboardUser from "./DashboardUser";

const mapDashboardComponents: Record<MemberOccupationEnum, React.FC<DashboardComponentProps>> = {
  [MemberOccupationEnum.Manager]: DashboardManager,
  [MemberOccupationEnum.RecruiterScheduler]: DashboardUser,
  [MemberOccupationEnum.Scheduler]: DashboardUser,
  [MemberOccupationEnum.Recruiter]: DashboardUser,
};

/**
 * Returns the dashboard component to be rendered according to the current member occupation.
 *
 * @param occupation The current member occupation.
 * @param isAdmin Defines if the current user is an admin.
 */
const getDashboardComponent = (
  occupation?: MemberOccupationEnum,
  isAdmin = false,
): React.FC<DashboardComponentProps> => {
  if (isAdmin) {
    return DashboardManager;
  }

  return (
    occupation
      ? mapDashboardComponents[occupation]
      : DashboardUser
  );
};

export default getDashboardComponent;
