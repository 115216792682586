import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import {
  EDIT_QUESTIONNAIRE_FORM_PAGE_LOCATION,
  EDIT_APPLICATION_FORM_PAGE_LOCATION,
  EDIT_SHORT_APPLICATION_FORM_PAGE_LOCATION,
} from "routes/locations";
import {
  FormTypeEnum,
  Job,
} from "generated/graphql";
import useSelectJob from "hooks/useSelectJob";

import { UseConfigureFormResult } from "./types";

/**
 * Exposes a function allows the user to customize an Application or Client form.
 *
 * When called, the function will query for the available jobs for the given form type
 * and open a modal for the user to select the job to customize the form for.
 *
 * If the user confirms the selection, the hook will redirect the page to the form
 * configuration page for the given form that was selected.
 *
 * The query loading state is also exposed.
 *
 * In case the query fails, a toast will be shown with the error.
 */
const useConfigureForm = (
  formType: FormTypeEnum,
): UseConfigureFormResult => {
  const [t] = useTranslation();
  const history = useHistory();

  const onConfirm = useCallback((job: Job) => {
    const location = ({
      [FormTypeEnum.Application]: EDIT_APPLICATION_FORM_PAGE_LOCATION,
      [FormTypeEnum.Client]: EDIT_QUESTIONNAIRE_FORM_PAGE_LOCATION,
      [FormTypeEnum.ShortApplication]: EDIT_SHORT_APPLICATION_FORM_PAGE_LOCATION,
    })[formType];

    const path = location.toUrl({
      id: job?.form?.id,
    });

    history.push(path);
  }, [
    formType,
    history,
  ]);

  const modalTitle = {
    [FormTypeEnum.Application]: t("actions.edit_full_application"),
    [FormTypeEnum.ShortApplication]: t("actions.edit_short_application"),
    [FormTypeEnum.Client]: t("actions.edit_client_form"),
  }[formType];

  return useSelectJob({
    modalTitle,
    onConfirm,
    formType,
  });
};

export default useConfigureForm;
