import React from "react";
import { useTranslation } from "react-i18next";

import QuestionCard from "components/Forms/CompanyOnboardingForm/Cards/QuestionCard";

import fields from "./fields";

const Resume: React.FC = () => {
  const [t] = useTranslation();

  return (
    <QuestionCard
      title={t("question_types.upload_index", { index: 1 })}
      fields={fields}
    />
  );
};

export default Resume;
