import yup from "settings/yup";

const resumeSchema = yup.object().shape({
  resume: yup
    .mixed(),
});

export interface ResumeSchema {
  resume?: File;
}

export default resumeSchema;
