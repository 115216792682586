import React, { useCallback, useMemo, useState } from "react";

import { Period, useGetDashboardApplicationsOverviewQuery } from "generated/graphql";
import Box from "components/Box";

import ApplicationsOverviewCardHeader from "./ApplicationsOverviewCardHeader";
import ApplicationsOverviewChart from "./ApplicationsOverviewChart";
import { ApplicationsOverviewCardProps } from "./types";
import { getYearPeriods } from "../../periods";
import { YearPeriod } from "../../types";

const ApplicationsOverviewCard: React.FC<ApplicationsOverviewCardProps> = ({
  showPeriodSelector = true,
  subtitle,
  period,
  jobId,
  title,
}) => {
  const periodOptions = useMemo<YearPeriod[]>(() => getYearPeriods(), []);

  const [
    selectedPeriod,
    setSelectedPeriod,
  ] = useState(periodOptions.find((option) => option.isCurrent));

  const handleSetSelectedPeriod = useCallback((value) => () => {
    setSelectedPeriod(value);
  }, []);

  const queryPeriod = period ?? selectedPeriod?.value;

  const {
    loading,
    data,
  } = useGetDashboardApplicationsOverviewQuery({
    fetchPolicy: "cache-and-network",
    skip: !queryPeriod,
    variables: {
      period: queryPeriod as Period,
      jobId,
    },
  });

  return (
    <Box
      h="full"
      w="full"
      isLoading={loading}
      title={(
        <ApplicationsOverviewCardHeader
          setSelectedPeriod={handleSetSelectedPeriod}
          showPeriodSelector={showPeriodSelector}
          selectedPeriod={selectedPeriod}
          periodOptions={periodOptions}
          subtitle={subtitle}
          title={title}
        />
      )}
      wrapperProps={{
        mb: 0,
      }}
    >
      <ApplicationsOverviewChart
        data={data?.getApplicationsOverview}
      />
    </Box>
  );
};

export default ApplicationsOverviewCard;
