import { ChartOptions } from "chart.js";
import deepMerge from "deepmerge";

import defaultChartOptions from "components/Charts/defaultChartOptions";
import theme from "settings/theme";

/**
 * Defines X-Axis IDs
 */
export const xAxisID = {
  scores: "x-axis-scores",
  average: "x-axis-average",
};

/**
 * Creates the default options for spider charts.
 */
const getChartOptions = (
  stacked: boolean,
): ChartOptions => deepMerge(defaultChartOptions, {
  maintainAspectRatio: false,
  layout: {
    padding: {
      right: 30,
      bottom: 0,
      left: 0,
      top: 0,
    },
  },
  plugins: {
    datalabels: {
      display: true,
      color: "black",
      font: {
        size: 14,
      },
      anchor: "end",
      align: "end",
      offset: 3,
      formatter: (value: number) => Math.round(value * 100),
    },
  },
  scales: {
    yAxes: [
      {
        afterFit(scaleInstance) {
          // eslint-disable-next-line no-param-reassign
          scaleInstance.width = 150;
        },
        stacked,
        ticks: {
          fontFamily: theme.fonts.heading,
          fontColor: theme.colors.black,
          beginAtZero: true,
          fontSize: 14,
          min: 0,
          max: 1,
        },
        gridLines: {
          display: false,
        },
      },
    ],
    xAxes: [
      {
        id: xAxisID.scores,
        stacked,
        gridLines: {
          display: false,
        },
        ticks: {
          beginAtZero: true,
          display: false,
          min: 0,
          max: 1,
        },
      },
      {
        id: xAxisID.average,
        position: "left",
        type: "linear",
        stacked: true,
        gridLines: {
          display: false,
        },
        ticks: {
          beginAtZero: true,
          display: false,
          min: 0,
          max: 1,
        },
      },
    ],
  },
  tooltips: {
    intersect: true,
  },
});

export default getChartOptions;
