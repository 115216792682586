/**
 * Makes a progressive array from a count.
 *
 * @example makeProgressiveArray(5, true) => [0, 1, 2, 3, 4]
 * @example makeProgressiveArray(5, false) => [1, 2, 3, 4, 5]
 * @example makeProgressiveArray(5) => [1, 2, 3, 4, 5]
 *
 * @param count The count.
 * @param startFromZero Defines if the array starts from zero (true) or one (false, default).
 */
const makeProgressiveArray = (
  count: number,
  startFromZero = false,
): number[] => (
  Array.from(Array(count).keys())
    .map((key) => (
      startFromZero
        ? key
        : key + 1
    ))
);

export default makeProgressiveArray;
