import * as React from "react";
import { IconProps } from "@chakra-ui/icons";
import { Icon } from "@chakra-ui/react";

const ArrowLeftIcon = React.forwardRef<SVGSVGElement, IconProps>((props, ref) => (
  <Icon {...props} ref={ref}>
    <g id="Group_14353" data-name="Group 14353" transform="translate(-1238 -822)">
      <g id="arrow_forward_ios-24px" transform="translate(1243.333 827.333)">
        <path fill="none" id="Path_5869" data-name="Path 5869" d="M0,13.333H13.333V0H0Z" opacity="0.87" />
        <path fill="currentColor" id="Path_5870" data-name="Path 5870" d="M12.973,12.117l-.983.983L6.49,7.6l5.5-5.5.983.983L8.457,7.6Z" transform="translate(-3.246 -0.933)" />
      </g>
    </g>
  </Icon>
));

export default ArrowLeftIcon;
