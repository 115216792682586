import React from "react";
import { TFunction } from "i18next";

import { REACT_TOUR_APPLICANTS_PROFILE_KEY } from "constants/storage";
import { APPLICANTS_PROFILE_TOUR_STEPS } from "constants/reactTour";
import { Role } from "generated/graphql";

import ReactTour from "../ReactTour";
import { Step } from "../types";
import { ApplicantsProfileTourProps } from "./types";

const makeMapStepList = ({
  roleName,
  isProfessional,
}: {roleName?: Role["name"]; isProfessional: boolean}) => (t:TFunction, step: string): Step => ({
  [APPLICANTS_PROFILE_TOUR_STEPS.roleFit]: {
    title: "react_tour.applicants_profile.steps.role_fit.title",
    content: `react_tour.applicants_profile.steps.role_fit.content_${
      isProfessional
        ? "professional"
        : "default"
    }`,
    roleName,
  },
  [APPLICANTS_PROFILE_TOUR_STEPS.qualities]: {
    title: "react_tour.applicants_profile.steps.qualities.title",
    content: "react_tour.applicants_profile.steps.qualities.content",
    tip: "react_tour.applicants_profile.steps.qualities.tip",
    roleName,
  },
  [APPLICANTS_PROFILE_TOUR_STEPS.chartView]: {
    title: "react_tour.applicants_profile.steps.change_chart_view.title",
    content: "react_tour.applicants_profile.steps.change_chart_view.content",
    roleName,
  },
  [APPLICANTS_PROFILE_TOUR_STEPS.qualitiesDefinition]: {
    title: "react_tour.applicants_profile.steps.qualities_definition.title",
    content: "react_tour.applicants_profile.steps.qualities_definition.content",
    roleName,
  },
  [APPLICANTS_PROFILE_TOUR_STEPS.review]: {
    title: "react_tour.applicants_profile.steps.review.title",
    content: "react_tour.applicants_profile.steps.review.content",
    roleName,
  },
  [APPLICANTS_PROFILE_TOUR_STEPS.tutorial]: {
    title: "react_tour.applicants_profile.steps.tutorial.title",
    content: "react_tour.applicants_profile.steps.tutorial.content",
    roleName,
  },
})[step];

const ApplicantsProfileTour: React.FC<ApplicantsProfileTourProps> = ({
  isProfessional = false,
  isLoading = true,
  isPrintLayout,
  roleName,
}) => {
  const mapStepList = makeMapStepList({
    roleName,
    isProfessional,
  });

  return (
    <ReactTour
      isLoading={isLoading}
      tourKey={REACT_TOUR_APPLICANTS_PROFILE_KEY}
      stepList={APPLICANTS_PROFILE_TOUR_STEPS}
      mapStepList={mapStepList}
      isPrintLayout={isPrintLayout}
    />
  );
};

export default ApplicantsProfileTour;
