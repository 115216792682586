import React from "react";

import { LandingPageTemplateProps } from "./types";
import JobDescriptionSection from "./JobDescriptionSection";
import TemplateBackground from "./TemplateBackground";
import JobMainSection from "./JobMainSection";

const Template3: React.FC<LandingPageTemplateProps> = ({
  navigateToForm,
  landingPage,
}) => (
  <>
    <TemplateBackground
      width="100vw"
      height="100vh"
      paddingY={20}
      paddingX={[10, null, 40]}
      backgroundPosition={["10%", "top"]}
      headerImageUrl={landingPage?.headerImageUrl}
    >
      <JobMainSection
        navigateToForm={navigateToForm}
        landingPage={landingPage}
        containerProps={{
          alignItems: "flex-end",
        }}
        textProps={{
          textAlign: "right",
        }}
      />
    </TemplateBackground>

    <JobDescriptionSection
      navigateToForm={navigateToForm}
      landingPage={landingPage}
    />
  </>
);

export default Template3;
