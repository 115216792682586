import { theme as chakraUiTheme } from "@chakra-ui/react";
import update from "immutability-helper";

import { ThemeComponents } from "./types";

const components: ThemeComponents = update(chakraUiTheme.components, {
  Heading: {
    sizes: {
      xl: {
        lineHeight: {
          $set: [null, null, 1.25, 1.25, 1.25],
        },
      },
    },
  },
  Button: {
    baseStyle: {
      borderRadius: {
        $set: "0.25rem",
      },
    },
  },
});

export default components;
