import React from "react";
import ReactSelect from "react-select";

import { SelectProps } from "./types";
import styles from "./styles";

const UncontrolledSelect = React.forwardRef<ReactSelect, SelectProps>((
  props,
  ref,
) => (
  <ReactSelect
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ref={ref as any}
    styles={styles}
    {...props}
  />
));

export default UncontrolledSelect;
