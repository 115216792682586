import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Stack } from "@chakra-ui/react";

import {
  useListImpersonatedCompanyActiveMembersQuery,
  useListCurrentCompanyActiveMembersQuery,
  useResendMemberInvitationEmailMutation,
  useUpdateMemberMutation,
  useCreateMemberMutation,
  useDeleteMemberMutation,
  MemberOccupationEnum,
  Member,
} from "generated/graphql";
import { MemberInformationSchema } from "settings/yup/schemas/memberInformationSchema";
import buildRefetchQueries from "hooks/useGetCompany/buildRefetchQueries";
import { onMutationSuccess, onQueryError } from "utils/queryHandlers";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import PageHeader from "components/PageHeaders/PageHeader";
import PlusIcon from "settings/theme/icons/PlusIcon";
import getSelectValue from "utils/getSelectValue";
import useGetCompany from "hooks/useGetCompany";
import { useModal } from "contexts/modal";
import Loading from "components/Loading";
import Button from "components/Button";
import useToast from "hooks/useToast";

import MemberInformationFormModal from "./MemberInformationFormModal";
import CompanyMembersTable from "./CompanyMembersTable";

const Team: React.FC = () => {
  const [showModal] = useModal();
  const [showToast] = useToast();
  const [t] = useTranslation();

  const [company, {
    currentCompanyData,
    loading,
  }] = useGetCompany(
    useListCurrentCompanyActiveMembersQuery,
    useListImpersonatedCompanyActiveMembersQuery,
    {
      onError: (error) => {
        onQueryError(error, showToast);
      },
    },
  );

  const refetchQueries = buildRefetchQueries({
    refetchCurrentCompanyQueries: [
      "ListCurrentCompanyActiveMembers",
    ],
    refetchImpersonatedCompanyQueries: [
      "ListImpersonatedCompanyActiveMembers",
    ],
  });

  const [updateMember, {
    loading: isUpdateMemberLoading,
  }] = useUpdateMemberMutation({
    refetchQueries,
  });

  const [createMember, {
    loading: isCreateMemberLoading,
  }] = useCreateMemberMutation({
    refetchQueries,
  });

  const [resendMemberInvitationEmail] = useResendMemberInvitationEmailMutation({
    refetchQueries,
  });

  const [deleteMember] = useDeleteMemberMutation({
    refetchQueries,
  });

  const currentUserMember = currentCompanyData?.currentUser?.member;

  const activeMembers = useMemo<Member[]>(() => (
    (company?.activeMembers ?? []) as Member[]
  ), [
    company,
  ]);

  const handleAddMember = useCallback(() => {
    if (!company?.id) {
      return;
    }

    showModal({
      size: "xl",
      title: t("dashboard.team.add_team_member"),
      component: MemberInformationFormModal,
      componentProps: {
        isLoading: isCreateMemberLoading,
        onFinish: (values: MemberInformationSchema) => {
          createMember({
            variables: {
              params: {
                ...values,
                occupation: getSelectValue(values?.occupation) as MemberOccupationEnum,
                companyId: company.id,
              },
            },
          })
            .then(() => {
              onMutationSuccess(t("dashboard.team.member_added_successfully"), showToast);
            })
            .catch((error) => {
              onQueryError(error, showToast);
            });
        },
      },
    });
  }, [
    isCreateMemberLoading,
    createMember,
    company,
    showToast,
    showModal,
    t,
  ]);

  const handleEditMember = useCallback((id: number) => () => {
    const member = activeMembers.find((activeMember) => activeMember.id === id);

    if (!member) {
      return;
    }

    showModal({
      size: "xl",
      title: t("dashboard.team.edit_member_info"),
      component: MemberInformationFormModal,
      componentProps: {
        isLoading: isUpdateMemberLoading,
        isEmailDisabled: !!member?.user?.id,
        initialValues: {
          invitedEmail: member.invitedEmail ?? member?.user?.email,
          phoneNumber: member.phoneNumber,
          occupation: member.occupation,
          name: member.name,
        },
        onFinish: (values: MemberInformationSchema) => {
          updateMember({
            variables: {
              id,
              params: {
                ...values,
                occupation: getSelectValue(values?.occupation) as MemberOccupationEnum,
              },
            },
          })
            .then(() => {
              onMutationSuccess(t("actions.information_updated"), showToast);
            })
            .catch((error) => {
              onQueryError(error, showToast);
            });
        },
      },
    });
  }, [
    isUpdateMemberLoading,
    activeMembers,
    updateMember,
    showToast,
    showModal,
    t,
  ]);

  const handleReinviteMember = useCallback((id: number) => () => {
    resendMemberInvitationEmail({
      variables: {
        id,
      },
    })
      .then(() => {
        onMutationSuccess(t("dashboard.team.member_reinvited_successfully"), showToast);
      })
      .catch((error) => {
        onQueryError(error, showToast);
      });
  }, [
    resendMemberInvitationEmail,
    showToast,
    t,
  ]);

  const handleRemoveMember = useCallback((id: number) => () => {
    const member = activeMembers.find((activeMember) => activeMember.id === id);

    if (!member) {
      return;
    }

    showModal({
      size: "xl",
      title: t("dashboard.team.remove_team_member"),
      component: ConfirmationModal,
      componentProps: {
        text: t("dashboard.team.are_you_sure_you_want_to_remove", {
          member: member.name,
        }),
        onConfirm: () => {
          deleteMember({
            variables: {
              id,
            },
          })
            .then(() => {
              onMutationSuccess(t("dashboard.team.member_removed_successfully"), showToast);
            })
            .catch((error) => {
              onQueryError(error, showToast);
            });
        },
      },
    });
  }, [
    activeMembers,
    deleteMember,
    showModal,
    showToast,
    t,
  ]);

  return (
    <>
      <PageHeader title={t("dashboard.team.title")}>
        <Button
          onClick={handleAddMember}
          rightIcon={(<PlusIcon />)}
          variant="outline"
          fontSize="xs"
          color="black"
          size="sm"
        >
          {t("dashboard.team.add_team_member")}
        </Button>
      </PageHeader>

      <Stack
        spacing={4}
        w="full"
        mt={6}
      >
        {
          loading
            ? (
              <Loading />
            )
            : (
              <CompanyMembersTable
                handleReinviteMember={handleReinviteMember}
                handleRemoveMember={handleRemoveMember}
                currentUserMember={currentUserMember}
                handleEditMember={handleEditMember}
                activeMembers={activeMembers}
              />
            )
        }
      </Stack>
    </>
  );
};

export default Team;
