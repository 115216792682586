import { Link } from "@chakra-ui/react";
import React from "react";

import { Location } from "generated/graphql";

import buildLocationUrl from "./buildLocationUrl";

const parseLocation = (location?: Partial<Location>): string | React.ReactElement => {
  const lat = location?.latitude;
  const lng = location?.longitude;
  const hasLatLng = !!lat && !!lng;

  let address = location?.streetAddress ? location.streetAddress : "";

  address = location?.complement ? `${address}, ${location.complement}` : address;
  address = location?.city ? `${address}, ${location.city}` : address;
  address = location?.state ? `${address}, ${location.state}` : address;
  address = location?.zipCode ? `${address} ${location.zipCode}` : address;

  if (!hasLatLng) {
    return address;
  }

  return (
    React.createElement(Link, {
      href: buildLocationUrl(location?.latitude, location?.longitude),
      color: "primary.200",
      rel: "noreferrer",
      target: "_blank",
    }, [
      address,
    ])
  );
};

export default parseLocation;
