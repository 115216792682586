import * as R from "remeda";

import sortStringArray from "./sortStringArray";

/**
 * Verify if two arrays of strings are equal
 *
 * @example
 * getIsStringArraysEqual(
  * ["Pedro Bini", "Laura Beatris"],
  * ["Laura Beatris", "Pedro Bini"]
 * ) -> true
 *
 * @param value1 The first array of strings value to compare
 * @param value2 The second array of strings to compare
 */
const getIsStringArraysEqual = (value1: string[], value2: string[]): boolean => (
  R.equals(sortStringArray(value1), sortStringArray(value2))
);

export default getIsStringArraysEqual;
