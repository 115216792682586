import { createOrUpdateExtraSectionRefetchQueries } from "graphql/mutations/createOrUpdateExtraSectionMutation";
import { useCreateOrUpdateExtraSectionMutation } from "generated/graphql";

import useCompanyOnboardingBaseEffects from "./useCompanyOnboardingBaseEffects";
import { UseCompanyOnboardingEffectsOptions } from "../types";
import useCompanyOnboardingFormStore from "../store";

/**
 * Handles side-effects in a extra section step using the `createOrUpdateExtraSection` mutation.
 */
function useCompanyOnboardingExtraSectionEffects<Schema>(
  options: UseCompanyOnboardingEffectsOptions<Schema>,
): void {
  const {
    extraSectionId,
    formId,
  } = useCompanyOnboardingFormStore((store) => ({
    extraSectionId: store.getStep()?.extraSection?.id as number,
    formId: store.form?.id as number,
  }));

  const createOrUpdateExtraSectionMutation = useCreateOrUpdateExtraSectionMutation({
    refetchQueries: createOrUpdateExtraSectionRefetchQueries,
  });

  return useCompanyOnboardingBaseEffects({
    mutation: createOrUpdateExtraSectionMutation,
    mutationPayload: {
      extraSectionId,
      formId,
    },
    ...options,
  });
}

export default useCompanyOnboardingExtraSectionEffects;
