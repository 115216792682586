import { useEffect } from "react";
import { useForm, UseFormMethods } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import { useMount } from "react-use";

import useFormStore from "views/Forms/store";

import { UseFormStepOptions } from "./types";

/**
 * Handles the Form logic form a Form Step. Initializes the useForm hook, updates the values within
 * the form store and exposes the form properties according to the schema.
 * @param schema The schema.
 */
function useFormStep<Schema>({
  overrideGetValues,
  formOptions,
  schema,
}: UseFormStepOptions<Schema>): UseFormMethods<Schema> {
  const form = useForm<Schema>({
    resolver: (
      schema
        ? yupResolver(schema)
        : undefined
    ),
    mode: "onChange",
    ...(formOptions ?? {}),
  });

  useMount(() => {
    const {
      getValues,
      trigger,
      errors,
      watch,
    } = form;

    useFormStore.setState({
      getValues: (
        overrideGetValues
          ? overrideGetValues(form)
          : getValues
      ),
      trigger,
      errors,
      watch,
    });
  });

  useEffect(() => {
    useFormStore.setState({
      isValid: form.formState.isValid,
      isDirty: form.formState.isDirty,
    });
  }, [
    form.formState.isValid,
    form.formState.isDirty,
  ]);

  return form;
}

export default useFormStep;
