import { SelectOption } from "components/FormComponents/Select/types";

/**
 * Parses one select value, returning the value to be used.
 *
 * @example getSelectValue({ value: "10", label: "y" }) => "10"
 * @example getSelectValue("x") => "x"
 * @example getSelectValue(null) => undefined
 *
 * @param selectedOption The selected option.
 */
const getSelectValue = (
  selectedOption?: unknown,
): string | number => {
  const option = selectedOption as SelectOption;

  return option?.value ?? selectedOption ?? "";
};

export default getSelectValue;
