import { useCallback } from "react";

import { useModal } from "contexts/modal";
import PhotoModal from "components/PhotoModal";

import { ShowPhotoModal } from "./types";
import photoModalDefaultStyle from "./styles";

/**
 * Exposes the useModal context with the PhotoModal component
 * as default modal component.
 * PhotoModal prevents photos height from overflow the screen
 * and properly handles photos aspect ratio.
 */
const usePhotoModal = (): ShowPhotoModal => {
  const [showModal] = useModal();

  const showPhotoModal = useCallback((pictureUrl: string) => () => {
    const modalProps = {
      ...photoModalDefaultStyle,
      component: PhotoModal,
      componentProps: { pictureUrl },
    };

    showModal(modalProps);
  }, [
    showModal,
  ]);

  return showPhotoModal;
};

export default usePhotoModal;
