import { Period, StatusUpdateEnum } from "generated/graphql";
import i18n from "translations/i18n";

import { DashboardBadgeColorEnum } from "../../types";

export const statusUpdatesCardPeriod = {
  currentPeriod: i18n.t("dashboard.dashboard.period_filter.current_month"),
  last7Days: i18n.t("dashboard.dashboard.period_filter.last_7_days"),
  lastMonth: i18n.t("dashboard.dashboard.period_filter.last_month"),
};

export interface StatusUpdatesCountCardProps {
  badgeColor: DashboardBadgeColorEnum;
  statuses: StatusUpdateEnum[];
  showPeriodSelector?: boolean;
  comparisonPeriod?: Period;
  isManager: boolean;
  period?: Period;
  jobId: number;
  title: string;
  subtitle?: string;
}

export interface StatusUpdatesCountCardHeaderProps {
  showPeriodSelector: StatusUpdatesCountCardProps["showPeriodSelector"];
  badgeColor: StatusUpdatesCountCardProps["badgeColor"];
  subtitle?: StatusUpdatesCountCardProps["subtitle"];
  title: StatusUpdatesCountCardProps["title"];
  selectedPeriod: string;
  setSelectedPeriod: (value: string) => () => void;
}

export interface GrowthIndicatorProps {
  growthRate: number;
}

export interface UseStatusUpdatesCountResult {
  growthRate: number | undefined;
  count: number | undefined;
  loading: boolean;
}

export interface UseStatusUpdatesCountOptions {
  comparisonPeriod?: StatusUpdatesCountCardProps["comparisonPeriod"];
  isManager: StatusUpdatesCountCardProps["isManager"];
  statuses: StatusUpdatesCountCardProps["statuses"];
  period?: StatusUpdatesCountCardProps["period"];
  jobId: StatusUpdatesCountCardProps["jobId"];
  selectedPeriod: string;
}
