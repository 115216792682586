import converter from "number-to-words";
import pluralize from "pluralize";
import * as R from "remeda";

import i18n from "translations/i18n";

/**
 * Capitalizes the first value of a word.
 * @param value The value.
 */
const capitalizeFirstLetter = (value: string): string => (
  `${value.charAt(0).toUpperCase()}${value.slice(1)}`
);

/**
 * Parses an extra section name according to its index to be more verbose.
 *
 * The index is incremented by one since it starts at zero and we start displaying at one.
 *
 * Also handles specific cases for specific extra sections, such as "Past Employers".
 *
 * @example parseExtraSectionGroupTitle("Past Employers", 0) => "Last or Current Employer"
 * @example parseExtraSectionGroupTitle("Past Employers", 1) => "Second Previous Employer"
 *
 * @example parseExtraSectionGroupTitle("Name", 2) => "Third Name"
 * @example parseExtraSectionGroupTitle("Name", 3) => "Fourth Name"
 *
 * @param sectionName The extra section name.
 * @param index The question index.
 */
const parseExtraSectionGroupTitle = (
  sectionName: string,
  index: number,
): string => {
  const position = index + 1;

  const ordinal = R.pipe(
    position,
    converter.toWordsOrdinal,
    capitalizeFirstLetter,
  );

  const singularSectionName = pluralize(sectionName, 1);

  if (sectionName === "Past Employers") {
    return (
      index === 0
        ? i18n.t("extra_section_question_texts.last_or_current_employer")
        : i18n.t("extra_section_question_texts.previous_employer", {
          position,
        })
    );
  }

  return i18n.t("extra_section_question_texts.ordinal_text", {
    text: singularSectionName,
    ordinal,
  });
};

export default parseExtraSectionGroupTitle;
