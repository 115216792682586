import React from "react";
import { FormControl, FormLabel } from "@chakra-ui/react";

import { FieldTitleProps } from "./types";

/**
 * FormLabel should be wrapped by a FormControl in order to be properly styled
 * I opened an issue on Chakra-UI repository
 * You can check it here: https://github.com/chakra-ui/chakra-ui/issues/3929
 */
const FieldTitle: React.FC<FieldTitleProps> = ({
  children,
  htmlFor,
  ...rest
}) => (
  <FormControl
    w="fit-content"
    m={0}
    p={0}
  >
    <FormLabel
      fontFamily="heading"
      htmlFor={htmlFor}
      fontSize="sm"
      {...rest}
    >
      {children}
    </FormLabel>
  </FormControl>
);

export default FieldTitle;
