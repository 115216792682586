import i18n from "translations/i18n";

import { DashboardBadgeColorEnum } from "../../types";
import { ParseChatDataOptions, ChartDataset, ChartData } from "./types";

/**
 * Parses the chart data according to the query result.
 * @param param0 Options.
 */
const parseChartData = ({
  applicantsCount,
  interviewsCount,
  hiredCount,
}: ParseChatDataOptions): ChartData => {
  const labels = [
    i18n.t("dashboard.dashboard.applications_overview_chart.applicants"),
    i18n.t("dashboard.dashboard.applications_overview_chart.interviews"),
    i18n.t("dashboard.dashboard.applications_overview_chart.hired"),
  ];

  const datasets: ChartDataset[] = [
    {
      data: [applicantsCount, interviewsCount, hiredCount],
      backgroundColor: [
        DashboardBadgeColorEnum.Stage1,
        DashboardBadgeColorEnum.Stage2,
        DashboardBadgeColorEnum.Stage4,
      ],
      borderColor: [
        DashboardBadgeColorEnum.Stage1,
        DashboardBadgeColorEnum.Stage2,
        DashboardBadgeColorEnum.Stage4,
      ],
      descriptions: [
        {
          descriptionKeywords: [],
          label: `${applicantsCount}`,
          descriptionText: [],
        },
        {
          descriptionKeywords: [],
          label: `${interviewsCount}`,
          descriptionText: [],
        },
        {
          descriptionKeywords: [],
          label: `${hiredCount}`,
          descriptionText: [],
        },
      ],
    },
  ];

  return {
    labels,
    datasets,
  };
};

export default parseChartData;
