import React, { useMemo } from "react";

import QuestionCard from "components/Forms/CompanyOnboardingForm/Cards/QuestionCard";

import { getExtraQuestionFields, getExtraQuestionTitle } from "./fields";
import { ExtraQuestionCardProps } from "./types";

const ExtraQuestionCard: React.FC<ExtraQuestionCardProps> = ({
  updateExtraQuestionDisplayType,
  extraQuestion,
  isDisabled,
}) => {
  const title = getExtraQuestionTitle(extraQuestion);

  const fields = useMemo(() => (
    getExtraQuestionFields({
      updateExtraQuestionDisplayType,
      extraQuestion,
      isDisabled,
    })
  ), [
    updateExtraQuestionDisplayType,
    extraQuestion,
    isDisabled,
  ]);

  return (
    <QuestionCard
      isDisabled={isDisabled}
      fields={fields}
      title={title}
    />
  );
};

export default ExtraQuestionCard;
