import React, { useMemo, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import TrackingList from "components/Tracking/TrackingListContainer/TrackingList";
import TrackingListContainer from "components/Tracking/TrackingListContainer";
import ApplicantListCard from "components/Tracking/Cards/Applicant/ApplicantListCard";
import TrackingListButtons from "components/Tracking/TrackingListButtons";
import { Applicant, useGetApplicantsQuery } from "generated/graphql";
import PageHeader from "components/PageHeaders/PageHeader";
import { APPLICANT_PROFILE_PAGE_LOCATION } from "routes/locations";
import useToast from "hooks/useToast";
import usePaginationParam from "hooks/usePaginationParams";
import { PAGINATION_LIMIT_ITEMS_PER_PAGE } from "constants/pagination";
import useApplicantFiltersParams from "hooks/useApplicantFiltersParams";
import ApplicantFiltersQueriesContainer from "contexts/trackingFiltersQueries/ApplicantFiltersQueriesContainer";
import useFilter, { filters as queryFilters } from "hooks/useFilter";
import useIsProfessional from "hooks/useIsProfessional";

import makeApplicantTrackingFilters from "./makeApplicantTrackingFilters";

const ApplicantTracking: React.FC = () => {
  const [search] = useFilter(queryFilters.search);
  const history = useHistory();

  const [{
    orderBy,
    setPage,
    offset,
    page,
  }, activePaginationParamsCount] = usePaginationParam();

  const [t] = useTranslation();
  const [showToast] = useToast();
  const [filters, activeFiltersParamsCount] = useApplicantFiltersParams();
  const isProfessional = useIsProfessional();

  const listFilters = useMemo(() => (
    makeApplicantTrackingFilters(isProfessional)
  ), [
    isProfessional,
  ]);

  const {
    refetch,
    loading,
    called,
    data,
  } = useGetApplicantsQuery({
    skip: !filters.jobId,
    variables: {
      search: search as string,
      filters,
      orderBy,
      offset,
    },
  });

  const handleClick = useCallback((id) => () => {
    history.push(APPLICANT_PROFILE_PAGE_LOCATION.toUrl({
      id,
    }));
  }, [history]);

  useEffect(() => {
    const shouldResetPagination = (
      data?.countApplicants
      && page
      && data?.countApplicants < PAGINATION_LIMIT_ITEMS_PER_PAGE
      && page > 1
    );

    if (shouldResetPagination) {
      setPage(undefined);
    }
  }, [
    page,
    setPage,
    data,
  ]);

  const refetchApplicantsList = useCallback(() => {
    if (!refetch) {
      return;
    }

    refetch({
      offset,
      orderBy,
      filters,
    })
      .catch((error) => {
        showToast({
          status: "error",
          title: error?.message,
        });
      });
  }, [
    offset,
    filters,
    orderBy,
    refetch,
    showToast,
  ]);

  useEffect(() => {
    if (called) {
      return;
    }

    refetchApplicantsList();
  }, [refetchApplicantsList, called]);

  const renderItem = useCallback((applicant: Applicant) => (
    <ApplicantListCard
      onClick={handleClick(applicant.id)}
      applicant={applicant}
      key={applicant?.id}
      cursor="pointer"
    />
  ), [
    handleClick,
  ]);

  const applicants = (data?.listApplicants ?? []) as Applicant[];

  const activeFiltersCount = activePaginationParamsCount + activeFiltersParamsCount;

  return (
    <>
      <PageHeader title={t("applicant_tracking.title")}>
        <TrackingListButtons
          refetchList={refetchApplicantsList}
          totalCount={data?.countApplicants}
          loading={!called || loading}
          csvExportType="applicants"
        />
      </PageHeader>

      <ApplicantFiltersQueriesContainer>
        <TrackingListContainer
          filters={listFilters}
          activeFiltersCount={activeFiltersCount}
        >
          <TrackingList
            countMessage={t("applicant_tracking.applicants_found", {
              count: data?.countApplicants ?? 0,
            })}
            emptyDataMessage={t("empty_data_messages.no_applicants_were_found")}
            totalCount={data?.countApplicants}
            loading={!called || loading}
            renderItem={renderItem}
            items={applicants}
          />
        </TrackingListContainer>
      </ApplicantFiltersQueriesContainer>
    </>
  );
};

export default ApplicantTracking;
