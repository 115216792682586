import React from "react";

import useWizardQueryStringPersistor from "components/Wizard/hooks/useWizardQueryStringPersistor";
import Wizard from "components/Wizard";

import steps from "./steps";

const CompanyOnboarding: React.FC = () => {
  const initialStepIndex = useWizardQueryStringPersistor();

  return (
    <Wizard
      initialStepIndex={initialStepIndex}
      steps={steps}
    />
  );
};

export default CompanyOnboarding;
