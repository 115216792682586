import React from "react";
import { useTranslation } from "react-i18next";

import Checkbox from "components/FormComponents/Checkbox";
import { ApplicationPreviewComponentProps } from "views/Forms/FormPreview/types";
import getFieldLabel from "utils/getFieldLabel";

import PreviewContent from "../PreviewComponents/PreviewContent";

const Requirements: React.FC<ApplicationPreviewComponentProps> = ({
  formResponse,
  isRequired,
  form,
}) => {
  const [t] = useTranslation();

  const fulfilledRequirements = formResponse.fulfilledRequirements ?? [];
  const requirements = form.requirements ?? [];

  return (
    <PreviewContent
      title={getFieldLabel(t("forms.steps.requirements.please_attest_to_the_following_job_requirements"), isRequired)}
      renderChildrenAsText={false}
    >
      {
        requirements.map((requirement) => (
          <Checkbox
            isChecked={fulfilledRequirements.includes(requirement)}
            name={requirement}
            key={requirement}
            isReadOnly
          >
            {requirement}
          </Checkbox>
        ))
      }
    </PreviewContent>
  );
};

export default Requirements;
