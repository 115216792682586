
/**
 * Returns a form response ID as stored in local storage according to a form type.
 * @param formType The form type.
 */
const getFormResponseId = (
  shortCode?: string,
): string | undefined => {
  if (!shortCode) {
    return undefined;
  }

  const storageValue = localStorage?.getItem(shortCode);

  return (
    storageValue
      ? JSON.parse(storageValue)
      : undefined
  );
};

export default getFormResponseId;
