import React, { useEffect, useCallback } from "react";
import { Stack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers";
import { useForm } from "react-hook-form";
import queryString from "query-string";
import { useHistory } from "react-router-dom";

import AuthBox from "components/Box/AuthBox";
import Button from "components/Button";
import PasswordInput from "components/FormComponents/Input/PasswordInput";
import resetPasswordSchema, { ResetPasswordSchema } from "settings/yup/schemas/resetPasswordSchema";
import { useResetPasswordMutation } from "generated/graphql";
import { ROOT_PAGE_PATH } from "routes";
import useToast from "hooks/useToast";
import useNavigate from "hooks/useNavigate";
import { handleOnKeyPress } from "utils/handleOnKeyPress";
import useSession from "hooks/useSession";

const ResetPassword: React.FC = () => {
  const [t] = useTranslation();
  const [showToast] = useToast();
  const [createSession] = useSession();
  const navigate = useNavigate();
  const history = useHistory();

  const [resetPassword, { loading }] = useResetPasswordMutation();

  const token = queryString.parse(history.location.search)?.token as string;

  useEffect(() => {
    if (!token) {
      navigate(ROOT_PAGE_PATH)();
    }
  }, [
    navigate,
    token,
  ]);

  const {
    errors,
    register,
    formState,
    handleSubmit,
  } = useForm<ResetPasswordSchema>({
    resolver: yupResolver(resetPasswordSchema),
    mode: "onChange",
  });

  const onSubmit = useCallback((values) => {
    resetPassword({
      variables: {
        password: values.password,
        token,
      },
    })
      .then((response) => {
        const sessionToken = response?.data?.resetPassword;

        if (sessionToken) {
          createSession(sessionToken, ROOT_PAGE_PATH);

          showToast({
            title: t("reset_password.password_updated"),
            status: "success",
          });
        }
      })
      .catch((error) => {
        showToast({
          title: error?.message,
          status: "error",
        });
      });
  }, [
    t,
    token,
    showToast,
    createSession,
    resetPassword,
  ]);

  return (
    <AuthBox
      title={t("reset_password.title")}
      subtitle={t("reset_password.subtitle")}
    >
      <Stack spacing={6}>
        <PasswordInput
          name="password"
          title={`${t("reset_password.new_password")}*`}
          ref={register}
          errors={errors}
          onKeyPress={handleOnKeyPress(handleSubmit(onSubmit))}
        />

        <Button
          fontSize="xs"
          textTransform="uppercase"
          isDisabled={!formState.isValid}
          isLoading={loading}
          onClick={handleSubmit(onSubmit)}
        >
          {t("buttons.save_changes")}
        </Button>
      </Stack>
    </AuthBox>
  );
};

export default ResetPassword;
