import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

import useExportTrackingListToCsv from "hooks/useExportTrackingListToCsv";
import { TrackingListButtonsProps } from "components/Tracking/types";
import UpdateListIcon from "settings/theme/icons/UpdateListIcon";
import DownloadIcon from "settings/theme/icons/DownloadIcon";
import Button from "components/Button";

const TrackingListButtons: React.FC<TrackingListButtonsProps> = ({
  csvExportType,
  refetchList,
  totalCount,
  loading,
}) => {
  const [t] = useTranslation();

  const [exportTrackingListToCsv, isCsvExportLoading] = useExportTrackingListToCsv();

  const handleExportToCsv = useCallback(() => {
    exportTrackingListToCsv({
      csvExportType,
    });
  }, [
    exportTrackingListToCsv,
    csvExportType,
  ]);

  return (
    <>
      <Button
        rightIcon={(<DownloadIcon />)}
        isLoading={isCsvExportLoading}
        onClick={handleExportToCsv}
        isDisabled={loading}
        variant="outline"
        borderRadius={4}
        fontSize="xs"
        color="black"
        size="sm"
      >
        {`${t("buttons.export_to_csv")}${totalCount ? ` (${totalCount})` : ""}`}
      </Button>

      <Button
        rightIcon={(<UpdateListIcon />)}
        onClick={refetchList}
        variant="outline"
        borderRadius={4}
        fontSize="xs"
        color="black"
        size="sm"
      >
        {t("buttons.update_list")}
      </Button>
    </>
  );
};

export default TrackingListButtons;
