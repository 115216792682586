import * as React from "react";

import { EmptyDataMessage } from "components/EmptyDataMessage";
import Pagination from "components/Pagination";

import { TableProps } from "./types";
import {
  TableContainer,
  TableHeader,
  TableTitle,
  TableRoot,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "./TableComponents";

/**
 * Represents tabular data - that is, information presented in a two-dimensional table
 * comprised of rows and columns of cells containing data.
 * It renders a native table HTML components according to `TableComponents` definitions.
 */
function Table<T = Record<string, unknown>>({
  cellFontSize = "xs",
  containerProps,
  emptyDataProps,
  titleProps,
  pagination,
  columns,
  title,
  data,
}: TableProps<T>): React.ReactElement {
  return (
    <>
      <TableContainer {...(containerProps || {})}>
        {
          title && (
            <TableTitle
              {...(titleProps || {})}
              title={title}
            />
          )
        }

        <TableRoot>
          <TableHead>
            {
              columns.map((column, index) => (
                <TableHeader
                  key={String(index)}
                  column={column}
                >
                  {column.title}
                </TableHeader>
              ))
            }
          </TableHead>

          <TableBody>
            {
              data.length > 0 && data.map((row, rowIndex) => (
                <TableRow key={String(rowIndex)}>
                  {
                    columns.map((column, columnIndex) => (
                      <TableCell
                        key={String(columnIndex)}
                        fontSize={cellFontSize}
                        currentIndex={rowIndex}
                        column={column}
                        row={row}
                      />
                    ))
                  }
                </TableRow>
              ))
            }
          </TableBody>
        </TableRoot>

        {
          data.length <= 0 && (
            <EmptyDataMessage
              {...(emptyDataProps || {})}
            />
          )
        }
      </TableContainer>

      {
        pagination && (
          <Pagination {...pagination} />
        )
      }
    </>
  );
}

export default Table;
