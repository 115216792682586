import React from "react";
import { Text, VStack } from "@chakra-ui/react";

import Tooltip from "components/Tooltip";

import { QualityTooltipProps } from "./type";

const QualityTooltip: React.FC<QualityTooltipProps> = ({
  description,
  footerNote,
  ...props
}) => (
  <Tooltip
    label={(
      <VStack
        spacing={4}
        color="black"
        fontSize="12px"
      >
        <Text>
          {description}
        </Text>

        <Text fontWeight="bold">
          {footerNote}
        </Text>
      </VStack>
    )}
    iconProps={{
      color: "black",
      boxSize: 5,
      ml: 2,
    }}
    placement="bottom-end"
    {...props}
  />
);

export default QualityTooltip;
