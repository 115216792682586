import React from "react";
import { Grid } from "@chakra-ui/react";

import buildAvailablePlans from "./buildAvailablePlans";
import { PlanCardsProps } from "../types";
import PlanCard from "./PlanCard";

const PlanCards: React.FC<PlanCardsProps> = ({
  subscription,
  companyId,
  plans,
  role,
}) => {
  const availablePlans = buildAvailablePlans(plans);

  return (
    <Grid
      autoFlow="column"
      overflow="scroll"
      gap={5}
      p={1}
    >
      {
        availablePlans.map((plan, index) => (
          <PlanCard
            subscription={subscription}
            companyId={companyId}
            key={String(index)}
            plan={plan}
            role={role}
          />
        ))
      }
    </Grid>
  );
};

export default PlanCards;
