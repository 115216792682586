import React from "react";
import ConditionalWrap from "conditional-wrap";
import {
  Stack,
  Text,
} from "@chakra-ui/react";

import { PreviewContentProps } from "views/Forms/FormPreview/types";

/**
 * Renders a question content.
 * Allows for customized renders by setting `renderChildrenAsText` as false.
 * @param props Component props.
 */
const PreviewContent: React.FC<PreviewContentProps> = ({
  renderChildrenAsText = true,
  children,
  title,
  ...rest
}) => (
  <Stack {...rest}>
    {
      title && (
        <Text
          fontFamily="bold"
          fontSize="xs"
          color="black"
        >
          {title}
        </Text>
      )
    }

    <ConditionalWrap
      condition={renderChildrenAsText}
      wrap={(content) => (
        <Text
          color="gray.400"
          fontSize="xs"
        >
          {content}
        </Text>
      )}
    >
      <>
        {children}
      </>
    </ConditionalWrap>
  </Stack>
);

export default PreviewContent;
