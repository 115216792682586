import { GOOGLE_MAPS_URL } from "constants/googleMapsUrl";

function buildLocationUrl(lat?: string, lng?: string): string {
  if (!lat || !lng) {
    return "";
  }

  return `${GOOGLE_MAPS_URL}${lat},${lng}`;
}

export default buildLocationUrl;
