/* eslint-disable @typescript-eslint/no-explicit-any */

import { useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { ApolloError } from "@apollo/client";
import { useLocalStorage } from "react-use";
import * as R from "remeda";

import { Form, useGetFormByShortcodeQuery } from "generated/graphql";
import captureSentryException from "utils/captureSentryException";
import { FORM_PREVIEW_PAGE_LOCATION } from "routes/locations";
import useWizardStore from "components/Wizard/store";
import { onQueryError } from "utils/queryHandlers";
import useFormStore from "views/Forms/store";
import { ROOT_PAGE_PATH } from "routes";
import useToast from "hooks/useToast";

import {
  UseFormHandlerOptions,
  UseFormHandlerResult,
  CreateResponseData,
} from "./types";

/**
 * Handles the mutation logic for a given form step according to the schema.
 * Exposes the `onSubmit` & `onContinue` functions.
 */
const useFormHandler = ({
  useCreateResponseMutation,
  useUpdateResponseMutation,
  shortCode,
}: UseFormHandlerOptions): UseFormHandlerResult => {
  const [responseId, setResponseId] = useLocalStorage<string>(shortCode);

  const [showToast] = useToast();
  const history = useHistory();

  const stepIndex = useWizardStore((store) => store.stepIndex);

  const {
    getValues,
    isValid,
  } = useFormStore((store) => ({
    getValues: store.getValues,
    isValid: store.isValid,
  }));

  useGetFormByShortcodeQuery({
    notifyOnNetworkStatusChange: true,
    skip: !shortCode,
    variables: {
      shortcode: shortCode,
    },
    onCompleted: (data) => {
      const form = data?.getFormByShortcode as Form;

      if (!form) {
        history.push(ROOT_PAGE_PATH);
        return;
      }

      useFormStore.setState({
        isFormLoading: false,
        form,
      });
    },
    onError: (error) => {
      captureSentryException(new Error("Failed to get form by short code"), error);
    },
  });

  const [createResponse, {
    loading: createResponseLoading,
  }] = useCreateResponseMutation();

  const [updateResponse, {
    loading: updateResponseLoading,
  }] = useUpdateResponseMutation();

  const getMutationPayload = useCallback(() => {
    if (!getValues) {
      return {};
    }

    const {
      address,
      ...payload
    } = getValues();

    return {
      ...(address ?? {}),
      ...payload,
    };
  }, [
    getValues,
  ]);

  const handleCreateResponse = useCallback(() => new Promise<void>((resolve) => {
    const payload = getMutationPayload();

    const promise = createResponse({
      variables: {
        params: {
          formLinkShortcode: shortCode,
          ...payload,
        },
      },
    }) as Promise<unknown>;

    promise
      .then((response) => {
        const id = R.pipe(
          (response as any),
          (res) => res?.data ?? {},
          (data) => Object.values(data),
          (values) => values[0],
          (value) => (value as CreateResponseData)?.id,
        );

        setResponseId(id);
      })
      .catch((error: ApolloError) => {
        onQueryError(error, showToast);
      })
      .finally(resolve);
  }), [
    getMutationPayload,
    createResponse,
    setResponseId,
    showToast,
    shortCode,
  ]);

  const handleUpdateResponse = useCallback(() => new Promise<void>((resolve) => {
    if (!responseId) {
      return;
    }

    const payload = getMutationPayload();

    const promise = updateResponse({
      variables: {
        id: responseId,
        params: payload,
      },
    }) as Promise<unknown>;

    promise
      .catch((error: ApolloError) => {
        onQueryError(error, showToast);
      })
      .finally(resolve);
  }), [
    getMutationPayload,
    updateResponse,
    responseId,
    showToast,
  ]);

  const loading = !!(
    createResponseLoading
    || updateResponseLoading
  );

  const onContinue = useCallback(async () => {
    if (!responseId && stepIndex === 0) {
      await handleCreateResponse();
      return;
    }

    await handleUpdateResponse();
  }, [
    handleUpdateResponse,
    handleCreateResponse,
    responseId,
    stepIndex,
  ]);

  const onSubmit = useCallback(async () => {
    await handleUpdateResponse();

    history.push(FORM_PREVIEW_PAGE_LOCATION.toUrl({
      shortCode,
    }));
  }, [
    handleUpdateResponse,
    shortCode,
    history,
  ]);

  /**
   * If the shortcode is invalid, we need to redirect the user back to the root page.
   */
  useEffect(() => {
    if (!shortCode) {
      history.push(ROOT_PAGE_PATH);
    }
  }, [
    shortCode,
    history,
  ]);

  return {
    onContinue,
    onSubmit,
    isValid,
    loading,
  };
};

export default useFormHandler;
