import React, { useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import { useTranslation } from "react-i18next";
import { Stack } from "@chakra-ui/react";

import useApplicantUpdateHandler from "components/Tracking/ApplicantStatusCard/hooks/useApplicantUpdateHandler";
import updateApplicantSchema, { UpdateApplicantSchema } from "settings/yup/schemas/updateApplicantSchema";
import { ApplicantStatusCardProps } from "components/Tracking/ApplicantStatusCard/types";
import DatePickerInput from "components/FormComponents/DatePickerInput";
import StarRatings from "components/FormComponents/StarRatings";
import { statusUpdateSelectOptions } from "constants/statuses";
import Textarea from "components/FormComponents/Textarea";
import Select from "components/FormComponents/Select";
import { StatusUpdateEnum } from "generated/graphql";

import getRecruiterOptions from "./getRecruiterOptions";

const StatusCardForm: React.FC<ApplicantStatusCardProps> = ({
  tourClassNames,
  isPrintLayout,
  applicant,
}) => {
  const [t] = useTranslation();

  const recruiterOptions = useMemo(() => (
    getRecruiterOptions(applicant?.job?.company?.activeMembers)
  ), [
    applicant,
  ]);

  const {
    formState,
    setValue,
    control,
    errors,
    reset,
    watch,
  } = useForm<UpdateApplicantSchema>({
    resolver: yupResolver(updateApplicantSchema),
    mode: "onChange",
    defaultValues: {
      recruiterNotes: applicant?.recruiterNotes ?? "",
      recruiterRating: applicant?.recruiterRating as number,
      responsibleRecruiterId: applicant?.responsibleRecruiter?.id as number,
      status: applicant?.status?.status as string,
      interviewAt: applicant?.interviewAt,
    },
  });

  const canChangeInterviewAt = (
    applicant?.status?.status === StatusUpdateEnum.InterviewScheduled
  );

  const canChangeResponsibleRecruiter = (
    applicant?.status?.status === StatusUpdateEnum.Claimed
  );

  useApplicantUpdateHandler({
    applicant,
    formState,
    setValue,
    reset,
    watch,
  });

  return (
    <Stack spacing={4}>
      <Controller
        name="status"
        control={control}
        render={({
          onChange,
          value,
          name,
        }) => (
          <Select
            name={name}
            value={value}
            errors={errors}
            onChange={onChange}
            options={statusUpdateSelectOptions}
            title={`${t("filters.status")}:`}
          />
        )}
      />

      <Controller
        as={Select}
        name="responsibleRecruiterId"
        control={control}
        errors={errors}
        options={recruiterOptions}
        isDisabled={!canChangeResponsibleRecruiter}
        title={`${t("applicant_profile.recruiter")}:`}
      />

      <Controller
        name="interviewAt"
        control={control}
        render={({
          onChange,
          value,
          name,
        }) => (
          <DatePickerInput
            name={name}
            value={value}
            errors={errors}
            onChange={onChange}
            isDisabled={!canChangeInterviewAt}
            title={`${t("applicant_profile.interview_date")}:`}
            datePickerProps={{
              showTimeSelect: true,
            }}
          />
        )}
      />

      <Controller
        name="recruiterRating"
        control={control}
        render={({
          onChange,
          value,
          name,
        }) => (
          <StarRatings
            className={tourClassNames?.review}
            name={name}
            value={value}
            errors={errors}
            onChange={onChange}
            title={`${t("applicant_profile.recruiter_rating")}:`}
          />
        )}
      />

      <Controller
        name="recruiterNotes"
        control={control}
        render={({
          onChange,
          value,
          name,
        }) => (
          <Textarea
            name={name}
            value={value}
            errors={errors}
            onChange={onChange}
            title={`${t("applicant_profile.notes")}:`}
            placeholder={t("actions.enter_any_important_note")}
            autoHeight={isPrintLayout}
            rows={4}
          />
        )}
      />
    </Stack>
  );
};

export default StatusCardForm;
