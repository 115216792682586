/**
 * Builds a name string.
 * @param firstName The first name.
 * @param lastName The last name.
 */
const buildName = (
  firstName: string | undefined,
  lastName: string | undefined,
): string => {
  if (!firstName && !lastName) {
    return "";
  }

  return `${firstName} ${lastName}`;
};

export default buildName;
