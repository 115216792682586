import { gql } from "@apollo/client";

export const updateFixedSectionsConfigurationRefetchQueries = [
  "GetForm",
];

export default gql`
  mutation UpdateFixedSectionsConfiguration (
    $id: Int!
    $params: UpdateFixedSectionsConfigurationParams!,
  ){
    updateFixedSectionsConfiguration (
      id: $id,
      params: $params,
    ) {
      id
    }
  }
`;
