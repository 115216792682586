import React from "react";

import {
  useGetImpersonatedCompanyDataQuery,
  useGetCurrentCompanyDataQuery,
} from "generated/graphql";
import useLoadingDelay from "hooks/useLoadingDelay";
import useGetCompany from "hooks/useGetCompany";
import Loading from "components/Loading";

import CompanyAccountForm from "./CompanyAccountForm";

const CompanyAccount: React.FC = () => {
  const [company, {
    loading,
  }] = useGetCompany(
    useGetCurrentCompanyDataQuery,
    useGetImpersonatedCompanyDataQuery,
    {
      fetchPolicy: "network-only",
    },
  );

  const isLoading = useLoadingDelay(loading);

  if (isLoading || !company) {
    return (
      <Loading />
    );
  }

  return (
    <CompanyAccountForm company={company} />
  );
};

export default CompanyAccount;
