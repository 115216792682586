import * as React from "react";
import { IconProps } from "@chakra-ui/icons";
import { createIcon } from "@chakra-ui/react";

const StarIcon: React.FC<IconProps> = createIcon({
  viewBox: "0 0 36 36",
  path: (
    <g
      id="Group_13773"
      data-name="Group 13773"
      transform="translate(0 -0.391)"
    >
      <rect
        fill="none"
        id="Rectangle_1401"
        data-name="Rectangle 1401"
        width="36"
        height="36"
        transform="translate(0 0.391)"
      />
      <path
        fill="currentColor"
        id="star"
        d="M35.906,13.529a1.91,1.91,0,0,0-1.647-1.316L23.868,11.27,19.76,1.653a1.913,1.913,0,0,0-3.519,0L12.132,11.27,1.74,12.214A1.916,1.916,0,0,0,.654,15.562L8.508,22.45l-2.316,10.2a1.91,1.91,0,0,0,2.846,2.067L18,29.362l8.96,5.357a1.913,1.913,0,0,0,2.847-2.067l-2.316-10.2,7.854-6.887A1.914,1.914,0,0,0,35.906,13.529Zm0,0"
        transform="translate(0 0.799)"
      />
    </g>
  ),
});

export default StarIcon;
