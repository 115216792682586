import { FilterOption } from "components/Tracking/types";
import i18n from "translations/i18n";

/**
 * The options of the applicants sort by filter
 */
export const applicantsSortByFilterOptions = (isProfessional?: boolean): FilterOption[] => ([
  {
    label: i18n.t("filters.date_submitted"),
    value: "applied_at",
  },
  {
    label: i18n.t("filters.last_action"),
    value: "status_happened_at",
  },
  {
    label: isProfessional ? i18n.t("filters.match") : i18n.t("filters.role_fit"),
    value: "role_fit_score",
  },
]);

/**
 * Default value for the applicants sort by filter
 */
export const applicantsSortByFilterDefaultValue = applicantsSortByFilterOptions()[0].value;

/**
 * The options of the clients sort by filter
 */
export const clientsSortByFilterOptions: FilterOption[] = [
  {
    label: i18n.t("filters.date_submitted"),
    value: "submitted_at",
  },
  {
    label: i18n.t("filters.care_match"),
    value: "role_fit_score",
  },
];

/**
 * Default value for the clients sort by filter
 */
export const clientsSortByFilterDefaultValue = clientsSortByFilterOptions[0].value;
