import React from "react";
import { Text, VStack } from "@chakra-ui/react";
import { Trans, useTranslation } from "react-i18next";

import { getRole } from "utils/getRole";

import { ReactTourStepProps } from "./types";

const ReactTourStep: React.FC<ReactTourStepProps> = ({
  mapStepList,
  step,
}) => {
  const [t] = useTranslation();

  const tourStep = mapStepList(t, step);

  const role = getRole(tourStep?.roleName);

  return (
    <VStack spacing={7}>
      <Text
        fontFamily="extrabold"
        color="primary.200"
        fontSize={16}
      >
        {t(tourStep.title, { role })}
      </Text>

      <Text>
        <Trans
          i18nKey={tourStep.content}
          values={{ role }}
          components={{
            Bold: (
              <Text
                fontWeight="bold"
                as="span"
              />
            ),
          }}
        />
      </Text>

      {
        tourStep.tip && (
          <Trans
            i18nKey={tourStep.tip}
            components={{
              Bold: (
                <Text
                  fontWeight="bold"
                />
              ),
            }}
          />
        )
      }
    </VStack>
  );
};

export default ReactTourStep;
