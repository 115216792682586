import create from "zustand";

import { DashboardTokensAlertStoreState } from "./types";

const useDashboardTokensAlertStore = create<DashboardTokensAlertStoreState>((set) => ({
  isVisible: false,
  setIsVisible: (value) => set({
    isVisible: value,
  }),
}));

export default useDashboardTokensAlertStore;
