import React, { useCallback, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Stack } from "@chakra-ui/react";

import {
  useGetClientQuery,
  SpiderChartScore,
  Applicant,
  Client,
} from "generated/graphql";
import ClientProfileCard from "components/Tracking/Cards/Client/ClientProfileCard";
import TrackingProfileButtons from "components/Tracking/TrackingProfileButtons";
import TrackingProfileCharts from "components/Tracking/TrackingProfileCharts";
import ExtraInformationCard from "components/Tracking/ExtraInformationCard";
import ClientStatusCard from "components/Tracking/ClientStatusCard";
import ClientMatchCard from "components/Tracking/ClientMatchCard";
import GoBackHeader from "components/PageHeaders/GoBackHeader";
import { CLIENT_MATCHING_PAGE_PATH } from "routes";
import Profile from "components/Tracking/Profile";
import { ProfileType } from "constants/profiles";
import Story from "components/Tracking/Story";
import buildName from "utils/buildName";
import QualityTooltip from "components/Tooltip/QualityTooltip";
import { getRole } from "utils/getRole";

import { ClientProfileParams } from "./types";

const ClientProfile: React.FC = () => {
  const [comparisonScores, setComparisonScores] = useState<SpiderChartScore[]>([]);
  const [t] = useTranslation();

  const { id } = useParams<ClientProfileParams>();

  const clientId = parseInt(id);

  const {
    loading,
    data,
  } = useGetClientQuery({
    variables: {
      id: clientId,
    },
  });

  const handleSetComparisonScores = useCallback((applicant?: Applicant | null) => {
    setTimeout(() => {
      setComparisonScores(applicant?.assessment?.spiderChartScores ?? []);
    }, 0);
  }, []);

  const client = data?.getClient as Client;

  if (!loading && !client) {
    return (
      <Redirect to={CLIENT_MATCHING_PAGE_PATH} />
    );
  }

  const role = getRole(client?.job?.role?.name);

  return (
    <>
      <GoBackHeader>
        <TrackingProfileButtons
          name={buildName(client?.firstName, client?.lastName)}
          profileType={ProfileType.Client}
          id={clientId}
        />
      </GoBackHeader>

      <Profile loading={loading}>
        <Stack spacing={3}>
          <ClientProfileCard client={client} />

          <TrackingProfileCharts
            comparisonDatasetLabel={t("applicant_profile.applicant_qualities")}
            mainDatasetLabel={t("client_profile.client_quality_requirements")}
            title={`${t("client_profile.quality_requirements")}:`}
            scores={client?.assessment?.spiderChartScores}
            comparisonScores={comparisonScores}
            comparisonColorScheme="primary"
            colorScheme="secondary"
            QualityTooltip={(
              <QualityTooltip
                description={t("tooltips.quality_requirements.description", { role })}
                footerNote={t("tooltips.quality_requirements.footer")}
              />
            )}
          />
        </Stack>

        <Stack spacing={3}>
          <ClientStatusCard client={client} />

          <ClientMatchCard
            setComparisonScores={handleSetComparisonScores}
            client={client}
          />
        </Stack>

        <Story
          title={`${t("client_profile.client_story")}`}
          openAnswers={client?.openAnswers}
        />

        <ExtraInformationCard
          title={t("client_profile.client_information")}
          profileType={ProfileType.Client}
          data={client}
        />
      </Profile>
    </>
  );
};

export default ClientProfile;
