import { MIN_PASSWORD_LENGTH } from "constants/forms";
import emailTest from "settings/yup/tests/emailTest";
import i18n from "translations/i18n";
import yup from "settings/yup";

const registerMemberSchema = yup.object().shape({
  email: yup
    .string()
    .test(emailTest)
    .required(),
  password: yup
    .string()
    .min(MIN_PASSWORD_LENGTH)
    .required(),
  confirmPassword: yup
    .string()
    .oneOf(
      [yup.ref("password")],
      i18n.t("schemas.password_confirmation_must_match"),
    ),
  terms: yup
    .boolean()
    .oneOf(
      [true],
      i18n.t("schemas.must_accept_terms_and_conditions"),
    ),
  subscribedToMailingList: yup
    .boolean(),
});

export interface RegisterMemberSchema {
  email: string;
  password: string;
  confirmPassword: string;
  terms: boolean;
  subscribedToMailingList: boolean;
}

export default registerMemberSchema;
