import i18n from "translations/i18n";

/**
 * Defines possible availability options.
 */
const availabilityOptions = [
  {
    label: i18n.t("availability.full_time"),
    value: "1",
  },
  {
    label: i18n.t("availability.part_time"),
    value: "0",
  },
];

export default availabilityOptions;
