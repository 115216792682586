import { Flex, Spinner } from "@chakra-ui/react";
import React from "react";

import { LoadingProps } from "./types";

const Loading: React.FC<LoadingProps> = ({
  containerWidth = "full",
  containerHeight = "sm",
  color = "primary.200",
  size = "xl",
  ...rest
}) => (
  <Flex
    justifyContent="center"
    alignItems="center"
    h={containerHeight}
    w={containerWidth}
  >
    <Spinner
      color={color}
      size={size}
      {...rest}
    />
  </Flex>
);

export default Loading;
