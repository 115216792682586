import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Stack } from "@chakra-ui/react";

import { TEXT_AREA_MEDIUM_LENGTH, TEXT_INPUT_LENGTH } from "constants/forms";
import Textarea from "components/FormComponents/Textarea";
import Input from "components/FormComponents/Input";
import Box from "components/Box";

const LandingPageContentCard: React.FC = () => {
  const [t] = useTranslation();

  const {
    control,
    errors,
  } = useFormContext();

  return (
    <Box
      w="full"
      h="full"
      title={t("company_onboarding.steps.configure_landing_page.content")}
      titleProps={{
        textTransform: "uppercase",
        fontFamily: "bold",
        fontSize: "xs",
      }}
    >
      <Stack
        spacing={6}
        h="full"
      >
        <Controller
          as={Input}
          name="title"
          control={control}
          errors={errors}
          title={`${t("landing_pages_templates.title")}*`}
          maxLength={TEXT_INPUT_LENGTH}
        />

        <Controller
          as={Textarea}
          name="subtitle"
          control={control}
          errors={errors}
          rows={4}
          title={`${t("landing_pages_templates.subtitle")}*`}
          validationMessage={t("yup.maximum_of_characters", {
            limit: TEXT_AREA_MEDIUM_LENGTH,
          })}
        />

        <Controller
          as={Textarea}
          name="jobDescription"
          control={control}
          errors={errors}
          rows={8}
          title={`${t("landing_pages_templates.job_description")}*`}
        />
      </Stack>
    </Box>
  );
};

export default LandingPageContentCard;
