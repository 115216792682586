import React from "react";
import { Redirect, useHistory, useParams } from "react-router-dom";

import { useGetFormRedirectByShortcodeQuery } from "generated/graphql";
import { getFormStartPath } from "constants/formTypes";
import Loading from "components/Loading";
import { ROOT_PAGE_PATH } from "routes";

import { FormPageParams } from "../types";

/**
 * Component that handles fetching form data based on a shortCode and redirects the
 * user redirecting to the correct path to start the form flow.
 */
const FormRedirect: React.FC = () => {
  const { shortCode } = useParams<FormPageParams>();

  const history = useHistory();

  const {
    loading,
  } = useGetFormRedirectByShortcodeQuery({
    skip: !shortCode,
    variables: {
      shortcode: shortCode,
    },
    onCompleted: (data) => {
      const hasLandingPage = !!data?.getFormByShortcode?.job?.landingPage;
      const formType = data?.getFormByShortcode?.formType;

      const path = getFormStartPath({
        hasLandingPage,
        shortCode,
        formType,
      });

      history.push(path ?? ROOT_PAGE_PATH);
    },
  });

  if (loading) {
    return (
      <Loading />
    );
  }

  return (
    <Redirect to={ROOT_PAGE_PATH} />
  );
};

export default FormRedirect;
