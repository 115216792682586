import React from "react";
import { useTranslation } from "react-i18next";
import numeral from "numeral";
import {
  ListItem,
  Badge,
  Stack,
  Text,
} from "@chakra-ui/react";

import { ExtraTokenPackPriceProps } from "./types";

const ExtraTokenPackPrice: React.FC<ExtraTokenPackPriceProps> = ({
  renderAsListItem = true,
  extraTokensPack,
}) => {
  const [t] = useTranslation();

  return (
    <Stack
      alignItems="center"
      isInline
    >
      <Text
        as={renderAsListItem ? ListItem : Text}
        fontSize="xs"
        pl={3}
      >
        {t("dashboard.subscription.add_tokens_modal.token_quantity", {
          price: extraTokensPack.price.formatted,
          count: extraTokensPack.quantity,
        })}

        {" = "}
      </Text>

      <Text
        textDecoration={extraTokensPack.activeDiscount ? "line-through" : ""}
        fontSize="xs"
      >
        {extraTokensPack.price.formatted}
      </Text>

      {
        extraTokensPack.activeDiscount && (
          <>
            <Text
              fontFamily="extrabold"
              fontSize="xs"
            >
              {extraTokensPack.activeDiscount.discountedPrice.formatted}
            </Text>

            <Badge
              borderRadius={4}
              bgColor="status.success"
              fontFamily="body"
              fontSize="xxs"
              color="white"
              p={1}
            >
              {`-${numeral(extraTokensPack.activeDiscount.discount?.rate).format("0%")}`}
            </Badge>
          </>
        )
      }
    </Stack>
  );
};

export default ExtraTokenPackPrice;
