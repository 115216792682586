import { useCallback, useMemo } from "react";
import { MutationTuple } from "@apollo/client";

import {
  useGenerateApplicantProfilePdfMutation,
  useGenerateClientProfilePdfMutation,
} from "generated/graphql";
import { onQueryError } from "utils/queryHandlers";
import { ProfileType } from "constants/profiles";
import useToast from "hooks/useToast";

import {
  UseGenerateProfilePdfResult,
  GenerateProfilePdfOptions,
} from "./types";

/**
 * Exposes the a function that queries for `generateProfilePdf` according to the profile type,
 * downloads the generated PDF file and opens it in the browser.
 *
 * In case the query fails, a toast will be shown with the error.
 */
const useGenerateProfilePdf = (): UseGenerateProfilePdfResult => {
  const [showToast] = useToast();

  const [generateClientProfilePdf, {
    loading: generateClientProfilePdfLoading,
  }] = useGenerateClientProfilePdfMutation();

  const [generateApplicantProfilePdf, {
    loading: generateApplicantProfilePdfLoading,
  }] = useGenerateApplicantProfilePdfMutation();

  const generateProfilePdf = useCallback(
    (options: GenerateProfilePdfOptions) => new Promise<string>((resolve, reject) => {
      const mutation = ({
        [ProfileType.Applicant]: generateApplicantProfilePdf,
        [ProfileType.Client]: generateClientProfilePdf,
      })[options.profileType] as MutationTuple<unknown, unknown>[0];

      mutation({
        variables: {
          id: options.id,
        },
      })
        .then((response) => {
          const data = (response.data ?? {}) as Record<string, unknown>;

          const profilePdfUrl = Object.values(data)[0] as string;

          resolve(profilePdfUrl);
        })
        .catch((error) => {
          onQueryError(error, showToast);

          reject(error);
        });
    }),
    [
      generateApplicantProfilePdf,
      generateClientProfilePdf,
      showToast,
    ],
  );

  const isLoading = (
    generateApplicantProfilePdfLoading
    || generateClientProfilePdfLoading
  );

  const payload = useMemo<UseGenerateProfilePdfResult>(() => [
    generateProfilePdf,
    isLoading,
  ], [
    generateProfilePdf,
    isLoading,
  ]);

  return payload;
};

export default useGenerateProfilePdf;
