import {
  useCallback,
  useEffect,
  useState,
  useMemo,
} from "react";
import { useTranslation } from "react-i18next";

import useAdminImpersonationStore from "components/AdminImpersonation/store";
import { onQueryError } from "utils/queryHandlers";
import useGetCompany from "hooks/useGetCompany";
import { useModal } from "contexts/modal";
import useToast from "hooks/useToast";
import {
  useGetImpersonatedCompanyJobsQuery,
  useGetCurrentCompanyJobsQuery,
  FormTypeEnum,
} from "generated/graphql";
import getJobsAndRoles from "utils/getJobsAndRoles";

import SendAssessmentModal from "./SendAssessmentModal";
import { UseSendAssessmentResult } from "./types";
import getModalTitle from "./getModalTitle";

/**
 * Exposes a function allows the user to send assessments.
 *
 * When called, the function will query for the available jobs and open the modal
 * to allow the user to send assessments.
 *
 * The query loading state is also exposed.
 *
 * In case the query fails, a toast will be shown with the error.
 */
const useSendAssessment = (
  formType: FormTypeEnum,
): UseSendAssessmentResult => {
  const [shouldFetch, setShouldFetch] = useState(false);

  const [showModal] = useModal();
  const [showToast] = useToast();
  const [t] = useTranslation();

  const impersonatedCompanyId = useAdminImpersonationStore((store) => store.companyId);

  useEffect(() => {
    setShouldFetch(false);
  }, [
    impersonatedCompanyId,
  ]);

  const modalTitle = getModalTitle(formType);

  const handleOnQueryComplete = useCallback((data) => {
    const company = data?.getCompany
    ?? data?.currentUser?.member?.company
    ?? [];

    const { jobs, roles } = getJobsAndRoles(company);

    if (roles.length <= 0) {
      showToast({
        title: t("errors.no_jobs_were_found"),
        status: "info",
      });

      return;
    }

    showModal({
      title: modalTitle,
      component: SendAssessmentModal,
      componentProps: {
        formType,
        roles,
        jobs,
      },
    });
  }, [
    modalTitle,
    showModal,
    showToast,
    formType,
    t,
  ]);

  const isClient = formType === FormTypeEnum.Client;

  const [, {
    refetch,
    loading,
  }] = useGetCompany(
    useGetCurrentCompanyJobsQuery,
    useGetImpersonatedCompanyJobsQuery,
    {
      variables: {
        hasClient: isClient || undefined,
        hasApplicant: isClient ? undefined : true,
      },
      skip: !shouldFetch,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
      onCompleted: handleOnQueryComplete,
      onError: (error) => {
        onQueryError(error, showToast);
      },
    },
  );

  const sendAssessment = useCallback(() => {
    if (!shouldFetch) {
      setShouldFetch(true);
      return;
    }

    refetch?.();
  }, [
    shouldFetch,
    refetch,
  ]);

  const payload = useMemo<UseSendAssessmentResult>(() => [
    sendAssessment,
    loading || !shouldFetch,
  ], [
    sendAssessment,
    shouldFetch,
    loading,
  ]);

  return payload;
};

export default useSendAssessment;
