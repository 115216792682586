import { useMemo, useCallback } from "react";

import { createOrUpdateExtraSectionRefetchQueries } from "graphql/mutations/createOrUpdateExtraSectionMutation";
import { updateFixedSectionsConfigurationRefetchQueries } from "graphql/mutations/updateFixedSectionsConfigurationMutation";
import { useCreateOrUpdateExtraSectionMutation, useUpdateFixedSectionsConfigurationMutation } from "generated/graphql";
import { SectionType } from "constants/formSteps";

import { UseUpdateVisibilityOptions, UseUpdateVisibilityResult } from "../types";

const useUpdateVisibility = ({
  formId,
  sectionType,
  extraSectionId,
  fixedSectionConfigurationId,
  sectionVisibilityField,
}: UseUpdateVisibilityOptions): UseUpdateVisibilityResult => {
  const [createOrUpdateExtraSection, {
    loading: extraLoading,
  }] = useCreateOrUpdateExtraSectionMutation({
    refetchQueries: createOrUpdateExtraSectionRefetchQueries,
  });

  const [updateFixedSectionsConfiguration, {
    loading: fixedLoading,
  }] = useUpdateFixedSectionsConfigurationMutation({
    refetchQueries: updateFixedSectionsConfigurationRefetchQueries,
  });

  const loading = !!(extraLoading || fixedLoading);

  const handleUpdateVisibility = useCallback((
    show: boolean,
  ): Promise<unknown> => {
    if (sectionType === SectionType.Extra) {
      return createOrUpdateExtraSection({
        variables: {
          formId,
          extraSectionId: extraSectionId as number,
          params: {
            show,
          },
        },
      });
    }

    return updateFixedSectionsConfiguration({
      variables: {
        id: fixedSectionConfigurationId as number,
        params: {
          [sectionVisibilityField as string]: show,
        },
      },
    });
  }, [
    formId,
    sectionType,
    extraSectionId,
    sectionVisibilityField,
    createOrUpdateExtraSection,
    fixedSectionConfigurationId,
    updateFixedSectionsConfiguration,
  ]);

  const payload = useMemo(() => ({
    updateVisibility: handleUpdateVisibility,
    loading,
  }), [
    handleUpdateVisibility,
    loading,
  ]);

  return payload;
};

export default useUpdateVisibility;
