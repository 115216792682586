import * as R from "remeda";

import i18n from "translations/i18n";

export enum QuestionDisplayTypeEnum {
  HIDDEN = "HIDDEN",
  SHOW = "SHOW",
}

export interface DisplayTypeInfo {
  value: QuestionDisplayTypeEnum;
  label: string;
}

/**
 * Maps possible form step types with their translations.
 */
export const mapQuestionDisplayTypes: Record<QuestionDisplayTypeEnum, DisplayTypeInfo> = {
  [QuestionDisplayTypeEnum.HIDDEN]: {
    value: QuestionDisplayTypeEnum.HIDDEN,
    label: i18n.t("question_display_types.hidden"),
  },
  [QuestionDisplayTypeEnum.SHOW]: {
    value: QuestionDisplayTypeEnum.SHOW,
    label: i18n.t("question_display_types.show"),
  },
};

/**
 * Display type options to be used in select components.
 */
export const displayTypeOptions = R.pipe(
  Object.values(mapQuestionDisplayTypes),
  R.map((displayType) => ({
    label: displayType.label,
    value: displayType.value,
  })),
);
