import React from "react";

import PreviewContent from "views/Forms/FormPreview/FormResponses/PreviewComponents/PreviewContent";

import { ExtraSectionAnswerProps } from "./types";

const ExtraSectionAnswer: React.FC<ExtraSectionAnswerProps> = ({
  questionAnswer,
}) => (
  <PreviewContent title={`${questionAnswer.questionText}*`}>
    {questionAnswer.answer}
  </PreviewContent>
);

export default ExtraSectionAnswer;
