import useShortApplicantFormSteps from "views/Forms/ShortApplicantForm/hooks/useSteps";
import useApplicantFormSteps from "views/Forms/ApplicantForm/hooks/useSteps";
import useClientFormSteps from "views/Forms/ClientForm/hooks/useSteps";
import { FormTypeEnum } from "generated/graphql";
import { FormStep } from "constants/formSteps";

import { UseFormStepsOptions, UseStepsHook } from "./types";

/**
 * Maps form types to `useSteps` hooks.
 */
const mapUseStepsHooks: Record<FormTypeEnum, UseStepsHook> = {
  [FormTypeEnum.ShortApplication]: useShortApplicantFormSteps,
  [FormTypeEnum.Application]: useApplicantFormSteps,
  [FormTypeEnum.Client]: useClientFormSteps,
};

/**
 * Returns the result of `useSteps` hook for a given form type and extra sections.
 * @param param0 Options.
 */
const useFormSteps = ({
  formName,
  fixedSectionsConfiguration,
  extraSections,
  formType,
  otherFormParams,
}: UseFormStepsOptions): FormStep[] => {
  const useStepsHook = mapUseStepsHooks[formType];

  return useStepsHook(
    formName,
    fixedSectionsConfiguration,
    extraSections,
    otherFormParams,
  );
};

export default useFormSteps;
