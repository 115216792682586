import React from "react";
import {
  Tooltip as ReactTooltip,
  Text,
} from "@chakra-ui/react";
import ConditionalWrap from "conditional-wrap";

import InfoIcon from "settings/theme/icons/InfoIcon";

import { TooltipProps } from "./types";

const Tooltip: React.FC<TooltipProps> = ({
  iconProps,
  children,
  label,
  ...props
}) => (
  <ReactTooltip
    placement="auto"
    bg="secondary.50"
    closeDelay={100}
    openDelay={50}
    maxW="250px"
    hasArrow
    p={2}
    {...props}
    label={(
      <ConditionalWrap
        condition={typeof label === "string"}
        wrap={(content) => (
          <Text
            fontSize="12px"
            color="black"
          >
            {content}
          </Text>
        )}
      >
        <span>
          {label}
        </span>
      </ConditionalWrap>
    )}
  >
    {
      children || (
        <InfoIcon
          color="white"
          {...iconProps}
        />
      )
    }
  </ReactTooltip>
);

export default Tooltip;
