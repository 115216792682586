import yup from "settings/yup";

const sourceSchema = yup.object().shape({
  referrerOptions: yup
    .array()
    .of(
      yup
        .string()
        .required(),
    ),
});

export interface SourceSchema {
  referrerOptions: string[];
}

export default sourceSchema;
