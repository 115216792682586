import React from "react";
import { useTranslation } from "react-i18next";

import PageHeader from "components/PageHeaders/PageHeader";

/**
 * TODO: Implement dashboard page: Help
 */
const Help: React.FC = () => {
  const [t] = useTranslation();

  return (
    <>
      <PageHeader title={t("dashboard.help.title")} />
    </>
  );
};

export default Help;
