import React, { useCallback } from "react";
import { ModalBody, ModalFooter } from "@chakra-ui/react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import { useTranslation } from "react-i18next";

import updateApplicantSchema, { UpdateApplicantSchema } from "settings/yup/schemas/updateApplicantSchema";
import { ScheduleInterviewModalProps } from "components/Tracking/ApplicantStatusCard/types";
import DatePickerInput from "components/FormComponents/DatePickerInput";
import Button from "components/Button";

const ScheduleInterviewModal: React.FC<ScheduleInterviewModalProps> = ({
  componentProps,
  hideModal,
}) => {
  const [t] = useTranslation();

  const {
    control,
    errors,
    watch,
  } = useForm<UpdateApplicantSchema>({
    resolver: yupResolver(updateApplicantSchema),
    mode: "onChange",
  });

  const interviewAt = watch("interviewAt");

  const handleFinish = useCallback(() => {
    let hasConfirmed = false;

    if (componentProps?.onFinish) {
      componentProps.onFinish(interviewAt);
      hasConfirmed = true;
    }

    hideModal({
      hasConfirmed,
    });
  }, [
    componentProps,
    interviewAt,
    hideModal,
  ]);

  return (
    <>
      <ModalBody>
        <Controller
          name="interviewAt"
          control={control}
          render={({
            onChange,
            value,
            name,
          }) => (
            <DatePickerInput
              name={name}
              value={value}
              errors={errors}
              onChange={onChange}
              title={`${t("applicant_profile.interview_date")}:`}
              datePickerProps={{
                showTimeSelect: true,
              }}
            />
          )}
        />
      </ModalBody>

      <ModalFooter>
        <Button
          isDisabled={!interviewAt}
          onClick={handleFinish}
          fontSize="sm"
          w="full"
        >
          {t("buttons.update")}
        </Button>
      </ModalFooter>
    </>
  );
};

export default ScheduleInterviewModal;
