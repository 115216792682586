import React, { useCallback, useState } from "react";
import {
  IconButton,
  Heading,
  Stack,
  Flex,
  Text,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { chartOptions, getChartComponent } from "components/Tracking/TrackingProfileCharts/charts";
import { ChartOption } from "components/Tracking/TrackingProfileCharts/types";
import {
  Applicant,
  useGetApplicantByExternalIdQuery,
} from "generated/graphql";
import QualityTooltip from "components/Tooltip/QualityTooltip";
import Loading from "components/Loading";
import ApplicantProfileCard from "components/Tracking/Cards/Applicant/ApplicantProfileCard";
import Story from "components/Tracking/Story";

import { ApplicantIframeChartParams } from "./types";

const ApplicantIframeChart: React.FC = () => {
  const [t] = useTranslation();

  const [selectedChart, setSelectedChart] = useState<ChartOption>("bar");

  const handleSetSelectedChart = useCallback((newValue: ChartOption) => () => {
    setSelectedChart(newValue);
  }, []);

  const { externalId, token } = useParams<ApplicantIframeChartParams>();

  const clientId = parseInt(externalId);

  const {
    data,
    loading,
  } = useGetApplicantByExternalIdQuery({
    variables: {
      externalId: clientId,
    },
    context: {
      headers: {
        authorization: `Bearer ${token}`,
      },
    },
  });

  const ChartComponent = getChartComponent(selectedChart);

  const applicant = data?.getApplicantByExternalId as Applicant;

  if (loading) {
    return (
      <Flex
        dir="column"
        h="full"
        m="auto"
        w={800}
        maxW="100%"
        p={8}
      >
        <Loading />
      </Flex>
    );
  }

  if (!applicant) {
    return (
      <Flex
        justify="center"
        align="center"
        m="auto"
      >
        <Text>{t("applicant_iframe_chart.unavailable")}</Text>
      </Flex>
    );
  }

  return (
    <Flex
      direction="column"
      h="full"
      m="auto"
      w={800}
      maxW="100%"
      p={8}
    >
      <ApplicantProfileCard
        applicant={applicant}
        roleMatch
        showTooltip
      />

      <Flex
        borderRadius={10}
        p={5}
        m="15px 0"
        direction="column"
        bgColor="white"
        shadow="md"
      >
        <Stack
          isInline
          align="center"
          justify="space-between"
        >
          <Flex alignItems="center">
            <Heading fontSize="md">
              {t("applicant_iframe_chart.title")}
            </Heading>

            <QualityTooltip
              description={t("tooltips.qualities.description")}
              footerNote={t("tooltips.qualities.footer")}
            />
          </Flex>

          <Stack isInline>
            {
              chartOptions.map(({
                icon: Icon,
                id,
              }) => (
                <IconButton
                  onClick={handleSetSelectedChart(id)}
                  aria-label="aria-label"
                  variant="ghost"
                  key={id}
                  icon={(
                    <Icon color={selectedChart === id ? "black" : "gray.300"} />
                  )}
                />
              ))
            }
          </Stack>
        </Stack>

        <Flex
          justify="center"
          align="center"
          pos="relative"
          minH="sm"
          h="full"
          p={3}
        >
          <ChartComponent
            comparisonDatasetLabel="label"
            mainDatasetLabel={t("applicant_iframe_chart.title")}
            scores={applicant?.assessment?.spiderChartScores}
          />
        </Flex>
      </Flex>

      <Story
        title={t("applicant_profile.applicant_story")}
        openAnswers={applicant?.openAnswers}
      />
    </Flex>
  );
};

export default ApplicantIframeChart;
