import React from "react";
import { useTranslation } from "react-i18next";
import { Stack } from "@chakra-ui/react";

import { ApplicationPreviewComponentProps } from "views/Forms/FormPreview/types";
import AvailabilityMatrix from "components/AvailabilityMatrix";
import getFieldLabel from "utils/getFieldLabel";
import isPresent from "utils/isPresent";

import PreviewContent from "../PreviewComponents/PreviewContent";

const Availability: React.FC<ApplicationPreviewComponentProps> = ({
  formResponse,
  isRequired,
}) => {
  const [t] = useTranslation();

  return (
    <>
      <PreviewContent title={getFieldLabel(t("forms.steps.availability.are_you_looking_for_a_full_time_or_part_time_position"), isRequired)}>
        {
          isPresent(formResponse.isFullTime) && (
            formResponse.isFullTime
              ? t("tracking_cards.full_time")
              : t("tracking_cards.part_time")
          )
        }
      </PreviewContent>

      <PreviewContent title={getFieldLabel(t("forms.steps.availability.when_are_you_available_to_begin_work"), isRequired)}>
        {formResponse.availableAt}
      </PreviewContent>

      <PreviewContent
        title={t("forms.steps.availability.which_days_and_times_are_you_available_to_work")}
        renderChildrenAsText={false}
      >
        <Stack overflow="scroll">
          <AvailabilityMatrix
            value={formResponse.availability?.days}
            isDisabled
          />
        </Stack>
      </PreviewContent>

      <PreviewContent title={t("forms.steps.availability.specifically_what_time_of_day_are_you_available_to_start_and_end_work_each_day")}>
        {formResponse.dayAvailability}
      </PreviewContent>
    </>
  );
};

export default Availability;
