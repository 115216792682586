import * as React from "react";
import { IconProps } from "@chakra-ui/icons";
import { createIcon } from "@chakra-ui/react";

const HelpIcon: React.FC<IconProps> = createIcon({
  path: (
    <>
      <rect
        fill="none"
        id="Rectangle_5038"
        data-name="Rectangle 5038"
        width="24"
        height="24"
      />
      <path
        fill="currentColor"
        id="Path_5850"
        data-name="Path 5850"
        d="M16.377,23.9A2.067,2.067,0,0,1,14.293,26a2.091,2.091,0,0,1-2.1-2.1,2.092,2.092,0,0,1,4.183,0ZM14.277,6A5.937,5.937,0,0,0,8.56,10.15l2.733,1.15a3.187,3.187,0,0,1,3-2.467,2.475,2.475,0,0,1,2.283,3.883c-.9,1.283-2.45,2.15-3.267,3.6a6.351,6.351,0,0,0-.517,3.3h3.033a4.207,4.207,0,0,1,.367-2.35c.65-1.2,1.85-1.767,3.117-3.617a5.031,5.031,0,0,0-.033-5.133A5.6,5.6,0,0,0,14.277,6Z"
        transform="translate(-2 -4)"
      />
    </>
  ),
});

export default HelpIcon;
