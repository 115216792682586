import { ApplicantResponse } from "generated/graphql";

export interface BasePersonalStoryProps {
  isRequired: boolean;
}

export interface PersonalStoryFormProps extends BasePersonalStoryProps {
  formResponse?: ApplicantResponse;
  pictureFiles: File[];
}

export interface OpenAnswer {
  question: string;
  answer: string;
}

export enum ImageFieldEnum {
  FirstImage = "firstImage",
  SecondImage = "secondImage",
}
