import create from "zustand";

import { WizardStoreState } from "./types";

/**
 * Defines the initial step index before being initialized by Wizard.
 */
export const INITIAL_STORE_STEP_INDEX = -1;

/**
 * Exposes the Wizard component store, containing the current component state and
 * methods to help with navigation between the Wizard steps.
 */
const useWizardStore = create<WizardStoreState>((set, get) => ({
  steps: [],
  stepIndex: INITIAL_STORE_STEP_INDEX,
  getIsFirstStep: () => {
    const { stepIndex } = get();

    return stepIndex === 0;
  },
  getIsLastStep: () => {
    const { steps, stepIndex } = get();

    return stepIndex === (steps.length - 1);
  },
  getIsLoaded: () => {
    const { steps } = get();

    return steps.length > 0;
  },
  setStepIndex: (newIndex) => set((prev) => {
    if (!prev.steps[newIndex]) {
      return {};
    }

    return {
      stepIndex: newIndex,
    };
  }),
  navigateToNextStep: () => set((prev) => {
    const newStepIndex = Math.min(prev.steps.length - 1, prev.stepIndex + 1);

    return {
      stepIndex: newStepIndex,
    };
  }),
  navigateToPreviousStep: () => set((prev) => {
    const newStepIndex = Math.max(0, prev.stepIndex - 1);

    return {
      stepIndex: newStepIndex,
    };
  }),
  getCurrentStep: () => {
    const { steps, stepIndex } = get();

    return steps[stepIndex];
  },
}));

export default useWizardStore;
