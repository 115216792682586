import React from "react";
import { useTranslation } from "react-i18next";

import Button from "components/Button";
import PageHeader from "components/PageHeaders/PageHeader";

import { FormHeaderProps } from "./types";

const FormHeader: React.FC<FormHeaderProps> = ({
  title,
  onSubmit,
  isButtonDisabled = false,
}) => {
  const [t] = useTranslation();

  return (
    <PageHeader title={title}>
      <Button
        onClick={onSubmit}
        disabled={isButtonDisabled}
        fontSize="xs"
      >
        {t("buttons.save_changes")}
      </Button>
    </PageHeader>
  );
};

export default FormHeader;
