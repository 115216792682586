import React from "react";
import { useTranslation } from "react-i18next";
import * as R from "remeda";
import {
  ListItem,
  HStack,
  VStack,
  Badge,
  List,
  Text,
  Flex,
} from "@chakra-ui/react";

import usePhotoModal from "hooks/usePhotoModal";
import getRoleFitScoreOpacity from "utils/getRoleFitScoreOpacity";
import {
  CardContainerProps,
  CardContentProps,
  CardStatusProps,
  CardInfoProps,
  CardFitProps,
} from "components/Tracking/Cards/types";
import Images from "components/Images";
import Button from "components/Button";
import Tooltip from "components/Tooltip";
import { APPLICANTS_PROFILE_TOUR_STEPS } from "constants/reactTour";

import {
  fitContainerSizes,
  cardFitCss,
} from "./styles";

const PROFILE_CARD_IMAGE_LIMIT = 2;

const LIST_CARD_IMAGE_HEIGHT = "115px";

/**
 * Renders the card fit section.
 * @param param0 Props.
 */
const CardFit: React.FC<CardFitProps> = ({
  roleFitScore,
  fitContainerSize,
  fitLabel,
  fitLabelFontSize = "md",
  tooltipText,
  formattedRoleFitScore,
  ...rest
}) => {
  const opacity = getRoleFitScoreOpacity(roleFitScore);

  const iconProps = {
    ml: 1,
  };

  return (
    <Flex
      paddingRight={2}
      direction="column"
      position="absolute"
      bgColor="green.500"
      zIndex={10}
      right={0}
      top={0}
      mt={0}
      opacity={opacity}
      css={cardFitCss}
      width={`${fitContainerSizes[fitContainerSize].width}px`}
      height={`${fitContainerSizes[fitContainerSize].width}px`}
      flexDir="column"
      alignItems="center"
      justifyContent="center"
      pl="30px"
      className={APPLICANTS_PROFILE_TOUR_STEPS.roleFit}
      {...rest}
    >
      <Text
        fontFamily="bold"
        textAlign="center"
        fontSize="4xl"
        color="white"
      >
        {formattedRoleFitScore}
      </Text>

      <Flex alignItems="center">
        <Text
          fontSize={fitLabelFontSize}
          color="white"
        >
          {fitLabel}
        </Text>

        {
          tooltipText && (
            <Tooltip
              label={tooltipText}
              iconProps={iconProps}
              placement="left"
            />
          )
        }
      </Flex>
    </Flex>
  );
};

/**
 * Renders the card container.
 * @param param0 Props.
 */
const CardContainer: React.FC<CardContainerProps> = ({
  fitContainerSize,
  roleFitScore,
  fitLabel,
  children,
  fitLabelFontSize,
  tooltipText,
  formattedRoleFitScore,
  ...rest
}) => (
  <Flex
    borderRadius={10}
    padding={5}
    maxWidth="100%"
    minWidth="100%"
    alignItems="flex-start"
    position="relative"
    direction="column"
    bgColor="white"
    shadow="md"
    minH={155}
    {...rest}
  >
    <CardFit
      fitContainerSize={fitContainerSize}
      roleFitScore={roleFitScore}
      fitLabelFontSize={fitLabelFontSize}
      fitLabel={fitLabel}
      borderRadius={10}
      tooltipText={tooltipText}
      formattedRoleFitScore={formattedRoleFitScore}
    />

    <VStack
      alignItems="flex-start"
      maxWidth="100%"
      flexGrow={1}
      spacing={4}
    >
      {children}
    </VStack>
  </Flex>
);

/**
 * Renders the card status indicator.
 * @param param0 Props.
 */
const CardStatus: React.FC<CardStatusProps> = ({
  bgColor,
  label,
}) => (
  <HStack spacing={2}>
    <Flex
      bgColor={bgColor}
      height="12px"
      width="12px"
      borderRadius="100%"
    />

    <Text fontFamily="heading" color="dark">
      {label}
    </Text>
  </HStack>
);

/**
 * Renders one item on CardInfo list.
 * @param param0 Props.
 */
const CardInfoItem: React.FC = ({
  children,
}) => (
  <ListItem
    justifyContent="flex-start"
    alignItems="flex-start"
    flexDirection="row"
    display="flex"
  >
    {children}
  </ListItem>
);

/**
 * Renders the card information.
 * @param param0 Props.
 */
const CardInfo: React.FC<CardInfoProps> = ({
  items = [],
  fullNameContainerProps,
  imageProps,
  fullName,
  ...rest
}: CardInfoProps) => {
  const [t] = useTranslation();

  return (
    <VStack
      justifyContent="flex-start"
      alignItems="flex-start"
      overflow="hidden"
      maxWidth="100%"
      height="100%"
      width="100%"
      spacing={2}
      {...rest}
    >
      <Text
        fontFamily="extrabold"
        textAlign="left"
        fontSize="sm"
        zIndex={11}
        {...(fullNameContainerProps ?? {})}
      >
        {fullName}
      </Text>

      {
        (imageProps?.showFlag && imageProps?.flagText) && (
          <Badge
            borderRadius={4}
            textTransform="none"
            bgColor="red.500"
            fontFamily="body"
            fontSize="xxs"
            color="white"
            ml={2}
            p={1}
          >
            {t("tracking_cards.unqualified")}
          </Badge>
        )
      }

      <List>
        {
          items.map(({
            icon: Icon,
            text,
          }) => (
            <CardInfoItem key={text}>
              <Icon
                marginRight={2}
                height="20px"
                width="20px"
              />

              <Text
                textAlign="left"
                fontSize="xs"
              >
                {text}
              </Text>
            </CardInfoItem>
          ))
        }
      </List>
    </VStack>
  );
};

/**
 * Renders the card content following the list layout.
 * @param param0 Props.
 */
export const ListCardContent: React.FC<CardContentProps> = ({
  picturesUrl = [],
  fitContainerSize,
  containerStyle,
  roleFitScore,
  imageProps,
  infoItems,
  fullName,
  fitLabel,
  status,
  formattedRoleFitScore,
  ...props
}) => (
  <CardContainer
    {...(containerStyle ?? {})}
    formattedRoleFitScore={formattedRoleFitScore}
    fitContainerSize={fitContainerSize}
    roleFitScore={roleFitScore}
    fitLabel={fitLabel}
    height="full"
    width="100%"
    maxWidth="100%"
    {...props}
  >
    <CardStatus
      label={status?.label}
      bgColor={status?.color}
    />
    <HStack
      alignItems="flex-start"
      maxWidth="100%"
      flexGrow={1}
      spacing={4}
      width="100%"
    >
      <Flex
        height={LIST_CARD_IMAGE_HEIGHT}
        justifyContent="center"
        borderRadius={10}
        width="3xs"
      >
        <Images
          {...(imageProps ?? {})}
          height={LIST_CARD_IMAGE_HEIGHT}
          showIndicators={false}
          src={picturesUrl}
          width="auto !important"
          objectFit="contain"
        />
      </Flex>

      <CardInfo
        fullName={fullName}
        items={infoItems}
        fullNameContainerProps={{
          maxWidth: "90%",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
        }}
      />
    </HStack>
  </CardContainer>
);

/**
 * Renders the card content following the profile layout.
 * @param param0 Props.
 */
export const ProfileCardContent: React.FC<CardContentProps> = ({
  fitContainerSize,
  picturesUrl = [],
  containerStyle,
  roleFitScore,
  imageProps,
  infoItems,
  fullName,
  fitLabel,
  status,
  formattedRoleFitScore,
  ...props
}) => {
  const showPhotoModal = usePhotoModal();
  const containerHeight = picturesUrl.length !== 0 ? "full" : "";

  return (
    <CardContainer
      {...(containerStyle ?? {})}
      fitContainerSize={fitContainerSize}
      roleFitScore={roleFitScore}
      fitLabel={fitLabel}
      formattedRoleFitScore={formattedRoleFitScore}
      h={containerHeight}
      {...props}
    >
      <CardStatus
        label={status?.label}
        bgColor={status?.color}
      />

      <VStack
        alignItems="flex-start"
        spacing={4}
      >
        <CardInfo
          imageProps={imageProps}
          fullName={fullName}
          items={infoItems}
          fullNameContainerProps={{
            fontSize: "md",
          }}
        />

        <HStack>
          {
            R.take(picturesUrl, PROFILE_CARD_IMAGE_LIMIT).map((pictureUrl) => (
              <Button
                display="flex"
                height="full"
                width="full"
                padding={0}
                key={pictureUrl}
                variant="unstyled"
                onClick={showPhotoModal(pictureUrl)}
              >
                <Images
                  {...(imageProps ?? {})}
                  showIndicators={false}
                  showFlag={false}
                  src={pictureUrl}
                  objectFit="contain"
                  bgColor="gray.100"
                  height="3xs"
                  width="auto"
                />
              </Button>
            ))
          }
        </HStack>
      </VStack>
    </CardContainer>
  );
};
