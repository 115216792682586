import React from "react";
import { Heading, RadioGroup, Stack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";

import { clientMatchStatuses, clientMatchStatusesOptions } from "constants/statuses";
import { ClientMatchFiltersProps } from "components/Tracking/ClientMatchCard/types";
import SearchInput from "components/FormComponents/Input/SearchInput";
import FieldTitle from "components/FormComponents/FieldTitle";
import Slider from "components/FormComponents/Slider";
import Radio from "components/FormComponents/Radio";

const ClientMatchFilters: React.FC<ClientMatchFiltersProps> = ({
  control,
  errors,
}) => {
  const [t] = useTranslation();

  return (
    <Stack spacing={4}>
      <Controller
        name="search"
        control={control}
        defaultValue=""
        render={({
          onChange,
          value,
          name,
        }) => (
          <SearchInput
            name={name}
            value={value}
            errors={errors}
            onChange={onChange}
          />
        )}
      />

      <Heading fontSize="xs">
        {`${t("filters.filter_by")}:`}
      </Heading>

      <Controller
        name="roleFitScore"
        control={control}
        defaultValue={[0, 100]}
        render={({
          onChange,
          value,
          name,
        }) => (
          <Slider
            name={name}
            value={value}
            errors={errors}
            onChange={onChange}
            title={t("tracking_cards.role_fit")}
            titleProps={{
              fontSize: "xs",
              color: "gray.400",
            }}
            labelSuffix="%"
          />
        )}
      />

      <Controller
        name="status"
        control={control}
        defaultValue={clientMatchStatuses.ONLY_HIRED.value}
        render={({
          onChange,
          value,
          name,
        }) => (
          <RadioGroup
            onChange={onChange}
            value={value}
            name={name}
          >
            <FieldTitle
              fontSize="xs"
              color="gray.400"
            >
              {t("filters.status")}
            </FieldTitle>

            <Stack spacing={2}>
              {
                clientMatchStatusesOptions.map((option) => (
                  <Radio
                    value={option.value}
                    name={option.label}
                    key={option.value}
                    fontSize={{
                      base: "xxs",
                      xl: "xs",
                    }}
                  >
                    {option.label}
                  </Radio>
                ))
              }
            </Stack>
          </RadioGroup>
        )}
      />
    </Stack>
  );
};

export default ClientMatchFilters;
