import yup from "settings/yup";

const sourceSchema = yup.object().shape({
  source: yup
    .string()
    .required(),
  sourceInput: yup
    .string(),
});

export interface SourceSchema {
  source: string;
  sourceInput?: string;
}

export default sourceSchema;
