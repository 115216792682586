import React from "react";

import Loading from "components/Loading";

import useApplicantFormResponse from "../hooks/useFormResponse/useApplicantFormResponse";
import AvailabilityForm from "./AvailabilityForm";

const Availability: React.FC = () => {
  const [formResponse, loading] = useApplicantFormResponse();

  if (loading) {
    return (
      <Loading />
    );
  }

  return (
    <AvailabilityForm formResponse={formResponse} />
  );
};

export default Availability;
