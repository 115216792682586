import * as R from "remeda";

import { SelectOption } from "components/FormComponents/Select/types";

import { Job, Role } from "../types";

/**
 * Parses the select role options, disabling roles that the user has already hired.
 */
const parseRoleOptions = (
  roles: Role[],
  existingJobs: Job[],
): SelectOption[] => R.pipe(
  roles,
  R.map((role): SelectOption => ({
    isDisabled: existingJobs.some((job) => job.role.id === role.id),
    label: role.name,
    value: role.id,
  })),
);

export default parseRoleOptions;
