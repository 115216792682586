import { ptBR, enUS, de } from "date-fns/locale";

import i18n from "./i18n";

const map: Record<string, Locale> = {
  pt: ptBR,
  en: enUS,
  de,
};

/**
 * Provides the Locale object to translate date-fns functions.
 * Defaults to enUS if not valid.
 */
const getDateFnsLocale = (): Locale => map[i18n.language] || enUS;

export default getDateFnsLocale;
