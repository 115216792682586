import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import Table from "components/Table";

import { CompanyMembersTableProps } from "../types";
import { makeTableColumns } from "./tableData";

const CompanyMembersTable: React.FC<CompanyMembersTableProps> = ({
  handleReinviteMember,
  handleRemoveMember,
  currentUserMember,
  handleEditMember,
  activeMembers,
}) => {
  const [t] = useTranslation();

  const columns = useMemo(() => (
    makeTableColumns({
      handleReinviteMember,
      handleRemoveMember,
      currentUserMember,
      handleEditMember,
    })
  ), [
    handleReinviteMember,
    handleRemoveMember,
    currentUserMember,
    handleEditMember,
  ]);

  return (
    <Table
      title={t("dashboard.team.current_team_members")}
      data={activeMembers}
      columns={columns}
      titleProps={{
        textTransform: "uppercase",
      }}
      containerProps={{
        rounded: "lg",
        shadow: "md",
      }}
    />
  );
};

export default CompanyMembersTable;
