import { useMemo } from "react";

import ApplicantPersonalInformation from "components/FormSteps/PersonalInformation/ApplicantPersonalInformation";
import ApplicantPersonalStory from "components/FormSteps/PersonalStory/ApplicantPersonalStory";
import parseExtraSection from "components/FormSteps/ExtraSection/parseExtraSection";
import Requirements from "components/FormSteps/Requirements";
import Availability from "components/FormSteps/Availability";
import Resume from "components/FormSteps/Resume";
import Source from "components/FormSteps/Source";
import { ExtraSection } from "generated/graphql";
import i18n from "translations/i18n";
import {
  FormStep,
  FormStepId,
  FormStepType,
  SectionVisibilityField,
  FixedSectionsConfigurationParam,
} from "constants/formSteps";
import isStepOptional from "utils/isStepOptional";
import { OtherFormParams } from "views/Forms/types";
import parsePersonalStories from "components/FormSteps/PersonalStory/parsePersonalStories";

const getBaseSteps = (
  subtitle: string,
  fixedSectionsConfiguration: FixedSectionsConfigurationParam,
  roleName: string | undefined,
  otherFormParams: OtherFormParams,
): FormStep[] => ([
  {
    id: FormStepId.PersonalInformation,
    type: FormStepType.RequiredReadOnly,
    component: ApplicantPersonalInformation,
    title: i18n.t("forms.steps.personal_information.title"),
    subtitle,
    isOptional: false,
  },
  {
    id: FormStepId.Requirements,
    type: isStepOptional(fixedSectionsConfiguration?.[SectionVisibilityField.Requirements])
      ? FormStepType.Optional
      : FormStepType.RequiredEditable,
    component: Requirements,
    title: i18n.t("forms.steps.requirements.title"),
    subtitle,
    isOptional: isStepOptional(fixedSectionsConfiguration?.[SectionVisibilityField.Requirements]),
    isVisible: fixedSectionsConfiguration?.[SectionVisibilityField.Requirements],
    sectionVisibilityField: SectionVisibilityField.Requirements,
  },
  {
    id: FormStepId.Source,
    type: isStepOptional(fixedSectionsConfiguration?.[SectionVisibilityField.Source])
      ? FormStepType.Optional
      : FormStepType.RequiredEditable,
    component: Source,
    title: i18n.t("forms.steps.source.title"),
    subtitle,
    isOptional: isStepOptional(fixedSectionsConfiguration?.[SectionVisibilityField.Source]),
    isVisible: fixedSectionsConfiguration?.[SectionVisibilityField.Source],
    sectionVisibilityField: SectionVisibilityField.Source,
  },
  {
    id: FormStepId.Availability,
    type: isStepOptional(fixedSectionsConfiguration?.[SectionVisibilityField.Availability])
      ? FormStepType.Optional
      : FormStepType.RequiredReadOnly,
    component: Availability,
    title: i18n.t("forms.steps.availability.title"),
    subtitle,
    isOptional: isStepOptional(fixedSectionsConfiguration?.[SectionVisibilityField.Availability]),
    isVisible: fixedSectionsConfiguration?.[SectionVisibilityField.Availability],
    sectionVisibilityField: SectionVisibilityField.Availability,
  },
  ...parsePersonalStories({
    component: ApplicantPersonalStory,
    roleName,
    ...otherFormParams,
  }),
]);

/**
 * Hook that exposes the steps to be rendered in Applicant Form.
 * @param extraSections The form extra sections.
 */
const useSteps = (
  roleName: string | undefined,
  fixedSectionsConfiguration: FixedSectionsConfigurationParam,
  extraSections: ExtraSection[] = [],
  otherFormParams: OtherFormParams,
): FormStep[] => {
  const subtitle = i18n.t("forms.applicant_form.subtitle", { roleName });

  const extraSectionSteps = useMemo(() => (
    extraSections.map((extraSection) => parseExtraSection(
      extraSection,
      subtitle,
    ))
  ), [
    subtitle,
    extraSections,
  ]);

  const baseSteps = getBaseSteps(
    subtitle,
    fixedSectionsConfiguration,
    roleName,
    otherFormParams,
  );

  const resumeStep: FormStep = useMemo(() => ({
    id: FormStepId.Resume,
    type: FormStepType.Optional,
    component: Resume,
    title: i18n.t("forms.steps.resume.title"),
    subtitle,
    isOptional: isStepOptional(fixedSectionsConfiguration?.[SectionVisibilityField.Resume]),
    isVisible: fixedSectionsConfiguration?.[SectionVisibilityField.Resume],
    sectionVisibilityField: SectionVisibilityField.Resume,
  }), [
    subtitle,
    fixedSectionsConfiguration,
  ]);

  const steps = useMemo(() => [
    ...baseSteps,
    ...extraSectionSteps,
    resumeStep,
  ], [
    baseSteps,
    resumeStep,
    extraSectionSteps,
  ]);

  return steps;
};

export default useSteps;
