import React from "react";
import {
  VStack,
  Grid,
} from "@chakra-ui/react";

import { TrackingListContainerProps } from "components/Tracking/types";

import TrackingFiltersContainer from "./TrackingFiltersContainer";

const TrackingListContainer: React.FC<TrackingListContainerProps> = ({
  children,
  filters,
  activeFiltersCount,
}) => (
  <Grid
    templateColumns="250px 1fr"
    minHeight="100vh"
    gap={6}
    mt={6}
  >
    <TrackingFiltersContainer
      activeFiltersCount={activeFiltersCount}
      filters={filters}
    />

    <VStack
      spacing={4}
      width="100%"
      height="100%"
      justifyContent="flex-start"
    >
      {children}
    </VStack>
  </Grid>
);

export default TrackingListContainer;
