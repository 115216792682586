import React from "react";
import { Box } from "@chakra-ui/react";

import Input from "components/FormComponents/Input";
import i18n from "translations/i18n";

import { ColorInputProps } from "./types";

const ColorInput = React.forwardRef<HTMLElement, ColorInputProps>((
  {
    placeholder = i18n.t("buttons.pick_a_color"),
    toggleColorPicker,
    value,
    ...props
  },
  ref,
) => (
  <Input
    {...props}
    onClick={toggleColorPicker}
    placeholder={placeholder}
    autoComplete="off"
    cursor="pointer"
    value={value}
    isReadOnly
    ref={ref}
    rightElementProps={{
      onClick: toggleColorPicker,
      cursor: "pointer",
      width: "3.5rem",
    }}
    rightElement={(
      <Box
        bgColor={value}
        rounded="md"
        boxSize={6}
      />
    )}
  />
));

export default ColorInput;
