/**
 * Parses the count of thumbs in the Slider component.
 * @param value The value.
 */
const getThumbCount = (value: number[] | number | undefined): number => (
  Array.isArray(value)
    ? value.length
    : 1
);

export default getThumbCount;
