import React from "react";
import { Flex, Heading, Stack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import Box from "components/Box";

import { ClientStatusCardProps } from "./types";
import StatusCardForm from "./StatusCardForm";

const ClientStatusCard: React.FC<ClientStatusCardProps> = ({
  client,
}) => {
  const [t] = useTranslation();

  return (
    <Box
      minHeight={160}
      w="full"
      h="auto"
      borderRadius={10}
    >
      <Flex
        direction="column"
        h="full"
      >
        <Heading fontSize="md" mb={5}>
          {`${t("client_profile.update_client_status")}:`}
        </Heading>

        <Stack h="full">
          <StatusCardForm client={client} />
        </Stack>
      </Flex>
    </Box>
  );
};

export default ClientStatusCard;
