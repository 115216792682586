import React from "react";
import { FormControl } from "@chakra-ui/react";
import ReactSelect from "react-select";

import getErrorMessage from "utils/getErrorMessage";
import FieldErrorMessage from "components/FormComponents/FieldErrorMessage";
import FieldTitle from "components/FormComponents/FieldTitle";

import parseSelectValue from "./parseSelectValue";
import { SelectProps } from "./types";
import styles from "./styles";

const Select = React.forwardRef<ReactSelect, SelectProps>((
  {
    showErrorMessage = true,
    titleProps,
    isDisabled,
    options,
    errors,
    value,
    title,
    name,
    ...rest
  },
  ref,
) => {
  const error = getErrorMessage(name, errors);

  const selectValue = parseSelectValue(value, options);

  return (
    <FormControl cursor={isDisabled ? "not-allowed" : "pointer"}>
      {
        title && (
          <FieldTitle
            htmlFor={name}
            {...(titleProps ?? {})}
          >
            {title}
          </FieldTitle>
        )
      }

      <ReactSelect
        {...rest}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ref={ref as any}
        isDisabled={isDisabled}
        value={selectValue}
        options={options}
        styles={styles}
        name={name}
      />

      {
        showErrorMessage && (
          <FieldErrorMessage
            textAlign="end"
            error={error}
          />
        )
      }
    </FormControl>
  );
});

export default Select;
