import React, { useCallback } from "react";
import { Button, Stack, Text } from "@chakra-ui/react";
import ConditionalWrap from "conditional-wrap";
import numeral from "numeral";

import { ApplicantDetailsProps } from "components/Tracking/ClientMatchCard/types";
import getRoleFitScoreOpacity from "utils/getRoleFitScoreOpacity";
import buildName from "utils/buildName";
import Box from "components/Box";

import { buttonCss } from "./styles";

const ApplicantDetails: React.FC<ApplicantDetailsProps> = ({
  setComparisonScores,
  textProps,
  applicant,
  clickable,
  onClick,
}) => {
  const roleFitScore = applicant?.assessment?.roleFitScore ?? 0;

  const fitScorePercentage = numeral(roleFitScore).format("0%");

  const opacity = getRoleFitScoreOpacity(roleFitScore);

  const handleOnMouseEnter = useCallback(() => {
    if (setComparisonScores) {
      setComparisonScores(applicant);
    }
  }, [
    setComparisonScores,
    applicant,
  ]);

  const handleOnMouseLeave = useCallback(() => {
    if (setComparisonScores) {
      setComparisonScores(null);
    }
  }, [
    setComparisonScores,
  ]);

  return (
    <ConditionalWrap
      condition={!!clickable}
      wrap={(children) => (
        <Button
          variant="unstyled"
          boxShadow="none"
          onClick={onClick}
          onMouseEnter={handleOnMouseEnter}
          onMouseLeave={handleOnMouseLeave}
          css={buttonCss}
          minHeight={50}
          outline={0}
          w="full"
        >
          {children}
        </Button>
      )}
    >
      <Stack
        alignItems="center"
        isInline
      >
        <Box
          justifyContent="center"
          alignItems="center"
          bgColor="green.500"
          opacity={opacity}
          display="flex"
          rounded={100}
          boxSize={50}
          mr={3}
        >
          <Text
            color="white"
            fontSize="sm"
          >
            {fitScorePercentage}
          </Text>
        </Box>

        <Text
          fontSize="sm"
          fontWeight="lighter"
          {...(textProps ?? {})}
        >
          {buildName(applicant?.firstName, applicant?.lastName)}
        </Text>
      </Stack>
    </ConditionalWrap>
  );
};

export default ApplicantDetails;
