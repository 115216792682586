import yup from "settings/yup";
import { TEXT_INPUT_LENGTH } from "constants/forms";

import updateLocationSchema, { LocationSchema } from "./locationSchema";

const updateCompanySchema = yup.object().shape({
  name: yup
    .string()
    .max(TEXT_INPUT_LENGTH)
    .required(),
  location: updateLocationSchema,
});

export interface UpdateCompanySchema {
  name: string;
  location: LocationSchema;
}

export default updateCompanySchema;
