import * as R from "remeda";

import { SelectOption } from "components/FormComponents/Select/types";
import { BaseJob } from "contexts/trackingFiltersQueries/types";

/**
 * Parses the select role filter options.
 * @param data Query result.
 */
const getRoleFilterOptions = (data?: BaseJob[]): SelectOption[] => R.pipe(
  (data ?? []),
  R.sortBy((job) => job.role.name),
  R.map((job) => ({
    value: String(job.id),
    label: job.role.name,
  })),
);

export default getRoleFilterOptions;
