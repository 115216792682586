import { WizardStep } from "components/Wizard/types";
import i18n from "translations/i18n";

import ConfigureQuestionnaireHeader from "./Steps/ConfigureQuestionnaire/ConfigureQuestionnaireHeader";
import ConfigureApplicationHeader from "./Steps/ConfigureApplication/ConfigureApplicationHeader";
import ConfigureLandingPageHeader from "./Steps/ConfigureLandingPage/ConfigureLandingPageHeader";
import SelectLandingPageHeader from "./Steps/SelectLandingPage/SelectLandingPageHeader";
import ConfigureQuestionnaire from "./Steps/ConfigureQuestionnaire";
import SelectRoleHeader from "./Steps/SelectRole/SelectRoleHeader";
import ConfigureApplication from "./Steps/ConfigureApplication";
import ConfigureLandingPage from "./Steps/ConfigureLandingPage";
import SelectLandingPage from "./Steps/SelectLandingPage";
import SelectRole from "./Steps/SelectRole";

export enum CompanyOnboardingStep {
  SelectRoleStep = 1,
  SelectLandingPageStep = 2,
  ConfigureLandingPageStep = 3,
  ConfigureApplicationStep = 4,
  ConfigureQuestionnaireStep = 5,
}

const steps: WizardStep[] = [
  {
    id: CompanyOnboardingStep.SelectRoleStep,
    component: SelectRole,
    headerComponent: SelectRoleHeader,
    title: i18n.t("company_onboarding.steps.select_role.title"),
  },
  {
    id: CompanyOnboardingStep.SelectLandingPageStep,
    component: SelectLandingPage,
    headerComponent: SelectLandingPageHeader,
    title: i18n.t("company_onboarding.steps.select_landing_page.title"),
  },
  {
    id: CompanyOnboardingStep.ConfigureLandingPageStep,
    component: ConfigureLandingPage,
    headerComponent: ConfigureLandingPageHeader,
    title: i18n.t("company_onboarding.steps.configure_landing_page.title"),
  },
  {
    id: CompanyOnboardingStep.ConfigureApplicationStep,
    component: ConfigureApplication,
    headerComponent: ConfigureApplicationHeader,
    title: i18n.t("company_onboarding.steps.configure_application_form.title"),
  },
  {
    id: CompanyOnboardingStep.ConfigureQuestionnaireStep,
    component: ConfigureQuestionnaire,
    headerComponent: ConfigureQuestionnaireHeader,
    title: i18n.t("company_onboarding.steps.configure_client_form.title"),
  },
];

export default steps;
