import { useCallback, useMemo } from "react";

import { useGetPaymentReceiptUrlLazyQuery } from "generated/graphql";
import { onQueryError } from "utils/queryHandlers";
import useToast from "hooks/useToast";

import { UseViewPaymentReceiptResult } from "./types";

/**
 * Exposes a function that queries for `getPaymentReceiptUrl` and opens the receipt in a new tab.
 *
 * The query loading state is also exposed.
 *
 * In case the query fails, a toast will be shown with the error.
 */
const useViewPaymentReceipt = (): UseViewPaymentReceiptResult => {
  const [showToast] = useToast();

  const [loadQuery, {
    refetch,
    loading,
    called,
  }] = useGetPaymentReceiptUrlLazyQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      const url = data?.getPaymentReceiptUrl;

      if (url) {
        window.open(url, "_blank");
      }
    },
    onError: (error) => {
      onQueryError(error, showToast);
    },
  });

  const viewPaymentReceipt = useCallback((paymentId: number) => () => {
    const payload = {
      id: paymentId,
    };

    if (!called) {
      loadQuery({
        variables: payload,
      });

      return;
    }

    refetch?.(payload);
  }, [
    loadQuery,
    refetch,
    called,
  ]);

  const payload = useMemo<UseViewPaymentReceiptResult>(() => [
    viewPaymentReceipt,
    loading,
  ], [
    viewPaymentReceipt,
    loading,
  ]);

  return payload;
};

export default useViewPaymentReceipt;
