import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import Select from "components/FormComponents/Select";
import ListItem from "components/ListItem";

import ExtraTokensPacksPrices from "./ExtraTokensPacksPrices";
import parseTokenPackOptions from "./parseTokenPackOptions";
import { AddTokensFormProps } from "./types";

const AddTokensForm: React.FC<AddTokensFormProps> = ({
  setSelectedTokensPack,
  selectedTokensPack,
  role,
}) => {
  const [t] = useTranslation();

  const extraTokensPacks = useMemo(() => (
    role.extraTokensPacks ?? []
  ), [
    role.extraTokensPacks,
  ]);

  const options = useMemo(() => (
    parseTokenPackOptions(extraTokensPacks)
  ), [
    extraTokensPacks,
  ]);

  return (
    <>
      <ListItem
        title={t("dashboard.subscription.add_tokens_modal.category")}
        value={role.name}
      />

      <ExtraTokensPacksPrices extraTokensPacks={extraTokensPacks} />

      <Select
        placeholder={t("dashboard.subscription.add_tokens_modal.select_number_of_tokens")}
        title={`${t("dashboard.subscription.add_tokens_modal.quantity")}:`}
        onChange={setSelectedTokensPack}
        value={selectedTokensPack}
        options={options}
        titleProps={{
          fontSize: "xs",
        }}
      />
    </>
  );
};

export default AddTokensForm;
