import React from "react";
import LoremIpsum from "react-lorem-ipsum";
import { useTranslation } from "react-i18next";
import {
  Heading,
  VStack,
  Flex,
  Text,
} from "@chakra-ui/react";

import Button from "components/Button";

import { JobDescriptionSectionProps } from "./types";

const JobDescriptionSection: React.FC<JobDescriptionSectionProps> = ({
  navigateToForm,
  headingProps,
  buttonProps,
  landingPage,
}) => {
  const [t] = useTranslation();

  return (
    <Flex
      paddingY={20}
      paddingX={[10, null, 40]}
      alignItems="start"
      direction="column"
    >
      <Heading
        textAlign="center"
        alignSelf="center"
        fontSize="md"
        color={landingPage.color}
        {...(headingProps ?? {})}
      >
        {t("landing_pages_templates.job_description")}
      </Heading>

      <Text
        color="dark"
        fontSize="xs"
        marginTop={15}
        whiteSpace="break-spaces"
      >
        {
          landingPage.jobDescription || (
            <VStack spacing={4}>
              <LoremIpsum
                p={3}
                avgWordsPerSentence={20}
              />
            </VStack>
          )
        }
      </Text>

      <Button
        marginTop={35}
        minWidth={40}
        fontSize="sm"
        fontFamily="heading"
        borderRadius={30}
        alignSelf="center"
        textTransform="uppercase"
        onClick={navigateToForm}
        color={landingPage.textColor}
        backgroundColor={landingPage.color}
        {...(buttonProps ?? {})}
      >
        {landingPage.buttonText}
      </Button>
    </Flex>
  );
};

export default JobDescriptionSection;
