import { MemberOccupationEnum } from "generated/graphql";
import emailTest from "settings/yup/tests/emailTest";
import yup from "settings/yup";

import { makePhoneNumberTest } from "../tests/phoneNumberTest";

const memberInformationSchema = yup.object().shape({
  name: yup
    .string()
    .required(),
  invitedEmail: yup
    .string()
    .test(emailTest)
    .required(),
  phoneNumber: yup
    .string()
    .test(makePhoneNumberTest())
    .required(),
  occupation: yup
    .string()
    .required(),
});

export interface MemberInformationSchema {
  name: string;
  invitedEmail: string;
  phoneNumber: string;
  occupation: MemberOccupationEnum;
}

export default memberInformationSchema;
