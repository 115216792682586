import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import { useTranslation } from "react-i18next";

import { useGetCurrentUserLazyQuery } from "generated/graphql";
import useSession from "hooks/useSession";
import useToast from "hooks/useToast";

import { CurrentUserContainerState, CurrentUserContextPayload } from "./types";
import { CurrentUserProvider } from ".";

const CurrentUserContainer: React.FC = ({
  children,
}) => {
  const [{ loading, currentUser }, setState] = useState<CurrentUserContainerState>({
    currentUser: null,
    loading: true,
  });

  const [, endSession, token] = useSession();
  const [showToast] = useToast();
  const [t] = useTranslation();

  const [loadQuery, {
    loading: queryLoading,
    refetch: queryRefetch,
    called: queryCalled,
    error: queryError,
  }] = useGetCurrentUserLazyQuery({
    fetchPolicy: "network-only",
  });

  const handleRefetch = useCallback(() => {
    setState((state) => ({
      ...state,
      loading: true,
    }));

    return new Promise((resolve, reject) => {
      if (!queryRefetch) {
        return;
      }

      queryRefetch()
        .then((payload) => {
          const queryCurrentUser = payload?.data?.currentUser;

          setState({
            currentUser: queryCurrentUser ?? null,
            loading: false,
          });

          resolve(queryCurrentUser);
        })
        .catch((error) => {
          showToast({
            title: t("actions.your_session_has_expired"),
            status: "info",
          });

          endSession();
          reject(error);
        });
    });
  }, [
    queryRefetch,
    endSession,
    showToast,
    t,
  ]);

  useEffect(() => {
    if (token) {
      handleRefetch();
      return;
    }

    setState({
      currentUser: null,
      loading: false,
    });
  }, [
    handleRefetch,
    token,
  ]);

  useEffect(() => {
    if (queryCalled) {
      return;
    }

    loadQuery();
  }, [
    queryCalled,
    loadQuery,
  ]);

  const contextValue = useMemo<CurrentUserContextPayload>(() => [
    currentUser,
    {
      loading: (!queryCalled || loading || queryLoading),
      refetch: handleRefetch,
      error: queryError,
    },
  ], [
    handleRefetch,
    queryLoading,
    queryCalled,
    currentUser,
    queryError,
    loading,
  ]);

  return (
    <CurrentUserProvider value={contextValue}>
      {children}
    </CurrentUserProvider>
  );
};

export default CurrentUserContainer;
