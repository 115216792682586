import React from "react";
import { Stack, Text } from "@chakra-ui/react";

import { FormTypeEnum } from "generated/graphql";
import useFormStore from "views/Forms/store";
import Image from "components/Images/Image";

/**
 * Displays the company logo and message from the form store.
 */
const FormWizardHeader: React.FC = () => {
  const form = useFormStore((store) => store.form);

  const company = form?.job?.publicCompanyInfo;

  const formType = form?.formType;

  const introductoryMessage = (
    formType
      ? (
        ({
          [FormTypeEnum.ShortApplication]: null,
          [FormTypeEnum.Application]: form?.applicantIntroductoryMessage,
          [FormTypeEnum.Client]: form?.clientIntroductoryMessage,
        })[formType]
      )
      : null
  );

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      p={6}
    >
      {
        company?.logoUrl
          ? (
            <Image
              src={company?.logoUrl}
              alt={company?.name}
              height="80px"
              width="auto"
            />
          )
          : (
            <Text
              fontSize={["md", "lg", "xl"]}
              fontFamily="heading"
              textAlign="center"
              color="black"
            >
              {company?.name}
            </Text>
          )
      }

      {
        introductoryMessage && (
          <Text
            whiteSpace="break-spaces"
            fontSize={["sm", "md"]}
            textAlign="center"
            color="gray.400"
          >
            {introductoryMessage}
          </Text>
        )
      }
    </Stack>
  );
};

export default FormWizardHeader;
