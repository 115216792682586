import React from "react";
import { matchPath, useHistory } from "react-router-dom";

import appRoutes from "routes/appRoutes";
import { useCurrentUser } from "contexts/currentUser";
import useHeaderUser from "hooks/useHeaderUser";
import {
  LOGIN_PAGE_PATH,
  NOT_MATCHED,
} from "routes";

const RouteSessionValidator: React.FC = ({
  children,
}) => {
  const history = useHistory();

  const [currentUser, {
    loading: loadingCurrentUser,
  }] = useCurrentUser();

  const { userAsMember } = useHeaderUser();

  const pathname = history?.location?.pathname ?? "";

  const appRoute = appRoutes.find((route) => matchPath(pathname, route));

  const isInvalidRoute = !!(
    appRoute?.requireAuthentication
    && !loadingCurrentUser
    && !currentUser
  );

  if (appRoute?.requireAuthentication && loadingCurrentUser) {
    return null;
  }

  if (isInvalidRoute) {
    window.location.href = LOGIN_PAGE_PATH;
    return null;
  }

  const isLoggedUserWithNoMember = !!(
    userAsMember !== undefined
    && !userAsMember
    && currentUser
  );

  if (
    isLoggedUserWithNoMember
    && pathname !== NOT_MATCHED
  ) {
    window.location.href = NOT_MATCHED;
  }

  return (
    <>
      {children}
    </>
  );
};

export default RouteSessionValidator;
