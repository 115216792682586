import React from "react";
import * as R from "remeda";

import RemoveItemButton from "components/Forms/CompanyOnboardingForm/Cards/QuestionCard/RemoveItemButton";
import { QuestionCardField } from "components/Forms/CompanyOnboardingForm/Cards/QuestionCard/types";
import i18n from "translations/i18n";

import { MakeFieldsOptions } from "./types";

const baseOptionFields: QuestionCardField[] = [
  {
    label: {
      text: i18n.t("company_onboarding.steps.source.question_1.field_1"),
    },
    value: {
      text: i18n.t("question_display_types.required_by_default"),
    },
  },
];

/**
 * Creates the fields to be rendered in source form.
 * @param param0 Options.
 */
export const makeOptionFields = ({
  removeItem,
  referrerOptions = [],
}: MakeFieldsOptions): QuestionCardField[] => [
  ...baseOptionFields,
  ...R.pipe(
    referrerOptions,
    R.map((referrerOption): QuestionCardField => ({
      label: {
        text: referrerOption,
      },
      value: {
        render: ({
          fieldIndex,
        }) => (
          <RemoveItemButton
            removeItem={removeItem}
            item={referrerOption}
            fieldIndex={fieldIndex}
          />
        ),
      },
    })),
  ),
];

export const textFields: QuestionCardField[] = [
  {
    label: {
      text: i18n.t("company_onboarding.steps.source.question_2.field_1"),
    },
    value: {
      text: i18n.t("question_display_types.optional_by_default"),
    },
  },
];
