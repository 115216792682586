import { TEXT_AREA_MEDIUM_LENGTH, TEXT_INPUT_LENGTH } from "constants/forms";
import yup from "settings/yup";

const landingPageSchema = yup.object().shape({
  jobDescription: yup
    .string()
    .required(),
  buttonText: yup
    .string()
    .required()
    .max(TEXT_INPUT_LENGTH),
  subtitle: yup
    .string()
    .required()
    .max(TEXT_AREA_MEDIUM_LENGTH),
  color: yup
    .string()
    .required(),
  title: yup
    .string()
    .required()
    .max(TEXT_INPUT_LENGTH),
  headerImage: yup
    .mixed(),
  logo: yup
    .mixed()
    .required(),
});

export interface LandingPageSchema {
  jobDescription?: string;
  buttonText?: string;
  subtitle?: string;
  color?: string;
  title?: string;
  headerImage?: File;
  logo?: File;
}

export default landingPageSchema;
