import React from "react";
import { useTranslation } from "react-i18next";
import { Stack } from "@chakra-ui/react";

import SelectLandingPageTemplateForm from "components/Forms/SelectLandingPageTemplateForm";
import FieldTitle from "components/FormComponents/FieldTitle";
import PageHeader from "components/PageHeaders/PageHeader";
import Select from "components/FormComponents/Select";
import Loading from "components/Loading";

import useEditLandingPageTemplateForm from "./hooks/useEditLandingPageTemplateForm";

const EditLandingPageTemplateForm: React.FC = () => {
  const [t] = useTranslation();

  const {
    onTemplateChange,
    onTemplateDelete,
    setSelectedJob,
    onEditTemplate,
    selectOptions,
    layoutLabel,
    isLoading,
  } = useEditLandingPageTemplateForm();

  if (isLoading) {
    return (
      <Loading />
    );
  }

  return (
    <Stack spacing={6}>
      <PageHeader
        title={t("forms.edit_landing_page_template_form.title")}
        childrenContainerProps={{
          alignItems: "center",
          w: "xs",
        }}
      >
        <FieldTitle
          whiteSpace="nowrap"
          fontSize="xs"
          mb={0}
        >
          {`${t("forms.edit_landing_page_template_form.select_role")}:`}
        </FieldTitle>

        <Select
          defaultValue={selectOptions?.[0]}
          onChange={setSelectedJob}
          options={selectOptions}
        />
      </PageHeader>

      <SelectLandingPageTemplateForm
        onTemplateDelete={onTemplateDelete}
        onEditTemplate={onEditTemplate}
        onChange={onTemplateChange}
        layoutLabel={layoutLabel}
        showEditButton
      />
    </Stack>
  );
};

export default EditLandingPageTemplateForm;
