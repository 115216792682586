/**
 * Defines the maximum value for a request to upload files, in MB.
 */
export const UPLOAD_REQUEST_MAXIMUM_SIZE_MB = 20;

/**
 * Defines valid file types for image upload.
 */
export const FILE_TYPE_IMAGE = "image/*";

/**
 * Defines valid file types for document upload.
 */
export const FILE_TYPE_DOCUMENTS = [
  ".docx",
  ".doc",
  ".pdf",
];
