import React, { useCallback } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import { useTranslation } from "react-i18next";
import * as R from "remeda";

import sendAssessmentSchema, { SendAssessmentSchema, SendAssessmentTypeEnum } from "settings/yup/schemas/assessment/sendAssessmentSchema";
import { onMutationSuccess, onQueryError } from "utils/queryHandlers";
import { Job, useShareFormLinkMutation } from "generated/graphql";
import useFormLink from "hooks/useFormLink";
import useToast from "hooks/useToast";
import useShare from "hooks/useShare";

import { SendAssessmentModalProps } from "./types";
import ModalFooter from "./ModalFooter";
import ModalBody from "./ModalBody";

const SendAssessmentModal: React.FC<SendAssessmentModalProps> = ({
  componentProps: {
    formType,
    roles,
    jobs,
  },
  hideModal,
}) => {
  const [showToast] = useToast();
  const [t] = useTranslation();
  const share = useShare();

  const [getFormLink, getFormLinkLoading] = useFormLink();

  const [shareFormLink, {
    loading: shareFormLinkLoading,
  }] = useShareFormLinkMutation();

  const form = useForm<SendAssessmentSchema>({
    resolver: yupResolver(sendAssessmentSchema),
    mode: "onChange",
    defaultValues: {
      type: SendAssessmentTypeEnum.Email,
      targets: [{
        value: "",
      }],
    },
  });

  const {
    getValues,
    register,
  } = form;

  register({
    name: "type",
    value: SendAssessmentTypeEnum.Email,
  });

  const findJob = useCallback((): Job | undefined => {
    const values = getValues();

    const job = jobs.find((jobItem) => jobItem.role.id === values.role?.value);

    return job;
  }, [
    getValues,
    jobs,
  ]);

  const copyLink = useCallback(async () => {
    const job = findJob();

    if (!job) {
      return;
    }

    const formLink = await getFormLink({
      formId: job.form.id,
      formType,
    });

    if (!formLink?.url) {
      return;
    }

    share({
      title: job.role.name,
      url: formLink.url,
    });
  }, [
    getFormLink,
    formType,
    share,
    findJob,
  ]);

  const sendAssessment = useCallback(async () => {
    const values = getValues();

    const job = findJob();

    if (!values.type || !job) {
      return;
    }

    const formLink = await getFormLink({
      formId: job.form.id,
      formType,
    });

    if (!formLink?.shortcode) {
      return;
    }

    const targets = R.pipe(
      values.targets,
      R.map((target) => target.value),
      R.filter((value) => !!value),
    );

    const payload = ({
      [SendAssessmentTypeEnum.Email]: {
        emails: targets,
      },
    })[values.type];

    shareFormLink({
      variables: {
        shortcode: formLink.shortcode,
        ...payload,
      },
    })
      .then(() => {
        onMutationSuccess(t("send_assessment.assessment_sent"), showToast);
      })
      .catch((error) => {
        onQueryError(error, showToast);
      });

    hideModal();
  }, [
    shareFormLink,
    getFormLink,
    getValues,
    hideModal,
    showToast,
    formType,
    t,
    findJob,
  ]);

  return (
    <FormProvider {...form}>
      <ModalBody
        formType={formType}
        roles={roles}
      />

      <ModalFooter
        sendAssessmentLoading={shareFormLinkLoading}
        copyLinkLoading={getFormLinkLoading}
        sendAssessment={sendAssessment}
        copyLink={copyLink}
      />
    </FormProvider>
  );
};

export default SendAssessmentModal;
