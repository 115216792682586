import { gql } from "@apollo/client";

export const cancelSubscriptionRefetchQueries = [
  "ListCurrentCompanySubscriptions",
  "GetJob",
];

export default gql`
  mutation CancelSubscription ($id: Int!) {
    cancelSubscription (id: $id) {
      id
      endAt
    }
  }
`;
