import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

import {
  useGetCurrentUserCompanyQuery,
  useCreateJobMutation,
} from "generated/graphql";
import { onQueryError } from "utils/queryHandlers";
import { useModal } from "contexts/modal";
import Button from "components/Button";
import useToast from "hooks/useToast";

import CustomizeCompanyModal from "./CustomizeCompanyModal";
import useSelectRoleStore from "./store";

const SelectRoleHeader: React.FC = () => {
  const [showModal] = useModal();
  const [showToast] = useToast();
  const [t] = useTranslation();

  const roleId = useSelectRoleStore((store) => store.roleId);

  const [createJob, {
    loading: createJobLoading,
  }] = useCreateJobMutation();

  const {
    loading,
    data,
  } = useGetCurrentUserCompanyQuery();

  const companyId = data?.currentUser?.member?.company?.id;

  const handleOnClick = useCallback(() => {
    createJob({
      variables: {
        params: {
          companyId: companyId as number,
          roleId: roleId as number,
        },
      },
    })
      .then(() => {
        showModal({
          title: t("actions.before_we_continue"),
          component: CustomizeCompanyModal,
          isCloseable: false,
        });
      })
      .catch((error) => {
        onQueryError(error, showToast);
      });
  }, [
    createJob,
    showModal,
    showToast,
    companyId,
    roleId,
    t,
  ]);

  const isButtonDisabled = (
    loading
    || !roleId
  );

  return (
    <Button
      isDisabled={isButtonDisabled}
      isLoading={createJobLoading}
      onClick={handleOnClick}
      fontSize="sm"
      size="sm"
    >
      {t("buttons.save_and_next")}
    </Button>
  );
};

export default SelectRoleHeader;
