import { WrapItemProps, WrapItem as ChakraWrapItem } from "@chakra-ui/react";
import React from "react";

const WrapItem: React.FC<WrapItemProps> = ({
  children,
  ...props
}) => (
  <ChakraWrapItem
    flex={1}
    {...props}
  >
    {children}
  </ChakraWrapItem>
);

export default WrapItem;
