import * as React from "react";
import { IconProps } from "@chakra-ui/icons";
import { createIcon } from "@chakra-ui/react";

const PlusIcon: React.FC<IconProps> = createIcon({
  path: (
    <path
      fill="currentColor"
      d="M19,13H13v6H11V13H5V11h6V5h2v6h6Z"
    />
  ),
});

export default PlusIcon;
