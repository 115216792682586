import React from "react";

import { ListCardContent } from "components/Tracking/Cards/CardComponents";
import { ApplicantCardProps } from "components/Tracking/Cards/types";

import useApplicantCard from ".";

const ApplicantListCard: React.FC<ApplicantCardProps> = ({
  showTooltip = false,
  applicant,
  ...props
}) => {
  const cardProps = useApplicantCard(applicant, showTooltip);

  return (
    <ListCardContent
      {...cardProps}
      {...props}
    />
  );
};

export default ApplicantListCard;
