import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Text } from "@chakra-ui/react";

import useAdminImpersonationStore from "components/AdminImpersonation/store";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import { useModal } from "contexts/modal";

import { UseValidateCurrentCompanyActionResult } from "./types";

/**
 * Hook that receives a function as the argument and only executes it if the current user
 * logged in is a normal user or an admin that is not impersonating any company.
 *
 * If the current user is an admin and there is a company being impersonated, the action
 * will not be executed and an error modal will be shown, explaining that this action is
 * blocked while impersonating any company.
 */
const useValidateCurrentCompanyAction = (): UseValidateCurrentCompanyActionResult => {
  const [showModal] = useModal();
  const [t] = useTranslation();

  const companyId = useAdminImpersonationStore((store) => store.companyId);

  const triggerAction = useCallback((action) => {
    if (companyId) {
      showModal({
        size: "xl",
        title: t("admin_impersonator_action_blocked_modal.title"),
        component: ConfirmationModal,
        componentProps: {
          confirmText: t("buttons.ok"),
          showDeclineButton: false,
          text: (
            <>
              <Text fontSize="xs">
                {t("admin_impersonator_action_blocked_modal.you_cannot_execute_that_action")}
              </Text>

              <Text fontSize="xs">
                {t("admin_impersonator_action_blocked_modal.please_deselect_the_company")}
              </Text>
            </>
          ),
        },
      });

      return;
    }

    action();
  }, [
    showModal,
    companyId,
    t,
  ]);

  const payload = useMemo<UseValidateCurrentCompanyActionResult>(() => [
    triggerAction,
  ], [
    triggerAction,
  ]);

  return payload;
};

export default useValidateCurrentCompanyAction;
