import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  ModalFooter as ChakraModalFooter,
  Spinner,
  Stack,
  Text,
} from "@chakra-ui/react";

import TextLink from "components/TextLink";
import Button from "components/Button";

import { ModalFooterProps } from "./types";

const ModalFooter: React.FC<ModalFooterProps> = ({
  sendAssessmentLoading,
  copyLinkLoading,
  sendAssessment,
  copyLink,
}) => {
  const [t] = useTranslation();

  const { formState } = useFormContext();

  return (
    <ChakraModalFooter>
      <Stack
        justifyContent="center"
        alignItems="center"
        w="full"
      >
        <Button
          isLoading={sendAssessmentLoading || copyLinkLoading}
          isDisabled={!formState.isValid}
          onClick={sendAssessment}
          fontSize="sm"
          w="full"
        >
          {t("buttons.send")}
        </Button>

        <Stack isInline>
          {
            copyLinkLoading
              ? (
                <Spinner
                  size="md"
                  alignSelf="center"
                  color="primary.200"
                />
              )
              : (
                <>
                  <Text
                    color="gray.400"
                    fontSize="xs"
                  >
                    {t("buttons.or")}
                  </Text>

                  <TextLink
                    onClick={copyLink}
                    color="primary.200"
                    fontFamily="bold"
                    fontSize="xs"
                  >
                    {t("buttons.copy_link")}
                  </TextLink>
                </>
              )
          }
        </Stack>
      </Stack>
    </ChakraModalFooter>
  );
};

export default ModalFooter;
