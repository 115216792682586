import { useEffect, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  ClientStatusEnum,
  UpdateClientParams,
  useUpdateClientMutation,
} from "generated/graphql";
import { UseClientUpdateHandlerOptions } from "components/Tracking/ClientStatusCard/types";
import useValidateCurrentCompanyAction from "hooks/useValidateCurrentCompanyAction";
import { onMutationSuccess, onQueryError } from "utils/queryHandlers";
import getSelectValue from "utils/getSelectValue";
import useToast from "hooks/useToast";

const refetchQueries = [
  "GetClient",
];

/**
 * Hooks that exposes the logic to update a client based on the form state.
 */
const useClientUpdateHandler = ({
  formState,
  client,
  reset,
  watch,
}: UseClientUpdateHandlerOptions): void => {
  const [shouldUpdate, setShouldUpdate] = useState(true);
  const [updateClient] = useUpdateClientMutation({
    refetchQueries,
  });

  const [showToast] = useToast();
  const [t] = useTranslation();

  const [validateCurrentCompanyAction] = useValidateCurrentCompanyAction();

  const status = getSelectValue(watch("status")) as string;

  const handleUpdateClient = useCallback((payload: Partial<UpdateClientParams>) => {
    if (!client?.id) {
      return;
    }

    setShouldUpdate(false);

    validateCurrentCompanyAction(() => {
      updateClient({
        variables: {
          id: client.id,
          params: payload,
        },
      })
        .then(() => {
          onMutationSuccess(t("actions.information_updated"), showToast);

          reset({
            status,
          });
        })
        .catch((error) => {
          onQueryError(error, showToast);
        })
        .finally(() => {
          setShouldUpdate(true);
        });
    });
  }, [
    validateCurrentCompanyAction,
    updateClient,
    client,
    showToast,
    status,
    reset,
    t,
  ]);

  /**
   * When the value of status changes, we need to update the client.
   */
  useEffect(() => {
    const canUpdate = !!(
      (status && formState?.dirtyFields?.status)
    );

    if (!canUpdate || !shouldUpdate) {
      return;
    }

    handleUpdateClient({
      status: status as ClientStatusEnum,
    });
  },
  [
    handleUpdateClient,
    shouldUpdate,
    formState,
    status,
  ]);
};

export default useClientUpdateHandler;
