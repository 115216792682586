import * as R from "remeda";

import { PersonalStorySchema } from "settings/yup/schemas/form/personalStorySchema";
import { ApplicantResponse, Form, FormTypeEnum } from "generated/graphql";
import { TEXT_AREA_LENGTH } from "constants/forms";
import i18n from "translations/i18n";

import { OpenAnswer } from "./types";

/**
 * Maps the intro text to be rendered according to a form type.
 * @param form The form.
 */
export const getIntroText = (form: Form): string => ({
  [FormTypeEnum.ShortApplication]: form.applicantOpenQuestionsIntro,
  [FormTypeEnum.Application]: form.applicantOpenQuestionsIntro,
  [FormTypeEnum.Client]: form.clientOpenQuestionsIntro,
})[form.formType];

/**
 * Maps the questions to be rendered according to a form type.
 * @param form The form.
 */
export const getQuestions = (form: Form): string[] => ({
  [FormTypeEnum.ShortApplication]: form.job?.role?.applicantOpenQuestions ?? [],
  [FormTypeEnum.Application]: form.job?.role?.applicantOpenQuestions ?? [],
  [FormTypeEnum.Client]: form.job?.role?.clientOpenQuestions ?? [],
})[form.formType];

/**
 * Parses a group of open answers.
 */
export const parseOpenAnswers = (
  openAnswers?: Record<string, unknown>[],
): OpenAnswer[] => R.pipe(
  openAnswers ?? [],
  R.map((openAnswer: Record<string, unknown>): OpenAnswer => ({
    question: openAnswer?.question as string,
    answer: openAnswer?.answer as string,
  })),
);

/**
 * Parses form default values.
 */
export const getDefaultValues = (
  questionTitles: string[],
  formResponse?: ApplicantResponse,
): PersonalStorySchema => {
  const openAnswers = parseOpenAnswers(formResponse?.openAnswers);

  return {
    questions: R.pipe(
      questionTitles ?? [],
      R.map((questionTitle) => {
        const question = openAnswers.find((item) => item.question === questionTitle);

        return {
          label: questionTitle,
          value: question?.answer ?? "",
        };
      }),
    ),
  };
};

/**
 * Parses a initial state for UploadBox component for a given response and file index.
 * @param file The picture file parsed from the previous response.
 */
export const getUploadBoxInitialFiles = (
  file: File | undefined,
): File[] | undefined => (
  file
    ? [file]
    : undefined
);

/**
 * Parses the mutation value for personal story step.
 * @param values The form values.
 */
export const parseMutationValues = (
  values: PersonalStorySchema,
): Record<string, unknown> => {
  const openAnswers = R.pipe(
    values?.questions ?? [],
    R.map((question) => ({
      question: question.label,
      answer: question.value || undefined,
    })),
    R.map(JSON.stringify),
  );

  const pictures = R.pipe(
    [
      values.firstImage,
      values.secondImage,
    ],
    R.filter((value) => !!value),
  );

  return {
    openAnswers,
    pictures,
  };
};

/**
 * Parses the validation message of one personal story question.
 * @param value The field value.
 */
export const getValidationMessage = (value: string): string | undefined => {
  if (!value || value.length > TEXT_AREA_LENGTH) {
    return undefined;
  }

  return i18n.t("forms.steps.personal_story.tell_us_a_little_more");
};
