import React, { useCallback } from "react";
import { yupResolver } from "@hookform/resolvers";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import {
  ModalFooter,
  ModalBody,
  Stack,
} from "@chakra-ui/react";

import memberInformationSchema, { MemberInformationSchema } from "settings/yup/schemas/memberInformationSchema";
import { MemberInformationFormModalProps } from "views/Dashboard/DashboardContent/Pages/Team/types";
import { memberOccupationOptions } from "constants/memberOccupations";
import PhoneInput from "components/FormComponents/PhoneInput";
import Select from "components/FormComponents/Select";
import Input from "components/FormComponents/Input";
import Button from "components/Button";

const MemberInformationFormModal: React.FC<MemberInformationFormModalProps> = ({
  hideModal,
  componentProps: {
    isEmailDisabled = false,
    initialValues,
    isLoading,
    onFinish,
  },
}) => {
  const [t] = useTranslation();

  const {
    getValues,
    control,
    errors,
    trigger,
  } = useForm<MemberInformationSchema>({
    resolver: yupResolver(memberInformationSchema),
    mode: "onChange",
  });

  const handleOnFinish = useCallback(async () => {
    const isValid = await trigger();
    if (!isValid) {
      return;
    }
    const formValues = getValues();

    if (onFinish) {
      onFinish(formValues);
    }

    hideModal();
  }, [
    hideModal,
    onFinish,
    getValues,
    trigger,
  ]);

  return (
    <>
      <ModalBody>
        <Stack
          spacing={3}
          my={3}
        >
          <Controller
            name="name"
            as={Input}
            control={control}
            errors={errors}
            defaultValue={initialValues?.name}
            title={t("dashboard.team.table.full_name")}
          />

          <Controller
            name="invitedEmail"
            as={Input}
            control={control}
            errors={errors}
            isDisabled={isEmailDisabled}
            defaultValue={initialValues?.invitedEmail}
            title={t("dashboard.team.table.email_address")}
          />

          <Stack isInline spacing={3}>
            <Controller
              name="phoneNumber"
              as={PhoneInput}
              control={control}
              errors={errors}
              defaultValue={initialValues?.phoneNumber}
              title={t("dashboard.team.table.phone_number")}
            />

            <Controller
              name="occupation"
              as={Select}
              control={control}
              errors={errors}
              options={memberOccupationOptions}
              defaultValue={initialValues?.occupation}
              title={t("dashboard.team.table.occupation")}
            />
          </Stack>
        </Stack>
      </ModalBody>

      <ModalFooter>
        <Button
          isLoading={isLoading}
          onClick={handleOnFinish}
          fontSize="sm"
          w="full"
        >
          {t("buttons.save_changes")}
        </Button>
      </ModalFooter>
    </>
  );
};

export default MemberInformationFormModal;
