import React from "react";
import "chartjs-plugin-datalabels";
import { QueryParamProvider } from "use-query-params";
import { Router, Route } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import { ChakraProvider } from "@chakra-ui/react";

import CurrentUserContainer from "contexts/currentUser/CurrentUserContainer";
import ModalContainer from "contexts/modal/ModalContainer";
import BackgroundBox from "components/Box/BackgroundBox";
import ErrorBoundary from "components/ErrorBoundary";
import apolloClient from "settings/apolloClient";
import AppRouter from "components/AppRouter";
import history from "settings/history";
import theme from "settings/theme";

const App: React.FC = () => (
  <ErrorBoundary>
    <ChakraProvider resetCSS theme={theme}>
      <BackgroundBox>
        <ApolloProvider client={apolloClient}>
          <Router history={history}>
            <QueryParamProvider ReactRouterRoute={Route}>
              <CurrentUserContainer>
                <ModalContainer>
                  <AppRouter />
                </ModalContainer>
              </CurrentUserContainer>
            </QueryParamProvider>
          </Router>
        </ApolloProvider>
      </BackgroundBox>
    </ChakraProvider>
  </ErrorBoundary>
);

export default App;
