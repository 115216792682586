import React from "react";

import PersonalInformation from "components/Forms/CompanyOnboardingForm/FormComponents/PersonalInformation";
import PersonalStory from "components/Forms/CompanyOnboardingForm/FormComponents/PersonalStory";
import Availability from "components/Forms/CompanyOnboardingForm/FormComponents/Availability";
import Requirements from "components/Forms/CompanyOnboardingForm/FormComponents/Requirements";
import ExtraSection from "components/Forms/CompanyOnboardingForm/FormComponents/ExtraSection";
import Introduction from "components/Forms/CompanyOnboardingForm/FormComponents/Introduction";
import RespondedBy from "components/Forms/CompanyOnboardingForm/FormComponents/RespondedBy";
import ThankYou from "components/Forms/CompanyOnboardingForm/FormComponents/ThankYou";
import Resume from "components/Forms/CompanyOnboardingForm/FormComponents/Resume";
import Source from "components/Forms/CompanyOnboardingForm/FormComponents/Source";
import { extraStepIdPrefix, FormStepId } from "constants/formSteps";
import { FormTypeEnum } from "generated/graphql";

/**
 * Maps form step components to their step ids.
 */
const getStepComponent: (formType: FormTypeEnum) => Record<FormStepId, React.FC> = (
  formType: FormTypeEnum,
) => ({
  [FormStepId.PersonalInformation]: () => <PersonalInformation formType={formType} />,
  [FormStepId.PersonalStory]: PersonalStory,
  [FormStepId.Requirements]: Requirements,
  [FormStepId.Availability]: Availability,
  [FormStepId.RespondedBy]: RespondedBy,
  [FormStepId.Resume]: Resume,
  [FormStepId.Source]: Source,
  [FormStepId.Introduction]: Introduction,
  [FormStepId.ThankYou]: ThankYou,
});

/**
 * Returns the form component to be rendered according to a given step id.
 * @param stepId The step id.
 */
const getFormComponent = (
  stepId: FormStepId | undefined,
  formType: FormTypeEnum,
): React.FC | undefined => {
  if (!stepId) {
    return undefined;
  }

  if (stepId.includes(extraStepIdPrefix)) {
    return ExtraSection;
  }

  return getStepComponent(formType)[stepId];
};

export default getFormComponent;
