import { updateFormRefetchQueries } from "graphql/mutations/updateFormMutation";
import { useUpdateFormMutation } from "generated/graphql";

import useCompanyOnboardingBaseEffects from "./useCompanyOnboardingBaseEffects";
import { UseCompanyOnboardingEffectsOptions } from "../types";
import useCompanyOnboardingFormStore from "../store";

/**
 * Handles side-effects in a company onboarding form using the `updateForm` mutation.
 */
function useCompanyOnboardingFormEffects<Schema>(
  options: UseCompanyOnboardingEffectsOptions<Schema>,
): void {
  const formId = useCompanyOnboardingFormStore((store) => store.form?.id as number);

  const updateFormMutation = useUpdateFormMutation({
    refetchQueries: updateFormRefetchQueries,
  });

  return useCompanyOnboardingBaseEffects({
    mutation: updateFormMutation,
    mutationPayload: {
      id: formId,
    },
    ...options,
  });
}

export default useCompanyOnboardingFormEffects;
