import create from "zustand";

import { SelectLandingPageStoreState } from "./types";

const useSelectLandingPageStore = create<SelectLandingPageStoreState>((set) => ({
  layoutLabel: undefined,
  setLayoutLabel: (value) => set({
    layoutLabel: value,
  }),
}));

export default useSelectLandingPageStore;
