import * as React from "react";
import { CircularProgress, Flex, Stack } from "@chakra-ui/react";
import ConditionalWrap from "conditional-wrap";
import { useDropzone } from "react-dropzone";
import { useMount } from "react-use";

import { FILE_TYPE_IMAGE, UPLOAD_REQUEST_MAXIMUM_SIZE_MB } from "constants/fileUpload";
import checkIsValidUploadRequestSize from "utils/checkIsValidUploadRequestSize";
import FieldTitle from "components/FormComponents/FieldTitle";
import { parseBase64ImageFromFiles } from "utils/base64";
import useToast from "hooks/useToast";
import i18n from "translations/i18n";

import { UploadBoxProps } from "./types";
import Preview from "./Preview";
import Box from "./Box";

const UploadBox: React.FC<UploadBoxProps> = ({
  accept = FILE_TYPE_IMAGE,
  color = "primary.300",
  initialFiles = [],
  changeImageLabel,
  defaultImageSrc,
  ensureContrast,
  boxSize = "md",
  fileLimit = 1,
  subtitleProps,
  isUploading,
  previewSrc,
  titleProps,
  subtitle,
  onUpload,
  title,
  ...props
}) => {
  const [fileSources, setFileSources] = React.useState<string[]>([]);
  const [showToast] = useToast();

  const handleSetFileSources = React.useCallback((files: File[]) => {
    parseBase64ImageFromFiles(files)
      .then(setFileSources)
      .catch(() => {
        showToast({
          status: "error",
          title: i18n.t("actions.failed_to_parse_files"),
        });
      });
  }, [
    showToast,
  ]);

  useMount(() => {
    if (initialFiles.length > 0) {
      handleSetFileSources(initialFiles);
    }
  });

  const onDrop = React.useCallback((droppedFiles: File[]) => {
    if (droppedFiles.length > fileLimit) {
      showToast({
        status: "error",
        title: i18n.t("actions.file_upload_limit", { limit: fileLimit }),
      });

      return;
    }

    const isValidRequestSize = checkIsValidUploadRequestSize(droppedFiles);

    if (!isValidRequestSize) {
      showToast({
        status: "error",
        title: i18n.t("actions.request_size_limit", {
          limit: UPLOAD_REQUEST_MAXIMUM_SIZE_MB,
        }),
      });

      return;
    }

    handleSetFileSources(droppedFiles);

    onUpload([...droppedFiles]);
  }, [
    handleSetFileSources,
    showToast,
    fileLimit,
    onUpload,
  ]);

  const {
    getInputProps,
    getRootProps,
    open,
  } = useDropzone({
    multiple: fileLimit > 1,
    disabled: isUploading,
    accept,
    onDrop,
  });

  const hasFiles = !!defaultImageSrc || fileSources.length > 0;

  return (
    <Stack spacing={1}>
      {
        title && (
          <FieldTitle {...(titleProps ?? {})}>
            {title}
          </FieldTitle>
        )
      }

      {
        subtitle && (
          <FieldTitle
            fontSize="xs"
            color="gray.400"
            fontFamily="body"
            {...(subtitleProps ?? {})}
          >
            {subtitle}
          </FieldTitle>
        )
      }

      <ConditionalWrap
        condition={!hasFiles}
        wrap={(children) => (
          <Box
            cursor={isUploading ? "progress" : "pointer"}
            boxSize={boxSize}
            {...props}
            {...getRootProps()}
          >
            {children}
          </Box>
        )}
      >
        <Flex>
          <input {...getInputProps()} />

          {
            isUploading
              ? (
                <CircularProgress color={color} />
              )
              : (
                <Preview
                  changeImageLabel={changeImageLabel}
                  defaultImageSrc={defaultImageSrc}
                  ensureContrast={ensureContrast}
                  fileSources={fileSources}
                  previewSrc={previewSrc}
                  hasFiles={hasFiles}
                  boxSize={boxSize}
                  color={color}
                  open={open}
                />
              )
          }
        </Flex>
      </ConditionalWrap>
    </Stack>
  );
};

export default UploadBox;
