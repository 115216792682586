import React, { useCallback } from "react";
import { HamburgerIcon } from "@chakra-ui/icons";
import {
  Flex,
  IconButton,
  Tooltip,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import ConfirmationModal from "components/Modal/ConfirmationModal";
import {
  useDeleteMemberMutation,
  useListImpersonatedCompanyActiveMembersQuery,
  useListCurrentCompanyActiveMembersQuery,
} from "generated/graphql";
import useToast from "hooks/useToast";
import { useModal } from "contexts/modal";
import useGetCompany from "hooks/useGetCompany";
import useSession from "hooks/useSession";
import useChangePassword from "hooks/useChangeUserPassword";
import { onMutationSuccess, onQueryError } from "utils/queryHandlers";

const END_SESSION_TIMEOUT_IN_MILISECONDS = 3000;

const AccountActions: React.FC = () => {
  const [showModal] = useModal();
  const [showToast] = useToast();
  const [t] = useTranslation();
  const [, endSession] = useSession();
  const handleChangePassword = useChangePassword();

  const [, { currentCompanyData }] = useGetCompany(
    useListCurrentCompanyActiveMembersQuery,
    useListImpersonatedCompanyActiveMembersQuery,
    {
      onError: (error) => {
        onQueryError(error, showToast);
      },
    },
  );

  const [deleteMember] = useDeleteMemberMutation();

  const currentUserMember = currentCompanyData?.currentUser?.member;

  const handleRemoveMember = useCallback(() => {
    showModal({
      size: "xl",
      title: t("my_account.delete_my_account"),
      component: ConfirmationModal,
      componentProps: {
        text: t("my_account.delete_account_confirmation"),
        onConfirm: () => {
          if (!currentUserMember?.id) {
            return;
          }

          showToast({ status: "info", title: t("my_account.deleting_account") });

          deleteMember({ variables: { id: currentUserMember.id } })
            .then(() => {
              onMutationSuccess(
                t("my_account.account_deleted_successfully"),
                showToast,
              );

              setTimeout(() => {
                endSession();
              }, END_SESSION_TIMEOUT_IN_MILISECONDS);
            })
            .catch((error) => {
              onQueryError(error, showToast);
            });
        },
      },
    });
  }, [
    deleteMember,
    showModal,
    showToast,
    currentUserMember,
    t,
    endSession,
  ]);

  return (
    <Flex justifyContent="flex-end">
      <Menu>
        <Tooltip
          aria-label={t("my_account.account_actions")}
          label={t("my_account.account_actions")}
          fontSize="xxs"
          hasArrow
        >
          <MenuButton
            as={IconButton}
            aria-label={t("my_account.account_actions")}
            icon={<HamburgerIcon color="primary.200" />}
          />
        </Tooltip>
        <MenuList>
          <MenuItem onClick={handleChangePassword}>
            {t("my_account.change_password.change_password_label")}
          </MenuItem>
          <MenuItem onClick={handleRemoveMember}>
            {t("my_account.delete_my_account")}
          </MenuItem>
        </MenuList>
      </Menu>
    </Flex>
  );
};

export default AccountActions;
