import React, { useCallback } from "react";

import { displayTypeOptions } from "constants/formEditableQuestions";
import Select from "components/FormComponents/Select";

import { SelectDisplayTypeProps } from "./types";

const SelectDisplayType: React.FC<SelectDisplayTypeProps> = ({
  handleCallback,
  value,
}) => {
  const onChange = useCallback((option) => {
    handleCallback(option.value);
  }, [
    handleCallback,
  ]);
  return (
    <Select
      options={displayTypeOptions}
      value={value}
      onChange={onChange}
    />
  );
};

export default SelectDisplayType;
