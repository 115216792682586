import React, { useCallback } from "react";
import {
  Stack,
  Text,
} from "@chakra-ui/react";

import { avoidPageBreakInsideCss } from "styles/css";

import { ListItemProps } from "./types";

/**
 * Renders one item with a title and a value.
 *
 * The value an either be:
 * - A string (rendered as text)
 * - An array of strings (rendered as list items)
 * - A custom react element (rendered as is)
 *
 * @param param0 Options.
 */
const ListItem: React.FC<ListItemProps> = ({
  containerProps,
  titleProps,
  title,
  value,
}) => {
  const renderValue = useCallback(() => {
    if (Array.isArray(value)) {
      return value.map((item) => (
        <Text
          as="li"
          fontSize="xs"
          key={item}
          pl={3}
        >
          {item}
        </Text>
      ));
    }

    if (typeof value === "string") {
      return (
        <Text fontSize="xs">
          {value}
        </Text>
      );
    }

    return value;
  }, [
    value,
  ]);

  const isValueArray = Array.isArray(value);

  return (
    <Stack
      alignItems={isValueArray ? "flex-start" : "center"}
      css={avoidPageBreakInsideCss}
      isInline={!isValueArray}
      spacing={1}
      {...(containerProps ?? {})}
    >
      <Text
        fontFamily="bold"
        fontSize="xs"
        {...(titleProps ?? {})}
      >
        {`${title}:`}
      </Text>

      {renderValue()}
    </Stack>
  );
};

export default ListItem;
