export const keys = {
  ENTER: "Enter",
};

/**
 * Handles onKeyPress in one input according to one key.
 * @param {*} onKeyPress Event to be executed
 * @param {*} key Key pressed for event to execute
 */
export const handleOnKeyPress = (
  onKeyPress: () => void,
  key = keys.ENTER,
) => (event: React.KeyboardEvent<HTMLInputElement>): void => {
  if (onKeyPress && event.key === key) {
    onKeyPress();
  }
};
