import React from "react";
import { Flex } from "@chakra-ui/react";

import { LandingPageTemplateProps } from "./types";
import JobDescriptionAside from "./JobDescriptionAside";
import TemplateBackground from "./TemplateBackground";

const Template5: React.FC<LandingPageTemplateProps> = ({
  navigateToForm,
  landingPage,
}) => (
  <Flex
    width="100vw"
    direction={["column", null, "row"]}
    justifyContent="space-between"
  >
    <TemplateBackground
      minHeight={["50vh", "100vh", "100vh"]}
      headerImageUrl={landingPage?.headerImageUrl}
      backgroundPosition="center center"
    />

    <JobDescriptionAside
      navigateToForm={navigateToForm}
      landingPage={landingPage}
    />
  </Flex>
);

export default Template5;
