import * as R from "remeda";

import {
  GetFormDefaultQuestionsStateOptions,
  GetFormDefaultValuesOptions,
  ExtraSectionQuestionSchema,
  ExtraSectionSchema,
  ExtraAnswersJson,
  AnswerJson,
} from "./types";

/**
 * Maps the default form state for `questions`.
 * If `formResponse` is provided, will merge already existing responses in default values.
 * @param param0 Options.
 */
export const getFormDefaultQuestionsState = ({
  extraSection,
  extraAnswer,
  formIndex,
}: GetFormDefaultQuestionsStateOptions): ExtraSectionQuestionSchema[] => {
  const questionAnswers = (
    extraAnswer?.answerGroups?.[formIndex as number]?.answers
    || extraAnswer?.answers
    || []
  ) as AnswerJson[];

  const defaultState = R.pipe(
    extraSection.extraQuestions ?? [],
    R.map((question) => {
      const questionAnswer = questionAnswers?.find?.((item) => item.id === question.id);

      return {
        questionId: question.id,
        value: questionAnswer?.answer || "",
      };
    }),
  );

  return defaultState;
};

/**
 * Parses the form default value according to the extra section and the responses.
 * @param param0 Options.
 */
const getFormDefaultValues = ({
  extraSection,
  formResponse,
}: GetFormDefaultValuesOptions): ExtraSectionSchema => {
  const extraAnswer = formResponse?.extraAnswers?.[extraSection.id] as ExtraAnswersJson;

  const arrayLength = (
    extraSection.isMultiple
      ? Math.max(extraAnswer?.answerGroups?.length ?? 1, extraSection.minimumNumber)
      : 1
  );

  return {
    answers: [...Array(arrayLength).keys()].map((formIndex) => ({
      questions: getFormDefaultQuestionsState({
        extraSection,
        extraAnswer,
        formIndex,
      }),
    })),
  };
};

export default getFormDefaultValues;
