import * as React from "react";
import { IconProps } from "@chakra-ui/icons";
import { createIcon } from "@chakra-ui/react";

const DeleteIcon: React.FC<IconProps> = createIcon({
  path: (
    <path
      fill="currentColor"
      d="M16,9V19H8V9h8M14.5,3h-5l-1,1H5V6H19V4H15.5ZM18,7H6V19a2.006,2.006,0,0,0,2,2h8a2.006,2.006,0,0,0,2-2Z"
    />
  ),
});

export default DeleteIcon;
