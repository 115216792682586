import React from "react";
import ConditionalWrap from "conditional-wrap";
import {
  Box as ChakraBox,
  Divider,
  Flex,
  Spinner,
  Text,
} from "@chakra-ui/react";

import { BoxProps } from "./types";

const Box: React.FC<BoxProps> = ({
  bgColor = "white",
  rounded = "lg",
  shadow = "md",
  h = "md",
  w = "md",
  loadingProps,
  wrapperProps,
  isDisabled,
  titleProps,
  isLoading,
  children,
  title,
  p = 5,
  ...rest
}) => (
  <ChakraBox
    flexDirection="column"
    bgColor={bgColor}
    rounded={rounded}
    shadow={shadow}
    display="flex"
    h={h}
    w={w}
    p={p}
    {...rest}
  >
    {
      title && (
        <>
          {
            typeof title === "string"
              ? (
                <Text
                  color={isDisabled ? "gray.300" : ""}
                  fontSize="sm"
                  mb={2}
                  {...(titleProps ?? {})}
                >
                  {title}
                </Text>
              )
              : title
          }

          <Divider borderColor="gray.100" />
        </>
      )
    }

    <ConditionalWrap
      condition={!!title}
      wrap={(content) => (
        <Flex
          flexGrow={1}
          w="full"
        >
          <Flex
            direction="column"
            w="full"
            my={6}
            {...(wrapperProps ?? {})}
          >
            {content}
          </Flex>
        </Flex>
      )}
    >
      {
        isLoading
          ? (
            <Flex
              justifyContent="center"
              color="primary.200"
              alignItems="center"
              height="full"
              width="full"
              p={6}
            >
              <Spinner
                size="md"
                {...(loadingProps ?? {})}
              />
            </Flex>
          )
          : (
            <>
              {children}
            </>
          )
      }
    </ConditionalWrap>
  </ChakraBox>
);

export default Box;
