import React from "react";
import {
  Heading,
  Stack,
  Text,
} from "@chakra-ui/react";

import NumberCircle from "components/NumberCircle";

import { WizardHeaderProps } from "./types";
import useWizardStore from "./store";

const WizardHeader: React.FC<WizardHeaderProps> = ({
  colorScheme = "primary",
  step,
}) => {
  const stepIndex = useWizardStore((store) => store.stepIndex);

  return (
    <Stack
      alignItems="center"
      spacing={4}
      isInline
    >
      <NumberCircle
        bgColor={`${colorScheme}.500`}
        value={stepIndex + 1}
      />

      <Stack spacing={1}>
        <Heading
          color={`${colorScheme}.500`}
          fontSize="md"
        >
          {step.title}
        </Heading>

        {
          step.subtitle && (
            <Text
              color="gray.400"
              fontSize="2sm"
            >
              {step.subtitle}
            </Text>
          )
        }
      </Stack>
    </Stack>
  );
};

export default WizardHeader;
