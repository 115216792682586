import { gql } from "@apollo/client";

export const createJobRefetchQueries = [
  "ListCurrentCompanySubscriptions",
];

export default gql`
  mutation CreateJob ($params: JobCreateParams!) {
    createJob(params: $params) {
      id
    }
  }
`;
