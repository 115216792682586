import React, { useCallback, useEffect, useState } from "react";
import { addYears, subYears } from "date-fns/esm";
import DatePicker from "react-datepicker";
import {
  FormControl,
  InputGroup,
} from "@chakra-ui/react";

import FieldErrorMessage from "components/FormComponents/FieldErrorMessage";
import { datePickerFormat, dateTimePickerFormat } from "utils/dateFormats";
import FieldTitle from "components/FormComponents/FieldTitle";
import getErrorMessage from "utils/getErrorMessage";

import { DatePickerInputProps } from "./types";
import DateInput from "./DateInput";
import parseDate from "./parseDate";

const DatePickerInput = React.forwardRef<HTMLInputElement, DatePickerInputProps>((
  {
    minDate = subYears(new Date(), 40),
    maxDate = addYears(new Date(), 40),
    showErrorMessage = true,
    datePickerProps,
    titleProps,
    isDisabled,
    onChange,
    errors,
    value,
    title,
    name,
  },
  ref,
) => {
  const error = getErrorMessage(name, errors);

  const [selectedDate, setSelectedDate] = useState<Date | null>(parseDate(value));

  useEffect(() => {
    setSelectedDate(parseDate(value));
  }, [value]);

  const handleOnDatePickerChange = useCallback((newValue) => {
    setSelectedDate(newValue);

    if (onChange) {
      onChange(newValue);
    }
  }, [
    onChange,
  ]);

  const dateFormat = (
    datePickerProps?.showTimeSelect
      ? dateTimePickerFormat
      : datePickerFormat
  );

  return (
    <FormControl>
      {
        title && (
          <FieldTitle
            htmlFor={name}
            {...(titleProps ?? {})}
          >
            {title}
          </FieldTitle>
        )
      }

      <InputGroup>
        <DatePicker
          {...datePickerProps}
          onChange={handleOnDatePickerChange}
          dateFormat={dateFormat}
          selected={selectedDate}
          showMonthYearDropdown
          disabled={isDisabled}
          minDate={minDate}
          maxDate={maxDate}
          customInput={(
            <DateInput
              name={name}
              ref={ref}
            />
          )}
        />
      </InputGroup>

      {
        showErrorMessage && (
          <FieldErrorMessage
            textAlign="end"
            error={error}
          />
        )
      }
    </FormControl>
  );
});

export default DatePickerInput;
