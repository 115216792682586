import { UPLOAD_REQUEST_MAXIMUM_SIZE_MB } from "constants/fileUpload";

/**
 * Defines if the total request size is valid to upload files.
 * The maximum value is defined in UPLOAD_REQUEST_MAXIMUM_SIZE_MB.
 *
 * @param {*} files The files to upload.
 */
const checkIsValidUploadRequestSize = (
  files: File[],
): boolean => {
  const totalSizeInBytes = (files || [])
    .map((file) => file?.size || 0)
    .reduce((acc, curr) => acc + curr, 0);

  const limitInKb = UPLOAD_REQUEST_MAXIMUM_SIZE_MB * 1024;
  const totalSizeInKb = totalSizeInBytes / 1024;

  return totalSizeInKb <= limitInKb;
};

export default checkIsValidUploadRequestSize;
