import * as React from "react";

import { CurrentUserContextPayload } from "./types";

const CurrentUserContext = React.createContext({} as CurrentUserContextPayload);

export const CurrentUserProvider = CurrentUserContext.Provider;

/**
 * Exposes the current user data.
 */
export const useCurrentUser = (): CurrentUserContextPayload => {
  const context = React.useContext(CurrentUserContext);

  if (!context) {
    throw new Error("useCurrentUser should be within CurrentUserProvider");
  }

  return context;
};
