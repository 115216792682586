import React, { useCallback } from "react";
import { Stack } from "@chakra-ui/react";

import useCompanyOnboardingFormStore from "components/Forms/CompanyOnboardingForm/store";
import Box from "components/Box";
import isStepEnabled from "utils/isStepEnabled";

import ToggleSectionVisibility from "./ToggleSectionVisibility";
import StepRow from "./StepRow";

const StepsCard: React.FC = () => {
  const {
    setSelectedStepIndex,
    selectedStepIndex,
    formId,
    steps,
    fixedSectionConfigurationId,
  } = useCompanyOnboardingFormStore((store) => ({
    setSelectedStepIndex: store.setSelectedStepIndex,
    selectedStepIndex: store.selectedStepIndex,
    formId: store.form?.id as number,
    fixedSectionConfigurationId: store.form?.fixedSectionsConfiguration?.id as number,
    steps: store.steps,
  }));

  const handleSetSelectedStepIndex = useCallback((index) => () => {
    setSelectedStepIndex(index);

    window.scrollTo(0, 0);
  }, [setSelectedStepIndex]);

  return (
    <Box
      overflow="hidden"
      w="full"
      h="full"
      p={0}
    >
      <Stack spacing={0}>
        {
          steps.map((step, index) => {
            const isSelected = index === selectedStepIndex;

            const isDisabled = !isStepEnabled(step);

            return (
              <Stack
                onClick={handleSetSelectedStepIndex(index)}
                bgColor={isSelected ? "gray.100" : ""}
                _hover={{ bgColor: "gray.100" }}
                _last={{ borderBottomWidth: 0 }}
                justifyContent="space-between"
                borderBottomWidth="1px"
                borderColor="gray.200"
                alignItems="center"
                cursor="pointer"
                key={step.id}
                isInline
                p={4}
              >
                <StepRow
                  isDisabled={isDisabled}
                  stepIndex={index}
                  step={step}
                />

                <ToggleSectionVisibility
                  formId={formId}
                  fixedSectionConfigurationId={fixedSectionConfigurationId}
                  step={step}
                />
              </Stack>
            );
          })
        }
      </Stack>
    </Box>
  );
};

export default StepsCard;
