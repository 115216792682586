import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { IconButton } from "@chakra-ui/react";

import { InputProps } from "components/FormComponents/Input/types";
import HidePasswordIcon from "settings/theme/icons/HidePasswordIcon";
import ShowPasswordIcon from "settings/theme/icons/ShowPasswordIcon";
import Input from "components/FormComponents/Input";

const PasswordInput = React.forwardRef<HTMLElement, InputProps>((props, ref) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const [t] = useTranslation();

  const toggleVisibility = useCallback(() => {
    setIsVisible((prev) => !prev);
  }, []);

  return (
    <Input
      {...props}
      ref={ref}
      type={isVisible ? "text" : "password"}
      rightElement={(
        <IconButton
          onClick={toggleVisibility}
          aria-label={t("buttons.toggle")}
          icon={(
            isVisible
              ? <HidePasswordIcon />
              : <ShowPasswordIcon />
          )}
        />
      )}
    />
  );
});

export default PasswordInput;
