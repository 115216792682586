import React from "react";
import { Redirect, useParams } from "react-router-dom";
import { Stack } from "@chakra-ui/react";

import ConfigureLandingPageForm from "components/Forms/ConfigureLandingPageForm";
import { EDIT_LANDING_PAGE_TEMPLATE_PAGE_PATH } from "routes";
import { useGetLandingPageQuery } from "generated/graphql";
import useFilter, { filters } from "hooks/useFilter";
import Loading from "components/Loading";

import { EditLandingPageConfigurationFormParams } from "./types";
import FormHeader from "./FormHeader";

const EditLandingPageConfigurationForm: React.FC = () => {
  const { id } = useParams<EditLandingPageConfigurationFormParams>();

  const [landingPageLayoutLabel] = useFilter<string>(filters.landingPageLayoutLabel);
  const [jobId] = useFilter<string>(filters.jobId);

  const {
    loading,
    data,
  } = useGetLandingPageQuery({
    fetchPolicy: "network-only",
    skip: !id,
    variables: {
      id: parseInt(String(id)),
    },
  });

  const landingPage = data?.getLandingPage;

  if (loading) {
    return (
      <Loading />
    );
  }

  if (!jobId) {
    return (
      <Redirect to={EDIT_LANDING_PAGE_TEMPLATE_PAGE_PATH} />
    );
  }

  return (
    <Stack spacing={6}>
      <FormHeader />

      <ConfigureLandingPageForm
        landingPageLayoutLabel={landingPageLayoutLabel}
        initialValues={landingPage}
        jobId={parseInt(jobId)}
      />
    </Stack>
  );
};

export default EditLandingPageConfigurationForm;
