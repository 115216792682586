/**
 * This function receives a string with special chars and returns a string without special chars.
 * To achieve this, we assume that special chars don't change between lowercase and uppercase.
 * Note that this assumption is not valid for languages like Chinese, Japanese, etc.
 * So use it only for languages that have differentiation from uppercase and lowercase.
 * If you need to remove special chars from Chinese sentences, for example, take a look at some
 * transliteration lib like: https://www.npmjs.com/package/unidecode.
 */
const removeSpecialChars = (string: string): string => {
  const lower = string.toLowerCase();
  const upper = string.toUpperCase();

  let result = "";
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < lower.length; ++i) {
    if (lower[i] !== upper[i] || lower[i].trim() === "") {
      result += string[i];
    }
  }

  return result;
};

export default removeSpecialChars;
