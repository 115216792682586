import React from "react";
import { useTranslation } from "react-i18next";

import { PreviewComponentProps } from "views/Forms/FormPreview/types";
import getFieldLabel from "utils/getFieldLabel";

import PreviewContent from "../PreviewComponents/PreviewContent";

const PersonalInformation: React.FC<PreviewComponentProps> = ({
  formResponse,
  isRequired,
}) => {
  const [t] = useTranslation();

  return (
    <>
      <PreviewContent title={getFieldLabel(t("forms.steps.personal_information.first_name"), isRequired)}>
        {formResponse.firstName}
      </PreviewContent>

      <PreviewContent title={getFieldLabel(t("forms.steps.personal_information.last_name"), isRequired)}>
        {formResponse.lastName}
      </PreviewContent>

      <PreviewContent title={getFieldLabel(t("forms.steps.personal_information.phone_number"), isRequired)}>
        {formResponse.phoneNumber}
      </PreviewContent>

      <PreviewContent title={getFieldLabel(t("forms.steps.personal_information.email_address"), isRequired)}>
        {formResponse.email}
      </PreviewContent>

      <PreviewContent title={getFieldLabel(t("forms.address.street_address"), isRequired)}>
        {formResponse.streetAddress}
      </PreviewContent>

      <PreviewContent title={t("forms.address.complement")}>
        {formResponse.complement}
      </PreviewContent>

      <PreviewContent title={getFieldLabel(t("forms.address.city"), isRequired)}>
        {formResponse.city}
      </PreviewContent>

      <PreviewContent title={getFieldLabel(t("forms.address.state"), isRequired)}>
        {formResponse.state}
      </PreviewContent>

      <PreviewContent title={getFieldLabel(t("forms.address.zip_code"), isRequired)}>
        {formResponse.zipCode}
      </PreviewContent>
    </>
  );
};

export default PersonalInformation;
