import React from "react";
import * as R from "remeda";

import { QuestionCardField } from "components/Forms/CompanyOnboardingForm/Cards/QuestionCard/types";
import { ExtraQuestion, ExtraQuestionTypeEnum } from "generated/graphql";
import i18n from "translations/i18n";

import ExtraQuestionDisplayType from "./ExtraQuestionDisplayType";
import { GetExtraQuestionFieldsOptions } from "./types";

const mapQuestionTypeToTranslationKey: Record<ExtraQuestionTypeEnum, string> = {
  [ExtraQuestionTypeEnum.Date]: "question_types.date_picker_index",
  [ExtraQuestionTypeEnum.Options]: "question_types.single_choice_index",
  [ExtraQuestionTypeEnum.StringBig]: "question_types.big_text_input_index",
  [ExtraQuestionTypeEnum.StringMedium]: "question_types.medium_text_input_index",
  [ExtraQuestionTypeEnum.StringSmall]: "question_types.small_text_input_index",
  [ExtraQuestionTypeEnum.Text]: "question_types.free_text_index",
};

/**
 * Parses the question card title for a given extra question.
 * @param extraQuestion The extra question.
 */
export const getExtraQuestionTitle = (
  extraQuestion: ExtraQuestion,
): string => {
  const translationKey = mapQuestionTypeToTranslationKey[extraQuestion.type];

  return i18n.t(
    [
      translationKey,
      "forms.field",
    ],
    {
      index: extraQuestion.position,
    },
  );
};

/**
 * Parses the fields to be rendered for a given extra question.
 * @param extraQuestion The extra question.
 */
export const getExtraQuestionFields = ({
  updateExtraQuestionDisplayType,
  extraQuestion,
  isDisabled,
}: GetExtraQuestionFieldsOptions): QuestionCardField[] => {
  const fields: QuestionCardField[] = [
    {
      label: {
        text: i18n.t("question_types.question", {
          question: extraQuestion.question,
        }),
      },
      value: {
        render: () => (
          <ExtraQuestionDisplayType
            updateExtraQuestionDisplayType={updateExtraQuestionDisplayType}
            extraQuestion={extraQuestion}
            isDisabled={isDisabled}
          />
        ),
      },
    },
  ];

  if (extraQuestion.type === ExtraQuestionTypeEnum.Options) {
    const options = R.pipe(
      extraQuestion.options ?? [],
      R.map((option): QuestionCardField => ({
        label: {
          text: option,
        },
        value: {
          text: i18n.t("question_display_types.show_option_by_default"),
        },
      })),
    );

    fields.push(...options);
  }

  return fields;
};
