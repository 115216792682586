import React, { useCallback, useMemo } from "react";
import { Controller, useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Stack, Wrap } from "@chakra-ui/react";
import merge from "deepmerge-json";

import { buildPersonalStorySchema, PersonalStorySchema } from "settings/yup/schemas/form/personalStorySchema";
import FieldTitle from "components/FormComponents/FieldTitle";
import Textarea from "components/FormComponents/Textarea";
import getFieldLabel from "utils/getFieldLabel";
import useFormStore from "views/Forms/store";
import UploadBox from "components/UploadBox";
import { Form } from "generated/graphql";
import useWizardStore from "components/Wizard/store";

import { ImageFieldEnum, PersonalStoryFormProps } from "./types";
import useFormStep from "../hooks/useFormStep";
import useCurrentPersonalStoryValues from "./hooks/useCurrentPersonalStoryValues";
import {
  getUploadBoxInitialFiles,
  getValidationMessage,
  parseMutationValues,
  getDefaultValues,
  getIntroText,
  getQuestions,
} from "./formValues";

const PersonalStoryForm: React.FC<PersonalStoryFormProps> = ({
  pictureFiles,
  formResponse,
  isRequired,
}) => {
  const [t] = useTranslation();

  const currentStep = useWizardStore().getCurrentStep();
  const questionPosition = currentStep?.questionNumber || 0;
  const isImageUploadStep = currentStep?.isImageUploadStep;

  const [currentStoryValues, setStoryValue] = useCurrentPersonalStoryValues();

  const form = useFormStore((store) => store.form) as Form;

  const questionTitles = useMemo(() => (
    getQuestions(form)
  ), [form]);

  const defaultValues = getDefaultValues(questionTitles, formResponse);

  if (currentStoryValues) defaultValues.questions = currentStoryValues;

  const isFieldRequired = !isImageUploadStep
    && defaultValues.questions[questionPosition].value === ""
    && isRequired;

  const {
    register,
    setValue,
    control,
    errors,
    watch,
    clearErrors,
    getValues: getFormValue,
  } = useFormStep<PersonalStorySchema>({
    schema: buildPersonalStorySchema(isFieldRequired),
    formOptions: {
      mode: "onBlur",
      defaultValues,
    },
    overrideGetValues: ({
      getValues,
    }) => () => {
      const values = getValues();
      const mergedQuestions = merge(defaultValues.questions, values.questions);
      const parsedValues = parseMutationValues({ ...values, questions: mergedQuestions });
      setStoryValue(mergedQuestions);
      return parsedValues;
    },
  });

  const onImageUpload = useCallback(
    (fieldName: ImageFieldEnum) => (files: File[]) => {
      setValue(fieldName, files?.[0]);
    },
    [setValue],
  );

  const { fields } = useFieldArray({
    name: "questions",
    control,
  });

  fields.forEach((field, index) => {
    register({
      name: `questions[${index}].label`,
      value: field.label,
    });
  });

  if (
    errors.questions
    && getFormValue().questions[questionPosition].value
  ) {
    clearErrors();
  }

  const firstImageFile = pictureFiles[0] || getFormValue("firstImage");
  const secondImageFile = pictureFiles[1] || getFormValue("secondImage");

  register({
    name: ImageFieldEnum.FirstImage,
    value: firstImageFile,
  });

  register({
    name: ImageFieldEnum.SecondImage,
    value: secondImageFile,
  });

  const firstImage = watch(ImageFieldEnum.FirstImage);

  const introText = getIntroText(form);

  const questionField = fields[questionPosition];

  const questionController = !isImageUploadStep && (
    <Controller
      name={`questions[${questionPosition}].value`}
      control={control}
      key={questionField.id}
      defaultValue={defaultValues.questions[questionPosition].value}
      render={(props) => (
        <Textarea
          {...props}
          validationMessage={getValidationMessage(props.value)}
          title={getFieldLabel(questionField.label, isRequired)}
          errors={errors}
          rows={5}
        />
      )}
    />
  );

  return (
    <Stack spacing={6}>
      <FieldTitle
        whiteSpace="break-spaces"
        color="gray.400"
        fontSize="sm"
      >
        {introText}
      </FieldTitle>

      {
        isImageUploadStep ? (
          <Stack spacing={4}>
            <FieldTitle>
              {t("forms.steps.personal_story.a_picture_is_worth_a_thousand_words")}
            </FieldTitle>

            <Wrap spacing={10}>
              <UploadBox
                initialFiles={getUploadBoxInitialFiles(firstImageFile)}
                onUpload={onImageUpload(ImageFieldEnum.FirstImage)}
                w={["xxs", null, "sm"]}
                boxSize="xxs"
              />

              {
                (firstImage || firstImageFile) && (
                  <UploadBox
                    initialFiles={getUploadBoxInitialFiles(secondImageFile)}
                    onUpload={onImageUpload(ImageFieldEnum.SecondImage)}
                    w={["xxs", null, "sm"]}
                    boxSize="xxs"
                  />
                )
              }
            </Wrap>
          </Stack>
        ) : (
          <Stack spacing={6}>
            {questionController}
          </Stack>
        )
      }
    </Stack>
  );
};

export default PersonalStoryForm;
