/**
* We created this style to prevent the checkmark from disappearing on printing.
* The icon component within Chakra-UI checkbox component loses its styles, so we
* can't pass color and other properties directly to it.
* TODO: Update this workaround in the future if this bug has been solved.
*/
export const checkBoxCss = {
  "input + span[data-checked]": {
    backgroundColor: "white !important",
    svg: {
      color: "primary.500",
      width: "16px",
      height: "16px",
    },
  },
};
