import React from "react";
import { Progress as ChakraProgress } from "@chakra-ui/react";

import { ProgressProps } from "./types";
import css from "./styles";

const Progress: React.FC<ProgressProps> = ({
  borderRadius = 10,
  value,
  ...rest
}) => (
  <ChakraProgress
    borderRadius={borderRadius}
    rounded={borderRadius}
    value={value}
    css={css}
    {...rest}
  />
);

export default Progress;
