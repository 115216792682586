import React from "react";
import { Grid } from "@chakra-ui/react";

import Loading from "components/Loading";

import useCompanyOnboardingFormStoreHandler from "./hooks/useCompanyOnboardingFormStoreHandler";
import ConfigureStepCard from "./Cards/ConfigureStepCard";
import { CompanyOnboardingFormProps } from "./types";
import useCompanyOnboardingFormStore from "./store";
import StepsCard from "./Cards/StepsCard";

/**
 * Component that handles rendering & updating a given form by id & steps.
 * @param param0 Props.
 */
const CompanyOnboardingForm: React.FC<CompanyOnboardingFormProps> = ({
  formType,
  useSteps,
  formId,
}) => {
  useCompanyOnboardingFormStoreHandler({
    formType,
    useSteps,
    formId,
  });

  const isFormLoading = useCompanyOnboardingFormStore((store) => store.isFormLoading);

  if (isFormLoading) {
    return (
      <Loading />
    );
  }

  return (
    <Grid
      gridTemplateColumns={["1fr", null, "400px 1fr"]}
      gap={5}
    >
      <StepsCard />

      <ConfigureStepCard formType={formType} />
    </Grid>
  );
};

export default CompanyOnboardingForm;
