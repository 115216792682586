import { ExtraSectionSchema } from "settings/yup/schemas/onboarding/extraSectionSchema";
import { ExtraSection } from "generated/graphql";

/**
 * Parses form default values for a given extra section.
 * @param extraSection The extra section.
 */
const getFormDefaultValues = (
  extraSection: ExtraSection,
): ExtraSectionSchema => ({
  minimumNumber: extraSection.minimumNumber || 1,
  customIntro: extraSection.customIntro ?? "",
});

export default getFormDefaultValues;
