import yup from "settings/yup";

import availabilityMatrixSchema, { AvailabilityMatrixSchema } from "../availabilityMatrixSchema";

const availabilitySchema = yup.object().shape({
  isFullTime: yup
    .boolean()
    .nullable()
    .default(null)
    .required(),
  availableAt: yup
    .date()
    .required(),
  dayAvailability: yup
    .string(),
  availability: availabilityMatrixSchema,
});

export interface AvailabilitySchema {
  isFullTime?: "0" | "1";
  availableAt: Date;
  dayAvailability?: string;
  availability: AvailabilityMatrixSchema;
}

export default availabilitySchema;
