import React, { useCallback, useEffect } from "react";
import { RadioGroup, VStack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import createTrackingFilter from "components/Tracking/Filters";
import Radio from "components/FormComponents/Radio";
import useFilter, { filters } from "hooks/useFilter";

const SortByFilter = createTrackingFilter(({
  options = [],
  setTitle,
  defaultValue,
}) => {
  const [t] = useTranslation();

  const [sortBy, setSortBy] = useFilter(filters.orderBy);

  useEffect(() => {
    setTitle(`${t("filters.sort_by")}:`);
  }, [
    setTitle,
    t,
  ]);

  useEffect(() => {
    const hasInitialValue = !!sortBy;

    if (!defaultValue || hasInitialValue) {
      return;
    }

    setSortBy(defaultValue);
  }, [
    sortBy,
    setSortBy,
    defaultValue,
  ]);

  const handleChange = useCallback((value) => {
    setSortBy(value);
  }, [setSortBy]);

  return (
    <RadioGroup
      defaultValue={defaultValue}
      onChange={handleChange}
      value={sortBy as string}
      name="sortBy"
    >
      <VStack spacing={2}>
        {
          options.map(option => (
            <Radio
              key={option?.label}
              name={option.label}
              value={option.value}
            >
              {option.label}
            </Radio>
          ))
        }
      </VStack>
    </RadioGroup>
  );
});

export default SortByFilter;
