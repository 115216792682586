/**
 * Defines animation transitions for progress bar.
 */
const css = {
  "> *": {
    transition: "all 0.5s",
  },
};

export default css;
