import { Period } from "generated/graphql";

export enum DashboardBadgeColorEnum {
  Stage1 = "#9DE5C3",
  Stage2 = "#60D09C",
  Stage3 = "#45B983",
  Stage4 = "#359669",
}

export interface DashboardComponentProps {
  selectedJobId: number;
  companyId: number;
  comparisonPeriod: Period;
  period: Period;
}

export interface DashboardPeriods {
  comparisonPeriod: Period;
  currentPeriod: Period;
}

export interface YearPeriod {
  isCurrent: boolean;
  label: string;
  value: Period;
}
