import React from "react";
import { Stack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";

import { STATE_MASK, ZIP_CODE_MASK } from "constants/masks";
import Input from "components/FormComponents/Input";
import getFieldLabel from "utils/getFieldLabel";
import i18n from "translations/i18n";

import type { AddressFormProps } from "./types";

const AddressForm: React.FC<AddressFormProps> = ({
  complementFieldTitle = i18n.t("forms.address.complement"),
  schemaAddressPath,
  isRequired,
  control,
  errors,
}) => {
  const [t] = useTranslation();

  return (
    <Stack
      borderRadius={4}
      borderWidth={1}
      spacing={6}
      p={4}
    >
      <Stack
        alignItems="flex-start"
        spacing={6}
        isInline
        d="flex"
      >
        <Controller
          as={Input}
          name={`${schemaAddressPath}.streetAddress`}
          control={control}
          errors={errors}
          title={getFieldLabel(t("forms.address.street_address"), isRequired)}
        />

        <Controller
          as={Input}
          name={`${schemaAddressPath}.complement`}
          control={control}
          errors={errors}
          title={complementFieldTitle}
        />
      </Stack>

      <Controller
        as={Input}
        name={`${schemaAddressPath}.city`}
        control={control}
        errors={errors}
        title={getFieldLabel(t("forms.address.city"), isRequired)}
      />

      <Stack
        alignItems="flex-start"
        spacing={6}
        isInline
        d="flex"
      >
        <Controller
          as={Input}
          name={`${schemaAddressPath}.state`}
          control={control}
          errors={errors}
          mask={STATE_MASK}
          title={getFieldLabel(t("forms.address.state"), isRequired)}
          textTransform="uppercase"
        />

        <Controller
          as={Input}
          name={`${schemaAddressPath}.zipCode`}
          control={control}
          errors={errors}
          mask={ZIP_CODE_MASK}
          title={getFieldLabel(t("forms.address.zip_code"), isRequired)}
          textTransform="uppercase"
        />
      </Stack>
    </Stack>
  );
};

export default AddressForm;
