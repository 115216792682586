import React from "react";
import { useTranslation } from "react-i18next";
import { IconButton } from "@chakra-ui/react";

import { InputProps } from "components/FormComponents/Input/types";
import SearchIcon from "settings/theme/icons/SearchIcon";
import Input from "components/FormComponents/Input";

const SearchInput = React.forwardRef<HTMLElement, InputProps>((props, ref) => {
  const [t] = useTranslation();

  return (
    <Input
      placeholder={t("buttons.search")}
      {...props}
      ref={ref}
      rightElement={(
        <IconButton
          aria-label={t("buttons.search")}
          icon={<SearchIcon />}
        />
      )}
    />
  );
});

export default SearchInput;
