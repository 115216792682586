import parseAvailabilities from "components/AvailabilityMatrix/parseAvailabilities";
import { AvailabilitySchema } from "settings/yup/schemas/form/availabilitySchema";
import { ApplicantResponse } from "generated/graphql";

/**
 * Parses form default values.
 */
export const getDefaultValues = (
  formResponse?: ApplicantResponse,
): AvailabilitySchema => ({
  availability: parseAvailabilities(formResponse?.availability?.days ?? []),
  dayAvailability: formResponse?.dayAvailability ?? "",
  availableAt: formResponse?.availableAt ?? "",
  isFullTime: ({
    true: "1",
    false: "0",
  })[String(formResponse?.isFullTime ?? "")] ?? "",
});
