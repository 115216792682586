import Location from "react-app-location";
import * as Yup from "yup";

import {
  SHORT_APPLICANT_FORM_PAGE_PATH,
  APPLICANT_PROFILE_PAGE_PATH,
  CLIENT_PROFILE_PAGE_PATH,
  APPLICANT_FORM_PAGE_PATH,
  CLIENT_FORM_PAGE_PATH,
  FORM_LANDING_PAGE_PATH,
  FORM_PREVIEW_PAGE_PATH,
  FORM_REDIRECT_PAGE_PATH,
  FORM_THANK_YOU_PAGE_PATH,
  EDIT_APPLICATION_FORM_PAGE_PATH,
  EDIT_SHORT_APPLICATION_FORM_PAGE_PATH,
  EDIT_QUESTIONNAIRE_FORM_PAGE_PATH,
  EDIT_LANDING_PAGE_CONFIGURATION_PAGE_PATH,
  DASHBOARD_PAGE_PATH,
  DASHBOARD_MODIFY_SUBSCRIPTION_PAGE_PATH,
} from "routes";

const buildIdSchema = (
  idName = "id",
  required = true,
): Record<string, Yup.NumberSchema> => {
  let field = Yup
    .number()
    .integer()
    .positive();

  if (required) {
    field = field.required();
  }

  return {
    [idName]: field,
  };
};

const pageSchema = {
  page: Yup
    .string(),
};

const shortCodeSchema = {
  shortCode: Yup
    .string()
    .required(),
};

const formThankYouPageSchema = {
  ...shortCodeSchema,
  formLocationType: Yup
    .string()
    .required()
    .oneOf([
      "short-applicant",
      "applicant",
      "client",
    ]),
};

export const CLIENT_PROFILE_PAGE_LOCATION = new Location(
  CLIENT_PROFILE_PAGE_PATH,
  buildIdSchema(),
);

export const APPLICANT_PROFILE_PAGE_LOCATION = new Location(
  APPLICANT_PROFILE_PAGE_PATH,
  buildIdSchema(),
);

export const APPLICANT_FORM_PAGE_LOCATION = new Location(
  APPLICANT_FORM_PAGE_PATH,
  shortCodeSchema,
);

export const SHORT_APPLICANT_FORM_PAGE_LOCATION = new Location(
  SHORT_APPLICANT_FORM_PAGE_PATH,
  shortCodeSchema,
);

export const CLIENT_FORM_PAGE_LOCATION = new Location(
  CLIENT_FORM_PAGE_PATH,
  shortCodeSchema,
);

export const FORM_LANDING_PAGE_LOCATION = new Location(
  FORM_LANDING_PAGE_PATH,
  shortCodeSchema,
);

export const FORM_PREVIEW_PAGE_LOCATION = new Location(
  FORM_PREVIEW_PAGE_PATH,
  shortCodeSchema,
);

export const FORM_REDIRECT_PAGE_LOCATION = new Location(
  FORM_REDIRECT_PAGE_PATH,
  shortCodeSchema,
);

export const EDIT_APPLICATION_FORM_PAGE_LOCATION = new Location(
  EDIT_APPLICATION_FORM_PAGE_PATH,
  buildIdSchema(),
);

export const EDIT_SHORT_APPLICATION_FORM_PAGE_LOCATION = new Location(
  EDIT_SHORT_APPLICATION_FORM_PAGE_PATH,
  buildIdSchema(),
);

export const EDIT_QUESTIONNAIRE_FORM_PAGE_LOCATION = new Location(
  EDIT_QUESTIONNAIRE_FORM_PAGE_PATH,
  buildIdSchema(),
);

export const FORM_THANK_YOU_PAGE_LOCATION = new Location(
  FORM_THANK_YOU_PAGE_PATH,
  formThankYouPageSchema,
);

export const EDIT_LANDING_PAGE_CONFIGURATION_PAGE_LOCATION = new Location(
  EDIT_LANDING_PAGE_CONFIGURATION_PAGE_PATH,
  buildIdSchema("id", false),
);

export const DASHBOARD_PAGE_LOCATION = new Location(
  DASHBOARD_PAGE_PATH,
  pageSchema,
);

export const DASHBOARD_MODIFY_SUBSCRIPTION_PAGE_LOCATION = new Location(
  DASHBOARD_MODIFY_SUBSCRIPTION_PAGE_PATH,
  buildIdSchema("jobId"),
);
