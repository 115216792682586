import {
  useCallback,
  useEffect,
  useState,
  useMemo,
} from "react";
import { useTranslation } from "react-i18next";

import useAdminImpersonationStore from "components/AdminImpersonation/store";
import { onQueryError } from "utils/queryHandlers";
import useGetCompany from "hooks/useGetCompany";
import { useModal } from "contexts/modal";
import useToast from "hooks/useToast";
import {
  useGetImpersonatedCompanyJobsQuery,
  useGetCurrentCompanyJobsQuery,
  FormTypeEnum,
} from "generated/graphql";
import getJobsAndRoles from "utils/getJobsAndRoles";

import { UseSelectJobOptions, UseSelectJobResult } from "./types";
import SelectJobModal from "./SelectJobModal";

/**
 * Exposes a function allows the user to select one job.
 *
 * When called, the function will query for the available jobs and open a modal
 * for the user to select the job.
 *
 * If the user is an admin and has selected a company to impersonate, this hook
 * will query for that company jobs instead of the current company.
 *
 * If the user confirms the selection, the hook will fire the `onConfirm` function
 * with the newly selected job.
 *
 * The query loading state is also exposed.
 *
 * In case the query fails, a toast will be shown with the error.
 */
const useSelectJob = ({
  closeModalOnConfirm = true,
  modalTitle,
  onConfirm,
  formType,
}: UseSelectJobOptions): UseSelectJobResult => {
  const [shouldFetch, setShouldFetch] = useState(false);

  const [showModal] = useModal();
  const [showToast] = useToast();
  const [t] = useTranslation();

  const impersonatedCompanyId = useAdminImpersonationStore((store) => store.companyId);

  useEffect(() => {
    setShouldFetch(false);
  }, [
    impersonatedCompanyId,
  ]);

  const handleOnQueryComplete = useCallback((data) => {
    const company = data?.getCompany
    ?? data?.currentUser?.member?.company
    ?? [];

    const { jobs, roles } = getJobsAndRoles(company);

    if (jobs.length <= 0) {
      showToast({
        title: t("errors.no_jobs_were_found"),
        status: "info",
      });

      return;
    }

    showModal({
      title: modalTitle,
      component: SelectJobModal,
      componentProps: {
        closeModalOnConfirm,
        onConfirm,
        roles,
        jobs,
        formType,
      },
    });
  }, [
    closeModalOnConfirm,
    modalTitle,
    onConfirm,
    showModal,
    showToast,
    t,
    formType,
  ]);

  const isClient = formType === FormTypeEnum.Client;

  const [, {
    refetch,
    loading,
  }] = useGetCompany(
    useGetCurrentCompanyJobsQuery,
    useGetImpersonatedCompanyJobsQuery,
    {
      variables: {
        hasClient: isClient || undefined,
        hasApplicant: isClient ? undefined : true,
      },
      skip: !shouldFetch,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
      onCompleted: handleOnQueryComplete,
      onError: (error) => {
        onQueryError(error, showToast);
      },
    },
  );

  const selectJob = useCallback(() => {
    if (!shouldFetch) {
      setShouldFetch(true);
      return;
    }

    refetch?.();
  }, [
    shouldFetch,
    refetch,
  ]);

  const payload = useMemo<UseSelectJobResult>(() => [
    selectJob,
    loading || !shouldFetch,
  ], [
    shouldFetch,
    selectJob,
    loading,
  ]);

  return payload;
};

export default useSelectJob;
