import React, { useCallback, useState } from "react";
import update from "immutability-helper";
import { Grid } from "@chakra-ui/react";

import { AvailabilityChangeHandler, AvailabilityMatrixProps } from "./types";
import parseAvailabilities from "./parseAvailabilities";
import AvailabilityRow from "./AvailabilityRow";

const AvailabilityMatrix: React.FC<AvailabilityMatrixProps> = ({
  lite = false,
  value = [],
  isDisabled,
  onChange,
}) => {
  const [availabilities, setAvailabilities] = useState(parseAvailabilities(value));

  const handleOnChange: AvailabilityChangeHandler = useCallback(
    (availability, field) => () => {
      if (isDisabled) {
        return;
      }

      const index = availabilities.findIndex((item) => item.day === availability.day);

      const newValue = update(availabilities, {
        [index]: {
          $set: {
            ...availability,
            [field]: !availability[field],
          },
        },
      });

      setAvailabilities(newValue);

      onChange?.(newValue);
    },
    [
      availabilities,
      isDisabled,
      onChange,
    ],
  );

  return (
    <Grid
      templateRows="repeat(2, 1fr)"
      bgColor="background"
      p={4}
      pt={lite ? 10 : 4}
    >
      {
        availabilities.map((availability, index) => (
          <AvailabilityRow
            firstRowWithTitles={lite && index === 0}
            handleOnChange={handleOnChange}
            availability={availability}
            isDisabled={isDisabled}
            key={availability.day}
            hideLabels={lite}
            lite={lite}
          />
        ))
      }
    </Grid>
  );
};

export default AvailabilityMatrix;
