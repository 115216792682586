import { ChartOptions } from "chart.js";
import deepMerge from "deepmerge";

import defaultChartOptions from "components/Charts/defaultChartOptions";
import theme from "settings/theme";

/**
 * Default options for spider charts.
 */
const options: ChartOptions = deepMerge(defaultChartOptions, {
  maintainAspectRatio: false,
  scales: {
    yAxes: [
      {
        ticks: {
          fontFamily: theme.fonts.heading,
          fontColor: theme.colors.black,
          beginAtZero: true,
          maxTicksLimit: 5,
          fontSize: 14,
        },
      },
    ],
    xAxes: [
      {
        gridLines: {
          display: false,
        },
        ticks: {
          fontFamily: theme.fonts.body,
          beginAtZero: true,
          fontSize: 14,
        },
      },
    ],
  },
});

export default options;
