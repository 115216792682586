import * as React from "react";
import { IconProps } from "@chakra-ui/icons";
import { createIcon } from "@chakra-ui/react";

const LiveHelpIcon: React.FC<IconProps> = createIcon({
  viewBox: "0 0 40 40",
  path: (
    <>
      <path
        d="M0,0H40V40H0Z"
        fill="none"
      />
      <path
        d="M29.667,2H6.333A3.332,3.332,0,0,0,3,5.333V28.667A3.332,3.332,0,0,0,6.333,32H13l5,5,5-5h6.667A3.343,3.343,0,0,0,33,28.667V5.333A3.343,3.343,0,0,0,29.667,2Zm0,26.667h-8.05l-.983.983L18,32.283l-2.65-2.65-.967-.967H6.333V5.333H29.667Zm-13.333-5h3.333V27H16.333ZM18,10.333a3.343,3.343,0,0,1,3.333,3.333c0,3.333-5,2.917-5,8.333h3.333c0-3.75,5-4.167,5-8.333a6.667,6.667,0,1,0-13.333,0h3.333A3.343,3.343,0,0,1,18,10.333Z"
        transform="translate(2 1.333)"
        fill="currentColor"
      />
    </>
  ),
});

export default LiveHelpIcon;
