import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  IconButton,
  Tooltip,
  Stack,
  Grid,
  Text,
} from "@chakra-ui/react";

import parseExtraSectionGroupTitle from "utils/forms/parseExtraSectionGroupTitle";
import DeleteIcon from "settings/theme/icons/DeleteIcon";

import { QuestionFormProps, ExtraSectionQuestionSchema } from "./types";
import QuestionInput from "./QuestionInput";

const QuestionForm: React.FC<QuestionFormProps> = ({
  extraSectionName,
  canRemoveForm,
  removeForm,
  fieldIndex,
  questions,
  showTitle,
  field,
}) => {
  const [t] = useTranslation();

  const { register } = useFormContext();

  const pathPrefix = `answers[${fieldIndex}]`;

  register({
    name: `${pathPrefix}.id`,
    value: field.id,
  });

  register({
    name: `${pathPrefix}.questions`,
    value: field.questions,
  });

  return (
    <Stack spacing={4}>
      {
        showTitle && (
          <Stack
            alignItems="center"
            isInline
          >
            {
              canRemoveForm && (
                <Tooltip
                  aria-label={t("buttons.remove_this_item")}
                  label={t("buttons.remove_this_item")}
                  fontSize="xxs"
                  hasArrow
                >
                  <IconButton
                    aria-label={t("buttons.remove")}
                    onClick={removeForm(fieldIndex)}
                    icon={(
                      <DeleteIcon color="primary.200" />
                    )}
                  />
                </Tooltip>
              )
            }

            <Text
              fontFamily="bold"
              fontSize="sm"
            >
              {parseExtraSectionGroupTitle(extraSectionName, fieldIndex)}
            </Text>
          </Stack>
        )
      }

      <Grid
        templateColumns={["1fr", "repeat(2, 1fr)", "repeat(4, 1fr)"]}
        gap={5}
      >
        {
          field.questions.map((
            question: ExtraSectionQuestionSchema,
            index: number,
          ) => {
            const extraQuestion = questions.find((item) => (
              item.id === question.questionId
            ));

            if (!extraQuestion) {
              return null;
            }

            return (
              <QuestionInput
                questionFormIndex={fieldIndex}
                key={question.questionId}
                question={extraQuestion}
                fieldIndex={index}
                field={question}
              />
            );
          })
        }
      </Grid>
    </Stack>
  );
};

export default QuestionForm;
