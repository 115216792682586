import React, { useCallback, useMemo, useState } from "react";
import getAverageColor from "get-average-color";
import { useMount } from "react-use";
import invert from "invert-color";
import * as R from "remeda";
import {
  Image as ChakraImage,
  Skeleton,
  Text,
  Flex,
} from "@chakra-ui/react";

import ImageFallback from "components/Images/ImageFallback";
import { ImageProps } from "components/Images/types";

import ImageDownloadIcon from "./ImageDownloadIcon";

const MAX_LOADING_TIME = 4000;

/**
 * Component that is responsible to render an image.
 *
 * Uses the `Image` component from Chakra UI, along with a fallback option to display in case the
 * image fails to load or is not available via the `src` prop.
 *
 * This component also displays a skeleton while loading the image to improve on loading UX.
 *
 * By setting the `ensureContrast` prop, it will ensure that the background color has a high
 * contrast ratio by calculating the average color from the image and inverting the bg color.
 */
const Image = React.forwardRef<HTMLImageElement, ImageProps>((
  {
    objectPosition = "center",
    ensureContrast = false,
    objectFit = "cover",
    borderRadius = 10,
    minFallbackHeight,
    showDownloadIcon,
    width = "full",
    flagColor,
    showFlag,
    flagText,
    bgColor,
    height,
    src,
    ...rest
  },
  ref,
) => {
  const [averageColor, setAverageColor] = useState<string>();
  const [isLoaded, setIsLoaded] = useState(!src);

  const onFinishLoading = useCallback(() => {
    setIsLoaded(true);
  }, []);

  useMount(() => {
    setTimeout(() => {
      setIsLoaded(true);
    }, MAX_LOADING_TIME);
  });

  useMount(() => {
    if (!src || !ensureContrast) {
      return;
    }

    getAverageColor(src)
      .then((result: string) => {
        setAverageColor(result);
      })
      .catch(R.noop);
  });

  const imageBgColor = useMemo(() => {
    if (bgColor) {
      return bgColor;
    }

    if (ensureContrast && averageColor) {
      return invert(averageColor, true);
    }

    return undefined;
  }, [
    ensureContrast,
    averageColor,
    bgColor,
  ]);

  return (
    <Flex
      justifyContent="center"
      position="relative"
      height="full"
      width="full"
    >
      <Skeleton isLoaded={isLoaded}>
        <ChakraImage
          objectPosition={objectPosition}
          borderRadius={borderRadius}
          onError={onFinishLoading}
          onLoad={onFinishLoading}
          bgColor={imageBgColor}
          rounded={borderRadius}
          objectFit={objectFit}
          height={height}
          width={width}
          src={src}
          ref={ref}
          fallback={(
            <ImageFallback
              minHeight={minFallbackHeight}
              borderRadius={borderRadius}
              rounded={borderRadius}
              height={height}
              width={width}
              src={src}
            />
          )}
          {...rest}
        />
      </Skeleton>

      {
        showFlag && (
          <Flex
            justifyContent="center"
            alignItems="center"
            position="absolute"
            bgColor={flagColor}
            height="25px"
            bottom={4}
            right={0}
            left={0}
          >
            <Text
              fontFamily="bold"
              fontSize="xs"
              color="white"
            >
              {flagText}
            </Text>
          </Flex>
        )
      }

      {
        showDownloadIcon && (
          <ImageDownloadIcon src={src} />
        )
      }
    </Flex>
  );
});

export default Image;
