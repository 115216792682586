import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Flex,
  Stack,
  HStack,
  VStack,
} from "@chakra-ui/react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";

import Input from "components/FormComponents/Input";
import { Member, useGetCurrentUserProfileQuery, useUpdateMemberMutation } from "generated/graphql";
import updateMemberSchema, { UpdateMemberSchema } from "settings/yup/schemas/updateMemberSchema";
import { handleOnKeyPress } from "utils/handleOnKeyPress";
import useLoadingDelay from "hooks/useLoadingDelay";
import useToast from "hooks/useToast";
import memberOccupations from "constants/memberOccupations";
import AvatarPreviewInput from "components/FormComponents/AvatarPreviewInput";
import FormHeader from "components/FormComponents/FormHeader";
import FormContainer from "components/FormComponents/FormContainer";
import PhoneInput from "components/FormComponents/PhoneInput";
import { updateMemberRefetchQueries } from "graphql/mutations/updateMemberMutation";

import AccountActions from "./AccountActions";

const MyAccount: React.FC = () => {
  const [t] = useTranslation();

  const [avatarFile, setAvatarFile] = useState<File>();

  const [showToast] = useToast();

  const [
    updateMember,
    { loading: loadingUpdateMemberMutation },
  ] = useUpdateMemberMutation({
    refetchQueries: updateMemberRefetchQueries,
  });

  const {
    data,
    loading: loadingCurrentUserProfile,
  } = useGetCurrentUserProfileQuery();

  const {
    handleSubmit,
    formState,
    control,
    errors,
  } = useForm<UpdateMemberSchema>({
    resolver: yupResolver(updateMemberSchema),
    mode: "onChange",
  });

  const onSubmit = useCallback((values) => {
    const currentMemberId = data?.currentUser?.member?.id;

    if (!currentMemberId) {
      return;
    }

    updateMember({
      variables: {
        id: currentMemberId,
        params: {
          ...values,
          photo: avatarFile,
        },
      },
    })
      .then(() => {
        showToast({
          status: "success",
          title: t("my_account.account_updated"),
        });
      })
      .catch((error) => {
        showToast({
          title: error?.message,
          status: "error",
        });
      });
  }, [
    t,
    avatarFile,
    showToast,
    updateMember,
    data,
  ]);

  const isButtonDisabled = (
    (
      !formState.isValid && formState.isSubmitted
    )
    || loadingCurrentUserProfile
    || loadingUpdateMemberMutation
  );

  const loadingProfileData = useLoadingDelay(loadingCurrentUserProfile || !data?.currentUser);

  const member = data?.currentUser?.member ?? {} as Member;

  return (
    <>
      <FormHeader
        title={t("my_account.title")}
        isButtonDisabled={isButtonDisabled}
        onSubmit={handleSubmit(onSubmit)}
      />

      <FormContainer isLoading={loadingProfileData}>
        <AccountActions />

        <VStack spacing={4} alignItems="center">
          <AvatarPreviewInput
            defaultPreview={member?.photo}
            onChange={setAvatarFile}
          />
        </VStack>

        <Flex width="full" paddingTop={10}>
          <Stack width="full" spacing={6}>
            <HStack
              display="flex"
              spacing={6}
              alignItems="flex-start"
            >
              <Controller
                as={Input}
                name="name"
                title={t("dashboard.team.table.full_name")}
                control={control}
                errors={errors}
                onKeyPress={handleOnKeyPress(handleSubmit(onSubmit))}
                defaultValue={member?.name}
              />

              <Input
                name="invitedEmail"
                isDisabled
                title={t("forgot_password.email_address")}
                value={data?.currentUser?.email}
              />
            </HStack>

            <HStack
              display="flex"
              spacing={6}
              alignItems="flex-start"
            >
              <Controller
                as={PhoneInput}
                name="phoneNumber"
                title={t("dashboard.team.table.phone_number")}
                control={control}
                errors={errors}
                onKeyPress={handleOnKeyPress(handleSubmit(onSubmit))}
                defaultValue={member?.phoneNumber}
              />

              <Input
                name="occupation"
                title={t("dashboard.team.table.occupation")}
                isDisabled
                value={memberOccupations[member?.occupation]?.label}
              />
            </HStack>
          </Stack>
        </Flex>
      </FormContainer>
    </>
  );
};

export default MyAccount;
