import React, { useEffect } from "react";
import {
  VStack,
  Stack,
  Text,
  Flex,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useDebounce } from "use-debounce";
import numeral from "numeral";
import * as R from "remeda";

import { ClientMatchListProps } from "components/Tracking/ClientMatchCard/types";
import { Applicant, useListMatchingApplicantsLazyQuery } from "generated/graphql";
import useFieldValueToQueryParams from "hooks/useFieldValueToQueryParams";
import AvatarListSkeleton from "components/Skeletons/AvatarListSkeleton";
import { EmptyDataMessage } from "components/EmptyDataMessage";
import { clientMatchStatuses } from "constants/statuses";
import useLoadingDelay from "hooks/useLoadingDelay";
import { filters } from "hooks/useFilter";

import ApplicantDetails from "./ApplicantDetails";

const ClientMatchList: React.FC<ClientMatchListProps> = ({
  initializeApplicantResume,
  setComparisonScores,
  onApplicantClick,
  client,
  watch,
}) => {
  const [t] = useTranslation();

  const [roleFitScore] = useDebounce(watch("roleFitScore"), 1000);
  const [search] = useDebounce(watch("search"), 1000);
  const status = watch("status");

  useFieldValueToQueryParams(filters.roleFitScore, roleFitScore);

  useFieldValueToQueryParams(filters.status, status);

  useFieldValueToQueryParams(filters.search, search);

  const [
    listMatchingApplicants,
    {
      loading: queryLoading,
      data,
    },
  ] = useListMatchingApplicantsLazyQuery({
    onCompleted: (loadedData) => (
      initializeApplicantResume(loadedData?.listApplicants as Applicant[])
    ),
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (!client?.job?.id) {
      return;
    }

    listMatchingApplicants({
      variables: {
        search,
        filters: {
          jobId: Number(client?.job?.id),
          status: clientMatchStatuses[status]?.matchTo || undefined,
          roleFitScoreMin: numeral(`${roleFitScore?.[0]}%`).value() || undefined,
          roleFitScoreMax: numeral(`${roleFitScore?.[1]}%`).value() || undefined,
        },
      },
    });
  }, [
    status,
    search,
    roleFitScore,
    client,
    listMatchingApplicants,
  ]);

  const loading = useLoadingDelay(queryLoading || !data?.listApplicants);

  const applicants = R.pipe(
    (data?.listApplicants ?? []) as Applicant[],
    R.sort((a, b) => (
      b?.assessment?.roleFitScore - a?.assessment?.roleFitScore
    )),
  );

  return (
    <Flex
      direction="column"
      flexGrow={1}
    >
      <Stack
        flexGrow={1}
        spacing={4}
        h="full"
      >
        <Text fontWeight="bold" fontSize="xs">
          {`${t("client_profile.suggested_applicants")}:`}
        </Text>

        <Flex
          position="relative"
          minHeight={150}
          flexGrow={1}
        >
          <Flex
            position="absolute"
            direction="column"
            overflow="scroll"
            overflowX="hidden"
            h="full"
            bottom={0}
            right={0}
            left={0}
            top={0}
          >
            <VStack
              w="full"
              align="start"
              spacing={4}
            >
              {
                loading
                  ? (
                    <AvatarListSkeleton count={5} />
                  )
                  : (
                    applicants.length > 0
                      ? applicants.map((applicant: Applicant) => (
                        <ApplicantDetails
                          setComparisonScores={setComparisonScores}
                          onClick={onApplicantClick(applicant)}
                          applicant={applicant}
                          key={applicant.id}
                          clickable
                        />
                      ))
                      : (
                        <Flex
                          width="full"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <EmptyDataMessage
                            message={t("empty_data_messages.no_applicants_were_found")}
                            isDataLoading={loading}
                          />
                        </Flex>
                      )
                  )
              }
            </VStack>
          </Flex>
        </Flex>
      </Stack>
    </Flex>
  );
};

export default ClientMatchList;
