import * as React from "react";
import { IconProps } from "@chakra-ui/icons";
import { createIcon } from "@chakra-ui/react";

const DownloadIcon: React.FC<IconProps> = createIcon({
  path: (
    <path
      fill="currentColor"
      d="M13,5v6h1.17L12,13.17,9.83,11H11V5h2m2-2H9V9H5l7,7,7-7H15Zm4,15H5v2H19Z"
    />
  ),
});

export default DownloadIcon;
