import React from "react";
import {
  Stack,
  Text,
} from "@chakra-ui/react";

import { BillingType } from "generated/graphql";

import { PlanPriceProps } from "../../types";

const PlanPrice: React.FC<PlanPriceProps> = ({
  selectedBillingType,
  plan,
}) => {
  const price = ({
    [BillingType.Monthly]: plan.monthlyPrice.formatted,
    [BillingType.Annual]: plan.annualPrice.formatted,
  })[selectedBillingType];

  if (plan.activeDiscount) {
    const discountedPrice = ({
      [BillingType.Monthly]: plan.activeDiscount.discountedMonthlyPrice,
      [BillingType.Annual]: plan.activeDiscount.discountedAnnualPrice,
    })[selectedBillingType];

    return (
      <Stack
        alignItems="center"
        isInline
      >
        <Text
          textDecoration="line-through"
          fontFamily="heading"
          color="gray.400"
          fontSize="xs"
          mx={1}
        >
          {price}
        </Text>

        <Text
          color="status.success"
          fontFamily="extrabold"
          whiteSpace="nowrap"
          fontSize="lg"
        >
          {discountedPrice.formatted}
        </Text>
      </Stack>
    );
  }

  return (
    <Text
      fontFamily="extrabold"
      color="primary.200"
      fontSize="lg"
    >
      {price}
    </Text>
  );
};

export default PlanPrice;
