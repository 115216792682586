import React from "react";
import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";

import useCompanyOnboardingFormEffects from "components/Forms/CompanyOnboardingForm/hooks/useCompanyOnboardingFormEffects";
import useCompanyOnboardingForm from "components/Forms/CompanyOnboardingForm/hooks/useCompanyOnboardingForm";
import useCompanyOnboardingFormStore from "components/Forms/CompanyOnboardingForm/store";
import textSchema, { TextSchema } from "settings/yup/schemas/onboarding/textSchema";
import Textarea from "components/FormComponents/Textarea";
import { FormTypeEnum } from "generated/graphql";
import Box from "components/Box";

const Introduction: React.FC = () => {
  const [t] = useTranslation();

  const {
    applicantIntroductoryMessage,
    clientIntroductoryMessage,
    formType,
  } = useCompanyOnboardingFormStore((store) => ({
    applicantIntroductoryMessage: store.form?.applicantIntroductoryMessage,
    clientIntroductoryMessage: store.form?.clientIntroductoryMessage,
    formType: store.formType,
  }));

  const form = useCompanyOnboardingForm<TextSchema>({
    schema: textSchema,
    formOptions: {
      defaultValues: {
        text: (
          formType === FormTypeEnum.Application
            ? applicantIntroductoryMessage
            : clientIntroductoryMessage
        ) ?? "",
      },
    },
  });

  useCompanyOnboardingFormEffects({
    fields: ["text"],
    form,
    overrideGetValues: ({
      getValues,
    }) => {
      const values = getValues();

      if (formType === FormTypeEnum.Application) {
        return {
          applicantIntroductoryMessage: values.text,
        };
      }

      return {
        clientIntroductoryMessage: values.text,
      };
    },
  });

  return (
    <Box
      w="full"
      h="auto"
      title={t("forms.steps.introduction.question_title")}
      wrapperProps={{
        mb: 0,
      }}
      titleProps={{
        fontFamily: "bold",
      }}
    >
      <Controller
        as={Textarea}
        name="text"
        control={form.control}
        errors={form.errors}
        rows={5}
      />
    </Box>
  );
};

export default Introduction;
