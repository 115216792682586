import { SelectOption } from "components/FormComponents/Select/types";
import { ClientStatusEnum, StatusUpdateEnum } from "generated/graphql";
import colors from "settings/theme/colors";
import i18n from "translations/i18n";

const PRIMARY_STATUS_COLOR = colors.primary["200"];

const SECONDARY_STATUS_COLOR = colors.status.error;

export type ClientMatchStatus = "ONLY_HIRED" | "ALL_CURRENT";

export interface Status {
  label: string;
  value: string;
  color: string;
}

export interface ClientMatchStatusDetails {
  value: ClientMatchStatus;
  matchTo: StatusUpdateEnum[];
  label: string;
}

/**
 * The statuses of a client
 */
export const clientStatuses: Record<ClientStatusEnum, Status> = {
  [ClientStatusEnum.Active]: {
    label: i18n.t("client_statuses.active"),
    value: ClientStatusEnum.Active,
    color: PRIMARY_STATUS_COLOR,
  },
  [ClientStatusEnum.Inactive]: {
    label: i18n.t("client_statuses.inactive"),
    value: ClientStatusEnum.Inactive,
    color: SECONDARY_STATUS_COLOR,
  },
};

/**
 * The statuses of an applicant update
 */
export const statusUpdates: Record<StatusUpdateEnum, Status> = {
  [StatusUpdateEnum.AwaitingReview]: {
    label: i18n.t("update_statuses.awaiting_review"),
    value: StatusUpdateEnum.AwaitingReview,
    color: SECONDARY_STATUS_COLOR,
  },
  [StatusUpdateEnum.Claimed]: {
    label: i18n.t("update_statuses.claimed"),
    value: StatusUpdateEnum.Claimed,
    color: PRIMARY_STATUS_COLOR,
  },
  [StatusUpdateEnum.ContactedLeftMessage]: {
    label: i18n.t("update_statuses.contacted_left_message"),
    value: StatusUpdateEnum.ContactedLeftMessage,
    color: PRIMARY_STATUS_COLOR,
  },
  [StatusUpdateEnum.InterviewScheduled]: {
    label: i18n.t("update_statuses.interview_scheduled"),
    value: StatusUpdateEnum.InterviewScheduled,
    color: PRIMARY_STATUS_COLOR,
  },
  [StatusUpdateEnum.PendingPaperwork]: {
    label: i18n.t("update_statuses.pending_paperwork"),
    value: StatusUpdateEnum.PendingPaperwork,
    color: PRIMARY_STATUS_COLOR,
  },
  [StatusUpdateEnum.InterviewCompleted]: {
    label: i18n.t("update_statuses.interview_completed"),
    value: StatusUpdateEnum.InterviewCompleted,
    color: PRIMARY_STATUS_COLOR,
  },
  [StatusUpdateEnum.OfferMade]: {
    label: i18n.t("update_statuses.offer_made"),
    value: StatusUpdateEnum.OfferMade,
    color: PRIMARY_STATUS_COLOR,
  },
  [StatusUpdateEnum.ApplicationWithdrawn]: {
    label: i18n.t("update_statuses.application_withdrawn"),
    value: StatusUpdateEnum.ApplicationWithdrawn,
    color: PRIMARY_STATUS_COLOR,
  },
  [StatusUpdateEnum.Rejected]: {
    label: i18n.t("update_statuses.rejected"),
    value: StatusUpdateEnum.Rejected,
    color: PRIMARY_STATUS_COLOR,
  },
  [StatusUpdateEnum.Hired]: {
    label: i18n.t("update_statuses.hired"),
    value: StatusUpdateEnum.Hired,
    color: PRIMARY_STATUS_COLOR,
  },
  [StatusUpdateEnum.NoShowForInterview]: {
    label: i18n.t("update_statuses.no_show_for_interview"),
    value: StatusUpdateEnum.NoShowForInterview,
    color: PRIMARY_STATUS_COLOR,
  },
  [StatusUpdateEnum.NoShowForOnboarding]: {
    label: i18n.t("update_statuses.no_show_for_onboarding"),
    value: StatusUpdateEnum.NoShowForOnboarding,
    color: PRIMARY_STATUS_COLOR,
  },
  [StatusUpdateEnum.Nonresponsive]: {
    label: i18n.t("update_statuses.nonresponsive"),
    value: StatusUpdateEnum.Nonresponsive,
    color: PRIMARY_STATUS_COLOR,
  },
  [StatusUpdateEnum.Fired]: {
    label: i18n.t("update_statuses.fired"),
    value: StatusUpdateEnum.Fired,
    color: PRIMARY_STATUS_COLOR,
  },
  [StatusUpdateEnum.ApplicationIncomplete]: {
    label: i18n.t("update_statuses.application_incomplete"),
    value: StatusUpdateEnum.ApplicationIncomplete,
    color: PRIMARY_STATUS_COLOR,
  },
  [StatusUpdateEnum.Resigned]: {
    label: i18n.t("update_statuses.resigned"),
    value: StatusUpdateEnum.Resigned,
    color: PRIMARY_STATUS_COLOR,
  },
};

/**
 * Defines the applicant update statuses to show the happened_at field date
 */
export const APPLICANT_STATUSES_UPDATES_TO_SHOW_DATE = [
  StatusUpdateEnum.Claimed,
  StatusUpdateEnum.ContactedLeftMessage,
  StatusUpdateEnum.InterviewScheduled,
  StatusUpdateEnum.PendingPaperwork,
  StatusUpdateEnum.InterviewCompleted,
  StatusUpdateEnum.OfferMade,
  StatusUpdateEnum.ApplicationWithdrawn,
  StatusUpdateEnum.Rejected,
  StatusUpdateEnum.Hired,
  StatusUpdateEnum.NoShowForInterview,
  StatusUpdateEnum.NoShowForOnboarding,
  StatusUpdateEnum.Nonresponsive,
  StatusUpdateEnum.Fired,
  StatusUpdateEnum.ApplicationIncomplete,
  StatusUpdateEnum.Resigned,
];

/**
 * Defines all possible statuses for applicants.
 */
export const APPLICANT_STATUSES = [
  statusUpdates[StatusUpdateEnum.AwaitingReview],
  statusUpdates[StatusUpdateEnum.Claimed],
  statusUpdates[StatusUpdateEnum.ContactedLeftMessage],
  statusUpdates[StatusUpdateEnum.InterviewScheduled],
  statusUpdates[StatusUpdateEnum.PendingPaperwork],
  statusUpdates[StatusUpdateEnum.InterviewCompleted],
  statusUpdates[StatusUpdateEnum.OfferMade],
  statusUpdates[StatusUpdateEnum.ApplicationWithdrawn],
  statusUpdates[StatusUpdateEnum.Rejected],
  statusUpdates[StatusUpdateEnum.Hired],
  statusUpdates[StatusUpdateEnum.NoShowForInterview],
  statusUpdates[StatusUpdateEnum.NoShowForOnboarding],
  statusUpdates[StatusUpdateEnum.Nonresponsive],
  statusUpdates[StatusUpdateEnum.Fired],
  statusUpdates[StatusUpdateEnum.ApplicationIncomplete],
  statusUpdates[StatusUpdateEnum.Resigned],
];

/**
 * Defines the statuses for applicants in progress.
 */
export const APPLICANTS_IN_PROGRESS_STATUSES = [
  statusUpdates[StatusUpdateEnum.AwaitingReview],
  statusUpdates[StatusUpdateEnum.Claimed],
  statusUpdates[StatusUpdateEnum.ContactedLeftMessage],
  statusUpdates[StatusUpdateEnum.InterviewScheduled],
  statusUpdates[StatusUpdateEnum.PendingPaperwork],
  statusUpdates[StatusUpdateEnum.InterviewCompleted],
  statusUpdates[StatusUpdateEnum.OfferMade],
];

/**
 * Defines the statuses for closed applicants.
 */
export const CLOSED_APPLICANTS_STATUSES = [
  statusUpdates[StatusUpdateEnum.ApplicationWithdrawn],
  statusUpdates[StatusUpdateEnum.ApplicationIncomplete],
  statusUpdates[StatusUpdateEnum.NoShowForInterview],
  statusUpdates[StatusUpdateEnum.NoShowForOnboarding],
  statusUpdates[StatusUpdateEnum.Nonresponsive],
  statusUpdates[StatusUpdateEnum.Rejected],
  statusUpdates[StatusUpdateEnum.Hired],
  statusUpdates[StatusUpdateEnum.Fired],
  statusUpdates[StatusUpdateEnum.Resigned],
];

/**
 * Defines the default statuses of a clients list
 */
export const CLIENTS_LIST_DEFAULT_STATUSES = [
  clientStatuses[ClientStatusEnum.Active],
];

/**
 * Defines all values for client matching statuses.
 */
export const clientMatchStatuses: Record<ClientMatchStatus, ClientMatchStatusDetails> = {
  ONLY_HIRED: {
    label: i18n.t("client_match_statuses.hired_applicants_only"),
    value: "ONLY_HIRED",
    matchTo: [
      StatusUpdateEnum.Hired,
    ],
  },
  ALL_CURRENT: {
    label: i18n.t("client_match_statuses.all_in_progress_applicants"),
    value: "ALL_CURRENT",
    matchTo: [
      StatusUpdateEnum.AwaitingReview,
      StatusUpdateEnum.Claimed,
      StatusUpdateEnum.ContactedLeftMessage,
      StatusUpdateEnum.InterviewScheduled,
      StatusUpdateEnum.OfferMade,
    ],
  },
};

const makeSelectOptions = (map: Record<string, Status | undefined>): SelectOption[] => (
  Object.keys(map).map((key) => ({
    label: map[key]?.label as string,
    value: key,
  }))
);

/**
 * Defines possible select options for applicant status updates.
 */
export const statusUpdateSelectOptions = makeSelectOptions(statusUpdates);

/**
 * Defines possible select options for client status.
 */
export const clientStatusSelectOptions = makeSelectOptions(clientStatuses);

/**
 * Defines possible select options for client status updates.
 */
export const clientStatusUpdateSelectOptions = makeSelectOptions(clientStatuses);

/**
 * Defines possible select options for applicant status updates.
 */
export const applicantsInProgressStatusSelectOptions = APPLICANTS_IN_PROGRESS_STATUSES.map(
  (status) => ({
    label: status.label,
    value: status.value,
  }),
);

/**
 * Defines possible select options for closed applicants.
 */
export const closedApplicantsStatusSelectOptions = CLOSED_APPLICANTS_STATUSES.map(
  (status) => ({
    label: status.label,
    value: status.value,
  }),
);

/**
 * Defines possible options for client matching statuses.
 */
export const clientMatchStatusesOptions = Object.keys(clientMatchStatuses).map((key) => ({
  label: clientMatchStatuses[key].label,
  value: clientMatchStatuses[key].value,
}));
