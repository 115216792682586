import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Text } from "@chakra-ui/react";

import { BillingType } from "generated/graphql";

import { ModifySubscriptionModalProps } from "../types";

const AutoRenewalConfirmationModalText: React.FC<ModifySubscriptionModalProps> = ({
  billingType,
}) => {
  const [t] = useTranslation();

  const billingPeriod = (
    billingType === BillingType.Annual
      ? t("billing_types.year")
      : t("billing_types.month")
  ).toLowerCase();

  return (
    <Trans>
      <Text fontSize="sm">
        {t("modify_subscription_modal.please_note_your_subscription_will_be_auto_renewed", {
          billingPeriod,
        })}
      </Text>
    </Trans>
  );
};

export default AutoRenewalConfirmationModalText;
