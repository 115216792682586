import { FormTypeEnum } from "generated/graphql";
import {
  SHORT_APPLICANT_FORM_PAGE_LOCATION,
  APPLICANT_FORM_PAGE_LOCATION,
  FORM_LANDING_PAGE_LOCATION,
  CLIENT_FORM_PAGE_LOCATION,
} from "routes/locations";

export interface GetFormPathOptions {
  shortCode: string;
  formType: FormTypeEnum;
}

export interface GetFormStartPathOptions extends GetFormPathOptions {
  hasLandingPage: boolean;
}

/**
 * Defines form types for use in routing.
 */
export enum FormLocationTypeEnum {
  ShortApplication = "short-applicant",
  Application = "applicant",
  Client = "client",
}

/**
 * Maps types to form location routes.
 */
const mapFormLocations = {
  [FormTypeEnum.ShortApplication]: SHORT_APPLICANT_FORM_PAGE_LOCATION,
  [FormTypeEnum.Application]: APPLICANT_FORM_PAGE_LOCATION,
  [FormTypeEnum.Client]: CLIENT_FORM_PAGE_LOCATION,
};

/**
 * Returns a form path according to its type and short code.
 * @param shortCode The form short code.
 * @param formType The form type.
 */
export const getFormPath = ({
  shortCode,
  formType,
}: GetFormPathOptions): string => {
  const location = mapFormLocations[formType];

  return location?.toUrl({
    shortCode,
  });
};

/**
 * Returns the path to start a form flow according to its type and short code.
 * May return the path to a landing page, if it exists and is valid.
 * @param shortCode The form short code.
 * @param formType The form type.
 */
export const getFormStartPath = ({
  hasLandingPage,
  shortCode,
  formType,
}: GetFormStartPathOptions): string | undefined => {
  if (hasLandingPage && formType === FormTypeEnum.Application) {
    return FORM_LANDING_PAGE_LOCATION.toUrl({
      shortCode,
    });
  }

  return getFormPath({
    shortCode,
    formType,
  });
};

/**
 * Maps one form location type to one form type.
 * @param formLocationType The form location type.
 */
export const getFormTypeFromLocationType = (
  formLocationType: FormLocationTypeEnum,
): FormTypeEnum => ({
  [FormLocationTypeEnum.ShortApplication]: FormTypeEnum.ShortApplication,
  [FormLocationTypeEnum.Application]: FormTypeEnum.Application,
  [FormLocationTypeEnum.Client]: FormTypeEnum.Client,
})[formLocationType];

/**
 * Maps one form type to one form location type.
 * @param formType The form type.
 */
export const getLocationTypeFromFormType = (
  formType: FormTypeEnum,
): FormLocationTypeEnum => ({
  [FormTypeEnum.ShortApplication]: FormLocationTypeEnum.ShortApplication,
  [FormTypeEnum.Application]: FormLocationTypeEnum.Application,
  [FormTypeEnum.Client]: FormLocationTypeEnum.Client,
})[formType];
