import React from "react";
import {
  Stack,
  Flex,
  Text,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import getTokenIndicatorColors from "utils/dashboard/getTokenIndicatorColors";

import { SubscriptionCardTitleProps } from "../types";

const SubscriptionCardTitle: React.FC<SubscriptionCardTitleProps> = ({
  currentIndex,
  job,
}) => {
  const [t] = useTranslation();

  const { bgColor: tokenIndicatorBgColor } = getTokenIndicatorColors(job.numberOfAvailableTokens);

  return (
    <Stack
      justifyContent="space-between"
      alignItems="center"
      isInline
      py={5}
      pl={5}
    >
      <Text fontSize="sm">
        {t("dashboard.subscription.role_title", {
          index: currentIndex + 1,
          name: job.role.name,
        })}
      </Text>

      <Stack
        spacing={0}
        isInline
        h={6}
      >
        <Flex
          bgColor={tokenIndicatorBgColor}
          boxShadow="sm"
          mr="-1px"
          h="full"
          w={4}
          css={{
            clipPath: "polygon(80% 0, 100% 0, 100% 100%, 80% 100%, 0% 50%)",
          }}
        />

        <Flex
          bgColor={tokenIndicatorBgColor}
          justifyContent="center"
          alignItems="center"
          boxShadow="sm"
          h="full"
          px={4}
        >
          <Text
            whiteSpace="nowrap"
            fontFamily="bold"
            fontSize="xxs"
            color="white"
          >
            {
              job.numberOfAvailableTokens === null
                ? t("dashboard.subscription.unlimited_tokens")
                : t("dashboard.subscription.tokens_left", {
                  count: job.numberOfAvailableTokens,
                })
            }
          </Text>
        </Flex>
      </Stack>
    </Stack>
  );
};

export default SubscriptionCardTitle;
