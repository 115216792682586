import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  IconButton,
  Collapse,
  Stack,
  Text,
  Box,
} from "@chakra-ui/react";

import {
  CreateTrackingFilterRender,
  TrackingFilterProps,
} from "components/Tracking/types";
import MinusIcon from "settings/theme/icons/MinusIcon";
import PlusIcon from "settings/theme/icons/PlusIcon";

/**
 * Creates a tracking filter component, automatically dealing with the collapsible layout & state.
 */
function createTrackingFilter (
  render: CreateTrackingFilterRender,
): React.FC<TrackingFilterProps> {
  const FilterComponent = React.forwardRef<HTMLDivElement, TrackingFilterProps>((
    {
      initialIsOpen = true,
      defaultValue,
      options,
      ...rest
    },
    ref,
  ) => {
    const [t] = useTranslation();

    const [isOpen, setIsOpen] = useState<boolean>(initialIsOpen);
    const [title, setTitle] = useState<string>();

    const toggleIsOpen = useCallback(() => {
      setIsOpen((prev) => !prev);
    }, []);

    return (
      <>
        <Stack
          justifyContent="space-between"
          alignItems="center"
          isInline
        >
          <Text
            fontFamily="bold"
            fontSize="xs"
          >
            {title}
          </Text>

          <IconButton
            aria-label={t("buttons.toggle")}
            onClick={toggleIsOpen}
            variant="ghost"
            icon={(
              isOpen
                ? <MinusIcon />
                : <PlusIcon />
            )}
          />
        </Stack>

        <Box mb="16px !important">
          <Collapse
            {...rest}
            in={isOpen}
            ref={ref}
            className=".chakra-dont-set-collapse"
          >
            {
              render({
                initialIsOpen,
                defaultValue,
                setIsOpen,
                setTitle,
                options,
                isOpen,
                title,
              })
            }
          </Collapse>
        </Box>
      </>
    );
  });

  return FilterComponent;
}

export default createTrackingFilter;
