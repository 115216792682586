import React, { useState, useCallback } from "react";
import { Flex, Heading, Stack } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";

import clientMatchSchema, { ClientMatchSchema } from "settings/yup/schemas/clientMatchSchema";
import CloseIcon from "settings/theme/icons/CloseIcon";
import { ClientMatchStatus } from "constants/statuses";
import useFilter, { filters } from "hooks/useFilter";
import { Applicant } from "generated/graphql";
import Button from "components/Button";
import Box from "components/Box";

import ApplicantResume from "./ClientMatchList/ApplicantResume";
import ClientMatchFilters from "./ClientMatchFilters";
import ClientMatchList from "./ClientMatchList";
import { ClientMatchCardProps } from "./types";

const ClientMatchCard: React.FC<ClientMatchCardProps> = ({
  setComparisonScores,
  client,
}) => {
  const [t] = useTranslation();

  const [selectedApplicant, setSelectedApplicant] = useState<Applicant>();

  const [applicantId, setApplicantId] = useFilter(filters.applicantId);
  const [roleFitScore] = useFilter(filters.roleFitScore);
  const [status] = useFilter(filters.status);
  const [search] = useFilter(filters.search);

  const {
    control,
    errors,
    watch,
  } = useForm<ClientMatchSchema>({
    resolver: yupResolver(clientMatchSchema),
    defaultValues: {
      status: status as ClientMatchStatus,
      search: search as string,
      roleFitScore: roleFitScore as number[],
    },
    shouldUnregister: false,
    mode: "onChange",
  });

  const handleSetSelectedApplicant = useCallback((applicant?: Applicant) => () => {
    setSelectedApplicant(applicant);
    setComparisonScores(applicant);
    setApplicantId(applicant?.id);
  }, [
    setComparisonScores,
    setApplicantId,
  ]);

  const initializeApplicantResume = useCallback((applicants?: Applicant[]) => {
    if (
      selectedApplicant
      || !applicantId
      || !applicants
    ) {
      return;
    }

    const applicant = applicants.find((item) => (
      item.id === applicantId
    ));

    setSelectedApplicant(applicant);
    setComparisonScores(applicant);
  }, [
    setComparisonScores,
    selectedApplicant,
    applicantId,
  ]);

  return (
    <Box
      position="relative"
      borderRadius={10}
      w="full"
      h="full"
    >
      <Flex
        direction="column"
        h="full"
        w="full"
      >
        <Heading fontSize="md" mb={5}>
          {
            selectedApplicant
              ? `${t("client_profile.applicant_preview")}:`
              : `${t("client_profile.match_to_applicants")}:`
          }
        </Heading>

        {
          selectedApplicant && (
            <Button
              top={2}
              right={2}
              position="absolute"
              variant="unstyled"
              onClick={handleSetSelectedApplicant(undefined)}
            >
              <CloseIcon boxSize={8} color="gray.400" />
            </Button>
          )
        }

        {
          selectedApplicant ? (
            <ApplicantResume
              applicant={selectedApplicant}
            />
          ) : (
            <Stack
              spacing={6}
              h="full"
            >
              <ClientMatchFilters
                control={control}
                errors={errors}
              />

              <ClientMatchList
                initializeApplicantResume={initializeApplicantResume}
                onApplicantClick={handleSetSelectedApplicant}
                setComparisonScores={setComparisonScores}
                client={client}
                watch={watch}
              />
            </Stack>
          )
        }
      </Flex>
    </Box>
  );
};

export default ClientMatchCard;
