import yup from "settings/yup";

const updateClientSchema = yup.object().shape({
  status: yup
    .string(),
});

export interface UpdateClientSchema {
  status: string;
}

export default updateClientSchema;
