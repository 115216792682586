import * as React from "react";
import { IconProps } from "@chakra-ui/icons";
import { createIcon } from "@chakra-ui/react";

const EditIcon: React.FC<IconProps> = createIcon({
  path: (
    <path
      fill="currentColor"
      id="Path_5732"
      data-name="Path 5732"
      d="M3,17.25V21H6.75L17.81,9.94,14.06,6.19ZM5.92,19H5v-.92l9.06-9.06.92.92ZM20.71,5.63,18.37,3.29a1,1,0,0,0-1.41,0L15.13,5.12l3.75,3.75,1.83-1.83a1,1,0,0,0,0-1.41Z"
    />
  ),
});

export default EditIcon;
