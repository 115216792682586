import React from "react";
import {
  Input, InputProps, Flex,
} from "@chakra-ui/react";

import { FILE_TYPE_IMAGE } from "constants/fileUpload";

const AvatarInput: React.FC<InputProps> = ({
  children,
  ...rest
}) => (
  <Flex
    position="relative"
    cursor="pointer"
    my={0}
  >
    {children}

    <Input
      accept={FILE_TYPE_IMAGE}
      position="absolute"
      cursor="pointer"
      name="avatar"
      fontSize={0}
      opacity={0}
      type="file"
      w="full"
      h="full"
      {...rest}
    />
  </Flex>
);

export default AvatarInput;
