import React, { useCallback, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { Flex, Stack, Text } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers";
import { useHistory } from "react-router-dom";

import registerMemberSchema, { RegisterMemberSchema } from "settings/yup/schemas/registerMemberSchema";
import PasswordInput from "components/FormComponents/Input/PasswordInput";
import { useRegisterMemberMutation } from "generated/graphql";
import { handleOnKeyPress } from "utils/handleOnKeyPress";
import Checkbox from "components/FormComponents/Checkbox";
import { LOGIN_PAGE_PATH, ROOT_PAGE_PATH } from "routes";
import Input from "components/FormComponents/Input";
import AuthBox from "components/Box/AuthBox";
import { TERMS_LINK } from "constants/links";
import useNavigate from "hooks/useNavigate";
import TextLink from "components/TextLink";
import useSession from "hooks/useSession";
import Button from "components/Button";
import useToast from "hooks/useToast";
import parseSignUpInvitationQuery from "utils/parseSignUpInvitationQuery";

const MemberSignUp: React.FC = () => {
  const [createSession] = useSession();
  const navigate = useNavigate();
  const [showToast] = useToast();
  const [t] = useTranslation();
  const history = useHistory();

  const searchQuery = history.location.search;
  const { email, token } = parseSignUpInvitationQuery(searchQuery);

  useEffect(() => {
    if (typeof token !== "string") {
      history.push(ROOT_PAGE_PATH);
    }
  }, [
    history,
    token,
  ]);

  const {
    handleSubmit,
    formState,
    register,
    control,
    errors,
  } = useForm<RegisterMemberSchema>({
    resolver: yupResolver(registerMemberSchema),
    mode: "onChange",
    defaultValues: {
      subscribedToMailingList: true,
      terms: false,
      email: typeof email === "string" ? email : "",
      confirmPassword: "",
      password: "",
    },
  });

  const [registerMember, { loading }] = useRegisterMemberMutation();

  const onSubmit = useCallback((values: RegisterMemberSchema) => {
    registerMember({
      variables: {
        subscribedToMailingList: values.subscribedToMailingList,
        password: values.password,
        email: values.email,
        token: String(token),
      },
    })
      .then((response) => {
        const loginToken = response?.data?.registerMember;

        if (loginToken) {
          createSession(loginToken, ROOT_PAGE_PATH);
        }
      })
      .catch((error) => {
        showToast({
          title: error?.message,
          status: "error",
        });
      });
  }, [
    registerMember,
    createSession,
    showToast,
    token,
  ]);

  const handleViewTermsAndConditions = useCallback((event) => {
    event?.preventDefault?.();
    event?.stopPropagation?.();

    window.open(TERMS_LINK, "_blank");
  }, []);

  const loginUrlQuery = `${LOGIN_PAGE_PATH}${searchQuery}`;

  return (
    <AuthBox title={t("sign_up.title")}>
      <Controller
        as={Input}
        control={control}
        name="email"
        errors={errors}
        isDisabled
        title={`${t("login.email")}*`}
        onKeyPress={handleOnKeyPress(handleSubmit(onSubmit))}
      />

      <Controller
        as={PasswordInput}
        control={control}
        name="password"
        errors={errors}
        title={`${t("login.password")}*`}
        onKeyPress={handleOnKeyPress(handleSubmit(onSubmit))}
      />

      <Controller
        as={PasswordInput}
        control={control}
        name="confirmPassword"
        errors={errors}
        title={`${t("login.confirm_password")}*`}
        onKeyPress={handleOnKeyPress(handleSubmit(onSubmit))}
      />

      <Stack spacing={4}>
        <Checkbox
          name="terms"
          ref={register}
          errors={errors}
          fontSize="xxs"
          borderColor="primary.100"
          renderChildrenAsText={false}
        >
          <Text
            fontSize="xxs"
            as="span"
          >
            {t("actions.i_have_reviewed_the_terms")}

            <TextLink
              onClick={handleViewTermsAndConditions}
              color="primary.500"
              fontSize="xxs"
            >
              {TERMS_LINK}
            </TextLink>
          </Text>
        </Checkbox>

        <Checkbox
          name="subscribedToMailingList"
          ref={register}
          errors={errors}
          alignItems="baseline"
          borderColor="primary.100"
          fontSize="xxs"
          defaultIsChecked
        >
          {t("actions.i_agree_to_receive_marketing_emails")}
        </Checkbox>
      </Stack>

      <Stack spacing={3}>
        <Button
          onClick={handleSubmit(onSubmit)}
          disabled={!formState.isValid}
          isLoading={loading}
        >
          {t("sign_up.title")}
        </Button>

        <Flex
          flexDir="row"
          alignItems="center"
          alignSelf="center"
          fontSize="xxs"
        >
          <Trans
            i18nKey="buttons.already_have_an_account"
            components={{
              TextLink: (
                <TextLink
                  onClick={navigate(loginUrlQuery)}
                  color="primary.500"
                  fontSize="xxs"
                  ml={1}
                />
              ),
            }}
          />
        </Flex>
      </Stack>
    </AuthBox>
  );
};

export default MemberSignUp;
