import { gql } from "@apollo/client";

export const createOrUpdateExtraQuestionRefetchQueries = [
  "GetForm",
];

export default gql`
  mutation CreateOrUpdateExtraQuestion (
    $extraQuestionId: Int!,
    $formId: Int!,
    $params: ExtraQuestionParams!,
  ){
    createOrUpdateExtraQuestion (
      extraQuestionId: $extraQuestionId,
      formId: $formId,
      params: $params,
    ) {
      id
    }
  }
`;
