import i18n from "translations/i18n";
import genericCompanyLogo1 from "assets/images/landingPagesTemplates/companyLogos/generic-company-logo-1.png";
import genericCompanyLogo2 from "assets/images/landingPagesTemplates/companyLogos/generic-company-logo-2.png";
import headerImageLandingPage1 from "assets/images/landingPagesTemplates/headerImages/landing-page-1.jpg";
import headerImageLandingPage2 from "assets/images/landingPagesTemplates/headerImages/landing-page-2.jpg";
import headerImageLandingPage3 from "assets/images/landingPagesTemplates/headerImages/landing-page-3.jpg";
import headerImageLandingPage4 from "assets/images/landingPagesTemplates/headerImages/landing-page-4.jpg";
import headerImageLandingPage5 from "assets/images/landingPagesTemplates/headerImages/landing-page-5.jpg";
import headerImageLandingPage6 from "assets/images/landingPagesTemplates/headerImages/landing-page-6.jpg";
import screenshotLandingPage2 from "assets/images/landingPagesTemplates/screenshots/landing-page-2.jpg";
import screenshotLandingPage3 from "assets/images/landingPagesTemplates/screenshots/landing-page-3.jpg";
import screenshotLandingPage4 from "assets/images/landingPagesTemplates/screenshots/landing-page-4.jpg";
import screenshotLandingPage5 from "assets/images/landingPagesTemplates/screenshots/landing-page-5.jpg";
import screenshotLandingPage6 from "assets/images/landingPagesTemplates/screenshots/landing-page-6.jpg";
import screenshotLandingPage1 from "assets/images/landingPagesTemplates/screenshots/landing-page-1.jpg";
import colors from "settings/theme/colors";

import { LandingPageTemplate } from "./types";

/*
 * The layout label identifer of a landing page template
 */
export enum LandingPageTemplateLayoutLabel {
  TEMPLATE_1 = "TEMPLATE_1",
  TEMPLATE_2 = "TEMPLATE_2",
  TEMPLATE_3 = "TEMPLATE_3",
  TEMPLATE_4 = "TEMPLATE_4",
  TEMPLATE_5 = "TEMPLATE_5",
  TEMPLATE_6 = "TEMPLATE_6",
}

/**
 * Common data that appears initial on every
 * landing page template
 */
export const commonLandingPageTemplateData = {
  title: i18n.t("landing_pages_templates.become_a_caregiver"),
  buttonText: i18n.t("landing_pages_templates.apply_now"),
  color: colors.primary["100"],
  textColor: colors.white,
};

/**
 * Map all the landing pages templates data data
 */
export const landingPagesTemplatesData: Record<
  LandingPageTemplateLayoutLabel,
  LandingPageTemplate
> = {
  [LandingPageTemplateLayoutLabel.TEMPLATE_1]: {
    ...commonLandingPageTemplateData,
    layoutLabel: LandingPageTemplateLayoutLabel.TEMPLATE_1,
    headerImageUrl: headerImageLandingPage1,
    screenshot: screenshotLandingPage1,
    logoUrl: genericCompanyLogo2,
    name: i18n.t("landing_pages_templates.template_title", {
      index: 1,
    }),
    id: 1,
  },
  [LandingPageTemplateLayoutLabel.TEMPLATE_2]: {
    ...commonLandingPageTemplateData,
    layoutLabel: LandingPageTemplateLayoutLabel.TEMPLATE_2,
    headerImageUrl: headerImageLandingPage2,
    screenshot: screenshotLandingPage2,
    logoUrl: genericCompanyLogo2,
    name: i18n.t("landing_pages_templates.template_title", {
      index: 2,
    }),
    id: 2,
  },
  [LandingPageTemplateLayoutLabel.TEMPLATE_3]: {
    ...commonLandingPageTemplateData,
    layoutLabel: LandingPageTemplateLayoutLabel.TEMPLATE_3,
    headerImageUrl: headerImageLandingPage3,
    screenshot: screenshotLandingPage3,
    logoUrl: genericCompanyLogo2,
    name: i18n.t("landing_pages_templates.template_title", {
      index: 3,
    }),
    id: 3,
  },
  [LandingPageTemplateLayoutLabel.TEMPLATE_4]: {
    ...commonLandingPageTemplateData,
    layoutLabel: LandingPageTemplateLayoutLabel.TEMPLATE_4,
    headerImageUrl: headerImageLandingPage4,
    screenshot: screenshotLandingPage4,
    logoUrl: genericCompanyLogo1,
    name: i18n.t("landing_pages_templates.template_title", {
      index: 4,
    }),
    id: 4,
  },
  [LandingPageTemplateLayoutLabel.TEMPLATE_5]: {
    ...commonLandingPageTemplateData,
    layoutLabel: LandingPageTemplateLayoutLabel.TEMPLATE_5,
    headerImageUrl: headerImageLandingPage5,
    screenshot: screenshotLandingPage5,
    logoUrl: genericCompanyLogo1,
    name: i18n.t("landing_pages_templates.template_title", {
      index: 5,
    }),
    id: 5,
  },
  [LandingPageTemplateLayoutLabel.TEMPLATE_6]: {
    ...commonLandingPageTemplateData,
    layoutLabel: LandingPageTemplateLayoutLabel.TEMPLATE_6,
    headerImageUrl: headerImageLandingPage6,
    screenshot: screenshotLandingPage6,
    logoUrl: genericCompanyLogo1,
    name: i18n.t("landing_pages_templates.template_title", {
      index: 6,
    }),
    id: 6,
  },
};

/**
 * Defines all available landing page templates.
 */
export const landingPageTemplates = Object.values(landingPagesTemplatesData);
