import React from "react";
import { useTranslation } from "react-i18next";
import {
  IconButton,
  Tooltip,
  Stack,
} from "@chakra-ui/react";

import { ActionButtonsRowProps } from "views/Dashboard/DashboardContent/Pages/Team/types";
import ReinviteIcon from "settings/theme/icons/ReinviteIcon";
import DeleteIcon from "settings/theme/icons/DeleteIcon";
import EditIcon from "settings/theme/icons/EditIcon";

const ActionButtonsRow: React.FC<ActionButtonsRowProps> = ({
  handleReinviteMember,
  handleRemoveMember,
  currentUserMember,
  handleEditMember,
  member,
}) => {
  const [t] = useTranslation();

  const canEdit = member.id !== currentUserMember?.id;

  const canReinvite = !member?.user;

  if (!canEdit) {
    return null;
  }

  return (
    <Stack
      justifyContent="flex-end"
      alignItems="center"
      spacing={1}
      isInline
    >
      {
        canReinvite && (
          <Tooltip
            aria-label={t("buttons.reinvite")}
            label={t("buttons.reinvite")}
            fontSize="xxs"
            hasArrow
          >
            <IconButton
              aria-label={t("buttons.reinvite")}
              onClick={handleReinviteMember(member.id)}
              variant="ghost"
              icon={(
                <ReinviteIcon
                  color="primary.200"
                />
              )}
            />
          </Tooltip>
        )
      }

      <Tooltip
        aria-label={t("buttons.edit")}
        label={t("buttons.edit")}
        fontSize="xxs"
        hasArrow
      >
        <IconButton
          aria-label={t("buttons.edit")}
          onClick={handleEditMember(member.id)}
          variant="ghost"
          icon={(
            <EditIcon
              color="primary.200"
            />
          )}
        />
      </Tooltip>

      <Tooltip
        aria-label={t("buttons.remove")}
        label={t("buttons.remove")}
        fontSize="xxs"
        hasArrow
      >
        <IconButton
          aria-label={t("buttons.remove")}
          onClick={handleRemoveMember(member.id)}
          variant="ghost"
          icon={(
            <DeleteIcon
              color="primary.200"
            />
          )}
        />
      </Tooltip>
    </Stack>
  );
};

export default ActionButtonsRow;
