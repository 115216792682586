import * as R from "remeda";

import { StoryProps, FormattedOpenAnswer } from "./types";

/**
 * Formats open answers of an applicant or client, to display on story
 * component
 *
 * @param openAnswers The open answers
 */
const formatOpenAnswers = (
  openAnswers?: StoryProps["openAnswers"],
): FormattedOpenAnswer[] => R.pipe(
  openAnswers ?? [],
  R.map((openAnswer) => ({
    title: openAnswer?.question as string,
    answer: openAnswer?.answer as string,
  })),
);

export default formatOpenAnswers;
