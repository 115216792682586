import {
  Flex,
  Text,
  HStack,
  VStack,
  Heading,
} from "@chakra-ui/react";
import React from "react";

import Box from "components/Box";

import { StoryProps } from "./types";
import formatOpenAnswers from "./formatOpenAnswers";

const Story: React.FC<StoryProps> = ({
  containerProps,
  openAnswers,
  title,
}) => {
  const formattedOpenAnswers = formatOpenAnswers(openAnswers);

  return (
    <Box
      w="full"
      h="auto"
      gridColumn="1 / span 2"
      borderRadius={10}
      {...(containerProps ?? {})}
    >
      <VStack
        align="start"
        spacing={5}
        h="full"
        w="full"
        p={5}
      >
        <Heading fontSize="md">
          {title}
        </Heading>

        <VStack
          w="full"
          spacing={4}
        >
          {
            formattedOpenAnswers.map((openAnswer) => (
              <VStack
                spacing={2}
                width="full"
                align="start"
                key={openAnswer.title}
              >
                <HStack alignItems="center" spacing={2}>
                  <Flex
                    bgColor="primary.300"
                    boxSize="8px"
                    borderRadius="100%"
                  />

                  <Text fontWeight="bold">{openAnswer.title}</Text>
                </HStack>

                <Text
                  whiteSpace="break-spaces"
                >
                  {openAnswer.answer}
                </Text>
              </VStack>
            ))
          }
        </VStack>
      </VStack>
    </Box>
  );
};

export default Story;
