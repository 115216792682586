import { Availability, DayOfWeekEnum } from "generated/graphql";

export interface ParsedAvailability {
  day: DayOfWeekEnum;
  afternoon: boolean;
  evening: boolean;
  morning: boolean;
  overnight: boolean;
}

export enum AvailabilityPeriod {
  Afternoon = "afternoon",
  Evening = "evening",
  Morning = "morning",
  Overnight = "overnight",
}

export interface AvailabilityMatrixProps {
  value?: Availability[];
  isDisabled?: boolean;
  onChange?: (newValue: ParsedAvailability[]) => void;
  lite?: boolean;
}

export type AvailabilityChangeHandler = (
  availability: ParsedAvailability,
  field: AvailabilityPeriod,
) => () => void;

export interface AvailabilityRowProps {
  handleOnChange: AvailabilityChangeHandler;
  availability: ParsedAvailability;
  isDisabled?: boolean;
  firstRowWithTitles?: boolean;
  hideLabels?: boolean;
  lite?: boolean;
}

export interface AvailabilityOption {
  field: AvailabilityPeriod;
  checked: boolean;
  label: string;
}
