import { useCallback, useMemo } from "react";
import { ApolloError, MutationTuple } from "@apollo/client";
import { useHistory } from "react-router-dom";

import {
  useSubmitApplicantResponseMutation,
  useSubmitClientResponseMutation,
  FormTypeEnum,
} from "generated/graphql";
import { getLocationTypeFromFormType } from "constants/formTypes";
import { FORM_THANK_YOU_PAGE_LOCATION } from "routes/locations";
import { onQueryError } from "utils/queryHandlers";
import useToast from "hooks/useToast";

import { UseSubmitResponseOptions, UseSubmitResponseResult } from "./types";

/**
 * Exposes the correct mutation to be executed according to a given form type.
 *
 * For applicant forms, this hook will execute the `submitApplicantResponse` mutation.
 * For client forms, this hook will execute the `submitClientResponse` mutation.
 *
 * In case the mutation fails, a toast will be shown with the error.
 *
 * @param param0 Options.
 */
const useSubmitResponse = ({
  formResponseId,
  shortCode,
  formType,
}: UseSubmitResponseOptions): UseSubmitResponseResult => {
  const [showToast] = useToast();
  const history = useHistory();

  const [submitApplicantResponse, {
    loading: submitApplicantResponseLoading,
  }] = useSubmitApplicantResponseMutation();

  const [submitClientResponse, {
    loading: submitClientResponseLoading,
  }] = useSubmitClientResponseMutation();

  const submitResponse = useCallback(() => {
    const mutation = ({
      [FormTypeEnum.ShortApplication]: submitApplicantResponse,
      [FormTypeEnum.Application]: submitApplicantResponse,
      [FormTypeEnum.Client]: submitClientResponse,
    })[formType] as MutationTuple<unknown, unknown>[0];

    mutation?.({
      variables: {
        id: formResponseId,
      },
    })
      .then(() => {
        const path = FORM_THANK_YOU_PAGE_LOCATION.toUrl({
          formLocationType: getLocationTypeFromFormType(formType),
          shortCode,
        });
        localStorage.removeItem(shortCode);
        history.push(path);
      })
      .catch((error: ApolloError) => {
        onQueryError(error, showToast);
      });
  }, [
    submitApplicantResponse,
    submitClientResponse,
    formResponseId,
    shortCode,
    showToast,
    formType,
    history,
  ]);

  const payload = useMemo<UseSubmitResponseResult>(() => [
    submitResponse,
    submitApplicantResponseLoading || submitClientResponseLoading,
  ], [
    submitApplicantResponseLoading,
    submitClientResponseLoading,
    submitResponse,
  ]);

  return payload;
};

export default useSubmitResponse;
