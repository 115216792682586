import React from "react";
import {
  Text,
  Flex,
  Image,
  Heading,
} from "@chakra-ui/react";
import LoremIpsum from "react-lorem-ipsum";

import Button from "components/Button";

import { JobMainSectionProps } from "./types";

const JobMainSection: React.FC<JobMainSectionProps> = ({
  navigateToForm,
  containerProps,
  landingPage,
  textProps,
}) => (
  <Flex
    width="100%"
    height="100%"
    direction="column"
    alignItems="flex-start"
    justifyContent="space-between"
    {...(containerProps ?? {})}
  >
    <Image
      src={landingPage.logoUrl}
      height={100}
    />

    <Flex
      width="100%"
      marginTop="auto"
      direction="column"
      alignItems="flex-start"
      {...(containerProps ?? {})}
    >
      <Text
        textTransform="uppercase"
        fontFamily="bold"
        textAlign="left"
        fontSize="lg"
        color="white"
        {...(textProps ?? {})}
      >
        {landingPage.title}
      </Text>

      <Heading
        color="white"
        marginY={10}
        fontSize="xs"
        maxWidth="20rem"
        lineHeight="1.5"
        textAlign="left"
        whiteSpace="break-spaces"
        {...(textProps ?? {})}
      >
        {
          landingPage.subtitle || (
            <LoremIpsum
              avgWordsPerSentence={4}
              avgSentencesPerParagraph={4}
            />
          )
        }
      </Heading>

      <Button
        minWidth={40}
        fontSize="sm"
        fontFamily="heading"
        textTransform="uppercase"
        borderRadius={30}
        onClick={navigateToForm}
        color={landingPage.textColor}
        backgroundColor={landingPage.color}
      >
        {landingPage.buttonText}
      </Button>
    </Flex>
  </Flex>
);

export default JobMainSection;
