import { ExtraQuestion, ExtraQuestionTypeEnum, ExtraSection } from "generated/graphql";
import { formatDate, monthDayYear } from "utils/dateFormats";

import {
  ExtraSectionQuestionSchema,
  ExtraSectionSchema,
} from "./types";

/**
 * Parses one answer value.
 * @param question The question.
 * @param extraQuestion The extra question data.
 */
const getAnswerValue = (
  question: ExtraSectionQuestionSchema,
  extraQuestion: ExtraQuestion | undefined,
): string | undefined => {
  if (extraQuestion?.type === ExtraQuestionTypeEnum.Date) {
    return (
      question.value
        ? formatDate(question.value ?? "", monthDayYear)
        : undefined
    );
  }

  return question.value;
};

/**
 * Returns a set of answers for a given question.
 * @param extraSection The extra section information.
 * @param question The question.
 */
const getAnswers = (
  extraSection: ExtraSection,
  question: ExtraSectionQuestionSchema,
): Record<string, unknown> => {
  const extraQuestion = extraSection.extraQuestions.find((item) => (
    item.id === question.questionId
  ));

  const answer = getAnswerValue(question, extraQuestion);

  return {
    questionText: extraQuestion?.question,
    id: question.questionId,
    answer,
  };
};

/**
 * Parses the answers data. May return a section called `answers` or `answerGroups` depending on
 * how many forms were filled at the extra section.
 *
 * @param extraSection The extra section information.
 * @param values The form values.
 */
const getAnswerPayload = (
  extraSection: ExtraSection,
  values: ExtraSectionSchema,
): Record<string, unknown> => {
  const shouldIncludeAnswerGroups = extraSection.isMultiple;

  if (shouldIncludeAnswerGroups) {
    return {
      answerGroups: (values?.answers ?? []).map((answer) => ({
        answers: (answer?.questions ?? []).map((question) => getAnswers(extraSection, question)),
      })),
    };
  }

  const questions = values?.answers?.[0]?.questions ?? [];

  return {
    answers: questions.map((question) => getAnswers(extraSection, question)),
  };
};

/**
 * Parses mutation values according to form state for Extra Section forms.
 * Will return a JSON structure that follows the backend result for queries.
 *
 * Example return:
 * %{
 *   "1" => %{
 *     "sectionName" => "Personal info",
 *     "answers" => [
 *       %{
 *         "id" => 1,
 *         "questionText" => "Phone",
 *         "answer" => "+55489999999",
 *       },
 *       %{
 *         "id" => 2,
 *         "questionText" => "Requirements",
 *         "answer" => "I have a high school degree",
 *       },
 *       %{
 *         "id" => 3,
 *         "questionText" => "Referrer?",
 *         "answer" => "Facebook",
 *       },
 *     ],
 *   },
 *   "2" => %{
 *     "sectionName" => "Past employers",
 *     "answerGroups" => [
 *       %{
 *         "answers" => [
 *           %{
 *             "id" => 4,
 *             "questionText" => "Company name",
 *             "answer" => "Jungsoft",
 *           },
 *           %{
 *             "id" => 5,
 *             "questionText" => "Start date",
 *             "answer" => "10/05/2018",
 *           },
 *         ],
 *       },
 *       %{
 *         "answers" => [
 *           %{
 *             "id" => 4,
 *             "questionText" => "Company name",
 *             "answer" => "Kartrak",
 *           },
 *           %{
 *             "id" => 5,
 *             "questionText" => "Start date",
 *             "answer" => "30/08/2019",
 *           },
 *         ],
 *       },
 *     ],
 *   },
 * }
 *
 * @param extraSection The extra section information.
 * @param values The form values.
 */
const getMutationValues = (
  extraSection: ExtraSection,
  values: ExtraSectionSchema,
): Record<string, unknown> => {
  const answerPayload = getAnswerPayload(extraSection, values);

  const newValues = {
    [extraSection.id]: {
      sectionName: extraSection.name,
      ...answerPayload,
    },
  };

  return {
    extraAnswers: JSON.stringify(newValues),
  };
};

export default getMutationValues;
