import React, { useState, useCallback, useEffect } from "react";
import { IconButton } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import Tour from "reactour";

import LiveHelpIcon from "settings/theme/icons/LiveHelpIcon";
import { APPLICANTS_PROFILE_TOUR_STEPS } from "constants/reactTour";
import { REACT_TOUR_APPLICANTS_PROFILE_KEY } from "constants/storage";

import { ReactTourProps } from "./types";
import CustomHelper from "./CustomHelper";
import getSteps from "./getSteps";

const SCROLL_OFFSET = -100;

const ReactTour: React.FC<ReactTourProps> = ({
  isLoading = true,
  mapStepList,
  stepList,
  isPrintLayout,
}) => {
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [t] = useTranslation();

  useEffect(() => {
    if (isLoading || isPrintLayout) {
      return;
    }

    setIsTourOpen(
      localStorage.getItem(REACT_TOUR_APPLICANTS_PROFILE_KEY) !== "true",
    );
  }, [
    isPrintLayout,
    isLoading,
  ]);

  const handleOpenTour = useCallback(() => {
    setIsTourOpen(true);

    localStorage.setItem(
      REACT_TOUR_APPLICANTS_PROFILE_KEY,
      JSON.stringify(false),
    );
  }, [
    setIsTourOpen,
  ]);

  const onRequestClose = useCallback(() => {
    localStorage.setItem(
      REACT_TOUR_APPLICANTS_PROFILE_KEY,
      JSON.stringify(true),
    );

    setIsTourOpen(false);
  }, [
    setIsTourOpen,
  ]);

  const steps = getSteps({
    mapStepList,
    stepList,
  });

  const buttonStyles = {
    filter: "brightness(1.1)",
  };

  return (
    <>
      <Tour
        onRequestClose={onRequestClose}
        scrollOffset={SCROLL_OFFSET}
        CustomHelper={CustomHelper}
        closeWithMask={false}
        isOpen={isTourOpen}
        disableInteraction
        showNumber={false}
        steps={steps}
        startAt={0}
      />

      <IconButton
        className={APPLICANTS_PROFILE_TOUR_STEPS.tutorial}
        aria-label={t("buttons.start_tour")}
        title={t("buttons.start_tour")}
        onClick={handleOpenTour}
        _active={buttonStyles}
        bgColor="primary.200"
        borderRadius="60px"
        variant="unstyled"
        position="fixed"
        bottom={10}
        zIndex={10}
        right={10}
        h="60px"
        w="60px"
        icon={(
          <LiveHelpIcon
            color="white"
            boxSize="40px"
          />
        )}
      />
    </>
  );
};

export default ReactTour;
