import * as React from "react";
import { useTranslation } from "react-i18next";
import { Flex, Text } from "@chakra-ui/react";

import { PreviewProps } from "components/UploadBox/types";
import Images from "components/Images";

import FileDropBox from "../FileDropBox";

const Preview: React.FC<PreviewProps> = ({
  changeImageLabel,
  defaultImageSrc,
  ensureContrast,
  fileSources,
  previewSrc,
  hasFiles,
  boxSize,
  color,
  open,
}) => {
  const [t] = useTranslation();

  const src = previewSrc ?? (
    fileSources.length > 0
      ? fileSources
      : defaultImageSrc
  );

  if (!hasFiles) {
    return (
      <FileDropBox
        boxSize={boxSize}
        color={color}
      />
    );
  }

  return (
    <Flex direction="column">
      <Images
        ensureContrast={ensureContrast}
        objectFit="scale-down"
        height={boxSize}
        width={boxSize}
        src={src}
      />

      <Text
        onClick={open}
        color={color}
        fontFamily="bold"
        cursor="pointer"
        fontSize="xs"
        mt={1}
        ml={1}
      >
        {changeImageLabel || t("actions.change_image")}
      </Text>
    </Flex>
  );
};

export default Preview;
