import React from "react";
import { Stack, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import { getPaymentText } from "./payments";
import { PaymentListProps } from "./types";

const PaymentList: React.FC<PaymentListProps> = ({
  subscription,
}) => {
  const [t] = useTranslation();

  return (
    <Stack spacing={2}>
      <Text
        color="primary.200"
        fontFamily="bold"
        fontSize="xs"
      >
        {t("dashboard.dashboard.subscription_role", {
          roleName: subscription.plan.role.name,
          planName: subscription.plan.name,
        })}
      </Text>

      <Stack spacing={2}>
        <Text
          as="span"
          fontSize="xs"
          pl={3}
        >
          {
            getPaymentText(
              t("dashboard.dashboard.last"),
              subscription?.lastPayment?.amount?.formatted,
              subscription?.lastPayment?.paidAt,
            )
          }
        </Text>

        <Text
          as="span"
          fontSize="xs"
          pl={3}
        >
          {
            getPaymentText(
              t("dashboard.dashboard.next"),
              subscription?.nextPayment?.amount?.formatted,
              subscription?.nextPayment?.expectedDate,
            )
          }
        </Text>
      </Stack>
    </Stack>
  );
};

export default PaymentList;
