import React from "react";
import { Redirect, useParams } from "react-router-dom";

import { Form, useGetFormByShortcodeQuery } from "generated/graphql";
import captureSentryException from "utils/captureSentryException";
import Loading from "components/Loading";
import { ROOT_PAGE_PATH } from "routes";

import FormPreviewContent from "./FormPreviewContent";
import { FormPageParams } from "../types";

const FormPreview: React.FC = () => {
  const { shortCode } = useParams<FormPageParams>();

  const {
    loading,
    data,
  } = useGetFormByShortcodeQuery({
    skip: !shortCode,
    variables: {
      shortcode: shortCode,
    },
    onError: (error) => {
      captureSentryException(new Error("Failed to get form by short code"), error);
    },
  });

  const form = data?.getFormByShortcode as Form;

  if (loading) {
    return (
      <Loading />
    );
  }

  if (!form || !shortCode) {
    return (
      <Redirect to={ROOT_PAGE_PATH} />
    );
  }

  return (
    <FormPreviewContent
      shortCode={shortCode}
      form={form}
    />
  );
};

export default FormPreview;
