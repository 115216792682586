import React from "react";

import Loading from "components/Loading";

import useApplicantFormResponse from "../hooks/useFormResponse/useApplicantFormResponse";
import SourceForm from "./SourceForm";

const Source: React.FC = () => {
  const [formResponse, loading] = useApplicantFormResponse();

  if (loading) {
    return (
      <Loading />
    );
  }

  return (
    <SourceForm formResponse={formResponse} />
  );
};

export default Source;
