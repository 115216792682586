import { AssertingTestOptions } from "yup";

import { ZIP_CODE_LENGTH } from "constants/masks";
import { isValidZipCode } from "utils/isValidZipCode";
import i18n from "translations/i18n";

/**
 * Applies zip code validation in one schema field.
 */
export const buildZipCodeTest = (isRequired = true): AssertingTestOptions<string> => ({
  name: "isValidZipCode",
  exclusive: false,
  test (value: string): value is string {
    if (!value && !isRequired) {
      return true;
    }

    return isValidZipCode(value);
  },
  message: i18n.t("yup.invalid_field_length", {
    min: ZIP_CODE_LENGTH,
  }),
});
