import { useCallback, useMemo } from "react";

import { useGetDashboardCountApplicantsQuery, useGetDashboardCountStatusUpdatesQuery } from "generated/graphql";

import { UseStatusUpdatesCountOptions, UseStatusUpdatesCountResult } from "../types";
import getStatusUpdatesCardPeriod from "./getStatusUpdatesCardPeriod";

const useStatusUpdatesCount = ({
  comparisonPeriod,
  selectedPeriod,
  isManager,
  statuses,
  period,
  jobId,
}: UseStatusUpdatesCountOptions): UseStatusUpdatesCountResult => {
  const getManagerQueryVariables = useCallback(() => {
    if (!isManager) {
      return undefined;
    }

    const currentPeriod = getStatusUpdatesCardPeriod(selectedPeriod, period);

    return {
      period: currentPeriod,
      comparisonPeriod,
      statuses,
      jobId,
    };
  }, [
    comparisonPeriod,
    selectedPeriod,
    isManager,
    statuses,
    period,
    jobId,
  ]);

  const {
    loading: loadingCountStatusUpdates,
    data: dataCountStatusUpdates,
  } = useGetDashboardCountStatusUpdatesQuery({
    variables: getManagerQueryVariables(),
    fetchPolicy: "cache-and-network",
    skip: !isManager,
  });

  const {
    loading: loadingCountApplicants,
    data: dataCountApplicants,
  } = useGetDashboardCountApplicantsQuery({
    fetchPolicy: "cache-and-network",
    skip: !!isManager,
    variables: {
      statuses,
      jobId,
    },
  });

  const isLoading = (
    loadingCountStatusUpdates
    || loadingCountApplicants
  );

  const count = (
    isManager
      ? dataCountStatusUpdates?.countStatusUpdates?.numberOfStatusUpdates
      : dataCountApplicants?.countApplicants
  );

  const growthRate = (
    (isManager && comparisonPeriod)
      ? Number(dataCountStatusUpdates?.countStatusUpdates?.growthRate) || 0
      : undefined
  );

  const payload = useMemo<UseStatusUpdatesCountResult>(() => ({
    loading: isLoading,
    growthRate,
    count,
  }), [
    growthRate,
    isLoading,
    count,
  ]);

  return payload;
};

export default useStatusUpdatesCount;
