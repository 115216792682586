import React, { useCallback } from "react";
import { yupResolver } from "@hookform/resolvers";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";

import companySignUpSchema, { CompanySignUpSchema } from "settings/yup/schemas/companySignUpSchema";
import { useRegisterWithCompanyMutationMutation } from "generated/graphql";
import CompanySignUpFields from "views/CompanySignUp/CompanySignUpFields";
import { COMPANY_ONBOARDING_PAGE_PATH } from "routes";
import AuthBox from "components/Box/AuthBox";
import useSession from "hooks/useSession";
import useToast from "hooks/useToast";

const CompanySignUp: React.FC = () => {
  const [createSession] = useSession();
  const [showToast] = useToast();
  const [t] = useTranslation();

  const {
    handleSubmit,
    formState,
    register,
    setValue,
    control,
    errors,
  } = useForm<CompanySignUpSchema>({
    resolver: yupResolver(companySignUpSchema),
    mode: "onChange",
    defaultValues: {
      subscribedToMailingList: true,
      terms: false,
      confirmPassword: "",
      companyName: "",
      managerName: "",
      password: "",
      email: "",
      address: {
        streetAddress: "",
        complement: "",
        longitude: "",
        latitude: "",
        zipCode: "",
        state: "",
        city: "",
      },
    },
  });

  const [registerWithCompany, {
    loading: registrationLoading,
  }] = useRegisterWithCompanyMutationMutation();

  const onSubmit = useCallback((values: CompanySignUpSchema) => {
    registerWithCompany({
      variables: {
        params: {
          subscribedToMailingList: values.subscribedToMailingList,
          password: values.password,
          name: values.managerName,
          email: values.email,
          company: {
            location: values.address,
            name: values.companyName,
          },
        },
      },
    })
      .then((response) => {
        const token = response?.data?.registerWithCompany;

        if (token) {
          createSession(token, COMPANY_ONBOARDING_PAGE_PATH);
        }
      })
      .catch((error) => {
        showToast({
          title: error?.message,
          status: "error",
        });
      });
  }, [
    registerWithCompany,
    createSession,
    showToast,
  ]);

  return (
    <AuthBox
      title={t("company_sign_up.title")}
      width="3xl"
      mt="75px"
      mb="75px"
    >
      <CompanySignUpFields
        loading={registrationLoading}
        handleSubmit={handleSubmit}
        formState={formState}
        register={register}
        setValue={setValue}
        onSubmit={onSubmit}
        control={control}
        errors={errors}
      />
    </AuthBox>
  );
};

export default CompanySignUp;
