import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  ModalFooter,
  ModalBody,
  Stack,
  Image,
  Text,
} from "@chakra-ui/react";

import { getCardFlagByBrand } from "constants/creditCards";
import { DASHBOARD_PAGE_LOCATION } from "routes/locations";
import TextLink from "components/TextLink";
import Button from "components/Button";

import { PaymentPreviewModalProps } from "./types";

const PaymentPreviewModal: React.FC<PaymentPreviewModalProps> = ({
  hideModal,
  componentProps: {
    paymentInformation,
    defaultCreditCard,
    onConfirm,
  },
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const [t] = useTranslation();
  const history = useHistory();

  const handleChangeDefaultCard = useCallback(() => {
    history.push(DASHBOARD_PAGE_LOCATION.toUrl({
      page: "wallet",
    }));

    hideModal();
  }, [
    hideModal,
    history,
  ]);

  const handleConfirm = useCallback(() => {
    setIsLoading(true);

    onConfirm();
  }, [
    onConfirm,
  ]);

  return (
    <>
      <ModalBody>
        <Stack
          spacing={8}
          p={4}
        >
          {
            paymentInformation && (
              paymentInformation
            )
          }

          <Stack spacing={4}>
            <Text
              fontFamily="bold"
              fontSize="xs"
            >
              {`${t("payment_modal.default_credit_card")}:`}
            </Text>

            <Stack
              justifyContent="space-between"
              alignItems="center"
              isInline
            >
              <Stack
                alignItems="center"
                spacing={3}
                isInline
                w="full"
              >
                <Image
                  src={getCardFlagByBrand(defaultCreditCard.brand)}
                  alt={defaultCreditCard.brand}
                  w="auto"
                  h={8}
                />

                <Text fontSize="xs">
                  {t("dashboard.wallet.card_ending_in", {
                    last4: defaultCreditCard.last4,
                    brand: defaultCreditCard.brand,
                  })}
                </Text>
              </Stack>

              <TextLink
                onClick={handleChangeDefaultCard}
                color="primary.200"
                fontFamily="bold"
                fontSize="xs"
              >
                {t("buttons.change")}
              </TextLink>
            </Stack>
          </Stack>
        </Stack>
      </ModalBody>

      <ModalFooter>
        <Button
          onClick={handleConfirm}
          isLoading={isLoading}
          fontSize="sm"
          w="full"
        >
          {t("buttons.confirm")}
        </Button>
      </ModalFooter>
    </>
  );
};

export default PaymentPreviewModal;
