import React from "react";

import Loading from "components/Loading";

import useApplicantFormResponse from "../hooks/useFormResponse/useApplicantFormResponse";
import RequirementsForm from "./RequirementsForm";

const Requirements: React.FC = () => {
  const [formResponse, loading] = useApplicantFormResponse();

  if (loading) {
    return (
      <Loading />
    );
  }

  return (
    <RequirementsForm formResponse={formResponse} />
  );
};

export default Requirements;
