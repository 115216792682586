import {
  startOfMonth,
  startOfWeek,
  endOfMonth,
  subMonths,
  endOfWeek,
  endOfDay,
  setMonth,
} from "date-fns";
import * as R from "remeda";

import makeProgressiveArray from "utils/makeProgressiveArray";
import { formatDate, monthName } from "utils/dateFormats";
import { Period } from "generated/graphql";

import { DashboardPeriods, YearPeriod } from "./types";

/**
 * Returns the period of the beginning of the month until the current day for a given date.
 */
export const getMonthUntilCurrentDayPeriod = (date: Date): Period => ({
  startDate: formatDate(startOfMonth(date)),
  endDate: formatDate(endOfDay(date)),
});

/**
 * Parses the periods to be used in dashboard queries.
 */
export const getDashboardPeriods = (): DashboardPeriods => {
  const currentDate = new Date();

  const currentDateLastMonth = subMonths(currentDate, 1);

  const comparisonPeriod = getMonthUntilCurrentDayPeriod(currentDateLastMonth);

  const currentPeriod = getMonthUntilCurrentDayPeriod(currentDate);

  return {
    comparisonPeriod,
    currentPeriod,
  };
};

/**
 * Returns the period of the current week (d - 7).
 */
export const getCurrentWeekPeriod = (): Period => {
  const currentDate = new Date();

  return {
    startDate: formatDate(startOfWeek(currentDate)),
    endDate: formatDate(endOfWeek(currentDate)),
  };
};

/**
 * Returns the period of the a month.
 * Defaults to the current month.
 */
export const getMonthPeriod = (date = new Date()): Period => ({
  startDate: formatDate(startOfMonth(date)),
  endDate: formatDate(endOfMonth(date)),
});

/**
 * Returns an array of year periods.
 */
export const getYearPeriods = (): YearPeriod[] => {
  const currentMonth = new Date().getMonth();

  return R.pipe(
    makeProgressiveArray(12, true),
    R.map((month) => setMonth(new Date(), month)),
    R.map((date): YearPeriod => ({
      isCurrent: date.getMonth() === currentMonth,
      label: formatDate(date, monthName),
      value: getMonthPeriod(date),
    })),
  );
};
