import { useMemo } from "react";

import ClientPersonalInformation from "components/FormSteps/PersonalInformation/ClientPersonalInformation";
import ClientPersonalStory from "components/FormSteps/PersonalStory/ClientPersonalStory";
import parseExtraSection from "components/FormSteps/ExtraSection/parseExtraSection";
import RespondedBy from "components/FormSteps/RespondedBy";
import { ExtraSection } from "generated/graphql";
import i18n from "translations/i18n";
import {
  FormStep,
  FormStepId,
  FormStepType,
  FixedSectionsConfigurationParam,
} from "constants/formSteps";
import { OtherFormParams } from "views/Forms/types";
import parsePersonalStories from "components/FormSteps/PersonalStory/parsePersonalStories";

const getSteps = (
  roleName: string | undefined,
): FormStep[] => [
  {
    id: FormStepId.PersonalInformation,
    type: FormStepType.RequiredReadOnly,
    component: ClientPersonalInformation,
    title: i18n.t("forms.steps.personal_information.title"),
    subtitle: i18n.t("forms.client_form.subtitle", { roleName }),
    isOptional: false,
  },
  {
    id: FormStepId.RespondedBy,
    type: FormStepType.RequiredReadOnly,
    component: RespondedBy,
    title: i18n.t("forms.steps.responded_by.title"),
    subtitle: i18n.t("forms.client_form.subtitle", { roleName }),
    isOptional: false,
  },
];

/**
 * Hook that exposes the steps to be rendered in Client Form.
 * @param extraSections The form extra sections.
 */
const useSteps = (
  roleName: string | undefined,
  _fixedSectionsConfiguration: FixedSectionsConfigurationParam,
  extraSections: ExtraSection[] = [],
  otherFormParams: OtherFormParams,
): FormStep[] => {
  const baseSteps = useMemo(() => (getSteps(roleName)
  ), [roleName]);

  const personalStorySteps = useMemo(() => (
    parsePersonalStories({
      component: ClientPersonalStory,
      roleName,
      ...otherFormParams,
    })
  ), [otherFormParams, roleName]);

  const extraSectionSteps = useMemo(() => (
    extraSections.map((extraSection) => parseExtraSection(
      extraSection,
      i18n.t("forms.client_form.subtitle", { roleName }),
    ))
  ), [extraSections, roleName]);

  const steps = useMemo(() => [
    ...baseSteps,
    ...personalStorySteps,
    ...extraSectionSteps,
  ], [
    baseSteps,
    personalStorySteps,
    extraSectionSteps,
  ]);

  return steps;
};

export default useSteps;
