import React, { useMemo } from "react";
import { SimpleGrid, Wrap } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import { StatusUpdateEnum } from "generated/graphql";
import WrapItem from "components/WrapItem";

import { DashboardBadgeColorEnum, DashboardComponentProps } from "./types";
import ApplicationsOverviewCard from "./Cards/ApplicationsOverviewCard";
import StatusUpdatesCountCard from "./Cards/StatusUpdatesCountCard";
import RemainingTokensCard from "./Cards/RemainingTokensCard";
import { getCurrentWeekPeriod } from "./periods";
import { dashboardWrapCss } from "./styles";

const DashboardUser: React.FC<DashboardComponentProps> = ({
  selectedJobId,
  period,
}) => {
  const [t] = useTranslation();

  const currentWeekPeriod = useMemo(() => getCurrentWeekPeriod(), []);

  return (
    <>
      <Wrap
        css={dashboardWrapCss}
        spacing={5}
      >
        <WrapItem>
          <StatusUpdatesCountCard
            isManager={false}
            badgeColor={DashboardBadgeColorEnum.Stage1}
            title={t("dashboard.dashboard.new_applicants")}
            subtitle={t("dashboard.dashboard.period_filter.current_period")}
            jobId={selectedJobId}
            statuses={[
              StatusUpdateEnum.AwaitingReview,
            ]}
          />
        </WrapItem>

        <WrapItem>
          <StatusUpdatesCountCard
            isManager={false}
            badgeColor={DashboardBadgeColorEnum.Stage2}
            title={t("dashboard.dashboard.awaiting_interview")}
            subtitle={t("dashboard.dashboard.period_filter.current_period")}
            jobId={selectedJobId}
            statuses={[
              StatusUpdateEnum.ContactedLeftMessage,
              StatusUpdateEnum.Claimed,
            ]}
          />
        </WrapItem>

        <WrapItem>
          <StatusUpdatesCountCard
            isManager={false}
            badgeColor={DashboardBadgeColorEnum.Stage3}
            title={t("dashboard.dashboard.awaiting_final_decision")}
            subtitle={t("dashboard.dashboard.period_filter.current_period")}
            jobId={selectedJobId}
            statuses={[
              StatusUpdateEnum.InterviewScheduled,
            ]}
          />
        </WrapItem>

        <WrapItem>
          <StatusUpdatesCountCard
            isManager
            badgeColor={DashboardBadgeColorEnum.Stage4}
            title={t("dashboard.dashboard.applicants_hired")}
            jobId={selectedJobId}
            showPeriodSelector
            period={period}
            statuses={[
              StatusUpdateEnum.Hired,
            ]}
          />
        </WrapItem>
      </Wrap>

      <Wrap
        css={dashboardWrapCss}
        spacing={5}
      >
        <WrapItem>
          <RemainingTokensCard />
        </WrapItem>

        <WrapItem>
          <SimpleGrid
            gridColumn={[null, null, null, "span 3"]}
            columns={[1, null, 2]}
            h="full"
            w="full"
            gap={5}
          >
            <ApplicationsOverviewCard
              title={t("dashboard.dashboard.applications_overview_by_week")}
              subtitle={t("dashboard.dashboard.period_filter.last_7_days")}
              showPeriodSelector={false}
              period={currentWeekPeriod}
              jobId={selectedJobId}
            />

            <ApplicationsOverviewCard
              title={t("dashboard.dashboard.applications_overview_by_month")}
              jobId={selectedJobId}
            />
          </SimpleGrid>
        </WrapItem>
      </Wrap>
    </>
  );
};

export default DashboardUser;
