import React from "react";
import {
  Heading,
  Image,
  Flex,
  Text,
} from "@chakra-ui/react";
import { LoremIpsum } from "react-lorem-ipsum";

import Button from "components/Button";

import { LandingPageTemplateProps } from "./types";
import JobDescriptionSection from "./JobDescriptionSection";
import TemplateBackground from "./TemplateBackground";

const Template1: React.FC<LandingPageTemplateProps> = ({
  navigateToForm,
  landingPage,
}) => (
  <>
    <TemplateBackground
      width="100vw"
      height="100vh"
      paddingY={20}
      paddingX={[10, null, 40]}
      display="flex"
      alignItems="center"
      justifyContent="center"
      backgroundPosition="top center"
      headerImageUrl={landingPage?.headerImageUrl}
    >
      <Flex
        width="100%"
        height="100%"
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Image
          src={landingPage?.logoUrl}
          height={100}
        />

        <Text
          textTransform="uppercase"
          fontFamily="bold"
          marginTop={20}
          textAlign="center"
          fontSize="lg"
          color="white"
        >
          {landingPage.title}
        </Text>

        <Heading
          color="white"
          marginY={12}
          fontSize="xs"
          maxWidth="30rem"
          lineHeight="1.5"
          textAlign="center"
          whiteSpace="break-spaces"
        >
          {
            landingPage.subtitle || (
              <LoremIpsum
                avgWordsPerSentence={4}
                avgSentencesPerParagraph={4}
              />
            )
          }
        </Heading>

        <Button
          fontSize="sm"
          minWidth={40}
          fontFamily="heading"
          textTransform="uppercase"
          onClick={navigateToForm}
          color={landingPage.textColor}
          backgroundColor={landingPage.color}
        >
          {landingPage.buttonText}
        </Button>
      </Flex>
    </TemplateBackground>

    <JobDescriptionSection
      navigateToForm={navigateToForm}
      landingPage={landingPage}
      buttonProps={{
        borderRadius: 4,
      }}
    />
  </>
);

export default Template1;
