import HorizontalBarChart from "components/Charts/HorizontalBarChart";
import SpiderChartIcon from "settings/theme/icons/SpiderChartIcon";
import BarChartIcon from "settings/theme/icons/BarChartIcon";
import SpiderChart from "components/Charts/SpiderChart";

import { ChartOption, ChartOptionDetails } from "./types";

/**
 * Maps a ChartOption to a chart component.
 * @param selectedChart The selected chart.
 */
export const getChartComponent = (
  selectedChart: ChartOption,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
): React.FC<any> => ({
  spider: SpiderChart,
  bar: HorizontalBarChart,
})[selectedChart];

/**
 * Defines possible chart options.
 */
export const chartOptions: ChartOptionDetails[] = [
  {
    id: "bar",
    icon: BarChartIcon,
  },
  {
    id: "spider",
    icon: SpiderChartIcon,
  },
];
