import { buildStateAbbreviationTest } from "settings/yup/tests/stateAbbreviationTest";
import { buildZipCodeTest } from "settings/yup/tests/zipCodeTest";
import { TEXT_INPUT_LENGTH } from "constants/forms";
import yup from "settings/yup";

const optionalAddressSchema = yup.object().shape({
  streetAddress: yup
    .string(),
  complement: yup
    .string()
    .max(TEXT_INPUT_LENGTH),
  city: yup
    .string(),
  state: yup
    .string()
    .test(buildStateAbbreviationTest(false)),
  zipCode: yup
    .string()
    .test(buildZipCodeTest(false)),
  latitude: yup
    .string(),
  longitude: yup
    .string(),
});

export interface OptionalAddressSchema {
  streetAddress?: string;
  complement?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  latitude?: string;
  longitude?: string;
}

export default optionalAddressSchema;
