import React from "react";
import { useTranslation } from "react-i18next";

import { ApplicationPreviewComponentProps } from "views/Forms/FormPreview/types";
import getFieldLabel from "utils/getFieldLabel";

import PreviewContent from "../PreviewComponents/PreviewContent";

const Source: React.FC<ApplicationPreviewComponentProps> = ({
  formResponse,
  isRequired,
}) => {
  const [t] = useTranslation();

  return (
    <PreviewContent title={getFieldLabel(t("forms.steps.source.how_did_you_hear_about_this_job"), isRequired)}>
      {formResponse.source}
    </PreviewContent>
  );
};

export default Source;
