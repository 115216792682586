import yup from "settings/yup";

const respondedBySchema = yup.object().shape({
  respondedBy: yup
    .string()
    .required(),
});

export interface RespondedBySchema {
  respondedBy: string;
}

export default respondedBySchema;
