import React, { useCallback } from "react";

import { extraQuestionDisplayTypeOptions } from "constants/extraQuestions";
import Select from "components/FormComponents/Select";

import { ExtraQuestionDisplayTypeProps } from "./types";

const ExtraQuestionDisplayType: React.FC<ExtraQuestionDisplayTypeProps> = ({
  updateExtraQuestionDisplayType,
  extraQuestion,
  isDisabled,
}) => {
  const onChange = useCallback((option) => {
    updateExtraQuestionDisplayType({
      displayType: option.value,
      id: extraQuestion.id,
    });
  }, [
    updateExtraQuestionDisplayType,
    extraQuestion.id,
  ]);

  return (
    <Select
      options={extraQuestionDisplayTypeOptions}
      value={extraQuestion.displayType}
      isDisabled={isDisabled}
      onChange={onChange}
    />
  );
};

export default ExtraQuestionDisplayType;
