import i18n from "translations/i18n";
import {
  FormStep,
  FormStepId,
  FormStepType,
  PersonalStoriesParams,
  PersonalStoryParams,
} from "constants/formSteps";
import { FormTypeEnum } from "generated/graphql";
import { UseStepMode } from "views/Forms/types";

export const personalStory = ({
  component: Component,
  questionNumber,
  roleName,
  typeForm,
  isImageUploadStep,
  totalQuestions,
  stepMode,
}: PersonalStoryParams): FormStep => {
  const subtitle = typeForm === FormTypeEnum.Client
    ? "forms.client_form.subtitle"
    : "forms.steps.personal_story.subtitle";

  const storyStep = stepMode === UseStepMode.Submission
    ? `${questionNumber + 1}/${totalQuestions}`
    : "";

  return {
    id: FormStepId.PersonalStory,
    type: FormStepType.RequiredEditable,
    title: i18n.t("forms.steps.personal_story.title", { storyStep }),
    subtitle: i18n.t(subtitle, { roleName }),
    component: Component,
    isOptional: false,
    questionNumber,
    isImageUploadStep,
  };
};

const parsePersonalStories = ({
  component: Component,
  jobForm,
  allowImageUpload,
  typeForm,
  stepMode,
  roleName,
}: PersonalStoriesParams): FormStep[] => {
  const imageUploadQuestion = i18n.t("forms.steps.personal_story.a_picture_is_worth_a_thousand_words");
  const role = jobForm?.role;
  let openQuestions: string[] | undefined = [];
  let steps: FormStep[] = [];

  if (typeForm === FormTypeEnum.Client) {
    openQuestions = role?.clientOpenQuestions;
  } else {
    openQuestions = role?.applicantOpenQuestions;
  }

  if (allowImageUpload && openQuestions?.length) {
    openQuestions = [...openQuestions, imageUploadQuestion];
  }

  steps = openQuestions ? openQuestions.map((question, questionIndex) => personalStory({
    component: Component,
    questionNumber: questionIndex,
    roleName,
    typeForm,
    stepMode,
    totalQuestions: openQuestions?.length ?? 0,
    isImageUploadStep: allowImageUpload && question === imageUploadQuestion,
  })) : [];

  if (UseStepMode.Edition.includes(stepMode)) {
    steps = steps.slice(0, 1);
  }

  return steps;
};

export default parsePersonalStories;
