import React, { useCallback } from "react";
import { Flex, Text } from "@chakra-ui/react";
import ConditionalWrap from "conditional-wrap";

import StarIcon from "settings/theme/icons/StarIcon";

import { makeStarContainerCss } from "./styles";
import { StarProps } from "../types";

const Star: React.FC<StarProps> = ({
  colorScheme,
  clickable = true,
  position,
  onClick,
  rating,
}) => {
  const isSelected = rating >= position;

  const color = (
    isSelected
      ? `${colorScheme}.100`
      : "gray.100"
  );

  const textColor = (
    isSelected
      ? "white"
      : "gray.300"
  );

  const css = makeStarContainerCss(colorScheme);

  const handleClick = useCallback(() => {
    if (!onClick || !position) {
      return;
    }

    onClick(position);
  }, [
    onClick,
    position,
  ]);

  return (
    <ConditionalWrap
      condition={!!clickable}
      wrap={(children) => (
        <Flex
          onClick={handleClick}
          justifyContent="center"
          alignItems="center"
          position="relative"
          cursor="pointer"
          height="auto"
          width="full"
          css={css}
        >
          {children}
        </Flex>
      )}
    >
      <Flex
        justifyContent="center"
        alignItems="center"
        position="relative"
        height="auto"
        maxWidth={8}
        minWidth={4}
        width="full"
      >
        <StarIcon
          height="auto"
          width="full"
          color={color}
          zIndex={1}
        />

        <Text
          position="absolute"
          color={textColor}
          fontFamily="bold"
          fontSize="xxs"
          zIndex={2}
          mt={1}
        >
          {position}
        </Text>
      </Flex>
    </ConditionalWrap>
  );
};

export default Star;
