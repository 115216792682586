import rgba from "rgba-convert";
import get from "lodash.get";
import * as R from "remeda";

import i18n from "translations/i18n";
import toFixed from "utils/toFixed";
import theme from "settings/theme";

import getChartOptions, { xAxisID } from "./options";
import {
  HorizontalBarChartDataset,
  ParseChartConfigOptions,
  ParseChartConfigResult,
} from "./types";
import formatDescriptions from "../formatDescription";

/**
 * Defines possible values for barThickness.
 */
const barThickness = {
  xs: 5,
  sm: 10,
  md: 20,
  lg: 30,
};

/**
 * Defines the opacity (in %) of the chart background fill color.
 */
const getBackgroundFillColorOpacity = (index: number): string => (
  index === 0
    ? ""
    : "75"
);

/**
 * Defines the offset to calculate the average value bar.
 *
 * As dataset values range from 0 to 1, we need to define an offset to calculate the position
 * to render the floating bar chart items.
 *
 * For example, using 0.005 (which is the result of 0.5 / 100), and assuming a value of 0.3,
 * the floating bar chart would be calculated to render in these coordinates:
 * [value - 0.005, value + 0.005]
 *
 * This would mean that this floating bar chart would span from 0.295 to 0.305 on the X axis.
 */
const averageValueOffset = 0.5 / 100;

/**
 * The minimum length of the average bar, in pixels.
 */
const minAverageBarLength = 5;

/**
 * Parses data for a spider chart, returning valid values.
 * @param showAverageValues Defines whether to show average values or not.
 * @param inputs The chart inputs.
 * @param mainDatasetLabel The main dataset label.
 * @param comparisonDatasetLabel The comparison dataset label.
 */
const parseChartConfig = ({
  comparisonDatasetLabel,
  showAverageValues,
  mainDatasetLabel,
  inputs,
}: ParseChartConfigOptions): ParseChartConfigResult => {
  const defaultInput = inputs[0];

  const defaultScores = defaultInput?.scores ?? [];

  const labels = R.pipe(
    defaultScores,
    R.sortBy((score) => score.info?.position),
    R.map((score) => score.name),
  );

  const descriptions = formatDescriptions(defaultScores);

  /**
   * Converts the inputs into specific datasets.
   */
  const datasets: HorizontalBarChartDataset[] = R.pipe(
    inputs,
    R.map.indexed((input, index) => {
      const bgColor = get(theme.colors, `${input.colorScheme}.200`);

      const alphaBgColor = rgba.css(`${bgColor}${getBackgroundFillColorOpacity(index)}`);

      return {
        backgroundColor: alphaBgColor,
        order: index + 1,
        data: R.pipe(
          input?.scores ?? [],
          R.sortBy((score) => score.info?.position),
          R.map((score) => Number(score.finalValue)),
          R.map((value) => toFixed(value, 2)),
        ),
        xAxisID: xAxisID.scores,
        label: (
          index === 0
            ? mainDatasetLabel || i18n.t("charts.score")
            : comparisonDatasetLabel || i18n.t("charts.comparison_score")
        ),
        barThickness: (
          index === 0
            ? barThickness.md
            : barThickness.sm
        ),
        descriptions,
      };
    }),
    R.filter((dataset) => dataset.data.length > 0),
  );

  const hasComparison = datasets.length > 1;

  const shouldStack = !hasComparison;

  const shouldFillAverageValues = showAverageValues && !hasComparison;
  const shouldFillGrayValues = !hasComparison;

  /**
   * Adds the gray bar filling the rest of each bar in each dataset.
   */
  if (shouldFillGrayValues) {
    const grayValueDatasets: HorizontalBarChartDataset[] = R.pipe(
      datasets,
      R.map((dataset) => ({
        ...dataset,
        backgroundColor: get(theme.colors, "gray.100"),
        label: "",
        data: R.pipe(
          dataset.data as number[],
          R.map((value) => toFixed(1 - value, 2)),
        ),
      })),
    );

    datasets.push(...grayValueDatasets);
  }

  /**
   * Adds the average value floating bar charts dataset.
   */
  if (shouldFillAverageValues) {
    datasets.push({
      backgroundColor: get(theme.colors, `${defaultInput?.colorScheme}.100`),
      label: i18n.t("charts.average_score"),
      minBarLength: minAverageBarLength,
      barThickness: barThickness.lg,
      xAxisID: xAxisID.average,
      order: 0,
      data: R.pipe(
        defaultInput?.scores ?? [],
        R.sortBy((score) => score.info?.position),
        R.map((score) => score.info?.averageValue),
        R.map((value) => Number(value)),
        R.map((value) => Math.max(value, 0)),
        R.map((value) => toFixed(value, 2)),
        R.map((value) => [
          value - averageValueOffset,
          value + averageValueOffset,
        ]),
      ),
    });
  }

  const options = getChartOptions(shouldStack);

  const datasetsWithLabels = R.pipe(
    datasets,
    R.map((dataset) => ({
      ...dataset,
      datalabels: {
        display: dataset.label === mainDatasetLabel || dataset.label === i18n.t("charts.score"),
      },
    })),
  );

  const result: ParseChartConfigResult = {
    options,
    data: {
      datasets: datasetsWithLabels,
      labels,
    },
  };

  return result;
};

export default parseChartConfig;
