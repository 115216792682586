/**
* Defines the id returned by `createApplicantResponse` mutation.
*/
export const APPLICANT_FORM_RESPONSE_ID_KEY = "@milimatch/applicant-form-response-id";

/**
* Defines the id returned by `createApplicantResponse` mutation for short applicant forms.
*/
export const SHORT_APPLICANT_FORM_RESPONSE_ID_KEY = "@milimatch/short-applicant-form-response-id";

/**
* Defines the id returned by `createClientResponse` mutation.
*/
export const CLIENT_FORM_RESPONSE_ID_KEY = "@milimatch/client-form-response-id";

/**
* Defines the id used within ReactTour component
*/
export const REACT_TOUR_APPLICANTS_PROFILE_KEY = "@milimatch/react-tour-applicants-profile";
