import React from "react";
import { Flex, Text } from "@chakra-ui/react";

import { QuestionCardItemProps } from "./types";

const QuestionCardItem: React.FC<QuestionCardItemProps> = ({
  render: RenderComponent,
  isDisabled,
  fieldIndex,
  textProps,
  text,
  ...rest
}) => (
  <Flex
    p={4}
    {...rest}
  >
    <Flex
      alignItems="center"
      w="full"
    >
      {
        RenderComponent
          ? (
            <RenderComponent
              fieldIndex={fieldIndex ?? 0}
            />
          )
          : (
            <Text
              fontSize="xs"
              color={isDisabled ? "gray.300" : ""}
              {...(textProps ?? {})}
            >
              {text}
            </Text>
          )
      }
    </Flex>
  </Flex>
);

export default QuestionCardItem;
