import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Skeleton } from "@chakra-ui/react";
import * as R from "remeda";

import { useTrackingFiltersQueries } from "contexts/trackingFiltersQueries";
import createTrackingFilter from "components/Tracking/Filters";
import useFilter, { filters } from "hooks/useFilter";
import { LocationFilterOption } from "components/Tracking/types";
import UncontrolledSelect from "components/FormComponents/Select/UncontrolledSelect";

import getLocationFilterOptions from "./getLocationFilterOptions";

const LocationFilter = createTrackingFilter(({
  setTitle,
}) => {
  const [locations, setLocations] = useFilter(filters.locations);

  const [t] = useTranslation();

  const { locations: locationsData, loading } = useTrackingFiltersQueries();

  const options = getLocationFilterOptions(locationsData);

  const onChange = useCallback((option) => {
    const shouldSetFilter = Array.isArray(option) && option?.length > 0;

    if (shouldSetFilter) {
      setLocations(JSON.stringify(option));
    } else {
      setLocations(undefined);
    }
  }, [setLocations]);

  useEffect(() => {
    setTitle(`${t("filters.location")}:`);
  }, [
    setTitle,
    t,
  ]);

  const parseLocationsQuery = R.pipe(
    locations ? JSON.parse(locations as string) : [],
    R.map((location: LocationFilterOption) => location),
    R.uniqBy((location) => location.value?.city),
  );

  return (
    <Skeleton isLoaded={!loading}>
      <UncontrolledSelect
        isMulti
        name="locations"
        value={parseLocationsQuery}
        options={options}
        onChange={onChange}
        placeholder={t("filters.select_a_location")}
      />
    </Skeleton>
  );
});

export default LocationFilter;
