const APPLICANTS_PROFILE_STEP_PREFIX = "tour-step-applicants-profile";

export const APPLICANTS_PROFILE_TOUR_STEPS = {
  roleFit: `${APPLICANTS_PROFILE_STEP_PREFIX}-role-fit`,
  qualities: `${APPLICANTS_PROFILE_STEP_PREFIX}-qualities`,
  chartView: `${APPLICANTS_PROFILE_STEP_PREFIX}-chart-view`,
  qualitiesDefinition: `${APPLICANTS_PROFILE_STEP_PREFIX}-qualities-definition`,
  review: `${APPLICANTS_PROFILE_STEP_PREFIX}-review`,
  tutorial: `${APPLICANTS_PROFILE_STEP_PREFIX}-tutorial`,
};
