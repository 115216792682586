import React from "react";
import { useTranslation } from "react-i18next";

import Button from "components/Button";

import { PlanCardFooterProps } from "../../types";

const PlanCardFooter: React.FC<PlanCardFooterProps> = ({
  selectPlan,
  isSelected,
  isLoading,
}) => {
  const [t] = useTranslation();

  return (
    <Button
      _hover={{
        bgColor: isSelected ? "gray.300" : "primary.200",
      }}
      bgColor={isSelected ? "gray.200" : "primary.100"}
      color={isSelected ? "gray.400" : "white"}
      isDisabled={isSelected}
      isLoading={isLoading}
      onClick={selectPlan}
      borderRadius={0}
      fontSize="xs"
      size="sm"
      mb={4}
    >
      {
        isSelected
          ? t("dashboard.modify_subscription.current_plan")
          : t("dashboard.modify_subscription.select_plan")
      }
    </Button>
  );
};

export default PlanCardFooter;
