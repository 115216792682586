import create from "zustand";

import { SelectRoleStoreState } from "./types";

const useSelectRoleStore = create<SelectRoleStoreState>((set) => ({
  roleId: undefined,
  setRoleId: (value) => set({
    roleId: value,
  }),
}));

export default useSelectRoleStore;
