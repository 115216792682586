import React, { useCallback } from "react";
import { yupResolver } from "@hookform/resolvers";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { ModalFooter, ModalBody, Stack } from "@chakra-ui/react";

import { yupChangePasswordSchema } from "settings/yup/schemas/changePasswordSchema";
import PasswordInput from "components/FormComponents/Input/PasswordInput";
import Button from "components/Button";

import {
  IChangePasswordFormInputs,
  IChangePasswordModalFormProps,
} from "../types";

const ChangePasswordModalForm: React.FC<IChangePasswordModalFormProps> = ({
  componentProps: { isLoading, onFinish },
}) => {
  const [t] = useTranslation();
  const {
    getValues,
    control,
    errors,
    trigger,
  } = useForm<IChangePasswordFormInputs>({
    resolver: yupResolver(yupChangePasswordSchema),
    mode: "onChange",
  });

  const handleOnSubmit = useCallback(async (e) => {
    e.preventDefault();
    const isValid = await trigger();
    if (!isValid) return;

    const formValues = getValues();
    if (onFinish) {
      onFinish({
        oldPassword: formValues.oldPassword,
        newPassword: formValues.newPassword,
      });
    }
  }, [
    onFinish,
    getValues,
    trigger,
  ]);

  return (
    <form onSubmit={handleOnSubmit}>
      <ModalBody>
        <Stack spacing={3} my={3}>
          <Controller
            type="password"
            name="oldPassword"
            as={PasswordInput}
            defaultValue=""
            control={control}
            errors={errors}
            title={t("my_account.change_password.current_password")}
          />

          <Controller
            type="password"
            name="newPassword"
            as={PasswordInput}
            defaultValue=""
            control={control}
            errors={errors}
            title={t("my_account.change_password.new_password")}
          />

          <Controller
            type="password"
            name="confirmPassword"
            as={PasswordInput}
            defaultValue=""
            control={control}
            errors={errors}
            title={t("my_account.change_password.confirm_new_password")}
          />
        </Stack>
      </ModalBody>
      <ModalFooter>
        <Button isLoading={isLoading} type="submit" fontSize="sm" w="full">
          {t("buttons.save_changes")}
        </Button>
      </ModalFooter>
    </form>
  );
};

export default ChangePasswordModalForm;
