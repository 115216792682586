import { buildStateAbbreviationTest } from "settings/yup/tests/stateAbbreviationTest";
import { buildZipCodeTest } from "settings/yup/tests/zipCodeTest";
import { TEXT_INPUT_LENGTH } from "constants/forms";
import yup from "settings/yup";

const addressSchema = yup.object().shape({
  streetAddress: yup
    .string()
    .required(),
  complement: yup
    .string()
    .max(TEXT_INPUT_LENGTH),
  city: yup
    .string()
    .required(),
  state: yup
    .string()
    .test(buildStateAbbreviationTest(true))
    .required(),
  zipCode: yup
    .string()
    .test(buildZipCodeTest(true))
    .required(),
  latitude: yup
    .string(),
  longitude: yup
    .string(),
});

export interface AddressSchema {
  streetAddress: string;
  complement: string;
  city: string;
  state: string;
  zipCode: string;
  latitude: string;
  longitude: string;
}

export default addressSchema;
