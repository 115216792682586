import React from "react";
import {
  MenuButton,
  MenuList,
  MenuItem,
  Stack,
  Menu,
  Text,
} from "@chakra-ui/react";

import DropdownIcon from "settings/theme/icons/DropdownIcon";

import { ApplicationsOverviewCardHeaderProps } from "./types";

const ApplicationsOverviewCardHeader: React.FC<ApplicationsOverviewCardHeaderProps> = ({
  showPeriodSelector,
  setSelectedPeriod,
  selectedPeriod,
  periodOptions,
  subtitle,
  title,
}) => (
  <Stack
    justifyContent="space-between"
    alignItems="center"
    spacing={2}
    isInline
    mb={3}
  >
    <Text
      textTransform="uppercase"
      fontFamily="heading"
      fontSize="xs"
    >
      {title}
    </Text>

    {
      showPeriodSelector
        ? (
          <Menu>
            <MenuButton>
              <Stack
                alignItems="center"
                cursor="pointer"
                isInline
              >
                <Text
                  color="gray.400"
                  fontSize="xxs"
                >
                  {selectedPeriod?.label}
                </Text>

                <DropdownIcon
                  color="gray.400"
                  boxSize={3}
                />
              </Stack>
            </MenuButton>

            <MenuList
              maxHeight="200px"
              overflowY="auto"
              zIndex={16}
            >
              {
                Object.values(periodOptions).map((option) => (
                  <MenuItem
                    onClick={setSelectedPeriod(option)}
                    key={option.label}
                  >
                    {option.label}
                  </MenuItem>
                ))
              }
            </MenuList>
          </Menu>
        )
        : (
          <Text
            color="gray.400"
            fontSize="xxs"
          >
            {subtitle}
          </Text>
        )
    }
  </Stack>
);

export default ApplicationsOverviewCardHeader;
