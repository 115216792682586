import React from "react";

import { FormStep, FormStepType, getExtraStepId } from "constants/formSteps";
import { ExtraSection as ExtraSectionType } from "generated/graphql";

import ExtraSection from ".";

/**
 * Parses one extra section into a wizard step.
 * @param extraSection The extra section.
 * @param subtitle The step subtitle.
 */
const parseExtraSection = (
  extraSection: ExtraSectionType,
  subtitle?: string,
): FormStep => {
  const step: FormStep = {
    id: getExtraStepId(extraSection.id),
    type: FormStepType.Optional,
    title: extraSection.name,
    extraSection,
    subtitle,
    component: () => (
      <ExtraSection
        extraSection={extraSection}
      />
    ),
    isOptional: true,
    isVisible: extraSection.show,
  };

  return step;
};

export default parseExtraSection;
