import * as React from "react";
import { IconProps } from "@chakra-ui/icons";
import { createIcon } from "@chakra-ui/react";

const DocumentIcon: React.FC<IconProps> = createIcon({
  viewBox: "0 0 32 32.433",
  path: (
    <>
      <path
        fill="none"
        d="M0,0H32V32H0Z"
      />
      <path
        fill="currentColor"
        d="M10.4,24.4H23.2v3.2H10.4Zm0-6.4H23.2v3.2H10.4ZM20,2H7.2A3.209,3.209,0,0,0,4,5.2V30.8A3.2,3.2,0,0,0,7.184,34H26.4a3.209,3.209,0,0,0,3.2-3.2V11.6Zm6.4,28.8H7.2V5.2H18.4v8h8Z"
        transform="translate(-1 -1.667)"
        stroke="#fff"
        strokeWidth="0.2"
      />
    </>
  ),
});

export default DocumentIcon;
