import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  IconButton,
  Heading,
  Stack,
  Flex,
} from "@chakra-ui/react";
import classNames from "classnames";

import { profilePrintChartCss } from "styles/css";
import Box from "components/Box";

import { ChartOption, TrackingProfileChartsProps } from "./types";
import { chartOptions, getChartComponent } from "./charts";

/**
 * Renders a chart within a styled card.
 * @param param0 Props.
 */
const TrackingProfileCharts: React.FC<TrackingProfileChartsProps> = ({
  comparisonDatasetLabel,
  comparisonColorScheme,
  showAverageValues,
  mainDatasetLabel,
  comparisonScores,
  containerProps,
  QualityTooltip,
  tourClassNames,
  colorScheme,
  scores,
  title,
}) => {
  const [selectedChart, setSelectedChart] = useState<ChartOption>("bar");

  const [t] = useTranslation();

  const handleSetSelectedChart = useCallback((newValue: ChartOption) => () => {
    setSelectedChart(newValue);
  }, []);

  const ChartComponent = getChartComponent(selectedChart);

  return (
    <Box
      w="full"
      h="full"
      css={profilePrintChartCss}
      borderRadius={10}
      {...(containerProps ?? {})}
    >
      <Flex
        direction="column"
        h="full"
      >
        <Stack
          isInline
          alignItems="center"
          justifyContent="space-between"
        >
          <Flex alignItems="center">
            <Heading
              className={classNames(tourClassNames?.qualities)}
              fontSize="md"
            >
              {title}
            </Heading>

            {
              QualityTooltip
            }
          </Flex>

          <Stack
            isInline
            className={classNames(tourClassNames?.chartView)}
          >
            {
              chartOptions.map(({
                icon: Icon,
                id,
              }) => (
                <IconButton
                  onClick={handleSetSelectedChart(id)}
                  aria-label={t("buttons.toggle")}
                  variant="ghost"
                  key={id}
                  icon={(
                    <Icon color={selectedChart === id ? "black" : "gray.300"} />
                  )}
                />
              ))
            }
          </Stack>
        </Stack>

        <Flex
          className={classNames(tourClassNames?.qualitiesDefinition)}
          justifyContent="center"
          alignItems="center"
          position="relative"
          minHeight="sm"
          h="full"
          p={3}
        >
          <ChartComponent
            comparisonDatasetLabel={comparisonDatasetLabel}
            comparisonColorScheme={comparisonColorScheme}
            showAverageValues={showAverageValues}
            mainDatasetLabel={mainDatasetLabel}
            comparisonScores={comparisonScores}
            colorScheme={colorScheme}
            scores={scores}
          />
        </Flex>
      </Flex>
    </Box>
  );
};

export default TrackingProfileCharts;
