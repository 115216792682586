import React from "react";

import Loading from "components/Loading";

import useApplicantFormResponse from "../hooks/useFormResponse/useApplicantFormResponse";
import ExtraSectionForm from "./ExtraSectionForm";
import { ExtraSectionProps } from "./types";

const ExtraSection: React.FC<ExtraSectionProps> = ({
  extraSection,
}) => {
  const [formResponse, loading] = useApplicantFormResponse();

  if (loading) {
    return (
      <Loading />
    );
  }

  return (
    <ExtraSectionForm
      extraSection={extraSection}
      formResponse={formResponse}
    />
  );
};

export default ExtraSection;
