import { useShallowCompareEffect } from "react-use";
import { useDebouncedCallback } from "use-debounce";
import { useTranslation } from "react-i18next";

import { onMutationSuccess, onQueryError } from "utils/queryHandlers";
import { UpdateFormParams } from "generated/graphql";
import useToast from "hooks/useToast";

import { UseBaseCompanyOnboardingEffectsOptions } from "../types";

/**
 * Handles side-effects in a company onboarding form to run one update mutation.
 * Receives a set of fields from a given schema to watch for state changes, and when
 * the value of any of these fields change, it will execute the mutation provided.
 */
function useCompanyOnboardingBaseEffects<Schema>({
  overrideGetValues,
  mutationPayload,
  mutation,
  fields,
  form,
}: UseBaseCompanyOnboardingEffectsOptions<Schema>): void {
  const [showToast] = useToast();
  const [t] = useTranslation();

  const watchResult = form.watch(fields) as Partial<UpdateFormParams>;

  const [mutate] = mutation;

  const handleUpdateForm = useDebouncedCallback(
    (payload: Partial<UpdateFormParams>) => {
      const params = overrideGetValues?.(form) ?? payload ?? {};

      if (Object.keys(params).length <= 0) {
        return;
      }

      mutate({
        variables: {
          ...(mutationPayload ?? {}),
          params,
        },
      })
        .then(() => {
          onMutationSuccess(t("actions.information_updated"), showToast);
        })
        .catch((error) => {
          onQueryError(error, showToast);
        });
    },
    1000,
  );

  useShallowCompareEffect(() => {
    if (!form.formState.isDirty) {
      return;
    }

    handleUpdateForm.callback(watchResult);
  }, [
    form.formState.isDirty,
    watchResult,
  ]);
}

export default useCompanyOnboardingBaseEffects;
