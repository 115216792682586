import React, { useCallback, useMemo } from "react";

import LandingPage from "components/LandingPage";
import { useModal } from "contexts/modal";
import { ShowModalOptions } from "contexts/modal/types";

import { LandingPageType, UseLandingPagePreviewPayload } from "./types";

/**
 * Parses the modal options to show the full screen modal.
 * @param landingPage The landing page data.
 */
const getModalOptions = (landingPage: LandingPageType): ShowModalOptions<unknown> => ({
  title: "",
  size: "full",
  component: () => (
    <LandingPage landingPage={landingPage} />
  ),
  modalHeaderProps: {
    padding: 0,
  },
  modalContentProps: {
    overflow: "scroll",
    marginTop: 0,
    marginBottom: 0,
    height: "100%",
  },
  modalCloseButtonProps: {
    zIndex: 100,
    margin: 5,
    fontSize: "sm",
  },
});

/**
 * Hook that returns a curried function that receives the landing page data and handles
 * showing the preview modal for that given landing page data.
 *
 * @param landingPage The landing page data.
 */
const useLandingPagePreview = (): UseLandingPagePreviewPayload => {
  const [showModal] = useModal();

  const showLandingPagePreview = useCallback((landingPage: LandingPageType) => () => {
    showModal(getModalOptions(landingPage));
  }, [
    showModal,
  ]);

  const payload = useMemo<UseLandingPagePreviewPayload>(() => [
    showLandingPagePreview,
  ], [
    showLandingPagePreview,
  ]);

  return payload;
};

export default useLandingPagePreview;
