import React from "react";
import { Flex } from "@chakra-ui/react";

import { LandingPageTemplateProps } from "./types";
import JobDescriptionAside from "./JobDescriptionAside";
import TemplateBackground from "./TemplateBackground";

const Template6: React.FC<LandingPageTemplateProps> = ({
  navigateToForm,
  landingPage,
}) => (
  <Flex
    width="100vw"
    direction={["column-reverse", null, "row"]}
    justifyContent="space-between"
  >
    <JobDescriptionAside
      navigateToForm={navigateToForm}
      landingPage={landingPage}
      logoProps={{
        alignSelf: "flex-start",
      }}
    />

    <TemplateBackground
      minHeight={["50vh", "100vh"]}
      headerImageUrl={landingPage?.headerImageUrl}
      backgroundPosition={["20%", "center"]}
      backgroundAttachment="fixed"
    />
  </Flex>
);

export default Template6;
