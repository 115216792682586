import * as React from "react";
import { IconProps } from "@chakra-ui/icons";
import { createIcon } from "@chakra-ui/react";

const InfoIcon: React.FC<IconProps> = createIcon({
  path: (
    <path
      fill="currentColor"
      id="Path_5699"
      data-name="Path 5699"
      d="M11,7h2V9H11Zm0,4h2v6H11Zm1-9A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8.011,8.011,0,0,1,12,20Z"
    />
  ),
});

export default InfoIcon;
