import { useCallback, useMemo } from "react";

import {
  GetBaseImpersonatedCompanyQueryVariables,
  GetBaseCurrentCompanyQueryVariables,
  GetBaseImpersonatedCompanyQuery,
  GetBaseCurrentCompanyQuery,
} from "generated/graphql";
import useAdminImpersonationStore from "components/AdminImpersonation/store";

import {
  QueryVariablesResolver,
  UseGetCompanyResult,
  QueryResolver,
  QueryOptions,
} from "./types";

/**
 * Generic hook that returns the company, obtained with the `useGetCurrentCompanyQuery` param.
 *
 * If the current user is an admin and has a selected company to impersonate, it'll query
 * for that given company instead, using the `useGetImpersonatedCompanyQuery`.
 */
function useGetCompany<
  TCurrentCompanyData
    extends GetBaseCurrentCompanyQuery,

  TCurrentCompanyVariables
    extends QueryVariablesResolver<GetBaseCurrentCompanyQueryVariables>,

  TImpersonatedCompanyData
    extends GetBaseImpersonatedCompanyQuery,

  TImpersonatedCompanyVariables
    extends QueryVariablesResolver<GetBaseImpersonatedCompanyQueryVariables>,
> (
  useGetCurrentCompanyQuery: QueryResolver<
    TCurrentCompanyData,
    TCurrentCompanyVariables
  >,
  useGetImpersonatedCompanyQuery: QueryResolver<
    TImpersonatedCompanyData,
    TImpersonatedCompanyVariables
  >,
  queryOptions?: QueryOptions,
): UseGetCompanyResult<TCurrentCompanyData, TImpersonatedCompanyData> {
  const impersonatedCompanyId = useAdminImpersonationStore((store) => store.companyId);

  const {
    loading: getCurrentCompanyLoading,
    refetch: getCurrentCompanyRefetch,
    error: getCurrentCompanyError,
    data: getCurrentCompanyData,
  } = useGetCurrentCompanyQuery({
    ...(queryOptions ?? {}),
    skip: !!(
      !!impersonatedCompanyId
      || queryOptions?.skip
    ),
  });

  const {
    loading: getImpersonatedCompanyLoading,
    refetch: getImpersonatedCompanyRefetch,
    error: getImpersonatedCompanyError,
    data: getImpersonatedCompanyData,
  } = useGetImpersonatedCompanyQuery({
    ...(queryOptions ?? {}),
    skip: !!(
      !impersonatedCompanyId
      || queryOptions?.skip
    ),
    variables: {
      ...(queryOptions?.variables ?? {}),
      companyId: impersonatedCompanyId as number,
    } as TImpersonatedCompanyVariables,
  });

  const refetch = useCallback(() => {
    if (impersonatedCompanyId) {
      const payload = {
        ...(queryOptions?.variables ?? {}),
        companyId: impersonatedCompanyId as number,
      } as TImpersonatedCompanyVariables;

      getImpersonatedCompanyRefetch?.(payload);

      return;
    }

    getCurrentCompanyRefetch?.();
  }, [
    getImpersonatedCompanyRefetch,
    getCurrentCompanyRefetch,
    queryOptions,
    impersonatedCompanyId,
  ]);

  const company = useMemo(() => (
    impersonatedCompanyId
      ? getImpersonatedCompanyData?.getCompany
      : getCurrentCompanyData?.currentUser?.member?.company
  ), [
    getCurrentCompanyData,
    getImpersonatedCompanyData,
    impersonatedCompanyId,
  ]);

  const loading = (
    getCurrentCompanyLoading
    || getImpersonatedCompanyLoading
  );

  const error = (
    getCurrentCompanyError
    || getImpersonatedCompanyError
  );

  const payload = useMemo<UseGetCompanyResult<TCurrentCompanyData, TImpersonatedCompanyData>>(
    () => [
      company,
      {
        impersonatedCompanyData: getImpersonatedCompanyData,
        currentCompanyData: getCurrentCompanyData,
        refetch,
        loading,
        error,
      },
    ],
    [
      getImpersonatedCompanyData,
      getCurrentCompanyData,
      refetch,
      company,
      loading,
      error,
    ],
  );

  return payload;
}

export default useGetCompany;
