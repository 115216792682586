import theme from "settings/theme";

export const buttonCss = {
  ":focus": {
    outline: 0,
    boxShadow: "none",
  },
  ":hover": {
    "*": {
      fontFamily: theme.fonts.bold,
    },
  },
};
