import { gql } from "@apollo/client";

export const updateFormRefetchQueries = [
  "GetForm",
];

export default gql`
  mutation UpdateForm (
    $id: Int!,
    $params: UpdateFormParams!
  ) {
    updateForm (id: $id, params: $params) {
      id
    }
  }
`;
