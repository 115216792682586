import * as R from "remeda";

import { SpiderChartScore } from "generated/graphql";
import getMultilineText from "utils/getMultilineText";

import { DescriptionsProps } from "./SpiderChart/types";

const formatDescriptions = (scores: SpiderChartScore[]): DescriptionsProps[] => {
  if (!scores) {
    return [];
  }

  return R.pipe(
    scores,
    R.sortBy((score) => score.info?.position),
    R.map((score) => ({
      name: score.name,
      description: score.info?.description,
    })),
    R.map((score) => {
      const splittedDescription = score.description?.split("\n  ");

      return {
        descriptionKeywords: getMultilineText(splittedDescription?.[0]),
        descriptionText: getMultilineText(splittedDescription?.[1]),
        label: score.name,
      };
    }),
  );
};

export default formatDescriptions;
