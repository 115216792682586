import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import useSteps from "views/Forms/ApplicantForm/hooks/useSteps";
import { FormTypeEnum } from "generated/graphql";

import { EditFormParams } from "./types";
import EditForm from ".";

const EditApplicationForm: React.FC = () => {
  const [t] = useTranslation();

  const { id } = useParams<EditFormParams>();

  return (
    <EditForm
      title={t("forms.edit_full_application.title")}
      formType={FormTypeEnum.Application}
      formId={parseInt(id)}
      useSteps={useSteps}
    />
  );
};

export default EditApplicationForm;
