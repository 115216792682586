import React from "react";

import QuestionCard from "components/Forms/CompanyOnboardingForm/Cards/QuestionCard";

import questions from "./questions";

const Availability: React.FC = () => (
  <>
    {
      questions.map((question) => (
        <QuestionCard
          fields={question.fields}
          title={question.title}
          key={question.title}
        />
      ))
    }
  </>
);

export default Availability;
