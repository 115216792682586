import React, { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import * as R from "remeda";
import {
  IconButton,
  Collapse,
  Heading,
  Stack,
  Flex,
  Grid,
} from "@chakra-ui/react";

import MinusIcon from "settings/theme/icons/MinusIcon";
import PlusIcon from "settings/theme/icons/PlusIcon";
import Box from "components/Box";

import AdditionalInformationData from "./AdditionalInformationData";
import { ExtraInformationCardProps } from "./types";
import ExtraAnswerData from "./ExtraAnswerData";

const ExtraInformationCard: React.FC<ExtraInformationCardProps> = ({
  profileType,
  title,
  data,
}) => {
  const [t] = useTranslation();

  const [isOpen, setIsOpen] = useState<boolean>(true);

  const toggleIsOpen = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  const extraAnswers = data?.extraAnswers ?? [];

  const extraAnswersArray = R.pipe(
    Object.keys(extraAnswers),
    R.map((key) => extraAnswers[key]),
  );

  const answerGroupsCount = extraAnswersArray.length + 1;

  return (
    <Box
      w="full"
      h="auto"
      gridColumn="1 / span 2"
      borderRadius={10}
    >
      <Flex
        direction="column"
        h="full"
      >
        <Stack
          justifyContent="space-between"
          alignItems="center"
          isInline
          p={2}
        >
          <Heading
            color="primary.200"
            fontSize="md"
          >
            {title}
          </Heading>

          <IconButton
            aria-label={t("buttons.toggle")}
            onClick={toggleIsOpen}
            variant="ghost"
            icon={(
              isOpen
                ? <MinusIcon />
                : <PlusIcon />
            )}
          />
        </Stack>

        <Collapse in={isOpen}>
          <Grid
            templateColumns="repeat(2, 50%)"
            h="full"
          >
            <AdditionalInformationData
              answerGroupsCount={answerGroupsCount}
              profileType={profileType}
              data={data}
            />

            {
              extraAnswersArray.map((extraAnswer, index) => (
                <ExtraAnswerData
                  answerGroupsCount={answerGroupsCount}
                  key={extraAnswer.sectionName}
                  extraAnswer={extraAnswer}
                  position={index + 1}
                />
              ))
            }
          </Grid>
        </Collapse>
      </Flex>
    </Box>
  );
};

export default ExtraInformationCard;
