import * as R from "remeda";

/**
 * Sorts an array of strings
 *
 * @example sortStringArray(["Pedro Bini", "Laura Beatris"]) -> ["Laura Beatris", "Pedro Bini"]
 *
 * @param values The array of strings
 */
const sortStringArray = (values: string[]): string[] => R.sort(values, (a, b) => {
  if (a > b) {
    return -1;
  }
  if (b > a) {
    return 1;
  }

  return 0;
});

export default sortStringArray;
