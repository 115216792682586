import React from "react";
import { Text } from "@chakra-ui/react";

import { TextLinkProps } from "./types";

const TextLink: React.FC<TextLinkProps> = ({
  color = "gray.400",
  cursor = "pointer",
  fontSize = "xs",
  onClick,
  ...rest
}) => (
  <Text
    _hover={{ textDecoration: "underline" }}
    fontSize={fontSize}
    onClick={onClick}
    cursor={cursor}
    color={color}
    {...rest}
  />
);

export default TextLink;
