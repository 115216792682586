import React from "react";
import { useTranslation } from "react-i18next";
import { IconButton } from "@chakra-ui/react";

import { DateInputProps } from "components/FormComponents/DatePickerInput/types";
import CalendarIcon from "settings/theme/icons/CalendarIcon";
import Input from "components/FormComponents/Input";
import i18n from "translations/i18n";

const DateInput = React.forwardRef<HTMLElement, DateInputProps>((
  {
    placeholder = i18n.t("buttons.pick_a_date"),
    disabled,
    ...props
  },
  ref,
) => {
  const [t] = useTranslation();

  return (
    <Input
      {...props}
      placeholder={placeholder}
      isDisabled={disabled}
      autoComplete="off"
      cursor="pointer"
      ref={ref}
      rightElement={(
        <IconButton
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onClick={props.onClick as any}
          aria-label={t("buttons.select")}
          isDisabled={disabled}
          icon={<CalendarIcon />}
        />
      )}
    />
  );
});

export default DateInput;
