import { buildStateAbbreviationTest } from "settings/yup/tests/stateAbbreviationTest";
import { buildZipCodeTest } from "settings/yup/tests/zipCodeTest";
import { TEXT_INPUT_LENGTH } from "constants/forms";
import yup from "settings/yup";

const updateLocationSchema = yup.object().shape({
  streetAddress: yup
    .string()
    .required(),
  zipCode: yup
    .string()
    .test(buildZipCodeTest(true))
    .required(),
  city: yup
    .string()
    .required(),
  state: yup
    .string()
    .test(buildStateAbbreviationTest(true))
    .required(),
  complement: yup
    .string()
    .max(TEXT_INPUT_LENGTH),
});

export interface LocationSchema {
  streetAddress: string;
  zipCode: string;
  city: string;
  state: string;
  complement: string;
}

export default updateLocationSchema;
