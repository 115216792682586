import React, { useEffect } from "react";
import { Wrap } from "@chakra-ui/react";

import { landingPageTemplates } from "components/LandingPage/Templates/landingPagesTemplatesData";

import { SelectLandingPageTemplateFormProps } from "./types";
import useSelectLandingPageStore from "./store";
import TemplateCard from "./TemplateCard";

const SelectLandingPageTemplateForm: React.FC<SelectLandingPageTemplateFormProps> = ({
  showEditButton = false,
  allowDeselect = true,
  onTemplateDelete,
  onEditTemplate,
  layoutLabel,
  onChange,
}) => {
  useEffect(() => {
    useSelectLandingPageStore.setState({
      layoutLabel,
    });
  }, [
    layoutLabel,
  ]);

  return (
    <Wrap spacing={5}>
      {
        landingPageTemplates.map((template) => (
          <TemplateCard
            onTemplateDelete={onTemplateDelete}
            onEditTemplate={onEditTemplate}
            showEditButton={showEditButton}
            allowDeselect={allowDeselect}
            key={template.layoutLabel}
            template={template}
            onChange={onChange}
          />
        ))
      }
    </Wrap>
  );
};

export default SelectLandingPageTemplateForm;
