import * as R from "remeda";

import { ExtraTokensPacks } from "views/Dashboard/DashboardContent/Pages/Subscription/types";
import { SelectOption } from "components/FormComponents/Select/types";
import i18n from "translations/i18n";

/**
 * Parses the select token pack options.
 */
const parseTokenPackOptions = (
  extraTokensPacks: ExtraTokensPacks,
): SelectOption[] => R.pipe(
  extraTokensPacks,
  R.map((tokenPack): SelectOption => {
    const priceLabel = i18n.t("dashboard.subscription.add_tokens_modal.token_quantity", {
      count: tokenPack.quantity,
    });

    const price = (
      tokenPack.activeDiscount?.discountedPrice?.formatted
      ?? tokenPack.price.formatted
    );

    return {
      label: `${priceLabel} = ${price}`,
      value: tokenPack.id,
    };
  }),
);

export default parseTokenPackOptions;
