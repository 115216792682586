import React from "react";
import { Heading, Stack } from "@chakra-ui/react";

import { PageHeaderProps } from "components/PageHeaders/types";

const PageHeader: React.FC<PageHeaderProps> = ({
  childrenContainerProps,
  children,
  title,
  ...rest
}) => (
  <Stack
    justifyContent="space-between"
    alignItems="center"
    isInline
    w="full"
    {...rest}
  >
    <Heading
      textTransform="uppercase"
      color="primary.500"
      fontSize="md"
    >
      {title}
    </Heading>

    {
      children && (
        <Stack
          isInline
          {...(childrenContainerProps ?? {})}
        >
          {children}
        </Stack>
      )
    }
  </Stack>
);

export default PageHeader;
