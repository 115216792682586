import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

/**
 * Resets the page scroll after each navigation to make sure new content is displayed properly.
 * The scroll reset should only happen when actually changing routes,
 * and should not be triggered when simply updating the path.
 */
const useResetScrollOnNavigation = (): void => {
  const [currentPathname, setCurrentPathname] = useState("");
  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen((path) => {
      if (currentPathname !== path.pathname) {
        setCurrentPathname(path.pathname);

        window.scrollTo(0, 0);
      }
    });

    return unlisten;
  }, [
    currentPathname,
    history,
  ]);
};

export default useResetScrollOnNavigation;
