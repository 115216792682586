import { gql } from "@apollo/client";

export const createOrUpdateLandingPageRefetchQueries = [
  "GetEditLandingPageTemplateCurrentCompany",
  "GetCurrentCompanyJobs",
];

export default gql`
  mutation CreateOrUpdateLandingPage ($params: LandingPageCreateOrUpdateParams!){
    createOrUpdateLandingPage(params: $params) {
      id
    }
  }
`;
