import yup from "settings/yup";

const requiredTextSchema = yup.object().shape({
  text: yup
    .string()
    .required(),
});

export interface RequiredTextSchema {
  text: string;
}

export default requiredTextSchema;
