import { SkeletonCircle, SkeletonText, Stack } from "@chakra-ui/react";
import React from "react";

import makeProgressiveArray from "utils/makeProgressiveArray";

import { AvatarListSkeletonProps } from "./types";

const AvatarListSkeleton: React.FC<AvatarListSkeletonProps> = ({
  size = "10",
  count,
}) => {
  const items = makeProgressiveArray(count);

  return (
    <Stack spacing={2}>
      {
        items.map((item) => (
          <Stack
            alignItems="center"
            key={item}
            isInline
          >
            <SkeletonCircle
              minHeight={size}
              minWidth={size}
              size={size as string}
            />

            <SkeletonText
              noOfLines={1}
              w="full"
            />
          </Stack>
        ))
      }
    </Stack>
  );
};

export default AvatarListSkeleton;
