import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Stack } from "@chakra-ui/react";

import GoBackIcon from "settings/theme/icons/GoBackIcon";
import TextLink from "components/TextLink";
import { noPrintCss } from "styles/css";
import i18n from "translations/i18n";

import { GoBackHeaderProps } from "./types";

const GoBackHeader: React.FC<GoBackHeaderProps> = ({
  title = i18n.t("buttons.back"),
  childrenContainerProps,
  showTitle = true,
  children,
  ...rest
}) => {
  const history = useHistory();

  const handleGoBack = useCallback(() => {
    history.goBack();
  }, [
    history,
  ]);

  return (
    <Stack
      justifyContent="space-between"
      alignItems="center"
      css={noPrintCss}
      isInline
      w="full"
      {...rest}
    >
      <TextLink
        onClick={handleGoBack}
        fontFamily="heading"
        alignItems="center"
        fontSize="sm"
        color="black"
        d="flex"
      >
        <GoBackIcon
          color="black"
          mr={3}
        />

        {showTitle && title}
      </TextLink>

      {
        children && (
          <Stack
            isInline
            {...(childrenContainerProps ?? {})}
          >
            {children}
          </Stack>
        )
      }
    </Stack>
  );
};

export default GoBackHeader;
