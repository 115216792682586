import { CalendarIcon } from "@chakra-ui/icons";

import DashboardIcon from "settings/theme/icons/DashboardIcon";
import WalletIcon from "settings/theme/icons/WalletIcon";
import TeamIcon from "settings/theme/icons/TeamIcon";
import HelpIcon from "settings/theme/icons/HelpIcon";
import i18n from "translations/i18n";

import { DashboardNavbarButton, DashboardPage } from "./types";

const HELP_DESK_URI = "https://milimatch.com/tutorials/";

// TODO: Enable icons as pages are implemented.
const dashboardNavbarButtons: Record<DashboardPage, DashboardNavbarButton> = {
  summary: {
    page: "summary",
    name: i18n.t("dashboard.dashboard.title"),
    icon: DashboardIcon,
    iconBoxSize: 6,
  },
  team: {
    page: "team",
    name: i18n.t("dashboard.team.title"),
    icon: TeamIcon,
    iconBoxSize: 6,
  },
  subscription: {
    page: "subscription",
    name: i18n.t("dashboard.subscription.title"),
    icon: CalendarIcon,
    iconBoxSize: 5,
  },
  wallet: {
    page: "wallet",
    name: i18n.t("dashboard.wallet.title"),
    icon: WalletIcon,
    iconBoxSize: 6,
  },
  help: {
    page: HELP_DESK_URI,
    name: i18n.t("dashboard.help.title"),
    icon: HelpIcon,
    iconBoxSize: 6,
    isExternalPage: true,
  },
};

/**
 * Defines all dashboard navbar buttons.
 */
const makeDashboardNavbarButtons = (isManagerOrAdmin: boolean): DashboardNavbarButton[] => {
  if (isManagerOrAdmin) {
    return [
      dashboardNavbarButtons.summary,
      dashboardNavbarButtons.team,
      dashboardNavbarButtons.subscription,
      dashboardNavbarButtons.wallet,
      dashboardNavbarButtons.help,
    ];
  }

  return [
    dashboardNavbarButtons.summary,
    dashboardNavbarButtons.help,
  ];
};

export default makeDashboardNavbarButtons;
