import * as React from "react";
import { IconProps } from "@chakra-ui/icons";
import { createIcon } from "@chakra-ui/react";

const InboxIcon: React.FC<IconProps> = createIcon({
  viewBox: "0 0 56 56",
  path: (
    <g
      id="inbox"
      transform="translate(0 -85.4)"
    >
      <g
        id="Group_14398"
        data-name="Group 14398"
        transform="translate(0 96.4)"
      >
        <rect
          fill="none"
          id="Rectangle_5048"
          data-name="Rectangle 5048"
          width="56"
          height="56"
          transform="translate(0 -11)"
        />
        <path
          fill="currentColor"
          id="Path_7261"
          data-name="Path 7261"
          d="M55.827,107.851,50.555,97.307a1.641,1.641,0,0,0-1.467-.907H6.912a1.641,1.641,0,0,0-1.467.907L.173,107.851a1.641,1.641,0,0,0-.173.734v21.088a1.641,1.641,0,0,0,1.641,1.641H54.359A1.641,1.641,0,0,0,56,129.672V108.584A1.641,1.641,0,0,0,55.827,107.851Zm-47.9-8.169H48.074l3.631,7.263H38.544a1.641,1.641,0,0,0-1.641,1.641,8.9,8.9,0,0,1-17.806,0,1.641,1.641,0,0,0-1.641-1.641H4.3Zm44.792,28.35H3.281V110.225H15.926a12.186,12.186,0,0,0,24.149,0H52.719Z"
          transform="translate(0 -96.4)"
        />
      </g>
    </g>
  ),
});
export default InboxIcon;
