import React from "react";
import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";
import { Stack } from "@chakra-ui/react";

import respondedBySchema, { RespondedBySchema } from "settings/yup/schemas/form/respondedBySchema";
import { respondedByFormOptions } from "constants/respondedBy";
import RadioGroup from "components/FormComponents/RadioGroup";

import { RespondedByFormProps } from "./types";
import useFormStep from "../hooks/useFormStep";

const RespondedBy: React.FC<RespondedByFormProps> = ({
  formResponse,
}) => {
  const [t] = useTranslation();

  const {
    control,
    errors,
  } = useFormStep<RespondedBySchema>({
    schema: respondedBySchema,
    formOptions: {
      defaultValues: {
        respondedBy: formResponse?.respondedBy ?? "",
      },
    },
  });

  return (
    <Stack spacing={6}>
      <Controller
        name="respondedBy"
        control={control}
        render={(props) => (
          <RadioGroup
            {...props}
            errors={errors}
            options={respondedByFormOptions}
            title={`${t("forms.steps.responded_by.who_is_filling_this_out")}:*`}
          />
        )}
      />
    </Stack>
  );
};

export default RespondedBy;
