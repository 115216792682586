import React from "react";

import { LandingPageTemplateLayoutLabel } from "components/LandingPage/Templates/landingPagesTemplatesData";
import SelectLandingPageTemplateForm from "components/Forms/SelectLandingPageTemplateForm";
import useCompanyOnboardingJob from "hooks/useCompanyOnboardingJob";
import Loading from "components/Loading";

const SelectLandingPage: React.FC = () => {
  const [job, loadingJob] = useCompanyOnboardingJob();

  if (loadingJob) {
    return (
      <Loading />
    );
  }

  const layoutLabel = job?.landingPage?.layoutLabel as LandingPageTemplateLayoutLabel;

  return (
    <SelectLandingPageTemplateForm layoutLabel={layoutLabel} />
  );
};

export default SelectLandingPage;
