import { useCallback } from "react";
import { useCopyToClipboard } from "react-use";
import * as R from "remeda";
import { useTranslation } from "react-i18next";

import useToast from "hooks/useToast";

import {
  UseShareOptions,
  UseShareResult,
} from "./types";

/**
 * Exposes the Open Graph Share functionality. If the share is not supported, it will simply
 * copy to the clipboard and show a success toast instead.
 */
const useShare = (): UseShareResult => {
  const [, copyToClipboard] = useCopyToClipboard();
  const [showToast] = useToast();
  const [t] = useTranslation();

  const share = useCallback(({
    title,
    text,
    url,
  }: UseShareOptions) => {
    if (navigator?.share) {
      navigator.share({
        title,
        text,
        url,
      })
        .catch(R.noop);

      return;
    }

    copyToClipboard(url);

    showToast({
      title: t("actions.copied_to_clipboard"),
      status: "info",
    });
  }, [
    copyToClipboard,
    showToast,
    t,
  ]);

  return share;
};

export default useShare;
