import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";
import { Stack } from "@chakra-ui/react";

import sourceSchema, { SourceSchema } from "settings/yup/schemas/form/sourceSchema";
import RadioGroup from "components/FormComponents/RadioGroup";
import Input from "components/FormComponents/Input";
import useFormStore from "views/Forms/store";

import { getDefaultValues, referrerOptionsWithInput } from "./formValues";
import useFormStep from "../hooks/useFormStep";
import { SourceFormProps } from "./types";

const SourceForm: React.FC<SourceFormProps> = ({
  formResponse,
}) => {
  const [t] = useTranslation();

  const referrerOptions = useFormStore((store) => store.form?.referrerOptions ?? []);

  const {
    formState,
    setValue,
    control,
    errors,
    watch,
  } = useFormStep<SourceSchema>({
    schema: sourceSchema,
    formOptions: {
      defaultValues: getDefaultValues(referrerOptions, formResponse),
    },
    overrideGetValues: ({
      getValues,
    }) => () => {
      const values = getValues();

      return {
        source: values.sourceInput || values.source,
      };
    },
  });

  const source = watch("source");

  useEffect(() => {
    if (!formState.isDirty) {
      return;
    }

    setValue("sourceInput", "");
  }, [
    formState.isDirty,
    setValue,
    source,
  ]);

  const shouldShowInput = referrerOptionsWithInput.includes(source);

  const options = referrerOptions.map((option) => ({
    label: option,
    value: option,
  }));

  return (
    <Stack spacing={6}>
      <Controller
        name="source"
        control={control}
        render={(props) => (
          <RadioGroup
            {...props}
            errors={errors}
            options={options}
            title={`${t("forms.steps.source.how_did_you_hear_about_this_job")}:*`}
          />
        )}
      />

      {
        shouldShowInput && (
          <Controller
            as={Input}
            name="sourceInput"
            control={control}
            errors={errors}
            title={t("forms.steps.source.if_you_were_referred_to_this_position_by")}
          />
        )
      }
    </Stack>
  );
};

export default SourceForm;
