import { AssertingTestOptions } from "yup";

import i18n from "translations/i18n";
import removeSpecialChars from "utils/removeSpecialChars";

/**
 * Applies the min words test validation in one schema field.
 *
 * This test ensures the field have at least 5 words. It ignores numbers, white-spaces
 * and special chars.
 */
const minWordsTest = (minWords = 5): AssertingTestOptions<string> => ({
  name: "hasMinWords",
  exclusive: false,
  message: i18n.t("yup.invalid_field_min_words", { min: minWords }),
  test(value: string): value is string {
    return (
      value
        ? removeSpecialChars(value)
          .split(" ")
          .filter(val => val.trim().length > 0)
          .length >= minWords
        : false
    );
  },
});

export default minWordsTest;
