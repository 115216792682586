import React, { ChangeEvent, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Avatar,
  Button,
  Flex,
  Text,
} from "@chakra-ui/react";
import { noop } from "remeda";

import AvatarInput from "components/FormComponents/AvatarInput";
import { parseBase64ImageFromFile } from "utils/base64";
import LogoFallback from "components/LogoFallback";

import { AvatarPreviewInputProps } from "./types";
import { logoStyles } from "./styles";

const AvatarPreviewInput: React.FC<AvatarPreviewInputProps> = ({
  isLogo = false,
  defaultPreview,
  onChange,
}) => {
  const [t] = useTranslation();
  const [avatarPreview, setAvatarPreview] = useState<string>();

  const css = isLogo
    ? logoStyles
    : undefined;

  const hasPreview = !!(avatarPreview || defaultPreview);

  const action = hasPreview
    ? t("buttons.change")
    : t("buttons.add");

  const buttonText = isLogo
    ? t("buttons.update_logo", { action })
    : t("buttons.update_photo", { action });

  const bg = (isLogo && hasPreview)
    ? "white"
    : "gray.200";

  const icon = isLogo
    ? <LogoFallback />
    : undefined;

  const handleAvatarChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    if (!event?.target?.files) {
      return;
    }

    const file = event?.target?.files[0];

    onChange(file);

    parseBase64ImageFromFile(file)
      .then((avatarSource) => {
        setAvatarPreview(avatarSource as string);
      })
      .catch(noop);
  }, [
    onChange,
    setAvatarPreview,
  ]);

  return (
    <AvatarInput onChange={handleAvatarChange}>
      <Flex
        direction="column"
        alignItems="center"
      >
        <Avatar
          size="2xl"
          src={avatarPreview || defaultPreview}
          css={css}
          bg={bg}
          icon={icon}
          shadow="md"
        />

        <Button
          mt={4}
          height="auto"
          variant="unstyled"
        >
          <Text
            fontFamily="bold"
            fontSize="xxs"
            color="primary.300"
          >
            {buttonText}
          </Text>
        </Button>
      </Flex>
    </AvatarInput>
  );
};

export default AvatarPreviewInput;
