import i18n from "translations/i18n";

import {
  AvailabilityOption,
  AvailabilityPeriod,
  ParsedAvailability,
} from "./types";

/**
 * Parses an availability options, returning the checkboxes to be rendered.
 * @param availability The availability value.
 */
const parseAvailabilityOptions = (
  availability: ParsedAvailability,
): AvailabilityOption[] => [
  {
    field: AvailabilityPeriod.Morning,
    label: i18n.t("periods.morning"),
    checked: availability.morning,
  },
  {
    field: AvailabilityPeriod.Afternoon,
    label: i18n.t("periods.afternoon"),
    checked: availability.afternoon,
  },
  {
    field: AvailabilityPeriod.Evening,
    label: i18n.t("periods.evening"),
    checked: availability.evening,
  },
  {
    field: AvailabilityPeriod.Overnight,
    label: i18n.t("periods.overnight"),
    checked: availability.overnight,
  },
];

export default parseAvailabilityOptions;
