
import React, { useCallback, useState } from "react";
import { ChromePicker } from "react-color";
import {
  Flex,
  FormControl,
  InputGroup,
} from "@chakra-ui/react";

import FieldErrorMessage from "components/FormComponents/FieldErrorMessage";
import FieldTitle from "components/FormComponents/FieldTitle";
import getErrorMessage from "utils/getErrorMessage";

import ColorPickerOverlay from "./ColorPickerOverlay";
import { ColorPickerInputProps } from "./types";
import ColorInput from "./ColorInput";

const ColorPickerInput = React.forwardRef<ChromePicker, ColorPickerInputProps>((
  {
    showErrorMessage = true,
    titleProps,
    onChange,
    errors,
    title,
    value,
    name,
    ...rest
  },
  ref,
) => {
  const error = getErrorMessage(name, errors);

  const [isOpen, setIsOpen] = useState(false);

  const toggleColorPicker = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  const handleColorChange = useCallback((newColor) => {
    onChange?.(newColor.hex);
  }, [
    onChange,
  ]);

  return (
    <>
      <ColorPickerOverlay
        toggleColorPicker={toggleColorPicker}
        isOpen={isOpen}
      />

      <FormControl>
        {
          title && (
            <FieldTitle
              htmlFor={name}
              {...(titleProps ?? {})}
            >
              {title}
            </FieldTitle>
          )
        }

        <InputGroup>
          <ColorInput
            toggleColorPicker={toggleColorPicker}
            onChange={onChange}
            value={value}
            name={name}
            {...rest}
          />

          {
            isOpen && (
              <Flex
                position="absolute"
                bottom="50px"
                zIndex={10}
              >
                <ChromePicker
                  onChange={handleColorChange}
                  color={value}
                  disableAlpha
                  ref={ref}
                />
              </Flex>
            )
          }
        </InputGroup>

        {
          showErrorMessage && (
            <FieldErrorMessage
              textAlign="end"
              error={error}
            />
          )
        }
      </FormControl>
    </>
  );
});

export default ColorPickerInput;
