import React from "react";
import {
  Flex,
  Text,
  Image,
  Button,
  VStack,
  Heading,
} from "@chakra-ui/react";
import LoremIpsum from "react-lorem-ipsum";
import { useTranslation } from "react-i18next";

import { JobDescriptionAsideProps } from "./types";

const JobDescriptionAside: React.FC<JobDescriptionAsideProps> = ({
  navigateToForm,
  landingPage,
  logoProps,
}) => {
  const [t] = useTranslation();

  return (
    <Flex
      width={["100%", null, "40%"]}
      height="100%"
      paddingX={5}
      paddingY={10}
      minHeight="100vh"
      direction="column"
      objectFit="cover"
      objectPosition="top"
      backgroundColor="white"
    >
      <Image
        src={landingPage?.logoUrl}
        height={65}
        alignSelf="flex-end"
        {...(logoProps ?? {})}
      />

      <Heading
        color="black"
        marginY={10}
        alignSelf="center"
        fontSize="xl"
      >
        {landingPage.title}
      </Heading>

      <VStack
        justifyContent="space-between"
        alignItems="flex-start"
        marginTop="auto"
        spacing={4}
      >
        <Heading
          color={landingPage.color}
          whiteSpace="break-spaces"
          fontSize="md"
        >
          {landingPage.subtitle || t("landing_pages_templates.subtitle")}
        </Heading>

        <Text
          whiteSpace="break-spaces"
          color="black"
          fontSize="xs"
        >
          {
            landingPage.jobDescription || (
              <LoremIpsum p={2} />
            )
          }
        </Text>
      </VStack>

      <Button
        width="100%"
        fontSize="sm"
        marginTop={10}
        fontFamily="heading"
        borderRadius={30}
        onClick={navigateToForm}
        color={landingPage.textColor}
        backgroundColor={landingPage.color}
      >
        {landingPage.buttonText}
      </Button>
    </Flex>
  );
};

export default JobDescriptionAside;
