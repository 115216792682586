import { useEffect } from "react";
import { useDebouncedCallback } from "use-debounce";
import { usePreviousDistinct } from "react-use";
import { useTranslation } from "react-i18next";

import {
  StatusUpdateEnum,
  UpdateApplicantParams,
  useUpdateApplicantMutation,
  UpdateApplicantStatusParams,
  useGetCurrentUserMemberQuery,
  useUpdateApplicantStatusMutation,
} from "generated/graphql";
import { ScheduleInterviewModalOnClosePayload, UseApplicantUpdateHandlerOptions } from "components/Tracking/ApplicantStatusCard/types";
import ScheduleInterviewModal from "components/Tracking/ApplicantStatusCard/ScheduleInterviewModal";
import useValidateCurrentCompanyAction from "hooks/useValidateCurrentCompanyAction";
import { onMutationSuccess, onQueryError } from "utils/queryHandlers";
import getSelectValue from "utils/getSelectValue";
import { useModal } from "contexts/modal";
import useToast from "hooks/useToast";

const refetchQueries = [
  "GetApplicant",
];

/**
 * Hooks that exposes the logic to update an applicant based on the form state.
 * Some fields can be updated right when they change while other fields require validations
 * such as a debounce or a modal depending on the value that has changed.
 */
const useApplicantUpdateHandler = ({
  applicant,
  formState,
  setValue,
  reset,
  watch,
}: UseApplicantUpdateHandlerOptions): void => {
  const [updateApplicantStatus] = useUpdateApplicantStatusMutation({
    refetchQueries,
  });

  const [updateApplicant] = useUpdateApplicantMutation({
    refetchQueries,
  });

  const {
    data,
  } = useGetCurrentUserMemberQuery();

  const [showModal] = useModal();
  const [showToast] = useToast();
  const [t] = useTranslation();

  const [validateCurrentCompanyAction] = useValidateCurrentCompanyAction();

  const responsibleRecruiterId = getSelectValue(watch("responsibleRecruiterId")) as number;
  const status = getSelectValue(watch("status")) as string;

  const recruiterRating = watch("recruiterRating");
  const recruiterNotes = watch("recruiterNotes");
  const interviewAt = watch("interviewAt");

  const previousStatus = usePreviousDistinct(status);

  const handleUpdateApplicantStatus = useDebouncedCallback(
    (overrideParams?: Partial<UpdateApplicantStatusParams>) => {
      if (!applicant?.id) {
        return;
      }

      validateCurrentCompanyAction(() => {
        const params = {
          responsibleRecruiterId: Number(responsibleRecruiterId) || data?.currentUser?.member?.id,
          interviewAt: interviewAt || undefined,
          status: status as StatusUpdateEnum,
          ...(overrideParams ?? {}),
        };

        updateApplicantStatus({
          variables: {
            id: applicant.id,
            params,
          },
        })
          .then(() => {
            onMutationSuccess(t("actions.information_updated"), showToast);

            reset({
              responsibleRecruiterId: params.responsibleRecruiterId as number,
              interviewAt: params.interviewAt,
              status: params.status,
              recruiterRating,
              recruiterNotes,
            });
          })
          .catch((error) => {
            onQueryError(error, showToast);
          });
      });
    },
    1000,
  );

  const handleUpdateApplicant = useDebouncedCallback((payload: Partial<UpdateApplicantParams>) => {
    if (!applicant?.id) {
      return;
    }

    validateCurrentCompanyAction(() => {
      updateApplicant({
        variables: {
          id: applicant.id,
          params: payload,
        },
      })
        .then(() => {
          onMutationSuccess(t("actions.information_updated"), showToast);

          reset({
            responsibleRecruiterId,
            recruiterRating,
            recruiterNotes,
            interviewAt,
            status,
          });
        })
        .catch((error) => {
          onQueryError(error, showToast);
        });
    });
  }, 1000);

  /**
   * When the value of recruiterNotes or recruiterRating changes, we need to update the applicant.
   */
  useEffect(() => {
    const canUpdate = formState?.dirtyFields?.recruiterNotes
      || (recruiterRating && formState?.dirtyFields?.recruiterRating);

    if (!canUpdate) {
      return;
    }

    handleUpdateApplicant.callback({
      recruiterRating,
      recruiterNotes,
    });
  },
  [
    formState?.dirtyFields?.recruiterRating,
    formState?.dirtyFields?.recruiterNotes,
    handleUpdateApplicant,
    recruiterRating,
    recruiterNotes,
  ]);

  /**
   * When the status, interviewAt or responsibleRecruiterId changes, we need to update the
   * applicant status. If the new status is "Interview Scheduled", we need to show a
   * modal asking for the interview date, and when this modal is filled,
   * we can update the status.
   */
  useEffect(() => {
    const isValid = !!(
      (status && formState?.dirtyFields?.status)
      || (interviewAt && formState?.dirtyFields?.interviewAt)
      || (responsibleRecruiterId && formState?.dirtyFields?.responsibleRecruiterId)
    );

    const hasUpdatedStatus = !!(
      applicant?.status.status === status
      && !formState?.dirtyFields?.interviewAt
      && !formState?.dirtyFields?.responsibleRecruiterId
    );

    if (!isValid || hasUpdatedStatus) {
      return;
    }

    const shouldRequireInterviewAt = (
      status === StatusUpdateEnum.InterviewScheduled
      && !formState?.dirtyFields?.interviewAt
      && formState?.dirtyFields?.status
    );

    if (shouldRequireInterviewAt) {
      showModal({
        title: t("applicant_profile.schedule_interview"),
        component: ScheduleInterviewModal,
        componentProps: {
          onFinish: (newInterviewAt: Date) => {
            handleUpdateApplicantStatus.callback({
              interviewAt: newInterviewAt,
            });
          },
        },
        onClose: (payload: ScheduleInterviewModalOnClosePayload) => {
          if (payload?.hasConfirmed || !previousStatus) {
            return;
          }

          setValue("status", previousStatus);
        },
      });

      return;
    }

    handleUpdateApplicantStatus.callback();
  },
  [
    formState?.dirtyFields?.responsibleRecruiterId,
    formState?.dirtyFields?.interviewAt,
    formState?.dirtyFields?.status,
    applicant?.status.status,
    data?.currentUser?.member?.id,
    handleUpdateApplicantStatus,
    responsibleRecruiterId,
    previousStatus,
    interviewAt,
    showModal,
    setValue,
    status,
    t,
  ]);
};

export default useApplicantUpdateHandler;
