import React from "react";
import usePagination from "@jungsoft/react-pagination";
import { Text, HStack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import { PAGINATION_LIMIT_ITEMS_PER_PAGE } from "constants/pagination";
import usePaginationParams from "hooks/usePaginationParams";

import { getPaginationComponents } from "./PaginationComponents";
import { PaginationProps } from "./types";

const Pagination: React.FC<PaginationProps> = ({
  totalCount,
  colorScheme,
  itemsPerPage = PAGINATION_LIMIT_ITEMS_PER_PAGE,
}) => {
  const [t] = useTranslation();

  const [{
    page,
    setPage,
  }] = usePaginationParams();

  const paginationData = usePagination({
    totalItems: totalCount,
    itemsPerPage,
    page,
  });

  const shouldRenderPagination = !!totalCount && totalCount > itemsPerPage;

  if (!shouldRenderPagination) {
    return null;
  }

  return (
    <HStack
      spacing={2}
      width="100%"
      alignItems="center"
      justifyContent="flex-end"
    >
      <Text
        fontFamily="heading"
        lineHeight={1}
        fontSize="xxs"
        color="black"
      >
        {t("pagination.showing_count", {
          maxPage: Math.min(page * itemsPerPage, totalCount ?? 0),
          minPage: ((page - 1) * itemsPerPage) + 1,
          totalCount,
        })}
      </Text>

      <HStack height={18} as="ul" spacing={2}>
        {
          getPaginationComponents({
            page: page as number,
            setPage,
            colorScheme,
            paginationData,
          })
        }
      </HStack>
    </HStack>
  );
};

export default Pagination;
