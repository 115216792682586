import { useCallback, useMemo } from "react";

import { useGetApplicantResponseResumeUrlLazyQuery } from "generated/graphql";
import { onQueryError } from "utils/queryHandlers";
import useToast from "hooks/useToast";

import {
  UseDownloadApplicantResponseResumeOptions,
  UseDownloadApplicantResponseResumeResult,
} from "./types";

/**
 * Exposes a function to download the resume as filled in the form and the query loading state.
 *
 * When executing the function, the hook will query for `getApplicantResponseResumeUrl` in
 * order to ensure that the download url is not expired, and then download the file.
 *
 * In case the query fails, a toast will be shown with the error.
 * @param param0 Options.
 */
const useDownloadApplicantResponseResume = ({
  formResponseId,
}: UseDownloadApplicantResponseResumeOptions): UseDownloadApplicantResponseResumeResult => {
  const [showToast] = useToast();

  const [loadQuery, {
    refetch,
    loading,
    called,
  }] = useGetApplicantResponseResumeUrlLazyQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      const url = data?.getApplicantResponseResumeUrl;

      if (url) {
        window.open(url, "_blank");
      }
    },
    onError: (error) => {
      onQueryError(error, showToast);
    },
  });

  const downloadApplicantResponseResume = useCallback(() => {
    const payload = {
      id: formResponseId,
    };

    if (!called) {
      loadQuery({
        variables: payload,
      });
      return;
    }

    refetch?.(payload);
  }, [
    loadQuery,
    refetch,
    called,
    formResponseId,
  ]);

  const payload = useMemo<UseDownloadApplicantResponseResumeResult>(() => [
    downloadApplicantResponseResume,
    loading,
  ], [
    downloadApplicantResponseResume,
    loading,
  ]);

  return payload;
};

export default useDownloadApplicantResponseResume;
