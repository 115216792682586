import * as R from "remeda";

import { SelectOption } from "components/FormComponents/Select/types";
import { Role } from "generated/graphql";

/**
 * Parses a list of jobs into select options with job ids.
 * @param roles The jobs.
 */
const parseRoleOptions = (
  roles: Role[],
): SelectOption[] => R.pipe(
  roles,
  R.map((role) => ({
    label: role?.name,
    value: role?.id,
  })),
  R.filter((option) => !!(
    option.label
    && option.value
  )),
);

export default parseRoleOptions;
