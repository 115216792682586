import { useMemo } from "react";

import ApplicantPersonalInformation from "components/FormSteps/PersonalInformation/ApplicantPersonalInformation";
import ApplicantPersonalStory from "components/FormSteps/PersonalStory/ApplicantPersonalStory";
import Availability from "components/FormSteps/Availability";
import i18n from "translations/i18n";
import {
  FixedSectionsConfigurationParam,
  FormStep,
  FormStepId,
  FormStepType,
  SectionVisibilityField,
} from "constants/formSteps";
import isStepOptional from "utils/isStepOptional";
import { OtherFormParams } from "views/Forms/types";
import { ExtraSection } from "generated/graphql";
import parsePersonalStories from "components/FormSteps/PersonalStory/parsePersonalStories";

const getSteps = (
  roleName: string | undefined,
  fixedSectionsConfiguration: FixedSectionsConfigurationParam,
  otherFormParams: OtherFormParams,
): FormStep[] => ([
  {
    id: FormStepId.PersonalInformation,
    type: FormStepType.RequiredReadOnly,
    component: ApplicantPersonalInformation,
    title: i18n.t("forms.steps.personal_information.title"),
    subtitle: i18n.t("forms.short_applicant_form.subtitle", { roleName }),
    isOptional: false,
  },
  {
    id: FormStepId.Availability,
    type: isStepOptional(fixedSectionsConfiguration?.[SectionVisibilityField.Availability])
      ? FormStepType.Optional
      : FormStepType.RequiredReadOnly,
    component: Availability,
    title: i18n.t("forms.steps.availability.title"),
    subtitle: i18n.t("forms.short_applicant_form.subtitle", { roleName }),
    isOptional: isStepOptional(fixedSectionsConfiguration?.[SectionVisibilityField.Availability]),
    isVisible: fixedSectionsConfiguration?.[SectionVisibilityField.Availability],
    sectionVisibilityField: SectionVisibilityField.Availability,
  },
  ...parsePersonalStories({
    component: ApplicantPersonalStory,
    roleName,
    ...otherFormParams,
  }),
]);

/**
 * Hook that exposes the steps to be rendered in ShortApplicant Form.
 */
const useSteps = (
  roleName: string | undefined,
  fixedSectionsConfiguration: FixedSectionsConfigurationParam,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  extraSections: ExtraSection[] = [],
  otherFormParams: OtherFormParams,
): FormStep[] => {
  const steps = getSteps(
    roleName,
    fixedSectionsConfiguration,
    otherFormParams,
  );

  const payload = useMemo(() => (
    steps.map((step, index) => ({
      ...step,
      editFormPreview: String(index + 1),
    }))
  ), [
    steps,
  ]);

  return payload;
};

export default useSteps;
