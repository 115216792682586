import { APPLICANT_STATUSES_UPDATES_TO_SHOW_DATE, statusUpdates } from "constants/statuses";
import { StatusUpdate, StatusUpdateEnum } from "generated/graphql";
import i18n from "translations/i18n";
import { formatDate, monthDayYear } from "utils/dateFormats";
import { ApplicantCardStatus } from "components/Tracking/Cards/types";

/**
 * Returns the status to show on an applicant card
 * @param statusUpdate The status update of a applicant
 */
const getApplicantCardStatus = (statusUpdate: StatusUpdate): ApplicantCardStatus => {
  const shouldShowDate = (
    APPLICANT_STATUSES_UPDATES_TO_SHOW_DATE.includes(statusUpdate?.status)
    && statusUpdate?.happenedAt
  );

  const formattedStatusUpdate = (
    statusUpdates[statusUpdate?.status as StatusUpdateEnum]
  );

  const status = (
    shouldShowDate
      ? {
        label: i18n.t("tracking_cards.status_update", {
          status: formattedStatusUpdate.label,
          happenedAt: formatDate(statusUpdate?.happenedAt, monthDayYear),
        }),
        color: formattedStatusUpdate.color,
      }
      : formattedStatusUpdate
  );

  return status;
};

export default getApplicantCardStatus;
