import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Stack } from "@chakra-ui/react";

import resumeSchema, { ResumeSchema } from "settings/yup/schemas/form/resumeSchema";
import DocumentUploadBox from "components/UploadBox/DocumentUploadBox";

import useFormStep from "../hooks/useFormStep";
import { ResumeFormProps } from "./types";

const ResumeForm: React.FC<ResumeFormProps> = ({
  resumeFile,
}) => {
  const [t] = useTranslation();

  const {
    setValue,
    register,
  } = useFormStep<ResumeSchema>({
    schema: resumeSchema,
  });

  const onUpload = useCallback((file: File) => {
    setValue("resume", file);
  }, [setValue]);

  register({
    name: "resume",
  });

  return (
    <Stack spacing={6}>
      <DocumentUploadBox
        title={t("forms.steps.resume.if_you_have_a_resume")}
        initialFile={resumeFile}
        onUpload={onUpload}
        boxSize="2xs"
        w="full"
      />
    </Stack>
  );
};

export default ResumeForm;
