import React from "react";
import { Text, TextProps } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

const LogoFallback: React.FC<TextProps> = (props) => {
  const [t] = useTranslation();

  return (
    <Text
      p={2}
      fontFamily="bold"
      fontSize="xl"
      color="black"
      {...(props ?? {})}
    >
      {t("fallbacks.your_logo_here")}
    </Text>
  );
};

export default LogoFallback;
