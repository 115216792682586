import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Stack, Text } from "@chakra-ui/react";

import {
  useGetImpersonatedCompanyNumberOfUnprocessedApplicantsQuery,
  useGetCurrentCompanyNumberOfUnprocessedApplicantsQuery,
} from "generated/graphql";
import { DASHBOARD_PAGE_LOCATION } from "routes/locations";
import { headerHeight } from "components/Header";
import useGetCompany from "hooks/useGetCompany";
import useNavigate from "hooks/useNavigate";

import { DashboardTokensAlertProps } from "./types";
import useDashboardTokensAlertStore from "./store";

export const dashboardTokensAlertHeight = {
  css: "30px",
  value: 30,
};

const DashboardTokensAlert: React.FC<DashboardTokensAlertProps> = ({
  isManagerOrAdmin,
}) => {
  const navigate = useNavigate();
  const [t] = useTranslation();

  const [isVisible, setIsVisible] = useDashboardTokensAlertStore((store) => [
    store.isVisible,
    store.setIsVisible,
  ]);

  const [company] = useGetCompany(
    useGetCurrentCompanyNumberOfUnprocessedApplicantsQuery,
    useGetImpersonatedCompanyNumberOfUnprocessedApplicantsQuery,
    {
      fetchPolicy: "cache-and-network",
    },
  );

  const numberOfUnprocessedApplicants = company?.numberOfUnprocessedApplicants ?? 0;

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsVisible(numberOfUnprocessedApplicants > 0);
    }, 500);

    return () => {
      clearTimeout(timeoutId);

      setIsVisible(false);
    };
  }, [
    numberOfUnprocessedApplicants,
    setIsVisible,
  ]);

  return (
    <Stack
      isInline
      left="50%"
      position="fixed"
      height={isVisible ? dashboardTokensAlertHeight.css : 0}
      top={isVisible ? headerHeight.css : 0}
      justifyContent="space-between"
      transform="translate(-50%)"
      borderBottomRadius="md"
      bgColor="status.error"
      transition="all 0.5s"
      alignItems="center"
      overflow="hidden"
      boxShadow="sm"
      zIndex={14}
      p={3}
    >
      <Text
        whiteSpace="nowrap"
        fontSize="xs"
        color="white"
      >
        {t("dashboard.unviewed_applicants_alert", {
          count: numberOfUnprocessedApplicants,
        })}
      </Text>

      {
        isManagerOrAdmin && (
          <Text
            onClick={navigate(DASHBOARD_PAGE_LOCATION.toUrl({
              page: "subscription",
            }))}
            _hover={{ textDecoration: "underline" }}
            fontFamily="heading"
            whiteSpace="nowrap"
            cursor="pointer"
            fontSize="xs"
            color="white"
          >
            {t("buttons.add_now").toUpperCase()}
          </Text>
        )
      }
    </Stack>
  );
};

export default DashboardTokensAlert;
